import React from "react";
import { StandardContentButton } from "./ContentBuilderMenuButtons/ContentBuilderMenuButton";

export type ComboMenuOptionsProps = {
    isLoading:boolean;    
    onVideoClick:any;
    onTextClick:any;
    onMultiChoiceClick:any;   
    onTrueFalseClick:any;  
}

/**
 * Menu options for when inside of a Combo lesson
 * @param props 
 * @returns 
 */
const ComboLessonMenuOption = (props:ComboMenuOptionsProps) => {
    const {isLoading, onVideoClick, onTextClick, onMultiChoiceClick, onTrueFalseClick} = props;
    return (
        
            <>
                <p className="content-label">Question by Format</p>
                
                <StandardContentButton 
                    isLoading={isLoading} 
                    onClick={() => onVideoClick()} 
                    btnLabel={"Video Lesson"} 
                />

                <StandardContentButton 
                    isLoading={isLoading} 
                    onClick={() => onTextClick()} 
                    btnLabel={"Text Lesson"} 
                />                
                
                <StandardContentButton 
                    isLoading={isLoading} 
                    onClick={() => onMultiChoiceClick()} 
                    btnLabel={"Multiple Choice Question"} 
                />

                <StandardContentButton 
                    isLoading={isLoading} 
                    onClick={() => onTrueFalseClick()} 
                    btnLabel={"True or False Question"} 
                />
            </>
        
    )
}

export default ComboLessonMenuOption;

