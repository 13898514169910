import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Spinner } from "reactstrap";
import { ReferralController } from "../../../controllers/ReferralController";
import { IReferral, ReferralFilters } from "../../../models/Referral/Referral";
import ReferralMap from "../ReferralMap/ReferralMap";
import ReferralUserListMobile from "./mobile/ReferralUserListMobile";
import "./ReferralListPage.css";
import ReferralUserList from "./ReferralUserList";

const referralController = new ReferralController();

/**
 * Referral page that display users that have had their referrals accepted on a map
 * @returns 
 */
const ReferralListPage = () => {
    const [referrals, setReferrals] = useState<IReferral[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedReferralId, setSelectedReferralId] = useState("");
    const [referralFilters, setReferralFilters] = useState<ReferralFilters>(
        {
            ageGroupFilters: [],
            licensedStateFilter: "",
            stateFilter: ""
        });

    useEffect(() => {
        async function onReferralFilterChange() {
            await getReferralsByFilter();
        }

        onReferralFilterChange();
    }, [referralFilters]);    

    /**
     * Get referrals with a filter on it
     */
    const getReferralsByFilter = async () => {
        setIsLoading(true);
        const toastLoadId = "referralListLoadId";
        toast.loading("Retrieving referral list...", {id: toastLoadId});
        try {

            //We only want accepted referrals
            const referrals = await referralController.GetReferralsByFilter(referralFilters);

            setReferrals(referrals);

            toast.dismiss(toastLoadId);
            setIsLoading(false);
        } catch (error) {
            toast.dismiss(toastLoadId);
            setIsLoading(false);
            console.error(error);
            toast.error("Failed to get referral list");
        }
    }

    /**
     * wrapper for children components to update referral filters
     * @param referralFilters 
     */
    const updateReferralFilters = (referralFilters: ReferralFilters) => {
        setReferralFilters(referralFilters);
    }

    /**
     * wrapper for children components to update the selected referral id
     * @param referralId 
     */
    const updateSelectedReferralId = (referralId: string) => {
        setSelectedReferralId(referralId);
    }

    return (
        <div className="referral-page">

            <>
                {referrals ?
                    (
                        <div className="referral-list-page">
                            <ReferralUserList
                                isLoading={isLoading}
                                referralFilters={referralFilters}
                                updateSelectedReferralId={updateSelectedReferralId}
                                updateReferralFilter={updateReferralFilters}
                                referrals={referrals}
                            />
                            {isLoading ?
                                (
                                    <></>
                                ) : (
                                    <ReferralMap
                                        selectedReferralId={selectedReferralId}
                                        updateSelectedReferralId={updateSelectedReferralId}
                                        referrals={referrals}
                                    />
                                )
                            }
                            <ReferralUserListMobile
                                isLoading={isLoading}
                                referralFilters={referralFilters}
                                updateSelectedReferralId={updateSelectedReferralId}
                                updateReferralFilter={updateReferralFilters}
                                referrals={referrals}
                            />
                        </div>
                    ) : (
                        <div className="referral-list-page-loading">
                            <Spinner/>
                        </div>
                    )
                }
            </>
        </div>
    )

}

export default ReferralListPage;