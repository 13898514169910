import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { ConsultationController } from "../../../../controllers/ConsultationController";
import { ConsultationSession, IConsultationSession } from "../../../../models/consultations/ConsultationSession";
import CbitDataGrid from "../../../Utilities/CbitTable/CbitDataGrid";
import ContinuingEducationLayout from "../../components/layout/ContinuingEducationLayout";
import { v4 as uuidV4 } from "uuid";
import CustomModal from "../../../Utilities/CustomModal";
import AddRecordingLink from "../../components/admin/AddRecordingLink";
import TimeHelper from "../../../../models/utility/TimeHelper";

const consultationController = new ConsultationController();

/**
 * Table for adding zoom recording links by admin to consultations  
 * @returns 
 */
const UploadWebinars = () => {
    const [webinarSessions, setWebinarSessions] = useState<ConsultationSession[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    //If there are ever over 1000 webinars you should add server side pagination on the database (not just on the api like how it currently is) and turn on server side for datagrid
    const [pageSize, setPageSize] = useState(1000); 
    const [pageNum, setPageNum] = useState(1);
    const [isAddLinkOpen, setIsAddLinkOpen] = useState(false);
    const [selectedSession, setSelectedSession] = useState<ConsultationSession | null>(null);

    useEffect(() => {
        async function onComponentMount() {
            await getPastWebinars();
        }

        onComponentMount();
    },[]);

    /**
     * Get webinars that have passed and updates state with them
     */
    const getPastWebinars = async () => {
        setIsLoading(true)
        try {            
            const webinars = await consultationController.GetWebinars(pageNum, pageSize, true);            
            setWebinarSessions(webinars);
        } catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    const webinarColumns:GridColDef[] =[
        {
            field: 'Date', 
            headerName: 'Date', 
            flex:1,       
            valueGetter: (params) => {
               const session:IConsultationSession = params.row;               
               const start = TimeHelper.formatFullDateAndTime(session.consultation.startDate as string, "YYYY-MM-DD h:mm A");
               const end = TimeHelper.formatFullDateAndTime(session.consultation.endDate as string, "YYYY-MM-DD h:mm A");
               return `${start} - ${end}`;
            }                                                            
        },
        {
            field: 'consultant', 
            headerName: 'Consultant', 
            flex:1,
            valueGetter: (params) => {
                const session:IConsultationSession = params.row;

                const consultant = session.consultant;

                if(!session || !consultant) {
                    return 'Not Found';
                }

                return `${consultant.firstName} ${consultant.lastName}`;
            }
        },
        {
            field: 'name',
            headerName: 'Title',
            flex: 1,
            valueGetter: (params) => {
                const session:IConsultationSession = params.row;
                const consultation = session.consultation;
                return consultation.name;
            }
        },
        {
            field: 'description', 
            headerName: 'Description', 
            flex:1,
            valueGetter: (params) => {
                const session:IConsultationSession = params.row;
                const consultation = session.consultation;
                return consultation.description;
            }                                                                 
        },
        {
            field: 'action', 
            headerName: 'Action', 
            sortable: false,
            renderCell: (cell:any) => {
                const row = cell.row;
                const {consultation} = row;
                return (
                    <>
                        {
                            consultation?.recordingLink && consultation?.recordingLink.length > 0 ? 
                            (
                                <button 
                                    onClick={() => {setSessionAndOpenModal(row)}}
                                    className="btn-cbit-link btn-user-table"
                                >
                                    Edit Link
                                </button>
                            ) 
                            : 
                            (
                                <button 
                                    onClick={() => {setSessionAndOpenModal(row)}}
                                    className="btn-cbit-link btn-user-table"
                                >
                                Add Link
                            </button>
                            )
                        }
                    </>
                )
            }                                                                
        },
    ];

    /**
     * Data grid requires rows to have a unique ID so we are creating a new array with a unique id
     * @returns 
     */
    const getGridRows = () => {
        return webinarSessions.map(w => ({...w, id:uuidV4()}))
    }

    const toggleAddLinkModal = () => {
        setIsAddLinkOpen(!isAddLinkOpen);
    }

    /**
     * When a link is added we want to refresh the data and close the modal
     */
    const onAddLinkSubmit = async () => {
        toggleAddLinkModal();
        await getPastWebinars();

    }

    const setSessionAndOpenModal = (session:IConsultationSession) => {
        setSelectedSession(session);
        toggleAddLinkModal();
    }



   return (
        <>
            <ContinuingEducationLayout>
                <h2 className="past-webinars-header">
                    Upload Webinar
                </h2>
                {
                    isLoading ? 
                    (
                        <Spinner/>
                        ) 
                        : 
                        (
                            <CbitDataGrid 
                                columns={webinarColumns} 
                                rowData={getGridRows()} 
                                noRowsText={"No past webinars found"} 
                                isLoading={isLoading}                                        
                            />
                        ) 
                    }
            </ContinuingEducationLayout>
            <CustomModal
                isOpen={isAddLinkOpen}
                toggle={toggleAddLinkModal}
            >
                {selectedSession != null && (
                    <AddRecordingLink 
                        onAddLinkSubmit={onAddLinkSubmit} 
                        consultationSession={selectedSession}
                    />
                )}
            </CustomModal>
        </>
   ) 
}

export default UploadWebinars;