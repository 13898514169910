import {Accordion, AccordionContext, Card, useAccordionButton} from "react-bootstrap";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import "./../AccordionItem.css"
import {PartialModuleTreeModel} from "../../../../models/partialModels/PartialModuleTreeModel";
import {AdminBranchState} from "../../../AdminDashboard/AdminAccordion/AdminAccordionItems/AdminBranchLevelOne";
import {AccordionHelper} from "../../../../models/utility/AccordionHelper";
import IconLessonGroup from "../../../../resources/icons/icon-lesson-group.png";
import IconCaretDown from "../../../../resources/icons/icon-caret-down.png";
import IconCaretLeft from "../../../../resources/icons/icon-caret-left.png";

interface contextAwareProps {
    module: any,
    selectedModuleId:any,
    parentModule:any,
    children: any,
    eventKey: any,
    callback: any
}
type branchProp={
    isOpen :boolean,
    isSelected:boolean
}

export const BranchLevelTwo: React.FC<contextAwareProps> = ({module, selectedModuleId, parentModule, children, eventKey, callback}) =>
{

    const [state, setState] = useState<branchProp>({
        isOpen:false,
        isSelected:false
    });
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(()=>{
        handleAccordion();
    },[selectedModuleId])

    const handleAccordion = () => {
        //If this current module is selected
        if(selectedModuleId === module.id) {
            setState(prevState => ({
                ...prevState,
                isSelected:true,
                isOpen:true
            }));

            AccordionHelper.setCollapseOrShowAccordion(false, module.id);

            return;
        }

        //If one of this current module's children are selected
        if((AccordionHelper.checkIfChildIsSelected(parentModule, module.id, selectedModuleId))) {
            setState(prevState => ({
                ...prevState,
                isSelected:false,
                isOpen:true
            }));
            AccordionHelper.setCollapseOrShowAccordion(false, module.id)
            return;
        }

        //When we are not selected and not open
        setState(prevState => ({
            ...prevState,
            isSelected:false,
            isOpen:false
        }));


        AccordionHelper.setCollapseOrShowAccordion(true, module.id)
    }

    const handleNavigationClick = (e:React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();

        setState(prevState => ({
            ...prevState,
            isOpen:!state.isOpen,
            isSelected:true
        }));

        AccordionHelper.toggleAccordion(module.id);

    }

    const mod = module;

    const LessonCount = function(list:PartialModuleTreeModel[]){
        let count =0 ;
        list.forEach(value =>{
            if(value.children.length ==0){
                count++;
            }
            if(value.children.length !=0){
                value.children.forEach(value2=>{
                    count++;
                })
            }
        })
        return count;
    }

    const CompletedCount = function(list:PartialModuleTreeModel[])
    {
        let count = 0;
        list.forEach(value=>{
            if(value.children.length ==0 && value.isComplete){
                count++;
            }
            if(value.children.length !=0 ){
                value.children.forEach(value2=>{
                    if(value2.isComplete){
                        count++;
                    }
                })
            }
        })
        return count;
    }
    const BranchDisabled = function(list:PartialModuleTreeModel[])
    {
        let count = 0;
        list.forEach(value=>{
            if(value.isDisabled){
                count++;
            }
        })

        if( count == list.length )
        {
            return true
        }else
        {
            return false
        }
    }
    const addIconCaretClass = () => {
        return state.isOpen ? "admin-caret-down" : "admin-caret-left"
    }

    /**
     * Updates the isMobile state based on the window size
     */
    const updateMobileStatus = () => {
        if (window !== undefined) {
            if (window.innerWidth <= 1023) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }
    };

    /**
     * Updates the isMobile state on component mount
     * and adds a listener to update the isMobile state
     * on window resize
     */
    useEffect(() => {

        if (window !== undefined) {
            updateMobileStatus();

            window.addEventListener("resize", updateMobileStatus);
        }

        return () => window.removeEventListener("resize", updateMobileStatus);

    }, [isMobile, setIsMobile, window.innerWidth]);

    return(
        <>
            {state.isSelected ? (
                <>
                    <div className=' sub-branch-container admin-lesson-group-item admin-selected' onClick={handleNavigationClick}>
                        {isMobile ? (
                            <div className='admin-lesson-content mobile remove-padding'>
                                <div className="sub-lesson-mobile">
                                    <div className='lesson-type-icon'>
                                        <img src={IconLessonGroup} alt="lesson group icon"/>
                                    </div>
                                    <div className='admin-lesson-text '>
                                        <b>{children}</b>
                                    </div>
                                </div>

                               <div className="sub-lesson-mobile end">
                                   <div className='chapter-lesson-amount sub-branch-time'>
                                       <span>{CompletedCount(mod.children)}</span>/<span>{mod.children.length}</span>
                                   </div>

                                   <div className={`admin-icon-container ${addIconCaretClass()}`}>
                                       {
                                           state.isOpen ?
                                               (
                                                   <img src={IconCaretDown} alt="icon caret down" />

                                               )
                                               :
                                               (
                                                   <img src={IconCaretLeft} alt="icon caret Left" />
                                               )
                                       }
                                   </div>
                               </div>
                            </div>
                            ) : (
                            <div className='admin-lesson-content remove-padding'>
                                <div className='lesson-type-icon'>
                                    <img src={IconLessonGroup} alt="lesson group icon"/>
                                </div>
                                <div className='admin-lesson-text '>
                                    <b>{children}</b>
                                </div>

                                <div className='chapter-lesson-amount sub-branch-time'>
                                    <span>{CompletedCount(mod.children)}</span>/<span>{mod.children.length}</span>
                                </div>

                                <div className={`admin-icon-container ${addIconCaretClass()}`}>
                                    {
                                        state.isOpen ?
                                            (
                                                <img src={IconCaretDown} alt="icon caret down" />

                                            )
                                            :
                                            (
                                                <img src={IconCaretLeft} alt="icon caret Left" />
                                            )
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </>
            ):(
                <>
                    <div className='sub-branch-container admin-lesson-group-item' onClick={handleNavigationClick}>
                        {isMobile ? (
                            <div className='admin-lesson-content mobile remove-padding'>
                               <div className="sub-lesson-mobile">
                                   <div className='lesson-type-icon'>
                                       <img src={IconLessonGroup} alt="lesson group icon"/>
                                   </div>
                                   <div className='admin-lesson-text'>
                                       <b>{children}</b>
                                   </div>
                               </div>
                              <div className="sub-lesson-mobile end">
                                  <div className="chapter-lesson-amount sub-branch-time">
                                      <span>{CompletedCount(mod.children)}</span>/<span>{mod.children.length}</span>
                                  </div>
                                  <div className={`admin-icon-container ${addIconCaretClass()}`}>
                                      {
                                          state.isOpen ?
                                              (
                                                  <img src={IconCaretDown} alt="icon caret down" />
                                              )
                                              :
                                              (
                                                  <img src={IconCaretLeft} alt="icon caret Left" />
                                              )
                                      }
                                  </div>
                              </div>
                            </div>
                            ) : (
                            <div className='admin-lesson-content remove-padding'>
                                <div className='lesson-type-icon'>
                                    <img src={IconLessonGroup} alt="lesson group icon"/>
                                </div>
                                <div className='admin-lesson-text'>
                                    <b>{children}</b>
                                </div>
                                <div className="chapter-lesson-amount sub-branch-time">
                                    <span>{CompletedCount(mod.children)}</span>/<span>{mod.children.length}</span>
                                </div>
                                <div className={`admin-icon-container ${addIconCaretClass()}`}>
                                    {
                                        state.isOpen ?
                                            (
                                                <img src={IconCaretDown} alt="icon caret down" />

                                            )
                                            :
                                            (
                                                <img src={IconCaretLeft} alt="icon caret Left" />
                                            )
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );

}

