import React from "react";
import ReactPlayer from "react-player";
import './IntroVideo.css';

type IntroVideProps = {
    showTitle:boolean
}

const IntroVideo = (props:IntroVideProps) => {
    const {showTitle} = props;

    const introVideoId = "800659281";
    return (
        <div className="intro-video-container">
            <div className="intro-video-react-player-container">
                <ReactPlayer 
                url={`https://vimeo.com/${introVideoId}`}
                controls={true}
                width={"100%"}            
                height={"100%"}                
                className="react-player"                                                     
                />
            </div>      
            {showTitle && (
                <div className="react-player-title-container">
                    <p className="react-player-title">
                        Introduction To CBIT
                    </p>
                </div>
            )}          
    </div>    
    )
}

export default IntroVideo;