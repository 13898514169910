import React, {useEffect, useState} from 'react';
import { Label } from 'reactstrap';
import { baseImageUrl, ImageManagementController, variantName } from '../../../../controllers/ImageManagementController';
import { ImageFileType } from '../../../../models/utility/ImageFileTypes';
import { StringHelper } from '../../../../models/utility/StringHelper';
import {ReactComponent as ImgIcon} from '../../../../resources/images/icon-img.svg';
import CustomModal from '../../../Utilities/CustomModal';


type ImagePreviewProps = {    
    onFileInputChange:any
    imageId?:string | null
    isLoading:boolean
}

const getImgSrc = (imageId:string) => {
    return baseImageUrl + imageId + variantName;
}

const ImagePreview = (props:ImagePreviewProps) => {

    const {onFileInputChange, imageId, isLoading} = props;

    const [imagePreviewState, UpdateImagePreviewState] = useState('');

    const [modalState, UpdateModalState] = useState(false);

    useEffect(() => {
        if(!StringHelper.IsNullOrWhiteSpace(imageId as string | null)) {
            UpdateImagePreviewState(getImgSrc(imageId as string));
        }
    },[]);

    /**
     * Render the correct preview image
     * @returns 
     */
    const renderImagePreview = () => {

        let imageSrc = "";

        if(imagePreviewState !== "") {
            imageSrc = imagePreviewState;
        } else if(imageId) {
            imageSrc = getImgSrc(imageId);
        }

        return (
            <>
                {
                    imageSrc === '' ? 
                    (
                        <ImgIcon />
                    ) 
                    : 
                    (
                        <>
                            <img onClick={toggleModal} className='text-form-image-preview' src={imageSrc} alt="Image Preview" />
                        </>
                    )
                }
         
            </>
        )
    }

    /**
     * Perform file validations
     * @param file 
     * @returns 
     */
    const performValidations = (file:File) => {

        if(!file) {
            return false;
        }

        if(validateImageFile(file.type) && validateImageSize(file.size)) {
            return true;
        } else {
            return false;
        }

        //TODO add dimensions validation
    }

    const toggleModal = () => {
        const isOpen = !modalState;

        UpdateModalState(isOpen);
    }

    /**
     * Validates the file type to ensure it is an image
     * @param fileType 
     */
     const validateImageFile = (fileType:string) =>  {
        
         const isValid = ImageFileType.includes(fileType);
        
         if(!isValid) {
             //TODO set state for errors
             throw new Error("Invalid Image Type")
         }

         return isValid;
    }

    /**
     * validates an image file size
     * @param fileSize 
     */
    const validateImageSize = (fileSize:number) => {
        const fileSizeLimit = 5000000;

        if(fileSize > fileSizeLimit){
            //TODO set state for errors
            throw new Error("Image must be 5 MB or less");
            
        }

        return true;
    }

    const handleFileInputChange = async (e:React.BaseSyntheticEvent) => {
        
        const fileList : FileList = e.target.files;
        
        if(fileList.length < 0) {
            // TODO throw some type of error
            return;
        }


        const file = fileList.item(0);        

        // because type script yells at us even though we are validating the file in performValidations 
        if(!file) {
            return; 
        }

        // perform image validations
        if(!performValidations(file)){
            return;
        }

        const imagePreview = URL.createObjectURL(file);        
        
        UpdateImagePreviewState(imagePreview);        

        onFileInputChange(file);
    }

    const handleImageRemoval = async () => {
        
        UpdateImagePreviewState("");

        onFileInputChange(null, null);        
        
    }

    const addNoImageClass = () => {
        if(imagePreviewState.length <= 0 ) {
            return "no-upload-image-thumb";
        } else {
            return "";
        }
    }
    

    return (
        <div className='form-upload-image-container'>
            <div className={`upload-image-thumb ${addNoImageClass()}`}>
                {renderImagePreview()}               
            </div>
            <div className="upload-instructions">
                <div className="upload-label">
                    <Label className='form-label' for="coverImage">COVER IMAGE (OPTIONAL)</Label>
                </div>
                <div className="upload-text">
                    Upload a cover image that appears below your heading. We recommend choosing a landscape 
                    image orientation with a minimum width of 640 px.
                </div>
                {
                    imagePreviewState === '' && !imageId ? 
                    (
                        <label htmlFor='inputImageUpload' className="upload-btn-container  btn-cbit-primary">
                            UPLOAD
                        </label>
                    ) 
                    : 
                    (
                        <div className='replace-image-container'>
                            <label htmlFor='inputImageUpload' className="upload-btn-container btn-cbit-primary replace-image">
                                REPLACE IMAGE
                            </label>
                            <button type='button' onClick={handleImageRemoval} className="btn-cbit-minor btn-remove-image">
                                REMOVE IMAGE
                            </button>
                        </div>

                    )
                }
                    <input 
                    disabled={props.isLoading}
                    id="inputImageUpload"
                    type='file' 
                    onChange={handleFileInputChange} 
                    className='input-txt-img-upload'
                    accept="image/png, image/jpeg"                                            
                    />                                                                                
            </div>
            <CustomModal 
                isOpen={modalState} 
                toggle={toggleModal } >
                <img src={imagePreviewState} alt="Image Preview" />
            </CustomModal>
        </div>
    )
}

export default ImagePreview