import {ConsultationReviewService} from "../services/ConsultationReviewService";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import {AxiosError} from "axios";
import {IConsultationReview} from "../models/consultations/ConsultationReview";
import { Consultation } from "../models/consultations/Consultation";
import { ConsultationSession } from "../models/consultations/ConsultationSession";
import TimeHelper from "../models/utility/TimeHelper";

/**
 * Class for calling services to communicate with Consultation Reviews
 */
export class ConsultationReviewController{

    private consultationReviewService:ConsultationReviewService;

    constructor()
    {
        this.consultationReviewService = new ConsultationReviewService();
    }

    /**
     * Checks if a user has a review they need to do
     * @param userTime 
     * @returns 
     */
    public async CheckIfUserNeedsToReview(userTime:Date|string) : Promise<ConsultationSession | null>{
        try {
            return await this.consultationReviewService.CheckIfUserNeedsToReview(userTime);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get a review by its id
     * @param id 
     * @returns 
     */
    public GetConsultationReviewById( id:string){
        try{
            return this.consultationReviewService.Get({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get reviews by the passed in date range
     * @param userId 
     * @param from 
     * @param to 
     * @returns 
     */
    public async GetConsultationReviewByDate(userId:string, from:Date|string, to:Date|string){
        try {
            from = new Date(from)
            to = new Date(to)

            const utcFrom = TimeHelper.ReplaceOffsetWithUTC(from);
            const utcTo = TimeHelper.ReplaceOffsetWithUTC(to);            

            return await this.consultationReviewService.GetConsultationReviewByDate(userId, utcFrom, utcTo);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get all consultation reviews
     * @returns 
     */
    public GetConsultationReviews( ){
        try{
            return this.consultationReviewService.GetAll();
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Creates a new consultation review
     * @param consultationData 
     * @returns 
     */
    public async CreateReview( consultationData: IConsultationReview){
        try{
            return await this.consultationReviewService.CreateConsultationReview(consultationData);
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Checks if a user has done a consultation review for a specific consultation
     * @param userId 
     * @param consultationId 
     */
    public async CheckReviewForConsultation(userId:string, consultationId:string) {
        try {            
            return this.consultationReviewService.CheckReviewForConsultation(userId, consultationId);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get a list of reviews by a list of consultation ids
     * @param consultationIds 
     */
    public async GetReviewsByConsultations(consultationIds:string[], assignedUserId:string) {
        try {
            return this.consultationReviewService.GetReviewsByConsultations(consultationIds, assignedUserId);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get all reviews done by consultants
     * @returns 
     */
    public async GetConsultantsReviews() {
        try {
            return this.consultationReviewService.GetConsultantsReviews();
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }                        
    }
    
    /**
     * Get all reviews done by attendees
     * @returns 
     */
    public async GetAttendeesReviews() {
        try {
            return this.consultationReviewService.GetAttendeesReviews();
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }

    }

    public async GetReviewWithReviewer(reviewId:string) {
        try {
            return this.consultationReviewService.GetReviewWithReviewer(reviewId);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
}