import React, {useEffect, useState} from "react"
import {SocialConnectionController} from "../../../../controllers/SocialConnectionController";
import "./AddPost.css"
import DOMPurify from "dompurify";
import toast from "react-hot-toast";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {CurrentUserState} from "../../../../store/CurrentUser";
import SocialConnectionLayout from "../../components/layout/SocialConnectionLayout";

const AddPost = ({history}: any) => {
    const [formData, setFormData] = useState({
        name: "",
        content: "",
        isPromoted: false,
    })

    const {name, content} = formData;

    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state) => state.currentUser);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (name === "" || content === "") {
            toast.error("Please fill in all fields");
            return;
        }

        let cleanedName = DOMPurify.sanitize(name);
        let cleanedContent = DOMPurify.sanitize(content);

        cleanedName = cleanedName.trim();
        cleanedContent = cleanedContent.trim();


        const newPost = {
            id: "",
            name: cleanedName,
            content: cleanedContent,
            isPromoted: false,
            isReported: false,
            isVisible: true,
            replies: [],
            userId: "",
            createdAt: new Date(),
            updatedAt: new Date(),
        }


        const sController = new SocialConnectionController();
        try {
            await sController.CreatePost(newPost);
            history.push('/social-connection')
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const userIsBanned = UserStore?.userProfile?.isBanned;

        if (userIsBanned) {
            history.push('/home');
        }

    }, [UserStore?.userProfile?.isBanned]);

    return (
        <SocialConnectionLayout>
            <div className='social-connection-add-post-container'>
                <div className="social-connection-add-post-header">
                    <h2 className='social-connection-heading'>Add Post</h2>
                    <span className='social-connection-go-back'>
                        <a href='/social-connection'>&#60; Return to Main Discussion Board</a>
                    </span>
                </div>
                <div className="social-connection-add-post-form-container">
                    <form onSubmit={handleSubmit}>
                        <div className="social-connection-add-post-form-input">
                            <label htmlFor="name">Title</label>
                            <input type="text" name="name" id="name" placeholder={'Enter a title...'}
                                   onChange={handleChange} value={name}/>
                        </div>
                        <div className="social-connection-add-post-form-input">
                            <label htmlFor="content">Content</label>
                            <textarea id="content" name="content" rows={4} cols={50} placeholder={'Enter some content...'}
                                      onChange={handleChange} value={content}/>
                        </div>

                        <div className="social-connection-add-post-disclaimer-text">
                            <p className={'disclaimer-text'}>
                                Note: By clicking "Add Post", you agree to not reveal any identifying information that could compromise the privacy of a patient, to be respectful and use language that is appropriate when interacting with others, and to be supportive of other users of this feature of CBIT Trainer.
                            </p>
                        </div>

                        <div className="social-connection-add-post-button-container">
                            <button className='btn-cbit-primary btn-social-connection-add-post'>
                                Add Post
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </SocialConnectionLayout>
    )
}

export default AddPost