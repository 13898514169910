import React from "react";
import "./ReferralAlert.css";

type ReferralWelcomeDialogProps = {
    onBtnClick:() => void;
}

const ReferralAlert = (props:ReferralWelcomeDialogProps) => {
    const {onBtnClick} = props;

    return (
        <div className="referral-alert">            
            <h2 className="cbit-dashboard-header">Welcome to the CBIT Trainer Referrals System!</h2>
            <div className="referral-description-container">
                <p>
                The CBIT Trainer referral system is a source to search for professionals in your area that are [insert text about referrals' qualifications]. Once you complete your CBIT Trainer program, you will also have the opportunity to appear on the referral list.
                </p>
                <p>
                Simply complete your profile and click "Submit" for your information to be considered by the CBIT Trainer admins. You will receive a notification once your profile has been accepted in the Referral list.
                </p>
            </div>
            <div className="cbit-dialog-btn-container">
                <button onClick={onBtnClick} className="btn-cbit-primary">GET STARTED</button>
            </div>
        </div>
    )
}

export default ReferralAlert;