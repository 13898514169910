import { IContactUsEmailRequest } from "../models/requests/ContactUsEmailRequest";
import { EmailerService } from "../services/EmailerService";
import { toast } from "react-hot-toast";

/**
 * Class for communicating with emailer services
 */
export class EmailerController {
    private emailerService:EmailerService;
    
    constructor() {        
        this.emailerService = new EmailerService();
    }

    /**
     * Sends a contact us email to the cbit team
     * @param req 
     * @returns 
     */
    public async SendContactUsEmail(req:IContactUsEmailRequest) : Promise<boolean> {
        try {
            return await this.emailerService.SendContactUsEmail(req);
        } catch (error) {
            console.error(error);
            toast.error("Failed to send message");
            return false;
        }
    }
}