import React, { useCallback, useEffect, useState } from 'react';
import {DropzoneOptions, useDropzone} from 'react-dropzone';
import toast from 'react-hot-toast';
import { Label } from 'reactstrap';
import trashIconSrc from '../../../resources/icons/icon-trash.png';
import './PdfUploader.css';

type pdfUploaderProps = {
    isLoading:boolean
    fileList:File[]
    hasErrors:boolean
    handleChange:(file:File | null | undefined) => void
}

type FileState = {
    files:File[]
}

/**
 * This is a temporary component that will eventually be replaced
 * with the Artifact Uploader that is in the chocolate bar project
 */
const PdfUploader = (props:pdfUploaderProps) => {
    const {isLoading, handleChange, fileList} = props;

    const onDrop = useCallback(acceptedFiles => {},[]);

    const maxFileSize = 16777217;

    const defaultFileState:FileState = {
        files: fileList
    }

    const [myFiles, setFiles] = useState(defaultFileState);

    useEffect(() => {
        setFiles(prevState => ({...prevState, files: [...fileList]}));
    },[fileList]);

    const handleAddingFiles = useCallback((acceptedFiles:File[], fileRejections) => {
        if(fileRejections.length > 0) {
            if(fileRejections[0].errors[0].code === "file-too-large") {
                toast.error("File Is Too Large Please Try A File 16 mb or Less")
            } else {
                toast.error("Failed To Accept File. Please Make Sure Your File Is A PDF");
            }
            return;
        }

        if(acceptedFiles.length < 1)
            return;

        handleChange(acceptedFiles[0]);

        return setFiles(prevState => ({
            ...prevState,
            files: [...acceptedFiles]
        }));

    }, [myFiles]);

    const removeFiles = () => {
        handleChange(null);
        setFiles(prevState => ({
            ...prevState,
            files: []
        }));
    }

    const dropzoneOptions:DropzoneOptions = {
        maxSize:maxFileSize,
        maxFiles:1,
        preventDropOnDocument:true,
        accept: {
            'application/pdf': ['.pdf']
        },
        //@ts-ignore
        onDrop:handleAddingFiles
    }

    const {getRootProps, getInputProps} = useDropzone(dropzoneOptions);

    return (
        <>
            <div className="upload-instructions pdf-uploader">

                <div className="upload-label">
                    <Label className='form-label' for="coverImage">Upload PDF File</Label>
                    <div className="upload-text">
                        Upload a PDF file that is less than 16mb.
                    </div>
                </div>
                <div className="upload-pdf-container">
                    {
                        myFiles.files.length <= 0  ?
                        (
                            <label {...getRootProps()} htmlFor='inputImageUpload' className="upload-btn-container  btn-cbit-primary">
                                UPLOAD
                            </label>
                        )
                        :
                        (
                            <div className='replace-image-container'>
                                <label {...getRootProps()} htmlFor='inputImageUpload' className="upload-btn-container btn-cbit-primary replace-image">
                                    REPLACE PDF
                                </label>
                            </div>
                        )
                    }
                    <input
                        {...getInputProps}
                        id={'inputImageUpload'}
                        disabled={props.isLoading}
                        type='file'
                        className='input-txt-img-upload'
                    />
                    {
                        myFiles.files.length > 0 && (
                            <div className="file-preview-container">
                                {myFiles.files[0].name}
                                {/* <button
                                    onClick={removeFiles}
                                    className='btn-cbit-icon'
                                >
                                    <img src={trashIconSrc} alt="Trash Icon" />
                                </button> */}
                            </div>
                        )
                    }
                </div>
                {
                    props.hasErrors && (
                        <div className="uploader-error-text">
                            Please Upload A File.
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default PdfUploader
