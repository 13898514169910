import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { MultipleChoiceContent } from '../../../../models/contentTypes/MultipleChoiceContent';
import { ILesson, IsInstanceOfLesson } from '../../../../models/lesson/Lesson';
import { ILessonContent, LessonContent } from '../../../../models/lesson/LessonContent';
import { AdminModule, emptyAdminModule, IAdminModule } from '../../../../models/module/AdminModule';
import { IModule, IsInstanceOfModule } from '../../../../models/module/Module';
import { AdminTreeModule, IAdminTreeModule } from '../../../../models/partialModels/AdminTreeModule';
import { DeepCopy } from '../../../../models/utility/DeepCopy';
import { EditorContentEnum } from '../../../../models/utility/EditorContentEnum';
import { StringHelper } from '../../../../models/utility/StringHelper';
import { ModuleAction } from '../../../../models/utility/UnSavedCourse/ModuleAction';
import { ApplicationState } from '../../../../store';
import { CurrentUserAccordionState } from '../../../../store/UserModuleAccordionStore';
import CustomAlert from '../../../Utilities/CustomAlert';
import AdminBreadCrumbs from '../../AdminBreadCrumbs.tsx/AdminBreadCrumbs';
import ContentTypeLabel from '../ContentTypeLabel/ContentTypeLabel';
import ChapterView from './ChapterView/ChapterView';
import ComboLessonView from './ComboLessonView/ComboLessonView';
import './ContentViewer.css';
import LessonGroupView from './LessonGroupView/LessonGroupView';
import QuizView from './QuizView/QuizView';
import TopModuleView from './TopModuleView/TopModuleView';
import ViewEditorTools from './ViewEditorTools/ViewEditorTools';

type ContentBuilderViewProps = {
    contentList:IAdminModule[]
    contentType:EditorContentEnum
    isLoading:boolean    
    hasUnSavedContent:boolean
    updateContentList:(contentList:IAdminModule[]) => void
    updateIsLoading:(isLoading:boolean) => void
    navigateTo:(module:IAdminTreeModule | IModule | ILesson) => void
    addToAccordion: (accordionModule: IAdminTreeModule) => void
    updateAccordion: (accordionModule: IAdminTreeModule) => void
    removeAccordionItem: (contentToRemoveId: string) => void
    updateUnSavedContent: (adminModule:IAdminModule, action:ModuleAction, adminModules?:IAdminModule[]) => void
    onSaveContent:() => void
    onPublishContent:() => void
    currentModuleId:string
    topMostModuleId:string
}

type deleteConfirmState = {
    nameOfItemToDelete:string,
    onDeleteAction:any,
    isDeleteConfirmOpen:boolean    
    unmount:boolean
}

const defaultDeleteConfirmState : deleteConfirmState = {
    nameOfItemToDelete: '',
    onDeleteAction: null,
    isDeleteConfirmOpen: false,
    unmount:false
}

const ContentBuilderView = (props:ContentBuilderViewProps) => {
    const {
        isLoading,
        contentType,
        contentList,
        navigateTo,
        updateContentList,
        updateIsLoading,
        updateAccordion,
        removeAccordionItem,
        updateUnSavedContent,
        hasUnSavedContent,
        addToAccordion,
        onSaveContent,
        onPublishContent,
        currentModuleId,
        topMostModuleId,
    } = props;

    const [isScrollVisible, setIsScrollVisible] = useState(false);    

    const [deleteConfirmState, setDeleteConfirmState] = useState(defaultDeleteConfirmState);

    const accordionStore = useSelector<ApplicationState, CurrentUserAccordionState | undefined>(state => state.currentUserModuleAccordion);
    
    const [globalContentState, setGlobalContentState] = useState(
        {
            isGlobalVisibility:accordionStore?.selectedModule.isViewable ? accordionStore?.selectedModule.isViewable : false, 
            isAllContentExpanded:false,
            forceRerender:false,
        }
    );

    const bottomSaveBtn = useRef<HTMLButtonElement>(null);

    const getViewTitle = () => {
        if(!accordionStore)
            return "";

        let title = "";
            
        if(contentType === EditorContentEnum.parentModule) {
            title = accordionStore.parentModule.name;
        } else {
            title = accordionStore.selectedModule.name;
        }

        return StringHelper.capitalizeWord(title);
    }

    useLayoutEffect(() => {
        function updateOnContentListChange() {
            checkIfScrollPresent();
        }

        updateOnContentListChange();
    },[contentList, isLoading])


    useEffect(() => {
        async function onCurrentModuleChange() {            
            if(!accordionStore)
                return;

            const isGlobalVisibility = accordionStore.selectedModule.isViewable;
            
            setGlobalContentState(prevState => ({
                ...prevState,
                isGlobalVisibility
            }));            
        }

        onCurrentModuleChange();
    }, [currentModuleId]);

    /**
     * Function that runs when the dragging and dropping have finished
     * @param results 
     * @returns 
     */
    const onDragFinished = (results:any) => {
       // If we do not have a destination we return
       if(!results.destination) {
           return;
       }                                

       const newContentList : any[] = [...contentList];

       const sourceIdx = results.source.index;

       const destinationIdx = results.destination.index;
       
       // the source index is the where the grabbed item is
       const grabbedItem : ILessonContent = {...newContentList[sourceIdx]} as LessonContent
       
       // The item that was swapped with the grabbed item
       const swappedItem : ILessonContent = {...newContentList[destinationIdx]} as LessonContent
       
       const [reorderedItem] = newContentList.splice(sourceIdx, 1);

       newContentList.splice(results.destination.index, 0, {...reorderedItem});

       // the only way to tell if I am in a quiz or not
       if(MultipleChoiceContent.checkIfQuestion(grabbedItem) && MultipleChoiceContent.checkIfQuestion(swappedItem)) {   
                   
           updateQuestionNumbers(newContentList);

       }
    
       updateUnSavedContent(emptyAdminModule, ModuleAction.ORDERCHANGE);
       
       updateContentList(newContentList);
    
    }

    /**
     * Toggles visibility for all items in the content list and added them to update stage area
     * Only for when inside of a chapter or lesson group   
     */
    const toggleGlobalVisibility = () => {
        if(contentType === EditorContentEnum.lesson || contentType === EditorContentEnum.parentModule)
            return;

        if(!accordionStore)
            return;

        const isGlobalVisibility = !globalContentState.isGlobalVisibility;
        
        const forceRerender = !globalContentState.forceRerender;
        
        setGlobalContentState(prevState => ({
            ...prevState,
            isGlobalVisibility,
            forceRerender
        }));

        const contentListCopy:IAdminModule[] = DeepCopy.copy(contentList);

        for(let adminModule of contentListCopy) {
            if(IsInstanceOfLesson(adminModule.content)) {
                (adminModule.content as ILesson).isViewable = isGlobalVisibility;
            }

            if(IsInstanceOfModule(adminModule.content as IModule)) {
                (adminModule.content as IModule).isViewable = isGlobalVisibility;
            }            

        }    

        const selectedModule:IAdminTreeModule = DeepCopy.copy(accordionStore.selectedModule);

        selectedModule.isViewable = isGlobalVisibility;

        const moduleWeAreIn = AdminTreeModule.convertAdminTreeModuleToModule(selectedModule);

        const contentToBeUpdated:IAdminModule[] = DeepCopy.copy(contentListCopy);

        contentToBeUpdated.push( new AdminModule(moduleWeAreIn));

        updateUnSavedContent(contentToBeUpdated[0], ModuleAction.UPDATE, contentToBeUpdated);

        updateContentList(contentListCopy);

    }

    /**
     * To globally expands or collapse lesson items/lesson content items
     * @param isExpanded 
     */
    const globalExpandAndCollapse = (isExpanded:boolean) => {
        const forceRerender = !globalContentState.forceRerender;
        setGlobalContentState(prevState => ({
            ...prevState,
            isAllContentExpanded:isExpanded,
            forceRerender
        }));
    }

    /**
     * Given a list of content, update the questions within them
     * @param contentList 
     */
    const updateQuestionNumbers = (contentList:any[]) => {
        debugger
        // We are using a counter instead of the index because we can update the counter only when there is a question
        let questionNumber = 1;

        for(let i = 0; i < contentList.length; i++) {
            const currentQuestion = {...contentList[i]};
            // For edge cases were a person added other content aside from a question on a quiz
            if(MultipleChoiceContent.checkIfQuestion(contentList[i])){

                // Because question numbers are strings in the API
                currentQuestion.multipleChoiceProperties.questionNumber = questionNumber.toString(); 
                
                contentList[i] = currentQuestion;
                
                questionNumber++;
            }                                                  
        }
    }
    /**
    * Check if a scrollbar is present which will decide if the bottom save button appears or not
    */
    const checkIfScrollPresent = () => {        
        const adminInnerView = document.querySelector(".admin-view-inner-panel");
        
        let scrollIsVisible = false

        if(adminInnerView) {
            
            scrollIsVisible = adminInnerView.scrollHeight > adminInnerView.clientHeight;
        }
        setIsScrollVisible(scrollIsVisible);    
            
    }

    /**
     * Handles what function the delete alert confirmation button should run
     * @param onDeleteSubmit The function we want to run when the delete alert is confirmed
     */
    const onDeleteConfirmSubmit = (deleteItemName:string, deleteCallback:any) => {        
        setDeleteConfirmState(prevState => ({
            ...prevState,
            nameOfItemToDelete:deleteItemName,
            onDeleteAction:deleteCallback,
            isDeleteConfirmOpen:true
        }))
    }

    /**
     * Toggles the delete confirmation modal/alert
     */
    const toggleDeleteConfirmation = () => {
        const isDeleteConfirmOpen = !deleteConfirmState.isDeleteConfirmOpen;

        setDeleteConfirmState(prevState => ({
            ...prevState,
            isDeleteConfirmOpen
        }));
    }

    /**
     * Decides which content view to render to show specific content items 
     * based on the content we are inside
     * We are prop drilling here because I was told not to add to the redux store
     * @returns 
     */
    const renderView = () => {
        if(!accordionStore)
            return;

        switch(contentType) {
            case EditorContentEnum.parentModule:
                return (
                    <TopModuleView
                        contentList={contentList}
                        updateIsLoading={updateIsLoading}
                        updatedContentList={updateContentList}
                        selectedModuleId={currentModuleId}
                        contentType={contentType}
                        navigateTo={navigateTo}
                        isLoading={isLoading}
                        addToAccordion={addToAccordion}
                        updateAccordion={updateAccordion}
                        onDeleteConfirm={onDeleteConfirmSubmit} 
                        removeAccordionItem={removeAccordionItem}   
                        updateUnSavedContent={updateUnSavedContent}    
                        topMostModuleId={topMostModuleId}      
                        parentModule={accordionStore.parentModule as unknown as IAdminTreeModule}                    
                        selectedModule={accordionStore.selectedModule as unknown as IAdminTreeModule}
                        forceRerender={globalContentState.forceRerender}
                        isVisible={globalContentState.isGlobalVisibility}
                        isExpanded={globalContentState.isAllContentExpanded}                                   
                    />
                );
            case EditorContentEnum.module:
                return (
                    <ChapterView
                        contentList={contentList}
                        updateIsLoading={updateIsLoading}
                        updatedContentList={updateContentList}
                        selectedModuleId={currentModuleId}
                        contentType={contentType}
                        navigateTo={navigateTo}
                        isLoading={isLoading}
                        addToAccordion={addToAccordion}
                        updateAccordion={updateAccordion}
                        onDeleteConfirm={onDeleteConfirmSubmit} 
                        removeAccordionItem={removeAccordionItem}   
                        updateUnSavedContent={updateUnSavedContent}  
                        topMostModuleId={topMostModuleId}
                        parentModule={accordionStore.parentModule as unknown as IAdminTreeModule}                    
                        selectedModule={accordionStore.selectedModule as unknown as IAdminTreeModule}
                        forceRerender={globalContentState.forceRerender}
                        isVisible={globalContentState.isGlobalVisibility}
                        isExpanded={globalContentState.isAllContentExpanded}
                    />
                )

            case EditorContentEnum.lessonQuiz:
                return (
                    <QuizView 
                    contentList={contentList}
                    updateIsLoading={updateIsLoading}
                    updatedContentList={updateContentList}
                    selectedModuleId={accordionStore.selectedModule.id}
                    contentType={contentType}
                    navigateTo={navigateTo}
                    isLoading={isLoading}
                    addToAccordion={addToAccordion}
                    updateAccordion={updateAccordion}
                    onDeleteConfirm={onDeleteConfirmSubmit} 
                    removeAccordionItem={removeAccordionItem}   
                    updateUnSavedContent={updateUnSavedContent}  
                    topMostModuleId={accordionStore.parentModule.id}                    
                    parentModule={accordionStore.parentModule as unknown as IAdminTreeModule}                    
                    selectedModule={accordionStore.selectedModule as unknown as IAdminTreeModule}
                    forceRerender={globalContentState.forceRerender}
                    isVisible={globalContentState.isGlobalVisibility}
                    isExpanded={globalContentState.isAllContentExpanded}
                    />
                )
            case EditorContentEnum.lessonGroup:
                return (
                    <LessonGroupView 
                        contentList={contentList}
                        updateIsLoading={updateIsLoading}
                        updatedContentList={updateContentList}
                        selectedModuleId={accordionStore.selectedModule.id}
                        contentType={contentType}
                        navigateTo={navigateTo}
                        isLoading={isLoading}
                        addToAccordion={addToAccordion}
                        updateAccordion={updateAccordion}
                        onDeleteConfirm={onDeleteConfirmSubmit} 
                        removeAccordionItem={removeAccordionItem}   
                        updateUnSavedContent={updateUnSavedContent}  
                        topMostModuleId={accordionStore.parentModule.id}
                        parentModule={accordionStore.parentModule as unknown as IAdminTreeModule}                    
                        selectedModule={accordionStore.selectedModule as unknown as IAdminTreeModule}
                        forceRerender={globalContentState.forceRerender}
                        isVisible={globalContentState.isGlobalVisibility}
                        isExpanded={globalContentState.isAllContentExpanded}
                        
                    />
                )
            case EditorContentEnum.lesson:
                return (
                    <ComboLessonView
                        contentList={contentList}
                        updateIsLoading={updateIsLoading}
                        updatedContentList={updateContentList}
                        selectedModuleId={accordionStore.selectedModule.id}
                        contentType={contentType}
                        navigateTo={navigateTo}
                        isLoading={isLoading}
                        addToAccordion={addToAccordion}
                        updateAccordion={updateAccordion}
                        onDeleteConfirm={onDeleteConfirmSubmit} 
                        removeAccordionItem={removeAccordionItem}   
                        updateUnSavedContent={updateUnSavedContent}  
                        topMostModuleId={accordionStore.parentModule.id}
                        parentModule={accordionStore.parentModule as unknown as IAdminTreeModule}                    
                        selectedModule={accordionStore.selectedModule as unknown as IAdminTreeModule}
                        forceRerender={globalContentState.forceRerender}
                        isVisible={globalContentState.isGlobalVisibility}
                        isExpanded={globalContentState.isAllContentExpanded}
                    />
                )
        }
    }

    return (
        <>
            {
                !accordionStore ? 
                (
                    <></>
                )
                :
                (
                    <div className="admin-view-panel"> 
                        <div className="admin-view-inner-panel">
                            {
                                isLoading ? 
                                (
                                    <Spinner className='custom-spinner admin-spinner' color="secondary">loading</Spinner>
                                ) 
                                : 
                                (
                            <>
                            <AdminBreadCrumbs  
                                contentType={contentType}                                     
                                setNavigateTo={navigateTo}                                    
                            />    
                            <div className='admin-view-header'>                                    
                                <h1 className='admin-view-title'>
                                    {getViewTitle()}                                 
                                </h1>
                                {                                    
                                    <ContentTypeLabel contentType={contentType} />                                                                        
                                }
                            </div>   
                            <>
                            <ViewEditorTools
                                onCollapseAndExpandClick={globalExpandAndCollapse}
                                contentType={contentType}                                                                
                                isAllVisible={globalContentState.isGlobalVisibility}
                                updateIsAllVisible={toggleGlobalVisibility}
                                hasContentChanges={hasUnSavedContent} 
                                onSaveContent={onSaveContent}
                                onPublishContent={onPublishContent}
                                updateUnsavedContent={updateUnSavedContent}
                                updateAccordion={updateAccordion}
                            />
                            <DragDropContext onDragEnd={onDragFinished}>
                                <Droppable droppableId='drop-zone'>
                                    {(provided, snapshot) => (
                                        <div className="content-list" {...provided.droppableProps} ref={provided.innerRef}>
                                            {accordionStore.parentModule.id.length > 0 && (
                                                renderView()
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>   
                            <div className="save-btn-container">
                            {
                                isScrollVisible && (
                                    <button 
                                    ref={bottomSaveBtn}
                                    disabled={!hasUnSavedContent} 
                                    onClick={onSaveContent}                                         
                                    className={`btn-cbit-primary btn-save-content`}
                                    >
                                        Save
                                    </button>                                
                                )   

                            }                 
                            </div>
                        </>
                        </>                        
                        )
                    }    
                        </div>
                    </div>
                )
            }
            {/* Delete Confirmation */}
            <CustomAlert 
                header={`ARE YOU SURE YOU WANT TO DELETE: "${deleteConfirmState.nameOfItemToDelete.toUpperCase()}"?`} 
                text={`Once you click "Delete", the lesson will be deleted immediately from your course and you will not be able to retrieve it's content.`} 
                primaryBtnText={'DELETE'} 
                secondaryBtnText={'CANCEL'} 
                isOpen={deleteConfirmState.isDeleteConfirmOpen} 
                primaryBtnSubmit={() => {                                       
                        deleteConfirmState.onDeleteAction() 
                        toggleDeleteConfirmation()
                    }
                } 
                secondaryBtnSubmit={() => toggleDeleteConfirmation()} 
                toggleAlert={() => toggleDeleteConfirmation()} 
            />

        </>
    )
}

export default ContentBuilderView;