import React, {useEffect, useState} from "react";
import {ConsultationController} from "../../../../controllers/ConsultationController";
import {ISeminar} from "../../../../models/consultations/Seminar";
import {DeepCopy} from "../../../../models/utility/DeepCopy";
import ContinuingEducationLayout from "../../components/layout/ContinuingEducationLayout";
import UpcomingWebinarItem from "../../components/upcomingWebinars/UpcomingWebinarItem";
import {Spinner} from "reactstrap";
import './UpcomingWebinars.css';

const UpcomingWebinars = () => {
    // @ts-ignore
    const [upcomingWebinars, setUpcomingWebinars] = useState<ISeminar>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(12);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [isPast, setIsPast] = useState<boolean>(false);

    const cController = new ConsultationController();

    /**
     * Gets all webinars
     * @returns {Promise<ISeminar[]>}
     */
    const getAllWebinars = async () => {
        const data = await cController.GetWebinars(pageNumber, pageSize, isPast);

        setUpcomingWebinars(data);
        setLoading(false)
        return data;
    }

    /**
     * Runs on component mount
     * Gets all upcoming webinars
     */
    useEffect(() => {
        getAllWebinars();
   }, []);

    /**
     * Loads more webinars
     * @param pageNumber
     * @param pageSize
     * @returns {Promise<ISeminar[]>}
     */
    const loadMore = async (pageNumber: number, pageSize: number) => {
        let newPage = pageNumber + 1;
        cController.GetWebinars(newPage, pageSize, isPast).then((data) => {
            if (data.length < pageSize) {
                setIsCompleted(true);
            }
            // @ts-ignore
            setUpcomingWebinars([...upcomingWebinars, ...data]);
        });
        setPageNumber(newPage);
    }

    /**
     * Handles data update for webinars
     * @param updatedRegistrations
     * @returns {Promise<ISeminar[]>}
     */
    const handleDataUpdate = async (updatedRegistrations: any) => {
        let oldData = DeepCopy.copy(upcomingWebinars);

        oldData.map((seminar: any) => {
            if (seminar.consultation.id === updatedRegistrations.id) {
                seminar.consultation = updatedRegistrations;
            }
            else {
                seminar = seminar;
            }
        })

        setUpcomingWebinars(oldData);
    }

    return (
        <ContinuingEducationLayout>
            {loading ? (
                <div className="spinner-container">
                    <Spinner color='primary'/>
                </div>
            ) : (
                <>
                    <div>
                        <h2 className="upcoming-webinars-header">
                            Upcoming Webinars
                        </h2>
                    </div>
                    <div className="upcoming-webinars-container">
                        {/* @ts-ignore */}
                        {upcomingWebinars && upcomingWebinars.length > 0 ? upcomingWebinars.map((seminar: ISeminar) => (
                            <UpcomingWebinarItem key={seminar.consultation.id} seminar={seminar} updateWebinars={handleDataUpdate} />
                        )) : (
                            <div className="upcoming-webinars-no-webinars-container">
                                <h3 className="upcoming-webinars-no-webinars-header">
                                    No Upcoming Webinars
                                </h3>
                            </div>
                        )}
                    </div>

                    {upcomingWebinars.length > 11 && !isCompleted ? (
                        <div className="upcoming-webinars-load-more-container">
                            <button className="upcoming-webinars-load-more-button" onClick={() => loadMore(pageNumber, pageSize)}>
                                {loading ? (<Spinner color='primary'/>) : ("Load More")}
                            </button>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </ContinuingEducationLayout>
    )
}

export default UpcomingWebinars;