import {LessonContentType} from "../contentTypes/LessonContentType";
import {ITextContent} from "../contentTypes/TextContent";
import {IVideoContent} from "../contentTypes/VideoContent";
import {IMultipleChoiceContent} from "../contentTypes/MultipleChoiceContent";

export interface ILessonContent {
    id:string
    lessonId: string 
    name: string
    description: string 
    incorrectText:string
    order: number 
    completion: string 
    type: LessonContentType | null
    videoProperties: IVideoContent | null
    multipleChoiceProperties: IMultipleChoiceContent | null
    textProperties: ITextContent | null
    createdAt: Date| string| undefined | null;
    updatedAt: Date| string| undefined | null;
}

export class LessonContent implements ILessonContent {
    constructor(lessonContent:ILessonContent = {
        id:'',
        lessonId: '',
        name: '',
        description: '',
        order: 0,
        completion: '',
        incorrectText:'',
        type: null,
        videoProperties: null,
        multipleChoiceProperties: null,
        textProperties: null,
        createdAt: new Date(),
        updatedAt: new Date()
    }) {
        this.id = lessonContent.id;
        this.lessonId = lessonContent.lessonId;
        this.name = lessonContent.name;
        this.description = lessonContent.description;
        this.order = lessonContent.order;
        this.completion = lessonContent.completion;
        this.type = lessonContent.type;
        this.videoProperties = lessonContent.videoProperties;
        this.multipleChoiceProperties = lessonContent.multipleChoiceProperties;
        this.textProperties = lessonContent.textProperties;
        this.incorrectText = lessonContent.incorrectText;
        this.createdAt = lessonContent?.createdAt;
        this.updatedAt = lessonContent?.updatedAt;
    }

    id= '';
    lessonId = '';
    name = '';
    description = '';
    order = 0;
    completion = '';
    incorrectText='';
    type : LessonContentType | null = null;
    videoProperties : IVideoContent | null = null;
    multipleChoiceProperties : IMultipleChoiceContent | null = null;
    textProperties : ITextContent | null = null;
    createdAt: Date | string | undefined | null = new Date();
    updatedAt: Date | string | undefined | null = new Date();    
}

/**
 * Returns whether or not that passed in object is a lesson content
 * @param module 
 */
 export function IsInstanceOfLessonContent(lessonContent:ILessonContent) {
    
    //if the property name changes or if another object has the same property this will break. 
    return "lessonId" in lessonContent;
}