import React from "react";

export type AttendanceLabelProps = {
    idx:number
}

const PresentLabel = (props:AttendanceLabelProps) => (    
    <label className="attendance-label present-label" htmlFor={`present-${props.idx}`}>
        <i className="present-icon bi bi-person-check"></i> 
    </label>
)

export default PresentLabel;

