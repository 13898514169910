import { AxiosError } from 'axios';
import moment from "moment/moment";
import { ContentCategory, IContentCategory } from '../models/AdditionalVideoContent/ContentCategory';
import { ILesson, Lesson } from "../models/lesson/Lesson";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { ContentCategoryService } from '../services/ContentCategoryService';
import { LessonService } from "../services/LessonService";
import { privateContentCategoryId } from './ContentCategoryController';

export class LessonController {
    constructor(){
        this.lessonService = new LessonService();
        this.contentCategoryService = new ContentCategoryService();
    }

    private lessonService:LessonService;
    private contentCategoryService:ContentCategoryService;
    
    public async CreateLesson(lessonData: ILesson) {
        try{                    
            lessonData.createdAt = moment(new Date()).toISOString();
            lessonData.updatedAt = moment(new Date()).toISOString();
            const lesson = await this.lessonService.Post(lessonData);
            //TODO create specific post that will update lesson last mod
            // await this.UpdateLessonLastModified(lesson.id);
            return lesson;
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    public async GetLesson(id:string): Promise<ILesson> {
        try{
            return await this.lessonService.Get({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    public async GetLessons() {
        try{
            return this.lessonService.GetAll();
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    public async GetTotalQuizQuestions(lessonId:string) {
        try {
            return this.lessonService.GetTotalQuizQuestions(lessonId)
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    public async UpdateLesson(lessonData: ILesson) {
        try{                   
            lessonData.updatedAt = moment(new Date()).toISOString();

            //we need to do this check even if it is not a quiz because of combo lessons
            const totalQuestions = await this.GetTotalQuizQuestions(lessonData.id);
            
            lessonData.totalQuestions = totalQuestions;

            const updatedLesson = await this.lessonService.Put({id:lessonData.id},lessonData);
            // await this.UpdateLessonLastModified(updatedLesson.id);
            return updatedLesson;
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    /**
     * This cascades and updates the lesson's parent module and the module's parent module updated/last modified field  
     * @param lesson 
     * @returns 
     */
    public async UpdateLessonLastModified(id:string) {
        try {
            return await this.lessonService.UpdateLessonLastModified(id);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    /**
     * Updates lessons and the private contentCategory when replacing a tagId 
     * in the lesson. When the tagId is replaced we must delete the old tag from 
     * the private contentCategory in order to free up that tagId 
     * @param lesson 
     * @returns 
     */
    public async UpdateLessonAndContentCategory(lesson:ILesson) {        
        const lessonId = lesson.id;

        try {                        
            const oldSavedLesson = await this.GetLesson(lessonId);

            const oldTag = oldSavedLesson.tagList[0];

            const replacementTag = lesson.tagList[0];   
            
            //we need to do this check even if it is not a quiz because of combo lessons
            const totalQuestions = await this.GetTotalQuizQuestions(lessonId);             
            
            lesson.totalQuestions = totalQuestions;

            //If we don't have an old tag just update the lesson and leave. Or if the replacementTag and the oldTag are the same just update and leave
            if(!oldTag || replacementTag && replacementTag._id === oldTag._id) {
                return this.UpdateLesson(lesson);            
            }
            
            let contentCategory:IContentCategory = await this.contentCategoryService.Get({id:privateContentCategoryId});

            //removing the old content tag from the list
            contentCategory.tags = ContentCategory.filterTagList(contentCategory, oldTag);   
            
            //Adding the new tag
            contentCategory.tags.push(replacementTag);

            lesson.updatedAt = moment(new Date()).toISOString();
            
            //Updating both contentCategory and the lesson
            const response = await Promise.all<[ContentCategory, ILesson]>(
                [
                    await this.contentCategoryService.Put({id: privateContentCategoryId}, contentCategory),

                    await this.UpdateLesson(lesson)
                ]
            )            

            //_ means discard which means we are not using
            const [ _, updatedLesson] = response;            

            return updatedLesson;

        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    /**
     * Deletes a lesson and remove its tagId from the Private content category
     * @param lesson 
     */
    public async DeleteLessonAndTagInContentCategory(lesson:ILesson) {
        try {
            const tagId = lesson.tagList[0];

            //If no tagId the just delete and return
            if(!tagId) {
                return this.DeleteLesson(lesson.id)
            }

            let contentCategory:IContentCategory = await this.contentCategoryService.Get({id:privateContentCategoryId});

            //removing the old content tag from the list
            contentCategory.tags = ContentCategory.filterTagList(contentCategory, tagId);                            
            
            //Updating both contentCategory and the lesson
            await Promise.all<void>(
                [
                    await this.contentCategoryService.Put({id: privateContentCategoryId}, contentCategory),
                    await this.DeleteLesson(lesson.id)
                ]
            )    

        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async DeleteLesson(id:string) {
        try{
            return this.lessonService.Delete({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    // public async GetPartialTreeViewLessonsByParentId(id:string): Promise<PartialModuleTreeModel[]>{
    //     try{
    //         const lessons:ILesson[] =await this.lessonService.GetLessonsWithParentId({id});
    //         const partials = lessons.map((lesson)=>
    //             ({
    //                 id: lesson.id,
    //                 originalDocumentId:lesson.id,
    //                 name : lesson.name,
    //                 isOpen: false,
    //                 children:[],
    //                 description: lesson.description,
    //                 order: lesson.order,
    //                 type: convertToPartialModuleEnum(true,lesson.type),
    //                 isComplete: lesson.IsComplete ? lesson.IsComplete : false,
    //                 isViewable: lesson.isViewable ? lesson.isViewable : false,
    //                 isOptional: lesson.IsOptional ? lesson.IsOptional : false,
    //                 isEditable: false,
    //                 isDisabled: lesson.IsDisabled ? lesson.IsDisabled : false,
    //                 isNext: lesson.IsNext ? lesson.IsNext : false,
    //                 time:lesson.duration
    //             }));
    //
    //         return partials;
    //     }catch(error){
    //         return ErrorHandler.catchApiError((error as AxiosError))
    //     }
    // }
    //
    // public async GetAdminTreeLessonByParentId(id:string): Promise<IAdminTreeModule[]> {
    //     try {
    //         const lessons:ILesson[] = await this.lessonService.GetLessonsWithParentId({id});
    //
    //         return lessons.map(lesson => new AdminTreeModule({
    //             ...lesson,
    //             type: convertToPartialModuleEnum(true, lesson.type),
    //             parentId: lesson.branchId,
    //             children: [],
    //             duration: lesson.duration,
    //             isViewable:lesson.isViewable ? lesson.isViewable : false
    //         }))
    //
    //     } catch (error) {
    //         return ErrorHandler.catchApiError((error as AxiosError))
    //     }
    // }
    //
    // public async GetPartialLessonsByBranchId(id:string) : Promise<PartialModuleType[]>{
    //     try{
    //         const lessons: ILesson[] = await this.lessonService.GetLessonsWithParentId({id});
    //         const partials = lessons.map((lesson)=>
    //             ({
    //                 id: lesson.id,
    //                 name: lesson.name,
    //                 description: lesson.description,
    //                 isLesson:true,
    //                 order: lesson.order
    //             }))
    //
    //         return partials;
    //
    //     }catch(error){
    //         return ErrorHandler.catchApiError((error as AxiosError))
    //     }
    // }
    public async GetLessonsWithParentId(id:string) {
        try{
            return this.lessonService.GetLessonsWithParentId({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async CompleteUserLesson(id:string) {
        try
        {
            return await this.lessonService.CompleteUserLesson({id:id})
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async GetAdditionalVideosForLessonId(id:string){
        try
        {
            return await this.lessonService.GetAdditionalVideosForLesson({id:id});        
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    public async GetPartialLessons(): Promise<{id:string, name:string}[] | never>{
        try{
            const lessons:ILesson[] = await this.GetLessons();
            const partialLessons = lessons.map((lesson)=>({id:lesson.id, name:lesson.name}))
            return partialLessons;
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
}