import * as React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

// import NavMenu from './PublicPages/NavMenu';
import NavMenu from './PublicPages/NavigationMenu/NavMenu';

const Layout = (props:{ children?: React.ReactNode }) => {
    const match = useRouteMatch();
    const location = useLocation();
    const noLayout = ['/logout'];
        
        return (
            <React.Fragment>
                <>
                {
                    noLayout.indexOf(location.pathname) <= -1 ? 
                    (
                        <NavMenu />

                    )
                    :
                    (
                        <></>
                    )
                }                    
                    {props.children}                                    
                </>
            </React.Fragment>
        );
}

export default Layout;