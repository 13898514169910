import { AxiosError } from "axios";
import { ICurrentUser, IUserBio, IUserBioWithReferral, IUserProfile } from "../models/module/UserProfile";
import { PasswordTokenUpdateRequest } from "../models/requests/PasswordTokenUpdateRequest";
import { PasswordUpdateRequest } from "../models/requests/PasswordUpdateRequest";
import { MyProfileDTO } from "../models/User/MyProfileDTO";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { BaseReactService } from "./interfaces/BaseReactService";

export class UserService extends BaseReactService {

    constructor() {
        super("UserProfile/");
    }

    /**
     * Logins in a users
     * @param username
     * @param password
     * @returns A token or errors
     */
    public async Login(username: string, password: string): Promise<ICurrentUser | never> {
        const endpoint = 'UserProfile/Authenticate';
        try {
            const params = {username, password};
            return await this.Post(params, endpoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Sends a request to the API to logout the user
     * @returns 
     */
    public async Logout() {
        const endpoint = 'UserProfile/Logout';

        try {
            return await this.Post({}, endpoint);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Creates a new user with the professional role
     * @param user 
     * @param testCode 
     * @returns 
     */
    public async CreateProfessional(user: IUserProfile, testCode: string) {
        const endPoint = `UserProfile/ProfessionalsSignUp?testCode=${testCode}`;

        try {
            return await this.Post(user, endPoint);
        } catch (error) {            
            throw error;
        }
    }

    /**
     * Check status of the current user to see if the user is still logged in
     * @returns
     */
    public async CheckUserStatus(): Promise<IUserProfile> {
        const endPoint = 'UserProfile/CheckStatus';

        try {
            return await this.CustomGet({}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
   /**
     * Sends API request to check if email is in use
     * @param email 
     * @returns 
     */    
   public async CheckIfEmailIsInUse(email:string) {
        const endPoint = "UserProfile/CheckIfEmailIsInUse";
    
        return await this.CustomGet({email},endPoint);
    }

        /**
     * Sends API request to check if username is in use
     * @param email 
     * @returns 
     */        
    public async CheckIfUsernameIsInUse(username:string) {
        const endPoint = "UserProfile/CheckIfUsernameIsInUse";

        return await this.CustomGet({username}, endPoint);
    }

    /**
     * Gets user's bio information
     * @param userID
     * @returns
     */
    public async GetUserBio(userID: string): Promise<IUserBio> {
        const endPoint = 'UserProfile/GetUserBio/';

        try {
            return await this.Get({id: userID}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get all user bio info
     * @returns
     */
    public async GetAllUserBio(): Promise<IUserBioWithReferral[]> {
        const endPoint = 'UserProfile/GetUserBio/';

        try {
            return await this.GetAll(endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async GetUserByUsername(username: string): Promise<IUserProfile> {
        const endpoint = 'UserProfile/GetUserByUsername';
        try {
            return await this.CustomGet({username}, endpoint)
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * A list of consultants
     */
    public async GetConsultants() {
        const endPoint = 'UserProfile/GetConsultants';
        try {
            return await this.GetAll(endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Updates a user bio information
     * @param userBio 
     * @returns 
     */
    public async UpdateUserBio(userBio: IUserBio) {
        const endpoint = 'UserProfile/UpdateUserBio/';
        try {
            const id = userBio.id
            return await this.Put({id}, userBio, endpoint);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Admin route for creating a new user
     */
    public async AdminCreateUserProfile(newUser:IUserProfile) {
        const endPoint = "UserProfile/AdminCreateUserProfile";
        try {
            return await this.Post(newUser, endPoint);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Admin route for update a user's profile
     * Note: can only update certain profiles
     */
    public async AdminUpdateUserProfile(userToUpdate: IUserProfile) {
        const endpoint = 'UserProfile/AdminUpdateUserProfile/';
        try {
            const id = userToUpdate.id
            return await this.Put({id}, userToUpdate, endpoint);
        } catch (error) {
            throw error;
        }
    }


    /**
     * Updates an individual user's password
     * @param id
     * @param pwdUpdateReq
     */
    public async UpdateUserPassword(id: string, pwdUpdateReq: PasswordUpdateRequest) {
        const endPoint = 'UserProfile/UpdatePassword/';
        try {
            return await this.Put({id}, pwdUpdateReq, endPoint);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Get a user's username by email
     * @param email
     */
    public async GetUsernameByEmail(email: string) {
        const endPoint = 'UserProfile/RequestUsername/';
        try {
            return await this.CustomGet({email}, endPoint);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Get a user's username by email for password reset
     * @param email
     */
    public async GetUsernameByEmailForPassword(email: string) {
        const endPoint = 'UserProfile/SendResetRequest/';
        try {
            return await this.CustomGet({email}, endPoint);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Get the amount of tokens a user has
     * @param id 
     */
    public async GetUserTokens(id:string) {
        const endPoint = "UserProfile/GetUserTokenAmount/";

        try {
            return await this.Get({id}, endPoint);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Update a user's password with password token
     * @param activePasswordToken
     */
    public async UpdatePasswordWithToken(activePasswordToken: PasswordTokenUpdateRequest) {
        const endPoint = 'UserProfile/UpdatePasswordWithToken/';
        try {
            return await this.Post( activePasswordToken, endPoint);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Updates a user's VisitedSocialConnection property
     * @param id
     * @returns void
     */
    public async UpdateVisitedSocialConnection(id: string) {
        const endPoint = 'UserProfile/HasVisitedSocialConnection/';
        try {
            return await this.CustomGet({id}, endPoint);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Get all users bio information and the user current lesson, and their referral profile
     */
    public async GetUserProgressAndReferral() {
        const endPoint = 'UserProfile/GetUserProgressAndReferral';
        try {
            return await this.CustomGet({}, endPoint);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Ends the user testing phase and removes the test role from the user
     * @param userId 
     * @returns 
     */
    public async EndUserTestingPhase(userId:string) {
        const endPoint = 'UserProfile/EndUserTestingPhase';
        try {
            return await this.CustomPatch({userId}, endPoint);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Update user with the specific fields in my profile
     * @param id 
     * @param myProfile 
     * @returns 
     */
    public async UpdateMyProfile(id:string, myProfile:MyProfileDTO) {
        const endPoint = 'UserProfile/UpdateMyProfile/'
        try {
            return await this.PatchWithBody({id}, myProfile, endPoint);
        } catch (error) {
            throw error;
        }
    }
}