import * as React from 'react';
import {ReactComponent as TrashIcon} from '../../../../resources/images/icon-feather-trash-2.svg';

type ContentTagProps = {
    name?:string
    index?:number
    updateContentCategoryTags:(name:string) => void
    editContentCategoryTags:(index:number, name:string) => void
    deleteContentCategoryTag:(index:number) => void
}

type ContentTagState = {
    name:string
    isEditing:boolean
}

class ContentTagForm extends React.Component<ContentTagProps, ContentTagState> {
    private defaultState : ContentTagState = 
    { 
        name: this.props.name ? this.props.name : "",
        isEditing:false
    }

    /**
     *
     */
    constructor(props:any) {
        super(props);
        this.state = this.defaultState
    }

    private onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const inputName = e.currentTarget.name;
        const inputValue = e.currentTarget.value;

        this.setState(prevState => ({
            ...prevState,
            [inputName]: inputValue
        }));
    }

    private toggleEditing = () => {
        this.setState(prevProps => ({
            ...prevProps,
            isEditing: !this.state.isEditing
        }));
    }

    private onContentTagSubmit = (e:React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "Enter") {
            e.preventDefault();
            this.props.updateContentCategoryTags(this.state.name);
            this.setState(this.defaultState);
        }
    }

    private onEditSubmit = (e:React.KeyboardEvent<HTMLInputElement>, index:number) => {
        if(e.key === "Enter") {
            e.preventDefault();
            this.props.editContentCategoryTags(index, this.state.name);
            this.toggleEditing();
        }
    }


    public render() {
        let index = this.props.index ? this.props.index : 0;

        const {name} = this.state;
        return (
            <>
            { this.props.name ?  
            (
                <div>                            
                {
                    this.state.isEditing ? 
                    (
                        <input                
                            className='inline-module-input'
                            autoFocus
                            name="name" 
                            type="text" 
                            id="name" 
                            placeholder={"Enter a Tag name"} 
                            value={name} 
                            onKeyPress={(e) => this.onEditSubmit(e, index)} 
                            onChange={this.onChange}  
                            onBlur={this.toggleEditing}
                        />

                    ) 
                    : 
                    (
                        <div className='tag-name-container'>
                            <p onClick={this.toggleEditing}>
                                {name}
                            </p>
                            <span onClick={() => this.props.deleteContentCategoryTag(index)}>
                                <TrashIcon />
                            </span>
                        </div>
                    )
                }
                </div>
            )
            :
            (
                <div className="category-input-container">
                <input                
                    className='inline-module-input'
                    autoFocus
                    name="name" 
                    type="text" 
                    id="name" 
                    placeholder={"Enter a tag name"} 
                    value={name} 
                    onKeyPress={this.onContentTagSubmit} 
                    onChange={this.onChange}  
                />
                </div>
            )}

            </>
        )
    }
    
}

export default ContentTagForm;