import React, {useState, useEffect} from "react";
import { PartialModuleTreeModel} from "../../../../models/partialModels/PartialModuleTreeModel";
import IconLessonGroup from '../../../../resources/icons/icon-lesson-group.png';
import  IconCaretLeft from '../../../../resources/icons/icon-caret-left.png';
import  IconCaretDown from '../../../../resources/icons/icon-caret-down.png'; 
import { AccordionHelper } from "../../../../models/utility/AccordionHelper";
import { AdminBranchState } from "./AdminBranchLevelOne";

interface contextAwareProps {
    module: any,
    children: any,    
    callback: any,    
    parentModule:PartialModuleTreeModel,
    selectedModuleId:string
}

export const AdminBranchLevelTwo: React.FC<contextAwareProps> = (props) =>
{
    const { module, children, callback, parentModule, selectedModuleId} = props;

    const defaultState:AdminBranchState = {
        isOpen: false,
        isSelected: false,
        totalLessons: 0
    }    
    
    const [state, setState] = useState(defaultState);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        handleAccordion();
    }, [selectedModuleId])

    const handleAccordion = () => {
        if(selectedModuleId === module.id) {
            setState(prevState => ({
                ...prevState,
                isSelected:true,
                isOpen:true
            }));

            AccordionHelper.setCollapseOrShowAccordion(false, module.id);

            return;
        }

        if((AccordionHelper.checkIfChildIsSelected(parentModule, module.id, selectedModuleId))) {
            setState(prevState => ({
                ...prevState,
                isSelected:false,
                isOpen:true
            }));
            AccordionHelper.setCollapseOrShowAccordion(false, module.id);
            return;
        }

        setState(prevState => ({
            ...prevState,
            isSelected:false,
            isOpen:false
        }));

        AccordionHelper.setCollapseOrShowAccordion(true, module.id)
    }

    const handleNavigationClick = (e:React.MouseEvent<HTMLElement>) => {        
        e.stopPropagation();

        setState(prevState => ({
            ...prevState,
            isOpen:!state.isOpen,
            isSelected:true
        }));

        AccordionHelper.toggleAccordion(module.id);

        callback();                
    }

    const addIconCaretClass = () => {
        return state.isOpen ? "admin-caret-down" : "admin-caret-left"
    }

    /**
     * Updates the isMobile state based on the window size
     */
    const updateMobileStatus = () => {
        if (window.innerWidth <= 1023) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    /**
     * Updates the isMobile state on component mount
     * and adds a listener to update the isMobile state
     * on window resize
     */
    useEffect(() => {

        if (window !== undefined) {
            updateMobileStatus();

            window.addEventListener("resize", updateMobileStatus);
        }

        return () => window.removeEventListener("resize", updateMobileStatus);

    }, [isMobile, window.innerWidth]);

    return(
        <>
            {
                state.isSelected ? 
                (
                    <div className='admin-lesson-group-item admin-selected' onClick={handleNavigationClick}>
                    <div className='admin-lesson-content'>                                                
                        <div className='lesson-type-icon'>
                            <img src={IconLessonGroup} alt="lesson group icon"/>
                        </div>
                        <div className='admin-lesson-text'>
                            <b>{children}</b>
                        </div>

                        <div className='chapter-lesson-amount'>
                            <span>{`(${module.children.length})`}</span>
                        </div>

                        {isMobile ? (
                            <div className={`admin-icon-container mobile ${addIconCaretClass()}`}>
                                {
                                    state.isOpen ?
                                        (
                                            <img src={IconCaretDown} alt="icon caret down" />

                                        )
                                        :
                                        (
                                            <img src={IconCaretLeft} alt="icon caret Left" />
                                        )
                                }
                            </div>
                        ) : (
                            <div className={`admin-icon-container ${addIconCaretClass()}`}>
                                {
                                    state.isOpen ?
                                        (
                                            <img src={IconCaretDown} alt="icon caret down" />

                                        )
                                        :
                                        (
                                            <img src={IconCaretLeft} alt="icon caret Left" />
                                        )
                                }
                            </div>
                        )}

                    </div>
                </div>
                )
                : 
                (
                    <div className='admin-lesson-group-item' onClick={handleNavigationClick}>
                        <div className='admin-lesson-content'>
                            <div className='lesson-type-icon'>
                                <img src={IconLessonGroup} alt="lesson group icon"/>
                            </div>
                            <div className='admin-lesson-text'>
                                {children}
                            </div>
                            <div className="chapter-lesson-amount">
                                {`(${module.children.length})`}
                            </div>

                            {isMobile ? (
                                <div className={`admin-icon-container mobile ${addIconCaretClass()}`}>
                                    {
                                        state.isOpen?
                                            (
                                                <img src={IconCaretDown} alt="icon caret down" />

                                            )
                                            :
                                            (
                                                <img src={IconCaretLeft} alt="icon caret Left" />
                                            )
                                    }
                                </div>
                            ) : (
                                <div className={`admin-icon-container ${addIconCaretClass()}`}>
                                    {
                                        state.isOpen?
                                            (
                                                <img src={IconCaretDown} alt="icon caret down" />

                                            )
                                            :
                                            (
                                                <img src={IconCaretLeft} alt="icon caret Left" />
                                            )
                                    }
                                </div>
                            )}

                        </div>
                </div>
                )
            }
        </>
    );

}

