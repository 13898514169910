import React, { useEffect, useMemo, useRef, useState } from "react";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import * as L from 'leaflet';
import { IReferral } from "../../../models/Referral/Referral";
import 'leaflet/dist/leaflet.css';
import "./ReferralUserMap.css";

type ReferralUserMapProps = {
    referral:IReferral
}

const ReferralUserMap = (props:ReferralUserMapProps) => {
    const {referral} = props;    
    
    let map:null|L.Map = useMemo(() => (null), [referral]) 

    const tileLayer = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';
    const attribution = '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
    
    const mapRef = useRef<HTMLDivElement>(null);

    const mapId = "referralUserMap";
    
    useEffect(() => {
        function onStatesDataChange() {            
            createTileLayer();
        }        
        
        onStatesDataChange();        
    },[referral])    

    /**
     * Creates the tile layer for the leaflet map.
     * We are using a ref here to make sure that the div is 
     * rendered before we create the tiles    
     * @returns 
     */
    const createTileLayer = () => {                
        if(!mapRef.current) {
            return;
        }         
        
        if(map != null) {
            map.remove();
        }

        const leafletMap =  L.map(mapId, {        
            //The value does not matter, we have to set zoomSnap to a fraction in order to use the zoomSnap functionality
            zoomSnap:0.25, 
            zoomControl:false,
            dragging: !referral.isContactPrivate,
            scrollWheelZoom: !referral.isContactPrivate
        })
        .setView([referral.latitude, referral.longitude], 16);

        map = leafletMap

        L.tileLayer(tileLayer, {
            maxZoom: 19,
            attribution,            
        }).addTo(leafletMap);      

        if(referral.isContactPrivate) {
            createPrivateCircle(leafletMap)
        } else {
            createMarkers(leafletMap);         
        }
    }

    /**
     * For referrals that are private we want to draw a circle around their general area
     * @param leafletMap 
     */
    const createPrivateCircle = (leafletMap:L.Map) => {
        const {latitude, longitude} = referral;

        if(latitude === 0 || longitude === 0) {
            return;
        }

        const circle = L.circleMarker([latitude, longitude], {
            color: '#3388ff',                        
            fillColor: '#bdd7e7',                
            fillOpacity: 0.8,
            weight:2,
            radius:  45,                
        }) 
        .addTo(leafletMap) 
    }

    const createMarkers = (leafletMap:L.Map) => {    
        
        if(referral.latitude === 0 || referral.longitude === 0) {
            return;
        }
        
        //Note: we need to set and icon size and anchor to prevent the marker from moving when zooming
        const defaultIcon = L.icon({
            iconUrl: icon,
            shadowUrl: iconShadow,
            iconSize: [24, 36],
            iconAnchor: [12, 36],
            className:"referral-map-icon"
        });    

        L.Marker.prototype.options.icon = defaultIcon;
        
        const marker = L.marker([referral.latitude, referral.longitude]).addTo(leafletMap);
    }

    return (
        <div ref={mapRef} id={mapId}></div>
    )
}

export default ReferralUserMap;