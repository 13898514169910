import * as React from 'react';
import * as UserTreeView from "../../../store/UserModuleAccordionStore";
import {UserLesson} from "../../../models/lesson/UserLesson";
import {connect} from "react-redux";
import {ApplicationState} from "../../../store";
import "./LearningContentViewer.css";
import UserLessonViewerComp from "./UserLessonViewerComp";

type UserAccordionItemProps =
    UserTreeView.currentUserAccordionProps
    & typeof UserTreeView.actionCreators

type UserLessonViewerState =
    {
        selectedUserLesson: UserLesson,
        isLoading: boolean,
        isSaving: boolean,
    }

class UserLessonViewerWrapper extends React.PureComponent<UserAccordionItemProps, UserLessonViewerState> {

    public render() {
        return (
            <>
                <UserLessonViewerComp ParentModule={this.props.parentModule}
                                      SelectedUserModule={this.props.selectedModule}
                                      SetUserAccordion={this.props.SetUserAccordion}/>
            </>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.currentUserModuleAccordion,
    UserTreeView.actionCreators
)(UserLessonViewerWrapper);