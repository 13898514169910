export default class FormHelper {
    /**
     * Removes white space from the given string
     * @param str 
     * @returns 
     */
    public static RemoveWhiteSpace = (str:string) => {
        return str.split(" ").join("");
    }

    public static maxErrorMsg = (fieldName:string, maxLen:number) => (`${fieldName} must be less than ${maxLen} characters`);
    
    public static minErrorMsg = (fieldName:string, minLen:number) => (`${fieldName} must be ${minLen} or more characters`);
    
    public static requiredErrorMsg = (fieldName:string) => (`Please provide a valid ${fieldName}`);
}