import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ModuleController } from '../../../../../controllers/ModuleController';
import { ILesson } from '../../../../../models/lesson/Lesson';
import { AdminModule, IAdminModule } from '../../../../../models/module/AdminModule';
import { IModule, Module } from '../../../../../models/module/Module';
import { AdminTreeModule, IAdminTreeModule } from '../../../../../models/partialModels/AdminTreeModule';
import { PartialModuleTreeModel } from '../../../../../models/partialModels/PartialModuleTreeModel';
import { EditorContentEnum } from '../../../../../models/utility/EditorContentEnum';
import { ApplicationState } from '../../../../../store';
import { currentUserAccordionProps } from '../../../../../store/UserModuleAccordionStore';
import ModuleItem from '../../ContentItems/ModuleItem';
import toast from 'react-hot-toast';
import { Spinner } from 'reactstrap';
import { DeepCopy } from '../../../../../models/utility/DeepCopy';
import { AdminTreeHelper } from '../../../../../models/utility/TreeHelper/AdminTreeHelper';
import { ModuleAction } from '../../../../../models/utility/UnSavedCourse/ModuleAction';
import { sortModule } from '../../../../../models/utility/SortModule';

export type AdminViewProps = {
    contentList:IAdminModule[];
    updatedContentList:(contentList:IAdminModule[]) => void;
    updateIsLoading:(isLoading:boolean) => void;    
    selectedModuleId:string
    contentType:EditorContentEnum
    navigateTo:(module:IAdminTreeModule | IModule | ILesson) => void
    isLoading:boolean
    addToAccordion:(adminTreeModule:IAdminTreeModule) => void
    updateAccordion:(adminTreeModule:IAdminTreeModule) => void
    removeAccordionItem:(id:string) => void
    onDeleteConfirm:(deleteItemName:string, deleteCallback:any) => void
    updateUnSavedContent: (adminModule:IAdminModule, action:ModuleAction) => void
    topMostModuleId:string
    parentModule:IAdminTreeModule
    selectedModule:IAdminTreeModule
    forceRerender:boolean
    isVisible:boolean
    isExpanded:boolean;
}

const moduleController = new ModuleController();

const TopModuleView = (props:AdminViewProps) => {
    const {
            contentList, 
            updatedContentList, 
            updateIsLoading, 
            selectedModuleId, 
            contentType,
            navigateTo,
            isLoading,
            updateAccordion,
            removeAccordionItem,
            onDeleteConfirm,
            updateUnSavedContent,
            addToAccordion,
            forceRerender
        } = props;

    const isLesson = useMemo(() => (
        contentType === EditorContentEnum.lesson
    ),[contentType]);

    const firstRender = useRef(true);

    useEffect(() => {
        async function onSelectedModuleChange() {
            if(firstRender.current) {
                // await getChapters();
                firstRender.current = false;
            }            
        }

        onSelectedModuleChange();
    },[]);

    /**
     * Get chapters from a top most module
     */
    const getChapters = async () => {
        updateIsLoading(true);
        
        try {
            
            const modules:IModule[] = await moduleController.GetModulesWithParentId(selectedModuleId);            
            
            sortModule(modules, false)

            const adminModules:IAdminModule[] = [];

            for(let module of modules) {
                adminModules.push(new AdminModule(module));
            }                
            
            updatedContentList(adminModules);            
                        
        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch chapters")
            
        }

        updateIsLoading(false);
        
    }

    /**
     * Adds a new chapter to the content list and updates the accordion 
     * @param adminModule 
     */
    const addChapter = (adminModule:IAdminModule) => {
        const contentListCopy:IAdminModule[] = DeepCopy.copy(contentList);

        const foundIndex = contentListCopy.findIndex(adminMod => adminMod.key === adminModule.key);        

        if(foundIndex !== -1) {
            contentListCopy[foundIndex] = adminModule;            
            
            updatedContentList(contentListCopy);

            addToAccordion(AdminTreeModule.convertModuleToAdminTreeModule(adminModule.content as Module));
            
            updateUnSavedContent(adminModule, ModuleAction.ADD);
            return;
        }

    }

    /**
     * replaces an adminModule item in the content list with an updated one
     * @param adminModule 
     * @returns 
     */
    const updateChapter = (adminModule:IAdminModule) => {
        const contentListCopy:IAdminModule[] = DeepCopy.copy(contentList);

        const foundIndex = contentListCopy.findIndex(adminMod => adminMod.content.id === adminModule.content.id);        
        if(foundIndex !== -1) {
            contentListCopy[foundIndex] = adminModule;            
            
            updatedContentList(contentListCopy);
            updateAccordion(AdminTreeModule.convertModuleToAdminTreeModule(adminModule.content as Module));

            if(adminModule.content.id === adminModule.key) {
                updateUnSavedContent(adminModule, ModuleAction.ADD);
            } else {
                
                updateUnSavedContent(adminModule, ModuleAction.UPDATE);
            }
            return;
        }

        toast.error("Failed to update Chapter")
    }

    /**
     * Removes a chapter from the content list and the accordion
     * @param adminModule 
     */
    const removeChapter = (adminModule:IAdminModule) => {
        let contentListCopy:IAdminModule[] = DeepCopy.copy(contentList);

        contentListCopy = contentListCopy.filter(adminMod => adminMod.content.id !== adminModule.content.id);  

        updatedContentList(contentListCopy);
        
        removeAccordionItem(adminModule.content.id);      
        
        updateUnSavedContent(adminModule, ModuleAction.DELETE);
    }

    /**
     * Handles the setup to render a module item
     * @param adminModule 
     * @param index 
     * @returns 
     */
    const handleModuleRender = (adminModule:IAdminModule, index:number) => {
        const {key, content, isNewModule} = adminModule;

        const contentId = content.id;

        const isNewContent = contentId === "";

        const onSubmit = content.name.length <= 0 ? addChapter : updateChapter
        return (
                <ModuleItem                        
                    parentID={selectedModuleId}
                    onDeleteConfirmSubmit={onDeleteConfirm}           
                    isLesson={isLesson}
                    contentType={contentType}
                    onSubmit={onSubmit}
                    deleteItem={() => removeChapter(adminModule)}
                    index={index}
                    content={content as IModule | ILesson }                            
                    navigateTo={navigateTo}                         
                    isEditing={isNewContent}
                    draggableID={key}
                    addingNewContent={isNewContent}
                    causeRerender={false}
                    parentsVisibility={false}
                    isNewModule={isNewModule}
                />

        )
    }

    return (
        <>                    
            {
                contentList.length > 0 ? 
                (
                    <>
                        {contentList.map((contentItem: IAdminModule, index:number) => (
                                //@ts-ignore
                                <React.Fragment key={contentItem.key}>
                                    {
                                        handleModuleRender(contentItem, index)                                                      
                                    }
                                </React.Fragment>                                                                                        
                            )
                        )}                                                                                          
                    </>
                ) 
                :
                (                                                                           
                    <div className="empty-content-list">
                        <p>There is nothing here yet</p>
                    </div>
                )
            }                    
        </>
    )

}

export default TopModuleView;