 export enum ConsultationStatus{
     None = 0,
     Active = 1,
     ActiveFull = 2,
     NeedsReview = 3,
     Completed = 4,
     Canceled = 5,
     Deleted = 6,
}

/**
 * Object for displaying user friendly text for consultation status 
 */
export const ConsultationUIStatus = {
    [ConsultationStatus.None]: "None",
    [ConsultationStatus.Active]: "Active",
    [ConsultationStatus.ActiveFull]: "Active and Full",
    [ConsultationStatus.NeedsReview]: "Needs Review",
    [ConsultationStatus.Completed]: "Completed",
    [ConsultationStatus.Canceled]: "Canceled",
    [ConsultationStatus.Deleted]: "Deleted",
}