import React from 'react';
import './ToggleButton.css';

type ToggleButtonProps = {
    isToggled:boolean
    onToggleChange:() => void
}

const ToggleButton = (props:ToggleButtonProps) => {

    const {isToggled, onToggleChange} = props;
    
    return (
        <label className="toggle-switch">
            <input 
                type="checkbox" 
                checked={isToggled}
                onChange={onToggleChange}
            /> 
               
        <span className="slider round"></span>
        </label>
    )
}

export default ToggleButton