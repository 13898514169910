export interface ITextContent {
    title:string
    subTitle:string    
    text:string
    completed:boolean
    imageId?:string | null
}

// TODO add comments
export class TextContent implements ITextContent {
    title =  '';
    subTitle = '';
    text = '';
    completed = false;
    imageId:string|null = '';
    /**
     *
     */
    constructor(textContent : ITextContent = {
        title: '',
        subTitle: '',
        text: '',
        completed:false,
        imageId: '',
    }) {
        this.title = textContent.title;        
        this.subTitle = textContent.subTitle;        
        this.text = textContent.text;       
        this.completed= textContent.completed;
        this.imageId = textContent.imageId? textContent?.imageId : null;
    }
    
}