import {idObject, _idObject} from "../AdditionalVideoContent/AdditionalVideoContent";
import {ConsultationType} from "./ConsultationType";
import {ConsultationStatus} from "./ConsultationStatus";
import { DeepCopy } from "../utility/DeepCopy";
import moment from "moment";
import { IUserBio } from "../module/UserProfile";
import { ZoomTimeZoneEnum } from "../Zoom/ZoomTimeZoneEnum";
import { StringHelper } from "../utility/StringHelper";
import TimeHelper from "../utility/TimeHelper";

export type ConsultationWithConsultant = {
    consultation:IConsultation
    consultant:IUserBio
}

export type ConsultationRegistrantLink = {
    userId:string
    registeredLink:string
}

export enum RecurrenceTypeEnum {
    daily = 1,
    weekly = 2,
    monthly =3
}

export interface IRecurrence {
    weekly_days:string
    type:RecurrenceTypeEnum
    
    //named this way because of zoom
    start_time:string
    end_date_time:string
}

export class Recurrence implements IRecurrence {
    type: RecurrenceTypeEnum = RecurrenceTypeEnum.weekly;
    weekly_days: string = "";
    start_time:string = "";
    end_date_time:string = "";
    /**
     * converts the weeklyDays string to a list of days of the week
     * and removes any NaN
     * @returns 
     */
    static convertWeeklyDaysToDaysOfWeeList(recurrence:Recurrence) {
        const weeklyDays = recurrence.weekly_days;
        let listOfDays = weeklyDays.split(",").map(day => parseInt(day));
        listOfDays = listOfDays.filter(day => !isNaN(day));
        return listOfDays;
    }
}

export interface IConsultation
{
    id:string
    name:string
    description:string
    ownerUserProfileId:string
    maxNumberOfAttendees:number
    attendeeList: _idObject[]
    type: ConsultationType
    status: ConsultationStatus
    startDate: Date|string|null
    endDate: Date|string|null
    deletedAt: Date|string|null
    link: string
    guestLink:string
    zoomMeetingLinkId:number
    timeZone:string
    userLinks:ConsultationRegistrantLink[]
    occurrenceId:string,
    recurrence:Recurrence,
    recordingLink:string
}

export class Consultation implements IConsultation {
    id:string;
    name:string;
    description: string;
    ownerUserProfileId:string;
    maxNumberOfAttendees: number;
    attendeeList: _idObject[];
    type: ConsultationType;
    status: ConsultationStatus;
    startDate: Date|string|null;
    endDate: Date|string|null;
    deletedAt: Date|string|null;
    link: string;
    guestLink: string;
    zoomMeetingLinkId: number;
    timeZone: string;
    userLinks: ConsultationRegistrantLink[];
    occurrenceId:string;
    recurrence: Recurrence;
    recordingLink:string;

    constructor(data: IConsultation) 
    {
        this.id = data.id;
        this.name = data.name;
        this.description = data.description;
        this.ownerUserProfileId = data.ownerUserProfileId;
        this.maxNumberOfAttendees = data.maxNumberOfAttendees;
        this.attendeeList = data.attendeeList;
        this.type = data.type;
        this.status = data.status;
        this.startDate = data.startDate as string;
        this.endDate = data.endDate as string;
        this.deletedAt = data.deletedAt;
        this.link = data.link;
        this.guestLink = data.guestLink;
        this.zoomMeetingLinkId = data.zoomMeetingLinkId;
        this.timeZone = data.timeZone;
        this.userLinks = data.userLinks;
        this.occurrenceId = data.occurrenceId;
        this.recurrence = data.recurrence;
        this.recordingLink = data.recordingLink;
    }

    /**
     * Find a specific consultation in a list of consultations by id
     * and returns a deep copy
     */
    static FindConsultation = (consultations:IConsultation[], id:string) => {
        const consultationsCopy:IConsultation[] = DeepCopy.copy(consultations);

        const foundIndex = consultationsCopy.findIndex(consultation => consultation.id === id);

        if(foundIndex === -1)
            return null;

        return consultationsCopy[foundIndex];
    }

    /**
     * Sorts a list of consultations by time in
     * @param consultations 
     */
    static SortConsultationsByTime = (consultations:IConsultation[], desc:boolean = false) => {        
        return consultations.sort(function(a:IConsultation, b:IConsultation) {
            if(desc) {
                return moment(a.startDate as string).isBefore(moment(b.startDate as string)) ? 1 : -1;
            }
            return moment(a.startDate as string) < (moment(b.startDate as string)) ? -1 : 1;
            
        })
    }

    /**
     * Get the remaining amount of space left for consultation
     * @param consultation 
     * @returns 
     */
    static GetSpaceRemaining = (consultation:IConsultation) => {
        const maxAttendees = consultation.maxNumberOfAttendees;
        const currentAmountOfAttendees = consultation.attendeeList.length;

        if(currentAmountOfAttendees > maxAttendees)
            return 0;

        return maxAttendees - currentAmountOfAttendees;
    }

    /**
     * Check if a user have booked this consultation or not
     * @param userId 
     */
    static CheckIfConsultationIsBookedByUser = (consultation:IConsultation, userId:string) => {
        const foundIndex = consultation.attendeeList.findIndex(attendee => attendee._id === userId);

        return foundIndex !== -1;
    }

    /**
     * Get a default timezone based on the users browser
     */
    static GetDefaultTimeZone = () => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;        

        const isValidTimeZone = Object.values(ZoomTimeZoneEnum).includes(timeZone as ZoomTimeZoneEnum);

        if(!isValidTimeZone) return "";

        const index = Object.values(ZoomTimeZoneEnum).indexOf(timeZone as unknown as ZoomTimeZoneEnum);

        const key = Object.keys(ZoomTimeZoneEnum)[index];
        
        return ZoomTimeZoneEnum[key as keyof typeof ZoomTimeZoneEnum];
    }

    /**
     * Filter through a consultations userLinks and 
     * get back a specified users registered link
     */
    static GetUsersRegisteredLink = (consultation:Consultation, userId:string) => {        
        if(!Array.isArray(consultation.userLinks)) {
            return "";
        }
        const foundLinkObj = consultation.userLinks.find(regLinkObj => regLinkObj.userId === userId);

        if(foundLinkObj == null) return "";

        return  foundLinkObj.registeredLink ? foundLinkObj.registeredLink : "";
    }


    /**
     * Get the users zoom link. If the user is an attendee then we need the registrant link.
     * Otherwise we need the main zoom link for the consultant
     * @returns 
     */
    static GetSessionsZoomLink = (userId:string, consultation:IConsultation) => {
        if(userId.length <= 0 || !consultation) return "";             

        if(userId === consultation.ownerUserProfileId) {
            const consultantsLink = consultation.link;
            
            return consultantsLink ? consultantsLink : "";
        } 

        return Consultation.GetUsersRegisteredLink(consultation, userId);
    }

    static GetConsultationTime = (consultation:Consultation) => {
        if(!consultation.startDate || !consultation.endDate){
            return "";
        }
       const [start, end] = Consultation.HandleDstWithConsultationDate(consultation);
        return `${moment(start).format("LT")} - ${moment(end).format("LT")}`
    }

    
    /**
     * Handles the DST issue for recurring consultations
     * @param consultation 
     */
    static HandleDstWithConsultationDate = (consultation:IConsultation) => {        
        if(StringHelper.IsNullOrWhiteSpace(consultation.occurrenceId)) {
            return [consultation.startDate, consultation.endDate]
        }
        
        const {start_time} = consultation.recurrence
        
        let dstHandledStart = TimeHelper.HandleDateDST(consultation.startDate as string, start_time);
        let dstHandleEnd = TimeHelper.HandleDateDST(consultation.endDate as string, start_time);

        return [dstHandledStart, dstHandleEnd];        
    }

}

export const emptyConsultation:IConsultation = {
    id: "",
    name: "",
    description: "",
    ownerUserProfileId: "",
    maxNumberOfAttendees: 0,
    attendeeList: [],
    type: ConsultationType.Unknown,
    status: ConsultationStatus.None,
    startDate: null,
    endDate: null,
    deletedAt: null,
    link: "",
    timeZone: "",
    guestLink: "",
    zoomMeetingLinkId: 0,
    userLinks: [],
    occurrenceId: "",
    recurrence: new Recurrence(),
    recordingLink: "",
}

export type UiConsultation = {
    id:string,
    name:string,
    consultantName:string,
    type: ConsultationType,
    availableSlots:number,
    startDate:string,
    endDate:string,
    currentAttendees:string[],
    link:string
}