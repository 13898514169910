import React, {useEffect, useState} from "react";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import NavSidebar from "./NavSidebar/NavSidebar";

type SocialConnectionLayoutProps = {
    children: React.ReactNode;
}

const SocialConnectionLayout = ({children}: SocialConnectionLayoutProps) => {
    const [isNavOpen, setIsNavOpen] = useState(window.innerWidth > 1023 ? true : false);
    const {height, width} = useWindowDimensions();

    const toggle = () => {
        setIsNavOpen(!isNavOpen);
    }

    useEffect(() => {
        // If the width of the device is greater than 1023px, open the navigation panel
        if (width > 1023) {
            setIsNavOpen(true);
        }

        // If the width of the device is less than 1023px, close the navigation panel
        if (width <= 1023) {
            setIsNavOpen(false);
        }

    }, [width]);

    return (
        // Layout container
        <div className={`social-connection-layout ${isNavOpen ? 'isOpen' : ''}`}>
            {/* Navigation Panel */}
            <div className="AccordionContent">
                <NavSidebar toggle={toggle}
                            isNavOpen={isNavOpen}
                            setIsNavOpen={setIsNavOpen}
                            title={'Social Connection'}
                            links={[
                                {
                                    name: 'Discussion Board',
                                    path: '/social-connection'
                                },
                                {
                                    name: 'My Posts',
                                    path: '/social-connection/my-posts'
                                },
                                {
                                    name: 'FAQs',
                                    path: '/social-connection/faqs'
                                },
                            ]}
                            adminLinks={[
                                {
                                    name: 'Admin',
                                    path: '/social-connection/admin'
                                }
                            ]}
                />
            </div>
            {/* Main content container */}
            <div className={`social-connection-main-content`}>
                {children}
            </div>
        </div>
    )
}

export default SocialConnectionLayout;