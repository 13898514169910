import { ILesson, Lesson } from "../lesson/Lesson"
import { ILessonContent, IsInstanceOfLessonContent, LessonContent } from "../lesson/LessonContent"
import { IModule, Module } from "./Module"
import {v4 as uuidv4} from 'uuid';
import { LessonContentType } from "../contentTypes/LessonContentType";
import { _idObject } from "../AdditionalVideoContent/AdditionalVideoContent";

export type IAdminModule = {
    key:string
    content: IModule | ILesson | ILessonContent
    file?:File
    totalQuestions?:number
    uploadUrl?:string
    isNewModule?:boolean
    tagList?:_idObject[]
}

/**
 * Admin Module class for items created in the content builder
 */
export class AdminModule implements IAdminModule {
    key: string;
    content: ILesson | IModule | ILessonContent;
    file: any;
    totalQuestions: number | undefined;

    constructor(content: IModule | ILesson | ILessonContent) {
        this.key = uuidv4(); 
        this.content = content as ILesson;
    }

    /**
     * Get the amount of current questions in a content list
     * @param adminContentList 
     */
    public static GetModuleQuestionTotal = (adminModuleList: ILessonContent[]) => {
        let totalQuestions = 0;
        
        for(let content of adminModuleList) {
            // Checking if the content inside the adminModule is lesson content
            if (IsInstanceOfLessonContent(content as ILessonContent)){
                const lessContent = content as ILessonContent;

                // Updating total questions if the lesson type is multi choice or true or false
                if(lessContent.type === LessonContentType.multipleChoice || lessContent.type === LessonContentType.trueOrFalse)
                    totalQuestions += 1
            }
        }

        return totalQuestions;
    }
}

export const emptyAdminModule:IAdminModule = {
    key: "",
    content: new Module()
}