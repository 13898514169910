import {BaseReactService} from "./interfaces/BaseReactService";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import { AxiosError } from "axios";

export class UserLessonService extends BaseReactService {

    constructor(){
        super("UserLesson/");
    }
    
    public async GetByLessonId(id: { id:string }){
        const endpoint = 'UserLesson/GetByLessonId/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async UnlockUserLesson(id:{id:string}){
        const endpoint = 'UserLesson/UnlockUserLesson/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async GetUserLessonByParentModuleId(id:{id:string}){
        const endpoint = 'UserLesson/GetUserLessonByParentModuleId/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
    
    public async GetAssignedLessonForUsersByParentModuleId(id: { id:string }){
        const endpoint = 'UserLesson/GetAssignedLessonsForUserByParentModuleId/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
    /**
     * Get the users current/next lesson.
     * @param userId 
     * @param sectionId 
     * @returns 
     */
    public async GetUserNextLesson(userId:String, sectionId:string) {
        const endpoint = 'UserLesson/GetUserNextLesson';
        try {
            return this.CustomGet({userId, sectionId}, endpoint);
        } catch (error) {            
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
}