import React, { useEffect, useState } from 'react';
import { IAdminModule } from '../../../../../models/module/AdminModule';
import { IAdminTreeModule } from '../../../../../models/partialModels/AdminTreeModule';
import { EditorContentEnum } from '../../../../../models/utility/EditorContentEnum';
import { ModuleAction } from '../../../../../models/utility/UnSavedCourse/ModuleAction';
import { ReactComponent as SlashVisibilityIcon } from '../../../../../resources/images/icon-visibility-slash.svg';
import { ReactComponent as VisibilityIcon } from '../../../../../resources/images/icon-visibility.svg';
import ComboLessonEditTools from './ComboLessonEditTools';
import { ModuleController } from '../../../../../controllers/ModuleController';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../../store';
import { CurrentUserAccordionState } from '../../../../../store/UserModuleAccordionStore';
import { StringHelper } from '../../../../../models/utility/StringHelper';
import './ViewEditorTools.css';

export type ViewEditorToolsProps = {    
    onCollapseAndExpandClick: (isExpanded:boolean) => void
    contentType:EditorContentEnum                
    isAllVisible:boolean
    hasContentChanges:boolean
    updateIsAllVisible:() => void
    onSaveContent: () => void
    onPublishContent: ()=> void
    updateUnsavedContent:(adminModule:IAdminModule, action:ModuleAction) => void
    updateAccordion:(accordionModule: IAdminTreeModule) => void
}

const modController = new ModuleController();

const ViewEditorTools = (props:ViewEditorToolsProps) => {
    const {            
            onCollapseAndExpandClick,
            contentType,                        
            isAllVisible,
            hasContentChanges,
            updateIsAllVisible,
            onSaveContent,
            onPublishContent,
            updateAccordion,
            updateUnsavedContent
    } = props;    

    const accordionStore = useSelector<ApplicationState, CurrentUserAccordionState | undefined>(state => state.currentUserModuleAccordion);
    
    const [canPublish, setCanPublish] = useState(false);

    /**
     * Decides if the admin view tools should be shown or not
     * They should only be shown on chapters, lesson groups, and quizzes.
     */
    const showAdminViewTools = () => {
        if(contentType === EditorContentEnum.lessonContent || contentType === EditorContentEnum.parentModule)
            return false;
        else 
            return true;
    }

    useEffect(() => {
        async function onComponentMount() {
            await checkIfTopModuleHaveChanges();
        }
        onComponentMount();
    },[]);

    const checkIfTopModuleHaveChanges = async () => {
        
        if(!accordionStore) {
            return;
        }
        const id = accordionStore.parentModule.id;

        if(StringHelper.IsNullOrWhiteSpace(id)) {
            return;
        }
        const hasChanges = await modController.CheckModuleAndPublishedModuleChanges(id);

        setCanPublish(hasChanges);
    }

    /**
     * Saves content and calls the publish button checker
     */
    const saveContent = () => {
        onSaveContent();
        checkIfTopModuleHaveChanges();
    }

    const renderEditorBtns = () => {
        return (
            <>
            {
                !hasContentChanges && !canPublish ? 
                (
                    <div className="empty-editor-btns-container"></div>
                ) 
                :
                (
                    <div className='editor-btns-container'>
                    {
                        hasContentChanges && 
                        (
                            <button
                                onClick={saveContent}
                                // disabled={!hasContentChanges}
                                className="btn-cbit-primary btn-save-content"
                            >
                                Save
                            </button>
                        )
                    }
                    {
                        canPublish &&
                        (
                            <button
                                onClick={onPublishContent}
                                // disabled={hasContentChanges}
                                className="btn-cbit-primary btn-save-content"
                            >
                                Publish
                            </button>
                        )
                    }
                    </div>
                )
            }                

            </>
        )
    }

    return (
        <div className="admin-view-tools-container">
        {
            showAdminViewTools() ?
            (
                <>
                    <div className="admin-view-tools-left">
                        <button onClick={() => onCollapseAndExpandClick(false)} className='btn-cbit-text'>
                            Collapse all
                        </button>
                        <span className='collapse-all-separator'>
                        </span>
                        <button onClick={() => onCollapseAndExpandClick(true)} className='btn-cbit-text'>
                            Expand all
                        </button>
                    </div>
                    <div className="admin-view-tools-right">
                            <>
                                {
                                    contentType === EditorContentEnum.lesson && (
                                       <ComboLessonEditTools 
                                                updateUnsavedContent={updateUnsavedContent}
                                                updateAccordion={updateAccordion} 
                                                contentType={contentType}
                                        />
                                    )
                                }
                                <>
                                    {contentType !== EditorContentEnum.lessonQuiz && contentType !== EditorContentEnum.lesson &&  (                                        
                                    <>
                                    {
                                        isAllVisible ? 
                                        (                                        
                                            <div onClick={() => updateIsAllVisible()} className="admin-all-visibility">
                                                Visibility
                                                <span className='admin-visibility-icon'>
                                                    <VisibilityIcon/>
                                                </span>
                                            </div>                                            
                                        ) 
                                        : 
                                        (
                                            <div onClick={() => updateIsAllVisible()} className="admin-all-visibility">
                                                Visibility
                                                <span className='admin-visibility-slash-icon'>
                                                    <SlashVisibilityIcon/>
                                                </span>
                                            </div>
                                        )
                                    }
                                    </>        
                                    )}                                
                                </>
                            </>

                            {renderEditorBtns()}
                        </div>
                </>
            )
            :
            (
                <div className='admin-view-tools-right'>
                    {renderEditorBtns()}
                </div>
            )
        }
                          
        </div>
    )
}

export default ViewEditorTools;