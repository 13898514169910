import React, {FunctionComponent, useEffect, useState} from "react";
import {UserLessonContentLogController} from "../../../../../controllers/UserLessonContentLogController";
import {UserLessonContentController} from "../../../../../controllers/UserLessonContentController";
import {UserLessonContent} from "../../../../../models/lesson/UserLessonContent";

type VideoContentProps={
    userLessonContent:UserLessonContent|null
    completeCallback:any,
}

const ContentViewerQuizTextContent:FunctionComponent<VideoContentProps>=({userLessonContent,  completeCallback})=> {

    const [state, setState] = useState(new UserLessonContent())

    const userLessonContentLogController: UserLessonContentLogController = new UserLessonContentLogController();
    const userLessonContentController :UserLessonContentController = new UserLessonContentController()

    const Compelted=()=>{
        //LogCompletetion
        userLessonContentLogController.PostLog(
            {
                Choice:"Opened Quiz Text ( "+state.lessonContent.videoProperties?.videoLink+" )",
                IsCompleted:"true",
                LessonContentId:state.lessonContent.id,
                CorrectAnswer:'Completed Quiz Text',
                IsCorrect:"true",
                LessonId:state.lessonContent.lessonId,
                Timestamp:""
            })

        userLessonContentController.UpdateUserLessonContent(state)

        completeCallback();
    }

    useEffect(()=>{
        const initalSet = (setData:any) =>{
            setData(userLessonContent)

        }

        initalSet(setState)
    }, [userLessonContent])

    const textToBody=(bodyContent:string | undefined)=>{
        return(
            <>
                {bodyContent === undefined &&(
                    <></>
                )}
                {bodyContent !== undefined &&(
                    <div className='quiz-instruction-text' dangerouslySetInnerHTML={{__html:bodyContent}}/>
                )}
            </>
        )
    }

    return(
        <>
            {userLessonContent == null || state == null ?(
                <>
                </>
            ):(
                <>
                    {state.lessonContent.textProperties != null &&(
                        <>
                            {textToBody(state.lessonContent?.textProperties?.text)}
                        </>
                    )}

                </>
            )}

        </>
    )
}

export default ContentViewerQuizTextContent