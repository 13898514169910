import {Accordion, AccordionContext, Card, useAccordionButton} from "react-bootstrap";
import * as React from "react";
import {useContext, useEffect, useState} from "react";
import "./../AccordionItem.css"
import {PartialModuleTreeModel} from "../../../../models/partialModels/PartialModuleTreeModel";
import {AccordionHelper} from "../../../../models/utility/AccordionHelper";
import {AdminBranchState} from "../../../AdminDashboard/AdminAccordion/AdminAccordionItems/AdminBranchLevelOne";
import IconCaretDown from "../../../../resources/icons/icon-caret-down.png";
import IconCaretLeft from "../../../../resources/icons/icon-caret-left.png";

interface contextAwareProps {
    module: any,
    selectedModuleId:any,
    SetSelectedBranch:any,
    selectedBranch:PartialModuleTreeModel,
    parentModule:any,
    children: any,
    eventKey: any,
    callback: any
}

type branchProp={
    isOpen :boolean,
    isSelected:boolean
}


export const BranchLevelOne: React.FC<contextAwareProps> = ({module, SetSelectedBranch,selectedBranch,selectedModuleId,parentModule,children,eventKey,callback}) =>
{

    const [state, setState] = useState<branchProp>({isOpen:false,isSelected:false});

    useEffect(()=>{

        let containsNewModule = false;

        module.children.forEach( (lesson:PartialModuleTreeModel)=>{
            if(lesson.id ==selectedModuleId){
                containsNewModule = true;
            }
            if(lesson.children.length > 0){
                lesson.children.forEach( (subLesson)=>{
                    if(subLesson.id == selectedModuleId){
                        containsNewModule = true;
                    }
                })
            }
        })

        if(containsNewModule)
        {

            AccordionHelper.setCollapseOrShowAccordion(false, module.id)
            setState({
                isOpen:true,
                isSelected:true,
            })
            SetSelectedBranch(module);
        }else{
            AccordionHelper.setCollapseOrShowAccordion(true, module.id)
            setState({
                isOpen:false,
                isSelected:false
            })
        }


    },[selectedModuleId])


    useEffect(()=>{

        let IsSelectedBranch = false;

        if(selectedBranch.id != module.id){
            module.children.forEach((child:PartialModuleTreeModel)=>{
                if(child.id == selectedBranch.id){
                    IsSelectedBranch =true;
                }
            })
        }else{
            IsSelectedBranch =true;
        }

        if(IsSelectedBranch){
            AccordionHelper.setCollapseOrShowAccordion(false, module.id)

            setState({
                isOpen:true,
                isSelected:true,
            })
        }else{
            AccordionHelper.setCollapseOrShowAccordion(true, module.id)


            setState({
                isOpen:false,
                isSelected:false,
            })
        }

    },[selectedBranch])

    const handleAccordion = () => {
        //If this current module is selected

        if(selectedModuleId === module.id) {
            setState(prevState => ({
                ...prevState,
                isSelected:true,
                isOpen:true
            }));

            AccordionHelper.setCollapseOrShowAccordion(false, module.id);
            return;
        }

        //If one of this current module's children are selected
        if((AccordionHelper.checkIfChildIsSelected(parentModule, module.id, selectedModuleId))) {
            setState(prevState => ({
                ...prevState,
                isSelected:false,
                isOpen:true
            }));
            AccordionHelper.setCollapseOrShowAccordion(false, module.id)
            return;
        }

        //When we are not selected and not open
        setState(prevState => ({
            ...prevState,
            isSelected:false,
            isOpen:false
        }));


        AccordionHelper.setCollapseOrShowAccordion(true, module.id)
    }

    const handleNavigationClick = (e:React.MouseEvent<HTMLElement>) => {


        if(module.id != selectedBranch.id){
            //New branch selected
            SetSelectedBranch(module);
        }else{

            //Old Branch Selected
            AccordionHelper.toggleAccordion(module.id);
            setState(prevState => ({
                ...prevState,
                isSelected:!state.isSelected,
                isOpen:!state.isOpen
            }));
        }
    }


    const mod:PartialModuleTreeModel = module;


    const LessonCount = function(list:PartialModuleTreeModel[]){
        let count =0 ;
        list.forEach(value =>{
            if(value.children.length ==0){
                count++;
            }
            if(value.children.length !=0){
                value.children.forEach(value2=>{
                    count++;
                })
            }
        })
        return count;
    }

    const CompletedCount = function(list:PartialModuleTreeModel[])
    {
        let count = 0;
        list.forEach(value=>{
            if(value.children.length ==0 && value.isComplete){
                count++;
            }
            if(value.children.length !=0 ){
                value.children.forEach(value2=>{
                    if(value2.isComplete){
                        count++;
                    }
                })
            }
        })
        return count;
    }

    return(
        <>
            {state.isSelected? (

                    <div className='chapter-content-container admin-selected' onClick={handleNavigationClick}>
                        <div className='admin-chapter-text'>
                            {children}
                        </div>
                        <div className='chapter-lesson-amount'>
                            <span>{CompletedCount(mod.children)}</span>/<span>{LessonCount(mod.children)}</span>
                        </div>
                        <div className={`admin-icon-container ${AccordionHelper.addIconCaretClass(state.isOpen)}`}>
                            {state.isOpen ? (
                                <img src={IconCaretDown} alt="icon caret down" />
                            ) : (
                                <img src={IconCaretLeft} alt="icon caret left" />
                            )}
                        </div>

                        <div className="chapter-lesson-amount-container mobile">
                            <div className='chapter-lesson-amount mobile'>
                                <span>{CompletedCount(mod.children)}</span>/<span>{LessonCount(mod.children)}</span>
                            </div>
                            <div className={`admin-icon-container mobile ${AccordionHelper.addIconCaretClass(state.isOpen)}`}>
                                {state.isOpen ? (
                                    <img src={IconCaretDown} alt="icon caret down" />
                                ) : (
                                    <img src={IconCaretLeft} alt="icon caret left" />
                                )}
                            </div>
                        </div>

                    </div>

            ):(
                <div className='chapter-content-container' onClick={handleNavigationClick}>
                    <div className='admin-chapter-text'>
                        {children}
                    </div>
                    <div className='chapter-lesson-amount'>
                        <span>{CompletedCount(mod.children)}</span>/<span>{LessonCount(mod.children)}</span>
                    </div>
                    <div
                        className={`admin-icon-container ${AccordionHelper.addIconCaretClass(state.isOpen)}`}
                    >
                        {
                            state.isOpen ?
                                (
                                    <img src={IconCaretDown} alt="icon caret down" />
                                )
                                :
                                (
                                    <img src={IconCaretLeft} alt="icon caret left" />
                                )
                        }

                    </div>

                    <div className="chapter-lesson-amount-container mobile">
                        <div className='chapter-lesson-amount mobile'>
                            <span>{CompletedCount(mod.children)}</span>/<span>{LessonCount(mod.children)}</span>
                        </div>
                        <div
                            className={`admin-icon-container mobile ${AccordionHelper.addIconCaretClass(state.isOpen)}`}
                        >
                            {
                                state.isOpen ?
                                    (
                                        <img src={IconCaretDown} alt="icon caret down" />
                                    )
                                    :
                                    (
                                        <img src={IconCaretLeft} alt="icon caret left" />
                                    )
                            }

                        </div>

                    </div>

                </div>
            )}

        </>
    );

}

