import React, { useState } from "react";
import ReferralUserDetails from "../../Referral/ReferralList/ReferralUserDetails";
import { IReferral, ReferralStatusEnum, ReferralStatusUi } from "../../../models/Referral/Referral";
import "./UserReferralApproval.css";
import { ReferralController } from "../../../controllers/ReferralController";
import { toast } from "react-hot-toast";

type UserReferralApprovalProps = {
    referral:IReferral
    onReferralApproval:() => void
    onReferralDecline:() => void
}

const referralController = new ReferralController();

/**
 * Component for admins to approve or decline referral submissions
 * @returns 
 */
const UserReferralApproval = (props:UserReferralApprovalProps) => {
    const {
            referral,
            onReferralApproval,
            onReferralDecline
        } = props;

    const [isLoading, setIsLoading] = useState(false);
    
    const approveOrDeclineReferral = async (status:ReferralStatusEnum) => {
        const loadId = "referralLoadId";
        try {
            toast.loading("Updating referral", {id: loadId});
            setIsLoading(true);
            
            const results = await referralController.UpdateReferralStatus(referral.id, referral.userProfileId, status);
            
            toast.dismiss(loadId);
            setIsLoading(false);

            if(!results) {
                return;
            }

            if(status === ReferralStatusEnum.Accepted) {
                onReferralApproval();
            } else if(status === ReferralStatusEnum.Declined) {
                onReferralDecline();
            }

            toast.success(`Successfully ${ReferralStatusUi[ReferralStatusEnum.Accepted].toLowerCase()} ${referral.name}`);
            
        } catch (error) {
            toast.dismiss(loadId);
            console.error(error);
            toast.error("Failed to update referral");
        }
    }

    /**
     * We need to disable the approve and decline buttons if the referral status is equal to them
     * @param status 
     * @returns 
     */
    const disableButton = (status:ReferralStatusEnum) => {
        if(isLoading) {
            return true;
        }

        return referral.status === status;
    }
    
    return (
        <div className="user-referral-approval">
            <ReferralUserDetails referral={referral} />
            <div className="cbit-dialog-btn-container">
                <button 
                    disabled={disableButton(ReferralStatusEnum.Declined)}
                    onClick={() => approveOrDeclineReferral(ReferralStatusEnum.Declined)} 
                    className="btn-cbit-minor"
                >
                    Decline
                </button>
                <button
                    disabled={disableButton(ReferralStatusEnum.Accepted)}
                    onClick={() => approveOrDeclineReferral(ReferralStatusEnum.Accepted)} 
                    className="btn-cbit-primary"
                >
                    Accept
                </button>
            </div>
        </div>
    )
}

export default UserReferralApproval;