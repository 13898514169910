import React from "react";
import "./MyProfileDescription.css";

type MyProfileDescriptionProps = {
    name:string
    description:string
} 

/**
 * Component for rendering really long descriptions.
 * Mostly used in a dialogue popup
 * @returns 
 */
const MyProfileDescription = (props:MyProfileDescriptionProps) => {
    const {name, description} = props;

    return (
        <div className="my-profile-full-description">
            <h2 className="my-profile-description-name">{name}</h2>
            <div>
                {description}
            </div>
        </div>
    )
}

export default MyProfileDescription;