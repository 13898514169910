import React from "react";
import {ReactComponent as ModuleBtnIcon} from '../../../../../resources/images/module-button.svg';



type ContentBuilderMenuButtonProps = {
    isLoading:boolean;
    onClick:any;
    btnLabel:string
}


export const StandardContentButton = (props:ContentBuilderMenuButtonProps) => {
    const {isLoading, onClick, btnLabel} = props;

    return (

        <button 
            disabled={isLoading}                        
            className="admin-content-menu-item"
            onClick={
                () => onClick()                         
            }
        >
            <span className='plus-icon'>
                <ModuleBtnIcon/>
            </span>
            <span className='admin-content-menu-item-subheading'>
                {btnLabel}
            </span>
        </button>

    )
}

 export const CustomContentButton = (props:ContentBuilderMenuButtonProps & {textDesc:string, btnIcon:any}) => {
    const {isLoading, onClick, btnLabel, textDesc, btnIcon} = props;

    return (

        <button 
            disabled={isLoading}                        
            className="admin-content-menu-item-custom"
            onClick={
                () => onClick()                            
            }
        >
            <span className='plus-icon'>
                {btnIcon}
            </span>
            <span className="content-menu-custom-container">

            <span className='admin-content-menu-item-subheading'>
                {btnLabel}
            </span>
            <span className='admin-content-menu-item-text'> 
                {textDesc}
            </span>
            </span>
        </button>

    )
 }