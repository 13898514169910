import {ISupportContent, SupportContent, supportLink} from "../models/AdditionalContent/SupportContent";
import {SupportContentService} from "../services/SupportContentService";
import moment from "moment/moment";

export class SupportContentController
{
    private supportContentService: SupportContentService

    constructor()
    {
        this.supportContentService = new SupportContentService();
    }

    public async GetSupportContent(){
        try{
            let data = await this.supportContentService.GetAll('SupportContent/GetSortedSupportContent');
            return data;
        }catch (exception)
        {

        }
    }


    public async UpdateSupportContent( content: ISupportContent){
        try{
            let params = {id:content.id}
            content.updatedAt = moment(new Date()).toISOString();
            let response = await this.supportContentService.Put(params, content);
            return response;
        }catch(exception){

        }
    }

    public async CreateSupportContent( content: ISupportContent){
        try{
            content.createdAt = moment(new Date()).toISOString();
            content.updatedAt = moment(new Date()).toISOString();
            let response = await this.supportContentService.Post(content);
            return response;
        }catch(exception){

        }
    }

    public async DeleteSupportContent( id:string){
        try{
            let response = await this.supportContentService.Delete({id});
            return response;
        }catch(exception){

        }
    }
}
