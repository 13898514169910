export enum ZoomTimeZoneEnum {    
    PacificMidway = 'Pacific/Midway',
    PacificPago_Pago = 'Pacific/Pago_Pago',
    PacificHonolulu = 'Pacific/Honolulu',
    AmericaAnchorage = 'America/Anchorage',
    AmericaVancouver = 'America/Vancouver',
    AmericaLos_Angeles = 'America/Los_Angeles',
    AmericaTijuana = 'America/Tijuana',
    AmericaEdmonton = 'America/Edmonton',
    AmericaDenver = 'America/Denver',
    AmericaPhoenix = 'America/Phoenix',
    AmericaMazatlan = 'America/Mazatlan',
    AmericaWinnipeg = 'America/Winnipeg',
    AmericaRegina = 'America/Regina',
    AmericaChicago = 'America/Chicago',
    AmericaMexico_City = 'America/Mexico_City',
    AmericaGuatemala = 'America/Guatemala',
    AmericaEl_Salvador = 'America/El_Salvador',
    AmericaManagua = 'America/Managua',
    AmericaCosta_Rica = 'America/Costa_Rica',
    AmericaMontreal = 'America/Montreal',
    AmericaNew_York = 'America/New_York',
    AmericaIndianapolis = 'America/Indianapolis',
    AmericaPanama = 'America/Panama',
    AmericaBogota = 'America/Bogota',
    AmericaLima = 'America/Lima',
    AmericaHalifax = 'America/Halifax',
    AmericaPuerto_Rico = 'America/Puerto_Rico',
    AmericaCaracas = 'America/Caracas',
    AmericaSantiago = 'America/Santiago',
    AmericaSt_Johns = 'America/St_Johns',
    AmericaMontevideo = 'America/Montevideo',
    AmericaAraguaina = 'America/Araguaina',
    AmericaArgentina = 'America/Argentina',
    AmericaGodthab = 'America/Godthab',
    AmericaSao_Paulo = 'America/Sao_Paulo',
    AtlanticAzores = 'Atlantic/Azores',
    CanadaAtlantic = 'Canada/Atlantic',
    AtlanticCape_Verde = 'Atlantic/Cape_Verde',    
    EtcGreenwich = 'Etc/Greenwich',
    EuropeBelgrade = 'Europe/Belgrade',
    CETSarajevo = 'CET	Sarajevo',
    AtlanticReykjavik = 'Atlantic/Reykjavik',
    EuropeDublin = 'Europe/Dublin',
    EuropeLondon = 'Europe/London',
    EuropeLisbon = 'Europe/Lisbon',
    AfricaCasablanca = 'Africa/Casablanca',
    AfricaNouakchott = 'Africa/Nouakchott',
    EuropeOslo = 'Europe/Oslo',
    EuropeCopenhagen = 'Europe/Copenhagen',
    EuropeBrussels = 'Europe/Brussels',
    EuropeBerlin = 'Europe/Berlin',
    EuropeHelsinki = 'Europe/Helsinki',
    EuropeAmsterdam = 'Europe/Amsterdam',
    EuropeRome = 'Europe/Rome',
    EuropeStockholm = 'Europe/Stockholm',
    EuropeVienna = 'Europe/Vienna',
    EuropeLuxembourg = 'Europe/Luxembourg',
    EuropeParis = 'Europe/Paris',
    EuropeZurich = 'Europe/Zurich',
    EuropeMadrid = 'Europe/Madrid',
    AfricaBangui = 'Africa/Bangui',
    AfricaAlgiers = 'Africa/Algiers',
    AfricaTunis = 'Africa/Tunis',
    AfricaHarare = 'Africa/Harare',
    AfricaNairobi = 'Africa/Nairobi',
    EuropeWarsaw = 'Europe/Warsaw',
    EuropePrague = 'Europe/Prague',
    EuropeBudapest = 'Europe/Budapest',
    EuropeSofia = 'Europe/Sofia',
    EuropeIstanbul = 'Europe/Istanbul',
    EuropeAthens = 'Europe/Athens',
    EuropeBucharest = 'Europe/Bucharest',
    AsiaNicosia = 'Asia/Nicosia',
    AsiaBeirut = 'Asia/Beirut',
    AsiaDamascus = 'Asia/Damascus',
    AsiaJerusalem = 'Asia/Jerusalem',
    AsiaAmman = 'Asia/Amman',
    AfricaTripoli = 'Africa/Tripoli',
    AfricaCairo = 'Africa/Cairo',
    AfricaJohannesburg = 'Africa/Johannesburg',
    EuropeMoscow = 'Europe/Moscow',
    AsiaBaghdad = 'Asia/Baghdad',
    AsiaKuwait = 'Asia/Kuwait',
    AsiaRiyadh = 'Asia/Riyadh',
    AsiaBahrain = 'Asia/Bahrain',
    AsiaQatar = 'Asia/Qatar',
    AsiaAden = 'Asia/Aden',
    AsiaTehran = 'Asia/Tehran',
    AfricaKhartoum = 'Africa/Khartoum',
    AfricaDjibouti = 'Africa/Djibouti',
    AfricaMogadishu = 'Africa/Mogadishu',
    AsiaDubai = 'Asia/Dubai',
    AsiaMuscat = 'Asia/Muscat',
    AsiaBaku = 'Asia/Baku',
    AsiaKabul = 'Asia/Kabul',
    AsiaYekaterinburg = 'Asia/Yekaterinburg',
    AsiaTashkent = 'Asia/Tashkent',
    AsiaCalcutta = 'Asia/Calcutta',
    AsiaKathmandu = 'Asia/Kathmandu',
    AsiaNovosibirsk = 'Asia/Novosibirsk',
    AsiaAlmaty = 'Asia/Almaty',
    AsiaDacca = 'Asia/Dacca',
    AsiaKrasnoyarsk = 'Asia/Krasnoyarsk',
    AsiaDhaka = 'Asia/Dhaka',
    AsiaBangkok = 'Asia/Bangkok',
    AsiaSaigon = 'Asia/Saigon',
    AsiaJakarta = 'Asia/Jakarta',
    AsiaIrkutsk = 'Asia/Irkutsk',
    AsiaShanghai = 'Asia/Shanghai',
    AsiaHong_Kong = 'Asia/Hong_Kong',
    AsiaTaipei = 'Asia/Taipei',
    AsiaKuala_Lumpur = 'Asia/Kuala_Lumpur',
    AsiaSingapore = 'Asia/Singapore',
    AustraliaPerth = 'Australia/Perth',
    AsiaYakutsk = 'Asia/Yakutsk',
    AsiaSeoul = 'Asia/Seoul',
    AsiaTokyo = 'Asia/Tokyo',
    AustraliaDarwin = 'Australia/Darwin',
    AustraliaAdelaide = 'Australia/Adelaide',
    AsiaVladivostok = 'Asia/Vladivostok',
    PacificPort_Moresby = 'Pacific/Port_Moresby',
    AustraliaBrisbane = 'Australia/Brisbane',
    AustraliaSydney = 'Australia/Sydney',
    AustraliaHobart = 'Australia/Hobart',
    AsiaMagadan = 'Asia/Magadan',
    SSTSolomon = 'SST	Solomon',
    PacificNoumea = 'Pacific/Noumea',
    AsiaKamchatka = 'Asia/Kamchatka',
    PacificFiji = 'Pacific/Fiji',
    PacificAuckland = 'Pacific/Auckland',
    AsiaKolkata = 'Asia/Kolkata',
    EuropeKiev = 'Europe/Kiev',
    AmericaTegucigalpa = 'America/Tegucigalpa',
    PacificApia = 'Pacific/Api',
}

export const UiFriendlyTimeZones = {    
    [ZoomTimeZoneEnum.PacificMidway]: 'Midway Island, Samoa',
    [ZoomTimeZoneEnum.PacificPago_Pago]:	'Pago Pago',
    [ZoomTimeZoneEnum.PacificHonolulu]:	'Hawaii',
    [ZoomTimeZoneEnum.AmericaAnchorage]:	'Alaska',
    [ZoomTimeZoneEnum.AmericaVancouver]:	'Vancouver',
    [ZoomTimeZoneEnum.AmericaLos_Angeles]:	'Pacific Time (US and Canada)',
    [ZoomTimeZoneEnum.AmericaTijuana]:	'Tijuana',
    [ZoomTimeZoneEnum.AmericaEdmonton]:	'Edmonton',
    [ZoomTimeZoneEnum.AmericaDenver]:	'Mountain Time (US and Canada)',
    [ZoomTimeZoneEnum.AmericaPhoenix]:	'Arizona',
    [ZoomTimeZoneEnum.AmericaMazatlan]:	'Mazatlan',
    [ZoomTimeZoneEnum.AmericaWinnipeg]:	'Winnipeg',
    [ZoomTimeZoneEnum.AmericaRegina]:	'Saskatchewan',
    [ZoomTimeZoneEnum.AmericaChicago]:	'Central Time (US and Canada)',
    [ZoomTimeZoneEnum.AmericaMexico_City]:	'Mexico City',
    [ZoomTimeZoneEnum.AmericaGuatemala]:	'Guatemala',
    [ZoomTimeZoneEnum.AmericaEl_Salvador]:	'El Salvador',
    [ZoomTimeZoneEnum.AmericaManagua]:	'Managua',
    [ZoomTimeZoneEnum.AmericaCosta_Rica]:	'Costa Rica',
    [ZoomTimeZoneEnum.AmericaMontreal]:	'Montreal',
    [ZoomTimeZoneEnum.AmericaNew_York]:	'Eastern Time (US and Canada)',
    [ZoomTimeZoneEnum.AmericaIndianapolis]:	'Indiana (East)',
    [ZoomTimeZoneEnum.AmericaPanama]:	'Panama',
    [ZoomTimeZoneEnum.AmericaBogota]:	'Bogota',
    [ZoomTimeZoneEnum.AmericaLima]:	'Lima',
    [ZoomTimeZoneEnum.AmericaHalifax]:	'Halifax',
    [ZoomTimeZoneEnum.AmericaPuerto_Rico]:	'Puerto Rico',
    [ZoomTimeZoneEnum.AmericaCaracas]:	'Caracas',
    [ZoomTimeZoneEnum.AmericaSantiago]:	'Santiago',
    [ZoomTimeZoneEnum.AmericaSt_Johns]:	'Newfoundland and Labrador',
    [ZoomTimeZoneEnum.AmericaMontevideo]:	'Montevideo',
    [ZoomTimeZoneEnum.AmericaAraguaina]:	'Brasilia',
    [ZoomTimeZoneEnum.AmericaArgentina]:'Buenos Aires, Georgetown',
    [ZoomTimeZoneEnum.AmericaGodthab]:	'Greenland',
    [ZoomTimeZoneEnum.AmericaSao_Paulo]:	'Sao Paulo',
    [ZoomTimeZoneEnum.AtlanticAzores]:	'Azores',
    [ZoomTimeZoneEnum.CanadaAtlantic]:	'Atlantic Time (Canada)',
    [ZoomTimeZoneEnum.AtlanticCape_Verde]:	'Cape Verde Islands',    
    [ZoomTimeZoneEnum.EtcGreenwich]:	'Greenwich Mean Time',
    [ZoomTimeZoneEnum.EuropeBelgrade]:	'Belgrade, Bratislava, Ljubljana',
    [ZoomTimeZoneEnum.CETSarajevo]: 'Skopje, Zagreb',
    [ZoomTimeZoneEnum.AtlanticReykjavik]:	'Reykjavik',
    [ZoomTimeZoneEnum.EuropeDublin]:	'Dublin',
    [ZoomTimeZoneEnum.EuropeLondon]:	'London',
    [ZoomTimeZoneEnum.EuropeLisbon]:	'Lisbon',
    [ZoomTimeZoneEnum.AfricaCasablanca]:	'Casablanca',
    [ZoomTimeZoneEnum.AfricaNouakchott]:	'Nouakchott',
    [ZoomTimeZoneEnum.EuropeOslo]:	'Oslo',
    [ZoomTimeZoneEnum.EuropeCopenhagen]:	'Copenhagen',
    [ZoomTimeZoneEnum.EuropeBrussels]:	'Brussels',
    [ZoomTimeZoneEnum.EuropeBerlin]:	'Amsterdam, Berlin, Rome, Stockholm, Vienna',
    [ZoomTimeZoneEnum.EuropeHelsinki]:	'Helsinki',
    [ZoomTimeZoneEnum.EuropeAmsterdam]:	'Amsterdam',
    [ZoomTimeZoneEnum.EuropeRome]:	'Rome',
    [ZoomTimeZoneEnum.EuropeStockholm]:	'Stockholm',
    [ZoomTimeZoneEnum.EuropeVienna]:	'Vienna',
    [ZoomTimeZoneEnum.EuropeLuxembourg]:	'Luxembourg',
    [ZoomTimeZoneEnum.EuropeParis]:	'Paris',
    [ZoomTimeZoneEnum.EuropeZurich]:	'Zurich',
    [ZoomTimeZoneEnum.EuropeMadrid]:	'Madrid',
    [ZoomTimeZoneEnum.AfricaBangui]:	'West Central Africa',
    [ZoomTimeZoneEnum.AfricaAlgiers]:	'Algiers',
    [ZoomTimeZoneEnum.AfricaTunis]:	'Tunis',
    [ZoomTimeZoneEnum.AfricaHarare]:	'Harare, Pretoria',
    [ZoomTimeZoneEnum.AfricaNairobi]:	'Nairobi',
    [ZoomTimeZoneEnum.EuropeWarsaw]:	'Warsaw',
    [ZoomTimeZoneEnum.EuropePrague]:	'Prague Bratislava',
    [ZoomTimeZoneEnum.EuropeBudapest]:	'Budapest',
    [ZoomTimeZoneEnum.EuropeSofia]:	'Sofia',
    [ZoomTimeZoneEnum.EuropeIstanbul]:	'Istanbul',
    [ZoomTimeZoneEnum.EuropeAthens]:	'Athens',
    [ZoomTimeZoneEnum.EuropeBucharest]:	'Bucharest',
    [ZoomTimeZoneEnum.AsiaNicosia]:	'Nicosia',
    [ZoomTimeZoneEnum.AsiaBeirut]:	'Beirut',
    [ZoomTimeZoneEnum.AsiaDamascus]:	'Damascus',
    [ZoomTimeZoneEnum.AsiaJerusalem]:	'Jerusalem',
    [ZoomTimeZoneEnum.AsiaAmman]:	'Amman',
    [ZoomTimeZoneEnum.AfricaTripoli]:	'Tripoli',
    [ZoomTimeZoneEnum.AfricaCairo]:	'Cairo',
    [ZoomTimeZoneEnum.AfricaJohannesburg]:	'Johannesburg',
    [ZoomTimeZoneEnum.EuropeMoscow]:	'Moscow',
    [ZoomTimeZoneEnum.AsiaBaghdad]:	'Baghdad',
    [ZoomTimeZoneEnum.AsiaKuwait]:	'Kuwait',
    [ZoomTimeZoneEnum.AsiaRiyadh]:	'Riyadh',
    [ZoomTimeZoneEnum.AsiaBahrain]:	'Bahrain',
    [ZoomTimeZoneEnum.AsiaQatar]:	'Qatar',
    [ZoomTimeZoneEnum.AsiaAden]:	'Aden',
    [ZoomTimeZoneEnum.AsiaTehran]:	'Tehran',
    [ZoomTimeZoneEnum.AfricaKhartoum]:	'Khartoum',
    [ZoomTimeZoneEnum.AfricaDjibouti]:	'Djibouti',
    [ZoomTimeZoneEnum.AfricaMogadishu]:	'Mogadishu',
    [ZoomTimeZoneEnum.AsiaDubai]:	'Dubai',
    [ZoomTimeZoneEnum.AsiaMuscat]:	'Muscat',
    [ZoomTimeZoneEnum.AsiaBaku]:	'Baku, Tbilisi, Yerevan',
    [ZoomTimeZoneEnum.AsiaKabul]:	'Kabul',
    [ZoomTimeZoneEnum.AsiaYekaterinburg]:	'Yekaterinburg',
    [ZoomTimeZoneEnum.AsiaTashkent]:	'Islamabad, Karachi, Tashkent',
    [ZoomTimeZoneEnum.AsiaCalcutta]:	'India',
    [ZoomTimeZoneEnum.AsiaKathmandu]:	'Kathmandu',
    [ZoomTimeZoneEnum.AsiaNovosibirsk]:	'Novosibirsk',
    [ZoomTimeZoneEnum.AsiaAlmaty]:	'Almaty',
    [ZoomTimeZoneEnum.AsiaDacca]:	'Dacca',
    [ZoomTimeZoneEnum.AsiaKrasnoyarsk]:	'Krasnoyarsk',
    [ZoomTimeZoneEnum.AsiaDhaka]:	'Astana, Dhaka',
    [ZoomTimeZoneEnum.AsiaBangkok]:	'Bangkok',
    [ZoomTimeZoneEnum.AsiaSaigon]:	'Vietnam',
    [ZoomTimeZoneEnum.AsiaJakarta]:	'Jakarta',
    [ZoomTimeZoneEnum.AsiaIrkutsk]:	'Irkutsk, Ulaanbaatar',
    [ZoomTimeZoneEnum.AsiaShanghai]:	'Beijing, Shanghai',
    [ZoomTimeZoneEnum.AsiaHong_Kong]:	'Hong Kong',
    [ZoomTimeZoneEnum.AsiaTaipei]:	'Taipei',
    [ZoomTimeZoneEnum.AsiaKuala_Lumpur]:	'Kuala Lumpur',
    [ZoomTimeZoneEnum.AsiaSingapore]:	'Singapore',
    [ZoomTimeZoneEnum.AustraliaPerth]:	'Perth',
    [ZoomTimeZoneEnum.AsiaYakutsk]:	'Yakutsk',
    [ZoomTimeZoneEnum.AsiaSeoul]:	'Seoul',
    [ZoomTimeZoneEnum.AsiaTokyo]:	'Osaka, Sapporo, Tokyo',
    [ZoomTimeZoneEnum.AustraliaDarwin]:	'Darwin',
    [ZoomTimeZoneEnum.AustraliaAdelaide]:	'Adelaide',
    [ZoomTimeZoneEnum.AsiaVladivostok]:	'Vladivostok',
    [ZoomTimeZoneEnum.PacificPort_Moresby]:	'Guam, Port Moresby',
    [ZoomTimeZoneEnum.AustraliaBrisbane]:	'Brisbane',
    [ZoomTimeZoneEnum.AustraliaSydney]:	'Canberra, Melbourne, Sydney',
    [ZoomTimeZoneEnum.AustraliaHobart]:	'Hobart',
    [ZoomTimeZoneEnum.AsiaMagadan]:	'Magadan',
    [ZoomTimeZoneEnum.SSTSolomon]: 'Islands',
    [ZoomTimeZoneEnum.PacificNoumea]:	'New Caledonia',
    [ZoomTimeZoneEnum.AsiaKamchatka]:	'Kamchatka',
    [ZoomTimeZoneEnum.PacificFiji]:	'Fiji Islands, Marshall Islands',
    [ZoomTimeZoneEnum.PacificAuckland]:	'Auckland, Wellington',
    [ZoomTimeZoneEnum.AsiaKolkata]:	'Mumbai, Kolkata, New Delhi',
    [ZoomTimeZoneEnum.EuropeKiev]:	'Kiev',
    [ZoomTimeZoneEnum.AmericaTegucigalpa]:	'Tegucigalpa',
    [ZoomTimeZoneEnum.PacificApia]:	'Independent State of Samoa',
}