export class StringHelper {
    /**
     * Function if a string is either null, an empty string, or a string with only whitespace
     */
    static IsNullOrWhiteSpace(str:string|null) {
        
        if(!str) return true;

        const trimmedStr = str.trim();

        return trimmedStr.length <= 0;
    }

    /**
     * Capitalized the first letter in a string
     * @param str 
     * @param isMultiWord 
     * @returns 
     */
    public static capitalizeWord = (str:string, isMultiWord?:boolean) => {    
        if(!str) {
            return "";
        }    
        const length = str.length;


        if(length < 1) return "";

        const capitalStr = `${str.charAt(0).toUpperCase()}${str.substring(1, length)}`;

        if(!isMultiWord) {
            return capitalStr;
        }
        
        const words = str.split(" ");
        let capitalizeWordsArr:string[] = words.map(w => this.capitalizeWord(w));
        let capitalWords = capitalizeWordsArr.join(" ");
        return capitalWords;
    }

    /**
     * remove all non digits from a string
     * @param str 
     * @returns 
     */
    public static removeNonDigits = (str:string) => {        
        let digitOnlySting = "";
        for(let char of str) {
            const digit = parseInt(char);
            if(!isNaN(digit)) {
                digitOnlySting = digitOnlySting + `${digit}`;
            }
        }
        return digitOnlySting;
    }

    /**
     * Formats a string into a phone number format
     * @param str 
     */
    public static formatPhoneNumber = (str:string) => {
        // Getting an array of the sections of the phone numbers 
        const phoneArr = str.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

        if(phoneArr == null) {
            return str;
        }
        
        // Update the phone number with a US formatted phone number
        str = !phoneArr[2] ? phoneArr[1] : '(' + phoneArr[1] + ') ' + phoneArr[2] + (phoneArr[3] ? '-' + phoneArr[3] : '');

        return str
    }

    /**
    * Format a number to include commas
    * @param {number} value 
    * @returns 
    */
    public static formatNumberWithCommas = (value:number) => {        
        return value.toLocaleString("en-US")
    }

}