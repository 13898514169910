import { IUserBio } from "../module/UserProfile";
import { IConsultation } from "./Consultation";

/**
 * type for deciding if an attendee attendee the consultation that is in review
 */
export interface IAttendeeAttendance {
    attendeeId:string;
    attendance:AttendanceEnum;
    isReported:boolean;
}

/**
 * Enum for the possible attendance an attendee could be
 */
export enum AttendanceEnum {
    unknown = 0,
    present = 1,
    absent = 2,
    late = 3
}

/**
 * object that keeps info about an attendee attendance
 */
export class AttendeeAttendance implements IAttendeeAttendance {
    constructor(id:string, attendance:AttendanceEnum, isReported:boolean) {
        this.attendeeId = id;
        this.attendance = attendance;
        this.isReported = isReported;
    }

    attendeeId: string;
    attendance: AttendanceEnum;
    isReported:boolean;
}

/**
 * Enum for the possible types of reviews a consultation could be
 */
export enum ReviewTypeEnum {
    reviewByConsultant = 0,
    reviewByAttendee = 1,
    // seminarReview = 2
}

/**
 * Interface for a consultation review
 */
export interface IConsultationReview
{
    id:string;
    name:string;
    consultationId:string;
    assignedUserId:string;
    qualityRating:number;
    comments:string;
    PostedAt:Date|string|null;
    reviewType:ReviewTypeEnum;
    attendeeAttendanceList:AttendeeAttendance[];    
}

/**
 * Review object for a consultation
 */
export class ConsultationReview implements IConsultationReview{
    id:string;
    name:string;
    consultationId:string;
    assignedUserId:string;
    qualityRating:number;
    comments:string;
    PostedAt:Date|string|null;
    reviewType:ReviewTypeEnum;
    attendeeAttendanceList: AttendeeAttendance[];    

    constructor(data: IConsultationReview)
    {
        this.id = data.id;
        this.name = data.name;
        this.consultationId = data.consultationId;
        this.assignedUserId = data.assignedUserId;
        this.qualityRating = data.qualityRating;
        this.comments = data.comments;
        this.PostedAt = data.PostedAt;
        this.reviewType = data.reviewType;
        this.attendeeAttendanceList = data.attendeeAttendanceList;
    }
}

export type ConsultationReviewWithReviewer = {
    review:IConsultationReview;
    reviewer:IUserBio;
    consultation:IConsultation;
    consultant:IUserBio | null;
}