import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";
import { getLessonIconSrc } from "../../../../models/lesson/Lesson";
import { PartialModuleTreeModel, TreeViewType } from "../../../../models/partialModels/PartialModuleTreeModel";
import { AccordionHelper } from "../../../../models/utility/AccordionHelper";
import IconComboLesson from "../../../../resources/icons/icon-combo.png";
import IconQuiz from "../../../../resources/icons/icon-quiz.png";
import IconText from "../../../../resources/icons/icon-reading.png";
import IconVideo from "../../../../resources/icons/icon-video.png";
import "./../AccordionItem.css";

interface contextAwareProps {
    module: PartialModuleTreeModel,
    children: any,
    selectedModuleId: any,
    selectedBranch: PartialModuleTreeModel,
    setSelectedBranch: any,
    parentModule: any,
    eventKey: any,
    callback: any
}

export const LessonLevelOne: React.FC<contextAwareProps> = ({
    module,
    parentModule,
    selectedModuleId,
    children,
    eventKey,
    callback
}) => {
    const [selected, setSelected] = useState(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        handleAccordion();
    }, [selectedModuleId])


    const handleAccordion = () => {
        //If this current module is selected
        if (selectedModuleId === module.id) {
            setSelected(true);
            callback();
            return;
        }

        //If one of this current module's children are selected
        if ((AccordionHelper.checkIfChildIsSelected(parentModule, module.id, selectedModuleId))) {
            setSelected(false)
            return;
        }

        //When we are not selected and not open
        setSelected(false)
    }

    const handleLessonClick = () => {
        //If this current module is selected
        if (selectedModuleId != module.id) {
            setSelected(true);
            callback();
            document.querySelector(".MainContent")?.classList.toggle("isNavOpen");
            document.querySelector(".AdditionalVideoContainer")?.classList.toggle("isNavOpen");
            document.querySelector(".Viewer-Content")?.classList.toggle("isNavOpen");  
            return;
        }
    }

    const getLessonIcon = () => {
        const lessonType = module.type;

        switch (lessonType) {
            case TreeViewType.VideoLesson:
                return IconVideo

            case TreeViewType.TextLesson:
                return IconText

            case TreeViewType.QuizLesson:
                return IconQuiz

            default:
                return IconComboLesson
        }
    }

    const {activeEventKey} = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );
    const decoratedOnClick_empty = useAccordionButton(
        eventKey, () => {
        },
    );
    const isCurrentEventKey = activeEventKey === eventKey;
    const mod: PartialModuleTreeModel = module;

    /**
     * Updates the isMobile state based on the window size
     */
    const updateMobileStatus = () => {
       if (window.innerWidth <= 1023) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
       }
    };

    /**
     * Updates the isMobile state on component mount
     * and adds a listener to update the isMobile state
     * on window resize
     */
    useEffect(() => {

        if (window !== undefined) {
            updateMobileStatus();

            window.addEventListener("resize", updateMobileStatus);
        }

        return () => window.removeEventListener("resize", updateMobileStatus);

    }, [isMobile, window.innerWidth]);

    return (
        <>
            {mod.isDisabled ? (
                <>
                    {/*//TODO: FINISH WITH NEW STYLES*/}
                    <div className={`disabled admin-lesson-item remove-admin-selected`}>
                            {isMobile ? (
                                <>
                                    <div className='admin-lesson-content mobile remove-padding'>
                                        <div className="admin-lesson-text-container mobile">
                                            <div className='lesson-type-icon'>
                                                <img src={getLessonIcon()} alt="lesson Icon"/>
                                            </div>
                                            <div className='admin-lesson-text'>
                                                {children}
                                            </div>
                                        </div>
                                        <div className='admin-icon-container mobile'>
                                            <div className='admin-lesson-duration lesson-duration-buffer'>
                                                <span>{module.time}</span>
                                            </div>
                                            <div className="admin-icon-container mobile">
                                                {mod.isComplete ? (
                                                    <>
                                                        <i className="bi bi-check-lg orange-color "></i>
                                                    </>
                                                ) : (
                                                    <>
                                                        {mod.isNext ?
                                                            (
                                                                <>
                                                                    <i className="bi bi-bookmark orange-color"></i>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <i className="small-width-20"></i>
                                                                </>
                                                            )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className='admin-lesson-content remove-padding'>
                                        <div className='lesson-type-icon'>
                                            <img src={getLessonIcon()} alt="lesson Icon"/>
                                        </div>
                                        {/*<div className="admin-lesson-text-container mobile">*/}
                                            <div className='admin-lesson-text'>
                                                {children}
                                            </div>
                                            <div className='admin-lesson-duration lesson-duration-buffer'>
                                                <span>{module.time}</span>
                                            </div>
                                        {/*</div>*/}
                                        <div className="admin-icon-container">
                                            {mod.isComplete ? (
                                                <>
                                                    <i className="bi bi-check-lg orange-color "></i>
                                                </>
                                            ) : (
                                                <>
                                                    {mod.isNext ?
                                                        (
                                                            <>
                                                                <i className="bi bi-bookmark orange-color"></i>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i className="small-width-20"></i>
                                                            </>
                                                        )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                    </div>
                    {/* Possible Duplicate */}
                    {/*<div className='disabled no-border Lesson-Header-Depth-1'>*/}
                    {/*        <div className='disabled float-left lesson-non-selected'>*/}
                    {/*        </div>*/}
                    {/*        <div className='float-left Right-Buffer-12'>*/}
                    {/*            <div className='lesson-type-icon' style={{marginRight:'8px'}}>*/}
                    {/*                <img src={getLessonIcon()} alt="lesson Icon"/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className='disabled accordion-lesson-text float-left'>*/}
                    {/*            {children}*/}
                    {/*        </div>*/}

                    {/*        <div className='float-right Right-Buffer-9'>*/}
                    {/*            <i className="bi bi-lock-fill"></i>*/}
                    {/*        </div>*/}
                    {/*        <div className='disabled float-right lesson-video-text-depth-1 Right-small-Buffer-14'>*/}
                    {/*            <span>{module.time}</span>*/}
                    {/*        </div>*/}

                    {/*</div>*/}
                </>
            ) : (
                <>
                    {selected ? (
                        <div className={`admin-lesson-item admin-selected`} onClick={handleLessonClick}>
                                {isMobile ? (
                                    <>
                                        <div className='admin-lesson-content mobile remove-padding'>

                                            <div className="admin-lesson-text-container mobile">
                                                <div className='orange-selected'/>
                                                <div className='lesson-type-icon'>
                                                    <img src={getLessonIconSrc(module.lessonIcon)} alt="lesson Icon"/>
                                                </div>
                                                <div className='admin-lesson-text'>
                                                    {children}
                                                </div>
                                            </div>
                                            <div className='admin-icon-container mobile'>
                                                <div className='admin-lesson-duration lesson-duration-buffer'>
                                                    <span>{module.time}</span>
                                                </div>
                                                <div className='admin-icon-container mobile'>
                                                    {mod.isComplete ? (
                                                        <>
                                                            <i className="bi bi-check-lg orange-color"></i>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {mod.isNext ?
                                                                (
                                                                    <>
                                                                        <i className="bi bi-bookmark orange-color"></i>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <i className="small-width-20"></i>
                                                                    </>
                                                                )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='admin-lesson-content remove-padding'>
                                            <div className='orange-selected'/>
                                            <div className='lesson-type-icon'>
                                                <img src={getLessonIconSrc(module.lessonIcon)} alt="lesson Icon"/>
                                            </div>
                                            <div className='admin-lesson-text'>
                                                {children}
                                            </div>
                                            <div className='admin-lesson-duration lesson-duration-buffer'>
                                                <span>{module.time}</span>
                                            </div>
                                            <div className='admin-icon-container'>
                                                {mod.isComplete ? (
                                                    <>
                                                        <i className="bi bi-check-lg orange-color"></i>
                                                    </>
                                                ) : (
                                                    <>
                                                        {mod.isNext ?
                                                            (
                                                                <>
                                                                    <i className="bi bi-bookmark orange-color"></i>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <i className="small-width-20"></i>
                                                                </>
                                                            )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                        </div>
                    ) : (
                        <div className={`admin-lesson-item remove-admin-selected`} onClick={handleLessonClick}>
                            {isMobile ? (
                                <>
                                    <div className='admin-lesson-content mobile remove-padding'>

                                        <div className="admin-lesson-text-container mobile">
                                            <div className='lesson-type-icon'>
                                                <img src={getLessonIconSrc(module.lessonIcon)} alt="lesson Icon"/>
                                            </div>
                                            <div className='admin-lesson-text'>
                                                {children}
                                            </div>
                                        </div>
                                        <div className="admin-duration-container mobile">
                                            <div className='admin-lesson-duration lesson-duration-buffer'>
                                                <span>{module.time}</span>
                                            </div>
                                            <div className='admin-icon-container mobile'>
                                                {mod.isComplete ? (
                                                    <>
                                                        <i className="bi bi-check-lg orange-color "></i>
                                                    </>
                                                ) : (
                                                    <>
                                                        {mod.isNext ?
                                                            (
                                                                <>
                                                                    <i className="bi bi-bookmark orange-color"></i>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <i className="small-width-20"></i>
                                                                </>
                                                            )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className='admin-lesson-content remove-padding'>
                                        <div className='lesson-type-icon'>
                                            <img src={getLessonIconSrc(module.lessonIcon)} alt="lesson Icon"/>
                                        </div>
                                        <div className='admin-lesson-text'>
                                            {children}
                                        </div>
                                        <div className='admin-lesson-duration lesson-duration-buffer'>
                                            <span>{module.time}</span>
                                        </div>
                                        <div className='admin-icon-container'>
                                            {mod.isComplete ? (
                                                <>
                                                    <i className="bi bi-check-lg orange-color "></i>
                                                </>
                                            ) : (
                                                <>
                                                    {mod.isNext ?
                                                        (
                                                            <>
                                                                <i className="bi bi-bookmark orange-color"></i>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <i className="small-width-20"></i>
                                                            </>
                                                        )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </>
            )}

        </>
    );
}
