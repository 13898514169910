import {BaseReactService} from "./interfaces/BaseReactService";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import { AxiosError } from "axios";
import { ILesson } from "../models/lesson/Lesson";

export class PublishedLessonService extends BaseReactService {

    constructor(){
        super("PublishedLesson/");
    }    
    
    public async GetLessonsWithParentId(id: { id:string }){
        const endpoint = 'PublishedLesson/WithBranchId/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    public async UpdateLessonLastModified(id:string) {
        const endpoint = 'PublishedLesson/LastModifiedUpdate';
        try {            
            return await this.CustomPatch({id}, endpoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async CompleteUserLesson(id: {id:string}){
        const endpoint = 'PublishedLesson/CompleteUserPublishedLesson/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }
    
    public async GetAdditionalVideosForLesson(id: {id:string})
    {
        const endpoint = 'PublishedLesson/GetAdditionalVideosForPublishedLesson/'
        try{
            return await this.Get(id, endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async GetTotalQuizQuestions(lessonId:string) {
        const endpoint = 'PublishedLesson/GetTotalQuizQuestions/';
        try {
            return await this.CustomGet({lessonId}, endpoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async GetLastLesson() {
        const endpoint = 'PublishedLesson/GetLastLesson/';
        try {
            return await this.CustomGet({}, endpoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }
}