import {BaseReactService} from "./interfaces/BaseReactService";

export class SupportContentService extends BaseReactService{

    constructor(){
        super("SupportContent/");
    }

    // public async GetAllSorted(){
    //     const endpoing = 'SupportContent/GetSortedSupportContent'
    //     try{
    //         return await this.GetAll({},endpoing);
    //     }catch(ex){
    //
    //     }
    // }
    //
    // public async GetModuleDepthById(id:{id:string}){
    //     const endpoint = 'PublishedModule/GetModuleDepthById/'
    //     try{
    //         return await this.Get(id,endpoint);
    //     }catch(error){
    //         // ErrorHandler.catchApiError(error)
    //     }
    // }
}
