import React, {useEffect, useState} from "react"
import "./Faqs.css"
import {SocialConnectionController} from "../../../../controllers/SocialConnectionController";
import {Spinner} from "reactstrap";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {CurrentUserState} from "../../../../store/CurrentUser";
import SocialConnectionPostItem from "../../components/SocialConnectionPostItem";
import {IPost} from "../../../../models/SocialConnection/Post";
import {DeepCopy} from "../../../../models/utility/DeepCopy";
import SocialConnectionLayout from "../../components/layout/SocialConnectionLayout";

const Faqs = ({history}: any) => {
    const [posts, setPosts] = useState<any>([]);
    const [loading, setLoading] = useState(true);

    const goToPost = (id: string) => {
        history.push('/social-connection/posts/' + id)
    }

    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state) => state.currentUser);

    const getAllPromotedPosts = async () => {
        const sConnectionController = new SocialConnectionController();
        const allPosts = await sConnectionController.GetAllPosts();
        let promotedPosts = allPosts.filter((post: any) => post.isPromoted === true);
        setPosts(promotedPosts);
        setLoading(false);
    }

    useEffect(() => {
        getAllPromotedPosts();
    }, []);

    useEffect(() => {
    }, [posts, setPosts]);

    useEffect(() => {
        const userIsBanned = UserStore?.userProfile?.isBanned;

        if (userIsBanned) {
            history.push('/home');
        }

        return () => {
            setPosts([]);
        }

    }, [UserStore?.userProfile?.isBanned]);

    const handlePostUpdate = async (updatedPost: IPost) => {
        let oldData = DeepCopy.copy(posts);

        oldData.map(()=> {
            if(oldData.id === updatedPost.id){
                oldData = updatedPost;
            }
        })

        setPosts(oldData);
    }

    return (
        <SocialConnectionLayout>
            {loading ? (
                <div className="spinner-container">
                    <Spinner color='primary'/>
                </div>
            ) : (
                <>
                    <div className='social-connection-faqs-container'>
                        <div className="social-connection-faqs-page-header">
                           <span className='social-connection-go-back'>
                                <a href='/social-connection'>&#60; Return to Main Discussion Board</a>
                            </span>
                        </div>
                        <div className="social-connection-faqs-subheader">
                            <h4 className='social-connection-subheading'>
                                FAQs
                            </h4>
                        </div>

                        <div className="social-connection-faqs-posts-container">
                            {posts.length > 0 ? posts.map((post: any) => (
                               <SocialConnectionPostItem key={post.id} post={post} updatePost={handlePostUpdate} />
                            )) : <h4 className='social-connection-no-posts'>No posts yet</h4>}
                        </div>
                    </div>
                </>
            )}
        </SocialConnectionLayout>
    )
}

export default Faqs