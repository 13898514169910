import { createTheme } from "@mui/system";
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import "./CbitDataGrid.css";

/**
 * Props for the data grid. 
 * pageSize and pageSizeOpts are optional
 */
type CbitDataGridProps = {
    columns:GridColDef[];
    rowData:any;
    noRowsText:string;
    isLoading:boolean;
    pageSize?:number;
    pageSizeOpts?:number[];
    columnVisibilityModel?:GridColumnVisibilityModel
}

const defaultPageSize = 10;
const defaultPageOpts = [10, 25, 50, 100];

/**
 * Wrapper for mui data grid so I don't have to keep repeating this 
 * @param props 
 * @returns 
 */
const CbitDataGrid = (props:CbitDataGridProps) => {
    const {
            columns, 
            rowData,
            noRowsText,
            isLoading,
            pageSize,
            pageSizeOpts,
    } = props;

    const dataGridStyleRoot =createTheme({
        typography: {
            fontFamily:[
                "Open Sans"
            ],
            fontSize:16
        }
    })

    /**
     * Decides between the passed in pageSize or the default pagesSze
     * @returns 
     */
    const getPageSize  = () => {
        return pageSize ?? defaultPageSize;
    }

    /**
     * Decides between the passed in pageSizeOpts or the default pagesSzeOpts
     * @returns 
     */
    const getPageOpts  =() => {
        return pageSizeOpts ?? defaultPageOpts;
    }

    /**
     * Adds the top toolbar features
     * @returns 
     */
    function CustomToolbar() {
        return (
          <GridToolbarContainer >            
            <GridToolbarFilterButton style={{ fontFamily: "var(--cbit-text)", color: "var(--cbit-dark-blue)" }}/>
            <GridToolbarDensitySelector style={{  fontFamily: "var(--cbit-text)",color: "var(--cbit-dark-blue)" }}/>
            <GridToolbarExport printOptions={{disableToolbarButton: true }} style={{ fontFamily: "var(--cbit-text)", color: "var(--cbit-dark-blue)" }}/>
          </GridToolbarContainer>
        );
      }

    /**
     * When no results or rows are found display the below jsx in the grid
     * @returns 
     */
    function noRowFound(){
        
        return(

            <div className="empty-cbit-grid">
                <p>
                    {noRowsText}
                </p>
            </div>
        ) 
    }

    return (  
        <div className="cbit-data-grid-container">
            <DataGrid
                className="cbit-data-grid" 
                loading={isLoading}       
                initialState={
                    {pagination: {
                        paginationModel: {
                            pageSize: getPageSize(),
                        }                
                    }}
                }
                disableColumnMenu={true}
                columnVisibilityModel={
                    props.columnVisibilityModel
                }                
                pageSizeOptions={getPageOpts()}            
                rows={rowData} 
                columns={columns}
                slots={{
                    toolbar:CustomToolbar,                
                    noRowsOverlay:noRowFound,
                    noResultsOverlay:noRowFound
                }}
                />
        </div>              
    )
}

export default CbitDataGrid;