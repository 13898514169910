import React, {useEffect, useState} from "react"
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import NavSidebar from "../../../SocialConnection/components/layout/NavSidebar/NavSidebar";

type ContinuingEducationLayoutProps = {
    children: React.ReactNode
}

const ContinuingEducationLayout = ({children}: ContinuingEducationLayoutProps) => {
    const [isNavOpen, setIsNavOpen] = useState(true);
    const {height, width} = useWindowDimensions();

    const toggle = () => {
        setIsNavOpen(!isNavOpen);
    }

    useEffect(() => {
        // If the width of the device is greater than 1023px, open the navigation panel
        if (width > 1023) {
            setIsNavOpen(true);
        }

        // If the width of the device is less than 1023px, close the navigation panel
        if (width <= 1023) {
            setIsNavOpen(false);
        }

    }, [width]);

    return (
        <div className={`continuing-education-layout ${isNavOpen ? 'isOpen' : ''}`}>
            {/* Navigation Panel */}
            <div className="AccordionContent">
                <NavSidebar toggle={toggle}
                            isNavOpen={isNavOpen}
                            setIsNavOpen={setIsNavOpen}
                            title={'Continuing Education'}
                            links={[
                                {
                                    name: 'Upcoming Webinars',
                                    path: '/continuing-education/upcoming-webinars'
                                },
                                {
                                    name: 'Past Webinars',
                                    path: '/continuing-education/past-webinars'
                                },
                                {
                                    name: 'My Registrations',
                                    path: '/continuing-education/my-registrations'
                                },
                            ]}
                            adminLinks={
                                [
                                    {
                                        name: 'Upload Webinars',
                                        path: '/continuing-education/upload-webinars'
                                    }
                                ]
                            }
                />
            </div>
            {/* Main content container */}
            <div className="continuing-education-main-content">
                {children}
            </div>
        </div>
    )
}

export default ContinuingEducationLayout