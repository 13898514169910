import React from "react";
import "./ReferralPendingPage.css";

const ReferralPendingPage = () => {

    return (
        <div className="referral-submitted cbit-view-layout">
            <div className="cbit-dashboard-header">Your profile has been submitted!</div>
            <div className="referral-submitted-text">We will review your information and notify you once your file has been approved and listed in the CBIT Trainer referral system.</div>
            <a href="/referral/my-profile?display=true" className="link-to">Return to view my profile</a>
        </div>
    )
}

export default ReferralPendingPage;