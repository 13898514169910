import { SubscriptionStatusEnum } from "../Enums/SubscriptionStatusEnum";
import { IReferral } from "../Referral/Referral";
import { ILesson, Lesson } from "../lesson/Lesson";
import { UserLesson } from "../lesson/UserLesson";
import Roles from "../utility/Roles";
import UserStatus from "../utility/UserStatus";

export const MaxCharactersForDescription = 2000;

export interface ICurrentUser  {
    id: string;
    firstName: string;
    lastName: string;
    username: string;
    email:string;
    roles: Roles[];
    tokens: number;
    imageLink:string;
    haveCompletedReferralProfile:boolean
    visitedSocialConnection: boolean;
    isBanned: boolean;
    completedTraining:string;
    assignedSectionId:string;
    completedFreeConsultation:boolean;
    stripeCustomerId:string;
    subscriptionEnd:string | Date;
    subscriptionStatus:SubscriptionStatusEnum;
}

export interface IUserProfile {    
    id:string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    prefix: string;
    description: string;
    password: string;
    passwordLastChanged: string;
    passwordAttempts: number;
    roles: Roles[];
    companyId: string | null; //No longer in use
    divisionId: string | null; //No longer in use
    status: UserStatus;
    tokens:number; //The amount of free consulting sessions
    imageLink:string;
    createdAt:Date|string|null;
    updatedAt:Date|string|null;
    haveCompletedReferralProfile:boolean;
    assignedSectionId:string;
    visitedSocialConnection:boolean;
    isBanned:boolean;
    zoomEmail?:string;
    completedTraining:string;
    completedFreeConsultation:boolean;
    stripeCustomerId:string;
    subscriptionEnd:string|Date;
    subscriptionStatus:SubscriptionStatusEnum;
    numFreeConsultations:number;
}

/**
 * User bio information
 */
export interface IUserBio {
    id:string,
    prefix: string,
    name:string,
    firstName: string,
    lastName: string,    
    email: string,
    description: string,    
    roles: Roles[],
    status:UserStatus,
    title:string,
    imageLink:string,
    createdAt:Date|string|null
    updateAt:Date|string|null
    assignedSectionId:string
    VisitedSocialConnection:boolean,
    isBanned:boolean,
    zoomEmail?:string
    completedTraining:string,
    completedFreeConsultation:boolean,
    tokens:number,
    stripeCustomerId:string,
    subscriptionEnd:string|Date,
    subscriptionStatus:SubscriptionStatusEnum,
    numFreeConsultations:number,
}

export interface IUserBioWithReferral {
    id:string,
    prefix: string,
    name:string,
    firstName: string,
    lastName: string,
    email: string,
    description: string,
    roles: Roles[],
    status:UserStatus,
    title:string,
    imageLink:string,
    createdAt:Date|string|null,
    updateAt:Date|string|null,
    assignedSectionId:string,
    referral:IReferral,
    subscriptionEnd:string|Date,
    subscriptionStatus:SubscriptionStatusEnum,
}

export class UserBio implements IUserBio {
    tokens: number = 3;
    zoomEmail?: string | undefined;
    id: string = "";
    prefix: string = "";
    name: string = "";
    firstName: string = "";
    lastName: string = "";
    email: string = "";
    description: string = "";
    roles: Roles[] = [];
    status: UserStatus = UserStatus.Deactivated;
    title: string = "";
    imageLink: string = "";
    createdAt:Date|string|null = "";
    updateAt:Date|string|null = "";
    assignedSectionId:string = ""
    VisitedSocialConnection: boolean = false;
    isBanned: boolean = false;    
    completedTraining: string = "";
    completedFreeConsultation:boolean = false;
    stripeCustomerId:string = "";
    subscriptionEnd: string | Date = new Date();
    subscriptionStatus: SubscriptionStatusEnum = SubscriptionStatusEnum.expired;
    numFreeConsultations:number = 0;
}

export enum UserProgressEnum {
    completed = "Completed",
    notStart = "Not Started",
    inProgress = "inProgress"
}

/**
 * Class that have user training progress and their referral profile
 */
export class UserProgressAndReferral extends UserBio implements IUserBioWithReferral {
    constructor(userLesson:UserLesson, currentLesson:Lesson, referral:IReferral) {
        super();
        this.currentLesson = currentLesson;
        this.currentUserLesson = userLesson;
        this.referral = referral;
    }

    referral: IReferral;
    currentUserLesson:UserLesson;
    currentLesson:Lesson;

    /**
     * Get user progress from the their lesson taglist      
     * @returns 
     */
    public static getUserProgress(user:UserProgressAndReferral) {

        if(user.completedTraining === user.assignedSectionId) {
            return UserProgressEnum.completed;
        }

        if(!user.currentLesson) {
            return UserProgressEnum.notStart;
        }

        return UserProgressEnum.inProgress;     
    }
}

/**
 * Checks to see if a user is a consult or not
 * note: an admin and super admin will return true
 */
export function CheckIfConsultant(roles:Roles[]) {
    return roles.includes(Roles.Consultant) || roles.includes(Roles.Admin) ||  roles.includes(Roles.SuperAdmin);
}

/**
 * Checks to see if a user is an admin or super admin
 * @param roles 
 * @returns 
 */
export function CheckIfAdmin(roles:Roles[]) {
    return roles.includes(Roles.Admin) ||  roles.includes(Roles.SuperAdmin);
}

/**
 * Checks to see if a user is a super admin
 * @param roles 
 * @returns 
 */
export function CheckIfSuperAdmin(roles:Roles[]) {
    return roles.includes(Roles.SuperAdmin);
}