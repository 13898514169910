import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { CurrentUserState } from '../../../store/CurrentUser';
import PasswordVisibilityToggle from '../../Utilities/PasswordVisibilityToggle/PasswordVisibilityToggle';
import './PasswordReset.css';
import { UserController } from '../../../controllers/UserController';
import { PasswordUpdateRequest } from '../../../models/requests/PasswordUpdateRequest';
import toast from 'react-hot-toast';

type PasswordFormValues = {
    oldPassword:string
    newPassword:string
    passwordConfirmation:string
}

const userController = new UserController();

/**
 * Form for resetting an user's password 
 * @returns 
 */
const PasswordReset = () => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser); 

    const initialPasswordFormValues:PasswordFormValues = {
        oldPassword: '',
        newPassword: '',
        passwordConfirmation: ''
    }
    
    const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
    const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const minErrorMsg = (fieldName:string, minLen:number) => (`${fieldName} must be ${minLen} or more characters`);

    const passwordValidationSchema = Yup.object({
        oldPassword: Yup.string().required("Please enter your old password"),
        newPassword: Yup.string()
                        .min(6, minErrorMsg('Password', 6))
                        .required('Please enter a new password'),
        passwordConfirmation: Yup.string()
                            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match') 
                            .required("Please confirm your password"),
    });
    
    const toggleOldPasswordVisibility = () => {
        setIsOldPasswordVisible(!isOldPasswordVisible);
    }

    const toggleNewPasswordVisibility = () => {
        setIsNewPasswordVisible(!isNewPasswordVisible);
    }

    const togglePasswordConfirmVisibility = () => {
        setIsPasswordConfirmVisible(!isPasswordConfirmVisible);
    }

    /**
     * Get if a form field should be of type text or password 
     * @param showPassword 
     * @returns 
     */
    const getTextOrPwdFieldType = (showPassword:boolean) => {
        return showPassword ? 'text' : 'password';
    }

    /**
     * Handles submitting our password data to the api
     * @param passwordStateValues 
     * @param setSubmitting 
     * @returns 
     */
    const handleSubmit = async (passwordStateValues:PasswordFormValues ,resetForm:() => void) => {
        if(!userStore) return;
        
        const loadingToastId = "loadingUpdatePwdToast";

        setIsLoading(true);
        toast.loading("Updating...", {id: loadingToastId})

        const {id} = userStore.userProfile

        const {newPassword, oldPassword} = passwordStateValues;

        const pwdUpdateReq:PasswordUpdateRequest =  {
            oldPassword,
            newPassword
        }

        try {
            
            const updatedUser = await userController.UpdateUserPassword(id, pwdUpdateReq);

            toast.dismiss(loadingToastId);

            if(!updatedUser) {
                toast.error("Failed to update password");
            } else {
                toast.success("Successfully updated password")
            }
            
        } catch (error) {     
            toast.dismiss(loadingToastId);
            for(let err of error as string[]) {
                toast.error(err);
            }       
        }
        
        resetForm();
        setIsLoading(false);        
    } 


    return (
        <div className="container">
            <Formik
                enableReinitialize={true}
                initialValues={initialPasswordFormValues}
                validationSchema={passwordValidationSchema}
                onSubmit={(values, {resetForm}) => {handleSubmit(values, resetForm)}}
            >
                <Form
                    translate={"yes"}
                    className="password-reset-form form"
                >
                    <h2 className="form-title">
                        Password Reset
                    </h2>
                    <div className="cbit-form-group">
                        <div className="cbit-row">
                            <div className="cbit-column">
                                <label className='form-label' htmlFor="oldPassword">Old Password {" "}
                                    <span className="required-field">*</span>
                                </label>
                            </div>
                        </div>
                        <div className="cbit-row">
                            <div className="cbit-column">
                                <div className="password-container">
                                    <Field
                                        id="oldPassword"
                                        name="oldPassword"
                                        className="form-input form-control"
                                        type={getTextOrPwdFieldType(isOldPasswordVisible)}
                                        placeholder="Old Password"

                                        />
                                    <PasswordVisibilityToggle
                                        togglePasswordVisibility={toggleOldPasswordVisibility}
                                        isPwdVisible={isOldPasswordVisible}
                                    />
                                </div>
                                <div className="error-message-container">
                                    <ErrorMessage name="oldPassword"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cbit-form-group">
                        <div className="cbit-row">
                            <div className="cbit-column">
                                <label className='form-label' htmlFor="newPassword">New Password {" "}
                                    <span className="required-field">*</span>
                                </label>
                            </div>
                        </div>
                        <div className="cbit-row">
                            <div className="cbit-column">
                                <div className="password-container">
                                    <Field
                                        id="newPassword"
                                        name="newPassword"
                                        className="form-input form-control"
                                        type={getTextOrPwdFieldType(isNewPasswordVisible)}
                                        placeholder="New Password"
                                        />
                                    <PasswordVisibilityToggle
                                        togglePasswordVisibility={toggleNewPasswordVisibility}
                                        isPwdVisible={isNewPasswordVisible}
                                    />
                                </div>
                                <div className="error-message-container">
                                    <ErrorMessage name="newPassword"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cbit-form-group">
                        <div className="cbit-row">
                            <div className="cbit-column">
                                <label className='form-label' htmlFor="passwordConfirmation">Password Confirmation {" "}
                                    <span className="required-field">*</span>
                                </label>
                            </div>
                        </div>
                        <div className="cbit-row">
                            <div className="cbit-column">
                                <div className="password-container">
                                    <Field
                                        id="passwordConfirmation"
                                        name="passwordConfirmation"
                                        className="form-input form-control"
                                        type={getTextOrPwdFieldType(isPasswordConfirmVisible)}
                                        placeholder="Password Confirmation"
                                        />
                                    <PasswordVisibilityToggle
                                        togglePasswordVisibility={togglePasswordConfirmVisibility}
                                        isPwdVisible={isPasswordConfirmVisible}
                                    />
                                </div>
                                <div className="error-message-container">
                                    <ErrorMessage name="passwordConfirmation"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        isLoading ?
                        (
                            <button
                                className="btn-cbit-primary"
                                disabled={true}
                            >
                                Updating...
                            </button>
                        )
                        :
                        (
                            <button className="btn-cbit-primary">
                                Update
                            </button>
                        )
                    }
                </Form>
            </Formik>
        </div>
    )
}

export default PasswordReset;