import * as React from 'react';
import { Link } from "react-router-dom";
import ConsultationReviewChecker from '../../ConsultationDashboard/ConsultationForms/ConsultationReviewChecker';
import './UserDashboard.css';
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {CurrentUserState} from "../../../store/CurrentUser";
import userProfile from "../UserProfile/UserProfile";
import ToolTipWrapper from '../../Utilities/ToolTipWrapper/ToolTipWrapper';
import { RolesHelper } from '../../../models/utility/Roles';

const UserDashboard = () => {
    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state )=> state.currentUser);

    /**
     * Checks if the user have completed the entire course/training
     * @returns 
     */
    const checkIfUserCompletedCourse = () => {
        if(!UserStore) {
            return false;
        }
        const {userProfile} = UserStore;

        if(RolesHelper.CheckIfConsultant(userProfile.roles)) {
            return true;
        }
        
        return userProfile.completedTraining === userProfile.assignedSectionId;
    }

    /**
     * Checks if the user have completed their 3 free consultation sessions
     * @returns 
     */
    const checkIfCompletedFreeConsultations= () => {
        if(!UserStore) {
            return false;
        }
        const {userProfile} = UserStore;

        //Consultants do not have to complete 3 sessions and this checks for admins as well
        if(RolesHelper.CheckIfConsultant(userProfile.roles)) {
            return true;
        }
        
        return userProfile.completedFreeConsultation;
    }

    return (
        <>

            <div className='user-dashboard'>
                <div className="training-box dashboard-box">
                    <Link to={'/learningDashboard/618b0a975f552467c3293e03'}>
                        <span className='dashboard-text'>TRAINING</span>
                    </Link>
                </div>
                <div className="toolbox-box dashboard-box">
                    <Link  to={`/therapist-toolbox`}>
                        <span className='dashboard-text'>Therapist Toolbox</span>
                    </Link>
                </div>
                {
                        checkIfUserCompletedCourse() ? 
                        (
                            <div className="consult-box dashboard-box">
                                <Link to={`/consultation/booking`}>
                                    <span className='dashboard-text'>Consultation</span>
                                </Link>
                            </div>
                        )
                        :
                        (
                            <div className="consult-box dashboard-box disabled">
                                <ToolTipWrapper toolTipText={"You must complete the training to unlock consultations"}>                                    
                                    <span className='dashboard-text'>Consultation</span>                                    
                                </ToolTipWrapper>
                            </div>
                        )
                }
                <div className={`social-box ${UserStore?.userProfile.isBanned ? 'disabled' : ''} dashboard-box ${UserStore?.userProfile.isBanned ? 'disabled' : ''}`}>
                    <Link to={`/social-connection`}>
                        <span className='dashboard-text'>Social Connection</span>
                    </Link>
                </div>
                <div className="family-box dashboard-box">
                    <Link to={`/family-support`}>
                        <span className='dashboard-text'>FAMILY SUPPORT</span>
                    </Link>
                </div>
                {
                    checkIfCompletedFreeConsultations() ? 
                    (
                        <div className="referral-box dashboard-box">
                            <Link to={`/referral/my-profile`}>
                                <span className='dashboard-text'>REFERRAL</span>
                            </Link>
                        </div>
                    )
                    :
                    (
                        <div className="referral-box dashboard-box disabled">
                            <ToolTipWrapper toolTipText={"You must complete 3 consultation sessions to unlock referrals"}>                                
                                <span className='dashboard-text'>REFERRAL</span>
                            </ToolTipWrapper>
                        </div>
                    )
                }
                <div className="ceu-box dashboard-box">
                    <Link to={`/continuing-education/upcoming-webinars`}>
                        <span className='dashboard-text'>Continuing Education</span>
                    </Link>
                </div>
            </div>
            <ConsultationReviewChecker/>
        </>
    )
}

export default UserDashboard;
