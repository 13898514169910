import React from "react";
import { IReferral } from "../../../models/Referral/Referral";
import CloudflareImageHelper from "../../../models/utility/CloudflareImageHelper";
import noProfileSrc from "../../../resources/images/user-placeholder.png";

type ReferralProfileImageProps = {
    referral:IReferral
    imageVariant:string
}

const ReferralProfileImage = (props:ReferralProfileImageProps) => {
    const {referral, imageVariant} = props;
    const imageHostUrl = CloudflareImageHelper.imageUrl;

    return (
        <div className="referral-image-container">
        {
            <div className="referral-image">
                {

                    referral && referral.imageLink && referral.imageLink.length > 0 ? 
                    (
                        <img className='profile-image-icon' src={`${imageHostUrl}${referral.imageLink}/${imageVariant}`} alt="Profile Image" />
                    )
                    :
                    (                                                                        
                        <img className='profile-image-icon' src={noProfileSrc} alt="No Profile Image" />
                    )
                }
            </div>                                                                                                         
        }
        </div>
    )
}

export default ReferralProfileImage;