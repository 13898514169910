export interface IVideoContent {
    videoName:string     
    videoDescription:string      
    videoLink:string     
    videoLength:string  
    completed:boolean
}

export class VideoContent implements IVideoContent {
    videoName = '';
    videoDescription = '';
    videoLink = '';
    videoLength = '';
    completed = false;
    /**
     *
     */
    constructor(videoContent : IVideoContent = {
        videoName: '',
        videoDescription: '',
        videoLink: '',
        videoLength: '',
        completed:false,
    }) {
        this.videoName = videoContent.videoName
        this.videoDescription = videoContent.videoDescription
        this.videoLink = videoContent.videoLink
        this.videoLength = videoContent.videoLength                
        this.completed = videoContent.completed
    }
}