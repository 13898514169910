import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {ReactComponent as ProfileIcon} from '../../../resources/icons/icon-profile.svg';
import './UserProfileMenu.css';
import {useSelector} from 'react-redux';
import {ApplicationState} from '../../../store';
import {CurrentUserState} from '../../../store/CurrentUser';
import {StringHelper} from '../../../models/utility/StringHelper';
import CloudflareImageHelper from '../../../models/utility/CloudflareImageHelper';
import {NotificationController} from "../../../controllers/NotificationController"
import { actionCreators } from '../../../store/CurrentUser';

type UserProfileMenuProps = {
    userId:string
    firstName:string
    lastName:string
    isAdmin:boolean
    logOutFunc:() => void
}

type UserProfileMenuState = {
    isUserItemsActive:boolean
}

/**
 * The user profile navigation menu user, admin, and super admin tools
 */
const UserProfileMenu = (props:UserProfileMenuProps) => {

    const {userId, firstName, lastName, isAdmin, logOutFunc} = props;

    const [totalCount, setTotalCount] = useState<number>(0);

    const userProfileIconRef = useRef<any>(null);

    const {updateNotificationCount} = actionCreators;

    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>((state )=> state.currentUser);

    const nController = new NotificationController();

    const userMenuRef = useRef<HTMLDivElement>(null);

    const imageHostUrl = CloudflareImageHelper.imageUrl;

    const defaultState:UserProfileMenuState = {
        isUserItemsActive: false
    }

    const [state, setState] = useState(defaultState);

    useEffect(() => {
        if(userProfileIconRef)
            transformProfileIconLetter(userProfileIconRef.current);

        if(userMenuRef) {
            document.addEventListener("mousedown", handleOutsideUserMenuClick);
            document.addEventListener("touchstart", handleOutsideUserMenuClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideUserMenuClick);
            document.removeEventListener("touchstart", handleOutsideUserMenuClick);
        }
    },[userMenuRef]);

    useEffect(() => {
        function onUserFirstNameChange() {
            if(userProfileIconRef) {
                transformProfileIconLetter(userProfileIconRef.current);
            }
        }
        onUserFirstNameChange();
    },[firstName])

    /**
     * toggles the user menu items
     */
    const toggleUserMenu = () => {
        const isActive = !state.isUserItemsActive;

        setState(prevState => ({
            ...prevState,
            isUserItemsActive: isActive
        }));
    }

    const setActiveMenuClass = () => {
        const isUserItemsActive = state.isUserItemsActive;

        return isUserItemsActive ? "menu-items-active" : "menu-items-inactive";
    }

    const setActiveIconClass = () => {
        const isUserItemsActive = state.isUserItemsActive;

        return isUserItemsActive ? "user-profile-menu-icon-active" : "";
    }

    /**
     * Transforms the default icon letter to match the current logged in
     * user first name letter
     */
    const transformProfileIconLetter = (iconProfile:any) => {
        if(!userStore) {
            return;
        }
        if(userStore.userProfile.imageLink) {
            return;
        }

        if(iconProfile.lastChild){
            iconProfile.lastChild.textContent = firstName.charAt(0).toUpperCase();
        }
    }    

    /**
     * closes the user menu when clicked outside of it
     */
    const handleOutsideUserMenuClick = (event:any) => {
        if(userProfileIconRef.current && userProfileIconRef.current.contains(event.target)) {
            return;
        }
        if(userMenuRef.current && !userMenuRef.current.contains(event.target)) {                                
                setState(prevState => ({
                    ...prevState,
                    isUserItemsActive: false
                }));
            }        
    }

    /**
     * Formats the first and last name to be displayed with
     * the greeting text
     */
    const formatNameForGreeting = () => {
        const firstLetter = firstName[0].toUpperCase();
        
        const capitalizedFirstName = firstLetter + firstName.slice(1, firstName.length);

        return `${capitalizedFirstName} ${lastName.charAt(0).toUpperCase()}.`
    }

    /**
     * Sets the total number of unread notifications
     * for the current user
     */
    useEffect(() => {
        setTotalCount(userStore!.notificationCount);

        if (totalCount != userStore!.notificationCount) {
            setTotalCount(userStore!.notificationCount);
        }

    }, [userStore!.notificationCount]);

    return (
        <div className="user-profile-menu">
            <div className="welcome-text-container">
                <p className='greeting-text'>
                    <span className='greeting'>
                        Hello,
                    </span>
                    {" "}
                    <>
                        {formatNameForGreeting()}
                    </>
                </p>
            </div>            
            <div className="user-profile-menu-icon-container">
                <div className={`user-profile-menu-icon ${setActiveIconClass()}`}>
                        {
                            userStore && !StringHelper.IsNullOrWhiteSpace(userStore.userProfile.imageLink) ? 
                            (
                                <div                                     
                                    onClick={() => toggleUserMenu()} 
                                    ref={userProfileIconRef}  
                                >
                                    <img 
                                        className='profile-image-icon'  
                                        src={`${imageHostUrl}${userStore.userProfile.imageLink}/navbarUserImage`} 
                                    />
                                    {totalCount > 0 && (
                                            <span className="profile-image-icon badge">{totalCount}</span>
                                    )}
                                </div>
                            )
                            :
                            (
                                <>
                                    <ProfileIcon
                                        onClick={() => toggleUserMenu()}
                                        ref={userProfileIconRef}
                                    />
                                    {totalCount > 0 && (
                                        <span className="profile-image-icon badge">{totalCount}</span>
                                    )}

                                </>
                            )
                        }

                </div>
            </div>

            <div ref={userMenuRef} aria-expanded={state.isUserItemsActive} className={`menu-item-container menu-arrow-top ${setActiveMenuClass()}`}>
                <ul className="menu-items">
                    <li className='menu-item'>
                        <Link to="/my-profile" className="menu-item-link" onClick={toggleUserMenu}>
                            <span className='menu-item-text'>
                                My Profile
                            </span>
                        </Link>
                    </li>
                    <li className='menu-item mobile'>
                        <Link to="/home" className="menu-item-link" onClick={toggleUserMenu}>
                            <span className='menu-item-text'>
                                Home
                            </span>
                        </Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/password-reset" className="menu-item-link" onClick={toggleUserMenu}>
                            <span className="menu-item-text">
                                Password Reset
                            </span>
                        </Link>
                    </li>
                    {userStore && userStore.notificationCount > 0 ? (
                        <li className='menu-item active'>
                            <Link to="/inbox" className="menu-item-link" onClick={toggleUserMenu}>
                                <span className='menu-item-text'>
                                    Inbox <span className="profile-image-icon notification-badge">{totalCount}</span>
                                </span>
                            </Link>
                        </li>
                    ) : (
                        <li className='menu-item'>
                            <Link to="/inbox" className="menu-item-link" onClick={toggleUserMenu}>
                                <span className='menu-item-text'>
                                    Inbox
                                </span>
                            </Link>
                        </li>
                    )}
                    {userStore && userStore.isAdmin || userStore && userStore.isSuperAdmin ? (
                        <li className='menu-item mobile'>
                            <Link to="/admin-tools" className="menu-item-link" onClick={toggleUserMenu}>
                                <span className='menu-item-text'>
                                    Admin Tools
                                </span>
                            </Link>
                        </li>
                    ) : null}
                    {/* <li className='menu-item'>
                        <Link to="" className="menu-item-link" onClick={toggleUserMenu}>
                            <span className='menu-item-text'>
                                Inbox
                            </span>
                        </Link> 
                    </li>
                    <li className='menu-item'>
                        <Link to="" className="menu-item-link" onClick={toggleUserMenu}>
                            <span className='menu-item-text'>
                                Account Setting
                            </span> 
                        </Link> 
                    </li> */}
                    
                    <li className='menu-item'>
                        <Link 
                            to="/logout" 
                            className="menu-item-link"                            
                        >
                            <span className='menu-item-text'>
                                Log Out
                            </span> 
                        </Link> 
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default UserProfileMenu;