import { AxiosError } from "axios";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { ConsultationReceiptService } from "../services/ConsultationReceiptService";

/**
 * Controller for Consultation Receipt
 */
export class ConsultationReceiptController{

    private consultationReceiptService;

    constructor()
    {
        this.consultationReceiptService = new ConsultationReceiptService();
    }

    /**
     * Calls services to get a compatible receipt 
     * @param userId 
     * @param consultationId 
     * @returns 
     */
    public async GetUserCompatibleReceipt(userId:string, consultationId:string) {
        try {
            return await this.consultationReceiptService.GetUserCompatibleReceipt(userId, consultationId);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Calls services to get a receipt by the original consultation id
     * @param consultationId 
     * @returns 
     */
    public async GetUserReceiptByOriginalConsultation(consultationId:string) {
        try {
            return await this.consultationReceiptService.GetUserReceiptByOriginalConsultation(consultationId);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

        /**
     * Get all user consultation receipts
     */
    public async GetUserReceipts() {
        try {            
            return await this.consultationReceiptService.GetUserReceipts();
        } catch (error) {
            throw error;
        } 
    }
}