import React from "react";

export type AttendanceLabelProps = {
    idx:number
}

const AbsentLabel = (props:AttendanceLabelProps) => (    
    <label className="attendance-label absent-label" htmlFor={`absent-${props.idx}`}>
        <i className="absent-icon bi bi-x-lg"></i> 
    </label>
)

export default AbsentLabel;

