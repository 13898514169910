enum UserStatus {
    Deactivated = 0,
    Active = 1,    
}

export const UiFriendlyStatus = {
    [UserStatus.Active]: 'Active',
    [UserStatus.Deactivated]: 'Deactivated',
}

export default UserStatus;