// Interface for module objects
import {ILesson} from "../lesson/Lesson";
import { TreeViewType } from "../partialModels/PartialModuleTreeModel";
import {ModuleType} from "./ModuleType";

/**
 * The id of the current parent module. 
 * This is hard coded because of "people" refusal to add identifiers to the module class 
 * that would identify the main training 
 */
export const cbitTrainingID = "618b0a975f552467c3293e03";

export interface IModule {
    id: string;

    // The ID of the module's parent if it has one; When doing validation string cannot be empty but the property can be null
    parentModule : string | null; 

    name: string;
    
    // Description of the module 
    description : string; 
    
    // The order of the module
    order : number;

    // The type of module
    type : ModuleType;

    // List of submodules
    modules : IModule[] | null;

    // List of lessons in the module
    lessons : ILesson[] | null;
    
    combinedList: PartialModuleType[] |null;

    isViewable?: boolean;

    isDisabled?: boolean;

    isEditable?: boolean;

    isOptional?: boolean;

    createdAt: Date| string| undefined | null;
    updatedAt: Date| string| undefined | null;


}

// The type for parent modules
export type PartialModuleType = {
    id:string;    
    name:string;
    description:string;
    isLesson:boolean;
    order:number;
}

// 
export class Module implements IModule {
    id = '';
    parentModule: string | null = null;
    name = '';
    description = '';
    order = 0;
    type = ModuleType.branch;
    modules: IModule[] | null = [];
    lessons: ILesson[] | null = [];
    combinedList: PartialModuleType[]|null=[];

    isViewable = true;
    isDisabled = false;
    isEditable = true;
    createdAt: Date | string | undefined | null = new Date();
    updatedAt: Date | string | undefined | null = new Date();

    // Constructor for assigning module properties; Defaults if moduleDate is not given
    
    constructor(moduleData?: IModule)
    {
        this.id = moduleData?.id? moduleData.id : '';
        this.parentModule = moduleData?.parentModule? moduleData?.parentModule: null;
        this.name = moduleData?.name? moduleData.name: "";
        this.description = moduleData?.description? moduleData.description:"";
        this.order = moduleData?.order? moduleData.order:0;
        this.type = moduleData?.type? moduleData?.type: ModuleType.branch;
        this.modules = moduleData?.modules?moduleData?.modules: null;
        this.lessons = moduleData?.lessons?moduleData?.lessons: null;
        this.createdAt = moduleData?.createdAt;
        this.updatedAt = moduleData?.updatedAt;
    }


    public getId():string{
        return this.id;
    }
}

/**
 * Returns whether or not that passed in object is a module
 * @param module 
 */
export function IsInstanceOfModule(module:IModule) {
    
    if(module.type === ModuleType.subLesson){
        return false;
    }
    
    //if the property name changes or if another object has the same property this will break. 
    return "parentModule" in module;
}