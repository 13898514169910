import DOMPurify from "dompurify";

/**
 * Static class for sanitizing HTML
 */
export default class SanitizeHTML {
    /**
     * JS do not have static classes so to prevent class initialization we throw an error
     */
    constructor() {
        if(this instanceof SanitizeHTML){
            throw Error('SanitizeHTML is a static class and cannot be initialized');
        }        
    }

    /**
     * Uses dompurify to sanitize html
     * @param dirtyHTML 
     * @returns 
     */
    static sanitize = (dirtyHTML:string) : string => {
        const purify = DOMPurify(window);
        
        let cleanHTML = purify.sanitize(dirtyHTML, {USE_PROFILES: {html:true, svg: false}}); 
        
        return cleanHTML;
    }
}