import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { v4 as uuidV4 } from 'uuid';
import { ConsultationController } from '../../../../controllers/ConsultationController';
import { IConsultation } from '../../../../models/consultations/Consultation';
import { ConsultationSession, IConsultationSession } from '../../../../models/consultations/ConsultationSession';
import { ConsultationType } from '../../../../models/consultations/ConsultationType';
import { SortByEnum } from '../../../../models/Enums/SortByEnum';
import { CheckIfAdmin, CheckIfConsultant, CheckIfSuperAdmin } from '../../../../models/module/UserProfile';
import { RecurringConsultationRequest } from '../../../../models/requests/RecurringConsultationRequest';
import { DeepCopy } from '../../../../models/utility/DeepCopy';
import { StringHelper } from '../../../../models/utility/StringHelper';
import TimeHelper from '../../../../models/utility/TimeHelper';
import { ApplicationState } from '../../../../store';
import { CurrentUserState } from '../../../../store/CurrentUser';
import CustomAlert from '../../../Utilities/CustomAlert';
import CustomModal from '../../../Utilities/CustomModal';
import ToggleButton from '../../../Utilities/ToggleButton/ToggleButton';
import ConsultantPlanForm from '../../ConsultationForms/ConsultantPlanForm';
import RecurringAlert from '../../ConsultationForms/RecurringAlert';
import SessionItem, { SessionItemType } from '../../ConsultationItems/SessionItem';
import CancelSessionAlert from './CancelSessionAlert';
import './UpcomingSessionsPage.css';

export enum consultationFilterDates {
    Today = 0,
    Next7Days = 1,
    Next30Days = 2,
    Next60Days = 3
}

type UpcomingSessionsPageState = {
    currentFilterType:ConsultationType
    currentFilterDate:consultationFilterDates
    upcomingConsultations:IConsultationSession[]
    selectedConsultation:IConsultation | null
    isLoading:boolean    
    isCancelAlertOpen:boolean
    isEditConsultationOpen:boolean
    consultationSessionForEditing:IConsultationSession | null    
    consultationForDeleting:IConsultation | null
}

const consultationController = new ConsultationController();

/**
 * Component page for upcoming consultations
 * Note: Consultants and professionals share the same upcoming sessions page
 * because consultants can also book meetings just like professionals
 */
const UpcomingSessionsPage = () => {    
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    const isFirstRender = useRef(true);

    const defaultState:UpcomingSessionsPageState = {
        currentFilterType: ConsultationType.Unknown,
        currentFilterDate: consultationFilterDates.Next7Days,
        upcomingConsultations:[],
        selectedConsultation:null,
        isLoading:false,
        isCancelAlertOpen:false,
        isEditConsultationOpen:false,
        consultationSessionForEditing:null,        
        consultationForDeleting:null
    }

    const [state, setState] = useState(defaultState);

    const [isRecurringAlertOpen, setRecurringAlert] = useState(false);

    const [isDeleteNormalAlertOpen, setDeleteNormalAlert] = useState(false);
        
    const [isDeleteRecurringAlertOpen, setDeleteRecurringAlert] = useState(false);

    const [isDeleteOccurrenceAlertOpen, setDeleteOccurrenceAlert] = useState(false);  

    const [isDisplayEmpty, setDisplayEmpty] = useState(false);

    const [showAllSessions, setShowAllSessions] = useState(false);

    const [sortBy, setSortBy] = useState(SortByEnum.asc);

    useEffect(() => {
        function onComponentMount() {
            isFirstRender.current = false
        }
        onComponentMount();
    }, []);

    useEffect(() => {
        async function onFilterChangeAndMount() {
            getUpcomingSessions();            
        }
        onFilterChangeAndMount();
    },[state.currentFilterDate, state.currentFilterType]);

    useEffect(() => {
        async function onDisplayEmptyUpdate() {            
            getUpcomingSessions();                        
        }
        if(!isFirstRender.current) {
            onDisplayEmptyUpdate();
        }
    },[showAllSessions]);

    useEffect(() => {
        function sortConsultationOnSortByChange() {
            const isDesc = sortBy === SortByEnum.desc;
            const sessionListCopy:IConsultationSession[] = DeepCopy.copy(state.upcomingConsultations);
            const sortedSessions = ConsultationSession.SortConsultationSessionsByTime(sessionListCopy, isDesc);
            setState(prevState => ({
                ...prevState,
                upcomingConsultations:sortedSessions
            }));
        }
        sortConsultationOnSortByChange();
    }, [sortBy]);

    /**
     * Get upcoming consultation sessions based on the current date
     */
    const getUpcomingSessions = async () => {
        if(!userStore) return;

        setState(prevState => ({...prevState, isLoading:true}));

        let startDate = TimeHelper.createMomentDate(new Date());
        
        //We are subtracting 1 hour from startDate so upcoming consultation can at least stay on the page for an hour (requested by the client)           
        //Happens on the backend
        // startDate.subtract(1, "hours");        
                
        let endDate = moment(startDate).endOf("day").toDate();                 
        
        switch (state.currentFilterDate) {
            case consultationFilterDates.Next7Days:
                endDate = moment(startDate).add(7, "day").toDate();                
                break;
            case consultationFilterDates.Next30Days:
                endDate = moment(startDate).add(30, "day").toDate();
                break
            case consultationFilterDates.Next60Days:
                endDate = moment(startDate).add(60, "day").toDate();
                break;
            default:
                break;
        }
        try {
            let upcomingSessions:ConsultationSession[] = []
            if(showAllSessions) {
                upcomingSessions = await consultationController.GetAllUpcomingConsultations(moment(endDate).endOf("day").format());  
            } 
            else {
                upcomingSessions = await consultationController.GetUpcomingConsultations(
                    userStore.userProfile.id, startDate.format(), moment(endDate).endOf("day").format());                        
            }

            if(state.currentFilterType !== ConsultationType.Unknown) {
                upcomingSessions = upcomingSessions.filter(session => session.consultation.type === state.currentFilterType);
            }            

            ConsultationSession.SortConsultationSessionsByTime(upcomingSessions, sortBy === SortByEnum.desc);

            setState(prevState => ({
                ...prevState, 
                isLoading:false,
                upcomingConsultations:upcomingSessions
            }));

        } catch (error) {
            console.error(error);
            toast.error("Failed to retrieve consultation sessions");
            setState(prevState => ({...prevState, isLoading:false}));
        }
    }

    const toggleShowAllSessions = () => {
        setShowAllSessions(!showAllSessions);
    }

    const handleOnSortChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.currentTarget.value;

        const parseValue = parseInt(value);

        if(!isNaN(parseValue)) {
            setSortBy(parseValue);
        }
    }

    /**
    * Handles input select change and updates state
    * @param e 
    */
    const handleInputChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
        const name = e.target.name;

        //Because the values are converted to strings by html we need to convert them back 
        const value = parseInt(e.target.value);

        setState((prevState:any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const toggleCancelAlert = () => (setState(prevState => ({...prevState, isCancelAlertOpen:!state.isCancelAlertOpen})))

    /**
     * Cancels a consultation as in removing the current logged in user from the attendee list.
     * It does not delete the consultation.
     * @returns 
     */
    const cancelConsultation = async () => {
        if(!userStore) return;

        if(!state.selectedConsultation) return;

        const toastCancelLoadId = "toastCancelLoad";

        try {            
            toast.loading("Canceling consultation session", {id: toastCancelLoadId});

            setState(prevState => ({...prevState, isLoading:true}));

            const withdrawnConsultation:IConsultation = await consultationController.WithdrawFromConsultation(state.selectedConsultation.id, userStore.userProfile.id);

            let upcomingSessions:ConsultationSession[] = DeepCopy.copy(state.upcomingConsultations);

            upcomingSessions =upcomingSessions.filter(session => session.consultation.id !== withdrawnConsultation.id);

            toast.dismiss(toastCancelLoadId);

            setState(prevState => ({
                ...prevState,
                isLoading:false,
                upcomingConsultations:upcomingSessions,
                selectedConsultation:null,
                isCancelAlertOpen:false
            }));

        } catch (error) {
            console.error(error);
            toast.dismiss(toastCancelLoadId);
            setState(prevState => ({...prevState, isLoading:false}));
            toast.error("Failed to cancel consultation");
        }
    }

    /**
     * Selects a consultation to be available to be canceled when the cancel alert pops open
     * @param consultation 
     */
    const selectConsultationToCancel = (consultation:IConsultation) => {        
        setState(prevState => ({
            ...prevState,
            selectedConsultation:consultation
        }));
    }

    /**
     * toggles the edit consultation form
     * only for consultants
     */
    const toggleEditConsultationModal =  () => {
        
        if(!userStore) return;

        if(!CheckIfConsultant(userStore.userProfile.roles)) return;

        setState((prevState) => ({...prevState, isEditConsultationOpen:!state.isEditConsultationOpen}));
    }

    /**
     * Set the consultation that will be edited and reject non consultants
     * @param consultation 
     * @returns 
     */
    const setConsultationToBeEdited = (consultationSession:IConsultationSession) => {
        if(!userStore) return;

        const {consultation} = consultationSession;

        if(!CheckIfConsultant(userStore.userProfile.roles)) return;

        if(consultation.ownerUserProfileId !== userStore.userProfile.id) return;

        setState(prevState => ({
            ...prevState,
            consultationSessionForEditing:consultationSession
        }));
    }

    /**
     * Updates a consultants consultation
     * @param consultation  
     * @returns 
     */
    const onConsultationEditSubmit = async (consultation:IConsultation) => {
        if(!userStore) return;

        if(!CheckIfConsultant(userStore.userProfile.roles)) return;

        if(consultation.ownerUserProfileId !== userStore.userProfile.id) return;

        toast.loading("Updating Consultation...", {id: "edit-consultation-toast"});        

        try {

            const updatedConsultation:IConsultation = await consultationController.UpdateConsultation(consultation);

            const updatedUpcomingConsultations = updateConsultationInUpcomingConsultations(updatedConsultation);
            
            toast.dismiss("edit-consultation-toast");
            
            if(!updatedUpcomingConsultations) {
                toast.error("Could not find consultation to update");
                return;
            }
                        
            toast.success("Successfully updated the consultation");

            ConsultationSession.SortConsultationSessionsByTime(updatedUpcomingConsultations, sortBy === SortByEnum.desc);

            setState(prevState => ({
                ...prevState,
                isEditConsultationOpen:false,                
                consultationForEditing:null,
                upcomingConsultations:updatedUpcomingConsultations
            }));
            
        } catch (error) {
            console.error(error);
            toast.dismiss("edit-consultation-toast");            
            setState(prevState => ({...prevState, isLoading:false}));
            toast.error("Failed to update consultation");   

        }
    }

    /**
     * Updates the upcoming consultations that is in state
     * @param consultation 
     * @returns 
     */
    const updateConsultationInUpcomingConsultations = (consultation:IConsultation) => {
        const upcomingConsultationSessions:ConsultationSession[] = DeepCopy.copy(state.upcomingConsultations);

        const foundIndex = upcomingConsultationSessions.findIndex(session => session.consultation.id === consultation.id);

        if(foundIndex === -1) return null;

        const consultant = upcomingConsultationSessions[foundIndex].consultant;

        const attendeesBioList = upcomingConsultationSessions[foundIndex].attendeesBioList

        const newConsultationSession:ConsultationSession = {
            consultant,
            consultation,
            attendeesBioList
        }

        upcomingConsultationSessions[foundIndex] = newConsultationSession;

        return upcomingConsultationSessions
    }

    const updateRecurringConsultation = async (recurringRequest:RecurringConsultationRequest) => {
        const toastUpdateRecurringId = "updateRecurringLoad";

        setState(prevState => ({...prevState, isLoading:true}));

        toast.loading("Updating Consultations", {id:toastUpdateRecurringId});

        try {

            await consultationController.UpdateRecurringConsultation(recurringRequest);            
            
            await getUpcomingSessions();
            
        } catch (error) {
            console.error(error);            
            toast.error("Failed to update recurring consultations");
        }
        toast.dismiss(toastUpdateRecurringId);
        setState(prevState => ({...prevState, isLoading:false}));
        toggleEditConsultationModal();
    }

    // Update a single occurrence of a recurring consultation
    const updateConsultationOccurrence = async (consultationOccurrence:IConsultation) => {
        const toastOccurrenceId = "updateOccurrenceLoad";

        setState(prevState => ({...prevState, isLoading:true}));

        toast.loading("Updating Consultation", {id:toastOccurrenceId});

        try {
            let updatedConsultation:IConsultation = await consultationController.UpdateConsultationOccurrence(consultationOccurrence);
            
            await getUpcomingSessions();

        } catch(error) {
            console.error(error);
            toast.error("Failed to update consultation occurrence")
        }
        toast.dismiss(toastOccurrenceId);
        setState(prevState => ({...prevState, isLoading:false}));
            toggleEditConsultationModal();
    }

        /**
     * Cancel the recurring on a recurring consultation
     * @param updatedConsultation 
     */
    const cancelRecurringAndCreateConsultation =  async (updatedConsultation:IConsultation) => {
        const toastCancelRecurringLoadId = "cancelRecurringLoad";

        setState(prevState => ({...prevState, isLoading:true}));

        toast.loading("Updating Consultation", {id:toastCancelRecurringLoadId});

        try {
            let newConsultation:IConsultation = await consultationController.CancelRecurringAndCreateConsultation(updatedConsultation);            

            await getUpcomingSessions();

        } catch (error) {
            console.error(error);
            console.error("Failed to cancel recurring for this consultation");
        }

        toast.dismiss(toastCancelRecurringLoadId);
        setState(prevState => ({...prevState, isLoading:false}));
        toggleEditConsultationModal();
    }

    /** when the delete button is clicked we want to set the item we want to delete and toggle the delete alert */
    const onUpcomingSessionDeleteClick = (consultation:IConsultation) => {
        setState(prevState => ({
            ...prevState,            
            consultationForDeleting:consultation
        }));        

        if(!StringHelper.IsNullOrWhiteSpace(consultation.occurrenceId)) {
            toggleRecurringAlert();
            return;
        }

        toggleDeleteNormalAlert();
    }

    const toggleRecurringAlert = () => {
        const isRecurringAlert = !isRecurringAlertOpen;

        setRecurringAlert(isRecurringAlert);
    }

    const toggleDeleteNormalAlert = () => {
        const isDeleteOpen = !isDeleteNormalAlertOpen;

        setDeleteNormalAlert(isDeleteOpen);
    }

    const toggleDeleteRecurringAlert = () => {
        const isDeleteOpen = !isDeleteRecurringAlertOpen;

        setDeleteRecurringAlert(isDeleteOpen);
    }

    const toggleDeleteOccurrenceAlert = () => {
        const isDeleteOpen = !isDeleteOccurrenceAlertOpen;

        setDeleteOccurrenceAlert(isDeleteOpen);
    }

    const handleDeleteAlertForRecurring = async () => {        
        toggleRecurringAlert();
        toggleDeleteRecurringAlert();
    }
    
    const handleDeleteAlertForOccurrence = async () => {        
        toggleRecurringAlert();
        toggleDeleteOccurrenceAlert();
    }

    /**
     * Check to see if the consultation was created by the current user
     */
    const checkIfConsultationOwner = (consultation:IConsultation) => {
        if(!userStore) return false;
    
        return userStore.userProfile.id === consultation.ownerUserProfileId;
    }

    /**
     * When the delete alert delete click we want to change the consultation status to deleted
     */
    const onDeleteAlertClick = async () => {
        if(!userStore) return;

        if(!CheckIfConsultant(userStore.userProfile.roles)) return;
        
        const consultationToDelete = state.consultationForDeleting;

        const deleteConsultationLoadId = "delete-consultation-toast";

        if(!consultationToDelete) return;

        if(consultationToDelete.ownerUserProfileId !== userStore.userProfile.id) return;

        
        toast.loading("Deleting consultation", {id:deleteConsultationLoadId});

        try {

            await consultationController.CancelConsultation(consultationToDelete.id);

            let upcomingConsultations:ConsultationSession[] = DeepCopy.copy(state.upcomingConsultations);

            upcomingConsultations = upcomingConsultations.filter(consultationItem => consultationItem.consultation.id !== consultationToDelete.id);           

            setState(prevState => ({
                ...prevState,
                upcomingConsultations,
                consultationForDeleting:null,                
            }));
            toggleDeleteNormalAlert();
            toast.dismiss(deleteConsultationLoadId);
            toast.success("Successfully deleted consultation");

        } catch (error) {
            console.error(error);
            toast.dismiss(deleteConsultationLoadId);
            toast.error("Failed to delete consultation");
        }        
    }

    const deleteRecurringConsultation = async () => {
        if(!userStore) return;

        if(!CheckIfConsultant(userStore.userProfile.roles)) return;
        
        const consultationToDelete = state.consultationForDeleting;

        const deleteConsultationLoadId = "deleteRecurringConsultationToast";

        if(!consultationToDelete) return;

        if(consultationToDelete.ownerUserProfileId !== userStore.userProfile.id) return;

        toast.loading("Deleting recurring consultations", {id:deleteConsultationLoadId});

        try {
            await consultationController.DeleteUpcomingRecurringConsultation(consultationToDelete.id);

            const upcomingSessionsCopy:IConsultationSession[] = DeepCopy.copy(state.upcomingConsultations);

            const filteredSessions = upcomingSessionsCopy.filter(cs => cs.consultation.zoomMeetingLinkId !== consultationToDelete.zoomMeetingLinkId);

            setState(prevState => ({
                ...prevState,
                upcomingConsultations:filteredSessions,
                consultationForDeleting:null,
                isLoading:false
            }));
            toggleDeleteRecurringAlert();
            toast.dismiss(deleteConsultationLoadId);

        } catch (error) {
            console.error(error);
            toast.dismiss(deleteConsultationLoadId);
            toast.error("Failed to delete consultation");
        }

        setState(prevState => ({...prevState, isLoading:false}));
        toast.dismiss(deleteConsultationLoadId);
    }

    const deleteConsultationOccurrence = async () => {
        if(!userStore) return;

        if(!CheckIfConsultant(userStore.userProfile.roles)) return;
        
        const consultationToDelete = state.consultationForDeleting;

        const deleteConsultationLoadId = "deleteConsultationOccurrenceToast";

        if(!consultationToDelete) return;

        if(consultationToDelete.ownerUserProfileId !== userStore.userProfile.id) return;

        toast.loading("Deleting recurring consultations", {id:deleteConsultationLoadId});

        try {
            await consultationController.DeleteConsultationOccurrence(consultationToDelete.id);

            const upcomingSessionsCopy:IConsultationSession[] = DeepCopy.copy(state.upcomingConsultations);

            const filteredSessions = upcomingSessionsCopy.filter(cs => cs.consultation.id !== consultationToDelete.id);

            setState(prevState => ({
                ...prevState,
                isLoading:false,
                upcomingConsultations:filteredSessions,
                consultationForDeleting:null
            }));
            toggleDeleteOccurrenceAlert();
            toast.dismiss(deleteConsultationLoadId);
        } catch (error) {
            console.error(error);
            toast.dismiss(deleteConsultationLoadId);
            toast.error("Failed to delete consultation");
        }

        setState(prevState => ({...prevState, isLoading:false}));
        toast.dismiss(deleteConsultationLoadId);
    }

    /**
     * When the edit button is clicked we want to toggle the edit form
     * and set the current consultation to be consultation that will be edited
     */
    const handleEditConsultationClick = (consultationSession:IConsultationSession) => {
        if(!userStore) return;        

        setConsultationToBeEdited(consultationSession);
        toggleEditConsultationModal();
    }

    /**
     * Toggles the cancel confirmation dialog and 
     * set the selected consultation to cancel
     */
    const handleCancelClick = (consultation:IConsultation) => {
        selectConsultationToCancel(consultation);
        toggleCancelAlert();
    }

    /**
     * Check to see if the current user is part of the consultation or not
     */
    const checkIfPartOfConsultation = (consultationSession:IConsultationSession) => {
        if(!userStore) {
            return false;
        }
        
        const {consultant, consultation, attendeesBioList} = consultationSession;

        const id = userStore.userProfile.id;

        if(consultant.id === id) {
            return true;
        }

        if(attendeesBioList.find(a => a.id === id)) {
            return true;
        }

        return false;
    }   

    return (
        <>
        <div className="consultations-container upcoming-consultations-container">
            <h2 className="consultation-header">
                Upcoming Sessions
            </h2>
            <div className="consultation-filters">
                <select 
                    id="filterConsultationType"
                    name="currentFilterType" 
                    className="form-input consultation-filter-select" 
                    value={state.currentFilterType}
                    onChange={handleInputChange}
                >
                    <option value={ConsultationType.Unknown}>All Sessions</option>
                    <option value={ConsultationType.OneOnOne}>Individual Sessions</option>
                    <option value={ConsultationType.SmallGroup}>Group Sessions</option>
                    <option value={ConsultationType.Seminar}>Seminars</option>
                </select>
                <select 
                    id="filterConsultationDate"
                    name="currentFilterDate" 
                    className="form-input consultation-filter-select" 
                    value={state.currentFilterDate}
                    onChange={handleInputChange}
                >
                    <option value={consultationFilterDates.Today}>Today</option>
                    <option value={consultationFilterDates.Next7Days}>Next 7 Days</option>
                    <option value={consultationFilterDates.Next30Days}>Next 30 days</option>
                    <option value={consultationFilterDates.Next60Days}>Next 60 days</option>
                </select>
                <select                     
                    className="form-input consultation-filter-select"
                    name="sortBy" 
                    value={sortBy}
                    onChange={handleOnSortChange} 
                >
                    <option value="" disabled> Sort Date By:</option>
                    <option value={SortByEnum.asc}>Ascending</option>
                    <option value={SortByEnum.desc}>Descending</option>                    
                </select>                            
                {
                    userStore && CheckIfSuperAdmin(userStore.userProfile.roles) && 
                    (
                        <div className="empty-session-toggle">
                            <span>Display All Consultations</span>
                            <ToggleButton
                                isToggled={showAllSessions}
                                onToggleChange={toggleShowAllSessions}
                            />
                        </div>
                    )
                }

            </div>
            <div className="upcoming-consultations">
                {
                    state.isLoading ? 
                    (
                        <Spinner></Spinner>
                    ) 
                    : 
                    (
                        <>
                        {
                            state.upcomingConsultations.length <= 0 ? 
                            (
                                <div>No upcoming Sessions Found</div>
                            )
                            :
                            (
                                <>
                                    {state.upcomingConsultations.map(upcomingSession => (
                                        <React.Fragment key={uuidV4()}>
                                            <SessionItem 
                                                sessionType={SessionItemType.upcoming} 
                                                consultationSession={upcomingSession}                                            
                                            >
                                            {
                                                checkIfConsultationOwner(upcomingSession.consultation) ? 
                                                (
                                                    <>
                                                        <button
                                                            onClick={() => handleEditConsultationClick(upcomingSession)} 
                                                            className='btn-cbit-minor btn-consultation-edit'
                                                        >
                                                            Edit Session
                                                        </button>
                                                        <button 
                                                            onClick={() => onUpcomingSessionDeleteClick(upcomingSession.consultation)}
                                                            className='btn-cbit-primary btn-consultation-delete'
                                                        >
                                                            Delete
                                                        </button>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                      {checkIfPartOfConsultation(upcomingSession) ? 
                                                        (
                                                            <button onClick={() => handleCancelClick(upcomingSession.consultation)} className="btn-cbit-minor btn-consultation-minor">
                                                                CANCEL SESSION
                                                            </button>
                                                        )
                                                        : 
                                                        (
                                                            ""
                                                        )  
                                                    }
                                                    </>
                                                )
                                            }
                                            </SessionItem>
                                        </React.Fragment>
                                    ))}
                                </>
                            )
                        }                        
                        </>
                    )
                }                
            </div>
        </div>
        {
            userStore && CheckIfConsultant(userStore.userProfile.roles) && 
            (
                <>
                <CustomModal isOpen={state.isEditConsultationOpen} toggle={toggleEditConsultationModal}>
                    {
                        state.consultationSessionForEditing && 
                        (
                            <ConsultantPlanForm
                                currentUser={userStore.userProfile}                                
                                isEditing={true}
                                isLoading={state.isLoading}
                                consultationSession={state.consultationSessionForEditing}
                                onSubmit={onConsultationEditSubmit}
                                togglePlanForm={toggleEditConsultationModal} 
                                recurringConsultationSubmit={updateRecurringConsultation}
                                updateRecurringConsultations={updateRecurringConsultation}
                                updateConsultationOccurrence={updateConsultationOccurrence}
                                cancelRecurring={cancelRecurringAndCreateConsultation}
                            />
                        )
                    }
                </CustomModal>
                <CustomModal 
                    isOpen={isRecurringAlertOpen} 
                    toggle={toggleRecurringAlert}
                >
                    <RecurringAlert
                        headerText='Delete Recurring Consultations' 
                        toggleAlert={toggleRecurringAlert} 
                        onOccurrenceUpdateSubmit={handleDeleteAlertForOccurrence} 
                        onRecurringUpdateSubmit={handleDeleteAlertForRecurring}                    
                    />
                </CustomModal>
                </>
            )
        }
            <CustomModal isOpen={state.isCancelAlertOpen} toggle={toggleCancelAlert}>
                <CancelSessionAlert  toggleModal={toggleCancelAlert} onCancelClick={cancelConsultation}/>
            </CustomModal>
            <CustomAlert 
                header={'ARE YOU SURE YOU WANT TO DELETE?'} 
                text={`Once you click "Delete", the consultation will be deleted immediately.`} 
                primaryBtnText={'DELETE'} 
                secondaryBtnText={'Cancel'} 
                isOpen={isDeleteNormalAlertOpen} 
                primaryBtnSubmit={() => onDeleteAlertClick()} 
                secondaryBtnSubmit={toggleDeleteNormalAlert}             
                toggleAlert={toggleDeleteNormalAlert}
            />   
            <CustomAlert 
                header={'ARE YOU SURE YOU WANT TO DELETE?'} 
                text={`Once you click "Delete", the consultation and all following occurrences will be deleted immediately.`} 
                primaryBtnText={'DELETE'} 
                secondaryBtnText={'Cancel'} 
                isOpen={isDeleteRecurringAlertOpen} 
                primaryBtnSubmit={() => deleteRecurringConsultation()} 
                secondaryBtnSubmit={toggleDeleteRecurringAlert}             
                toggleAlert={toggleDeleteRecurringAlert}
            />   
            <CustomAlert 
                header={'ARE YOU SURE YOU WANT TO DELETE?'} 
                text={`Once you click "Delete", this consultation occurrence will be deleted immediately.`} 
                primaryBtnText={'DELETE'} 
                secondaryBtnText={'Cancel'} 
                isOpen={isDeleteOccurrenceAlertOpen} 
                primaryBtnSubmit={() => deleteConsultationOccurrence()} 
                secondaryBtnSubmit={toggleDeleteOccurrenceAlert}             
                toggleAlert={toggleDeleteOccurrenceAlert }
            />
        </>
    )
}

export default UpcomingSessionsPage;