import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { CurrentUserState } from "../../../store/CurrentUser";
import ImageUploader from "../../Utilities/ImageUploader";
import CloudflareImageHelper from "../../../models/utility/CloudflareImageHelper";
import { IReferral} from "../../../models/Referral/Referral";
import { ReferralController } from "../../../controllers/ReferralController";
import toast from "react-hot-toast";
import { DeepCopy } from "../../../models/utility/DeepCopy";
import { ImageManagementController } from "../../../controllers/ImageManagementController";
import { UserController } from "../../../controllers/UserController";
import "./ReferralMyProfileForm.css";
import ReferralForm from "../ReferralForm/ReferralForm";
import { useHistory } from "react-router";
import { Spinner } from "reactstrap";

const referralController = new ReferralController();
const imageManagementController = new ImageManagementController();
const userController = new UserController();

type ReferralMyFormProps = {
    isEditing:boolean
    referral?:IReferral
    removedImageId?:string
    updateIsEditing:(isEdit:boolean) => void
    removeImageFromReferral:() => void
}

const ReferralMyProfileForm = (props:ReferralMyFormProps) => {
    const {
            isEditing,
            referral,
            removedImageId,
            updateIsEditing,
            removeImageFromReferral
    } = props;

    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    const imageHostUrl = CloudflareImageHelper.imageUrl;

    const [isLoading, setIsLoading] = useState(false);    

    const [profileImageFile, setProfileImageFile] = useState<File | null>(null);

    const [profileImageUrl, setProfileImageUrl] = useState("");    

    const history = useHistory();

    useEffect(() => {
        function onComponentMount() {
            checkUserHaveReferral();
        }
        onComponentMount();

        return () => {}
    }, []);


    /**
     * Check to see if the user have completed their referral profile
     */
    const checkUserHaveReferral = async () => {
        if(!userStore) {
            return;
        }

        if(!userStore.userProfile.haveCompletedReferralProfile) {
            //Show modal
        }                
    }


    const onImageUpload = async (imageId:string) => {
        if(!referral) return;

        try {

            setIsLoading(true);
            
            let referralCopy:IReferral = DeepCopy.copy(referral);

            referralCopy.imageLink = imageId;

            const updatedReferral = await referralController.UpdateReferral(referralCopy.id, referralCopy);

            if(updatedReferral) {
                toast.dismiss("upload-image-toast");
                toast.success("Successfully updated image");

                // setReferral(updatedReferral);
            }
        } catch (error) {
            console.error(error);
            toast.dismiss("upload-image-toast");
            toast.error("Failed to upload image");
        }
    }

    /**
     * Not in use
     * @returns 
     */
    const removeImage = async () => {
        if(!referral) return;

        try {
            setIsLoading(true);
            
            const toastLoadingId = "toastRemoveImageLoadId";
            
            toast.loading("Removing Image", {id: toastLoadingId});

            await imageManagementController.DeleteImage(referral.imageLink);

            const referralCopy = DeepCopy.copy(referral);

            referralCopy.imageLink = "";

            const updatedReferral = await referralController.UpdateReferral(referral.id, referral);

            toast.dismiss(toastLoadingId);

            // setReferral(updatedReferral);
            
            setIsLoading(false);
            
        } catch (error) {
            setIsLoading(false);
            console.error(error);
            toast.dismiss("toast-remove-image");
            toast.error("Failed to remove image");
        }
    }


    const removeImageOnEdit = () => {
        if(profileImageFile) {
            setProfileImageFile(null);
            setProfileImageUrl("");
        }
        removeImageFromReferral();
    }

    /**
     * Update profile image but does not upload the image to Cloudflare.
     * Basically preview of the image
     * @param file 
     */
    const UpdateProfileImageFile = (file:File) => {
        setProfileImageFile(file);
        
        const profileImageUrl = window.URL.createObjectURL(file);
        if(profileImageUrl) {
            setProfileImageUrl(profileImageUrl as string);
            removeImageFromReferral();
        }
    }

    /**
     * Uploads the profile image to cloud flare
     * @returns 
     */
    const uploadProfileImage =  async () => {
        if(!profileImageFile) {
            return;
        }

        const res: {id:string, uploadUrl:string} | undefined = await imageManagementController.GetDirectUploadUrl();

        try {
            if(res) {
                if(res.uploadUrl) {
                    const uploadResults = await imageManagementController.UploadImage(res.uploadUrl, profileImageFile); 
                    
                   if(uploadResults){
                        return res.id;
                   }
                }
            }
            
            return "";
        } catch (error) {
            console.error(error);
            setIsLoading(false);
            toast.error("Failed To Upload Image");
        }
    }    

    /**
     * Create a new referral object for the signed in user
     * @param referral 
     * @returns 
     */
    const onReferralFormSubmit = async (referral:IReferral) => {
        try {
            setIsLoading(true);
                        
            const imageId = await uploadProfileImage();

            referral.imageLink = imageId ? imageId : "";

            const results = await referralController.CreateNewReferral(referral);

            history.replace("/referral/pending-profile");

            setIsLoading(false);

            if(!results) {
                toast.error("Failed to submit form")
                return;
            }

            toast.success("Successfully submitted");
        } catch (error) {
            setIsLoading(false);
            toast.error("Failed to submit form");
            console.error(error);
        }
    }

    /**
     * Updates the logged in user referral object and is ran 
     * when the the form is submitted in editing mode
     * @param referral 
     */
    const onEditReferralSubmit = async (referral:IReferral) => {
        setIsLoading(true);
        
        const toastLoadId = "updateReferralLoadingId"

        toast.loading("Updating referral information...", {id: toastLoadId});
        try {

            const imageId = await uploadProfileImage();

            referral.imageLink = imageId ? imageId : referral.imageLink;
            
            const results = await referralController.UpdateReferral(referral.id, referral);

            if(removedImageId) {
                await imageManagementController.DeleteImage(removedImageId);
            }

            toast.dismiss(toastLoadId);
            toast.success("Successfully updated referral information");                                
            history.replace("/referral/pending-profile");
            updateIsEditing(false);
            
            setIsLoading(false);
        } catch (error) {
            toast.dismiss(toastLoadId);
            setIsLoading(false);
            toast.error("Failed to submit form");
            console.error(error);
        }
    }
    
    return (
        <>        
            {userStore && (        
                <div className="referral-profile">
                    {
                        isLoading ? 
                        (
                            <Spinner color="black"></Spinner>
                        ) 
                        : 
                        (
                            <>
                                <h2 className="cbit-dashboard-header">
                                {
                                    isEditing ? 
                                    (
                                        "Edit My Profile"
                                    ) 
                                    : 
                                    (
                                        "Create My Profile"
                                    )
                                }                    
                                </h2>
                                <div className="referral-profile-body">
                                    <div className="referral-image-container">
                                        {
                                            referral && referral.imageLink.length > 0 ? 
                                            (
                                                <div className="referral-image">
                                                    <img 
                                                        className='profile-image-icon' 
                                                        src={`${imageHostUrl}${referral.imageLink}/consultantProfileImage`} 
                                                        alt="Profile Image" 
                                                    />
                                                </div>
                                            )
                                            :
                                            (
                                                <>
                                                    {
                                                        profileImageUrl && profileImageUrl.length > 0 ? 
                                                        (
                                                            <div className="referral-image">
                                                                <img className='profile-image-icon' src={`${profileImageUrl}`} alt="Profile Image" />
                                                            </div>
                                                    )
                                                    :
                                                    (
                                                        <div className="empty-image-container">
                                                            <div className="empty-profile-image"><div>No Image</div></div>
                                                        </div>
                                                    )
                                                    }
                                                </>
                                            )
                                        }
                                        <label 
                                            htmlFor='inputImageUpload' 
                                            className="upload-btn-container btn-cbit-minor"
                                        >
                                            {referral && referral.imageLink.length > 0 || profileImageUrl && profileImageUrl.length > 0 ? 
                                                (
                                                    <>Change</>
                                                ) 
                                                : 
                                                (
                                                    <>Upload</>
                                                )
                                            }                                            
                                        </label>
                                        <ImageUploader 
                                            setIsLoading={setIsLoading}
                                            isLoading={isLoading}
                                            updateParentImageUrl={() => {}}
                                            performAfterUpload={onImageUpload}
                                            removeImage={removeImage}
                                            hideRemoveImage={true}
                                            customUpload={UpdateProfileImageFile}
                                        />
                                        {
                                            isEditing && 
                                            (
                                                <button 
                                                    className="btn-cbit-link"
                                                    onClick={removeImageOnEdit}
                                                >
                                                        Delete Image
                                                </button>
                                            )
                                        }
                                    </div>
                                    <div className="referral-form">
                                        {
                                            isEditing && referral ? 
                                            (
                                                <ReferralForm 
                                                    onFormSubmit={onEditReferralSubmit} 
                                                    referral={referral}
                                                    isEditing={true}
                                                    updateIsEditing={updateIsEditing}
                                                />
                                            )
                                            :
                                            (
                                                <ReferralForm 
                                                    onFormSubmit={onReferralFormSubmit} 
                                                    isEditing={false}
                                                    updateIsEditing={() => {}}
                                                />
                                            )
                                        }

                                    </div>
                                </div>       
                            </>
                        )
                    }
                </div>
            )}
        </>
    )
}

export default ReferralMyProfileForm;