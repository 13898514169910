import axios, {AxiosRequestConfig} from "axios";
import {AgentOptions} from "https";
import https from "https";

const baseLocalHostUrl = 'https://localhost:44386/api';
const baseDevHostUrl = 'https://edutrainer-services-dev.herokuapp.com/api';
const baseProdHostUrl = 'https://api.cbittrainer.com/api';

export class BaseWebService {
    private baseUrl = '';
    private controller = '';
    private apiLocation = '';

    constructor(providedController: string) {
        if (process.env.REACT_APP_DEV_ENV === 'production') {
            this.baseUrl = baseProdHostUrl;
        }else if(process.env.REACT_APP_DEV_ENV === 'development') {
            this.baseUrl = baseDevHostUrl;
        } else {
            this.baseUrl = baseLocalHostUrl;
        }

        this.controller = providedController;
    }

    private buildPath(action: string, newController: string = "") {
        let path = `${this.baseUrl}/`;
        if (newController !== "") {
            path += `${newController}/${action}`;
        } else {
            path += `${this.controller}/${action}`;
        }

        return path
    }

    private GetConfig(params: any = null): AxiosRequestConfig {
        let baseConfig: AxiosRequestConfig = {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }

        if (params != null) {
            baseConfig.params = params;
        }

        return baseConfig;
    }

    public Get(action: string, params: any = null): Promise<any> {
        let path = this.buildPath(action);
        let config = this.GetConfig(params);
        return axios.get(path, config);
    }

    public GetAll(action:string):Promise<any>{
        let path = this.buildPath(action);
        let config = this.GetConfig();
        return axios.get(path,config);
    }
    public Post(action: string, params: any): Promise<any> {
        let path = this.buildPath(action);
        let config = this.GetConfig();
        return axios.post(path, params, config);
    }

    public PostControllerOverride(controller: string, action: string, params: any): Promise<any> {
        let path = controller;
        path += `/${action}`

        return axios.post(path, params, this.GetConfig());
    }

    public Put(action: string, params: { id:string, objIn:any }):Promise<any> {
        let path = this.buildPath(action);
        path+= `/${params.id}`
        let config = this.GetConfig();
        return axios.put(path, params.objIn, config);
    }

    public Delete(action: string, params: any) {
        let path = this.buildPath(action);
        path+= `/${params.id}`
        return axios.delete(path, this.GetConfig());
    }
}
