import {BaseReactService} from "./interfaces/BaseReactService";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import { AxiosError } from "axios";

export class UserModuleService extends BaseReactService {

    constructor(){
        super("UserModule/");
    }

    public async GetByModuleId(id: { id:string }){
        const endpoint = 'UserModule/GetByLessonId/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    public async GetAssignedSection(id:{id:string}){
        const endpoint = 'UserModule/GetAssignedSection/'
        try{
            return await this.Get(id, endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
    public async GetAssignedPartialUserModulesByParentIdWithLessonChildren(id: { id:string }){
        const endpoint = 'UserModule/GetAssignedPartialUserModulesByParentIdWithLessonChildren/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }
    public async GetAssignedModulesForUserByParentModuleId(id: { id:string }){
        const endpoint = 'UserModule/GetAssignedModulesForUserByParentModuleId/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }
    public async DoesUserHaveModules(id: { id:string }){
        const endpoint = 'UserModule/DoesUserHaveModules/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    public async InitializeUserDataForParentId(id: { id:string }){
        const endpoint = 'UserModule/InitializeUserDataForParentId/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }
    public async GetAssignedHeadModulesForUser(){
        const endpoint = 'UserModule/GetAssignedHeadModulesForUser/'
        try{
            return await this.GetAll(endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }
    /**
     * Get all the head modules for a specified user
     * The assignedHeadModules only get head modules for the current user
     */
    public async GetAllUserHeadModules(id: {id:string}) {
        const endPoint = 'UserModule/GetAllUserHeadModules/'
        try {
            return await this.Get(id, endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async GetNextUserLesson(data: {
        userLessonId: string; }) {
        const endpoint = "UserModule/GetNextUserLesson";
        try {
            return await this.CustomGet(data, endpoint);
            // return await this.Get(params,endpoint);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async UpdateUserLessonCompleteness(data: { userLessonId: string, nextUserLessonId:string; }) {
        const endpoint = "UserModule/UpdateUserLessonCompleteness";
        try {
            return await this.CustomGet(data, endpoint);
            // return await this.Get(params,endpoint);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async UpdateAllUsersCourse(sectionId:string) {
        const endPoint = "UserModule/UpdateAllUsersCourse";
        try {
            return await this.CustomGet({sectionId}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Update a single user's course content
     * @param userId 
     * @param assignedSectionId 
     * @returns 
     */
    public async UpdateUserCourse(userId:string, assignedSectionId:string) {
        const endPoint = "UserModule/UpdateUserCourse";
        try {
            return await this.CustomGet({userId, assignedSectionId}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async UnlockAllLessonsForUser(data:{userId:string, sectionId:string}) {
        const endpoint = "UserModule/UnlockAllLessonsForUserId";
        try {
            return await this.CustomGet(data, endpoint);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
}