import { Elements } from "@stripe/react-stripe-js";
import { Appearance, StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { StripeController } from "../../controllers/StripeController";
import { StringHelper } from "../../models/utility/StringHelper";
import { ApplicationState } from "../../store";
import { CurrentUserState } from "../../store/CurrentUser";
import PaymentForm from "./PaymentForm";
import RenderProductItem from "./RenderProductItem";
import "./ConsultationPayment.css";
import { IConsultation } from "../../models/consultations/Consultation";
import { ConsultationType, ConsultationUIType } from "../../models/consultations/ConsultationType";
import { CbitUrls } from "../../models/utility/CbitUrls";

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

const stripeController = new StripeController();

const stripePromise = loadStripe(publishableKey ? publishableKey : "");

type ConsultationPaymentProps = {
    consultation:IConsultation,   
    consultantName:String,
    togglePaymentModel:() => void 
}

/**
* Get the proper url based on our current environment
*/
const getCurrentUrl = () => {
    const env = process.env.REACT_APP_DEV_ENV;
    
    const currentUrl = CbitUrls.internalUrls[env as keyof typeof CbitUrls.internalUrls];

    //If somehow no value exists return the local url
    if(!currentUrl) {
        return CbitUrls.localUrl;
    }

    return currentUrl;
} 

/**
 * Component for accepting payments for consultation sessions
 */
const ConsultationPayment = (props:ConsultationPaymentProps) => {
    const {
        consultation,
        consultantName,
        togglePaymentModel
    } = props;

    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>((state )=> state.currentUser);

    const [isLoading, setIsLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState<string>(""); 
    const [priceId, setPriceId] = useState<string>("");

    useEffect(() => {
        async function onComponentMount() {
            getClientSecret();
        }
        onComponentMount();
    }, []);

    //Get the client secret by creating the payment intent
    const getClientSecret = async () => {
        if(!userStore){            
            return;
        }
        const {userProfile} = userStore;
        setIsLoading(true);
        
        let userId = userProfile.id

        const stripeRes:any = await stripeController.CreateConsultationPaymentIntent({userId,  consultationId: consultation.id});

        const {paymentIntent, priceId} = stripeRes;
                
        setClientSecret(paymentIntent.clientSecret);

        setPriceId(priceId);
        
        setIsLoading(false);

    }

    /**
    * Provides set appearance styles for the Stripe form
    */
    const appearance:Appearance = {
            theme: 'stripe',
            variables: {
                colorPrimary: '#00A3C1'
            }
    }
              
    /**
    * Options pass to the Stripe form
    */
    const options:StripeElementsOptions = {
        clientSecret,
        appearance,
    };

    /**
     * Description to display about the consultation that is being purchased
     */
    const getConsultationDescription = () => {
        let displayName = consultation.type === ConsultationType.OneOnOne ? "private" : ConsultationUIType[consultation.type];

        return `${displayName} session with ${consultantName}`
    }

    const getReturnUrl = () => {
        return `${getCurrentUrl()}/consultation/booking?consultationId=${consultation.id}`;
    }

    return (
        <div className="cbit-container consultation-payment">        
            <h2 className="cbit-header">
                Purchase Consultation
            </h2>
            <p className="text-red">
                    You are purchasing a non refundable {getConsultationDescription()}. Once purchased, if you cancel the consultation session you will be able to join another session of the same type.  
            </p>
            {
                isLoading ? 
                (
                    <Spinner/>       
                    ) 
                    : 
                    (
                        <>
                        <RenderProductItem 
                            priceId={priceId}
                        />
                        {clientSecret && !StringHelper.IsNullOrWhiteSpace(clientSecret) && (
                            <Elements 
                                options={options} 
                                stripe={stripePromise}
                            >
                                <PaymentForm 
                                    returnUrl={getReturnUrl()}
                                />
                                {/* <button 
                                    className="btn-cbit-minor"
                                    onClick={togglePaymentModel}
                                >
                                    Cancel
                                </button>                                 */}
                            </Elements>

                        )}
                    </>
                ) 
            }        
        </div>
    )
}

export default ConsultationPayment;