import { BaseReactService } from "./interfaces/BaseReactService";

/**
 * Service for communicating the ConsultationReceipt backend routes
 */
export class ConsultationReceiptService extends BaseReactService{
    constructor()
    {
        super("ConsultationReceipt/");
    }
    
    /**
     * Get a user compatible receipt so they can book a consultation session
     * @param userId 
     * @param consultationId 
     * @returns 
     */
    public async GetUserCompatibleReceipt(userId:string, consultationId:string) {
        const endPoint = "ConsultationReceipt/GetUserCompatibleReceipt/";

        return await this.CustomGet({userId, consultationId}, endPoint);
    }

    /**
     * Get a user receipt by the original consultation id that was purchased
     * @param consultationId 
     * @returns 
     */
    public async GetUserReceiptByOriginalConsultation(consultationId:string) {
        const endpoint = "ConsultationReceipt/GetUserReceiptByOriginalConsultation/";
        return await this.Get({id: consultationId}, endpoint);
    }

    /**
     * Get all user consultation receipts
     */
    public async GetUserReceipts() {
        try {
            const endPoint = "ConsultationReceipt/GetUserReceipts";
            return await this.CustomGet({}, endPoint);
        } catch (error) {
            throw error;
        }
    }
}
