import { Action, Reducer } from "redux";
import { ILesson, Lesson } from "../models/lesson/Lesson";

export interface CurrentLastLessonState {
    lastLesson:ILesson
}

enum lastLessonActions {
    SetLastLesson = "SET_LAST_LESSON",
    RemoveLastLesson = "REMOVE_LAST_LESSON"
}

export interface SetLastLessonAction { type: lastLessonActions.SetLastLesson, currentState:CurrentLastLessonState }
export interface RemoveLastLessonAction { type: lastLessonActions.RemoveLastLesson}

export type KnownAction = SetLastLessonAction | RemoveLastLessonAction;

export const lastLessonActionCreators = {
    setLastLesson: (state:CurrentLastLessonState) => ({ type: lastLessonActions.SetLastLesson, currentState:state } as SetLastLessonAction),
    removeLastLesson: () => ({ type: lastLessonActions.RemoveLastLesson } as RemoveLastLessonAction)
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<CurrentLastLessonState> = (state: CurrentLastLessonState | undefined, incomingAction: Action): CurrentLastLessonState => {
    if (state === undefined) {
        return { lastLesson: new Lesson() };
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case lastLessonActions.SetLastLesson:
            return { lastLesson: action.currentState.lastLesson};
        case lastLessonActions.RemoveLastLesson:
            return { lastLesson: new Lesson() };
        default:
            return state;
    }
};