import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { ModuleController } from '../../controllers/ModuleController';
import { UserController } from '../../controllers/UserController';
import { UserModuleController } from '../../controllers/UserModuleController';
import { IModule } from '../../models/module/Module';
import { IUserProfile } from '../../models/module/UserProfile';
import { DeepCopy } from '../../models/utility/DeepCopy';
import FormHelper from '../../models/utility/FormHelper';
import { formErrors, FormValidationHelper, validationInput } from '../../models/utility/formValidation/FormValidationHelper';
import Roles from '../../models/utility/Roles';
import UserStatus from '../../models/utility/UserStatus';
import IconEyeClosed from '../../resources/icons/icon-eye-closed.png';
import IconEyeOpen from '../../resources/icons/icon-eye-open.png';
import { SubscriptionStatusEnum } from '../../models/Enums/SubscriptionStatusEnum';

type SignUpState = {
    isLoading:boolean
    user:userSignUpType
    formErrors:formErrors
    parentModules:IModule[]
    isPwdVisible:boolean
    isPwdConfirmVisible:boolean
    testCode:string
}

type userSignUpType = {
    username: string,    
    firstName: string,
    lastName: string,
    email: string,        
    password: string,
    passwordConfirm: string,    
    userModuleId: string
}

const userController = new UserController();    
const userModuleController = new UserModuleController();
const moduleController = new ModuleController();
const cbitTrainingID = "618b0a975f552467c3293e03";

/**
 * Sign up form for adding professionals.
 * The Validation here needs to be replaced with a validation library (Need To update Typescript version first)
 * Temp: For our test group we are using a test code
 */
const SignUp = () => {
    
    const history = useHistory();

    const fieldNames = {
        username:"username",
        firstName:"firstName",
        lastName:"lastName",
        email:"email",
        password:"password",
        passwordConfirm:"passwordConfirm",
        testCode:"testCode"
    }
    const defaultState:SignUpState = {
        isLoading: false,
        user: {
            username: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            passwordConfirm: '',
            userModuleId: ''
        },
        formErrors: {
            hasErrors: false,
            errors: {}
        },
        parentModules: [],
        isPwdVisible: false,
        isPwdConfirmVisible: false,
        testCode:"",
    }

    const [state, setState] = useState(defaultState);

    /**
     * Handles input changes and updates state
     * @param e 
     */
    const onChange = (e: React.FormEvent<HTMLInputElement>): void => {
        const inputField = e.currentTarget.name;

        let inputValue = e.currentTarget.value;               
        
        //Temp: Only for the testing phase
        if(inputField === fieldNames.testCode) {
            setState(prevState => ({
                ...prevState,
                testCode: inputValue
            }));
            
            validateForm(inputField, inputValue);

            return;
        }

        if(inputField === fieldNames.password || inputField === fieldNames.passwordConfirm)
            validateForm(inputField, inputValue);
        

        setState(prevState => ({
            ...prevState,
            user: {
                ...prevState.user,
                [inputField]: inputValue
            }
        }));

        validateForm(inputField, inputValue);
    }

    /**
     * Validation for basic input min and max lengths
     */
    const basicInputValidation = (formErrs:formErrors, inputValidation:validationInput ,minLen:number, maxLen:number , displayName:string) => {
        FormValidationHelper.validateInputMinLength(formErrs, inputValidation, minLen, displayName );
                    
        FormValidationHelper.validateInputMaxLength(formErrs, inputValidation, maxLen,displayName);
    }
    
    /**
     * This will get replace with a validation library
     * Validates a specific form field
     * @param fieldName 
     * @param value 
     */
    const validateForm =  (fieldName:string, value:string) => {            
            
            const formErrors:formErrors= DeepCopy.copy(state.formErrors);         
            
            value = value.trim();

            const validationInput:validationInput = {
                fieldName,
                value,
            } 
    
            switch (fieldName) {
                
                case fieldNames.username:{
    
                    basicInputValidation(formErrors, validationInput, 3, 20, "Username");       
                    
                    break;
                }                     
    
                case fieldNames.firstName: {

                    basicInputValidation(formErrors, validationInput, 2, 32, "First Name");     
                    
                    break;
                }  
    
                case fieldNames.lastName:   {
    
                    basicInputValidation(formErrors, validationInput, 2, 32, "Last Name");    
                    
                    break;
                }              
    
                case fieldNames.email: {
    
                    const displayName = "Email";
    
                    FormValidationHelper.validateEmailAddress(formErrors, validationInput, displayName);
                    
                    break;
                }
    
                case fieldNames.password: {
                    let displayName = "Password";
                    const minLength = 6;
                    FormValidationHelper.validateInputMinLength(formErrors, validationInput, minLength, displayName);
                    FormValidationHelper.validateMatch(formErrors, validationInput, state.user.passwordConfirm, displayName)
    
                    validationInput.fieldName = fieldNames.passwordConfirm;
                    validationInput.value = state.user.passwordConfirm;
                    displayName = "Password";
                    FormValidationHelper.validateMatch(formErrors, validationInput, value, displayName)
                    
                    break;
                }
                    
                case fieldNames.passwordConfirm: {
    
                    const displayName = "Password";
                    
                    FormValidationHelper.validateMatch(formErrors, validationInput, state.user.password, displayName)
                    
                    validationInput.fieldName = fieldNames.password;
                    
                    validationInput.value = state.user.password;
                    
                    FormValidationHelper.validateMatch(formErrors, validationInput, value, displayName)                
                    
                    break;
                }

                case fieldNames.testCode: {
                    const displayName = "code";
                    
                    FormValidationHelper.validateInputEmpty(formErrors, validationInput, displayName);

                    break;
                }
            
                default:
                    break;
            }
        
            setState(prevState => ({
                ...prevState,
                formErrors: {
                    ...prevState.formErrors,
                    ...formErrors
                }
            }));
    } 

    /**
     * Determines if the form has errors or not
     */
    const hasErrors = (fieldName:string) => {
        const {errors} = state.formErrors;

        let hasError = false;                

        if(errors[fieldName] ) {
            if(errors[fieldName].message.length > 0) {
                hasError = true;
            }
        } 

        return hasError;
    }

    const togglePwdVisibility = (isPwdConfirmed?:boolean) => {
        let isVisible = !state.isPwdVisible;
        if(isPwdConfirmed) {
            isVisible = !state.isPwdConfirmVisible;

            setState(prevState => ({
                ...prevState,
                isPwdConfirmVisible: isVisible
            }));
            return;
        }

            setState(prevState => ({
                ...prevState,
                isPwdVisible: isVisible
            }));
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        const {user, formErrors} = state;
    
        if(formErrors.hasErrors) {            
            return;
        }

        const userToBeCreated:IUserProfile = {
            name: user.username,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            prefix: "",
            description: "",
            password: user.password,
            passwordLastChanged: new Date().toISOString(),
            passwordAttempts: 0,
            roles: [Roles.Professional],
            companyId: null,
            divisionId: null,
            status: UserStatus.Active,
            haveCompletedReferralProfile: false,
            assignedSectionId: '',
            visitedSocialConnection: false,
            isBanned: false,
            id: '',
            tokens: 0,
            imageLink: '',
            createdAt: new Date(),
            updatedAt: new Date(),
            completedTraining: '',
            completedFreeConsultation: false,
            stripeCustomerId: '',
            subscriptionEnd: new Date(),  //End time is handled on the backend
            subscriptionStatus: SubscriptionStatusEnum.paid,
            numFreeConsultations: 0
        }

        removeUserWhiteSpace(userToBeCreated);

        try {
            setState(prevState => ({...prevState, isLoading:true}));
            toast.loading("Creating Account...", {id: 'professionalLoading'})

            const respUserId:string = await userController.CreateProfessional(userToBeCreated, state.testCode);                    
            
            setState(prevState => ({...prevState, isLoading:false}));
            
        } catch (error) {            
            toast.dismiss('professionalLoading')
            displayError(error as string[]);
            setState(prevState => ({...prevState, isLoading:false}));
            return;
        }
        
        setState(defaultState);
        toast.dismiss('professionalLoading')
        //Navigate somewhere and show alert
        toast.success("Your account has successfully been created please login!");

        history.push("/login");
    }

    const removeUserWhiteSpace = (user:IUserProfile) => {
        Object.keys(user).map((key) => {
            if(key === "name" || key === "firstName" || key === "lastName" || key ==="email" || key==="prefix") {
                user[key] = FormHelper.RemoveWhiteSpace(user[key]).toLowerCase();
            }
        })
    }

    const displayError = (error:string[]) => {
        for(let msg of error) {
            toast.error(msg)
        }
    }
    
    return (
        <div className='container'>
            <Form className="user-form form" onSubmit={handleSubmit}>                    
                
                <h2 className='form-title'>
                    Sign Up
                </h2>
                
                <Row>
                    <Col>
                    <FormGroup floating="true">
                        <Label className='form-label' for="username">Username {" "}
                            <span className="required-field">*</span>
                        </Label>
                            <Input 
                                onBlur={() => validateForm(fieldNames.username, state.user.username)}
                                invalid={hasErrors(fieldNames.username)}
                                id="username" 
                                className='form-input'
                                name="username" 
                                type="text" 
                                placeholder="Username" 
                                value={state.user.username} onChange={onChange}  required/>
                            <FormFeedback>
                                {state.formErrors.errors[fieldNames.username]?.message}
                            </FormFeedback>
                    </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup floating="true">
                            <Label for="firstName" className='form-label'>
                                First Name {" "}
                                <span className="required-field">*</span>
                            </Label>
                                <Input 
                                    onBlur={() => validateForm(fieldNames.firstName, state.user.firstName)}
                                    invalid={hasErrors(fieldNames.firstName)}
                                    className='form-input'
                                    name="firstName" 
                                    type="text" 
                                    id="firstName" 
                                    placeholder="First Name" 
                                    value={state.user.firstName} onChange={onChange}  required/>
                                <FormFeedback>
                                {state.formErrors.errors[fieldNames.firstName]?.message}
                                </FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup floating="true">
                            <Label for="lastName" className='form-label'>
                                Last Name {" "}
                                <span className="required-field">*</span>
                            </Label>
                                <Input 
                                    onBlur={() => validateForm(fieldNames.lastName, state.user.lastName)}
                                    invalid={hasErrors(fieldNames.lastName)}
                                    className='form-input'
                                    name="lastName" 
                                    type="text" 
                                    id="lastName" 
                                    placeholder="Last Name" 
                                    value={state.user.lastName} onChange={onChange}  required/>
                                <FormFeedback>
                                    {state.formErrors.errors[fieldNames.lastName]?.message}
                                </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup floating="true">
                            <Label for="email" className='form-label'>
                                Email {" "}
                                <span className="required-field">*</span>
                            </Label>
                                <Input 
                                    onBlur={() => validateForm(fieldNames.email, state.user.email)}
                                    invalid={hasErrors(fieldNames.email)}
                                    className='form-input'
                                    name="email" 
                                    type="email" 
                                    id="email" 
                                    placeholder="Email" 
                                    value={state.user.email} 
                                    onChange={onChange}  
                                    required
                                />
                                <FormFeedback>
                                    {state.formErrors.errors[fieldNames.email]?.message}
                                </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup floating="true">
                            <Label for="password" className='form-label'> 
                                Password {" "}
                                <span className="required-field">*</span>
                            </Label>
                            <div className="password-container">
                                <Input 
                                    onBlur={() => validateForm(fieldNames.password, state.user.password)}
                                    invalid={hasErrors(fieldNames.password)}
                                    className='form-input'
                                    name="password" 
                                    type={state.isPwdVisible ? "text" : "password"}                                     
                                    id="password" 
                                    placeholder="Password" value=
                                    {state.user.password} 
                                    onChange={onChange} required                                    
                                />                                
                                <button type="button" className='toggle-visibility' onClick={() => togglePwdVisibility()}>         
                                    {
                                        state.isPwdVisible ? 
                                        (
                                            <img 
                                                className="toggle-visibility-hide" 
                                                src={IconEyeClosed}
                                                alt="Hide password"
                                            />
                                        )
                                        : 
                                        (
                                            <img 
                                                className="toggle-visibility-open" 
                                                src={IconEyeOpen}
                                                alt="Show password"
                                            />
                                        )
                                    }
                                </button>
                            </div>
                            {
                                hasErrors(fieldNames.password) && (
                                    <div className='cbit-invalid-feedback'>
                                        <span className="">                                        
                                            {state.formErrors.errors[fieldNames.password]?.message}
                                        </span>
                                    </div>
                                )
                            }
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup floating="true">
                            <Label for="passwordConfirm" className='form-label'> 
                                Password Confirmation {" "}
                                <span className="required-field">*</span>
                            </Label>
                            <div className="password-container">
                                <Input 
                                    onBlur={() => validateForm(fieldNames.passwordConfirm, state.user.passwordConfirm)}
                                    invalid={ hasErrors(fieldNames.passwordConfirm)}
                                    id="passwordConfirm" 
                                    className='form-input'
                                    name="passwordConfirm" 
                                    type={state.isPwdConfirmVisible ? "text" : "password"}
                                    placeholder="Password Confirmation" 
                                    value={state.user.passwordConfirm} 
                                    onChange={onChange}                                    
                                    required
                                />
                                <button type="button" className='toggle-visibility' onClick={() => togglePwdVisibility(true)}>         
                                    {
                                        state.isPwdConfirmVisible ? 
                                        (
                                            <img 
                                                className="toggle-visibility-hide" 
                                                src={IconEyeClosed}
                                                alt="Hide password"
                                            />
                                        )
                                        : 
                                        (
                                            <img 
                                                className="toggle-visibility-open" 
                                                src={IconEyeOpen}
                                                alt="Show password"
                                            />
                                        )
                                    }
                                </button>                                                
                            </div>
                            {
                                hasErrors(fieldNames.passwordConfirm) && (
                                    <div className='cbit-invalid-feedback'>
                                        <span className="">                                        
                                            {state.formErrors.errors[fieldNames.passwordConfirm]?.message}
                                        </span>
                                    </div>
                                )
                            }
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <FormGroup>
                        <Label className='form-label' for="TesterCode">Tester Code {" "}
                            <span className="required-field">*</span>
                        </Label>
                        <Input 
                            onBlur={() => validateForm(fieldNames.testCode, state.testCode)}
                            invalid={hasErrors(fieldNames.testCode)}
                            className='form-input'
                            name="testCode" 
                            type="text" 
                            id="TesterCode" 
                            placeholder="Tester Code" 
                            value={state.testCode} 
                            onChange={onChange}  
                            required
                        />
                        <FormFeedback>
                            {state.formErrors.errors[fieldNames.testCode]?.message}
                        </FormFeedback>
                    </FormGroup>
                    </Col>
                </Row>
                {state.isLoading ?(
                    <Button disabled className='btn-cbit-primary'>
                        Loading...
                    </Button>
                    )
                    :
                    <Button className='btn-cbit-primary' type="submit"> 
                        Create
                    </Button>
                }
            </Form>   
        </div>
    )
}

export default SignUp;