import React, {useEffect, useState} from "react"
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import NavSidebar from "../../SocialConnection/components/layout/NavSidebar/NavSidebar";
import {ContentCategory} from "../../../models/AdditionalVideoContent/ContentCategory";

type TherapistSupportLayoutProps = {
    contentCategories: ContentCategory[]
    selectedContentCategory: ContentCategory | null | undefined
    selectContentCategory: (id:string) => void
    children: React.ReactNode
}

const TherapistSupportLayout = (props: TherapistSupportLayoutProps) => {
    // Navigation Panel
    const [isNavOpen, setIsNavOpen] = useState(true);
    const {height, width} = useWindowDimensions();

    const toggle = () => {
        setIsNavOpen(!isNavOpen);
    }

    useEffect(() => {
        // If the width of the device is greater than 1023px, open the navigation panel
        if (width > 1023) {
            setIsNavOpen(true);
        }

        // If the width of the device is less than 1023px, close the navigation panel
        if (width <= 1023) {
            setIsNavOpen(false);
        }

    }, [width]);

    const {
        contentCategories,
        selectedContentCategory,
        selectContentCategory,
        children
    } = props;

    /**
     * Get styles for the current selected category
     * @param id
     * @returns
     */
    const getSelectedStyles = (id:string) => {
        if(!selectedContentCategory)
            return "";

        return selectedContentCategory.id === id ? "active-nav-item" : "";
    }

    const handleLinkClick = () => {
        if (width > 1023) {
            return
        }
        setIsNavOpen(false);
    }

    return (
        <div className={`therapist-support-layout ${isNavOpen ? 'isOpen' : ''}`}>
            {/* Navigation Panel */}
            <div className="AccordionContent">
                <NavSidebar toggle={toggle}
                            isNavOpen={isNavOpen}
                            setIsNavOpen={setIsNavOpen}
                            title={'Therapist Toolbox'}
                >
                    {
                        contentCategories.map(category => (
                            <div
                                key={category.id}
                                onClick={() => selectContentCategory(category.id)}
                                className={`chapter-content-container ${getSelectedStyles(category.id)}`}
                            >
                                <div className="admin-chapter-text sidebar-link-text" onClick={handleLinkClick}>
                                    {category.name}
                                </div>
                            </div>
                        ))
                    }
                </NavSidebar>
            </div>
            {/* Main content container */}
            <div className="therapist-support-main-content">
                {children}
            </div>
        </div>
    )
}

export default TherapistSupportLayout