import {useState} from 'react';
import { SortByEnum } from '../models/Enums/SortByEnum';


const useSortBy = (defaultSortBy:SortByEnum):[SortByEnum, (sortBy:SortByEnum) => void, (e:React.ChangeEvent<HTMLSelectElement>) => void] => {
    const [sortBy, setSortBy] = useState(defaultSortBy);

    const handleOnSortChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.currentTarget.value;

        const parseValue = parseInt(value);

        if(!isNaN(parseValue)) {
            setSortBy(parseValue);
        }
    }

    return [sortBy, setSortBy, handleOnSortChange]
}

export default useSortBy;