export interface ISelectableChoice {
    text:string;
    order:number; //May need to be string. Shorts are not supported in js
    isCorrect:boolean;
}

/**
 * Classes for selectable choices for the question form
 */
export class SelectableChoice implements ISelectableChoice{
    text = '';
    order = 0;
    isCorrect = false;
    
    /**
     *
     */
    constructor(choice : ISelectableChoice = {
        text: '',
        order: 0,
        isCorrect: false
    }) {
        this.text = choice.text,
        this.order = choice.order,
        this.isCorrect = choice.isCorrect        
    }
}