import React from 'react';


type CancelSessionAlertProps = {
    toggleModal:() => void
    onCancelClick:() => void
}

const CancelSessionAlert = (props:CancelSessionAlertProps) => {
    const {toggleModal, onCancelClick} = props;

    return (
        <div className="success-booking-container cbit-dialog">
            <div className="cbit-header success-booking-header">ARE YOU SURE YOU WANT TO CANCEL THIS SESSION?</div>
            <p className='cbit-text success-booking-text'>
                Once you click "Cancel Session", you will no longer have a reservation for this consultation and must repeat the reservation.
            </p>
            <div className="cbit-dialog-btn-container">
                <button onClick={toggleModal} className='btn-cbit-minor'>Cancel</button>
                <button onClick={onCancelClick} className="btn-cbit-primary">Cancel Session</button>
            </div>
        </div>
    )
}

export default CancelSessionAlert