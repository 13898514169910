import React from "react"
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

type FunctionModalProps = {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void
    action:() => void
    text: string,
    title: string,
}

const FunctionModal = ({isOpen, setIsOpen, title, text, action}: FunctionModalProps) => {

    return (
        <>
            <Modal isOpen={isOpen} size={'lg'} centered={true}>
                <ModalHeader toggle={() => setIsOpen(false)}>{title}</ModalHeader>
                <ModalBody>
                    <p>
                        {text}
                    </p>
                </ModalBody>
                <ModalFooter>
                    {/* @ts-ignore */}
                    <Button color="primary"
                            onClick={() => action()}>
                        Accept
                    </Button>
                    <Button color="secondary"
                            onClick={() => setIsOpen(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default FunctionModal