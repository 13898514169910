import Roles from "../Roles"

export type formErrors = {
    hasErrors:boolean
    errors: {
        [fieldName:string]:{
            message:string
        }
    }
}

export type validationInput = {
    fieldName:string,
    value:string,
    errorMessage?:string
}

/**
 * This is only used in sign up. Eventually get rid of this and use formik for validation.
 * This was made before we was allowed to use libraries like formik
 */
export class FormValidationHelper {

    private static setErrorMessage = (formErrors:formErrors, defaultErrorMsg:string, input:validationInput) => {
        const {errorMessage, fieldName} = input;

        const errMsg = errorMessage ? errorMessage : defaultErrorMsg

        formErrors.errors[fieldName] = {message: errMsg}

        formErrors.hasErrors = true;
    }

    public static clearError = (formErrors:formErrors, input:validationInput, defaultErrorMsg:string) => {
        const {errorMessage, fieldName} = input;
        
        if(!formErrors.errors[fieldName])
            return;        
        
        const formErrorMsg = formErrors.errors[fieldName].message;

        const errorMsgToUse = errorMessage ? errorMessage : defaultErrorMsg;

        if(errorMsgToUse === formErrorMsg) {
            delete formErrors.errors[fieldName];

            if(Object.keys(formErrors.errors).length <= 0)
                formErrors.hasErrors = false;
        }
    }

    public static validateInputMinLength = (formErrors:formErrors, input:validationInput, minLength:number, displayFieldName:string) => {
        
        const {value} = input;

        let defaultErrorMsg = `${displayFieldName} must be ${minLength} or more characters`;        
        
        if(value.length < minLength) {
            this.setErrorMessage(formErrors, defaultErrorMsg, input)
            return;
        }                

        this.clearError(formErrors, input, defaultErrorMsg);
    }

    public static validateInputMaxLength = (formErrors:formErrors, input:validationInput, maxLength:number, displayFieldName:string) => {
        const {value} = input;

        const defaultErrorMsg = `${displayFieldName} must be less than ${maxLength} characters`;
        
        if(value.length > maxLength) {
            this.setErrorMessage(formErrors, defaultErrorMsg, input)
            return;
        }

        this.clearError(formErrors, input, defaultErrorMsg);
    }

    public static validateInputEmpty = (formErrors:formErrors, input:validationInput, displayFieldName:string) => {
        const {value} = input;
        
        const defaultErrorMsg = `Please provide a ${displayFieldName}`        

        if(value.length <= 0) {
            this.setErrorMessage(formErrors, defaultErrorMsg, input)
            return;
        }        

        this.clearError(formErrors, input, defaultErrorMsg);
    }

    public static validateEmailAddress = (formErrors:formErrors, input:validationInput, displayFieldName:string) => {

        const {value} = input;

        const defaultErrorMsg = `Please enter a valid ${displayFieldName}`;

        /** Regex is from W3 Schools to validate email address */
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        
        const isValid = emailRegex.test(value);

        if(!isValid) {
            this.setErrorMessage(formErrors, defaultErrorMsg, input)
            return;
        }

        this.clearError(formErrors, input, defaultErrorMsg)
    }

    public static validateUserRoles = (formErrors:formErrors, input:validationInput, displayFieldName:String, selectedRoles:Roles[]) => {
        const defaultErrorMsg = `Please select a ${displayFieldName}`;

        let rolesAreValid = false;

        for(let roles of selectedRoles) {            
            rolesAreValid = Object.values(Roles).includes(roles);
        }        

        if(!rolesAreValid) {
            this.setErrorMessage(formErrors, defaultErrorMsg, input);
            return;
        }

        this.clearError(formErrors, input, defaultErrorMsg);
    }

    public static validateMatch = (formErrors:formErrors, input:validationInput,valueToMatch:string, displayFieldName:string) => {
        const {value} = input;
        
        const defaultErrorMsg  = `${displayFieldName} do not match`;
        
        const notMatch = value !== valueToMatch;

        if(notMatch) {
            this.setErrorMessage(formErrors, defaultErrorMsg, input)
            return;
        } 

        this.clearError(formErrors, input, defaultErrorMsg);
    }
}