import  {useState} from "react"

/**
 * A modal hook that keeps track of a modal state and provides a function for toggle that modal
 * Note: use with the CustomModal component
 * @param isModalOpen 
 * @returns 
 */
const useModal = (isModalOpen:boolean):[boolean, (isOpen:boolean) => void, (isOpen?:boolean) => void] => {
    const [modalState, setModalState] = useState(isModalOpen);        

    const toggleModal = (isOpen?:boolean) => {
        const isModalOpen = isOpen != undefined ? isOpen : !modalState;

        setModalState(isModalOpen);
    }

    return [modalState, setModalState, toggleModal]
}

export default useModal;