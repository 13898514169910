import React from "react";
import "./AdminActionFullItem.css";

type AdminActionFullItemProps = {
    mainContainerClass:string;
    iconSrc:string;
    title:string
    description:string
}

const AdminActionFullItem = (props:AdminActionFullItemProps) => {
    const {
        mainContainerClass,
        iconSrc,
        title,
        description
    } = props;

    return (
        <div className={`action-full-item cbit-container ${mainContainerClass}`}>
            <div className="action-full-left">
                <div className="action-icon-container">
                    <img src={iconSrc} alt="Admin Action Icon" />
                </div>
            </div>
            <div className="action-full-right">
                <div className="admin-action-header cbit-dashboard-header">
                    {title}
                </div>
                <div className="action-full-desc">
                    {description}
                </div>
            </div>
        </div>
    )
}

export default AdminActionFullItem;