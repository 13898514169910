import React, {useEffect, useState} from "react";
import PublicRoutes, {validPages} from "../../../Routes/PublicRoutes";
import PrivateRoutes from "../../../Routes/PrivateRoutes";
import {useHistory} from "react-router";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { CurrentUserState } from "../../../store/CurrentUser";
import { SubscriptionStatusEnum } from "../../../models/Enums/SubscriptionStatusEnum";
import ExpiredSubscriptionRoutes from "../../../Routes/ExpiredSubscriptionRoutes";
import { CheckIfConsultant } from "../../../models/module/UserProfile";
import Roles from "../../../models/utility/Roles";

const UrlWhitelist = () => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);
    
    const [isPublicRoute, setIsPublicRoute] = useState(true);

    const history = useHistory();

    useEffect(() => {
        let {pathname} = window.location;

        if (validPages.includes(pathname)) {
            setIsPublicRoute(true)
        } else {
            setIsPublicRoute(false);
        }

    });

    const checkExpiredSubscription = () => {
        if(!userStore) {
            return <PrivateRoutes/>
        }
        const {userProfile} = userStore;

        let hasExpired = userProfile.subscriptionStatus !== SubscriptionStatusEnum.paid;
        
        //This not only checks for consultants but for admins as well
        if(CheckIfConsultant(userProfile.roles) || userProfile.roles.includes(Roles.TestUser)) {
            //Consultants, admins, and test users (for now) can have expired subscriptions and still navigate
            hasExpired = false;
        }

        //TODO Uncomment out when testing phase is over
        // if(hasExpired) {
        //     return <ExpiredSubscriptionRoutes/>
        // }

        return <PrivateRoutes/>
    } 

    return (
        <>
            {isPublicRoute ? <PublicRoutes/> : checkExpiredSubscription()}
        </>
    )
}

export default UrlWhitelist;