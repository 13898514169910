import React from 'react';
import { IUserBio, UserBio } from '../../../../models/module/UserProfile';
import CloudflareImageHelper from '../../../../models/utility/CloudflareImageHelper';

type ConsultantBookingProfileProps = {
    consultant:UserBio
    viewConsultantAvailability:(consultant:IUserBio) => void
}

const ConsultantBookingProfile = (props:ConsultantBookingProfileProps) => {
    const {consultant, viewConsultantAvailability} = props;

    const imageHostUrl = CloudflareImageHelper.imageUrl;

    return (
        <div className="consultant-booking-item">
            <div className="consultant-info-container-left">
                    {
                        consultant.imageLink && consultant.imageLink.length > 0 ? 
                        (
                            <div className="consultant-profile-img-container">
                                <img src={`${imageHostUrl}${consultant.imageLink}/consultantProfileImage`} />
                            </div>
                        )
                        :
                        (   
                            <div className="empty-profile-image"><div>No Image</div></div>
                        )
                    }
                    
            </div>
            <div className="consultant-info-container-right">
                <div className="consultant-booking-name">
                    {consultant.firstName} {consultant.lastName}
                </div>
                <div className="consultant-booking-title">
                    {consultant.title}
                </div>
                <p className="consultant-booking-bio">
                    {consultant.description}
                </p>
                <div className="consultant-action-container">
                    <button 
                        onClick={(e) => viewConsultantAvailability(consultant)}
                        className='btn-cbit-primary btn-consultation-action'
                    >
                        VIEW AVAILABILITY
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ConsultantBookingProfile;