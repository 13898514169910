import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { v4 as uuidV4 } from "uuid";
import { ConsultationReviewController } from "../../../../controllers/ConsultationReviewController";
import { ConsultationUIType } from "../../../../models/consultations/ConsultationType";
import { ConsultantReview } from "../../../../models/consultations/reviews/ConsultantReview";
import TimeHelper from "../../../../models/utility/TimeHelper";
import CbitDataGrid from "../../../Utilities/CbitTable/CbitDataGrid";
import CustomModal from "../../../Utilities/CustomModal";
import ReviewViewer from "../ReviewViewer";

export type consultationReviewType = {
    id:string;
    createdAt:string;
    consultationType:string,
    rating:number;    
    attendance:string;
    reviewedBy:string;
    summary:string;
}

export type SelectedReviewState = {
    reviewId:string;
    reviewerName:string;
} 

const reviewController = new ConsultationReviewController(); 

const noReviewsFoundText = "No Consultant Reviews Found";

/**
 * Table for consultants reviews
 * @returns 
 */
const ConsultantReviewTable = () => {
    const [reviews, setReviews] = useState<(ConsultantReview & {id:string})[]>([]);
    const [selectedReviewInfo, setSelectedReviewInfo] = useState<SelectedReviewState>({reviewId: "" ,reviewerName: ""})
    const [isReviewOpen, setIsReviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);    
    const isFirstRender = useRef(true);

    useEffect(() => {
        let isMounted = true;       
        async function onComponentMount() {     
            try {
                setIsLoading(true);
                const results:ConsultantReview[] = await reviewController.GetConsultantsReviews();
                const consultantReviews = results.map(cr => ({...cr, id:uuidV4()}))
                if(isMounted) {
                    setReviews(consultantReviews);
                }
            } catch (error) {
                console.error(error);
                toast.error("Failed to get consultants reviews");
            }
            setIsLoading(false);
        }
        
        //To stop the flickering caused by mui tabs and using async await combined
        setTimeout(() => {
            onComponentMount();    
        }, 100)

        return () => { isMounted = false };
    }, []);

    const consultantColumns:GridColDef[] =[
        {
            field: 'reviewedAt', 
            headerName: 'Date', 
            flex:1,
            valueGetter: (params) => {
                const date = params.value;            
                return TimeHelper.formatFullDateAndTime(date, "YYYY-MM-DD h:mm A")
            }                                                              
        },
        {
            field: 'attendeeAttendance',
            headerName: 'Attendee Attendance',
            flex: 1,
          },
        {
          field: 'type',
          headerName: 'Consultation Type',
          flex: 1,          
          valueGetter: (params) => {
            const type = params.value;            
            return ConsultationUIType[type as keyof typeof ConsultationUIType];
          }
        },
        {
            field: 'rating', 
            headerName: 'Rating', 
            flex:1,                                                                  
        },
        {
            field: 'reviewerName', 
            headerName: 'Reviewed By', 
            flex:1,                                                                  
        },
        {
            field: 'comments', 
            headerName: 'Summary', 
            flex:1,                                                                  
        },
        {
            field: 'view', 
            headerName: 'View', 
            filterable:false,
            sortable: false,
            renderCell: (cell:any) => {                
                return (

                    <button 
                        onClick={() => setAndOpenReview(cell.row.reviewId, cell.row.reviewerName)}
                        className="btn-cbit-link btn-user-table"
                    >
                        View
                    </button>
                )
            }
        },
    ];

    /**
     * Sets a selected review and open it in the modal
     * @param reviewId 
     * @param reviewerName 
     */
    const setAndOpenReview = (reviewId:string, reviewerName:string) => {
        setSelectedReviewInfo({reviewId, reviewerName});
        toggleIsReviewOpen();
    }

    /**
     * Open and close the review modal
     */
    const toggleIsReviewOpen = () => {
        setIsReviewOpen(!isReviewOpen);
    }

    return (
        <>            
            <CbitDataGrid 
                columns={consultantColumns} 
                rowData={reviews} 
                noRowsText={noReviewsFoundText} 
                isLoading={isLoading}
            />           
            <CustomModal 
                isOpen={isReviewOpen} 
                toggle={toggleIsReviewOpen }
            >
                <ReviewViewer 
                    reviewId={selectedReviewInfo.reviewId}                         
                    reviewerName={selectedReviewInfo.reviewerName}                         
                />
            </CustomModal>            
        </>
    )
}

export default ConsultantReviewTable;