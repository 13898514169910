import { ErrorMessage, Field, Form, Formik, FieldAttributes } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import * as Yup from "yup";
import { AgeGroupEnum, ageGroupLabels } from "../../../models/Enums/AgeGroupEnum";
import { EmptyReferral, IReferral, Referral, ReferralStatusEnum } from "../../../models/Referral/Referral";
import { UsTerritories } from "../../../models/utility/StateDictionary";
import { StringHelper } from "../../../models/utility/StringHelper";
import { ApplicationState } from "../../../store";
import { CurrentUserState } from "../../../store/CurrentUser";
import CbitMultiSelect, { MultiSelectOption } from "../../Utilities/FormFields/CbitMultiSelect";
import InputMask, { BeforeMaskedStateChangeStates, InputState } from "react-input-mask";
import "./ReferralForm.css";

type ReferralProps = {
    onFormSubmit:(referral:IReferral) => void
    referral?:IReferral
    isEditing:boolean
    updateIsEditing:(isEdit:boolean) => void
}

type ReferralFormState = {
    name:string    
    imageLink:string
    occupation:string
    description:string
    email:string
    phone:string
    streetName:string
    city:string
    zip:string
    state:string
    licensedStates:MultiSelectOption[]
    websiteLink:string
    ageGroup:string
    isContactPrivate:boolean
    isTelehealth:boolean
}

const ageGroupList = [
    AgeGroupEnum.Children,
    AgeGroupEnum.Adults,
    AgeGroupEnum.All
]

/**
 * Form component for creating a new referral object
 * TODO add max bio char limit
 */
const ReferralForm = (props:ReferralProps) => {    
    const {
            onFormSubmit,
            isEditing,
            referral,
            updateIsEditing
    } = props;
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);    
    
    const maxBioCharLimit = 2000;

    const initialState:ReferralFormState = isEditing && referral ? 
        {
            ...referral, 
            state:StringHelper.capitalizeWord(referral.state),
            ageGroup:referral.ageGroup.toString(), 
            licensedStates: referral.licensedStates.map(ls =>  ({label: StringHelper.capitalizeWord(ls, true), value: StringHelper.capitalizeWord(ls, true)})),            
            isTelehealth:referral.isTelehealth
        } 
        : 
        {
            ...EmptyReferral, 
            ageGroup: "", 
            licensedStates: []
        };

    const maxErrorMsg = (fieldName:string, maxLen:number) => (`${fieldName} must be less than ${maxLen} characters`);
    
    const minErrorMsg = (fieldName:string, minLen:number) => (`${fieldName} must be ${minLen} or more characters`);
    
    const requiredErrorMsg = (fieldName:string) => (`Please provide a ${fieldName}`);

    const phoneRegex = "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$";

    const validationSchema = Yup.object({
        name: Yup.string().required(requiredErrorMsg("Name"))
                            .matches(/^[a-zA-Z0-9 ]*$/, "Invalid name")
                            .min(2, minErrorMsg("Name", 2))
                            .max(32, maxErrorMsg("Name", 32)),
        occupation: Yup.string(),
        description:Yup.string(),
        email: Yup.string().required(requiredErrorMsg("valid email address"))
                            .email("Please provide a valid email address"),        
        phone: Yup.string().required("Please Enter a valid 10 digit Phone Number")                            ,
        streetName:Yup.string().required(requiredErrorMsg("valid street name")),
        city:Yup.string().required(requiredErrorMsg("valid city")),
        state:Yup.string().required(requiredErrorMsg("valid state")),
        zip:Yup.string().required(requiredErrorMsg("valid zip code"))
                        .min(5, "Please enter a valid 5-digit zip code")
                        .max(5, "Please enter a valid 5-digit zip code"),
        // licensedStates:Yup.array().of(Yup.string()), //TODO check if required
        ageGroup:Yup.mixed().oneOf(ageGroupList.map(a => a.toString()), "Age Group must be one of the following values: children, adults, or both").required(),
        websiteLink:Yup.string(), //TODO add website regex
        isContactPrivate:Yup.boolean(),
        isTelehealth:Yup.boolean()
    });

    /**
     * Handles submitting the form by creating a new referral obj 
     * with the values from the inputs
     * @param values 
     * @returns 
     */
    const handleSubmit = async (values:ReferralFormState) => {
        if(!userStore) {
            return;
        }

        const {
            name,
            imageLink,
            occupation,
            description,
            email,
            phone,
            streetName,
            city,
            zip,
            state,
            licensedStates,
            websiteLink,
            ageGroup,
            isContactPrivate,
            isTelehealth,
        } = values;

        const parsedAgeGroup = parseInt(ageGroup);

        if(isNaN(parsedAgeGroup)) {
            toast.error("Age group value is invalid");
            return;
        }

        const parsedLicensedStates = licensedStates.map(ls => ls.value);

        const parsedPhone = StringHelper.removeNonDigits(phone);

        //adding input field values to a new referral obj 
        const referralObj = new Referral(
            {
                name, 
                imageLink,
                occupation,
                description,
                email,
                phone:parsedPhone,
                streetName,
                city,
                zip,
                state,
                longitude: 0, //lon and lat are getting added on the backend through geoapify
                latitude: 0,
                licensedStates:parsedLicensedStates,
                websiteLink,
                ageGroup:parsedAgeGroup,
                createdAt:new Date(),
                updatedAt:new Date(),
                id:"",
                userProfileId:userStore.userProfile.id,
                isContactPrivate,
                status:ReferralStatusEnum.Unknown,
                isTelehealth:isTelehealth
            }
        );

        //When we are editing we need to update referral obj with our current referral
        if(isEditing && referral) {
            referralObj.id = referral.id;
            referralObj.imageLink = referral.imageLink;
            referralObj.longitude = referralObj.longitude;
            referralObj.latitude = referralObj.latitude;
        }
        
        onFormSubmit(referralObj);
    }

    const phoneNumberMask = "(999) 999-9999";

    /**
     * Updates licensed states field with Telehealth states when checked
     * @param e 
     * @param handleChange 
     * @param setFieldValue 
     */
    const onTelehealthCheck = (e:any, handleChange:any, setFieldValue:any) => {
        const checked = e.target.checked;
        handleChange(e);
        if(checked) {
            const teleState = Object.entries(UsTerritories.TelehealthStates).map(([key, value]) => ({label:key, value}))
            setFieldValue("licensedStates", teleState);
        } else {
            setFieldValue("licensedStates", [])
        }
    }

    return (
        <Formik
            enableReinitialize={true}             
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {props => (
                <Form                    
                >                    
                    <div className="cbit-row referral-container">
                        <div className="cbit-column referral-inputs">
                            <div className="cbit-form-group">                            
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                    <label htmlFor="name" className="cbit-label">Name</label>
                                    {" "}
                                    <span className="required-field">*</span>
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <Field 
                                            id="name" 
                                            name="name"
                                            className="form-input form-control"                                                                                
                                            type="text"
                                            placeholder="Full Name"   
                                            onChange={props.handleChange}
                                        />
                                        <div className="error-message-container">
                                            <ErrorMessage name="name"/>
                                        </div>
                                    </div>
                                </div>                        
                            </div>
                            <div className="cbit-form-group">
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <label htmlFor="occupation" className="cbit-label">Occupation</label>                                                                
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <Field 
                                            id="occupation" 
                                            name="occupation"
                                            className="form-input form-control"                                                                                
                                            type="text"
                                            placeholder="Occupation"   
                                            onChange={props.handleChange}
                                        />
                                        <div className="error-message-container">
                                            <ErrorMessage name="occupation"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cbit-form-group">
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <label htmlFor="description" className="cbit-label">Bio</label>                                                            
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <Field 
                                            id="description" 
                                            name="description"
                                            className="form-input form-control"                                                                                
                                            component="textarea"
                                            placeholder="Add a short bio about yourself"   
                                            rows={"4"}
                                            onChange={props.handleChange}
                                        />
                                        <div className="error-message-container">
                                            <ErrorMessage name="description"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cbit-form-group">
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                    <label htmlFor="email" className="cbit-label">Email</label>
                                    {" "}
                                    <span className="required-field">*</span>
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <Field 
                                            id="email" 
                                            name="email"
                                            className="form-input form-control"                                                                                
                                            type="text"
                                            placeholder="Email"   
                                            onChange={props.handleChange}
                                        />
                                        <div className="error-message-container">
                                            <ErrorMessage name="email"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cbit-form-group">
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                    <label htmlFor="phone" className="cbit-label">Phone</label>
                                    {" "}
                                    <span className="required-field">*</span>
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <Field 
                                            name="phone">
                                            {
                                                ({field}:FieldAttributes<any>) => (
                                                    <InputMask 
                                                        {...field}
                                                        id="phone" 
                                                        mask={phoneNumberMask}
                                                        placeholder="Phone"                                                       
                                                        type="text"                                                    
                                                        onChange={props.handleChange}                                            
                                                        className="form-input form-control"                                                                                
                                                    />
                                                )
                                            }                                                
                                        </Field>
                                        <div className="error-message-container">
                                            <ErrorMessage name="phone"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cbit-form-group">
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                    <label htmlFor="streetName" className="cbit-label">Address</label>
                                    {" "}
                                    <span className="required-field">*</span>
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div className="cbit-column referral-address-field">
                                        <Field 
                                            id="streetName" 
                                            name="streetName"
                                            className="form-input form-control"                                                                                
                                            type="text"
                                            placeholder="Street Name"   
                                            onChange={props.handleChange}
                                        />
                                        <div className="error-message-container">
                                            <ErrorMessage name="streetName"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div className="cbit-column referral-address-field">
                                        <Field 
                                            id="city" 
                                            name="city"
                                            className="form-input form-control"                                                                                
                                            type="text"
                                            placeholder="City"   
                                            onChange={props.handleChange}
                                        />
                                        <div className="error-message-container">
                                            <ErrorMessage name="city"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <Field 
                                            id="zip" 
                                            name="zip"
                                            className="form-input form-control"                                                                                
                                            type="text"
                                            placeholder="Zip Code"   
                                            onChange={props.handleChange}
                                        />
                                        <div className="error-message-container">
                                            <ErrorMessage name="zip"/>
                                        </div>
                                    </div>
                                    <div className="cbit-column">
                                        <Field 
                                            id="state" 
                                            name="state"
                                            className="form-input form-control"                                                                                
                                            as="select"
                                            placeholder="State"   
                                            onChange={props.handleChange}
                                        >
                                            <option
                                                value={""}
                                            >
                                                Please select a state
                                            </option>
                                        {
                                            Object.entries(UsTerritories.states).map(([key, value]) => (
                                                <option 
                                                    key={uuidV4()} 
                                                    value={key}
                                                >
                                                    {value}
                                                </option>
                                            ))
                                                }
                                        </Field>
                                        <div className="error-message-container">
                                            <ErrorMessage name="state"/>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className="cbit-form-group">
                                <div 
                                        role="group"
                                        className='user-form-checkbox-container'
                                        aria-label='checkbox-group'                                
                                    >
                                                                                                                     
                                            <div className="user-form-checkbox">
                                                <label  className="checkbox-label-cbit">
                                                    Do you want your contact private? {" "}
                                                    <Field 
                                                        type="checkbox" 
                                                        name="isContactPrivate"                                                                                                     
                                                        className='form-checkbox-input checkbox-cbit'                                                                
                                                    />                                                    
                                                </label>
                                            </div>                                                                                    
                                        <div className="error-message-container">
                                            <ErrorMessage name="isContactPrivate"/>                                                                                                    
                                        </div>                          
                                    </div>  
                            </div>
                            <div className="cbit-form-group">
                                <div 
                                        role="group"
                                        className='user-form-checkbox-container'
                                        aria-label='checkbox-group'                                
                                    >
                                                                                                                     
                                            <div className="user-form-checkbox">
                                                <label  className="checkbox-label-cbit">
                                                    Are you Telehealth licensed? {" "}
                                                    <Field 
                                                        type="checkbox" 
                                                        name="isTelehealth"
                                                        onChange={(e:any) => {onTelehealthCheck(e, props.handleChange, props.setFieldValue)}}
                                                        className='form-checkbox-input checkbox-cbit'                                                                
                                                    />                                                    
                                                </label>
                                            </div>
                                            <div className="error-message-container">
                                                <ErrorMessage name="isTelehealth"/>                                                                                                    
                                            </div>                                                                                                                                      
                                    </div>  
                            </div>
                            <div className="cbit-form-group">
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <label htmlFor="licensedStates" className="cbit-label">Licensed States</label>
                                        <br/>
                                        <label htmlFor="">
                                            Select all that applies
                                        </label>
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div className="cbit-column"> 
                                        <Field 
                                            id="licensedStates" 
                                            name="licensedStates"
                                            // className="form-input form-control"                                                                                                                            
                                            placeholder="Select Licensed States"   
                                            onChange={props.handleChange}
                                            component={CbitMultiSelect}
                                            isMulti={true}
                                            options={Object.entries(UsTerritories.states).map(([key, value]) => ({label:key, value}))}
                                        />
                                        <div className="error-message-container">
                                            <ErrorMessage name="licensedStates"/>
                                        </div>
                                    </div>
                                </div>     
                            </div>                                                                             
                            <div className="cbit-form-group">
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <label htmlFor="websiteLink" className="cbit-label">Website Link</label>                                                                        
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <Field 
                                            id="websiteLink" 
                                            name="websiteLink"
                                            className="form-input form-control"                                                                                
                                            type="text"
                                            placeholder="Website Link"   
                                            onChange={props.handleChange}
                                        />
                                        <div className="error-message-container">
                                            <ErrorMessage name="websiteLink"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cbit-form-group">
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                    <label className="cbit-label">Age Group</label>
                                    {" "}
                                    <span className="required-field">*</span>
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div 
                                        role="group"
                                        className='user-form-checkbox-container'
                                        aria-label='checkbox-group'                                
                                    >
                                        {
                                            ageGroupList.map((ageGroup) => (                                        
                                                <div className="user-form-checkbox" key={ageGroup}>
                                                    <label  className="checkbox-label-cbit">
                                                        <Field 
                                                            type="radio" 
                                                            name="ageGroup" 
                                                            value={ageGroup.toString()}                                                 
                                                            className='form-checkbox-input checkbox-cbit'                                                                
                                                        />
                                                        {ageGroupLabels[ageGroup]}
                                                    </label>
                                                </div>
                                            ))
                                        }  
                                        <div className="error-message-container">
                                            <ErrorMessage name="ageGroup"/>                                                                                                    
                                        </div>                          
                                    </div>  
                                </div>
                            </div>
                            <div className="form-btn-container">
                                {
                                    isEditing ? 
                                    (
                                        <>
                                            <button
                                                className='referral-btn-discard btn-cbit-minor' 
                                                type="button"
                                                onClick={() => updateIsEditing(false)}
                                            >
                                                Discard Changes
                                            </button>
                                            <button 
                                                className='btn-cbit-primary' 
                                                type="submit"
                                            >
                                                    Save
                                            </button>
                                        </>
                                    )
                                    :
                                    (
                                        <button className='btn-cbit-primary' type="submit">Submit</button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}

export default ReferralForm;