
/**
 * Sorts a passed in module/lesson/lessonContent by reference
 * TODO Check for Lesson and lessonContent
 * TODO replace with sort items
 * @param module 
 * @param desc // whether or not to sort by descending 
 */
export const sortModule = (module:any[], desc:boolean) => {
    if(desc) {
        module.sort((item, prevItem) : any => {
            const prevOrder = parseInt(prevItem.order);

            const currentOrder = parseInt(item.order);

            return prevOrder - currentOrder;
        }); 
    } else {
        module.sort((item, prevItem) : any => {
            const prevOrder = parseInt(prevItem.order);

            const currentOrder = parseInt(item.order);

            return currentOrder - prevOrder;
        }); 
    }
}  