import ContactUsForm from "./ContactUsForm";
import "./ContactUs.css";
import { Link } from "react-router-dom";

export default function ContactUs() {
    return (        
            <div className="cbit-page-container contact-us-page">
                <h1 className="cbit-header">Contact Us</h1>
                <div className="contact-us-inner-container">
                    <div className="contact-us-form-container">
                        <div className="contact-us-form-msg">
                            We would love to hear from you! Send us a message and we will get back to you shortly.
                        </div>
                        <ContactUsForm />
                    </div>
                    <div className="contact-us-location-container">
                        <div className="psyctech-location-container">
                            <h2 className="cbit-section-label">PSYCTECH, LLC</h2>
                            <ul>
                                <li>7660 Woodway Drive, Suite 575</li>
                                <li>Houston, Texas 77063</li> 
                                <li>(713) 914-9944</li> 
                                <li>Support@CBITTrainer.com</li>
                            </ul>
                        </div>
                        <div className="contact-us-questions-container">
                            <h2 className="cbit-section-label">QUESTIONS?</h2>
                            <div>
                                Check out our <Link className="cbit-link" to="/faq"> frequently asked questions!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>        
    )
}