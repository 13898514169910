import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { AdditionalContentController } from '../../../controllers/AdditionalContentController';
import { GroupedAdditionalContent } from '../../../models/AdditionalContent/AdditionalContent';
import { IContentCategory } from '../../../models/AdditionalVideoContent/ContentCategory';
import TherapistSupportContentItem from '../TherapistSupportContentItem';
import './TherapistSupportView.css';

type TherapistSupportViewProps = {
    selectedContentCategory:IContentCategory
}

type TherapistSupportViewState = {
    groupedContent:GroupedAdditionalContent[];    
    isLoading:boolean
}

const additionalContentController = new AdditionalContentController()

const TherapistSupportView = (props:TherapistSupportViewProps) => {
    const {selectedContentCategory} = props;

    const defaultState:TherapistSupportViewState = {
        groupedContent: [],        
        isLoading:false,
    }

    const [state, setState] = useState(defaultState);

    const [isLoading, setIsLoading] = useState(false);    

    useEffect(() => {
        async function onComponentMount() {
            await getAdditionalContent();
        }

        onComponentMount();
    },[selectedContentCategory]);

    const getAdditionalContent = async () => {
        const {tags} = selectedContentCategory;
        
        if(tags.length < 1)
            return;

        const tagsToSearchBy = tags.map(tag => tag._id);

        try {
            setState(prevState => ({
                ...prevState,
                isLoading:true
            }))
           const additionalContentList =  await additionalContentController.GetAdditionalContentAndGroupByTag(tagsToSearchBy);

           if(!additionalContentList) {
                toast.error("Could Not Retrieve Videos/PDFs");
                return;
           }

           setState(prevState => ({
                ...prevState,
                groupedContent:additionalContentList,
                isLoading:false
           }));
            
        } catch (error) {
            
            toast.error("Could Not Retrieve Videos/PDFs");
        }
    }

    const displayAdditionalContent = (subCategoryId:string) => {
  
        const foundContent = state.groupedContent.find(content => content.tagId === subCategoryId);

        if(!foundContent)
            return [];
        
        foundContent.content = foundContent.content.filter((content, index) => 
        !index || (content.id !== foundContent.content[index - 1].id));

        foundContent.content.sort((a, b) => {   
            const foundIndex = a.tagList.findIndex(t => t._id === subCategoryId);

            const foundBIndex = b.tagList.findIndex(t => t._id === subCategoryId);

            return a.tagList[foundIndex].order - b.tagList[foundBIndex].order;
        });

        return foundContent.content
    }    

    return (
        <>
            <div className="category-title-container">
                <h2 className="category-title">{props.selectedContentCategory.name}</h2>
            </div>
            <div className="tag-list">            
                {
                    selectedContentCategory.tags.map((tag) => (
                        <React.Fragment key={tag._id}>
                            <div className="tag-item">
                                <div className="category-title-container">
                                    <h4 className="tag-title">{tag._id}</h4>
                                </div>
                                <div className="tag-content-container">
                                    <>
                                    {
                                        displayAdditionalContent(tag._id).map((additionalContent => (
                                            <TherapistSupportContentItem 
                                                subCategory={tag._id}
                                                additionalContent={additionalContent}                                                                                         
                                            />
                                        )))
                                    }                                    
                                    </>
                                </div>                                                    
                            </div>
                        </React.Fragment>
                    ))
                }
            </div>
    </>
    )
}

export default TherapistSupportView;