import React, {useEffect, useState} from "react"
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import NavSidebar from "../../SocialConnection/components/layout/NavSidebar/NavSidebar";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import BasicInlineForm from "../../AdminDashboard/AdminForms/InlineForms/BasicInlineForm";
import editIconSrc from "../../../resources/icons/icon-edit.png";
import trashIconSrc from "../../../resources/icons/icon-trash.png";
import {SupportContent} from "../../../models/AdditionalContent/SupportContent";
import {SupportContentController} from "../../../controllers/SupportContentController";
import toast from "react-hot-toast";
import {DeepCopy} from "../../../models/utility/DeepCopy";
import { ReactComponent as DragGrip } from '../../../resources/images/icon-drag-drop.svg';
import {StandardContentButton} from "../../AdminDashboard/ContentBuilder/ContentBuilderMenu/ContentBuilderMenuButtons/ContentBuilderMenuButton";

type FamilySupportAdminDashboardPanelProps = {
    isAdmin:boolean;
    contentCategories:SupportContent[]
    selectedCategory:SupportContent;
    updateCategory:(contentCategory:SupportContent) => void
    addNewContentCategory:(name:string)=>void;
    setSelectedContentCategory:(content: SupportContent) =>void
    setItemToDelete:(id:string)=>void
    toggleDeleteAlert:()=>void
    children: React.ReactNode
}

type FamilySupportAdminDashboardPanelState = {
    newCategoryName:string
    isShowingInlineForm:boolean
    categoryEditingId:string
}

const supportContentController = new SupportContentController();

const FamilySupportLayout = (props:FamilySupportAdminDashboardPanelProps) => {
    // Navigation Panel
    const [isNavOpen, setIsNavOpen] = useState(true);
    const {height, width} = useWindowDimensions();

    const toggle = () => {
        setIsNavOpen(!isNavOpen);
    }

    useEffect(() => {
        // If the width of the device is greater than 1023px, open the navigation panel
        if (width > 1023) {
            setIsNavOpen(true);
        }

        // If the width of the device is less than 1023px, close the navigation panel
        if (width <= 1023) {
            setIsNavOpen(false);
        }

    }, [width]);

    const {
        isAdmin,
        contentCategories,
        updateCategory,
        addNewContentCategory,
        setSelectedContentCategory,
        setItemToDelete,
        toggleDeleteAlert,
        selectedCategory,
        children
    } = props;

    const defaultState:FamilySupportAdminDashboardPanelState = {
        newCategoryName: '',
        isShowingInlineForm:false,
        categoryEditingId:""
    }

    const [isShowingEditInlineForm, setIsShowingEditInlineForm] = useState(false);

    const [state, setState] = useState(defaultState);


    useEffect(()=>{
        if(contentCategories.length>0){
            setSelectedContentCategory(contentCategories[0])
        }
    },[contentCategories])

    /**
     * Toggles weather or not to show the inline form
     */
    const toggleInlineForm = () => {

        const isShowingInlineForm = !state.isShowingInlineForm;

        setState(prevState => ({
            ...prevState,
            isShowingInlineForm
        }));
    }

    const handleEditingCategory = (categoryName:string) => {
        const contentCategory = props.contentCategories.find(category => category.id === state.categoryEditingId);

        if(!contentCategory)
        {
            toast.error("Failed To Update Category");
            return;
        }

        const contentCategoryCopy:SupportContent = DeepCopy.copy(contentCategory);

        contentCategoryCopy.name = categoryName;

        updateCategory(contentCategoryCopy);
    }

    const toggleEditInlineForm = () => setIsShowingEditInlineForm(!isShowingEditInlineForm);

    const handleToggleEditForm = (e:React.MouseEvent<HTMLButtonElement>, categoryId:string) => {
        e.stopPropagation();
        setState(prevState => ({...prevState, categoryEditingId: categoryId}))
        toggleEditInlineForm();
    }


    const handleDeleteClick = (e:React.MouseEvent<HTMLButtonElement>, contentCategory:SupportContent) => {
        e.stopPropagation();
        setItemToDelete(contentCategory.id);
        toggleDeleteAlert();
    }

    /**
     * When a content category finish dragging update the order of that category
     * and update the database
     * @param results
     * @returns
     */
    const afterDragUpdateCategoryDatabase = (results:any) =>
    {
        if (!results.destination) return;

        const contentCategories:SupportContent[] = DeepCopy.copy(props.contentCategories);

        const [reorderedItem] = contentCategories.splice(results.source.index, 1);

        contentCategories.splice(results.destination.index, 0, reorderedItem);

        const updateTasks = contentCategories.map((content, index) => {
            try {
                content.order = index;
                supportContentController.UpdateSupportContent(content);

            } catch (error) {
                console.error(error);
                toast.error("Failed To Reorder Category")
            }
        });

        //Update Local List
        updateCategory(contentCategories[0]);

        Promise.all(updateTasks);
    }

    const getSelectedStyles = (id:string) => {
        if(!selectedCategory)
            return "";

        return selectedCategory.id === id ? "active-nav-item " : "";
    }

    const handleLinkClick = () => {
        if (width > 1023) {
            return;
        }
        setIsNavOpen(false);
    }

    return (
        <div className={`therapist-support-layout ${isNavOpen ? 'isOpen' : ''}`}>
            {/* Navigation Panel */}
            <div className="AccordionContent">
                <NavSidebar toggle={toggle}
                            isNavOpen={isNavOpen}
                            setIsNavOpen={setIsNavOpen}
                            title={'Family Support'}
                >
                    <DragDropContext onDragEnd={afterDragUpdateCategoryDatabase}>
                        <Droppable droppableId='content-category-drop-zone'>
                            {(provided, snapshot) => (
                                <div className="" {...provided.droppableProps} ref={provided.innerRef}>
                                    <>
                                        {
                                            contentCategories.map((category, index) => (
                                                <Draggable
                                                    key={category.id}
                                                    draggableId={category.id}
                                                    index={index}
                                                >
                                                    {(dragProvided, snapshot) => (
                                                        <div
                                                            key={category.id}
                                                            onClick={() => setSelectedContentCategory(category)}
                                                            className={`chapter-content-container ${getSelectedStyles(category.id)}`}
                                                            ref={dragProvided.innerRef}
                                                            {...dragProvided.draggableProps}
                                                        >
                                                            <div className="chapter-icon-container">


                                                                {isAdmin && (
                                                                    <div
                                                                        className="drag-handle"
                                                                        {...dragProvided.dragHandleProps}
                                                                    >
                                                                        <DragGrip />
                                                                    </div>
                                                                )}

                                                                <div className="sidebar-link-text" onClick={handleLinkClick}>
                                                                    {
                                                                        isShowingEditInlineForm && state.categoryEditingId === category.id ?
                                                                            (
                                                                                <BasicInlineForm
                                                                                    text={category.name}
                                                                                    placeHolderText={'Enter A Name For This Category'}
                                                                                    onSubmit={handleEditingCategory}
                                                                                    onBlur={toggleEditInlineForm}
                                                                                />
                                                                            ) : (
                                                                                <div className='hover'>
                                                                                    {category.name}
                                                                                </div>
                                                                            )
                                                                    }
                                                                </div>
                                                            </div>
                                                            {
                                                                isAdmin && (
                                                                    <div className="therapist-panel-btn-container">
                                                                        <button
                                                                            className="btn-cbit-icon"
                                                                            onClick={(e) => handleToggleEditForm(e, category.id)}
                                                                        >
                                                                            <img src={editIconSrc} alt="Edit Icon" />
                                                                        </button>
                                                                        <button
                                                                            className="btn-cbit-icon"
                                                                            onClick={(e) => handleDeleteClick(e, category)}>
                                                                            <img src={trashIconSrc} alt="Trash Icon" />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))
                                        }
                                        <>
                                            {
                                                state.isShowingInlineForm && (
                                                    <div className="chapter-content-container">
                                                        <BasicInlineForm
                                                            text={''}
                                                            placeHolderText={'Enter A Name For This Category'}
                                                            onSubmit={addNewContentCategory}
                                                            onBlur={toggleInlineForm}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </>
                                    </>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <>
                        {
                            isAdmin && (
                                <div className="standard-content-btn-container">
                                    <StandardContentButton
                                        isLoading={false}
                                        onClick={toggleInlineForm}
                                        btnLabel={'Add Category'}
                                    />
                                </div>
                            )
                        }
                    </>
                </NavSidebar>
            </div>
            {/* Main content container */}
            <div className="therapist-support-main-content">
                {children}
            </div>
        </div>
    )
}

export default FamilySupportLayout