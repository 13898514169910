import moment from "moment";
import { IUserBio } from "../module/UserProfile";
import { IConsultation } from "./Consultation";

export interface IConsultationSession 
{
    consultation:IConsultation,
    consultant:IUserBio,
    attendeesBioList:IUserBio[]
}

/**
 * Class for holding more info about a consultation
 */
export class ConsultationSession implements IConsultationSession {
    consultation:IConsultation;
    consultant:IUserBio;
    attendeesBioList: IUserBio[];
    
    constructor(consultation:IConsultation, consultant:IUserBio, attendeesBioList:IUserBio[]) {
        this.consultation = consultation;
        this.consultant = consultant;
        this.attendeesBioList = attendeesBioList;        
    }

    /**
     * Sorts a list of consultation sessions by time in
     * @param consultations 
     */
    static SortConsultationSessionsByTime = (sessions:IConsultationSession[], desc:boolean = false) => {        
        return sessions.sort(function(a:IConsultationSession, b:IConsultationSession) {
            if(desc) {
                return moment(a.consultation.startDate as string).isBefore(moment(b.consultation.startDate as string)) ? 1 : -1;
            }
            return moment(a.consultation.startDate as string) < (moment(b.consultation.startDate as string)) ? -1 : 1;                
        })
    }
    
}