import {AxiosError} from "axios";
import toast from "react-hot-toast";

export class ErrorHandler{
    static catchApiError = (error:AxiosError) : never => {
        console.info(error);
        console.error('API ERROR: ', error);
        const message = (error as AxiosError).response?.data.message; // makes sure to refactor this because it will throw an undefined error if message is not on data            
        throw Array.isArray(message) ? message : [message];
    }

    /**
     * Display a list of error messages or a group of errors in toast
     * @param errMsgs 
     * @returns 
     */
    static displayErrorsInToast = (errMsgs:string | string[]) => {        
        if(!Array.isArray(errMsgs)) {
            toast.error(errMsgs);
            return;
        }

        for(let err of errMsgs) {
            toast.error(err);
        }
    }

    //TODO:RE-WRITE TO ADD TOSTER
    static displayError = (error:AxiosError) : never => {
        console.info(error);
        console.error('API ERROR: ', error);
        const message = (error as AxiosError).response?.data.message; // makes sure to refactor this because it will throw an undefined error if message is not on data            
        throw Array.isArray(message) ? message : [message];
    }
    
    static generalError = (error:any) : never =>{
        console.info(error);
        console.error('Error Thrown: ', error);
        throw error;
    }
}
