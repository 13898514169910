import { AxiosError } from "axios";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { StripeService } from "../services/StripeService";
import { ConsultationPaymentIntentRequest } from "../models/Stripe/ConsultationPaymentIntentRequest";

/**
 * Controller for communicating with stripe services
 */
export class StripeController {

    constructor() {
        this.stripeService = new StripeService();        
    }

    private stripeService:StripeService;

      /**
     * Get product information from stripe
     * @param productId 
     * @returns 
     */
      public async GetStripeProduct(productId:string) {
        try {                        
            return await this.stripeService.GetStripeProduct(productId);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Get stripe product by price id
     * @param priceId 
     * @returns 
     */
    public async GetStripeProductByPriceId(priceId:string) {
        try {            
            return await this.stripeService.GetStripeProductByPriceId(priceId)            
        } catch (error) {
           return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
    /**
     * Calls the api to create a new stripe customer and subscription.
     * @param user 
     * @returns 
     */
    public async CreateCbitSubscription(userId:string) {
        try {                        
            return await this.stripeService.CreateCbitSubscription(userId);            
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
    /**
     * Calls the api to get the current logged in user current subscription payment intent.
     * @param user 
     * @returns 
     */
    public async GetCurrentSubscriptionIntent() {
        try {                        
            return await this.stripeService.GetCurrentSubscriptionIntent();
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
       }
    }

    /**
     * Creates a consultation payment intent based on the consultation type
     * @param userId 
     * @param consultationId 
     * @returns 
     */
    public async CreateConsultationPaymentIntent(paymentIntentReq:ConsultationPaymentIntentRequest) {
        try {
            return await this.stripeService.CreateConsultationPaymentIntent(paymentIntentReq);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Calls services to create a subscription for a test user
     */
    public async CreateSubscriptionForTestUser(userId:string) {
        try {
            return await this.stripeService.CreateSubscriptionForTestUser(userId);
        } catch (error) {
            throw error;
        }
    }
}