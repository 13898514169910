import { IReferral, ReferralFilters, ReferralStatusEnum } from "../models/Referral/Referral";
import {BaseReactService} from "./interfaces/BaseReactService";

/**
 * Service class for communicating with the API to handle the referral object
 */
export class ReferralService extends BaseReactService{
    constructor()
    {
        super("Referral/");
    }

    public async GetAllReferralsByStatus(referralStatus?:ReferralStatusEnum) {
        const endPoint = "Referral/GetAllReferralsByStatus";
        return await this.CustomGet({referralStatus}, endPoint);
    }

    public async GetReferralsByFilters(referralFilters:ReferralFilters) {        
        const endPoint = "Referral/GetReferralsByFilters";
        return await this.GetWithObject(referralFilters, endPoint);
        // return await this.CustomGet({...referralFilters}, endPoint);
    }

    public async GetReferralByUserId(userId:string) {
        const endPoint = "Referral/GetReferralByUserId";
        return await this.CustomGet({userId}, endPoint);
    }
    /**
     * Submits a request to the api to create a new referral
     * @param referral 
     * @returns 
     */
    public async CreateNewReferral(referral:IReferral) {
        const endPoint = "Referral/CreateNewReferral/";
        return await this.Post(referral, endPoint);
    }
    
    public async UpdateReferral(id:string, referral:IReferral) {
        const endPoint = "Referral/UpdateReferral/";
        return await this.Put({id},referral, endPoint);
    }

    public async UpdateReferralStatus(id:string, userId:string, status:ReferralStatusEnum):Promise<boolean> {
        const endPoint = "Referral/UpdateReferralStatus";
        return await this.CustomPatch({id, userId, status}, endPoint)
    }
}
