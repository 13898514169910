import React, {useEffect, useState} from "react"
import {actionCreators, CurrentUserState} from "../../../../store/CurrentUser";
import {ApplicationState} from "../../../../store";
import {useSelector, useDispatch} from "react-redux";
import {NotificationController} from "../../../../controllers/NotificationController";
import {INotification} from "../../../../models/Inbox/Notification";
import NotificationItem from "../../components/inbox/NotificationItem";
import MarkAllAsRead from "../../components/inbox/MarkAllAsRead";
import {Spinner} from "reactstrap";
import './Inbox.css'
import ContinuingEducationLayout from "../../../ContinuingEducation/components/layout/ContinuingEducationLayout";
import InboxLayout from "../../components/layout/InboxLayout";

const InboxPage = () => {
    const [notifications, setNotifications] = useState([])
    const [loading, setLoading] = useState<Boolean>(true)
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);

    const nController = new NotificationController()

    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>((state )=> state.currentUser);

    const {updateNotificationCount} = actionCreators;

    const dispatch = useDispatch()

    const getNotifications = async () => {
        const data = await nController.GetNotifications(userStore!.userProfile.id, pageNumber, pageSize)
        setNotifications(data)
        setLoading(false)
        return data
    }

    useEffect(() => {
        getNotifications()
    }, [])

    useEffect(() => {
    }, [notifications])

    const SetNewNotificationData = (data: any) => {

        let incData = data;

        //@ts-ignore
        setNotifications((prevState) => (
            prevState.map((n: INotification) => {
                if (n?.id === data?.id) {
                    n.hasRead = true;
                    incData = n;
                }
                return n;
            })
        ))

    }

    const setNewNotificationsData = (data: any) => {
        let newList = notifications.filter((n: INotification) => n?.id !== data?.id);
        setNotifications(newList);
    }

    const setNewNotifications = (data: any) => {
        setNotifications(data);
    }

    const markAllAsRead = async () => {
      try {
          let unreadNotificationCount = 0;

          if (notifications.length === 0) {
              return;
          }

          notifications.forEach((n: INotification) => {
              if (n.hasRead) {
                  return
              }
          })

          notifications.forEach((n: INotification) => {
             if (!n.hasRead) {
                 unreadNotificationCount++;
             }
          })

          let data: any = await nController.MarkAllAsRead(userStore!.userProfile.id).then((d) => {

              // @ts-ignore
              setNotifications((prevState) => (
                    prevState.map((n: INotification) => {
                        n.hasRead = true;
                        return n;
                    })
                ))
            })

          // @ts-ignore
          dispatch(updateNotificationCount(0))
          return data;
      }
        catch (e) {
            console.error(e)
        }
    }

    const loadMore = async (pageNumber: number, pageSize: number) => {
        let newPage = pageNumber + 1;
        nController.GetNotifications(userStore!.userProfile.id, newPage, pageSize).then((data) => {
            if (data.length <= pageSize) {
                setIsCompleted(true);
            }
            // @ts-ignore
            setNotifications([...notifications, ...data]);
        });
        setPageNumber(newPage);
    }

    return (
    <InboxLayout>
        {loading ? (
            <div className="spinner-container">
                <Spinner color='primary'/>
            </div>
        ) : (
            <>
                <div className='inbox-header-container'>
                    <h2 className="inbox-header-title">
                        Inbox
                    </h2>
                    <MarkAllAsRead markAllAsRead={markAllAsRead} />
                </div>
                <div className="inbox-container">
                    {/* @ts-ignore */}
                    {notifications.length > 0 ? notifications.map((notification: INotification) => (
                        <NotificationItem notification={notification} key={notification.id} updateNotification={SetNewNotificationData} updateNotifications={setNewNotificationsData}/>
                    )) : (
                        <div className="inbox-no-notifications-container">
                            <h3 className="inbox-no-notifications-header">
                                No Notifications
                            </h3>
                        </div>
                    )}
                </div>

                {notifications.length > 9 && !isCompleted ? (
                    <div className="inbox-load-more-container">
                        <button className="inbox-load-more-button" onClick={() => loadMore(pageNumber, pageSize)}>
                            {loading ? (<Spinner color='primary'/>) : ("Load More")}
                        </button>
                    </div>
                ) : (
                    <></>
                )}
            </>
        )}
    </InboxLayout>
  )
}

export default InboxPage