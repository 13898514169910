import { AgeGroupEnum } from "../Enums/AgeGroupEnum"

export enum ReferralStatusEnum {
    Unknown = 0,
    InReview = 1,
    Accepted = 2,
    Declined = 3,
    Deleted = 4
}

export const ReferralStatusUi = {
    [ReferralStatusEnum.Unknown]: "-",
    [ReferralStatusEnum.InReview]: "Pending Review",
    [ReferralStatusEnum.Accepted]: "Accepted",
    [ReferralStatusEnum.Declined]: "Declined",
    [ReferralStatusEnum.Deleted]: "Deleted",
}

export type ReferralFilters = {
    ageGroupFilters:AgeGroupEnum[]
    licensedStateFilter:string
    stateFilter:string        
}

export interface IReferral {
    id:string
    userProfileId:string
    name:string
    imageLink:string
    occupation:string
    description:string
    email:string
    phone:string
    streetName:string
    city:string
    zip:string
    state:string
    licensedStates:string[]
    websiteLink:string
    ageGroup:AgeGroupEnum
    isContactPrivate:boolean
    isTelehealth:boolean
    longitude:number,
    latitude:number,
    createdAt: Date| string| undefined | null;
    updatedAt: Date| string| undefined | null;
    status:ReferralStatusEnum
}

export class Referral implements IReferral{
    constructor(referral:IReferral) {
        this.id = referral.id;
        this.userProfileId = referral.userProfileId;
        this.name = referral.name;
        this.imageLink = referral.imageLink;
        this.occupation = referral.occupation.trim().toLowerCase();
        this.description = referral.description;
        this.email = referral.email.trim().toLowerCase();
        this.phone = referral.phone.trim().toLowerCase();
        this.streetName = referral.streetName.trim().toLowerCase();
        this.city = referral.city.trim().toLowerCase();
        this.zip = referral.zip.trim().toLowerCase();
        this.state = referral.state.trim().toLowerCase();
        this.licensedStates = referral.licensedStates;
        this.websiteLink = referral.websiteLink.trim().toLowerCase();
        this.ageGroup = referral.ageGroup;
        this.isContactPrivate = referral.isContactPrivate;
        this.isTelehealth = referral.isTelehealth;
        this.longitude = referral.longitude;
        this.latitude = referral.latitude;
        this.createdAt = referral.createdAt;
        this.updatedAt = referral.updatedAt;      
        this.status = referral.status;  
    }
    
    id: string
    userProfileId: string
    name:string
    imageLink: string
    occupation: string
    description: string
    email: string
    phone: string
    streetName: string
    city: string
    zip: string
    state: string
    licensedStates: string[]
    websiteLink: string
    ageGroup: AgeGroupEnum
    isContactPrivate: boolean
    isTelehealth: boolean
    longitude: number
    latitude: number
    createdAt: string | Date | null | undefined
    updatedAt: string | Date | null | undefined
    status: ReferralStatusEnum;
}

export const EmptyReferral:IReferral = {
    id: "",
    userProfileId: "",
    name: "",
    imageLink: "",
    occupation: "",
    description: "",
    email: "",
    phone: "",
    streetName: "",
    city: "",
    zip: "",
    state: "",
    licensedStates: [],
    websiteLink: "",
    ageGroup: AgeGroupEnum.All,
    isContactPrivate: false,
    longitude: 0,
    latitude: 0,
    createdAt: undefined,
    updatedAt: undefined,
    status: ReferralStatusEnum.Unknown,
    isTelehealth: false
}