import React, { useState } from "react";
import { ConsultationController } from "../../../../controllers/ConsultationController";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as DOMPurify from "dompurify"
import toast from "react-hot-toast";
import { IConsultationSession } from "../../../../models/consultations/ConsultationSession";
import "./AddRecordingLink.css";

type AddRecordingLinkProps = {
    consultationSession:IConsultationSession
    onAddLinkSubmit:() => void;
}

type recordingLinkForm = {
    recordingLink:string
}

const consultationController = new ConsultationController();

const domPurifyOpts = {USE_PROFILES: {html: false},  ALLOWED_TAGS: [],  };

/**
 * Form for adding recording link to a webinar
 * @returns 
 */
const AddRecordingLink = (props:AddRecordingLinkProps) => {
    const {
        consultationSession : {consultant, consultation},
        onAddLinkSubmit
    } = props;

    const [isLoading, setIsLoading] = useState(false);

    const initialValues:recordingLinkForm = {
        recordingLink: consultation.recordingLink ?? "",
    }

    //Validation object for formik
    const validationObj = {
        recordingLink: yup.string().trim()
        .min(23, "Recording link must be 23 characters or more")
        .max(200, "Recording link must be 200 characters or less")        
    }

    const validationSchema = yup.object(validationObj);
    
    /**
     * Updates the consultation with the zoom recording link
     */
    const onSubmit = async (formData:recordingLinkForm) => {
        const {recordingLink} = formData;
        const loadId = "zoomRecordingLoad";
        try {
            toast.loading("Adding link to consultation", {id:loadId});
            setIsLoading(true);
            const cleanLink = DOMPurify.sanitize(recordingLink, domPurifyOpts)
            await consultationController.AddRecordingToConsultation(cleanLink, consultation.id);
            toast.success("Recording successfully added");
        } catch (error) {
            console.error(error);
            toast.error("Failed to add zoom recording link");
        }
        onAddLinkSubmit();
        toast.dismiss(loadId);
        setIsLoading(false);
    }    

    return (        
         <div className="cbit-dialog zoom-recording-link">
            <h2 className="cbit-header">Add Zoom Recording</h2>
            <div className="cbit-form-group">
                <div className="cbit-label">Consultant</div>
                <div>
                    {`${consultant.firstName} ${consultant.lastName}`}
                </div>
            </div>
            <div className="cbit-form-group">
                <div className="cbit-label">Title</div>
                <div>
                    {`${consultation.name}`}
                </div>
            </div>
            <div className="cbit-form-group">
                <div className="cbit-label">Date</div>
                <div>
                    {`${consultation.startDate} ${consultation.endDate}`}
                </div>
            </div>
            <div className="cbit-form-group">
                <div className="cbit-label">Title</div>
                <div>
                    {consultation.name}
                </div>
            </div>
            <div className="cbit-form-group">
                <div className="cbit-label">Notes</div>
                {
                    consultation.description.length > 0 ? 
                    (
                        <div>
                            {consultation.description}
                        </div>
                    ) 
                    :
                    (
                        <div className="italicize-text">
                            No notes
                        </div>
                    )
                }
            </div>
            <Formik
                initialValues={initialValues}
                initialTouched={{recordingLink: true}}                
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({handleSubmit}) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="cbit-form-group">
                        <div className="cbit-row">
                            <label className='form-label' htmlFor="recordingLink">Recording Link
                            </label>
                        </div>
                        <div>
                            <Field 
                                id="recordingLink"
                                className="form-input" 
                                name="recordingLink" 
                                placeholder="Recording Link"
                                type="text"
                            />
                            <div className="error-message-container">
                                <ErrorMessage name="recordingLink" />
                            </div>
                        </div>
                        </div>
                        <div className="cbit-dialog-btn-container">
                            <button 
                                disabled={isLoading}
                                type="submit" 
                                className="btn-cbit-primary"
                            >
                                    Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>

         </div>
    )
}

export default AddRecordingLink;