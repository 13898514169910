import * as React from "react";
import { Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { UserLessonContent } from "../../../models/lesson/UserLessonContent";
import { PartialModuleTreeModel } from "../../../models/partialModels/PartialModuleTreeModel";
import { ErrorHandler } from "../../../models/utility/ErrorHandler";
import { ApplicationState } from "../../../store";
import * as CurrentUser from "../../../store/CurrentUser";
import * as UserTreeView from "../../../store/UserModuleAccordionStore";
import AdminModuleAccordionItem from "./AdminModuleAccordionItem";
import { Spinner } from "reactstrap";
import { AdminModuleController } from "../../../controllers/AdminModuleController";
import { AdminTreeModule } from "../../../models/partialModels/AdminTreeModule";
import { DeepCopy } from "../../../models/utility/DeepCopy";
import './AdminAccordion.css';
import { LessonIconEnum } from "../../../models/lesson/Lesson";

type AccordionProps =
    UserTreeView.currentUserAccordionProps
    & CurrentUser.CurrentUserState
    & typeof UserTreeView.actionCreators
    & RouteComponentProps 
    & { 
        id: string
        setNavigateTo:(module:AdminTreeModule) => void    
        discardChanges:boolean
        updateIsLoading:(isLoading:boolean) => void
        isLoading:boolean
    }
    
type AccordionState =
    {
        parentModule: PartialModuleTreeModel,
        selectedModule: PartialModuleTreeModel,
        isUpdating: boolean,
        nextLessonId:string,
        selectedContent: UserLessonContent[],
        topParentModuleId: string,
        userId: string,
        isLoading: boolean,
    }

export const cleanState: AccordionState = {
        isLoading: false,
        isUpdating:false,
        userId:'',
        topParentModuleId:'',
        nextLessonId:'',
        selectedContent:[],
        parentModule: {
            id: '',
            parentDocumentId: '',
            originalDocumentId: '',
            type: 0,
            order: 0,
            name: '',
            time: '',
            description: '',
            isViewable: true,
            isDisabled: false,
            isEditable: true,
            isOptional: true,
            isComplete: false,
            isLocked: false,
            isOpen: false,
            isNext:false,
            children: [],   
            lessonIcon:LessonIconEnum.Unknown,
            totalQuestions:0     
        },
        selectedModule: {
            id: '',
            parentDocumentId: '',            
            originalDocumentId: '',
            type: 0,
            order: 0,
            name: '',
            time: '',
            description: '',
            isViewable: true,
            isDisabled: false,
            isEditable: true,
            isOptional: true,
            isComplete: false,
            isLocked: false,
            isNext:false,
            isOpen: false,
            children: [],
            lessonIcon:LessonIconEnum.Unknown,
            totalQuestions: 0
        } 
    }
    
class AdminModuleAccordion extends React.PureComponent<AccordionProps, AccordionState>
{    
    _isMounted = false;
    private isLoggedIn = this.props.isLoggedIn;    
    private adminModuleController : AdminModuleController = new AdminModuleController();

    constructor(props:any){
        super(props);
        this.state = DeepCopy.copy(cleanState);
    }        
    
    async componentDidMount(){        

        this._isMounted = true;

        try
        {   
            
            if(this._isMounted) {
                this.props.SetUserAccordion(cleanState);
                this.getTreeViewData();
            }
            
        }catch(error)
        {
            ErrorHandler.generalError((error));            
        }
    }

    async componentDidUpdate(prevProps:AccordionProps) {
        if(prevProps.parentModule.id !== this.props.parentModule.id) {
            this.getTreeViewData();
        }
        if(this.props.discardChanges) {
            this.getTreeViewData()                                    
        }
    }

    componentWillUnmount() {        
        this._isMounted = false;
        
        this.setState(DeepCopy.copy(cleanState));

        this.props.SetUserAccordion(DeepCopy.copy(cleanState));        
    }

    /**
     * Navigates to a module or toggle save confirmation
     */
    private navigateToModule = async (module:AdminTreeModule) => {
            
            const {selectedModule} = this.props;

            if(selectedModule.id === module.id)
                return;
             
            
            this.props.setNavigateTo(module);        
    }   
    
    /**
     * Get data for the admin module accordion
     */
    private getTreeViewData = async () => {
        this.setState(prevState => ({
            ...prevState,
            isLoading:true
        }))
        

        try {
                        
            //If the property id is not null then load in the entire module
            if(this.props.id !== null)
            {
                const parent = await this.adminModuleController.getAdminAccordionTree(this.props.id);                

                    this.setState(prevState => ({
                        ...prevState,
                        parentModule: parent as any,
                        isLoading: false,
                    }));                
            
                
                this.props.SetUserAccordion({
                    ...this.state,
                    parentModule:parent as any,
                    selectedModule:this.props.selectedModule
                });
            }
        } catch (error) {
            ErrorHandler.generalError((error));  
        }

        this.setState(prevState => ({
            ...prevState,
            isLoading:false
        }));
    }    
    
    public render()
    {
        return(
            <React.Fragment>
                <div id="admin-navigation" className="admin-nav">
                    <div className="admin-nav-header-container">
                        <div className="admin-nav-header">     
                        <div className="admin-nav-title-container">
                            <h2 className="admin-nav-title">
                            Course Outline
                            </h2>        
                        </div>
                        <div className="admin-nav-text-container">
                            <div className="admin-nav-text">
                                Below is an outline of your course. Click on the chapter or lesson to view and edit the content.
                            </div>
                        </div>
                        </div>
                    </div>
                    {
                        this.state.isLoading ? 
                        (
                            <div className="admin-accordion-spinner">
                                <Spinner className='custom-spinner' color="white">loading</Spinner>
                            </div>
                        ) 
                        : 
                        (
                            <Accordion className="admin-nav-body" defaultActiveKey="0">
                                {
                                    this.props.parentModule?.children?.map((module) =>
                                    (
                                        <AdminModuleAccordionItem key={module.id}
                                            discardChanges={this.props.discardChanges}
                                            staticContext={this.props.staticContext}
                                            history={this.props.history}
                                            location={this.props.location}
                                            depth={0}
                                            module={module}
                                            navigateToModule={this.navigateToModule}
                                            match={this.props.match}/>
                                    ))}
                    </Accordion>
                    )}
                </div>
            </React.Fragment>
        )
    }
}
export default connect(
    (state: ApplicationState) => state.currentUserModuleAccordion,
    UserTreeView.actionCreators
)(AdminModuleAccordion);