import {ILesson, LessonIconEnum} from "../lesson/Lesson";
import {IModule} from "../module/Module";
import {LessonTypeEnum} from "../lesson/LessonType";

export enum TreeViewType
{
    none=0,
    Branch=1,
    CustomLesson=2,
    VideoLesson =3,
    TextLesson = 4,
    QuizLesson =5,
}

export function convertToPartialModuleEnum(isLesson:boolean, type:LessonTypeEnum)
{
    if(isLesson)
    {
        switch(type){
            case LessonTypeEnum.video:
                return 3;
                break;
            case LessonTypeEnum.text:
                return 4;
                break;
            case LessonTypeEnum.quiz:
                return 5;
                break;
            case LessonTypeEnum.custom:
                return 2;
                break;
        }    
    }else
    {
        return 1;
    }
}

export class PartialModuleTreeModel {
    id: string = '';
    parentDocumentId: string ='';
    originalDocumentId: string='';
    type:TreeViewType = 0;

    order:number =  0;
    name:string = '';
    time:string ='';
    description:string = '';
    isViewable:boolean = true;
    isDisabled:boolean = false;
    isEditable:boolean = true;
    isOptional:boolean = true;
    isComplete:boolean = false;
    isNext:boolean = false;
    isOpen:boolean =false;
    isLocked:boolean = true;
    lessonIcon:LessonIconEnum = LessonIconEnum.Unknown;
    totalQuestions:number = 0;
    children:PartialModuleTreeModel[] = [];
}

/**
 * Navigate through the tree to get the path to the node with the id passed in
 * TODO add to PartialModuleTreeModel class as a static function
 * @param node 
 * @param arr 
 * @param id 
 * @returns 
 */
 export function getModuleTreePath(node:PartialModuleTreeModel, arr:any[], id:string) {            

     if(node == null){
         return false;
     }
     //@ts-ignore
    if(node.parentId && node.parentId.length > 0)
        arr.push({...node});

    if(node.id === id) {
        return true
    }
    
        let i;
        
        for(i = 0; i < node.children.length; i++) {
                                    
            if(getModuleTreePath(node.children[i], arr, id)) {
                return true
            }
        }                    

    arr.pop();
    return false;
}

/**
 * Get the total amount of items in the passed in tree
 */
export function getTreeLength(node:PartialModuleTreeModel, length:number = 0) {
    if(node.children.length <= 0 || node.type > 1)
        return length;

    length += node.children.length;

    for(let child of node.children) {
        length = getTreeLength(child, length);
    }

    return length;
}

/**
 * Converts a module object to a partial module tree object
 */
export function ConvertModuleToPartialModuleTree(module:IModule ) {
    let convertedModuleTree : PartialModuleTreeModel = new PartialModuleTreeModel();
    
    convertedModuleTree = {
        ...convertedModuleTree, // We are spreading in properties that are not equivalent to the module object
        id: module?.id ? module.id : '',
        originalDocumentId: module?.parentModule ? module.parentModule : '',
        type: convertToPartialModuleEnum(false, LessonTypeEnum.custom ), // b/c we are converting from a module the type should always be branch
        order: module.order as number,
        name: module.name,
        description: module.description,
        isViewable: module.isViewable !== undefined ? module.isViewable : true,
        isDisabled: module.isDisabled !== undefined ? module.isDisabled : false,
        isEditable: module.isEditable !== undefined ? module.isEditable : true,
        isOptional: module.isOptional !== undefined ? module.isOptional : false,  
        isNext:false,
        time: "",
        children: []                                                                     
    };

    return convertedModuleTree;
}

/**
 * Temp will remove
 * @param module 
 * @returns 
 */
export function ConvertLessonToPartialModuleTree(lesson:ILesson ) {
    let convertedModuleTree : PartialModuleTreeModel = new PartialModuleTreeModel();
    
    convertedModuleTree = {
        ...convertedModuleTree, // We are spreading in properties that are not equivalent to the module object
        id: lesson?.id ? lesson.id : '',
        originalDocumentId: lesson?.branchId ? lesson.branchId : '',
        type: convertToPartialModuleEnum(true,lesson.type), // b/c we are converting from a module the type should always be branch
        order: lesson.order as number,
        name: lesson.name,
        description: lesson.description,
        isViewable: lesson.isViewable,
        isDisabled: lesson.IsDisabled,
        isEditable: false,
        isOptional: lesson.IsOptional, 
        isNext:lesson.IsNext,
        time:lesson.duration,
        lessonIcon:lesson.lessonIcon,
        totalQuestions: lesson.totalQuestions,
        children: []                                                                     
    };

    return convertedModuleTree;
}
