import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { UserController } from "../../../controllers/UserController";
import { IUserBio } from "../../../models/module/UserProfile";
import { ApplicationState } from "../../../store";
import { CurrentUserState } from "../../../store/CurrentUser";
import "./MyProfile.css";
import MyProfileForm from "./MyProfileForm";
import MyProfileInfo from "./MyProfileInfo";

const userController = new UserController();

/**
 * Area for user to update their bio information
 */
const MyProfile = () => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    const [user, setUser] = useState<IUserBio|null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        async function onComponentMount() {
            await getCurrentUser();
        }

        onComponentMount()
    }, []);

    /**
     * Get the current user to display their profile information
     */
    const getCurrentUser = async () => {
        if(!userStore) {
            return;
        }

        const {userProfile} = userStore;

        try {
            setIsLoading(true);
            var res = await userController.GetUserBio(userProfile.id);
            setUser(res);
        } catch (error) {
            console.error(error);
            toast.error("Failed to get profile information");
        }
        setIsLoading(false);
    }

    const toggleIsEditing = () => {
        setIsEditing(!isEditing);
    }

    const turnOffEditing = async () => {
        setIsEditing(false);
        await getCurrentUser();
    }

    const updateImageLink = (imageUrl:string) => {
        if(!user) {
            return;
        }

        setUser({...user, imageLink:imageUrl});
    }

    return (
        <>
        {
            isLoading ? 
            (
                <div className="my-profile cbit-dialog">
                    <Spinner/>
                </div>

            ) 
            : 
            (
                <>
                {
                    !user ? 
                    (
                        <div>Profile not found</div>
                    ) 
                    : 
                    (
                        <>
                        {
                    isEditing ? 
                    (
                        <MyProfileForm 
                            user={user} 
                            updateImageLink={updateImageLink} 
                            toggleEditing={turnOffEditing}
                        />
                    ) 
                    : 
                    (
                        <MyProfileInfo
                            user={user}
                            toggleEditing={toggleIsEditing}
                        />
                    )
                }
                        </>
                    )
                }
               
                </>
            )
        }
        </>
    )
}

export default MyProfile;