import * as React from 'react';
import toast from 'react-hot-toast';
import { ImageManagementController } from '../../controllers/ImageManagementController';
import { ImageFileType } from '../../models/utility/ImageFileTypes';

type ImageUploaderProps = {
    isLoading:boolean
    updateParentImageUrl:(url:string) => void
    performAfterUpload:(imageId:string) => void
    removeImage:()=>void
    customUpload?:(file:File) => void;
    setIsLoading?:(isLoading:boolean) => void
    hideRemoveImage?:boolean
    imageId?:string
}

type ImageUploaderState = {
    imageUrl:string
}

class ImageUploader extends React.Component<ImageUploaderProps, ImageUploaderState> {
    private imageManagementController = new ImageManagementController();

    private defaultState : ImageUploaderState = {
        imageUrl: ''
    }
    
    /**
     *
     */
    constructor(props:any) {
        super(props);
        this.state = this.defaultState;
    }


    /**
     * 
     * @param file 
     * @returns 
     */
    private performValidations = (file:File) => {

        if(!file) {
            return false;
        }

        if(this.validateImageFile(file.type) && this.validateImageSize(file.size)) {
            return true;
        } else {
            return false;
        }

        //TODO add dimensions validation
    }


    /**
     * Validates the file type to ensure it is an image
     * @param fileType 
     */
    private validateImageFile = (fileType:string) =>  {
        
        const isValid = ImageFileType.includes(fileType);
        
        if(!isValid) {
            toast.error("Invalid image type");
            console.error("Invalid image type");
            return false;
        }

        return isValid;
    }
    
       /**
        * validates an image file size
        * @param fileSize 
        */
        private validateImageSize = (fileSize:number) => {
           const fileSizeLimit = 5000000;
    
           if(fileSize > fileSizeLimit){
               toast.error("Image must be 5 MB or less");
               console.error("Image must be 5 MB or less");
               return false;
               
           }
    
           return true;
       }
    
    /**
     * 
     * @param e 
     * @returns 
     */
    private handleFileInputChange = async (e:React.BaseSyntheticEvent) => {
        
        const fileList : FileList = e.target.files;
        
        if(fileList.length < 0) {
            // TODO throw some type of error
            return;
        }

        const file = fileList.item(0);        

        // because type script yells at us even though we are validating the file in performValidations 
        if(!file) {
            return; 
        }

        // perform image validations
        if(!this.performValidations(file)){
            toast.error("Image must be 5 MB or less");
            return;
        }

        const imagePreview = URL.createObjectURL(file);        
        
        this.setState(prevState => ({
            ...prevState,
            imageUrl: imagePreview
        }));        

        if(this.props.customUpload) {
            this.props.customUpload(file);
        } else {
            await this.UploadImage(file);      
        }

    }

    private UploadImage = async (file:File) => {
        toast.loading("Uploading Image...", {id: "upload-image-toast"});

        if(this.props.setIsLoading) {
            this.props.setIsLoading(true);
        }

        const res: {id:string, uploadUrl:string} | undefined = await this.imageManagementController.GetDirectUploadUrl();        
        try {
            if(res) {
                if(res.uploadUrl) {
                    const uploadResults = await this.imageManagementController.UploadImage(res.uploadUrl, file); 
    
                   if(uploadResults){
                        this.props.performAfterUpload(res.id);
                   }
                }
            }

            if(res) {
                return res.uploadUrl
            }
    
        } catch (error) {
            console.error(error);
            toast.error("Failed To Upload Image");

            if(this.props.setIsLoading) {
                this.props.setIsLoading(false);
            }
        }
    }


    private handleImageRemoval = () => {
        this.setState((prevState) => ({
            ...prevState,
            imageUrl: ''
        }));        
        
        this.props.removeImage();
    }

    public render() {
        return (
            <>
                {/* <label htmlFor='inputImageUpload' className="upload-btn-container btn-cbit-primary">
                    Upload Image        
                </label> */}
                <input 
                    disabled={this.props.isLoading}
                    id="inputImageUpload"
                    type='file' 
                    onChange={this.handleFileInputChange} 
                    className='input-txt-img-upload'
                    accept="image/png, image/jpeg"                                            
                />

                {!this.props.hideRemoveImage && (
                    <button type='button' onClick={this.handleImageRemoval} className="btn-cbit-link btn-remove-image">
                        REMOVE IMAGE
                    </button>
                )}
            </>
        )
    }
}

export default ImageUploader;