import React from 'react';
import LessonContentView from '../LessonContentView/LessonContentView';
import { AdminViewProps } from '../TopModuleView/TopModuleView';

type QuizViewProps = {
    
} & AdminViewProps;

const QuizView = (props:QuizViewProps) => {
    const {
        contentList, 
        updatedContentList, 
        updateIsLoading, 
        selectedModuleId, 
        topMostModuleId,
        contentType,
        navigateTo,
        isLoading,
        updateAccordion,
        removeAccordionItem,
        onDeleteConfirm,
        updateUnSavedContent,
        addToAccordion,
        parentModule,
        selectedModule,
        forceRerender,
        isVisible,
        isExpanded
    } = props;

    const failedFetchErrMsg = "Failed to retrieve Quiz items"

    const failedUpdateErrMsg = "Failed to update Quiz item"

    return (
        <LessonContentView
            contentList={contentList}
            updateIsLoading={updateIsLoading}
            updatedContentList={updatedContentList}
            selectedModuleId={selectedModuleId}
            contentType={contentType}
            navigateTo={navigateTo}
            isLoading={isLoading}
            addToAccordion={addToAccordion}
            updateAccordion={updateAccordion}
            onDeleteConfirm={onDeleteConfirm}
            removeAccordionItem={removeAccordionItem}
            updateUnSavedContent={updateUnSavedContent}
            topMostModuleId={topMostModuleId}
            failedFetchErrMsg={failedFetchErrMsg}
            failedUpdateErrMsg={failedUpdateErrMsg}
            parentModule={parentModule}
            selectedModule={selectedModule} 
            forceRerender={forceRerender}
            isVisible={isVisible}
            isExpanded={isExpanded}        
        />
    )
}

export default QuizView;