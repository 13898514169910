import React, { useCallback, useEffect, useState } from 'react';
import './BasicInlineForm.css';

type ModuleItemFormProps = {    
    text:string     
    placeHolderText:string;    
    inputCSSClass?:string;
    onSubmit(text:string):void;
    onBlur(isEditing:boolean):void;
}

// The module form state type 
type ModuleFormState = {
    text:string
    isLoading: boolean,
    errors: string[] // Make as an object
    textHeight:string    
}

/**
 * This component is for inline editing Module and Lesson names in the Admin dashboard
 */
const BasicInlineForm = (props:ModuleItemFormProps) =>  {
    const placeholderText = props.placeHolderText;
    const defaultState: ModuleFormState = {
        text: props.text,
        isLoading:false,
        errors: [],
        textHeight: ""
    }

    const [state, setState] = useState(defaultState);

    /**
     * Reference to the text area below
     */
    const textAreaRef = useCallback(node => {
        if(node !== null) {
            if(node.value)
                node.selectionStart = node.value.length

            setState(prevState => ({
                ...prevState,
                textHeight: node.scrollHeight + 'px'
            }))
        }
    }, []);

    /**
     * When input changes update the state
     * @param e 
     */
    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) : void => {
        
        const inputName : string = e.currentTarget.name;

        const inputValue : string = e.currentTarget.value;

        setState(prevState => ({
            ...prevState,
            [inputName]: inputValue
        }));
    }

    const onSubmit = async (e: React.KeyboardEvent<HTMLTextAreaElement>) : Promise<void> => {
        
        const text = state.text;

        if(text.trim().length <= 0)
            return;

        if(e.which === 13 && !e.shiftKey) {            
            e.preventDefault();

            props.onBlur(false);

            // despite what your IDE may say, await is needed to stop the flashing of the form or empty content when creating new content
            await props.onSubmit(state.text);
            
        }
    }
    
    /**
     * Handles when a user clicks of the inline form
     */
    const handleOnBlur = async () => {
        let text = state.text;
        
        text = text.trim();
        // If a user had not typed anything then we still want to submit but with the placeholder text
        if(text.length <= 0) {
            text = placeholderText;
        }
        // await props.onSubmit(text);

        props.onBlur(false);
    }

    const handleCustomInputCss = () => {
        if(!props.inputCSSClass) {
            return ""
        }

        return props.inputCSSClass;
    }
    
    return (
        <>
            <textarea
                rows={ state.text.length> 23? 3: 1}
                ref={textAreaRef}
                onBlur={handleOnBlur}
                className={`inline-basic-input ${handleCustomInputCss()}`}
                autoFocus
                // style={
                //     {
                //         width: (state.text.length + 'ch'),
                //         // minWidth: (placeholderText.length + 'ch'),
                //         minWidth: "auto",
                //         maxWidth: 230 + "px",
                //         // height: state.textHeight,
                //         // height: "auto",
                //         overflow: "scroll",
                //         // overflowY: "hidden",
                //     }
                // }
                name="text"
                id="text"
                placeholder={placeholderText}
                value={state.text}
                onKeyPress={onSubmit}
                onChange={onChange}
            />
        </>
    )    
}

export default BasicInlineForm;