import { AxiosError } from "axios";
import { SiteLocation } from "../models/Enums/SiteLocation";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { ContentViewLogService } from "../services/ContentViewLogService";

export class ContentViewLogController {

    constructor() {
        this.ContentService = new ContentViewLogService();
    }
    private ContentService: ContentViewLogService;

    public async GetCSV() {
        try{
            return this.ContentService.GetCSV();
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async GetCSVUrl(){
        return this.ContentService.GetCSVUrl();
    }

    public async UpdateViewCount(location:SiteLocation, name:string) {
        try{

            return this.ContentService.UpdateViewCount(location,name);
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get content view logs by a date range
     * @param from 
     * @param to 
     * @returns 
     */
    public async GetCsvByDate(from:string, to:string) {
        try {
            return this.ContentService.GetCsvByDate(from, to);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
}
