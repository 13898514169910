import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { match, useHistory, useLocation, useRouteMatch } from 'react-router';
import { AdminModuleController } from '../../../controllers/AdminModuleController';
import { LessonContentType } from '../../../models/contentTypes/LessonContentType';
import { ILesson, IsInstanceOfLesson, Lesson } from '../../../models/lesson/Lesson';
import { ILessonContent, IsInstanceOfLessonContent } from '../../../models/lesson/LessonContent';
import { LessonTypeEnum } from '../../../models/lesson/LessonType';
import { AdminModule, IAdminModule } from '../../../models/module/AdminModule';
import { IModule, IsInstanceOfModule, Module } from '../../../models/module/Module';
import { ModuleType } from '../../../models/module/ModuleType';
import { AdminTreeModule, IAdminTreeModule } from '../../../models/partialModels/AdminTreeModule';
import { DeepCopy } from '../../../models/utility/DeepCopy';
import { EditorContentEnum } from '../../../models/utility/EditorContentEnum';
import { StringHelper } from '../../../models/utility/StringHelper';
import { AdminTreeHelper } from '../../../models/utility/TreeHelper/AdminTreeHelper';
import { emptyUnSavedContent, IUnsavedContent, SaveContent } from '../../../models/utility/UnSavedCourse';
import { ModuleAction } from '../../../models/utility/UnSavedCourse/ModuleAction';
import { ApplicationState } from '../../../store';
import { CurrentUserState } from '../../../store/CurrentUser';
import { actionCreators, CurrentUserAccordionState } from '../../../store/UserModuleAccordionStore';
import CustomAlert from '../../Utilities/CustomAlert';
import AdminModuleAccordion, { cleanState } from '../AdminAccordion/AdminModuleAccordion';
import './ContentBuilder.css';
import ContentMenu from './ContentBuilderMenu/ContentMenu';
import ContentViewer from './ContentViewer/ContentViewer';
import { PublishedModuleController } from '../../../controllers/PublishedModuleController';
import { UserModuleController } from '../../../controllers/UserModuleController';
import { ErrorMessage } from '../../../models/Types/ErrorMessage';
import { ErrorHandler } from '../../../models/utility/ErrorHandler';

type BuilderState = {    
    currentModuleId:string
    unSavedContent:IUnsavedContent    
    contentList:IAdminModule[]    
    isSaveAlertOpen:boolean
    moduleToNavigateTo:IAdminTreeModule | null
    discardedChanges:boolean
    hasSavedContent:boolean
}

const adminModuleController = new AdminModuleController();
const publishedModulesController = new PublishedModuleController();
const userModuleController = new UserModuleController();


/**
 * Component for Building out a course
 * Note when adding new fields make sure to update the AminTreeHelper update and replace node functions 
 * if you want things to work in the accordion
 */
const ContentBuilder = () => {
    const location = useLocation();

    const history = useHistory()

    const routeMatch = useRouteMatch();

    const topModuleId = location.state?.id;

    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    const accordionStore = useSelector<ApplicationState, CurrentUserAccordionState | undefined>(state => state.currentUserModuleAccordion);

    const dispatch = useDispatch();

    const defaultState:BuilderState = {        
        currentModuleId:topModuleId,
        unSavedContent: emptyUnSavedContent,                
        contentList:[],
        isSaveAlertOpen:false,
        moduleToNavigateTo: null,
        discardedChanges:false,
        hasSavedContent:false
    }

    const [state, setState] = useState(defaultState);

    const [isLoading, setIsLoading] = useState(false);

    const [contentType, setContentType] = useState(EditorContentEnum.parentModule);

    const {SetSelectedModule, SetUserAccordion} = actionCreators;

    const [isPublishAlertOpen, setIsPublishAlert] = useState(false);

    useEffect(() => {
        async function onComponentMount() {   
            setContentType(EditorContentEnum.parentModule);

            getContentList(topModuleId)
        }
        onComponentMount();

        return () => {            
            
            dispatch(SetUserAccordion(DeepCopy.copy(cleanState)))
        }
    },[]);

    useEffect(() => {
        async function onCurrentModuleChange() {
            if(!accordionStore)
                return;                                
                        
            if(accordionStore.selectedModule.id.length <= 0 && contentType === EditorContentEnum.parentModule || accordionStore.selectedModule.id === state.currentModuleId) {
                return;                 
            }                        
            
            //@ts-ignore
            const typeOfContent = AdminTreeModule.convertTreeViewTypeToEditorContentType(accordionStore.selectedModule, topModuleId);            
            
            setContentType(typeOfContent);

            const currentModuleId = accordionStore.selectedModule.id.length > 0 ? 
                                        accordionStore.selectedModule.id : topModuleId;
            
            await getContentList(currentModuleId);            
        }
        onCurrentModuleChange();
    },[accordionStore?.selectedModule]);

    useEffect(() => {
        async function whenContentHasSaved () {
            
            if(state.hasSavedContent) {
                
                await getContentList(state.currentModuleId);
                

                setState(prevState => ({
                    ...prevState,
                    hasSavedContent:false
                }));
            }            
        }

        whenContentHasSaved();
    },[state.hasSavedContent])

      /**
     * Get content list items based on the current content type 
     * @returns 
     */
    const getContentList = async (currentModuleId:string) => {
        if(!accordionStore)
            return;
        
        updateIsLoading(true);

        const contentType = AdminTreeModule.getAdminTreeItemContentType(
                                                accordionStore.selectedModule as unknown as IAdminTreeModule, 
                                                accordionStore.parentModule as unknown as IAdminTreeModule);
        
        let adminModules:IAdminModule[] = [];        

        switch (contentType) {
            case EditorContentEnum.parentModule:
                adminModules = await adminModuleController.getChapters(topModuleId);                        
                break;
        
            case EditorContentEnum.module:
            case EditorContentEnum.lessonGroup:
                adminModules = await adminModuleController.GetModulesAndLessons(currentModuleId);
                break;

            default:
                adminModules = await adminModuleController.getLessonContent(currentModuleId, contentType === EditorContentEnum.lessonQuiz);
                break;
        }

        updateContentList(adminModules);

        setState(prevState => ({
            ...prevState,
            currentModuleId,
            contentList:adminModules,
            discardedChanges:false
        }));

        /**
         * SetTimeout because to stop flickering and shifting of content being loaded in.
         * */
        setTimeout(() => {
            updateIsLoading(false);
        }, 500)
    }

    const updateIsLoading = (loading:boolean) => {
        setIsLoading(loading);
    }

    const updateContentList = (adminModList:IAdminModule[]) => {
        setState(prevState => ({
            ...prevState,
            contentList:adminModList
        }));
    }
    
    const toggleSaveAlert = () => {
        const isSaveAlertOpen = !state.isSaveAlertOpen
        setState(prevState => ({
            ...prevState,
            isSaveAlertOpen
        }));
    }

    const togglePublishAlert = () => {
        setIsPublishAlert(!isPublishAlertOpen);
    }

    const saveAndLeaveConfirmation = async () => {
        
        if(!state.moduleToNavigateTo || !accordionStore)
            return;
        
        toggleSaveAlert();
        
        const moduleToNavigateTo:IAdminTreeModule = DeepCopy.copy(state.moduleToNavigateTo);

        setState(prevState => ({
            ...prevState,
            moduleToNavigateTo: null,        
        }));        
                        
        await handleSavingContent(null, undefined, moduleToNavigateTo);

        // dispatch(SetSelectedModule(moduleToNavigateTo as unknown as PartialModuleTreeModel));         
    }

    /**
     * Remove all pending changes from unsaved content and updates the accordion
     */
    const discardChanges = async () => {
        toggleSaveAlert();
        if(!accordionStore)
            return;
                
            
        if(state.unSavedContent.addedItems.length > 0) {
            const unSavedCopy:IUnsavedContent = DeepCopy.copy(state.unSavedContent);

            const contentList = removeItemsFromContentList(unSavedCopy.addedItems);

            unSavedCopy.deletedItems = [];

            unSavedCopy.deletedItems = unSavedCopy.addedItems;

            unSavedCopy.addedItems = [];
            
            handleSavingContent(null,unSavedCopy);                                

            setState(prevState => ({
                ...prevState,
                contentList
            }));                        

            return;
        }

        getContentList(state.currentModuleId);

        setState(prevState => ({
            ...prevState,
            unSavedContent:emptyUnSavedContent
        }));

        dispatchToAccordionStore(accordionStore.selectedModule, cleanState.parentModule);
    }

    /**
     * Removes content items from the given content list
     * TODO: Find a way to rerender contentBuilderView
     */
    const removeItemsFromContentList = (itemsToFilterOut:IAdminModule[]) => {
        let contentList:IAdminModule[] = DeepCopy.copy(state.contentList);
        
        contentList = contentList.filter(adminModule => itemsToFilterOut.some(filterItem => filterItem.key !== adminModule.key));

        return contentList;
    }

    /**
     * Navigates to the passed in module
     * Also checks if there is unsaved content and toggles the save alert
     * @param module 
     */
    const navigateTo = (module:AdminTreeModule | IModule | ILesson) => {
        
        const {SetSelectedModule} = actionCreators;

        if(IsInstanceOfLesson(module as ILesson))
            module = AdminTreeModule.convertLessonToAdminTreeModule(module as ILesson);

        if(IsInstanceOfModule(module as IModule) || module.type === ModuleType.subLesson)
            module = AdminTreeModule.convertModuleToAdminTreeModule(module as IModule);
        
        const moduleCopy = DeepCopy.copy(module);

        if(state.unSavedContent.hasChanges) {
            setState(prevState => ({
                ...prevState,
                moduleToNavigateTo:moduleCopy
            }));

            toggleSaveAlert();

            return;
        }
        
        dispatch(SetSelectedModule(moduleCopy));
    }

    /**
     * Add a list of adminModules to another list of adminModules
     * and update items that are already in the list
     */
    const combineAdminModules = (prevAdminModules:IAdminModule[], newAdminModules:IAdminModule[]) => {
        for (let newModule of newAdminModules) {
            const foundIndex = prevAdminModules.findIndex(mod => mod.content.id === newModule.content.id);

            if(foundIndex !== -1) {
                prevAdminModules[foundIndex] = newModule;
                continue;
            }

            prevAdminModules.push(newModule);
        }

        return prevAdminModules;
    }

    /**
     * Updates the unSavedContent object and flag for changes
     */
    const updateUnSavedContent = (adminModule:IAdminModule, action:ModuleAction, adminModules?:IAdminModule[]) => {
        const unSavedContentCopy:IUnsavedContent = DeepCopy.copy(state.unSavedContent);

        unSavedContentCopy.hasChanges = true;

        switch(action) {
            case ModuleAction.ADD: {
                const foundIndex = unSavedContentCopy.addedItems.findIndex(mod => mod.key === adminModule.key)

                if(foundIndex !== -1){
                    unSavedContentCopy.addedItems[foundIndex] = adminModule
                } else {

                    unSavedContentCopy.addedItems.push(adminModule);
                }
            }
                break;
            case ModuleAction.UPDATE:{
                const foundIndex = unSavedContentCopy.editedItems.findIndex(mod => mod.content.id === adminModule.content.id)

                // If we want to add multiple content items to be updated in one go
                if(adminModules) {
                    unSavedContentCopy.editedItems = combineAdminModules(unSavedContentCopy.editedItems, adminModules);
                    break;
                }

                if(foundIndex !== -1){
                    unSavedContentCopy.editedItems[foundIndex] = adminModule
                } else {

                    unSavedContentCopy.editedItems.push(adminModule);
                }                

                break;
            }
            case ModuleAction.DELETE:
                unSavedContentCopy.deletedItems.push(adminModule);
                break;
            case ModuleAction.ORDERCHANGE:
                unSavedContentCopy.hasOrderChanged = true
                break;

            default:
                break;
        }

        setState(prevState => ({
            ...prevState,
            unSavedContent:unSavedContentCopy
        }));
    }

    /**
     * Adds an empty Module or Lesson (only Lesson Groups or quizzes)
     * to the course items list
     * @param isLesson 
     */
    const addEmptyCourseItem = (isLesson = false, lessonType: LessonTypeEnum | null = null) => {   
        if(!accordionStore)             
            return;

        let newContentList:IAdminModule[] = DeepCopy.copy(state.contentList);

        newContentList = removeNewModuleStatus(newContentList);         

        let content = null;

        const currentID = contentType === EditorContentEnum.parentModule ? accordionStore.parentModule.id : accordionStore.selectedModule.id;             
        
        if(isLesson) {
            content = new Lesson();
            content.branchId = currentID;
            if(lessonType) {
                content.type = lessonType;
                content.lessonIcon = Lesson.GetLessonIcon(lessonType);
            }
        } else {
            content = new Module();                                                           
            content.parentModule = currentID;
        }

        if(lessonType === LessonTypeEnum.quiz) {
            content.name = "Quiz";
        }
        
        content.order = state.contentList.length;

        let contentToAdd:IAdminModule = new AdminModule(content);        

        contentToAdd.isNewModule = true;
        
        newContentList.push(contentToAdd);

        setState(prevState => ({
            ...prevState,
            contentList: newContentList
        }));
    }

    /**
     * Remove a list of modules "new" status
     */
    const removeNewModuleStatus = (adminModules:IAdminModule[]) => {
        for(let i = 0; i < adminModules.length; i++) {
            const adminModule = adminModules[i]

            if(adminModule.isNewModule) {
                adminModule.isNewModule = false;
            }

            adminModules[i] = adminModule;
        }  

        return adminModules;
    }

    /**
     * Get a deep copy of the parentModule, selectedModule
     * and the current ParentId from the accordion
     */
    const accordionCopyItems:[IAdminTreeModule, IAdminTreeModule, string] | undefined = useMemo( () => {
        if(!accordionStore)
            return;

        const selectedModuleCopy:IAdminTreeModule = DeepCopy.copy(accordionStore.selectedModule);
            
        const parentModuleCopy:IAdminTreeModule = DeepCopy.copy(accordionStore.parentModule);
        
        const currentParentId:string = contentType === EditorContentEnum.parentModule ? parentModuleCopy.id : selectedModuleCopy.id;

        return [selectedModuleCopy, parentModuleCopy, currentParentId]
    },[dispatch, contentType, accordionStore?.parentModule, accordionStore?.selectedModule]);

    /**
     * 
     */
    const dispatchToAccordionStore = useCallback((selectedModule:any, parentModule:any) => {
        //Replacing whats in redux with the updated copies
        dispatch( SetUserAccordion(
            {
                selectedModule: selectedModule,
                parentModule: parentModule,
                selectedContent: [],
                nextLessonId: '',
                isUpdating: false
            }));
    },[]);
    
    const addToAccordion = useCallback(
        (accordionModule:IAdminTreeModule) =>{
            if(!accordionStore || !accordionCopyItems)
                return;
            
            const [selectedModule, parentModule, currentParentId] = accordionCopyItems;                        
            
            AdminTreeHelper.addNodeToTree(selectedModule, currentParentId, accordionModule);
                   
            AdminTreeHelper.addNodeToTree(parentModule, currentParentId, accordionModule);

            dispatchToAccordionStore(selectedModule, parentModule)
            
    }, [dispatch, contentType, accordionStore?.parentModule, accordionStore?.selectedModule]);
    
    /**
     * Update the accordion with an updated module
     * @param accordionModule 
     * @returns 
     */
    const updateAccordion = useCallback(
        (accordionModule:IAdminTreeModule) => {
            if(!accordionStore || !accordionCopyItems)
            return;
        
        const [selectedModule, parentModule] = accordionCopyItems;
                
        //We need to update both the selected module and parent module inside the copies
        AdminTreeHelper.updateTree(selectedModule, accordionModule.id, accordionModule);
        
        AdminTreeHelper.updateTree(parentModule, accordionModule.id, accordionModule);

        dispatchToAccordionStore(selectedModule, parentModule)
    },[dispatch, contentType, accordionStore?.parentModule, accordionStore?.selectedModule]);
    
    
    /**
     * Remove a accordion item from the accordion tree
     */
    const removeAccordionItem = useCallback((contentToRemoveId:string) => {            
            if(!accordionStore || !accordionCopyItems)
                return;
        
        const [selectedModule, parentModule, currentParentId] = accordionCopyItems;                             

        AdminTreeHelper.removeNodeFromTree(selectedModule, currentParentId, contentToRemoveId);            
            
        AdminTreeHelper.removeNodeFromTree(parentModule, currentParentId, contentToRemoveId);
        
        dispatchToAccordionStore(selectedModule, parentModule);        
        
    },[dispatch, contentType, accordionStore?.parentModule, accordionStore?.selectedModule]);
    
    /**
     * Saves anything in the unsaved content obj to the DB
     * Note: The mouse event is for when this function is used on a button it get auto passed in
     */
    const handleSavingContent = async (mouseEvent?:any, unSavedContent?:IUnsavedContent, navigationModule?:IAdminTreeModule) => {        
        if(!accordionStore) {
            return;
        }

        if(!unSavedContent){
            unSavedContent = state.unSavedContent;
        }

        const hasChanges = unSavedContent.hasChanges;

        const topMostModuleId = accordionStore.parentModule.id;

        
        try {            
            setIsLoading(true);

            //Has to be in order
            await SaveContent.performSave(unSavedContent.addedItems, hasChanges, ModuleAction.ADD, topMostModuleId);
            await SaveContent.performSave(unSavedContent.editedItems, hasChanges, ModuleAction.UPDATE, topMostModuleId);
            await SaveContent.performSave(unSavedContent.deletedItems, hasChanges, ModuleAction.DELETE, topMostModuleId);      
            
            if(unSavedContent.hasOrderChanged) {     
                const contentListCopy = updateQuestionNumber();           
                await SaveContent.performSave(contentListCopy, hasChanges, ModuleAction.ORDERCHANGE, topMostModuleId); 
            }

            if(!accordionStore)
                return;

            const selectedModule = DeepCopy.copy(accordionStore.selectedModule);
            
            const parentModule = DeepCopy.copy(accordionStore.parentModule);
            
            const currentModule = StringHelper.IsNullOrWhiteSpace(selectedModule.id) ? parentModule : selectedModule;

            let navigateTo:IAdminTreeModule = navigationModule ? navigationModule : currentModule;

            dispatchToAccordionStore(navigateTo, cleanState.parentModule);
            
            setState(prevState => ({
                ...prevState,                
                unSavedContent:emptyUnSavedContent,
                hasSavedContent:true,
                currentModuleId:navigateTo.id
            }));    
            
            setIsLoading(false);

        } catch (error) {
            console.error(error);
            toast.error("Failed to save");
        }         
    }

    /**
     * Publishes the entire course and update all users course content
     */
    const handleCoursePublish = async () => {
        const toastLoadingId = "publishedLoadingId";
        setIsLoading(true);
        toast.loading("Publishing course", { id: toastLoadingId});
        try {
            await publishedModulesController.PublishCourse();
            // await userModuleController.UpdateAllUsersCourse(topModuleId);
            toast.dismiss(toastLoadingId);
            setIsLoading(false);        
            togglePublishAlert();
            toast.success("Course successfully published.")    
            return;
        } catch (error) {
            toast.dismiss(toastLoadingId);   
            console.error(error);         
            ErrorHandler.displayErrorsInToast(error as string[])
            setIsLoading(false);            
        }
        toast.dismiss(toastLoadingId);
        togglePublishAlert();

    }

    /**
     * Updates question number on order change
     */
    const updateQuestionNumber = () => {
        const contentListCopy = DeepCopy.copy(state.contentList);
        if(contentType === EditorContentEnum.lessonQuiz || contentType === EditorContentEnum.lesson) {
            let currentQuestionNumber = 1;
            for(let {content} of contentListCopy) {
                if(IsInstanceOfLessonContent(content as ILessonContent)){
                        if((content as ILessonContent).type === LessonContentType.multipleChoice || (content as ILessonContent).type === LessonContentType.trueOrFalse){
                            const lessonContent = content as ILessonContent;

                            if(lessonContent.multipleChoiceProperties) {
                                lessonContent.multipleChoiceProperties.questionNumber = `${currentQuestionNumber}`;
                                currentQuestionNumber += 1;   
                            }                            
                            
                        }
                }
            }
        }

        return contentListCopy
    }

    return (
        <>
            <div className="admin-dashboard">            
                <div className='admin-dashboard-body'>                    
                    {userStore && accordionStore && (
                        <>
                            <div className="admin-navigation-panel ">                            
                                <AdminModuleAccordion 
                                    discardChanges={false}
                                    setNavigateTo={navigateTo}
                                    match={routeMatch as match}
                                    history={history}
                                    location={location}
                                    userProfile={userStore.userProfile}
                                    isAdmin={userStore.isAdmin}
                                    isLoggedIn={userStore.isLoggedIn}
                                    id={topModuleId}
                                    updateIsLoading={updateIsLoading}
                                    isLoading={isLoading} isSuperAdmin={userStore.isSuperAdmin} notificationCount={0}/>                                                                            
                            </div>
                            <ContentViewer
                                contentList={state.contentList}
                                contentType={contentType}
                                isLoading={isLoading}
                                updateContentList={updateContentList}
                                updateIsLoading={updateIsLoading}
                                navigateTo={navigateTo}
                                updateAccordion={updateAccordion}
                                removeAccordionItem={removeAccordionItem}
                                updateUnSavedContent={updateUnSavedContent}
                                hasUnSavedContent={state.unSavedContent.hasChanges}
                                addToAccordion={addToAccordion}
                                onSaveContent={handleSavingContent}
                                onPublishContent={togglePublishAlert}
                                currentModuleId={state.currentModuleId}
                                topMostModuleId={topModuleId}
                            />
                            <div className="admin-content-menu-panel">
                                <ContentMenu                     
                                    contentType={contentType} 
                                    contentList={state.contentList} 
                                    isLoading={isLoading} 
                                    currentModuleId={state.currentModuleId} 
                                    updateContentList={updateContentList}
                                    addToAccordion={addToAccordion}
                                    updateUnSavedContent={updateUnSavedContent}
                                    addEmptyCourseItem={addEmptyCourseItem}                     
                                />
                            </div>
                        </>
                    )}     
                </div>
            </div>
            {/* Unsaved Changes confirmation */}
            <CustomAlert 
                header={'YOU ARE ABOUT TO LEAVE THIS PAGE.'} 
                text={'Do you want to save your lesson content before your leave?'} 
                primaryBtnText={'SAVE AND LEAVE'} 
                secondaryBtnText={'DISCARD'} 
                tertiaryBtnText={'CANCEL'} 
                isOpen={state.isSaveAlertOpen} 
                primaryBtnSubmit={ saveAndLeaveConfirmation}
                
                secondaryBtnSubmit={() => discardChanges()} 
                tertiaryBtnSubmit={() => toggleSaveAlert()} 
                toggleAlert={() => toggleSaveAlert()} 
            />
            {/* Publish course confirmation */}
            <CustomAlert 
                header={'ARE YOU SURE THAT YOU WANT TO PUBLISH?'}
                text={'By publishing the course you will update all users course content.'}
                primaryBtnText={'PUBLISH'}
                secondaryBtnText={'CANCEL'} 
                isOpen={isPublishAlertOpen}
                primaryBtnSubmit={handleCoursePublish}
                secondaryBtnSubmit={() => togglePublishAlert()}
                toggleAlert={() => togglePublishAlert()}
            />
        </>
    )
}

export default ContentBuilder;