import {useState} from 'react';

/**
 * Custom hook for handling and setting form data
 * @param defaultState 
 * @returns 
 */
const useFields = (defaultState:any) => {
    // Creating a state for form data
    const [formData, setFormData] = useState(defaultState);

    // Handles the on change event for input form data
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;

        const value = e.target.value;

        setFormData((fData:any) => ({
            ...fData,
            [name]: value
        }));
    };

    // resets the form data to a default state
    const resetFormData = () => {
        setFormData(defaultState);
    };

    return [formData, handleChange, resetFormData, setFormData]
}

export default useFields