import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router';
import { UserController } from '../../controllers/UserController';
import { actionCreators } from '../../store/CurrentUser';

const userController = new UserController();
const Logout = () => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(true);

    const history = useHistory();

    useEffect(() => {
        async function onComponentMount() {
            await logUserOut();
        }

        onComponentMount().then(() => {
            setIsLoading(false);
        });
    },[]);

    const logUserOut = async() => {
     
        const {removeUser} = actionCreators;        
        
        await userController.Logout();
        
        //The set timeout is to give the browser time to update the expired cookie
        setTimeout(() => {
            dispatch(removeUser());
            setIsLoading(false);
             history.push("/login");
        }, 1000);
    }

    return (
        <>
           
        </>
    )
}

export default Logout;