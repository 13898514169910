import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { LessonContentController } from "../../../../../controllers/LessonContentController";
import { LessonController } from "../../../../../controllers/LessonController";
import { ILesson, Lesson, LessonIconEnum } from "../../../../../models/lesson/Lesson";
import { ILessonContent } from "../../../../../models/lesson/LessonContent";
import { AdminModule, IAdminModule } from "../../../../../models/module/AdminModule";
import { AdminTreeModule, IAdminTreeModule } from "../../../../../models/partialModels/AdminTreeModule";
import { EditorContentEnum } from "../../../../../models/utility/EditorContentEnum";
import { StringHelper } from "../../../../../models/utility/StringHelper";
import { ModuleAction } from "../../../../../models/utility/UnSavedCourse/ModuleAction";
import { ApplicationState } from "../../../../../store";
import { CurrentUserAccordionState } from "../../../../../store/UserModuleAccordionStore";
import InlineForm from "../../../AdminForms/InlineForms/InlineForm";
import ComboIconDropdown from "./ComboIconDropdown";

type EditorViewToolsProps = {
    contentType:EditorContentEnum;
    updateUnsavedContent:(adminModule:IAdminModule, action:ModuleAction) => void;
    updateAccordion:(accordionModule: IAdminTreeModule) => void;
}

type EditorViewToolsState = {
    isShowingDurationEdit:boolean;
    comboLessonIcon:LessonIconEnum;
    originalDuration:string;
    duration:string;
    totalQuestions:number;
    isQuizComboLesson:boolean;
    comboLesson:ILesson;
    lessonContentArr:ILessonContent[];
}

const lessonController = new LessonController();
const lessonContentController = new LessonContentController();

const durationRegex:RegExp = new RegExp("^[0-9][0-9]:[0-9][0-9]$")

const ComboLessonEditTools = (props:EditorViewToolsProps) => {
    const {
            contentType,
            updateUnsavedContent, 
            updateAccordion
        } = props;
    
    const accordionStore = useSelector<ApplicationState, CurrentUserAccordionState | undefined>(state => state.currentUserModuleAccordion);

    const defaultState:EditorViewToolsState = {
        isShowingDurationEdit: false,
        comboLessonIcon: LessonIconEnum.Unknown,
        originalDuration:"00:00",
        duration:"00:00",
        totalQuestions: 0,
        isQuizComboLesson:false,
        comboLesson:new Lesson(),
        lessonContentArr: [],
    }
    
    const [state, setState] = useState(defaultState);
    
    useEffect(() => {
        async function onComponentMount() {
            if(!accordionStore) {
                return;
            }

            const {selectedModule} = accordionStore;
            
            let totalQuestions = selectedModule.totalQuestions;

            if(selectedModule.lessonIcon === LessonIconEnum.QuizIcon) {
                const lessonContentArr = await lessonContentController.GetAllLessonContentByLessonId(selectedModule.id);
                totalQuestions = AdminModule.GetModuleQuestionTotal(lessonContentArr); 
                 
            }
            setState(prevState => ({
                ...prevState,
                //@ts-ignore
                originalDuration:selectedModule.duration,
                //@ts-ignore
                duration: selectedModule.duration,
                comboLessonIcon: selectedModule.lessonIcon,
                totalQuestions: totalQuestions                
            }));
        }
        onComponentMount();
    }, [accordionStore?.isUpdating]);

    /**
     * Handles combo lesson duration when editing
     * @param duration 
     */
    const handleDurationUpdate = (duration:string) => {
        if(!duration)
            return;
    
        duration = checkDuration(duration);

        setState(prevState => ({
            ...prevState,
            duration  
          }));      
    
        updateComboLessonDuration(duration);
    }

    /**
     * Checks to see if the duration the user inputted is valid or not
     * @param duration 
     * @returns 
     */
    const checkDuration = (duration:string) => {
        let defaultDurationText = "00:00";
        
        //in case undefined gets somehow stored in the string
        if(StringHelper.IsNullOrWhiteSpace(duration) || duration === `${undefined}`) {
            return defaultDurationText;
        }

        //TODO check in safari
        if(!durationRegex.test(duration)) {
            return defaultDurationText
        }

        return duration ;
    }

    const handleOnIconChange = (lessonIconStr:string | null) => {    
        if(StringHelper.IsNullOrWhiteSpace(lessonIconStr)) {
            return;
        }

        lessonIconStr = lessonIconStr as string;

        //we have to convert the value from string to number
        const parsedVal = parseInt(lessonIconStr);

        if(isNaN(parsedVal)) {
            return;
        }

        if(parsedVal < 0 || parsedVal > 4) {
            return;
        }

        setState(prevState => ({
            ...prevState,
            comboLessonIcon:parsedVal
        }));  
        

        updateComboLessonIcon(parsedVal);
    }

    /**
     * Because we have to update a combo lesson duration here
     * Also updates the accordion and unsaved content
     * @param duration 
     * @returns 
     */
    const updateComboLessonDuration = async (duration:string) => {
        if(!accordionStore)
            return;
        //We need to get the full combo lesson we are currently in
        const comboLesson:ILesson = await lessonController.GetLesson(accordionStore.selectedModule.id);        

        comboLesson.duration = duration;
        comboLesson.lessonIcon = state.comboLessonIcon;

        
        updateSaveContentAndAccordion(comboLesson);
    }

    /**
     * updates the current lesson content we are in icon
     * @param lessonIcon 
     */
    const updateComboLessonIcon = async (lessonIcon:LessonIconEnum) => {
        if(!accordionStore)
            return;

        const comboLesson:ILesson = await lessonController.GetLesson(accordionStore.selectedModule.id);        

        comboLesson.lessonIcon = lessonIcon;
        
        comboLesson.duration = state.duration;

        let totalQuizQuestions = 0;

        if(lessonIcon === LessonIconEnum.QuizIcon) {
            const lessonContentArr = await lessonContentController.GetAllLessonContentByLessonId(comboLesson.id);
            totalQuizQuestions = AdminModule.GetModuleQuestionTotal(lessonContentArr);     
            comboLesson.totalQuestions = totalQuizQuestions;             
        }

        setState(prevState => ({
            ...prevState,
            totalQuizQuestions,
            duration:comboLesson.duration
        }));

        updateSaveContentAndAccordion(comboLesson, totalQuizQuestions);
    }

    const updateSaveContentAndAccordion = (comboLesson:ILesson, totalQuizQuestions?:number) => {
        const adminModule = new AdminModule(comboLesson);
        
        const adminTreeModule = AdminTreeModule.convertAdminModuleToAdminTreeModule(adminModule);
        updateAccordion(adminTreeModule)
        updateUnsavedContent(adminModule, ModuleAction.UPDATE);    
    }

    /**
     * Toggles between showing the combo lesson duration text 
     * and the module inline form
     */
    const toggleComboDurationEdit = () => {        
        const isShowingEdit = !state.isShowingDurationEdit;

        setState(prevState => ({
            ...prevState,
            isShowingDurationEdit:isShowingEdit
        }));
    }

    const renderComboDuration = () => {
        return (
            <>
            {
                state.isShowingDurationEdit ? 
                (
                    <InlineForm 
                        field={state.duration ?? '00:00'} 
                        placeHolderText={''} 
                        onSubmit={handleDurationUpdate} 
                        onBlur={toggleComboDurationEdit} 
                    />
                ) 
                : 
                (
                    <span className='combo-duration-time'>
                        {state.duration.length > 0 ? state.duration : '00:00'}
                    </span>

                )
            }
            </>
        )
    }

    const getQuizCssClass = () => {
        return state.comboLessonIcon === LessonIconEnum.QuizIcon ? "combo-duration-quiz" : "";
    }
 
    return (
        <>
            <div>
                <ComboIconDropdown 
                    lessonIcon={state.comboLessonIcon}
                    handleIconChange={handleOnIconChange}
                />
            </div>
            <div className={`combo-duration ${getQuizCssClass()}`} onClick={toggleComboDurationEdit}>
                Duration: {" "}
                {
                    state.comboLessonIcon === LessonIconEnum.QuizIcon ? 
                    (
                        <span>
                            {`${state.totalQuestions}/${state.totalQuestions}`}
                        </span>                
                    ) 
                    : 
                    (
                        renderComboDuration()
                    )
                }                                        
            </div>
        </>
    )
}

export default ComboLessonEditTools;