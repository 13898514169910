import React, {useEffect, useRef} from "react";
import {Link, NavLink, useHistory} from "react-router-dom";
import navIconSrc from "../../../../../resources/icons/icon-nav-menu.png";
import "../SocialConnectionLayout.css";
import {CurrentUserState} from "../../../../../store/CurrentUser";
import {ApplicationState} from "../../../../../store";
import {useSelector} from "react-redux";

type NavSidebarProps = {
    toggle: () => void;
    setIsNavOpen: (isOpen: boolean) => void;
    isNavOpen: boolean;
    title: string;
    links?: {
        name: string;
        path: string;
    }[];
    adminLinks?: {
        name: string;
        path: string;
    }[];
    children?: React.ReactNode | null;
}

/**
 * Cont education sidebar navigation menu
 * @param param0 
 * @returns 
 */
const NavSidebar = ({toggle, setIsNavOpen, isNavOpen, title, links, adminLinks,children}: NavSidebarProps) => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>((state) => state.currentUser);
    const sidebarRef = useRef(null);
    let windowWidth = window.innerWidth;

    const handleLinkClick = () => {

        if (windowWidth < 1023) {
        setIsNavOpen(false);
        }

        return;
    }

    useEffect(() => {
        const handleClickOutside = (event: any) => {

            if (windowWidth > 1023) {
                return;
            }

            if (windowWidth > 1023 && event.target.className === 'sidebar-link-text') {
                return;
            }

            // @ts-ignore
            if (sidebarRef.current && !sidebarRef.current!.contains(event.target)) {
                setIsNavOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        }
    }, [sidebarRef, setIsNavOpen, windowWidth]);

    return (
        <>
            {/* Menu tab */}
            <button className='nav-sidebar-menu-tab' onClick={toggle}>
                <div className='buttonIcon'>
                    <i className="bi bi-list" />
                </div>
            </button>
            {/* Sidebar */}
            <div ref={sidebarRef} className='nav-sidebar'>
                <div className="title-container">
                    <div className="dashboard-link-container">
                        {/* Back to Dashboard & icon */}
                        <Link to="/home" className='back-to-dashboard'>
                            Back To Dashboard
                        </Link>
                        <button onClick={toggle} className='nav-sidebar-collapse'>
                            <img src={navIconSrc} alt="Nav Collapse Icon"/>
                        </button>
                    </div>
                    {/* Title */}
                    <h2 className='nav-sidebar-title'>
                        {title}
                    </h2>
                </div>
                <div className="links-container">
                    {links != null &&(
                        <>
                            {/* Link item */}
                            {links && links.map((link, index) => (
                                <NavLink key={index} activeClassName='active-nav-item' className='sidebar-link' exact={true} to={link.path} onClick={handleLinkClick}>
                                    <div className="sidebar-link-text">
                                        {link.name}
                                    </div>
                                </NavLink>
                            ))}
                        </>
                    )}

                    {/* Admin link item */}
                    {userStore && userStore.isAdmin || userStore && userStore.isSuperAdmin ? (
                        adminLinks && adminLinks.map((link, index) => (
                            <NavLink key={index} activeClassName='active-nav-item' className='sidebar-link' to={link.path}  onClick={handleLinkClick}>
                                <div className="sidebar-link-text">
                                    {link.name}
                                </div>
                            </NavLink>
                        ))) : null}

                    {children != null && (
                        <>
                            {children}
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default NavSidebar;