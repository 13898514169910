export enum ConsultationType{
    Unknown = 0,
    OneOnOne = 1,
    SmallGroup = 2,
    Seminar = 3,
}

/**
 * Object for displaying user friendly to text for consultation type
 */
export const ConsultationUIType = {
    [ConsultationType.Unknown]: "Unknown",
    [ConsultationType.OneOnOne]: "Individual",
    [ConsultationType.SmallGroup]: "Group",
    [ConsultationType.Seminar]: "Seminar",
}