import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/vimeo';
import { v4 as uuidV4 } from 'uuid';
import { } from '../../../';
import { AdditionalContentController } from '../../../controllers/AdditionalContentController';
import { ArtifactController } from '../../../controllers/ArtifactController';
import { AdditionalContent, IAdditionalContent } from '../../../models/AdditionalContent/AdditionalContent';
import { AdditionalContentEnum } from '../../../models/Enums/AdditionalContentEnum';
import { DeepCopy } from '../../../models/utility/DeepCopy';
import editIconSrc from '../../../resources/icons/icon-edit.png';
import pdfIconSrc from '../../../resources/icons/icon-reading.png';
import trashIconSrc from '../../../resources/icons/icon-trash.png';
import videoIconSrc from '../../../resources/icons/icon-video.png';
import { ReactComponent as DragGrip } from '../../../resources/images/icon-drag-drop.svg';
import CustomAlert from '../../Utilities/CustomAlert';
import CustomModal from '../../Utilities/CustomModal';
import AdditionalContentForm from '../AdditionalContentForm/AdditionalContentForm';

type AdminTherapistContentItemProps = {
    additionalContent:IAdditionalContent
    isAdmin:boolean
    subCategory:string
    index:number    
    updateAdditionalContentItems:(msg:string) => void
}

const additionalContentController = new AdditionalContentController();

const artifactController = new ArtifactController();

const AdminTherapistSupportContentItem = (props:AdminTherapistContentItemProps) => {
    const {additionalContent} = props;

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({id: additionalContent.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

    const [isContentModalOpen, setIsContentModalOpen] = useState(false);

    const [isAlertOpen, setIsAlertOpen] = useState(false);    

    const [pdfUrl, setPdfUrl] = useState("");

    const toggleToolTip = () => setIsTooltipOpen(!isTooltipOpen);

    const toggleVideoModal = () => setIsVideoModalOpen(!isVideoModalOpen);

    const toggleContentModal = () => setIsContentModalOpen(!isContentModalOpen);    

    const toggleAlert = () => setIsAlertOpen(!isAlertOpen);

    const htmlId = uuidV4();

    useEffect(() => {
        function onComponentMount() {
            getFile();
        }
        onComponentMount();
    },[]);

    /**
     * Function for handling what to do when the content item itself have been clicked
     */
    const getFile = async () => {

        const urlToFile = await artifactController.GetPdfDownloadUrl(additionalContent.artifactId);
                
        setPdfUrl(urlToFile);
    }

    /**
     * Get the content description from the content item
     * Returns a default string if no description is available
     * @returns 
     */
    const getContentItemDescription = () => {
        if(!additionalContent.description || additionalContent.description.length < 1) {
            return "No Description Available For This Video";
        }

        return additionalContent.description;
    }    

    /**
     * Function for running after a content item has been updated
     * @param additionalContent 
     */
    const updateAdditionalContent = (additionalContent:AdditionalContent) => {
        
        toggleContentModal();
        props.updateAdditionalContentItems("Successfully Updated Content Item");
    }

    /**
     * TODO change delete to delete from category
     */
    const deleteContentItem = async () => {        
        const additionalContent:IAdditionalContent = DeepCopy.copy(props.additionalContent);

        additionalContent.tagList = additionalContent.tagList.filter(tag => tag._id !== props.subCategory);
        
        if(additionalContent.tagList.length < 1) {
            if(additionalContent.type === AdditionalContentEnum.pdf && additionalContent.artifactId.length > 0) {
                await artifactController.DeleteArtifact(additionalContent.artifactId)
            }
            
            await additionalContentController.DeleteAdditionalContent(additionalContent.id);
        } else {

            await additionalContentController.updateAdditionalContentItem(additionalContent);
        }
        
        if(isVideoModalOpen) {
            toggleVideoModal();        
        }
        toggleAlert();

        props.updateAdditionalContentItems("Successfully Removed Content Item");

    }

    /**
     * Toggles the delete alert modal
     * Prevent default and stopPropagation is need to stop the main click action from happening
     * @param e 
     */
    const handleDeleteClick = (e:React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();        
        toggleAlert();
    }


    /**
     * Toggles the edit modal of the additional content form
     * @param e 
     */
    const handleEditClick = (e:React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();        
        toggleContentModal();
    }

    
    const renderInnerContentItem = () => {
        return (
            <>
            <div className="therapist-item-details-container">
                <div className='therapist-content-title'>{additionalContent.name}</div>                
                {/* <p className='therapist-content-description'>{additionalContent.description}</p> */}
            </div>
            {
                props.isAdmin && (                            
                    <div className="therapist-panel-btn-container">
                            <button 
                                className="btn-cbit-icon"
                                onClick={handleEditClick}
                            >
                                <img src={editIconSrc} alt="Edit Icon" />
                            </button>
                            <button 
                                className="btn-cbit-icon"
                                onClick={handleDeleteClick}
                            >
                                <img src={trashIconSrc} alt="Trash Icon" />
                            </button>                            
                    </div>                            
                )                
            }
        </>
        )
    }

    return (
            <>

                
                    <div ref={setNodeRef} style={style}>
                        {
                            additionalContent.type === AdditionalContentEnum.video ? 
                            (
                                <div onClick={() =>toggleVideoModal()}  className="admin therapist-content-item">
                                    <div 
                                        className="drag-handle"  
                                        {...attributes} {...listeners}
                                    >
                                        <DragGrip />         
                                    </div>
                                    <div className="therapist-content-item-info">
                                        <div className="therapist-icon-container">
                                            <img src={videoIconSrc} alt="Video Icon"/>                            
                                        </div>
                                        <div className="content-item-duration">{additionalContent.videoLength}</div>
                                    </div>
                                    {renderInnerContentItem()}
                                </div>

                            )                    
                            : 
                            (
                                <a href={`/download-pdf/${additionalContent.artifactId}`} target="blank" className='admin therapist-content-item therapist-pdf-item'>     
                                    <div 
                                        className="drag-handle"  
                                        {...attributes} {...listeners}
                                    >
                                        <DragGrip />         
                                    </div>                   
                                    <div className="therapist-content-item-info">
                                        <div className="therapist-icon-container">
                                            <img src={pdfIconSrc} alt="PDF Icon"/>                         
                                        </div>
                                    </div>
                                    {renderInnerContentItem()}                        
                                </a>
                            )
                        }
                    </div>
                {/* <Tooltip
                    className='therapist-tooltip'
                    
                    
                    
                    placement="bottom"
                >
                {(getContentItemDescription())}
                </Tooltip> */}
                <CustomModal 
                    toggle={toggleVideoModal} 
                    isOpen={isVideoModalOpen}
                    className="therapist-support-modal"
                >
                    <div className="therapist-player-wrapper">
                        <ReactPlayer 
                            url={additionalContent.videoLink} 
                            controls={true}
                            className="therapist-player"                            
                        />
                        <div className="therapist-player-title-container">
                            <p className="therapist-player-title">
                                {additionalContent.name}
                            </p>
                            {
                                props.isAdmin && (
                                <div className="therapist-panel-btn-container">
                                    <button 
                                        className="btn-cbit-icon"
                                        onClick={() => toggleAlert()}
                                    >
                                        <img src={trashIconSrc} alt="Trash Icon" />
                                    </button>
                                </div>
                                )
                            }
                        </div>
                    </div>
                </CustomModal>
                <CustomModal
                    isOpen={isContentModalOpen}
                    toggle={toggleContentModal}                    
                >
                    <AdditionalContentForm 
                        defaultSelectedTags={""}
                        isEditing={true}
                        additionalContent={props.additionalContent}
                        onFormSubmit={updateAdditionalContent}
                    />
                </CustomModal>
                <CustomAlert 
                    header={'ARE YOU SURE YOU WANT TO DELETE: ' + props.additionalContent.name} 
                    text={'Once you click "Delete", the content will be deleted immediately.'} 
                    primaryBtnText={'DELETE'} 
                    secondaryBtnText={'CANCEL'} 
                    isOpen={isAlertOpen} 
                    primaryBtnSubmit={deleteContentItem} 
                    secondaryBtnSubmit={toggleAlert} 
                    toggleAlert={toggleAlert}
                />
            </>
    )
}

export default AdminTherapistSupportContentItem;