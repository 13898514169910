import React from 'react';
import { IAdminTreeModule } from '../../../../../models/partialModels/AdminTreeModule';
import ChapterView from '../ChapterView/ChapterView';
import { AdminViewProps } from '../TopModuleView/TopModuleView';

type LessonGroupProps = {
    topMostModuleId:string
    parentModule:IAdminTreeModule
    selectedModule:IAdminTreeModule

} & AdminViewProps

const LessonGroupView = (props:LessonGroupProps) => {
    const {
        contentList, 
        updatedContentList, 
        updateIsLoading, 
        selectedModuleId,         
        contentType,
        navigateTo,
        isLoading,
        updateAccordion,
        removeAccordionItem,
        onDeleteConfirm,
        updateUnSavedContent,
        addToAccordion,
        topMostModuleId,
        parentModule,
        selectedModule,
        forceRerender,
        isVisible,
        isExpanded
    } = props;
    return (
        <ChapterView
            contentList={contentList}
            updateIsLoading={updateIsLoading}
            updatedContentList={updatedContentList}
            selectedModuleId={selectedModuleId}
            contentType={contentType}
            navigateTo={navigateTo}
            isLoading={isLoading}
            addToAccordion={addToAccordion}
            updateAccordion={updateAccordion}
            onDeleteConfirm={onDeleteConfirm} 
            removeAccordionItem={removeAccordionItem}   
            updateUnSavedContent={updateUnSavedContent}  
            topMostModuleId={topMostModuleId}            
            parentModule={parentModule as unknown as IAdminTreeModule}            
            selectedModule={selectedModule as unknown as IAdminTreeModule}
            forceRerender={forceRerender}
            isVisible={isVisible}
            isExpanded={isExpanded}
        />
    )
}

export default LessonGroupView;