import React from "react";
import { DaysOfWeek } from "../../../models/Enums/DaysOfWeek";
import './DaysOfWeekSelect.css';

type DaysOfWeekSelectProps = {
    listOfDays:DaysOfWeek[]
    updateDaysOfWeekList:(lisOfDays:DaysOfWeek[]) => void
}

/**
 * Component for selecting multiple week days
 * */
const DaysOfWeekSelect = (props:DaysOfWeekSelectProps) => {
    const {listOfDays, updateDaysOfWeekList} = props;

    const handleDayOfWeekInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {        
        const value = parseInt(e.target.value);
        const checked = e.target.checked;
        
        if(isNaN(value)) {
            return;
        }

        let listOfDaysCopy = listOfDays.map(days => days);

        if(!checked) {
            listOfDaysCopy = listOfDays.filter(day => day !== value);                        
        } else {
            listOfDaysCopy.push(value);            
        }

        updateDaysOfWeekList(listOfDaysCopy)
    }

    return (   
        <div className="days-of-week-container">
            <input 
                id="recurrenceDayS" 
                name="recurrenceDay" 
                className="recurrence-checkbox" 
                type="checkbox"
                value={DaysOfWeek.sunday}
                onChange={handleDayOfWeekInputChange}
                checked={listOfDays.includes(DaysOfWeek.sunday)}
            />
            <label 
                htmlFor="recurrenceDayS" 
                className='recurrence-checkbox-label'
            >
                Sun
            </label>
            
            <input 
                id="recurrenceDayM" 
                className="recurrence-checkbox" 
                type="checkbox" 
                name="recurrenceDay"
                value={DaysOfWeek.monday}
                onChange={handleDayOfWeekInputChange}
                checked={listOfDays.includes(DaysOfWeek.monday)} 
            /> 
            <label 
                htmlFor="recurrenceDayM" 
                className='recurrence-checkbox-label'
                
            >
                Mon                  
            </label>

            <input 
                className="recurrence-checkbox" 
                type="checkbox" 
                name="recurrenceDay" 
                id="recurrenceDayT"
                value={DaysOfWeek.tuesday}
                onChange={handleDayOfWeekInputChange}
                checked={listOfDays.includes(DaysOfWeek.tuesday)} 
            />
            <label 
                htmlFor="recurrenceDayT" 
                className='recurrence-checkbox-label'
            >
                Tues
            </label>

            <input 
                className="recurrence-checkbox" 
                type="checkbox" 
                name="recurrenceDay" 
                id="recurrenceDayW"
                value={DaysOfWeek.wednesday}
                onChange={handleDayOfWeekInputChange}
                checked={listOfDays.includes(DaysOfWeek.wednesday)} 
            />
            <label 
                htmlFor="recurrenceDayW" 
                className='recurrence-checkbox-label'
            >
                Wed            
            </label>

            <input 
                className="recurrence-checkbox" 
                type="checkbox" 
                name="recurrenceDay" 
                id="recurrenceDayThur"
                value={DaysOfWeek.thursday}
                onChange={handleDayOfWeekInputChange}
                checked={listOfDays.includes(DaysOfWeek.thursday)} 
            />
            <label 
                htmlFor="recurrenceDayThur" 
                className='recurrence-checkbox-label'
            >
                Thur            
            </label>
            
            <input 
                className="recurrence-checkbox" 
                type="checkbox" 
                name="recurrenceDay" 
                id="recurrenceDayF"
                value={DaysOfWeek.friday}
                onChange={handleDayOfWeekInputChange}
                checked={listOfDays.includes(DaysOfWeek.friday)} 
            />
            <label 
                htmlFor="recurrenceDayF" 
                className='recurrence-checkbox-label'
            >
                Fri
            </label>
            
            <input 
                className="recurrence-checkbox" 
                type="checkbox" 
                name="recurrenceDay" 
                id="recurrenceDaySat"
                value={DaysOfWeek.saturday}
                onChange={handleDayOfWeekInputChange}
                checked={listOfDays.includes(DaysOfWeek.saturday)} 
            />
            <label 
                htmlFor="recurrenceDaySat" 
                className='recurrence-checkbox-label'
            >
                Sat

            </label>
        </div>
    )
}

export default DaysOfWeekSelect