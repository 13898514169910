import React from "react"
import {IReferral, ReferralFilters} from "../../../../models/Referral/Referral";
import {StringHelper} from "../../../../models/utility/StringHelper";
import {UsTerritories} from "../../../../models/utility/StateDictionary";
import {v4 as uuidV4} from "uuid";
import {AgeGroupEnum, ageGroupUi} from "../../../../models/Enums/AgeGroupEnum";
import IconMarkerSrc from "../../../../resources/icons/icon-marker.png";
import {Spinner} from "reactstrap";
import ReferralUserItem from "../ReferralUserItem";
import ArrayHelper from "../../../../models/utility/ArrayHelper";
import {DeepCopy} from "../../../../models/utility/DeepCopy";

type ReferralUserListMobileProps = {
    isLoading: boolean
    referrals: IReferral[]
    referralFilters: ReferralFilters
    updateSelectedReferralId: (referralId: string) => void
    updateReferralFilter: (referralFilters: ReferralFilters) => void
}

type ReferralGroupedByState = {
    state: IReferral[]
}

const ReferralUserListMobile = (props: ReferralUserListMobileProps) => {
    const {
        isLoading, referrals, referralFilters, updateSelectedReferralId, updateReferralFilter
    } = props;

    const referralsByState = (): ReferralGroupedByState => {
        return ArrayHelper.groupBy(referrals, "state");
    }

    const ageGroupList = [AgeGroupEnum.Children, AgeGroupEnum.Adults, AgeGroupEnum.All]

    const ageGroupOptions = {
        [ageGroupUi[AgeGroupEnum.Children]]: AgeGroupEnum.Children,
        [ageGroupUi[AgeGroupEnum.Adults]]: AgeGroupEnum.Adults,
        [ageGroupUi[AgeGroupEnum.All]]: AgeGroupEnum.All
    }

    /**
     * Handles the input for our filters that will be sent to the referral filter api
     * @param e
     * @returns
     */
    const handleFilterInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = e.target.name;

        const value = e.target.value.toLowerCase();

        const referralFiltersCopy: ReferralFilters = DeepCopy.copy(referralFilters);

        const refKey = name as keyof typeof referralFilters;

        // Because we have an array in the object we need to handle input differently when we are adding to it
        if (Array.isArray(referralFiltersCopy[name as keyof typeof referralFilters])) {
            const parseValue = parseInt(value);

            if (isNaN(parseValue) || parseValue > 3 || parseValue < 1) {
                return;
            }

            const isChecked = (e as React.ChangeEvent<HTMLInputElement>).target.checked;

            if (isChecked) {
                (referralFiltersCopy[refKey] as AgeGroupEnum[]).push(parseValue)
            } else {
                (referralFiltersCopy[refKey] as AgeGroupEnum[]) = (referralFiltersCopy[refKey] as AgeGroupEnum[]).filter(ag => ag !== parseValue);
            }

        } else {
            (referralFiltersCopy[refKey] as string) = value;
        }

        updateReferralFilter(referralFiltersCopy);
    };

    return (
        <div className="cbit-form-group referral-user-list mobile">
            {isLoading ? (
                <Spinner color="black"></Spinner>
            ) : (
                <>
                    {referrals.length > 0 ? (
                        <div className='referral-user-list-container mobile'>
                            {Object.keys(referralsByState()).sort((a, b) => (a < b ? -1 : 1)).map((key) => (
                                <React.Fragment key={uuidV4()}>
                                    <React.Fragment key={uuidV4()}>
                                        <div className="cbit-label">{key}</div>
                                        <div className="cbit-divider"></div>
                                        {referralsByState()[key as unknown as keyof ReferralGroupedByState]
                                            .sort((a, b) => (a.name.localeCompare(b.name))).map(referral => (
                                                <ReferralUserItem
                                                    key={uuidV4()}
                                                    referral={referral}
                                                    updateSelectedReferralId={updateSelectedReferralId}
                                                />))}
                                    </React.Fragment>
                                </React.Fragment>
                            ))}
                        </div>
                    ) : (
                        <div className="cbit-label referral-not-found mobile">
                            Referrals Not found
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default ReferralUserListMobile