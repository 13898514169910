import axios from "axios";
import { url } from "inspector";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { BaseReactService } from "./interfaces/BaseReactService";

export class ImageManagementService extends BaseReactService {

    constructor() {        
        super("ImageManager/");        
    }

    /**
     * Uploads an image to an image management service
     */
    public async GetImageUrl() {
        const endPoint = 'ImageManager/GetDirectCreatorUploadUrl';

        try {
            return await this.Get({id:""},endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as any);
        }
    }

}