import React from "react";
import { Spinner } from "reactstrap";
import { v4 as uuidV4 } from "uuid";
import { AgeGroupEnum, ageGroupUi } from "../../../models/Enums/AgeGroupEnum";
import { IReferral, ReferralFilters } from "../../../models/Referral/Referral";
import ArrayHelper from "../../../models/utility/ArrayHelper";
import { DeepCopy } from "../../../models/utility/DeepCopy";
import { UsTerritories } from "../../../models/utility/StateDictionary";
import { StringHelper } from "../../../models/utility/StringHelper";
import IconMarkerSrc from "../../../resources/icons/icon-marker.png";
import ReferralUserListFiltersMobile from "./mobile/ReferralUserListFiltersMobile";
import ReferralUserItem from "./ReferralUserItem";
import "./ReferralUserList.css";

type ReferralUserListProps = {
    isLoading:boolean
    referrals:IReferral[]
    referralFilters:ReferralFilters    
    updateSelectedReferralId:(referralId:string) => void
    updateReferralFilter:(referralFilters:ReferralFilters) => void
}

type ReferralGroupedByState ={
    state:IReferral[]
}

/**
 * A list of user referral profiles
 * TODO: make filter into its own component
 * @param props 
 * @returns 
 */
const ReferralUserList = (props:ReferralUserListProps) => {
    const {
            isLoading,
            referrals,
            referralFilters,            
            updateSelectedReferralId,
            updateReferralFilter
    } = props;

    const referralsByState = (): ReferralGroupedByState=> {             
        return ArrayHelper.groupBy(referrals, "state");
    }  
    
    const ageGroupList = [
        AgeGroupEnum.Children,
        AgeGroupEnum.Adults,
        AgeGroupEnum.All
    ]

    /**
     * Handles the input for our filters that will be sent to the referral filter api
     * @param e 
     * @returns 
     */
    const handleFilterInputChange = (e:React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = e.target.name;

        const value = e.target.value.toLowerCase();

        const referralFiltersCopy:ReferralFilters = DeepCopy.copy(referralFilters);          
        
        const refKey = name as keyof typeof referralFilters;
        
        // Because we have an array in the object we need to handle input differently when we are adding to it
        if(Array.isArray(referralFiltersCopy[name as keyof typeof referralFilters])) {            
            const isChecked = (e as React.ChangeEvent<HTMLInputElement>).target.checked;

           filterAgeGroup(value, referralFiltersCopy, refKey, isChecked);

        } else {
            (referralFiltersCopy[refKey] as string) = value;
        }

        updateReferralFilter(referralFiltersCopy);
    };

    /**
     * Add age group filter to the list of filters
     * Note: this would have be easier to deal with if the referral profile had age group as an array and all was not in the enum
     * @param filterVal 
     * @param filters 
     * @param refKey 
     * @param isChecked 
     * @returns 
     */
    const filterAgeGroup = (filterVal:string, filters:ReferralFilters, refKey:keyof ReferralFilters, isChecked:boolean) => {
        const parseValue = parseInt(filterVal);
            
            if(isNaN(parseValue) || parseValue > 3 || parseValue < 1) {
                return;
            }

            let ageFilter = filters[refKey] as AgeGroupEnum[];
                                    
            //If the All was unchecked remove all age groups
            if(!isChecked && parseValue === AgeGroupEnum.All) {
                ageFilter = [];
                (filters[refKey] as AgeGroupEnum[]) = ageFilter;
                return;
            } 
            
            //If the all box is checked add all age groups to the filter
            if(isChecked && parseValue === AgeGroupEnum.All) {
                ageFilter.push(AgeGroupEnum.Adults, AgeGroupEnum.Children, AgeGroupEnum.All);
                (filters[refKey] as AgeGroupEnum[]) = ageFilter;
                return;
            }  
            
            //If we already have children or adult checked and we are checking one of the two then add all
            if(isChecked && ageFilter.includes(AgeGroupEnum.Adults) 
                || isChecked && ageFilter.includes(AgeGroupEnum.Children)) {

                ageFilter.push(AgeGroupEnum.Adults, AgeGroupEnum.Children, AgeGroupEnum.All);
                (filters[refKey] as AgeGroupEnum[]) = ageFilter;
                return;
            }

            if(isChecked) {
                ageFilter.push(parseValue);
                (filters[refKey] as AgeGroupEnum[]) = ageFilter;
                return;
            } 
           
            //When we are unchecking children or adults
            ageFilter = ageFilter.filter(ag => ag !== parseValue && ag !== AgeGroupEnum.All);
            (filters[refKey] as AgeGroupEnum[]) = ageFilter;
    }

    return (
        <>
            <div className="referral-users-container">
                <h4 className="cbit-dashboard-header">CBIT-Trained Professionals by State</h4>
                <div className="cbit-row referral-state-row">
                    <div className="cbit-form-group">
                        <label htmlFor="stateFilter" className="cbit-label">State</label>
                        <select
                            id="stateFilter"
                            name="stateFilter"
                            className="form-input form-control"
                            placeholder="State"
                            onChange={handleFilterInputChange}
                            value={StringHelper.capitalizeWord(referralFilters.stateFilter, true)}
                        >
                            <option value="">All</option>
                            {
                                Object.entries(UsTerritories.states).map(([key, value]) => (
                                    <option
                                        key={uuidV4()}
                                        value={key}
                                    >
                                        {value}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="cbit-form-group">
                        <label htmlFor="licensedStateFilter" className="cbit-label">Licensed State</label>
                        <select
                            id="licensedStateFilter"
                            name="licensedStateFilter"
                            className="form-input form-control"
                            placeholder="Licensed State"
                            onChange={handleFilterInputChange}
                            value={StringHelper.capitalizeWord(referralFilters.licensedStateFilter, true)}
                        >
                            <option value="">All</option>
                            {
                                Object.entries(UsTerritories.states).map(([key, value]) => (
                                    <option
                                        key={uuidV4()}
                                        value={key}
                                    >
                                        {value}
                                    </option>
                                ))
                            }
                        </select>
                    </div>
                </div>

                <div className="cbit-form-group">
                    <label htmlFor="stateFilter" className="cbit-label">Age Group</label>
                    <div
                        role="group"
                        className='filter-age-group'
                        aria-label='checkbox-group'
                    >
                        {
                            ageGroupList.map((ageGroup, index) => (
                                <div className="user-form-checkbox" key={ageGroup}>
                                    <label htmlFor={`choice-${ageGroupList[index]}`} className="checkbox-label-cbit">
                                        {ageGroupUi[ageGroup]}
                                        <input
                                            type="checkbox"
                                            id={`choice-${ageGroupList[index]}`}
                                            name="ageGroupFilters"
                                            value={ageGroup.toString()}
                                            checked={referralFilters.ageGroupFilters.includes(ageGroup)}
                                            onChange={handleFilterInputChange}
                                            className='form-checkbox-input checkbox-cbit'
                                        />
                                    </label>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="referral-address-legend">
                    <div>
                        <div className="referral-user-marker-container">
                            <img
                                src={IconMarkerSrc}
                                className="referral-map-icon"
                                alt="Public Marker Icon"
                            />
                        </div>
                        {" "}
                        Public Address
                    </div>
                    <div>
                        <div className="referral-user-marker-container">
                            <img
                                src={IconMarkerSrc}
                                alt="Private Marker Icon"
                            />
                        </div>
                        {" "}
                        Private Address
                    </div>
                </div>
                {
                    isLoading ?
                        (
                            <Spinner color="black"></Spinner>
                        )
                        :
                        (
                            <>
                                {
                                    referrals.length > 0 ?
                                        (
                                            <div className='referral-user-list-container'>
                                                {
                                                    Object.keys(referralsByState()).sort((a, b) => (a < b ? -1 : 1)).map((key) => (
                                                        <>
                                                            <React.Fragment key={uuidV4()}>
                                                                <div className="cbit-label">{key}</div>
                                                                <div className="cbit-divider"></div>
                                                                {referralsByState()[key as unknown as keyof ReferralGroupedByState]
                                                                    .sort((a, b) => (a.name.localeCompare(b.name) )).map(referral => (
                                                                        <ReferralUserItem
                                                                            key={uuidV4()}
                                                                            referral={referral}
                                                                            updateSelectedReferralId={updateSelectedReferralId}
                                                                        />
                                                                    ))}
                                                            </React.Fragment>
                                                        </>
                                                    ))
                                                }
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="cbit-label">Referrals Not found</div>
                                        )
                                }
                            </>
                        )
                }
            </div>
            <ReferralUserListFiltersMobile
                isLoading={isLoading}
                referralFilters={referralFilters}
                updateSelectedReferralId={updateSelectedReferralId}
                updateReferralFilter={updateReferralFilter}
                referrals={referrals}
            />

        </>
    )

}

export default ReferralUserList;