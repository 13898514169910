import { _idObject } from "../AdditionalVideoContent/AdditionalVideoContent";
import { ILessonContent } from "./LessonContent";
import { LessonTypeEnum } from "./LessonType";
import {UserLessonContent} from "./UserLessonContent";
import IconVideoSrc from "../../resources/icons/icon-video.png";
import IconTextSrc from "../../resources/icons/icon-reading.png";
import IconQuizSrc from "../../resources/icons/icon-quiz.png";
import IconComboLessonSrc from "../../resources/icons/icon-combo.png";

export enum LessonIconEnum {
    Unknown = 0,
    VideoIcon = 1,
    TextIcon = 2,
    QuizIcon = 3,
    ComboLessonIcon = 4,
}

export const getLessonIconSrc = (lessonIcon:LessonIconEnum) => {
    switch (lessonIcon) {
        case LessonIconEnum.VideoIcon: {
            return IconVideoSrc;
        } 
        case LessonIconEnum.TextIcon: {
            return IconTextSrc;
        } 
        case LessonIconEnum.QuizIcon: {
            return IconQuizSrc;
        } 
        case LessonIconEnum.ComboLessonIcon: {
            return  IconComboLessonSrc;
        }                        
        default: {
            return "";
        }            
    }
}

// Interface for lesson objects
export const DefaultLesson:ILesson={
    id: '',
    branchId: '',
    name: '',
    description: '',
    order: 0,
    type: LessonTypeEnum.custom,
    lessonContent: [],
    userLessonContent: [],
    tagList: [],
    duration: '00:00',
    IsLocked: false,
    IsOptional: false,
    isViewable: true,
    IsComplete: false,
    IsDisabled: false,
    IsNext: false,
    lessonIcon: LessonIconEnum.Unknown,
    totalQuestions: 0,
    createdAt: new Date(),
    updatedAt: new Date(),
}

export interface ILesson {
    id: string;
    branchId : string;
    name: string;
    description : string;
    order: number;
    type: LessonTypeEnum;
    lessonContent: ILessonContent[] | null; // Upon creation of a lesson it can have no content
    userLessonContent: UserLessonContent[];
    tagList: _idObject[];
    duration:string;
    IsLocked:boolean;
    IsOptional:boolean;
    isViewable:boolean;
    IsComplete:boolean;
    IsDisabled:boolean;
    IsNext:boolean;
    lessonIcon:LessonIconEnum;
    totalQuestions:number;
    createdAt: Date| string| undefined | null;
    updatedAt: Date| string| undefined | null;
}

export class Lesson implements ILesson {
    id = '';
    branchId = '';
    name = '';
    description = '';
    order = 0;
    type = LessonTypeEnum.custom;
    lessonContent : ILessonContent[] | null = [] ;
    userLessonContent: UserLessonContent[] =[];
    tagList:_idObject[];
    duration = '00:00';
    IsLocked=false;
    IsOptional=false;
    /**Is viewable defaults to true now because we have the publish button
     * and admin frequently forget to toggle viewable on when publishing**/
    isViewable=true;
    IsComplete=false;
    IsDisabled=false;
    IsNext=false;
    lessonIcon=LessonIconEnum.Unknown;
    totalQuestions=0;
    createdAt: Date | string | undefined | null = new Date();
    updatedAt: Date | string | undefined | null = new Date();
    
    constructor(lessonData:ILesson = {
        id: '',
        branchId : '',
        name: '',
        description : '',
        order: 0,
        type: LessonTypeEnum.custom,
        lessonContent: null,
        tagList: [],
        userLessonContent: [],
        duration: '00:00',
        IsLocked:false,
        IsOptional:false,
        isViewable:true,
        IsComplete:false,
        IsDisabled:false,
        IsNext:false,
        lessonIcon: LessonIconEnum.Unknown,
        totalQuestions: 0,
        createdAt: new Date(),
        updatedAt: new Date()
    }){
        this.id = lessonData.id;
        this.branchId = lessonData.branchId;
        this.name = lessonData.name;
        this.description = lessonData.description;
        this.order = lessonData.order;
        this.type = lessonData.type;
        this.lessonContent = lessonData.lessonContent;
        // this.userLessonContent = lessonData.userLessonContent.length == 0? lessonData.userLessonContent: [];
        this.tagList = lessonData.tagList;
        this.duration = lessonData.duration;
        this.isViewable = lessonData.isViewable;
        this.lessonIcon =lessonData.lessonIcon;
        this.totalQuestions = lessonData.totalQuestions;
        this.createdAt = lessonData?.createdAt;
        this.updatedAt = lessonData?.updatedAt;
        this.MapJsonLowerCaseFieldsToLessonFields(lessonData);
    }

    public static GetLessonIcon = (lessonType:LessonTypeEnum) => {
        switch(lessonType) {
            case LessonTypeEnum.video: {
                return LessonIconEnum.VideoIcon;
            }
            case LessonTypeEnum.text: {
                return LessonIconEnum.TextIcon;
            }
            case LessonTypeEnum.quiz: {
                return LessonIconEnum.QuizIcon;
            }
            case LessonTypeEnum.custom: {
                return LessonIconEnum.ComboLessonIcon;
            }
            default: {
                return LessonIconEnum.Unknown;
            }
        }
    }

    /**
        Because the lesson properties are formatted with capitalization (even though json by default make c# fields lowercase).
        There are too many places to change on the frontend that are using the capitalization fields so that is why we are using
        the lowercase fields here and adding a ts-ignore because the property does exists on the json data.
    */
    private MapJsonLowerCaseFieldsToLessonFields(lessonData:ILesson) {
    
        //@ts-ignore
        this.IsLocked = lessonData.IsLocked ?  lessonData.IsLocked :lessonData.isLocked;
        //@ts-ignore
        this.IsOptional = lessonData.IsOptional ?  lessonData.IsOptional :lessonData.isDisabled;
        //@ts-ignore
        this.IsComplete = lessonData.IsComplete ?  lessonData.IsComplete :lessonData.isComplete;
        //@ts-ignore
        this.IsDisabled = lessonData.IsDisabled ?  lessonData.IsDisabled :lessonData.isDisabled;
        //@ts-ignore
        this.IsNext = lessonData.IsNext ?  lessonData.IsNext :lessonData.isNext;

        if(this.IsLocked == null) {
            this.IsLocked = false;
        }

        if(this.IsOptional == null) {
            this.IsOptional= false;
        }

        if(this.IsComplete == null) {
            this.IsComplete = false;
        }

        if(this.IsDisabled == null) {
            this.IsDisabled = false;
        }

        if(this.IsDisabled == null) {
            this.IsNext = false;
        }
    }
}


/**
 * Returns whether or not that passed in object is a lesson
 * @param lesson 
 */
 export function IsInstanceOfLesson(lesson:any) {
    //if the property name changes or if another object has the same property this will break. 
    
    // return (lesson as ILesson).branchId; 
    return "branchId" in lesson;
    
}