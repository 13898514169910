import { AxiosError } from "axios";
import { IConsultation } from "../models/consultations/Consultation";
import { RecurringConsultationRequest } from "../models/requests/RecurringConsultationRequest";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import {BaseReactService} from "./interfaces/BaseReactService";

export class ConsultationService extends BaseReactService{
    constructor()
    {
        super("Consultation/");
    }
    
    public async GetConsultationsByConsultant(id:string) 
    {
        const endPoint = 'Consultation/GetUserConsultations/';

        try {
            const params = {id}
            return await this.Get(params, endPoint);

        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }

    public async GetUserConsultations(id:string) {
        const endPoint = 'Consultation/GetUserConsultations/';

        try {
            const params = {id}
            return await this.Get(params, endPoint);

        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }

    /**
     * Get a consultants available consultations by a date 
     * @param id 
     * @param from 
     * @param to 
     * @returns 
     */
    public async GetConsultantsAvailableConsultationsByDate(id:string, from:Date|string, to:Date|string) {
        const endPoint = 'Consultation/GetConsultantsAvailableConsultations';
        try {
            return this.CustomGet({consultantId:id, from, to}, endPoint);    
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get upcoming active/active full consultations
     * @param userId 
     * @param from 
     * @param to 
     * @returns 
     */
    public async GetUpcomingSessions(userId:string, from:Date|string, to:Date|string) {
        const endPoint = 'Consultation/GetUpcomingConsultations';
        try {
            
            return this.CustomGet({userId, from, to}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get all upcoming active/active full consultations
     * For super admins only
     * @param userId 
     * @param from 
     * @param to 
     * @returns 
     */
    public async GetAllUpcomingSessions(to:Date|string) {
        const endPoint = 'Consultation/GetAllUpcomingConsultations';
        try {
            
            return this.CustomGet({to}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get all previous active/active full consultations
     * For super admins only
     * @param userId 
     * @param from 
     * @param to 
     * @returns 
     */
    public async GetAllPreviousSessions(startTime:Date|string, endTime:Date|string) {
        const endPoint = 'Consultation/GetAllPreviousConsultations';
        try {
            
            return this.CustomGet({startTime, endTime}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get all past consultations
     * @param userId 
     * @param from 
     * @param to 
     * @returns 
     */
    public async GetPreviousConsultationSessions(userId:string, startTime:Date|string, endTime:Date|string) {
        const endPoint = 'Consultation/GetPreviousConsultations';
        try {
            //Converting to IsoString because the start and end times are saved as iso strings in mongo
            return this.CustomGet({userId, startTime, endTime}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get the full info for a specific consultation session
     * @param id 
     * @returns 
     */
    public async GetConsultationSession(id:string){
        const endPoint = 'Consultation/GetConsultationSession/';
        try {
            return this.Get({id},endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async CreateNewConsultation(consultation:IConsultation) {
        const endPoint = 'Consultation/CreateNewConsultation';
        try {
            return this.Post(consultation, endPoint)
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async CreateRecurringConsultations(recurringRequest:RecurringConsultationRequest) {
        const endPoint = 'consultation/CreateRecurringConsultation';

        try {
            return this.Post(recurringRequest, endPoint)
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Updates a single consultation
     * @param consultation 
     * @returns 
     */
    public async UpdateConsultation(consultation:IConsultation) {
        const endPoint = 'Consultation/UpdateConsultation/';
        try {
            return this.Put({id: consultation.id}, consultation, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async UpdateRecurringConsultation(recurringRequest:RecurringConsultationRequest) {
        const endPoint = 'Consultation/UpdateAllRecurringConsultations/';
        
        const id = recurringRequest.recurringConsultation.id;
        
        try {
            return await this.Put({id}, recurringRequest, endPoint);            
        } catch (error) {
           return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async UpdateConsultationOccurrence(consultationOccurrence:IConsultation) {
        const endPoint = 'Consultation/UpdateConsultationOccurrence/';
        const id = consultationOccurrence.id;
        try {
            return this.Put({id}, consultationOccurrence, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    /**
     * Books a consultation
     * @param userId 
     * @param consultationId 
     */
    public async bookConsultation(consultationId:string, userId:string) {
        const endPoint = 'Consultation/BookConsultation/';

        try {
            return this.CustomPut(consultationId, {userId}, endPoint);
        } catch (error) {
            // return ErrorHandler.catchApiError((error as AxiosError));
            throw error;
        }
    }

    /**
     * Removes an attendee from the consultation session
     * @param consultationId 
     * @param userId 
     * @returns 
     */
    public async WithdrawFromConsultation(consultationId:string, userId:string) {
        const endPoint = 'Consultation/WithdrawFromConsultation/';
        try {
            return this.CustomPut(consultationId, {userId}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * updates consultation status to deleted to softly delete a consultation
     */
    public async CancelConsultation(id:string) {
        const endPoint = 'Consultation/CancelConsultation/';
        try {
            return this.Put({id}, {}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    // Note: All functions below permanently deletes

    /**
     * Delete all upcoming occurrences of a recurring consultation
     * @param id 
     */
    public async DeleteUpcomingRecurringConsultation(id:string) {
        const endPoint = 'Consultation/DeleteUpcomingRecurringConsultation/';
        try {
            return this.Delete({id}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Delete a single occurrence out of a recurring consultation
     * @param id 
     */
    public async DeleteConsultationOccurrence(id:string) {
        const endPoint = 'Consultation/DeleteConsultationOccurrence/';
        try {
            return this.Delete({id}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Cancel recurring for a consultation and 
     * create a new normal consultation in its place 
     * @param consultation 
     */
    public async CancelRecurringAndCreateConsultation(consultation:IConsultation) {
        const endPoint = 'Consultation/CancelRecurringAndCreateConsultation/';
        try {
            return this.Put({id:consultation.id}, consultation, endPoint)
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Load more upcoming Seminars
     * @param PageNumber
     * @param PageSize
     * @param isPast
     * @returns ISeminar[]
     */
    public async GetWebinars(PageNumber: number, PageSize: number, isPast: boolean) {
        const endPoint = 'Consultation/GetSeminars/';
        try {
            return this.CustomGet({PageNumber, PageSize, isPast: isPast}, endPoint);
        }
        catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get all seminars by a user
     * @param PageNumber
     * @param PageSize
     * @param userId
     * @returns ISeminar[]
     */
    public async GetWebinarsByUser(PageNumber: number, PageSize: number, userId:string) {
        const endPoint = 'Consultation/GetRegistrations/';
        try {
            return this.CustomGet({PageNumber, PageSize, id: userId}, endPoint);
        }
        catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    /**
     * Get all webinars that have recording links
     * @param PageNumber
     * @param PageSize     
     * @returns ISeminar[]
     */
    public async GetWebinarsWithRecordings(PageNumber: number, PageSize: number) {
        const endPoint = 'Consultation/GetWebinarsWithRecordings/';
        try {
            return this.CustomGet({PageNumber, PageSize}, endPoint);
        }
        catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Removes a user from the reserve list
     */
    public async RemoveUserFromReserveList(consultationId:string) {
        const endPoint = 'Consultation/RemoveUserFromReserveList/';
        try {
            return await this.Get({id: consultationId}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Add recording link to a consultation
     * @param recordingLink 
     * @param consultationId 
     * @returns 
     */
    public async AddRecordingToConsultation(recordingLink:string, consultationId:string) {
        const endPoint = 'Consultation/AddRecordingToConsultation';
        try {
            return await this.CustomPatch({recordingLink, consultationId}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
}