import { DirectUploadResults } from "../models/ImageManagement/DirectUploadResults";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { ImageManagementService } from "../services/ImageManagementService";
import { BaseRemoteApiService } from "../services/interfaces/BaseRemoteApiService";

export const baseImageUrl = "https://imagedelivery.net/vvP7bkGThCibwLU377SBtQ/";
export const variantName = "/public"; // name of the cloudFlare variant

export class ImageManagementController {
    
    private ImageManagement = new ImageManagementService();
    private RemoteApiService = new BaseRemoteApiService();

    public async GetDirectUploadUrl() {
        try {

            const directUploadRes:DirectUploadResults = await  this.ImageManagement.GetImageUrl();                    
            
            if(!directUploadRes.success)
                throw new Error("Direct Upload Error");
            
            return directUploadRes.result
            

        } catch (error) {
            ErrorHandler.catchApiError(error as any);
        }
        
    }

    public async UploadImage(uploadUrl: string, imageFile:File){
        try {   

            const formData = new FormData();

            formData.append("file", imageFile,imageFile.name);            
            
            //TODO move to services
            const uploadResult = await fetch(uploadUrl, {
              method: "POST",
              body: formData,
            });

            return uploadResult;
            
        } catch (error) {
            console.error(error);
        }
    }

    public async DeleteImage(id:string) {
        try {
            
            const deleteResults = await this.ImageManagement.Delete({id});            

        } catch (error) {
            ErrorHandler.catchApiError(error as any);
        }
    }
}