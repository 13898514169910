import React from 'react';
import ReactPlayer from 'react-player';

type videoItemProps = { 
    videoLink:string;
    videoName:string;
    videoDescription:string
}

const VideoViewItem = (props:videoItemProps) => {
    
    const {videoLink, videoDescription} = props;

    return (
        <div className="admin-view-item">
            <div className="admin-video-wrapper">                                       
                <ReactPlayer url={videoLink}
                    className="admin-video-player"
                    controls={true}
                    width={'100%'}
                    height={'100%'}                       
                />            
            </div>        
            
            <p className='admin-video-description'>{videoDescription}</p>
    </div>
    )
}

export default VideoViewItem;