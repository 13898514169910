import React, {useEffect, useState} from 'react';
import '../FamilySupportAdminDashboard/FamilySupportAdminDashboard.css';
import {SupportContentController} from "../../controllers/SupportContentController";
import {SupportContent, supportLink} from "../../models/AdditionalContent/SupportContent";
import moment from "moment/moment";

import {DeepCopy} from "../../models/utility/DeepCopy";
import FamilySupportAdminDashboardPanel from "../FamilySupportAdminDashboard/FamilySupportAdminDashboardPanel";
import FamilySupportAdminView from "../FamilySupportAdminDashboard/FamilySupportAdminView";
import FamilySupportLayout from "./layout/FamilySupportLayout";


const FamilySupportAdminDashboard = () => {
    const [state, setState] = useState<SupportContent[]>([]);
    const [selectedState, setSelectedState] = useState<SupportContent>({
        id: "", name: "", createdAt: null, updatedAt: null, order: -1, links: []
    });

    const [selectedLinkIndex, setSelectedLinkIndex] = useState(0);
    const [selectedLink, setSelectedLink] = useState<supportLink>({
        title: "", external: true, link: "", content: "", mongoFile: '',
    })

    const [itemToDelete, setItemToDelete] = useState<string>("");

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isDeleteContentAlertOpen, setIsDeleteContentAlertOpen] = useState(false);

    const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);

    const supportContentController = new SupportContentController();

    useEffect(() => {
        async function onComponentMount(setData: any) {
            let data = await supportContentController.GetSupportContent()
            setData(data);
        }

        onComponentMount(setState);
    }, []);

    /**
     * Open or close the delete alert
     * @returns
     */
    const toggleDeleteAlert = () => setIsDeleteAlertOpen(!isDeleteAlertOpen);
    const toggleLinkModal = () => {
        setSelectedLink({title: '', content: '', link: '', external: true, mongoFile: ''})
        setIsLinkModalOpen(!isLinkModalOpen);
    }

    const toggleDeleteLinkAlert = () => {

        setIsDeleteContentAlertOpen(!isDeleteContentAlertOpen);
    }

    const toggleEditLinkModal = (link: supportLink) => {
        setSelectedLink(link)
        setIsLinkModalOpen(!isLinkModalOpen);
    }

    const DeleteCategory = async () => {

        toggleDeleteAlert();

        await supportContentController.DeleteSupportContent(itemToDelete);
        let data = await supportContentController.GetSupportContent()

        setState(data);
        setItemToDelete("");

    }

    const DeleteSupportContent = async () => {

        toggleDeleteLinkAlert();

        let category = selectedState;
        category.links.splice(selectedLinkIndex, 1);

        let updatedContent = await supportContentController.UpdateSupportContent(category);


        let data = await supportContentController.GetSupportContent()
        setSelectedLinkIndex(0);
        setState(data);

    }

    const returnCategoryName = (id: string) => {
        for (var i = 0; i < state.length; i++) {
            if (state[i].id == id) {
                return state[i].name;
            }
        }
        return "N/A"
    }

    const returnContentName = (index: number) => {
        try {
            return selectedState.links[index].title

        } catch (exception) {
            return "N/A"

        }
    }

    const UpdateCategoryContent = async (contentCategory: SupportContent) => {
        //Update Copy in the DB
        let updatedContent = await supportContentController.UpdateSupportContent(contentCategory);

        let data = await supportContentController.GetSupportContent()
        setState(data);
    }

    const CreateNewSupportContent = async (link: supportLink) => {
        let content = selectedState;
        content.links.push(link);

        toggleLinkModal();
        let updatedContent = await supportContentController.UpdateSupportContent(content);

        let data = await supportContentController.GetSupportContent()
        setState(data);
    }

    const UpdateSupportContent = async (link: supportLink) => {
        let content = selectedState;
        content.links[selectedLinkIndex] = link;

        toggleLinkModal();
        let updatedContent = await supportContentController.UpdateSupportContent(content);

        let data = await supportContentController.GetSupportContent()
        setState(data);
    }

    const updateSupportLinkOrder = async (links: supportLink[]) => {
        let content = selectedState;
        content.links = links;

        let updatedContent = await supportContentController.UpdateSupportContent(content);

        let data = await supportContentController.GetSupportContent()
        setState(data);
    }

    const AddNewSupportContent = async (name: string) => {
        // Send Up New Data
        let request: SupportContent = {
            links: [],
            updatedAt: null,
            createdAt: moment(new Date()).toISOString(),
            order: state.length,
            name: name,
            id: ""
        };

        let newData = await supportContentController.CreateSupportContent(request)

        // Update State
        const contentCategoriesCopy: SupportContent[] = DeepCopy.copy(state);
        contentCategoriesCopy.push(newData);

        setState(contentCategoriesCopy);
    }

    return (
        <FamilySupportLayout isAdmin={false}
                             contentCategories={state}
                             updateCategory={UpdateCategoryContent}
                             addNewContentCategory={AddNewSupportContent}
                             setSelectedContentCategory={setSelectedState}
                             setItemToDelete={setItemToDelete}
                             toggleDeleteAlert={toggleDeleteAlert}
                             selectedCategory={selectedState}
        >
            <>
                {selectedState.id == "" ? (
                    <>
                        <h2 className="h2">No Categories Has Been Selected</h2>
                    </>
                ) : (
                    <>
                        <div className="category-title-container main-category-container">
                            <h2 className="category-title">
                                {selectedState.name}
                            </h2>
                        </div>
                        <FamilySupportAdminView isAdmin={false}
                                                contentLinks={selectedState.links}
                                                setSelectSupportLink={setSelectedLinkIndex}
                                                toggleEditLinkModal={toggleEditLinkModal}
                                                toggleDeleteLinkAlert={toggleDeleteLinkAlert}
                                                updateLinkOrder={updateSupportLinkOrder}
                        />
                    </>
                )}
            </>
        </FamilySupportLayout>
    )
}

export default FamilySupportAdminDashboard;
