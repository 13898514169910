import React from "react"
import {StringHelper} from "../../../../models/utility/StringHelper";
import {UsTerritories} from "../../../../models/utility/StateDictionary";
import {v4 as uuidV4} from "uuid";
import ArrayHelper from "../../../../models/utility/ArrayHelper";
import {AgeGroupEnum, ageGroupUi} from "../../../../models/Enums/AgeGroupEnum";
import {IReferral, ReferralFilters} from "../../../../models/Referral/Referral";
import {DeepCopy} from "../../../../models/utility/DeepCopy";
import IconMarkerSrc from "../../../../resources/icons/icon-marker.png";

type ReferralUserListProps = {
    isLoading:boolean
    referrals:IReferral[]
    referralFilters:ReferralFilters
    updateSelectedReferralId:(referralId:string) => void
    updateReferralFilter:(referralFilters:ReferralFilters) => void
}

type ReferralGroupedByState ={
    state:IReferral[]
}

const ReferralUserListFiltersMobile = (props:ReferralUserListProps) => {
    const {
        isLoading,
        referrals,
        referralFilters,
        updateSelectedReferralId,
        updateReferralFilter
    } = props;

    const referralsByState = (): ReferralGroupedByState=> {
        return ArrayHelper.groupBy(referrals, "state");
    }

    const ageGroupList = [
        AgeGroupEnum.Children,
        AgeGroupEnum.Adults,
        AgeGroupEnum.All
    ]

    const ageGroupOptions = {
        [ageGroupUi[AgeGroupEnum.Children]]: AgeGroupEnum.Children,
        [ageGroupUi[AgeGroupEnum.Adults]]: AgeGroupEnum.Adults,
        [ageGroupUi[AgeGroupEnum.All]]: AgeGroupEnum.All
    }

    /**
     * Handles the input for our filters that will be sent to the referral filter api
     * @param e
     * @returns
     */
    const handleFilterInputChange = (e:React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const name = e.target.name;

        const value = e.target.value.toLowerCase();

        const referralFiltersCopy:ReferralFilters = DeepCopy.copy(referralFilters);

        const refKey = name as keyof typeof referralFilters;

        // Because we have an array in the object we need to handle input differently when we are adding to it
        if(Array.isArray(referralFiltersCopy[name as keyof typeof referralFilters])) {
            const parseValue = parseInt(value);

            if(isNaN(parseValue) || parseValue > 3 || parseValue < 1) {
                return;
            }

            const isChecked = (e as React.ChangeEvent<HTMLInputElement>).target.checked;

            if(isChecked) {
                (referralFiltersCopy[refKey] as AgeGroupEnum[]).push(parseValue)
            } else {
                (referralFiltersCopy[refKey] as AgeGroupEnum[]) = (referralFiltersCopy[refKey] as AgeGroupEnum[]).filter(ag => ag !== parseValue);
            }

        } else {
            (referralFiltersCopy[refKey] as string) = value;
        }

        updateReferralFilter(referralFiltersCopy);
    };

    return (
        <div className='referral-users-container mobile'>
            <h4 className="cbit-dashboard-header">CBIT-Trained Professionals by State</h4>
            <div className="cbit-row referral-state-row">
                <div className="cbit-form-group">
                    <label htmlFor="stateFilter" className="cbit-label">State</label>
                    <select
                        id="stateFilter"
                        name="stateFilter"
                        className="form-input form-control"
                        placeholder="State"
                        onChange={handleFilterInputChange}
                        value={StringHelper.capitalizeWord(referralFilters.stateFilter, true)}
                    >
                        <option value="">All</option>
                        {
                            Object.entries(UsTerritories.states).map(([key, value]) => (
                                <option
                                    key={uuidV4()}
                                    value={key}
                                >
                                    {value}
                                </option>
                            ))
                        }
                    </select>
                </div>
                <div className="cbit-form-group">
                    <label htmlFor="licensedStateFilter" className="cbit-label">Licensed State</label>
                    <select
                        id="licensedStateFilter"
                        name="licensedStateFilter"
                        className="form-input form-control"
                        placeholder="Licensed State"
                        onChange={handleFilterInputChange}
                        value={StringHelper.capitalizeWord(referralFilters.licensedStateFilter, true)}
                    >
                        <option value="">All</option>
                        {
                            Object.entries(UsTerritories.states).map(([key, value]) => (
                                <option
                                    key={uuidV4()}
                                    value={key}
                                >
                                    {value}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div>

            <div className="cbit-form-group">
                <label htmlFor="stateFilter" className="cbit-label">Age Group</label>
                <div
                    role="group"
                    className='filter-age-group'
                    aria-label='checkbox-group'
                >
                    {
                        ageGroupList.map((ageGroup, index) => (
                            <div className="user-form-checkbox" key={ageGroup}>
                                <label htmlFor={`choice-${ageGroupList[index]}`} className="checkbox-label-cbit">
                                    {ageGroupUi[ageGroup]}
                                    <input
                                        type="checkbox"
                                        id={`choice-${ageGroupList[index]}`}
                                        name="ageGroupFilters"
                                        value={ageGroup.toString()}
                                        checked={referralFilters.ageGroupFilters.includes(ageGroup)}
                                        onChange={handleFilterInputChange}
                                        className='form-checkbox-input checkbox-cbit'
                                    />
                                </label>
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="referral-address-legend">
                <div>
                    <div className="referral-user-marker-container">
                        <img
                            src={IconMarkerSrc}
                            className="referral-map-icon"
                            alt="Public Marker Icon"
                        />
                    </div>
                    {" "}
                    Public Address
                </div>
                <div>
                    <div className="referral-user-marker-container">
                        <img
                            src={IconMarkerSrc}
                            alt="Private Marker Icon"
                        />
                    </div>
                    {" "}
                    Private Address
                </div>
            </div>
        </div>
    )
}

export default ReferralUserListFiltersMobile