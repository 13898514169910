import React from "react"
import PastWebinarsModal from "./PastWebinarsModal";
import {ISeminar} from "../../../../models/consultations/Seminar";
import moment from "moment/moment";

type PastWebinarItemProps = {
    seminar: ISeminar
}

/**
 * An individual past webinar that links to a zoom recording of the consultation
 * @param param0 
 * @returns 
 */
const PastWebinarItem = ({seminar: {consultation, consultant}}: PastWebinarItemProps) => {

    return (
        <div key={consultation.id} className="past-webinar-item">
            <div className="past-webinar-item-title-container">
                <h4 className="past-webinar-item-title">
                    {consultation.name}
                </h4>
            </div>
            <div>
                <h5 className="past-webinar-item-info-title instructor">
                    {moment(consultation?.startDate).format("ddd, MMM Do YY")}
                </h5>
                <h5 className="past-webinar-item-info-title">
                    {moment(consultation?.startDate).format('LT')} - {moment(consultation?.endDate).format('LT')} CT
                </h5>
                <h5 className="past-webinar-item-info-title instructor">
                    {consultant?.prefix !== '' ?  consultant?.prefix + ' ' + consultant?.firstName + ' ' + consultant?.lastName : consultant?.firstName + ' ' + consultant?.lastName}
                </h5>
                <h5 className="past-webinar-item-info-title">
                    Free
                </h5>
            </div>
            <div className="past-webinar-item-description-container">
                <p className="past-webinar-item-description">
                    {consultation?.description}
                </p>
            </div>
            {
                consultation.recordingLink && (
                    <div className="past-webinar-item-btn-container">
                        <a 
                            className='past-webinar-item-btn'
                            href={consultation.recordingLink}
                            target="_blank"
                            aria-label="Webinar link to video"
                        >
                            Watch Video
                        </a>
                        {/* <PastWebinarsModal seminar={seminar}/> */}
                    </div>
                )
            }
        </div>
    )
}

export default PastWebinarItem