import React, {useEffect, useState} from "react";
import userProfileImage from "../../../resources/images/user-placeholder.png"
import moment from "moment/moment";
import AddReply from "./AddReply";
import CloudflareImageHelper from "../../../models/utility/CloudflareImageHelper";
import {IPost} from "../../../models/SocialConnection/Post";
import PostFunctionToolBar from "./toolbar/PostFunctionToolBar";
import SocialConnectionReplyItem from "./SocialConnectionReplyItem";
import {SocialConnectionController} from "../../../controllers/SocialConnectionController";

type SocialConnectionPostItemWithReplyProps = {
    post: IPost,
    updatePost: any,
    isPostPage?: boolean,
    updatePostReply?: any,
}

const SocialConnectionPostItemWithReply = ({post, updatePost, isPostPage, updatePostReply}: SocialConnectionPostItemWithReplyProps) => {
    const [myPost, setMyPost] = useState(post)
    const [isEditing, setIsEditing] = useState(false);
    const [content, setContent] = useState(post.content);

    const imageHostUrl = CloudflareImageHelper.imageUrl;

    const sConnectionController = new SocialConnectionController();

    useEffect(() => {
        setMyPost(post);
    }, [post]);

    const handleChange = (e: any) => {
        const {name, value} = e.target;

        if (name === 'content') {
            setContent(value);
        }
    }

    const handleEditPost = async (postId: string) => {
        let post = {
            ...myPost,
            content: content,
        }

        return await sConnectionController.UpdatePost(postId, post).then((data: any) => {
            updatePost(post);
            setIsEditing(false);
        }).catch((error) => {
            console.error(error);
        })
    }

    return (
        <React.Fragment key={post.id}>
            <div className={`social-connection-single-post ${!post.isVisible ? 'deleted' : ''}`}>
                {/* Left side */}
                <div className='social-connection-single-post-user-image'>
                    <img src={post?.userImage ? `${imageHostUrl}${post?.userImage}/userProfileImage` : userProfileImage}
                         alt={`${post?.userName ? 's profile image' : 'User profile image'}'`}
                         className={`social-connection-single-post-user-image-reply ${!post.isVisible ? 'deleted' : ''}`}
                    />
                </div>

                {/* Center */}
                <div className="social-connection-single-post-content">
                    <div className="social-connection-single-post-header">
                        <h5 className={`social-connection-single-post-username ${!post.isVisible ? 'deleted' : ''}`}>
                            {post?.userName ? post?.userName : 'User Name Not Found'}
                        </h5>
                        <h5 className={`social-connection-single-post-date ${!post.isVisible ? 'deleted' : ''}`}>
                            {moment(post?.createdAt).format('MM/DD/YYYY')} {!post.isVisible ? <span>[Deleted]</span> : ''}
                        </h5>

                    </div>
                    <div className="social-connection-single-post-body">
                        <div className="social-connection-single-post-title-container">
                            <h4 className={`social-connection-single-post-title ${!post.isVisible ? 'deleted' : ''}`}>
                                {post?.name}
                            </h4>
                        </div>

                        {isEditing ? (
                            <div className="social-connection-editing-textarea-container">
                                <textarea className='social-connection-editing-textarea' name='content' value={content} onChange={handleChange} />
                            </div>
                        ) : (
                            <div className="social-connection-single-post-text-container">
                                <p className={`social-connection-single-post-text ${!post.isVisible ? 'deleted' : ''}`}>
                                    {post?.content}
                                </p>
                            </div>
                        )}

                        {isEditing && (
                            <div className="social-connection-edit-post-container">
                                <button className='btn-cbit-primary btn-social-connection-edit-post'
                                        onClick={() => handleEditPost(post.id)}>
                                    Save
                                </button>
                                <button className='btn-cbit-primary btn-social-connection-cancel-edit-post'
                                        onClick={() => setIsEditing(false)}>
                                    Cancel
                                </button>
                            </div>
                        )}

                    </div>
                </div>
                {/* User Toolbar */}
                <PostFunctionToolBar post={myPost}
                                     isEditing={isEditing}
                                     setIsEditing={setIsEditing}
                                     updatePost={updatePost}
                                     isPostPage={isPostPage}
                                     replyIndex={-1}
                />
            </div>

            {/* Replies */}
            {post?.replies.length > 0 && post?.replies.map((reply: any, index: number) => {
                return (
                    <SocialConnectionReplyItem key={index}
                                               post={post}
                                               reply={reply}
                                               replyIndex={index}
                                               updatePost={updatePost}
                    />
                )
            })}

            {/* Add Reply */}
            <AddReply post={post} updatePostReply={updatePost}/>
        </React.Fragment>
    )
}

export default SocialConnectionPostItemWithReply;