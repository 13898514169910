import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { UserController } from '../../../controllers/UserController';
import { UserModuleController } from '../../../controllers/UserModuleController';
import { EmptyReferral, IReferral } from '../../../models/Referral/Referral';
import { UserLesson } from '../../../models/lesson/UserLesson';
import { IUserBio, UserBio, UserProgressAndReferral } from '../../../models/module/UserProfile';
import { DeepCopy } from '../../../models/utility/DeepCopy';
import CustomAlert from '../../Utilities/CustomAlert';
import CustomModal from '../../Utilities/CustomModal';
import './UserManagement.css';
import UserManagementSearch from './UserManagementSearch';
import UserManagementTable from './UserManagementTable';
import UserProgress from './UserProgress';
import UserReferralApproval from './UserReferralApproval';

type UserManagementState = {
    users:UserProgressAndReferral[]
    originalUsers:UserProgressAndReferral[]
    isLoading:boolean    
    userToDelete:IUserBio | null
}

const userController = new UserController();
const userModuleController = new UserModuleController();

/**
 * Component for creating, updating, and deleting users
 */
const UserManagement = () => {
    const defaultState:UserManagementState = {
        users: [],
        originalUsers:[],
        isLoading:false,        
        userToDelete:null
    }

    const [state, setState] = useState(defaultState);

    const {users, isLoading, originalUsers} = state;

    const [isUnlockAlert, setIsUnlockAlert] = useState(false);

    const [isDeleteAlertOpen, setDeleteAlert] = useState(false);

    const [referralForApproval, setReferralForApproval] = useState(EmptyReferral);

    const [isReferralModalOpen, setIsReferralModalOpen] = useState(false);

    const [userProgressDisplay, setUserProgressDisplay] = useState<{currentUserLesson:UserLesson, userBio:UserBio} | null>(null);

    const [isUserProgressOpen, setIsUserProgressOpen] = useState(false);

    useEffect(() => {
        async function onComponentMount() {
            await getUsers();
        }
        onComponentMount();
    }, []);

    /**
     * Get a list of user bios
     */
    const getUsers = async () => {
        setState(prevState => ({...prevState, isLoading:true}));

        try {
            // const users = await userController.GetAllUserBio();
            const users:UserProgressAndReferral[] = await userController.GetUserProgressAndReferral();
                        
            setState(prevState => ({
                ...prevState,
                users,
                originalUsers:users,
                isLoading:false
            }));
            
        } catch (error) {
            console.error(error);
            toast.error("Failed to retrieve users");
            setState(prevState => ({...prevState, isLoading:false}));
        }
    }

    /**
     * Permanently deletes a user
     * TODO in the future you may want to disable a user instead
     */
    const deleteUser = async (userId:string) => {
        if(userId.length < 1) return;

        toast.loading("Deleting User...", {id:"deleteLoadToast"});
        
        setState(prevState => ({...prevState, isLoading:true}));
        try {
            await userController.DeleteUser(userId);    
        
            let updatedUsers:UserProgressAndReferral[] = DeepCopy.copy(state.users); 

            updatedUsers = updatedUsers.filter(user => user.id !== userId);

            toggleDeleteAlert(); 

            setState(prevState => ({
                ...prevState,
                users:updatedUsers,
                isLoading:false,
            }));

            toast.dismiss("deleteLoadToast");
            toast.success(`Successfully deleted user`);

        } catch (error) {
            toast.dismiss("deleteLoadToast");
            console.error(error);
            toast.error("Failed to delete user");
        }
    }

    const toggleDeleteAlert = () => (setDeleteAlert(!isDeleteAlertOpen));

    const toggleDeleteAlertAndSetUserToDelete = (userToDelete:IUserBio) => {              
        setState(prevState => ({...prevState, userToDelete}));
        toggleDeleteAlert(); 
    }

    const toggleReferralModal = () => {
        setIsReferralModalOpen(!isReferralModalOpen);
    }

    const openAndSetReferral = (referral:IReferral) => {
        setReferralForApproval(referral);
        toggleReferralModal();
    }

    const toggleUserProgress = () => {
        setIsUserProgressOpen(!isUserProgressOpen);
    }

    const openAndSetUserProgressDisplay = (currentUserLesson:UserLesson, userBio:UserBio) => {
        setUserProgressDisplay({currentUserLesson, userBio});
        toggleUserProgress();
    }

    /**
     * For children components to update user state
     */
    const updateUser = (users:UserProgressAndReferral[]) => {
        setState(prevState => ({
            ...prevState,
            users
        }));
    }

    /**
     * For children components to be able to update state
     * @param loading 
     */
    const updateIsLoading = (loading:boolean) => {
        setState(prevState => ({
            ...prevState,
            isLoading:loading
        }));
    }
    

    return (
        <>
            <div className='user-management-container cbit-container'>
                <div className='user-management-header-container'>
                    <h2 className='cbit-header'>
                        Users
                    </h2>
                    <Link to="/users/new" className="btn-cbit-primary">
                        Add User
                    </Link>
                </div>
                <div>
                    <UserManagementSearch 
                        users={originalUsers} 
                        filterUsers={updateUser}
                    />
                </div>
                <div>                    
                    {
                        <>
                            <UserManagementTable
                                isLoading={isLoading}
                                updateIsLoading={updateIsLoading}
                                users={users}
                                onDelete={toggleDeleteAlertAndSetUserToDelete} 
                                onReferralClick={openAndSetReferral} 
                                onUserProgressClick={openAndSetUserProgressDisplay}
                                getUsers={getUsers}
                            />
                        </>                        
                    }

                </div>
            </div>                
                <CustomAlert 
                    header={`ARE YOU SURE YOU WANT TO DELETE ${state.userToDelete?.firstName} ${state.userToDelete?.lastName}?`} 
                    text={`Once you click "Delete", the user will be deleted immediately.`} 
                    primaryBtnText={'Delete'} 
                    secondaryBtnText={'Cancel'} 
                    isOpen={isDeleteAlertOpen} 
                    primaryBtnSubmit={() => deleteUser(state.userToDelete ? state.userToDelete.id : "")} 
                    secondaryBtnSubmit={toggleDeleteAlert} 
                    toggleAlert={toggleDeleteAlert}
                />
                
            <CustomModal className='cbit-dialog' isOpen={isReferralModalOpen} toggle={toggleReferralModal}>
                <UserReferralApproval 
                    onReferralApproval={() => {toggleReferralModal(); getUsers();}}
                    onReferralDecline={() => {toggleReferralModal(); getUsers();}}
                    referral={referralForApproval} 
                />
            </CustomModal>
            <CustomModal className='cbit-dialog' 
                isOpen={isUserProgressOpen} 
                toggle={toggleUserProgress}
            >     
                {
                    userProgressDisplay && 
                    (
                        <UserProgress 
                            currentUserLesson={userProgressDisplay?.currentUserLesson} 
                            userBio={userProgressDisplay?.userBio}                
                        />
                    )
                }
            </CustomModal>
        </>
    )
}

export default UserManagement;