import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ContentCategoryController } from '../../../controllers/ContentCategoryController';
import { IAdditionalContent } from '../../../models/AdditionalContent/AdditionalContent';
import { ContentCategory, IContentCategory } from '../../../models/AdditionalVideoContent/ContentCategory';
import { DeepCopy } from '../../../models/utility/DeepCopy';
import { AdditionalContentService } from '../../../services/AdditionalContentService';
import { ApplicationState } from '../../../store';
import { CurrentUserState } from '../../../store/CurrentUser';
import '../../TherapistSupport/TherapistSupport.css';
import CustomAlert from '../../Utilities/CustomAlert';
import CustomModal from '../../Utilities/CustomModal';
import AdditionalContentForm from '../AdditionalContentForm/AdditionalContentForm';
import TherapistSupportPanel from './AdminTherapistSupportPanel';
import TherapistSupportView from './AdminTherapistSupportView/AdminTherapistSupportView';
import { sortModule } from '../../../models/utility/SortModule';
import AdminTherapistSupportLayout from "./layout/AdminTherapistSupportLayout";

const contentCategoryController = new ContentCategoryController();

type therapistSupportState = {
    selectedContentCategory:ContentCategory | null | undefined,
    selectedTag:string;
    contentCategories:ContentCategory[]
    categoryForDelete:ContentCategory | null 
}

const AdminTherapistSupport = () => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    const defaultState:therapistSupportState = {
        selectedContentCategory: null,
        selectedTag:"",
        contentCategories: [],
        categoryForDelete: null,        
    }

    const [state, setState] = useState(defaultState);

    const [isLoading, setIsLoading] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isTagEditorOpen, setIsTagEditorOpen] = useState(false);

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);

    const history = useHistory()

    const isAdmin = useMemo(() => {
        if(!userStore)
            return false;

        return userStore.isAdmin;
    },[userStore]);

    useEffect(() => {
        async function onComponentMount() {        
            if(!isAdmin) {
                history.replace("/home");
            }

            await getContentCategories();

            new AdditionalContentService().GetContentGroupByTagIds(["Tick"])
        }

        onComponentMount();
    },[]);

    /**
     * Get public content categories from the API and update the state
     */
    const getContentCategories = async () => {
        let categories = await contentCategoryController.GetAllPublicCategories();

        sortModule(categories, false);

        setState(prevState => ({
            ...prevState,
            contentCategories:categories,
            selectedContentCategory:categories[0]
        }));
    }

    /**
     * Sets a main or selected content category so we can know which category tags to show
     * @param id 
     * @returns 
     */
    const setSelectedContentCategory = (id:string) => {
        const selectedCategory = state.contentCategories.find(category => category.id === id);

        if(!selectedCategory) {
            toast.error("Could Not Retrieve Category");
            console.error("Content Category: " + id + " Could Not Be Found");
            return; 
        }

        setState(prevState => ({
            ...prevState,
            selectedContentCategory:selectedCategory
        }));
    }

    /**
     * Updates the selected content category state and not in the db
     */
    const updateSelectedContentCategory = (contentCategory:ContentCategory) => {
        setState(prevState => ({
            ...prevState,
            selectedContentCategory:contentCategory
        }));
    }

    const updateContentCategoryState = (contentCategories:ContentCategory[]) => {
        setState(prevState => ({
            ...prevState,
            contentCategories
        }));
    }

    /**
     * Adds to the content category list and update the database with a 
     * new content category
     * @param name 
     */
    const addToContentCategoryList = async (name:string) => {
        const order = state.contentCategories.length;

        let newContentCategory: ContentCategory | undefined = new ContentCategory({id:"", name, order, tags:[]});

        newContentCategory = await addContentCategoryToDatabase(newContentCategory);

        if(!newContentCategory)
            return;

        const contentCategoriesCopy:ContentCategory[] = DeepCopy.copy(state.contentCategories);

        contentCategoriesCopy.push(newContentCategory);

        setState(prevState => ({
            ...prevState,
            contentCategories:contentCategoriesCopy
        }));        
    }

    /**
     * Sends a content category to the API so it can be added to the database
     * @param contentCategory 
     * @returns 
     */
    const addContentCategoryToDatabase = async (contentCategory:ContentCategory) => {
        setIsLoading(true);
        try {
            contentCategory = await contentCategoryController.CreateContentCategory(contentCategory);

            return contentCategory;
            
        } catch (error) {
            toast.error("Failed To Create Category: " + contentCategory.name);
            console.error(error)
        }

        setIsLoading(false);
    }

    const editContentCategoryListItem = async (contentCategory:ContentCategory) => {
       const updatedContentCategory = await editContentCategory(contentCategory);

        if(!updatedContentCategory) {
            return;
        }

        const contentCategoryList:ContentCategory[] = DeepCopy.copy(state.contentCategories);

        const foundIndex = contentCategoryList.findIndex(category => category.id === contentCategory.id);

        if(foundIndex < 0){
            toast.error("Could Not Find Category To Update");
            return;
        }

        contentCategoryList[foundIndex] = updatedContentCategory;

        if(state.selectedContentCategory && state.selectedContentCategory.id === updatedContentCategory.id) {
            setState(prevState => ({
                ...prevState,
                selectedContentCategory:updatedContentCategory,
                contentCategories:contentCategoryList
            }));

            return;
        }

        setState(prevState => ({
            ...prevState,            
            contentCategories:contentCategoryList
        }));

    }

    const editContentCategory = async (contentCategory:ContentCategory): Promise<ContentCategory | undefined> => {
        setIsLoading(true);
        try {
            contentCategory = await contentCategoryController.UpdateContentCategory(contentCategory);
            return contentCategory;
        } catch (error) {
            toast.error("Failed to Update: " + contentCategory.name);
            console.error(error);
        }
        setIsLoading(false);
    }

    /**
     * Adds a tag to the content category 
     * @param tagId 
     */
    const addTagToContentCategory = async (tagId:string) => {
        tagId = tagId.trim().toLowerCase();

        const currentCategory:ContentCategory = DeepCopy.copy(state.selectedContentCategory);
        
        const foundTagIndex = currentCategory.tags.findIndex(tag => tag._id === tagId);

        if(foundTagIndex > -1) {
            toast.error("Cannot Create Tag, It Already Exists");
            return;
        }

        currentCategory.tags.push({_id: tagId});

        const categories:IContentCategory[] = DeepCopy.copy(state.contentCategories);

        const foundIndex = categories.findIndex(category => category.id === currentCategory.id);

        if(foundIndex === -1) {
            toast.error("Something Went Wrong When Creating A Tag. Please Refresh The Page.");
            return;
        }

        categories[foundIndex] = currentCategory;

        setState(prevState => ({
            ...prevState,
            selectedContentCategory:currentCategory,
            contentCategories:categories
        }));

        await contentCategoryController.UpdateContentCategory(currentCategory);
    }

    /**
     * updates a tag in the current selected category
     * @param tagId 
     */
    const updateTagInSelectedCategory = async (oldTagId:string, tagId:string) => {
        if(tagId.length < 1) {
            toast.error("Invalid Input For Tag Name");
            return; 
        }

        tagId = tagId.trim().toLowerCase();        

        const currentCategory:ContentCategory = DeepCopy.copy(state.selectedContentCategory);
        
        const tagThatAlreadyExists =  currentCategory.tags.find(tag => tag._id === tagId);

        if(tagThatAlreadyExists) {
            toast.error("A Tag With That Name Already Exists");
            return;
        }

        const foundTagIndex = currentCategory.tags.findIndex(tag => tag._id === oldTagId);

        if(foundTagIndex < 0) {
            toast.error("The Tag You Are Trying To Update Do Not Exists");
            return;
        }

        currentCategory.tags[foundTagIndex]._id = tagId;

        const categories:IContentCategory[] = DeepCopy.copy(state.contentCategories);

        const foundIndex = categories.findIndex(category => category.id === currentCategory.id);

        if(foundIndex === -1) {
            toast.error("Something Went Wrong When Creating A Tag. Please Refresh The Page.");
            return;
        }

        categories[foundIndex] = currentCategory;

        setState(prevState => ({
            ...prevState, 
            selectedContentCategory:currentCategory,
            contentCategories:categories

        }));

        await contentCategoryController.UpdateContentCategory(currentCategory);
    }

    /**
     * Removes a content category from the db through the api
     * @param categoryId 
     */
    const deleteContentCategoryFromDB = async (categoryId:string) => {
        try {
            toast.loading("Deleting Category", {id: "categoryDeleteToastId"});
            await contentCategoryController.DeleteContentCategory(categoryId);
            
        } catch (error) {
            console.error(error);
            toast.error("Failed To Delete Category")
        }
    }

    /**
     * Removes a content category from our state list of categories
     * @returns 
     */
    const deleteContentCategoryFromList = async () => {
        if(!state.categoryForDelete) {
            toast.error("Cannot Delete Category");
            return;
        }

        const categoryToDeleteCopy:ContentCategory = DeepCopy.copy(state.categoryForDelete);

        await deleteContentCategoryFromDB(categoryToDeleteCopy.id);

        const categoriesCopy:ContentCategory[] = DeepCopy.copy(state.contentCategories);

        const updatedCategories = categoriesCopy.filter(category => category.id !== categoryToDeleteCopy.id);

        setState(prevState => ({
            ...prevState, 
            contentCategories:updatedCategories,
            selectedContentCategory:updatedCategories[0]
        }));

        toast.dismiss("categoryDeleteToastId");

        toast.success("Successfully Deleted Category");

        toggleDeleteAlert();
    }

    /**
     * Open or close the delete alert
     * @returns 
     */
    const toggleDeleteAlert = () => setIsDeleteAlertOpen(!isDeleteAlertOpen);

    /**
     * Set the category we are going to delete when the delete alert is submitted
     * @param contentCategory 
     */
    const handleSettingCategoryToDelete = (contentCategory:ContentCategory) => {
        setState(prevState => ({...prevState, categoryForDelete:contentCategory}));
    }


    const handleDeleteTagInCategory = async (tagToDelete:string) => {
        const selectedCategoryCopy:ContentCategory = DeepCopy.copy(state.selectedContentCategory);

        selectedCategoryCopy.tags = selectedCategoryCopy.tags.filter(tag => tag._id !== tagToDelete);

        const updatedCategory = await contentCategoryController.UpdateContentCategory(selectedCategoryCopy);

        const categories:IContentCategory[] = DeepCopy.copy(state.contentCategories);

        const foundIndex = categories.findIndex(category => category.id === selectedCategoryCopy.id);

        if(foundIndex === -1) {
            toast.error("Something Went Wrong When Creating A Tag. Please Refresh The Page.");
            return;
        }

        categories[foundIndex] = selectedCategoryCopy;

        setState(prevState => ({
            ...prevState,
            selectedContentCategory:updatedCategory,
            contentCategories:categories
        }));
    }

    const toggleTagEditor = async () => {
        setIsTagEditorOpen(!isTagEditorOpen);
    }

    const submitAdditionalContent = (additionalContent:IAdditionalContent) => {
        
        //TODO add to our array
        
        toggleModal();
    }

    /**
     * Toggles the modal to open or close
     */
    const toggleModal = () => {
        const isModalOpenCopy = !isModalOpen;

        setIsModalOpen(isModalOpenCopy);
    }

    return (
        <AdminTherapistSupportLayout isAdmin={isAdmin}
                                     contentCategories={state.contentCategories}
                                     selectedContentCategory={state.selectedContentCategory}
                                     selectContentCategory={setSelectedContentCategory}
                                     addNewContentCategory={addToContentCategoryList}
                                     editContentCategory={editContentCategoryListItem}
                                     toggleDeleteAlert={toggleDeleteAlert}
                                     setCategoryForDelete={handleSettingCategoryToDelete}
                                     updateContentCategoryState={updateContentCategoryState}>

                    {
                        state.selectedContentCategory == null ?
                        (
                            <h2 className="category-title">No Categories Have Been Created</h2>
                        ) : (
                            <>
                                <TherapistSupportView 
                                    selectedContentCategory={state.selectedContentCategory}
                                    handleAddingTagToCategory={addTagToContentCategory}
                                    handleUpdatingTagInCategory={updateTagInSelectedCategory}
                                    isTagEditorOpen={isTagEditorOpen}
                                    toggleTagEditor={toggleTagEditor}
                                    handleDeletingTagInCategory={handleDeleteTagInCategory}
                                    isAdmin={isAdmin}
                                    updateSelectedCategory={updateSelectedContentCategory}
                                />
                            </>
                        )
                    }

            <CustomModal isOpen={isModalOpen} toggle={toggleModal}>
                <AdditionalContentForm 
                    defaultSelectedTags={state.selectedTag}
                    onFormSubmit={submitAdditionalContent}
                />
            </CustomModal>
            <CustomAlert 
                header={'ARE YOU SURE YOU WANT TO DELETE: ' + state.categoryForDelete?.name} 
                text={'Once you click "Delete", the category will be deleted immediately.'} 
                primaryBtnText={'DELETE'} 
                secondaryBtnText={'CANCEL'} 
                isOpen={isDeleteAlertOpen} 
                primaryBtnSubmit={deleteContentCategoryFromList} 
                secondaryBtnSubmit={toggleDeleteAlert} 
                toggleAlert={toggleDeleteAlert}
            />
        </AdminTherapistSupportLayout>
    )
}

export default AdminTherapistSupport;