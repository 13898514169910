import React, { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import useModal from '../../../hooks/UseModal';
import { IUserBio, UserBio } from '../../../models/module/UserProfile';
import UserProfile from '../../UserDashboard/UserProfile/UserProfile';
import CustomModal from '../../Utilities/CustomModal';
import AttendeeList from './AttendeesList/AttendeeList';
import './ConsultationAttendeesDetails.css';

type ConsultationAttendeesDetailsProps = {
    attendees:UserBio[]
    isEditingAttendeeList?:boolean
    removeAttendee?:(id:string) => void
}

/**
 * List up to 4 attendees in a consultation and opens up a modal to display 
 * all attendees
 */
const ConsultationAttendeesDetails = (props:ConsultationAttendeesDetailsProps) => {    
    const {
        attendees,
        removeAttendee
    } = props;

    const [isModalOpen, ,toggleModal] = useModal(false); 
    const [isUserOpen, setIsUserOpen] = useState(false); 
    const [selectedUser,setSelectedUser] = useState<IUserBio | null>(null);

    /**
     * opens and close the user modal
     */
    const toggleUserModal = () => {
        setIsUserOpen(!isUserOpen);
    }

    /**
     * Update selected user and open the user profile modal
     */
    const updateAndOpenUser = (selectedUser:IUserBio) => {
        setSelectedUser(selectedUser);
        setIsUserOpen(true);
    }

    return (
        <>
            {
                attendees.length <= 0 ?
                (
                    <div className='cbit-row'>
                        <div className="cbit-column view-details-text-container">
                            No attendees found
                        </div>
                    </div>
                )
                :
                (
                    <ul className='attendees-list'>
                        {attendees.map( (attendee, index) => (
                            <React.Fragment key={uuidV4()}>                                
                                <li className='attendees-list-item'>
                                    {
                                        <button className="btn-cbit-link" onClick={() => updateAndOpenUser(attendee)}>
                                            {`${attendee.firstName} ${attendee.lastName}`}
                                        </button>
                                    }
                                    {
                                        removeAttendee != null && (
                                            <>
                                                {" "}
                                                <button 
                                                    type='button' 
                                                    className="btn-cbit-text"
                                                    onClick={() => removeAttendee(attendee.id)}
                                                    >
                                                    remove
                                                </button>
                                            </>
                                        )       
                                    }
                                </li>
                            </React.Fragment>                                                    
                        ))}
                    </ul>  
                )
            }                              
            <CustomModal 
                isOpen={isModalOpen}
                toggle={toggleModal}
            >
               <AttendeeList attendees={attendees}/>
            </CustomModal>
            <CustomModal
                isOpen={isUserOpen}
                toggle={toggleUserModal}
            >
                <div className="cbit-dialog review-user-profile attendee-detail-profile">
                    <UserProfile userBio={selectedUser ?? new UserBio} />
                </div>
            </CustomModal>
        </>
    )
}

export default ConsultationAttendeesDetails;