import React, { useEffect, useMemo, useRef, useState } from "react";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import * as L from 'leaflet';
import { IReferral } from "../../../models/Referral/Referral";
import 'leaflet/dist/leaflet.css';
import "./ReferralMap.css";
import { UsTerritories } from "../../../models/utility/StateDictionary";
import { StringHelper } from "../../../models/utility/StringHelper";

type ReferralMapProps = {
    referrals:IReferral[]
    selectedReferralId:string
    updateSelectedReferralId:(referralId:string) => void;
}

//We need to declare this obj outside the component to prevent the map is already initialized error

type MarkerDictType = {
    [key:string]:L.Marker
}

const ReferralMap = (props:ReferralMapProps) => {
    const {
            referrals,
            selectedReferralId,
            updateSelectedReferralId
    } = props;
    
    let map:null|L.Map = useMemo(() => (null), [referrals])

    const tileLayer = 'https://tile.openstreetmap.org/{z}/{x}/{y}.png';
    const attribution = '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>';
    
    const mapRef = useRef<HTMLDivElement>(null);

    //These coordinates came from Google
    const usMapView = {
        lon:37.0902,
        lat:-95.7129
    }
    
    const [markersDict, setMarkersDict] = useState<MarkerDictType>({});

    useEffect(() => {
        function onStatesDataChange() {            
            createTileLayer();
        }        
        
        onStatesDataChange();        
    },[referrals]);
    
    useEffect(() => {
        function onSelectedReferralIdChange() {
            
        }
        if(!StringHelper.IsNullOrWhiteSpace(selectedReferralId)) {
            const marker = markersDict[selectedReferralId];

            if(!marker) {
                return;
            }
            
            marker.openPopup();
        }
        onSelectedReferralIdChange();
    }, [selectedReferralId])

    /**
     * Keeps the map centered on the coordinates of the selected referrals
     * @param referrals IReferral[]
     * @returns array of coordinates
     */
    const handleFitBounds = (referrals: IReferral[]) => {
        let bounds:any[] = [];

        referrals.map((referral) => {
          if (referral.latitude === 0 || referral.longitude === 0) {
            return;
            }

          if (referral.isContactPrivate) {
            return;
          }

          bounds.push([referral.latitude, referral.longitude]);
        });

        return bounds;
    };

    /**
     * Creates the tile layer for the leaflet map.
     * We are using a ref here to make sure that the div is 
     * rendered before we create the tiles    
     * @returns 
     */
    const createTileLayer = () => {                
        if(!mapRef.current) {
            return;
        }

        if(map != null) {
            map.remove();
        }

        var usMaxBounds = L.latLngBounds(
            L.latLng(5.499550, -167.276413), //Southwest
            L.latLng(83.162102, -52.233040)  //Northeast
        );

        const leafletMap =  L.map('leafletMap', {
            // center: [0,0],
            // zoom:0,   
            // maxBounds:usMaxBounds,           
            //The value does not matter, we have to set zoomSnap to a fraction in order to use the zoomSnap functionality
            zoomSnap:0.25, 
            zoomControl:false
        })
        .setView([37.8, -96], 4.6);

        map = leafletMap;

        if (referrals.length > 0) {

            let incReferals = handleFitBounds(referrals);

            // @ts-ignore
            L.latLngBounds(incReferals);

            L.tileLayer(tileLayer, {
                maxZoom: 19,
                attribution,
            }).addTo(leafletMap);

            createMarkers(leafletMap);

            // map.flyToBounds(incReferals, { padding: [50, 50] ,maxZoom: 12} );
        } else {
            map.setView([37.8, -96], 4.6);

        }

        L.tileLayer(tileLayer, {
            maxZoom: 19,
            attribution,            
        }).addTo(leafletMap);      

        createMarkers(leafletMap);
    }

    const createMarkers = (leafletMap:L.Map) => {        
        //Note: we need to set and icon size and anchor to prevent the marker from moving when zooming
        const defaultIcon = L.icon({
            iconUrl: icon,
            shadowUrl: iconShadow,
            iconSize: [24, 36],
            iconAnchor: [12, 36],
            className:"referral-map-icon"
        });    

        L.Marker.prototype.options.icon = defaultIcon;        
        
        for(let referral of referrals) {
            if(referral.latitude === 0 || referral.longitude === 0) {
                continue;
            }

            if(referral.isContactPrivate) {
                continue;
            }

            const marker = L.marker([referral.latitude, referral.longitude], {title: `marker-${referral.id}`}).addTo(leafletMap)
            
            setMarkersDict((prevState) => ({
                ...prevState,
                [referral.id]:marker
            }));

            marker.on('click', () => {
                window.location.href = `/referral/list#${referral.name.split(" ").join("")}${referral.id.slice(-2)}`;
                //if you want to zoom in to the map
                // leafletMap.flyTo([referral.latitude, referral.longitude], 16, {animate: true, duration: 1});
            }).bindPopup(createPopup(referral))
            
            marker.bindTooltip(referral.name);

            //The remove action is ran when the popup is closed
            marker.getPopup()?.on("remove", () => {
                //when the popup is closed we want to set the selected referral id to empty 
                //(fixes the already clicked when closed bug)
                updateSelectedReferralId("");
            })
            
        }
    }

    /**
     * Because bindPopup only accepts a string and "renderToString" is not recommend by react: https://react.dev/reference/react-dom/server/renderToString
     * we are creating the component as regular html instead of a jsx component. 
     * Also, since we are on an old version of react we cannot use "createRoot" which is recommend by React
     * @param referral 
     */
    const createPopup = (referral:IReferral) => {
        const {
                name,
                occupation,
                isContactPrivate,
                streetName
        } = referral;
        return (
            `<div class="referral-map-popup">
                <h4 class="cbit-small-header text-ellipse">${referral.name}</h4>
                <div class="italicize-text text-ellipse">${referral.occupation}</div>
                <div class="referral-popup-address ">
                ${referral.isContactPrivate ? 
                    ("Private") 
                    : 
                    (
                        ` <div class="text-ellipse">${referral.streetName}</div>
                        <div class="text-ellipse">${formatCityWithStateAndZip(referral)}</div>`
                    )
                }  
                </div>
            </div>`
        )
    }

        /**
     * Format text in the format of "Houston, TX 77006"
     */
        const formatCityWithStateAndZip = (referral:IReferral) => {            
            const {state, city, zip} = referral;
            const capitalizeCity = StringHelper.capitalizeWord(city, true);
            let abbreviatedState = UsTerritories.stateToAbbreviation[StringHelper.capitalizeWord(state) as keyof typeof UsTerritories.stateToAbbreviation]
            abbreviatedState = StringHelper.capitalizeWord(abbreviatedState, true);
    
            return `${capitalizeCity}, ${abbreviatedState} ${zip}`
        }

    return (
        <div ref={mapRef} id="leafletMap"></div>
    )
}

export default ReferralMap;