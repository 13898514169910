import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Consultation, IConsultation } from '../../../../models/consultations/Consultation';
import { IConsultationSession } from '../../../../models/consultations/ConsultationSession';
import { ConsultationStatus } from '../../../../models/consultations/ConsultationStatus';
import { ConsultationType, ConsultationUIType } from '../../../../models/consultations/ConsultationType';
import { ApplicationState } from '../../../../store';
import { CurrentUserState } from '../../../../store/CurrentUser';
import CustomModal from '../../../Utilities/CustomModal';
import './AvailableSessionItem.css';
import BookAndWithdrawView from './BookAndWithdrawView';
import ConsultationPayment from '../../../Payment/ConsultationPayment';
import { ConsultationController } from '../../../../controllers/ConsultationController';

type AvailableSessionItemProps = {
    consultationSession:IConsultationSession
    updateConsultations:(bookedConsultation:IConsultation, isCanceling?:boolean) => void
}

type AvailableSessionItemState = {
    isBookingModalOpen:boolean
    isPaymentOpen:boolean
}

const consultationController = new ConsultationController();

/**
 * A Session item component that contains info about the consultation session
 * @returns 
 */
const AvailableSessionItem = (props:AvailableSessionItemProps) => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    const {consultationSession: {consultation, consultant}, updateConsultations} = props;

    const defaultState:AvailableSessionItemState = {
        isBookingModalOpen: false,
        isPaymentOpen:false
    }

    const [state, setState] = useState(defaultState);

    const [isSuccessAlertOpen, setSuccessAlert] = useState(false);

    const toggleBookingModal = () => (setState(prevState=> ({...prevState, isBookingModalOpen:!state.isBookingModalOpen})));

    const togglePaymentModal = () => (setState(prevState=> ({...prevState, isPaymentOpen:!state.isPaymentOpen})));

    const handleOnSessionItemClick = () => {
        toggleBookingModal();
    }

    const getConsultationTypeColor = (consultationType:ConsultationType) => {        
        const consultationColors = {
            [ConsultationType.OneOnOne]: "individual-color",
            [ConsultationType.SmallGroup]: "group-color",
            [ConsultationType.Seminar]: "seminar-color ",
            [ConsultationType.Unknown]: "",
        }

        return consultationColors[consultationType];
    }
    
    /**
     * Open or closes the success booking modal
     */
    const toggleSuccessBooking = () => {         
        setSuccessAlert(!isSuccessAlertOpen);
    }

    /**
     * Show how many seats are left over, if consultation if full or if a user
     * has already booked the consultation
     */
    const showCurrentStatusOfConsultation = () => {
        if(!userStore) return;
            
        if(Consultation.CheckIfConsultationIsBookedByUser(consultation, userStore.userProfile.id)) {
            return "Booked"
        }

        const spaceRemaining = Consultation.GetSpaceRemaining(consultation);
        if(consultation.status === ConsultationStatus.ActiveFull || spaceRemaining <= 0) {
            return "full";
        }

        if(spaceRemaining === 1) {
            return `${spaceRemaining} seat remaining`

        }

        return `${spaceRemaining} seats remaining`

    }

    const getCssClassBasedOnConsultationStatus = () => {
        const status = showCurrentStatusOfConsultation();

        if(status === "full") return "full-session";

        return "";
    }

    const removeUserFromReserveOnClose = () => {
        consultationController.RemoveUserFromReserveList(consultation.id);
        togglePaymentModal();
    }

    return (
        <>
            <div className={`available-session-item ${getCssClassBasedOnConsultationStatus()}`} onClick={handleOnSessionItemClick}>
                <div className={`available-consultation-type ${getConsultationTypeColor(consultation.type)}`}>
                    {ConsultationUIType[consultation.type]}
                </div>

                <div className="available-consultation-time">
                    {Consultation.GetConsultationTime(consultation)}
                </div>
                <div className="available-consultation-status">
                    {showCurrentStatusOfConsultation()}
                </div>
            </div>
            <CustomModal 
                isOpen={state.isBookingModalOpen} 
                toggle={toggleBookingModal}
            >
                <BookAndWithdrawView 
                    consultationSession={props.consultationSession} 
                    toggleModal={toggleBookingModal}
                    togglePayment={togglePaymentModal} 
                    toggleSuccessBooking={toggleSuccessBooking}
                    updateConsultations={updateConsultations}
                />
            </CustomModal>
            <CustomModal
                isOpen={state.isPaymentOpen}
                toggle={removeUserFromReserveOnClose}
            >
                <ConsultationPayment 
                    consultation={consultation}
                    consultantName={`${consultant.firstName} ${consultant.lastName}`}
                    togglePaymentModel={togglePaymentModal} 
                />
            </CustomModal>
        </>
    )
}

export default AvailableSessionItem;