import * as React from 'react';
import InputMask from 'react-input-mask';
import './InlineForm.css';

type ModuleItemFormProps = {     
    field:string
    placeHolderText:string    
    onSubmit(field:string) : void
    onBlur(isEditing:boolean) :void
}

// The module form state type 
type ModuleFormState = {
    field:string, 
    isLoading: boolean,
    errors: string[] // Make as an object
}

/**
 * This component is for inline editing Module and Lesson names in the Admin dashboard
 */
class InlineForm extends React.Component<ModuleItemFormProps, ModuleFormState> {
    private placeholderText = this.props.placeHolderText;    

    /**
     *
     */
    constructor(props:any) {
        super(props);
        this.state = this.defaultState;        
    }
    
    /**
     * The default settings for the inlineForm
     */
    private defaultState : ModuleFormState = {
        field: this.props.field,
        isLoading:false,
        errors: []
    }

    /**
     * 
     * @param e 
     */
    private onChange = (e: React.ChangeEvent<HTMLInputElement>) : void => {
        
        const inputName : string = e.currentTarget.name;

        const inputValue : string = e.currentTarget.value;

        this.setState(prevState => ({
            ...prevState,
                field: inputValue
            }));
    }

    private onSubmit = async (e: React.KeyboardEvent<HTMLInputElement>) : Promise<void> => {
        const {field} = this.state;

        if(this.state.field.length <= 0)
            return;

        if(e.key === "Enter") {            
            // despite what your IDE may say the await is needed to stop the flashing of the form or empty content when creating new content
            await this.props.onSubmit(field);
            
            this.props.onBlur(false);                        
        }
    }

    private handleFocus = (event:any) => event.target.select();

    public render(){
        const {field} = this.state;
        return (
            <>
            <InputMask                 
                autoFocus          
                placeholder={this.placeholderText} 
                className='combo-lesson-inline-module-input' 
                id='Inline-Field' 
                name={field} 
                value={field}
                onChange={this.onChange}
                onKeyPress={this.onSubmit} 
                mask="99:99"
                onFocus={this.handleFocus}
                onBlur={() => this.props.onBlur(false)}  
            />
        </>
        )
    }
}

export default InlineForm;

