import * as React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import DOMPurify from 'dompurify';
import './TextEditor.css';

/**
 * Enum of available themes for the text editor
 */
enum TextEditorTheme {
    snow = "snow",
    bubble = "bubble"
}

/**
 * Enum for the formats the editor will take be default
 */
enum TextEditorFormats {
    header = "header",
    bold = "bold",
    italic = "italic",
    uderline = "underline",
    strike = "strike",
    blockquote = "blockquote",
    font = "font",
    size = "size",
    list = "list",
    bullet = "bullet",
    indent = "indent",
    // image = "image",
    link = "link",
    align="align"
}

/**
 * Required props for the Text Editor
 */
type TextEditorProps = {
    placeHolder:string;
    formats:string[];
    toolbar:any[];
}

/**
 * The TextEditor's state properties
 */
type TextEditorState = {
    editorHTML: string;
    theme: TextEditorTheme;
}

/**
 * The default state for how the initial TextEditor should be
 */
const TextEditorDefaultState : TextEditorState = {
    editorHTML: '',
    theme: TextEditorTheme.bubble
}

/**
 * Default props for the TextEditor that provides a placeholder,
 * default formats, and the default toolbar.
 * To give the TextEditor a different toolbar or formats, just passin 
 * your own toolbar/formats props to the text editor 
 */
const TextEditorDefaultProps : TextEditorProps = {
    placeHolder: 'Write something...',
    formats: Object.entries(TextEditorFormats).map(([key, value]) => { return value; }),
    toolbar: [
        // [
        //     {
        //         // [TextEditorFormats.header]: [1, 2, false],
        //         [TextEditorFormats.header]: 1, // The number represents the header type h1, h2, etc
        //     },
        //     {
        //         [TextEditorFormats.header]: 2
        //     },
        //     {
        //         [TextEditorFormats.font]: [] // We are saying use the default fonts of the browser
        //     }
        // ],
        // [
        //     {
        //         [TextEditorFormats.size]: [] //Give sizes for the font from small to huge
        //     }
        // ],
        [
            TextEditorFormats.bold,
            TextEditorFormats.italic,
            TextEditorFormats.uderline,
            TextEditorFormats.strike,
            TextEditorFormats.blockquote,
        ],
        [            
            {
                [TextEditorFormats.align]: '' 
            },
            {
                [TextEditorFormats.align]: 'center'
            },
            {
                [TextEditorFormats.align]: 'right'  
            }
        ],

        [
            TextEditorFormats.link,
            // TextEditorFormats.image
        ],

        [
            {
                [TextEditorFormats.list]: TextEditorFormats.bullet
            },

            {
                [TextEditorFormats.list]: 'ordered'
            },

            // {
            //     [TextEditorFormats.indent]: '-1' // How much we want the indent to be
            // },
            // {
            //     [TextEditorFormats.indent]: '+1'
            // }
        ],
        [
            {
                [TextEditorFormats.size]: "30px"
            }
        ]

        // [
        //     'clean' //Turns the formated text back to normal
        // ]
    ],
}

/**
 * The TextEditor component that uses React Quill to allow users to be able to create
 * Formated text content.
 * Make sure you santize the editorHTML value before rendering on a page or saving to a db
 */
class TextEditor extends React.Component<TextEditorProps & {editorValue:string, onChange(value:any):void}, TextEditorState> {
    // The defaultProps field get map to props, so that is why we can use this.props with default props
    static defaultProps : TextEditorProps = TextEditorDefaultProps;

    private QuillRefObj : any;

    constructor(props:any) {
        super(props);
        this.state = TextEditorDefaultState;
    }

    /**
     * Updates the state when users type into the text editor
     * @param value 
     */
    private handleChange = (value: any, delta: any, source: any, context: any) => {
        
        const purify = DOMPurify(window);
        
        let cleanHTML = purify.sanitize(value, {USE_PROFILES: {html:true, svg: true}}); 
        
        this.props.onChange(cleanHTML);
    } 
    
    public render() {
        const {editorValue, placeHolder, toolbar, formats} = this.props;
        return (              
            <ReactQuill 
                ref={(element) => {this.QuillRefObj = element}}                
                theme='snow' 
                value={editorValue}                 
                onChange={this.handleChange} 
                placeholder={placeHolder} 
                modules={{toolbar: toolbar}}
                formats={formats}
            />                                        
                
        )
    }
}

export default TextEditor;