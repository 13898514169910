import React, { useEffect, useState } from 'react';
import { LessonController } from '../../../../../controllers/LessonController';
import LessonContentView from '../LessonContentView/LessonContentView';
import { AdminViewProps } from '../TopModuleView/TopModuleView';

type ComboLessonViewProps = { 
    topMostModuleId:string
} & AdminViewProps;

const lessonController = new LessonController();

const ComboLessonView = (props:ComboLessonViewProps) => {
    const {
        contentList, 
        updatedContentList, 
        updateIsLoading, 
        selectedModuleId, 
        topMostModuleId,
        contentType,
        navigateTo,
        isLoading,
        updateAccordion,
        removeAccordionItem,
        onDeleteConfirm,
        updateUnSavedContent,
        addToAccordion,
        selectedModule,
        parentModule,
        forceRerender,
        isVisible,
        isExpanded
    } = props;
    
    const failedFetchErrMsg = "Failed to retrieve Combo Lesson items"

    const failedUpdateErrMsg = "Failed to update Combo Lesson item"

    const [tagListState, setTagList] = useState(undefined);

    useEffect(() => {
        async function getComboLesson() {
            try {
                const lesson = await lessonController.GetLesson(props.selectedModuleId);
                //@ts-ignore
                setTagList(lesson.tagList[0]);

            } catch (error) {
                console.error(error);
            }
        }
        getComboLesson();
    }, [contentList]);

    return (
        <LessonContentView
            contentList={contentList}
            updateIsLoading={updateIsLoading}
            updatedContentList={updatedContentList}
            selectedModuleId={selectedModuleId}
            contentType={contentType}
            navigateTo={navigateTo}
            isLoading={isLoading}
            addToAccordion={addToAccordion}
            updateAccordion={updateAccordion}
            onDeleteConfirm={onDeleteConfirm}
            removeAccordionItem={removeAccordionItem}
            updateUnSavedContent={updateUnSavedContent}
            topMostModuleId={topMostModuleId} 
            failedFetchErrMsg={failedFetchErrMsg}      
            failedUpdateErrMsg={failedUpdateErrMsg}
            selectedModule={selectedModule}
            parentModule={parentModule}  
            forceRerender={forceRerender}  
            isVisible={isVisible}   
            isExpanded={isExpanded}       
            tagId={tagListState}
        />
    )
}

export default ComboLessonView;