import { GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { IUserBio, UserBio } from "../../../../models/module/UserProfile";
import UserProfile from "../../../UserDashboard/UserProfile/UserProfile";
import CbitDataGrid from "../../../Utilities/CbitTable/CbitDataGrid";
import CustomModal from "../../../Utilities/CustomModal";
import "./AttendeeList.css";

type AttendeeListProps = {
    attendees:IUserBio[]
    isReview?:boolean
}

type AttendeesTableItem = {
    userBio:IUserBio
}

/**
 * List for displaying attendees in a consultation
 * @param props 
 * @returns 
 */
const AttendeeList = (props:AttendeeListProps) => {
    const {attendees, isReview} = props;

    const [attendeesForTable, setAttendeesForTable] = useState<AttendeesTableItem[]>(attendees.map(attendee => ({userBio: attendee})));

    const [selectedUser, setSelectedUser] = useState<UserBio | null>(null);

    const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);    

    /**
     * Toggles a modal to display more information about the selected user
     * @param userBio 
     */
    const openUserProfileAndSetUser = (userBio:UserBio) => {
        setSelectedUser(userBio);
        setIsUserProfileOpen(!isUserProfileOpen);        
    }

    const attendeeColumns:GridColDef[] =[
        {
            field: 'fullName', 
            headerName: 'Full Name',
            headerClassName: 'user-table-header',             
            flex:1,            
            renderCell: (cell:any) => {                
                const user:IUserBio = cell.row;

                return (
                    <button onClick={() => openUserProfileAndSetUser(user)} className="btn-cbit-link">{`${user.firstName} ${user.lastName}`}</button>
                )
            }
        },        
        {
            field: 'email',
            headerName: 'Email',            
            headerClassName: 'user-table-header',
            flex:1,
        },
    ];

    return (
        <>
        <div className="attendee-list cbit-dialog">
            <h2 className="cbit-header">Attendees</h2>            
            <CbitDataGrid 
                columns={attendeeColumns} 
                rowData={attendees} 
                noRowsText={"No Attendees Found"} 
                isLoading={false}
            />            
            <CustomModal 
                isOpen={isUserProfileOpen} 
                toggle={() => setIsUserProfileOpen(!isUserProfileOpen)}
                >
                {
                    selectedUser && (
                        <div className="attendee-list-user-profile cbit-dialog">
                            <UserProfile 
                                userBio={selectedUser} 
                                />
                        </div>
                    )
                }
            </CustomModal>
        </div>
        </>
    )
}

export default AttendeeList;