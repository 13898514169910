import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ICurrentUser } from '../../../models/module/UserProfile';
import { ApplicationState } from '../../../store';
import { CurrentUserState } from '../../../store/CurrentUser';

/**
 * Admin only links for the navigation menu
 */
const AdminNavLinks = () => {
    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state )=> state.currentUser);

    return (
        <>
            { UserStore && (
                <>
                    {
                        UserStore.isAdmin || UserStore.isSuperAdmin ? (
                            <>
                                <NavLink 
                                    activeClassName='is-active' 
                                    className="nav-item-text" 
                                    to="/admin-tools"
                                    >
                                    Admin&nbsp;Tools
                                </NavLink>
                            </>
                        )
                        :
                        (
                            <>                            
                            </>
                        )
                    }
                </>
            ) }
        </>
    )

}

export default AdminNavLinks;