import moment from "moment";
import React, { useState, useEffect } from "react";
import { ConsultationReviewController } from "../../../controllers/ConsultationReviewController";
import { Consultation } from "../../../models/consultations/Consultation";
import { ConsultationSession } from "../../../models/consultations/ConsultationSession";
import TimeHelper from "../../../models/utility/TimeHelper";
import CustomModal from "../../Utilities/CustomModal";
import ConsultationReviewForm from "./ConsultationReviewForm";

const consultationReviewController = new ConsultationReviewController();

const ConsultationReviewChecker = () => {
    const [session, setSession] = useState<ConsultationSession | null>(null);
    
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);    
    
    const haveSeenConsultationReviewKey =  "haveSeenConsultationReview";    

    useEffect(() => {
        async function onComponentMount() {
            const haveSeenReview = sessionStorage.getItem(haveSeenConsultationReviewKey);

            // if(haveSeenReview != null) {
            //     return;
            // }

            await checkForConsultationReview();
        }
        onComponentMount();    
    },[]);


    const toggleReviewModal = () => {
        setIsReviewModalOpen(!isReviewModalOpen);
    }

    const checkForConsultationReview = async () => {        
        const session = await consultationReviewController.CheckIfUserNeedsToReview(moment(new Date()).format());
        if(session) {
            setSession(session);
            toggleReviewModal();
        } 
        sessionStorage.setItem(haveSeenConsultationReviewKey, JSON.stringify(true));        
    }
    return (
        <>
            {
                session && (
                    <CustomModal                         
                        isOpen={isReviewModalOpen} 
                        toggle={toggleReviewModal}
                    >
                        <ConsultationReviewForm 
                            consultationSession={session}
                            onSubmit={toggleReviewModal}
                        />              
                    </CustomModal>
                )
            }
        </>
    )
}

export default ConsultationReviewChecker;