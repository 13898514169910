import DOMPurify from 'dompurify';
import React, { useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ArtifactController } from '../../controllers/ArtifactController';
import FileArtifact from '../../models/FileArtifact/FileArtifact';
import { ApplicationState } from '../../store';
import { CurrentUserState } from '../../store/CurrentUser';

type downloadPageParams = {
    id:string
}



const artifactController = new ArtifactController();

const DownloadPdfPage = () => {

    const userStore = useSelector<ApplicationState, CurrentUserState | undefined> (store => store.currentUser);

    const {id} = useParams<downloadPageParams>();
    useLayoutEffect(() => {
        async function onPageLoad() {
            await downloadLoadPdfFile();

        }
        onPageLoad()
    }, []);

    /**
     * Downloads a pdf and opens it in the same window
     * @returns
     */
    const downloadLoadPdfFile = async () => {
        if(!id || id.length < 1)
        return;
        let artifactId = id.trim();

        artifactId = DOMPurify.sanitize(artifactId)

        const artifact:FileArtifact = await artifactController.GetArtifact(artifactId);

        //@ts-ignore
        const pdfBlob = convertBase64ToBlob(artifact.attachment, "application/pdf");

        const url = window.URL.createObjectURL(pdfBlob);

        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', artifact.name);
        document.body.appendChild(link);
        link.click();
        window.close();

        // window.open(window.URL.createObjectURL(pdfBlob));
    }

    /**
     * Converts a base64String To a blob so we can download or view files.
     * TODO: Move to a helper class
     * @param base64
     * @param contentType
     * @returns
     */
    const convertBase64ToBlob = (base64:string, contentType:string) => {
        const decodedStr = window.atob(base64);

        const bytes = new Uint8Array(decodedStr.length);

        for (let i = 0; i < decodedStr.length; i++) {
           var ascii = decodedStr.charCodeAt(i);
           bytes[i] = ascii;
        }

        return new Blob([bytes], {type: contentType});
     }

    return (
        <></>
    )
}

export default DownloadPdfPage;
