import React from 'react';
import IconEyeClosedSrc from '../../../resources/icons/icon-eye-closed.png';
import IconEyeOpenSrc from '../../../resources/icons/icon-eye-open.png';
import './PasswordVisibilityToggle.css';

type PasswordVisibilityToggleProps = {
    togglePasswordVisibility:() => void
    isPwdVisible:boolean
}

/**
 * Password Visibility Icon button for toggle text or password on a text field
 * @param props 
 * @returns 
 */
const PasswordVisibilityToggle = (props:PasswordVisibilityToggleProps) => {
    const {
            togglePasswordVisibility,
            isPwdVisible
        } = props;


    return (        
        <button type="button" className='toggle-visibility' onClick={() => togglePasswordVisibility()}>         
        {
            isPwdVisible ? 
            (
                <img 
                    className="toggle-visibility-hide" 
                    src={IconEyeClosedSrc}
                    alt="Hide password"
                />
            )
            : 
            (
                <img 
                    className="toggle-visibility-open" 
                    src={IconEyeOpenSrc}
                    alt="Show password"
                />
            )
        }
        </button>           
    )
}

export default PasswordVisibilityToggle;