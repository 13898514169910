import React, {useState} from 'react';
import {SupportContent, supportLink} from "../../models/AdditionalContent/SupportContent";
import {SupportContentController} from "../../controllers/SupportContentController";
import PlusBlueIconSrc from "../../resources/icons/plus_blue_icon.png";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import BasicInlineForm from "../AdminDashboard/AdminForms/InlineForms/BasicInlineForm";
import editIconSrc from "../../resources/icons/icon-edit.png";
import trashIconSrc from "../../resources/icons/icon-trash.png";
import {ReactComponent as DragGrip} from '../../resources/images/icon-drag-drop.svg';
import './FamilySupportAdminDashboard.css';
import {Link, useHistory} from "react-router-dom";
import {DeepCopy} from "../../models/utility/DeepCopy";
import toast from "react-hot-toast";
import {SiteLocation} from "../../models/Enums/SiteLocation";
import {ArtifactController} from "../../controllers/ArtifactController";
import {ContentViewLogController} from "../../controllers/ContentViewLogController";
import pdfIconSrc from "../../resources/icons/icon-pdf.png";

type FamilySupportAdminDashboardPanelProps = {
    isAdmin: boolean; contentLinks: supportLink[]
    setSelectSupportLink: (id: number) => void; toggleEditLinkModal: (link: supportLink) => void
    toggleDeleteLinkAlert: () => void
    updateLinkOrder: (links: supportLink[]) => void
}

const supportContentController = new SupportContentController();

const FamilySupportAdminView = (props: FamilySupportAdminDashboardPanelProps) => {
    const {
        isAdmin, contentLinks, setSelectSupportLink, toggleEditLinkModal, toggleDeleteLinkAlert, updateLinkOrder
    } = props;

    const artifactController = new ArtifactController();

    const afterDragUpdateLinkPos = (results: any) => {
        if (!results.destination) return;

        const links: supportLink[] = DeepCopy.copy(contentLinks);


        //Reorder item in list
        const [reorderedItem] = links.splice(results.source.index, 1);
        links.splice(results.destination.index, 0, reorderedItem);

        //Update Local List
        updateLinkOrder(links);

    }

    const contentViewLogController = new ContentViewLogController();

    const UpdateViewCounter = async (name: string) => {
        var response = await contentViewLogController.UpdateViewCount(SiteLocation.FamilySupport, name);

    }

    const handleExternalLinkNavigation = async (e: any, link: string, name: string) => {
        e.stopPropagation();
        await UpdateViewCounter(name);
        window.open(link, '_blank')
    }

    return (
        <>
            <div>
                <DragDropContext onDragEnd={afterDragUpdateLinkPos}>
                    <Droppable droppableId='content-category-drop-zone'>
                        {(provided, snapshot) => (
                            <div className="" {...provided.droppableProps} ref={provided.innerRef}>
                                <>
                                    {contentLinks.map((link, index) => (<Draggable
                                            key={link.title}
                                            draggableId={link.title}
                                            index={index}
                                        >
                                            {(dragProvided, snapshot) => (<div
                                                    key={link.title}
                                                    className={`admin-content-container`}
                                                    ref={dragProvided.innerRef}
                                                    {...dragProvided.draggableProps}
                                                >

                                                    {isAdmin ? (
                                                        <div
                                                            className={'chapter-content-container admin-inner-content-container '}
                                                        >
                                                            <>
                                                                <div
                                                                    className="drag-handle"
                                                                    {...dragProvided.dragHandleProps}
                                                                >
                                                                    <DragGrip/>
                                                                </div>
                                                                <div className="admin-chapter-text admin-content-body">
                                                                    {link.link.length > 0 ? (// <a className="SupportContent-title" href={link.link} target={"_blank"}>{link.title}</a>
                                                                        <a className="SupportContent-title"
                                                                           href={link.link}
                                                                           target={"_blank"}>{link.title} {link.external ? ("") : ("( Internal Only )")}</a>
                                                                    ) : (// <a href={`/download-pdf/${additionalContent.artifactId}`} target="blank" className='admin therapist-content-item therapist-pdf-item'>
                                                                        <a className="SupportContent-pdf-container SupportContent-title"
                                                                           href={`${artifactController.GetPdfDownloadUrlWithLog('5', link.mongoFile)}`}
                                                                           target={"_blank"}>
                                                                            <div
                                                                                className="therapist-content-item-info">
                                                                                <div
                                                                                    className="therapist-icon-container">
                                                                                    <img
                                                                                        style={{width: '16px !important'}}
                                                                                        src={pdfIconSrc}
                                                                                        alt="PDF Icon"/>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={'SupportContent-pdf-title-container'}>
                                                                                {link.title} {link.external ? ("") : ("( Internal Only )")}
                                                                            </div>
                                                                        </a>

                                                                    )}

                                                                    <div className="admin-support-content">
                                                                        {link.content}
                                                                    </div>


                                                                </div>
                                                                <div className="therapist-panel-btn-container">
                                                                    <button
                                                                        className="btn-cbit-icon"
                                                                        onClick={(e) => {
                                                                            setSelectSupportLink(index)
                                                                            toggleEditLinkModal(link)
                                                                        }}
                                                                    >
                                                                        <img src={editIconSrc} alt="Edit Icon"/>
                                                                    </button>
                                                                    <button
                                                                        className="btn-cbit-icon"
                                                                        onClick={(e) => {
                                                                            setSelectSupportLink(index)
                                                                            toggleDeleteLinkAlert()
                                                                        }}>
                                                                        <img src={trashIconSrc} alt="Trash Icon"/>
                                                                    </button>
                                                                </div>
                                                            </>
                                                        </div>) : (<>
                                                            <div className="SupportContent-container">
                                                                <div className={"SupportContent-titleContainer"}>
                                                                    {link.link.length > 0 ? (// <a className="SupportContent-title" href={link.link} target={"_blank"}>{link.title}</a>
                                                                        <a className="SupportContent-title"
                                                                           onClick={(e) => handleExternalLinkNavigation(e, link.link, link.title)}>{link.title}</a>
                                                                    ) : (// <a href={`/download-pdf/${additionalContent.artifactId}`} target="blank" className='admin therapist-content-item therapist-pdf-item'>
                                                                        <a className="SupportContent-pdf-container SupportContent-title"
                                                                           href={`${artifactController.GetPdfDownloadUrlWithLog('5', link.mongoFile)}`}
                                                                           target={"_blank"}>
                                                                            <div
                                                                                className="therapist-content-item-info">
                                                                                <div
                                                                                    className="therapist-icon-container">
                                                                                    <img src={pdfIconSrc}
                                                                                         alt="PDF Icon"/>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={'SupportContent-pdf-title-container'}>
                                                                                {link.title}
                                                                            </div>
                                                                        </a>
                                                                    )}
                                                                </div>
                                                                <div className="admin-support-content">
                                                                    {link.content}
                                                                </div>
                                                            </div>
                                                        </>)}

                                                </div>)}
                                        </Draggable>))}
                                </>
                                {provided.placeholder}
                            </div>)}
                    </Droppable>
                </DragDropContext>

            </div>
        </>
    )
}

export default FamilySupportAdminView;
