import { getModuleTreePath, PartialModuleTreeModel } from "../partialModels/PartialModuleTreeModel";

export class AccordionHelper {

    /**
    * Toggles the accordion to show or hide
    */
    public static toggleAccordion = (moduleId:string) => {
        const accordionItem = document.querySelector(`[data-key="${moduleId}"]`);

        if(accordionItem?.classList.contains("show")){
            accordionItem.classList.remove("show");
        } else {
            accordionItem?.classList.add("show");
        }
    }

    /**
    * sets collapse or show the accordion
    * @param collapse 
    * @returns 
    */
    public static setCollapseOrShowAccordion = (collapse:boolean, moduleId:string) => {
        const accordionItem = document.querySelector(`[data-key="${moduleId}"]`);

        if(collapse) {
            accordionItem?.classList.remove("show")
        } else {
            if(accordionItem?.classList.contains("show"))
                return;
            accordionItem?.classList.add("show");
        }
    }

    /**
    * Check to see if this module child have been selected
    * @returns 
    */
   public static checkIfChildIsSelected(parentModule:PartialModuleTreeModel, moduleId:string, selectedModuleId:string) {
       const treePath:PartialModuleTreeModel[] = [];
   
       getModuleTreePath(parentModule,treePath, selectedModuleId);        

       const foundIndex = treePath.findIndex(pathItem => pathItem.id === moduleId);    

       return foundIndex !== -1 && treePath.length > 1;
   }

    /**
     * Add the icon caret class depending on if the accordion item is open or not
     * @returns 
     */
    public static addIconCaretClass(isOpen:boolean) {
        return isOpen ? "admin-caret-down" : "admin-caret-left";
    }
}