import {BaseReactService} from "./interfaces/BaseReactService";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import { IAdditionalContent } from "../models/AdditionalContent/AdditionalContent";
import { AxiosError } from "axios";

export class AdditionalContentService extends BaseReactService {

    constructor()
    {
        super("AdditionalContent/");
    }

    public async GetContentGroupByTagIds(tagIds:string[]) {
        const endPoint = "AdditionalContent/GetGroupedContentByTagId";

        try {
            const params = new URLSearchParams();

            tagIds.forEach(tagId => {
                params.append("tagIds", tagId);
            });            

            return await this.CustomGet(params,endPoint);

        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Updates video content to match vimeo video information
     */
    public async RefreshVideoAdditionalContent() {
        const endPoint = "AdditionalContent/RefreshVimeoContent/";

        try {
            return await this.CustomGet({}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async CreateAdditionalVideoContent(additionalContent:IAdditionalContent) {
        const endPoint = "AdditionalContent/CreateAdditionalVideoContent/";

        try {
            return await this.Post(additionalContent, endPoint);
        } catch (error) {
            console.error(error);
        }
    }

    public async UpdateTagInAdditionalContent(oldTagId:string, newTagId:string) {
        const endPoint = "AdditionalContent/UpdateTagInAdditionalContent/";

        try {
            return await this.CustomPatch({oldTagId, newTagId}, endPoint);            
        } catch(error) {
            console.error(error);
        }
    }

    public async DeleteTagInAdditionalContentItems(tagToDelete:string) {
        const endPoint = "AdditionalContent/DeleteTagInAdditionalContent/";

        try {
            return await this.Delete({id:tagToDelete}, endPoint);
        } catch (error) {
            console.error(error);
        }
    }

}