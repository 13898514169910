import * as React from 'react';
import {Input} from 'reactstrap';
import { ContentCategoryController } from '../../../../controllers/ContentCategoryController';
import { _idObject } from '../../../../models/AdditionalVideoContent/AdditionalVideoContent';
import { ContentCategory } from '../../../../models/AdditionalVideoContent/ContentCategory';
import { IContentCategory } from '../../../../models/AdditionalVideoContent/ContentCategory';
import { DeepCopy } from '../../../../models/utility/DeepCopy';
import {ReactComponent as TrashIcon} from '../../../../resources/images/icon-feather-trash-2.svg';
import './ContentCategoryForm.css';
import ContentTagForm from './ContentTagForm';

/**
 * 
 */
type CategoryFormState = {
    contentCategory:IContentCategory
    tagName:string
    isEditing:boolean
}

type CategoryFormProps = {
    contentCategory?:IContentCategory
    index?:number
    editContentCategoryBuilder: (category:IContentCategory, index:number) => void
    updateContentCategoryBuilder:(category:IContentCategory)=> void
    deleteContentCategory:(category:IContentCategory, index:number) => void
}

/**
 * 
 */
class ContentCategoryForm extends React.Component<CategoryFormProps, CategoryFormState> {
    private ContentCategoryAPI  = new ContentCategoryController();

    private defaultCategoryFormState:CategoryFormState = { 
        contentCategory: this.props.contentCategory ? this.props.contentCategory : new ContentCategory({id: "", name: "", order: 0, tags: []}),   
        tagName: '',
        isEditing:false
    }

    /**
     *
     */
    constructor(props:any) {
        super(props);
        this.state = this.defaultCategoryFormState;
    }

    async componentDidMount(){
        
    }

    /**
     * Handles input change
     */
    private handleChange =  () => {

    }

    /**
     * Runs when the input field have changed
     */
    private onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const inputName = e.currentTarget.name;
        
        const inputValue = e.currentTarget.value;
        
        if(inputName === "categoryName") {
            this.setState((prevState) => ({
                ...prevState,
                contentCategory: {
                    ...prevState.contentCategory,
                    name: inputValue
                }
            }));
        } else {    
            this.setState(prevState => ({
                ...prevState,
                [inputName]: inputValue
            }));        
        }
    }

    /**
     * 
     */
    private toggleEditing = () => {
        this.setState(prevState => ({
            ...prevState,
            isEditing:!this.state.isEditing
        }));
    }

    /**
     * 
     * @param name 
     */
    private addContentCategoryTags = (name:string) => {
        const contentCategory = DeepCopy.copy(this.state.contentCategory);

        const nameObj : _idObject = {
            _id: name
        }

        contentCategory.tags.push(nameObj);

        this.setState(prevState => ({
            ...prevState,
            contentCategory
        }));

        this.ContentCategoryAPI.UpdateContentCategory(contentCategory);

    }

    private editContentCategoryTags = async (index:number, name:string) => {
        const contentCategory:IContentCategory = DeepCopy.copy(this.state.contentCategory);

        const tags = contentCategory.tags;

        tags[index]._id = name;

        contentCategory.tags = tags;

        this.setState(prevState => ({
            ...prevState,
            contentCategory
        }));

        await this.ContentCategoryAPI.UpdateContentCategory(contentCategory);
    }

    /**
     * 
     * @param index 
     */
    private deleteContentCategoryTag = async (index:number) => {
        const contentCategory:IContentCategory = DeepCopy.copy(this.state.contentCategory);

        const tags = contentCategory.tags;

        tags.splice(index, 1);

        contentCategory.tags = tags;

        this.setState(prevState => ({
            ...prevState,
            contentCategory
        }));
    }
    
    /**
     * Handles the content category input submit 
     */
    private onContentCategorySubmit = async (event:React.KeyboardEvent<HTMLInputElement>) => {            
        if(event.key === "Enter") {
            event.preventDefault(); //If outside of this check it will conflict with onChange
            const contentCategory = {...this.state.contentCategory}
            const newContentCategory = await this.ContentCategoryAPI.CreateContentCategory(contentCategory);

            this.props.updateContentCategoryBuilder(newContentCategory);

            this.setState(this.defaultCategoryFormState);
        }
    }

    /**
     * 
     * @param event 
     */
    private onEditCategorySubmit = async (e:React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "Enter") {
            const index = this.props.index ? this.props.index : 0;
            e.preventDefault();

            const contentCategory = DeepCopy.copy(this.state.contentCategory);

            this.props.editContentCategoryBuilder(contentCategory, index);         
            
            this.toggleEditing();
        }
    }

    /**
     * 
     */
    private handleCategoryDelete = async () => {
        const categoryToRemove = DeepCopy.copy(this.state.contentCategory);
        const index = this.props.index ? this.props.index : 0;
        this.props.deleteContentCategory(categoryToRemove, index);
    }

    public render() {
        const {contentCategory, tagName} = this.state;
        return (
            <form action="" className="category-form-2">
                {
                    contentCategory.id ? 
                    (
                        <div className='category-form'>    
                            <span onClick={this.handleCategoryDelete} className='category-delete-btn'>
                                <TrashIcon />
                            </span>                        
                            {
                                
                                this.state.isEditing ? 
                                (
                                    <input
                                        onBlur={this.toggleEditing}                
                                        className='inline-module-input'
                                        autoFocus
                                        name="categoryName" 
                                        type="text" 
                                        id="name" 
                                        placeholder={"Enter a category name"} 
                                        value={contentCategory.name} 
                                        onKeyPress={this.onEditCategorySubmit} 
                                        onChange={this.onChange}  
                                    />

                                ) 
                                : 
                                (
                                    <p className='content-category-name' onClick={this.toggleEditing}>
                                        {contentCategory.name}
                                    </p>
                                )
                            }
                            <ul>
                            <ContentTagForm    
                                editContentCategoryTags={this.editContentCategoryTags}
                                updateContentCategoryTags={this.addContentCategoryTags}
                                deleteContentCategoryTag={this.deleteContentCategoryTag}                             
                            />
                                {
                                    
                                    contentCategory.tags.map((tag, index) => ((
                                        <li>
                                            <ContentTagForm 
                                                name={tag._id} 
                                                index={index}
                                                updateContentCategoryTags={this.addContentCategoryTags}
                                                editContentCategoryTags={this.editContentCategoryTags}
                                                deleteContentCategoryTag={this.deleteContentCategoryTag}
                                            />
                                        </li>
                                )))}
                            </ul>
                        </div>
                    ) 
                    : 
                    (
                        <div className="category-input-container">
                            <input                
                                className='inline-module-input'
                                autoFocus
                                name="categoryName" 
                                type="text" 
                                id="name" 
                                placeholder={"Enter a category name"} 
                                value={contentCategory.name} 
                                onKeyPress={this.onContentCategorySubmit} 
                                onChange={this.onChange}  
                            />
                                {
                                    this.state.contentCategory.id && (
                                        <div className="content-category-tags">
                                            <ul>
                                                <Input 
                                                    className='inline-module-input'
                                                    name="tagId"
                                                    value={tagName}
                                                    placeholder='Create a tag name'
                                                    onChange={this.onChange}
                                                    />  
                                                {contentCategory.tags.map((tag) => {
                                                    <li>{tag._id}</li>
                                                })}
                                            </ul> 
                                        </div>
                                    )
                                }                        
                            </div>  
                    ) 
                }
 
            </form>
        )
    }
}

export default ContentCategoryForm;