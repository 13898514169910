import React from "react";

export type AttendanceLabelProps = {
    idx:number
}

const LateLabel = (props:AttendanceLabelProps) => (    
    <label className="attendance-label late-label" htmlFor={`late-${props.idx}`}>
        <i className="late-icon bi bi-alarm"></i> 
    </label>
)

export default LateLabel;

