import React from "react"

type MarkAllAsReadProps = {
    markAllAsRead: () => void
}

const MarkAllAsRead = ({markAllAsRead}: MarkAllAsReadProps) => {
    return (
        <button className='btn-cbit-primary inbox-btn' onClick={markAllAsRead}>
            Mark All As Read
        </button>
    )
}

export default MarkAllAsRead