import DOMPurify from "dompurify";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { ConsultationReviewController } from "../../../controllers/ConsultationReviewController";
import { AttendanceEnum, AttendeeAttendance, ConsultationReview, IConsultationReview, ReviewTypeEnum } from "../../../models/consultations/ConsultationReview";
import { IConsultationSession } from "../../../models/consultations/ConsultationSession";
import { ConsultationType } from "../../../models/consultations/ConsultationType";
import { ApplicationState } from "../../../store";
import { CurrentUserState } from "../../../store/CurrentUser";
import UserProfile from "../../UserDashboard/UserProfile/UserProfile";
import CustomModal from "../../Utilities/CustomModal";
import CbitTextArea from "../../Utilities/FormFields/CbitTextArea";
import AttendeeList from "../ConsultationItems/AttendeesList/AttendeeList";
import AttendanceForm from "./AttendanceForm";
import "./ConsultationReviewForm.css";


type ConsultationReviewFormProps = {
    consultationSession:IConsultationSession
    onSubmit:(consultationReview?:IConsultationReview) => void
}

type ConsultationReviewFormState = {
    rating:number,
    comments:string
}

const consultationReviewController = new ConsultationReviewController();

/**
 * Review form for consultants and attendees to review their previous consultations
 * @param props 
 * @returns 
 */
const ConsultationReviewForm = (props:ConsultationReviewFormProps) => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    const {consultationSession:{consultant, consultation, attendeesBioList}, onSubmit} = props;

    const maxCommentCharLimit = 2000;

    const initialFormValues:ConsultationReviewFormState = {
        rating: 0,
        comments: ""
    }

    const [formData, setFormData] = useState(initialFormValues);
    const [isAttendanceOpen, setIsAttendanceOpen] = useState(false);
    const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
    const [isAttendanceFormComplete, setAttendanceFormComplete] = useState(false);

    /**
     * Checks if the current logged in user is the creator of the consultation
     */
    const checkIfConsultationOwner = () => {
        if(!userStore) {
            return false;
        }
    
        const {userProfile: {id}} = userStore;
        
        return id === consultant.id;
    } 

    const getReviewType = () => {
        // if (consultation.type === ConsultationType.Seminar) {
        //     return ReviewTypeEnum.seminarReview;
        // }

        return checkIfConsultationOwner() ? ReviewTypeEnum.reviewByConsultant : ReviewTypeEnum.reviewByAttendee;
    }
    

    const getInitialAttendance = () => {
        if(getReviewType() !== ReviewTypeEnum.reviewByConsultant) {
            return [];
        }

        return attendeesBioList.map(attendee => (
            new AttendeeAttendance(attendee.id, AttendanceEnum.unknown, false)
        ));

    }

    const [attendance, setAttendance] = useState(getInitialAttendance());

    const validationSchema = Yup.object({
        rating: Yup.number()
        .max(5, "Rating cannot be greater than 5 stars")
        .min(1, "Rating cannot be less than 1 star")
        .required("Please provide a star rating"),        
        comments: Yup.string()
        .max(maxCommentCharLimit + 1, 
            `Comments cannot be greater than ${maxCommentCharLimit} characters`
        ),
    });   
    
   
    const toggleUserProfileModal = () => {
        setIsUserProfileOpen(!isUserProfileOpen);
    }    

    const toggleAttendanceModal = () => {
        setIsAttendanceOpen(!isAttendanceOpen);
    }

    const attendanceFormSubmit = (attendanceList:AttendeeAttendance[]) => {
        setAttendance(attendanceList);
        toggleAttendanceModal();
        setAttendanceFormComplete(true);
    }

    const checkAttendanceForm = () => {
       return attendance.every(a => a.attendance !== AttendanceEnum.unknown)
    }

    const handleSubmit = async (values:ConsultationReviewFormState) => {
        if(!userStore) {
            return;
        }
        
        const {rating, comments} = values;     
        
        //Because formik and HTML inputs values are actually strings we need to parse it despite what TypeScript says
        const parsedRating = parseInt(rating as unknown as string)        

        const toastLoadId = "reviewLoadingId";

        if(isNaN(parsedRating)) {
            toast.error("Invalid Rating");
            return;
        }

        if(parsedRating < 1 || parsedRating > 5) {
            toast.error("Rating must be between one and five stars")
            return;
        }

        if(comments.length > maxCommentCharLimit) {
            toast.error(`Additional comments cannot be greater than ${maxCommentCharLimit}`);
            return;
        }

        if (getReviewType() === ReviewTypeEnum.reviewByConsultant && consultation.type !== ConsultationType.Seminar) {
            if(!checkAttendanceForm()) {
                toast.error("Please fill out the attendance form");
                return;
            }
        }

        let cleanedComments = DOMPurify.sanitize(comments);

        cleanedComments = cleanedComments.trim();        
        
        try {
            toast.loading("Sending review", {id:toastLoadId})
            
            const {id, firstName, lastName} = userStore.userProfile;

            const review = new ConsultationReview(
                {
                    id: "",
                    name: `${firstName} ${lastName} review`,
                    assignedUserId: id,
                    consultationId: consultation.id,
                    qualityRating: rating,
                    comments: cleanedComments,
                    reviewType: getReviewType(),
                    PostedAt: new Date().toUTCString(),
                    attendeeAttendanceList: attendance
                });
            
            const newReview = await consultationReviewController.CreateReview(review);
            
            onSubmit(newReview);
            
            toast.dismiss(toastLoadId);
            toast.success("Successfully submitted review");
        } catch (error) {
            console.error(error);
            toast.dismiss(toastLoadId);
            toast.error("Failed to submit review");
        }
    }

    return (
        <>
            <Formik
                enableReinitialize={true}             
                initialValues={formData}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                { props => (
                    <Form
                        translate={"yes"}
                        className="consultation-review-form form"                    
                    >
                        <h2 className="form-title">Consultation Review</h2>
                        <div className="cbit-row">
                            <div className="cbit-col">
                                <div className="review-btn-container">
                                {
                                    checkIfConsultationOwner() ?
                                    (
                                        <>
                                        {
                                            consultation.type !== ConsultationType.Seminar &&  attendeesBioList.length > 0 ? 
                                            (
                                                <>
                                                    <div className="cbit-row">
                                                        <p>
                                                            Select attendees that attended this session
                                                        </p>                                    
                                                    </div>
                                                    <div className="cbit-row">
                                                        <button 
                                                            type="button" 
                                                            className="btn-cbit-link"
                                                            onClick={() => toggleAttendanceModal()}
                                                        >
                                                            {
                                                            isAttendanceFormComplete ? 
                                                            (
                                                                "Attendance Form (Completed)"
                                                            )
                                                            :
                                                            (
                                                                "Attendance Form"
                                                            )
                                                            
                                                        }
                                                        </button>
                                                    </div>
                                                </>
                                            )
                                            :
                                            (                                                
                                                <div className="cbit-row">
                                                    <button 
                                                    type="button" 
                                                        className="btn-cbit-link cbit-label"
                                                        onClick={() => toggleUserProfileModal()}
                                                    >
                                                        Attendee List
                                                    </button>
                                                </div>                                            
                                            )
                                        }
        
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <span className="cbit-label">
                                                Consultant:
                                            </span>
                                            <button 
                                                type="button" 
                                                className="btn-cbit-link cbit-label" 
                                                onClick={() => toggleUserProfileModal()}
                                            >
                                                {`${consultant.firstName} ${consultant.lastName}`}
                                            </button>
                                        </>
                                    )
                                }
                                </div>
                            </div>
                        </div>
                        <div className="cbit-row">
                            <p>
                                {
                                    `How was your ${moment(consultation.startDate).format("MMM DD YYYY, h:mm A")} consulting session?`
                                }
                                
                            </p>
                        </div>
                        <div className="cbit-row">
                            <div className="cbit-column">
                                <label htmlFor="qualityRating" className="cbit-label">Rating</label>
                                {" "}
                                <span className="required-field">*</span>
                            </div>
                        </div>
                        <div className="cbit-row rating-container">
                            <div className="cbit-column">
                                <div className="rating-input-container">
                                    <Field 
                                        id="qualityRating5" 
                                        className="rating-input" 
                                        name="rating" 
                                        type="radio" 
                                        value={"5"}   
                                        onChange={props.handleChange}
                                    />
                                    <label 
                                        className="rating-label"
                                        htmlFor="qualityRating5"
                                    >
                                    </label>
                                    
                                    <Field 
                                        id="qualityRating4" 
                                        className="rating-input" 
                                        name="rating" 
                                        type="radio" 
                                        value={"4"}                                    
                                        onChange={props.handleChange}                                    
                                    />
                                    <label 
                                        className="rating-label"
                                        htmlFor="qualityRating4"></label>
                                    
                                    <Field 
                                        id="qualityRating3" 
                                        className="rating-input" 
                                        name="rating" 
                                        type="radio" 
                                        value={"3"}
                                        onChange={props.handleChange}                                    
                                    />
                                    <label 
                                        className="rating-label"
                                        htmlFor="qualityRating3"></label>
                                    
                                    <Field 
                                        id="qualityRating2" 
                                        className="rating-input" 
                                        name="rating" 
                                        type="radio" 
                                        value={"2"}
                                        onChange={props.handleChange}                                    
                                    />
                                    <label 
                                        className="rating-label"
                                        htmlFor="qualityRating2"></label>
                                    
                                    <Field 
                                        id="qualityRating1" 
                                        className="rating-input" 
                                        name="rating" 
                                        type="radio" 
                                        value={"1"}
                                        onChange={props.handleChange}                                    
                                    />
                                    <label 
                                        className="rating-label"
                                        htmlFor="qualityRating1"></label>
                                </div>
                                <div className="error-message-container">
                                    <ErrorMessage name="rating"/>
                                </div>
                            </div>
                        </div>
                        <div className="cbit-row">
                            <div className="cbit-column">
                                <label 
                                    className="cbit-label"
                                    htmlFor="consultationReviewComments"
                                >
                                    Additional Comments
                                </label>
                            </div>
                        </div>
                        <div className="cbit-row">
                            <div id='cbit-review-textarea' className="cbit-column">
                                <CbitTextArea 
                                    name={"comments"} 
                                    id={"consultationReviewComments"} 
                                    value={props.values.comments}
                                    cols={50} 
                                    rows={5}
                                    maxCharacterLimit={maxCommentCharLimit}                                 
                                    required={false} 
                                    additionalClasses={["consultation-review-comments"]}
                                    onChange={props.handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-btn-container">
                            <button 
                                type="submit"
                                className="btn-cbit-primary"
                            >
                                Submit
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>                        
            <CustomModal isOpen={isUserProfileOpen} toggle={toggleUserProfileModal}>
                {
                    checkIfConsultationOwner() ? 
                    (
                        <AttendeeList 
                            attendees={attendeesBioList}
                        />
                    )
                    :
                    (
                        <div className="cbit-dialog review-user-profile">
                            <UserProfile 
                                userBio={consultant}
                            />
                        </div>
                    )
                }
            </CustomModal>
            {
                checkIfConsultationOwner() && consultation.type != ConsultationType.Seminar && (
                    <CustomModal isOpen={isAttendanceOpen} toggle={toggleAttendanceModal}>  
                        <AttendanceForm
                            attendees={attendeesBioList}
                            attendanceList={attendance}
                            attendanceFormSubmit={attendanceFormSubmit}
                        />
                    </CustomModal>
                )
            }
        </>
    )
}

export default ConsultationReviewForm;