import {Formik, Form} from 'formik'

import {SupportContent, supportLink} from "../../models/AdditionalContent/SupportContent";
import * as Yup from 'yup'
import React, {useEffect, useState} from 'react';
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import './FamilySupportAdminDashboard.css';
import PdfUploader from "../Utilities/FileUploaders/PdfUploader";
import trashIconSrc from "../../resources/icons/icon-trash.png";
import {ArtifactController} from "../../controllers/ArtifactController";
import {DeepCopy} from "../../models/utility/DeepCopy";

type fourmProps = {
    content: supportLink
    updateOldLink: (link: supportLink) => void;
    createNewLink: (link: supportLink) => void;
}


const SupportContentForum = (props: fourmProps) => {

    const {content, updateOldLink, createNewLink} = props;
    const [state, setState] = useState<supportLink>({
        content: content.content,
        link: content.link,
        external: content.external,
        title: content.title,
        mongoFile: content.mongoFile
    });

    const [file, setFile] = useState<File | null | undefined>()
    const [fileToDelete, setFileToDelete] = useState<string>("");
    const [fileName, setFileName] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const artifactController = new ArtifactController();

    const emptyFile=()=>{
        setFile(null);
        setFileName("");
        if(state.mongoFile != "" && state.mongoFile != "newFile"){
            setFileToDelete(state.mongoFile);
        }

        setState((prevState)=>({
            ...prevState,
            mongoFile:"",
        }))
    }
    const handleFileUpload = (files: FileList | null) => {
        if (files == null) {
            return;
        }

        setFile(files[0])
        setFileName(files[0].name)
        if(state.mongoFile != "" && state.mongoFile != "newFile"){
            setFileToDelete(state.mongoFile);
        }

        setState((prevState)=>({
            ...prevState,
            mongoFile:"newFile"
        }))
    }

    useEffect(()=>
    {
        const getFileInfo = async (setFile:any, setLoading:any) =>{

            if(content.mongoFile != ""){
                let name = await artifactController.GetDownloadName(content.mongoFile);
                setFile(name);
                setLoading(false)
            }else{
                setLoading(false);
            }
        }

       getFileInfo(setFileName,setIsLoading)

    },[content])

    return (
        <>
            <Formik enableReinitialize={true}
                initialValues={{
                    title: content.title,
                    link: content.link,
                    content: content.content,
                    external: content.external,
                    mongoFile: content.mongoFile
                }}
                validationSchema={Yup.object().shape({
                    title: Yup.string().max(255).required('Title is required'),
                    content: Yup.string().max(4096).required('Description is required'),
                    link: Yup.string().max(4096).matches(/(^https?:\/\/|^HTTPS?:\/\/)/, "must contain https://")
                })}
                //(^https?:\/\/|^HTTPS?:\/\/))
                onSubmit={async (values, {setErrors, setStatus, setSubmitting}) =>
                {

                    let baseObj =DeepCopy.copy(content);
                    baseObj.title = values.title;
                    baseObj.content = values.content;
                    baseObj.link = values.link;
                    baseObj.external = values.external;

                    if(fileToDelete.length>0)
                    {
                        var reponseData = await artifactController.DeleteArtifact(fileToDelete);
                    }
                    baseObj
                    if(state.mongoFile ==="newFile")
                    {
                        if(file!=null)
                        {
                            var responseData = await artifactController.UploadPdfFiles(file);
                            baseObj.mongoFile = responseData.id;
                        }
                    }

                    if(baseObj.link.length  >0)
                    {
                        baseObj.mongoFile = ""
                    }

                    if (content.title != "") {
                        //If Not New
                        updateOldLink(baseObj);
                    } else {
                        //If New
                        createNewLink(baseObj);
                    }
                }}
            >
                {({errors, handleBlur, handleChange, handleSubmit, touched, values}) => (
                    <>
                        <div className="additional-content-form" style={{padding: '30px'}}>
                            <form noValidate onSubmit={handleSubmit}>
                                <h2 className="form-title">{content.title == "" ? ('Create') : ('Update')} Support Content</h2>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label htmlFor='title' className='form-label'>Title</Label>
                                            <Input
                                                required
                                                id="title"
                                                name="title"
                                                type='text'
                                                className='form-input'
                                                value={values.title}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                }}
                                            />
                                            {errors.title && touched.title ? (
                                                <Label className="formik-error-text">{errors.title}</Label>
                                            ) : null}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label htmlFor='content' className='form-label'>Description</Label>
                                            <Input
                                                required
                                                id="content"
                                                name="content"
                                                type='textarea'
                                                className='form-input'
                                                value={values.content}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                }}
                                            />
                                            {errors.content && touched.content ? (
                                                <Label className="formik-error-text">{errors.content}</Label>
                                            ) : null}
                                        </FormGroup>

                                    </Col>
                                </Row>

                                {/*LINK*/}
                                {fileName.length == 0 &&(
                                    <>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor='link' className='form-label'>Link</Label>
                                                    <Input
                                                        required
                                                        id="link"
                                                        name="link"
                                                        type='text'
                                                        className='form-input'
                                                        value={values.link}
                                                        onChange={(e) => {
                                                            handleChange(e)
                                                        }}
                                                    />
                                                    {errors.link && touched.link ? (
                                                        <Label className="formik-error-text">{errors.link}</Label>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                {/* Upload Files */}
                                {values.link.length == 0 &&(
                                    <>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <div className="upload-label">
                                                        <Label className='form-label' for="coverImage">Upload PDF File</Label>
                                                        <div className="upload-text">
                                                            Upload a PDF file that is less than 16mb.
                                                        </div>
                                                    </div>
                                                    {isLoading?(
                                                        <>
                                                        </>
                                                    ):(
                                                        <>
                                                            <>
                                                                { fileName.length > 0 ? (
                                                                    <div className="upload-pdf-container">
                                                                        <label htmlFor='inputImageUpload' className="upload-btn-container btn-cbit-primary replace-image">
                                                                            REPLACE PDF
                                                                        </label>
                                                                        <div className="file-preview-container" style={{marginLeft:'5px'}}>
                                                                            {fileName}
                                                                        </div>
                                                                        <div>
                                                                            <img className='delete-button' onClick={(e)=>{emptyFile()}} width={16} src={trashIconSrc} alt="Trash Icon" />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <label htmlFor='inputImageUpload' className="upload-btn-container  btn-cbit-primary">
                                                                            UPLOAD
                                                                        </label>
                                                                    </>
                                                                )}
                                                            </>
                                                            <input
                                                                id={'inputImageUpload'}
                                                                type='file'
                                                                disabled={false}
                                                                onChange={(e) => handleFileUpload(e.target.files)}
                                                                className={'input-txt-img-upload'}
                                                            />
                                                        </>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </>
                                )}

                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label htmlFor="external" className="form-label">Is Public</Label>
                                            <input
                                                id="external"
                                                name="external"
                                                type='checkbox'
                                                style={{marginLeft:'5px'}}
                                                className='form-input'
                                                checked={values.external}
                                                onChange={(e) => {
                                                    handleChange(e)
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <button type="submit" className='btn-cbit-primary'>Submit</button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                    </>
                )}
            </Formik>
        </>
    )

}

export default SupportContentForum;
