/**
 * Class with static properties for getting urls internal and external urls
 */
export class CbitUrls {
    static prodUrl = "https://cbittrainer"; //TODO when the site launch this will change to app.cbittrainer.com
    static prodExternalUrl = "https://cbittrainer";
    static devUrl = "https://edutrainer-web-dev.herokuapp.com";
    static devExternalUrl = "https://edutrainer-frontend-dev.herokuapp.com";
    // static devExternalUrl = "https://dev.cbittrainer.com";
    static localUrl = "http://localhost:3000";
    static localExternalUrl = "http://localhost:5000";

    static internalUrls = {
        local:this.localUrl,
        development:this.devUrl,
        production:this.prodUrl
    } 
    
    static externalUrls = {
        local:this.localExternalUrl,
        development:this.devExternalUrl,
        production:this.prodExternalUrl
    }
}