import { ContactUsEmailRequest } from "../models/requests/ContactUsEmailRequest";
import { BaseWebService } from "./interfaces/BaseWebServices";

/**
 * Class for communicating with the email service api endpoints
 */
export class EmailerService extends BaseWebService {

    constructor() {
        super('Emailer');        
    }

    /**
     * Sends a contact us email to the cbit team
     * @param req 
     * @returns 
     */
    public async SendContactUsEmail(req:ContactUsEmailRequest) {
        const endPoint = "SendContactUsEmail";

        return await this.Post(endPoint, req);
    }
}