import React from "react";
import { useEffect, useState } from "react";
import { StripeController } from "../../controllers/StripeController";
import { StripeProduct } from "../../models/Stripe/StripeProduct";
import { NumberHelper } from "../../models/utility/NumberHelper";
import { Spinner } from "reactstrap";
import "./RenderProductItem.css";

const stripeController = new StripeController();

//Product price/etc is only for display and is not used for purchasing because we cant trust the client
// const priceId = process.env.REACT_APP_STRIPE_CBIT_RECURRING_PRICE;

type RenderProductItemProps = {
    priceId:string;
}

/**
 * Component for displaying the cbit training course pricing/etc 
 */
const RenderProductItem = (props:RenderProductItemProps) => {
    const {priceId} = props;
    const [isLoading, setIsLoading] = useState(true);
    const [stripeProduct, setStripeProduct] = useState<StripeProduct | null>(null);

    useEffect(() => {
        async function onComponentMount(){
            if(!priceId) {
                return;
            }

            const stripeProduct = await stripeController.GetStripeProductByPriceId(priceId);
            
            setIsLoading(true);
            
            if(!stripeProduct) {
                return;
            }

            setStripeProduct(stripeProduct);
            setIsLoading(false)
        }
        onComponentMount();
    },[]);


    return (
        <div className="product-item">
            {/* <h3 className="product-header cbit-header">Recurring Subscription</h3> */}
            <div className="product-info-container">
            {
                isLoading ? 
                (                    
                    <></>
                ) 
                : 
                (
                    <>
                    {
                        stripeProduct != null ? 
                        (
                            <>
                                <div className="product-name">{stripeProduct.name}</div>
                                <div className="product-price">{`$${NumberHelper.ConvertCentsToDollars(stripeProduct.price)} ${stripeProduct.currency}`}</div>
                                <div className="product-description">
                                    {stripeProduct.description}
                                </div>
                            </>
                        ) 
                        : 
                        (
                            <>
                                <p>Could not retrieve product information</p>
                            </>
                        )
                    }
                    </>
                )
            }
            </div>
        </div>
    )
}

export default RenderProductItem;