import { AxiosError } from "axios";
import { IReferral, ReferralFilters, ReferralStatusEnum } from "../models/Referral/Referral";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { ReferralService } from "../services/ReferralService";

/**
 * Controller for handling communication with the referral service obj
 */
export class ReferralController {
    private referralService:ReferralService;

    constructor() {
        this.referralService = new ReferralService();        
    }

    public async GetAllReferrals() : Promise<IReferral[]> {
        try {
            return await this.referralService.GetAll();
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async GetAllReferralsByStatus(referralStatus?:ReferralStatusEnum) : Promise<IReferral[]> {
        try {
            return await this.referralService.GetAllReferralsByStatus(referralStatus);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Get a list of referrals by specific filters (state, licensed state, or age groups) 
     * @param referralFilters 
     * @returns
     */
    public async GetReferralsByFilter(referralFilters:ReferralFilters) {
        try {
            return await this.referralService.GetReferralsByFilters(referralFilters);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async GetReferralByUserId(userId:string) : Promise<IReferral> {
        try {
            return await this.referralService.GetReferralByUserId(userId);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
    /**
     * Creates a new referral
     * @param referral 
     * @returns 
     */
    public async CreateNewReferral(referral:IReferral) : Promise<IReferral>{
        try {
            return await this.referralService.CreateNewReferral(referral);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async UpdateReferral(id:string, referral:IReferral) : Promise<IReferral> {
        try {
            return await this.referralService.UpdateReferral(id,referral);
        } catch(error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async UpdateReferralStatus(id:string, userId:string, status:ReferralStatusEnum):Promise<boolean> {
        try {
            return await this.referralService.UpdateReferralStatus(id, userId, status);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
}