import { AxiosError } from "axios";
import moment from "moment";
import { IConsultation } from "../models/consultations/Consultation";
import { ConsultationSession } from "../models/consultations/ConsultationSession";
import { RecurringConsultationRequest } from "../models/requests/RecurringConsultationRequest";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import TimeHelper from "../models/utility/TimeHelper";
import { ConsultationService } from "../services/ConsultationService";

export class ConsultationController{
    
    private consultationService:ConsultationService;
    
    constructor()
    {
        this.consultationService = new ConsultationService();
    }
    
    //
    //FUNCTIONS
    //
    public async GetConsultationById( id:string){
        try
        {
            return await this.consultationService.Get({id});
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    /**
     * Gets all consultations
     * @returns 
     */
    public async GetConsultations( )
    {
        try
        {
            return await this.consultationService.GetAll();
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    /**
     * Obsolete and needs to be removed
     * @param id 
     * @returns 
     */
    public async GetConsultationsByConsultant(id:string) {
        try {
            return await this.consultationService.GetConsultationsByConsultant(id);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Obsolete and needs to be removed
     * @param id 
     * @returns 
     */
    public async GetUserConsultations(id:string) {
        try {
            return await this.consultationService.GetUserConsultations(id);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get a consultants available consultations by a date
     * @param userId 
     * @param from 
     * @param to 
     * @returns 
     */
    public async GetConsultantsAvailableConsultationsByDate(userId:string, from:Date|string, to:Date|string) {
        try {
            const fromIso = new Date(from).toISOString();
            const toIso = new Date(to).toISOString();
            return await this.consultationService.GetConsultantsAvailableConsultationsByDate(userId, fromIso, toIso);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get upcoming consultations for a user that are currently active
     * @param userId 
     * @param from 
     * @param to 
     */
    public async GetUpcomingConsultations(userId:string, from:Date|string, to:Date|string) {
        try {
            //Because dates are stored in the db as utc we need to convert the dates
            from = new Date(from).toISOString();
            to = new Date(to).toISOString();

            return await this.consultationService.GetUpcomingSessions(userId, from, to);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }


    /**
     * Get a;; upcoming consultations that are currently active
     * @param userId 
     * @param from 
     * @param to 
     */
    public async GetAllUpcomingConsultations(to:Date|string) {
        try {
            //Because dates are stored in the db as utc we need to convert the dates            
            to = new Date(to).toISOString();

            return await this.consultationService.GetAllUpcomingSessions( to);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get all previous consultations that are currently active
     * @param userId 
     * @param from 
     * @param to 
     */
    public async GetAllPreviousSessions(startTime:Date|string) {
        try {
            //Because dates are stored in the db as utc we need to convert the dates            
            startTime = new Date(startTime).toISOString();
            
            let endTime = new Date().toISOString(); //our current time
            
            //We need to add an hour if we are in daylight savings time
            if(TimeHelper.CheckIsDayLightSavings(endTime)) {
                let newDate = moment(endTime);    
                endTime = new Date(newDate.add(1, 'hour').toString()).toISOString(); 
            }

            return await this.consultationService.GetAllPreviousSessions( startTime, endTime);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get all past consultations excepted deleted and consultations with the none status
     * @param userId 
     * @param from 
     * @param to 
     * @returns 
     */
    public async GetPreviousConsultations(userId:string, from:Date|string, to:Date|string) {
        try {
            //Because dates are stored in the db as utc we need to convert the dates
            const startTime = new Date(from).toISOString();            

            let endTime = new Date().toISOString(); //our current time
            
            //We need to add an hour if we are in daylight savings time
            if(TimeHelper.CheckIsDayLightSavings(endTime)) {
                let newDate = moment(endTime);    
                endTime = new Date(newDate.add(1, 'hour').toString()).toISOString(); 
            }

            
            return await this.consultationService.GetPreviousConsultationSessions(userId, startTime, endTime);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get the attendees info that are attending a consultation 
     */
    public async GetConsultationSession(id:string):Promise<ConsultationSession> {
        try {
            return await this.consultationService.GetConsultationSession(id);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Creates a single consultation session note:this will create a zoom meeting as well
     * @param consultationData 
     * @returns 
     */
    public async CreateConsultation( consultationData: IConsultation)
    {
        try
        {
            return await this.consultationService.CreateNewConsultation(consultationData);
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Creates recurring consultation meetings up to a certain date (will also create multiple zoom meetings)
     */
    public async CreateRecurringConsultations(request:RecurringConsultationRequest) {
        try {
            return this.consultationService.CreateRecurringConsultations(request);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Books consultation for a user by adding them to the attendee list
     * @param consultantId 
     * @param userId 
     * @returns 
     */
    public async BookConsultation(consultantId:string, userId:string) {
        try {
            return await this.consultationService.bookConsultation(consultantId, userId);
        } catch (error) {            
            throw error
            // return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Updates a consultation session
     * @param consultationData 
     * @returns 
     */
    public async UpdateConsultation( consultationData: IConsultation){
        try
        {
            return await this.consultationService.UpdateConsultation(consultationData);
        } catch (error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Updates all recurring consultation sessions
     * @param recurringRequest 
     * @returns 
     */
    public async UpdateRecurringConsultation(recurringRequest:RecurringConsultationRequest) {
        try {
            return await this.consultationService.UpdateRecurringConsultation(recurringRequest);
        }catch(error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Updates a single occurrence out of a recurring consultation meeting
     */
    public async UpdateConsultationOccurrence(consultationOccurrence:IConsultation) {   
        try {
            return await this.consultationService.UpdateConsultationOccurrence(consultationOccurrence);
        } catch(error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Cancels the recurring on a recurring consultation and creates a new normal consultation
     * @param consultation 
     * @returns 
     */
    public async CancelRecurringAndCreateConsultation(consultation:IConsultation) {
        try {
            return await this.consultationService.CancelRecurringAndCreateConsultation(consultation);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Withdraw a user from a consultation
     * @param consultantId 
     * @param userId 
     * @returns 
     */
    public async WithdrawFromConsultation(consultantId:string, userId:string) {
        try {
            return await this.consultationService.WithdrawFromConsultation(consultantId, userId);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    /**
     * Cancels a single consultation session
     * @param id 
     * @returns 
     */
    public async CancelConsultation(id:string) {
        try {
            return await this.consultationService.CancelConsultation(id);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    public async DeleteConsultation(id:string)
    {
        try
        {
            return await this.consultationService.Delete({id});
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async DeleteUpcomingRecurringConsultation(id:string) {
        try {
            return await this.consultationService.DeleteUpcomingRecurringConsultation(id);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async DeleteConsultationOccurrence(id:string) {
        try {
            return await this.consultationService.DeleteConsultationOccurrence(id);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get all Consultations of type Seminar
     * @param PageNumber
     * @param PageSize
     * @param isPast
     * @returns ISeminar[]
     */
    public async GetWebinars(PageNumber: number, PageSize: number, isPast: boolean) {
        try {
            return await this.consultationService.GetWebinars(PageNumber, PageSize, isPast);
        }
        catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get all seminars a user has attended
     * @param PageNumber
     * @param PageSize
     * @param userId
     * @returns ISeminar[]
     */
    public async GetWebinarsByUser(PageNumber: number, PageSize: number, userId:string) {
        try {
            return await this.consultationService.GetWebinarsByUser(PageNumber, PageSize, userId);

        }
        catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get all webinars that have recording links
     * @param PageNumber
     * @param PageSize     
     * @returns ISeminar[]
     */
    public async GetWebinarsWithRecordings(PageNumber: number, PageSize: number) {            
        try {
            return this.consultationService.GetWebinarsWithRecordings(PageNumber, PageSize);
        }
        catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * removes a user from the reserve list of a consultation
     * @param consultationId
     * @returns 
     */
    public async RemoveUserFromReserveList(consultationId:string) {
        try {
            return await this.consultationService.RemoveUserFromReserveList(consultationId);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Add recording link to a consultation
     * @param recordingLink 
     * @param consultationId 
     * @returns 
     */
    public async AddRecordingToConsultation(recordingLink:string, consultationId:string) {
        try {
            return await this.consultationService.AddRecordingToConsultation(recordingLink, consultationId);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
}