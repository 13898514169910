import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Spinner } from 'reactstrap';
import { AdditionalContentController } from '../../../../controllers/AdditionalContentController';
import { ContentCategoryController } from '../../../../controllers/ContentCategoryController';
import { GroupedAdditionalContent, IAdditionalContent } from '../../../../models/AdditionalContent/AdditionalContent';
import { ContentCategory, IContentCategory } from '../../../../models/AdditionalVideoContent/ContentCategory';
import { DeepCopy } from '../../../../models/utility/DeepCopy';
import editIconSrc from '../../../../resources/icons/icon-edit.png';
import trashIconSrc from '../../../../resources/icons/icon-trash.png';
import PlusBlueIconSrc from '../../../../resources/icons/plus_blue_icon.png';
import CustomAlert from '../../../Utilities/CustomAlert';
import CustomModal from '../../../Utilities/CustomModal';
import AdditionalContentForm from '../../AdditionalContentForm/AdditionalContentForm';
import BasicInlineForm from '../../AdminForms/InlineForms/BasicInlineForm';
import AdminTherapistContentItemList from '../AdminTherapistContentItemList';



type AdminTherapistSupportViewProps = {
    selectedContentCategory:IContentCategory
    isTagEditorOpen:boolean
    isAdmin:boolean
    toggleTagEditor:() => void
    handleAddingTagToCategory:(tagId:string) => Promise<void>
    handleUpdatingTagInCategory:(oldTagId:string, tagId:string) => Promise<void>
    handleDeletingTagInCategory:(tagToDelete:string) => Promise<void>
    updateSelectedCategory:(contentCategory:ContentCategory) => void
}

type AdminTherapistSupportViewState = {
    groupedContent:GroupedAdditionalContent[]
    isAdditionalContentModalOpen:boolean
    tagIdForForm:string
    tagForEditing:string
    tagToDelete:string
    isTagEditorOpen:boolean
    isDeleteAlertOpen:boolean
    isLoading:boolean    
}

const additionalContentController = new AdditionalContentController();

const contentCategoryController = new ContentCategoryController();

const AdminTherapistSupportView = (props:AdminTherapistSupportViewProps) => {
    const {isAdmin, selectedContentCategory, handleAddingTagToCategory} = props;


    const defaultState:AdminTherapistSupportViewState = {
        groupedContent: [],        
        isAdditionalContentModalOpen: false,
        tagIdForForm:"",
        tagForEditing:"",
        tagToDelete:"",
        isTagEditorOpen:false,
        isDeleteAlertOpen:false,
        isLoading:false,
    }

    const [state, setState] = useState(defaultState);

    const [isLoading, setIsLoading] = useState(false);    

    useEffect(() => {
        async function onComponentMount() {
            await getAdditionalContent();
        }

        onComponentMount();
    },[selectedContentCategory]);

    const getAdditionalContent = async () => {
        const {tags} = selectedContentCategory;
        
        if(tags.length < 1)
            return;

        const tagsToSearchBy = tags.map(tag => tag._id);

        try {
            setState(prevState => ({
                ...prevState,
                isLoading:true
            }))
           const additionalContentList =  await additionalContentController.GetAdditionalContentAndGroupByTag(tagsToSearchBy);

           if(!additionalContentList) {
                toast.error("Could Not Retrieve Videos/PDFs");
                return;
           }

           setState(prevState => ({
                ...prevState,
                groupedContent:additionalContentList,
                isLoading:false
           }));
            
        } catch (error) {
            
            toast.error("Could Not Retrieve Videos/PDFs");
        }
    }

    const displayAdditionalContent = (tagId:string) => {
  
        const foundContent = state.groupedContent.find(content => content.tagId === tagId);

        if(!foundContent)
            return [];
        
        foundContent.content = foundContent.content.filter((content, index) => 
        !index || (content.id !== foundContent.content[index - 1].id));

        return foundContent.content
    }    

    const setTagForEditing = (e:React.MouseEvent<HTMLButtonElement>, tagId:string) => {        
        setState(prevState => ({...prevState, tagForEditing:tagId, isTagEditorOpen: !state.isTagEditorOpen}));        
    }

    const toggleAdditionalContentForm = () => (setState(prevState => ({...prevState, isAdditionalContentModalOpen: !state.isAdditionalContentModalOpen})));

    const toggleTagEditor = () => (setState(prevState => ({...prevState, isTagEditorOpen:!state.isTagEditorOpen, tagForEditing: ""})));

    const handleTagUpdate = async (tagId:string) => {
        const oldTag = state.tagForEditing;

        props.handleUpdatingTagInCategory(oldTag, tagId);

        //TODO some type of loading
        const results = await additionalContentController.updateTagInAdditionalContentItem(oldTag, tagId);

        const foundTagGroup = state.groupedContent.find(group => group.tagId === tagId);

        //If we don't get a result back or the contentGroup is empty but exists inside our state
        if(!results || results.length < 1 && foundTagGroup) {
            toast.error("Failed To Update Tag")
            return;
        }     

        //If tag do not have additional content than we do not need to update the state
        if(results.length < 1 && !foundTagGroup) {
            toast.success("Successfully Updated Tag");
            return;
        }

        const groupedContentCopy:GroupedAdditionalContent[] = DeepCopy.copy(state.groupedContent);

        const foundIndex = groupedContentCopy.findIndex(content => content.tagId === oldTag);

        if(foundIndex === -1) {
            toast.error("Could Not Find Tag In Additional Content");
            return;
        }        
        groupedContentCopy[foundIndex].content = results[0].content;

        setState(prevState => ({...prevState, groupedContent:groupedContentCopy}));        
    }

    const handleDeleteTagFromAdditionalContent = async () => {
        
        const tagToDelete = state.tagToDelete;

        if(!tagToDelete || tagToDelete.length < 1) {
            toast.error("Cannot Delete Tag");
            return;
        }

        props.handleDeletingTagInCategory(tagToDelete);

        const results = await additionalContentController.DeleteTagInAdditionalContentItems(tagToDelete);

        const foundTagGroup = state.groupedContent.find(group => group.tagId === tagToDelete);

        if(!results && foundTagGroup) {
            toast.error("Failed To Delete Tag In Additional Content");
        }

        const groupedContentCopy:GroupedAdditionalContent[] = DeepCopy.copy(state.groupedContent);

        groupedContentCopy.filter(items => items.tagId !== tagToDelete);

        setState(prevState => ({
            ...prevState,
            groupedContent:groupedContentCopy,
            isDeleteAlertOpen:false,
            tagToDelete: ""
        }));
    }

    const handleDeleteClick = (tagId:string) => {
        setState(prevState => ({
            ...prevState,
            tagToDelete:tagId,
            isDeleteAlertOpen:true
        }));        
        // setCategoryForDelete(contentCategory);
        // toggleDeleteAlert();
    }

    const handleAddContentClick = (tagId:string) => {
        setState(prevState => ({
            ...prevState,
            tagIdForForm:tagId
        }));

        toggleAdditionalContentForm();
    }    

    const submitAdditionalContent = (additionalContent:IAdditionalContent) => {
        const tags = additionalContent.tagList.map(tag => tag._id);
        
        const groupedContentCopy:GroupedAdditionalContent[] = DeepCopy.copy(state.groupedContent);

        for(let tag of tags) {
            const foundIndex = groupedContentCopy.findIndex(content => content.tagId === tag);

            if(foundIndex > -1) {
                groupedContentCopy[foundIndex].content.push(additionalContent);
            } else {
                groupedContentCopy.push({
                    tagId:tag,
                    content:[additionalContent]
                });
            }
        }

        setState(prevState => ({...prevState, groupedContent:groupedContentCopy}));        
    
        toggleAdditionalContentForm();
    }

    const updateAdditionalContentItems = (msg:string) => {
        //TODO set is loading
        getAdditionalContent();

        toast.success(msg);
    }

    /**
     * Refreshes video content through vimeo
     */
    const refreshVideoContent = async () => {
        setIsLoading(true);
        await additionalContentController.RefreshVideoAdditionalContent();
        await getAdditionalContent();
        setIsLoading(false);
    }

    const toggleDeleteAlert = () => setState(prevState => ({...prevState, isDeleteAlertOpen: !state.isDeleteAlertOpen}));

    /**
     * When a sub category finish dragging update the order of that category
     * and update the database
     * @param results 
     * @returns 
     */
    const afterDragUpdateSubCategoryDatabase = async (results:any) => {
        if (!results.destination) return;
        
        const contentCategory:IContentCategory = DeepCopy.copy(props.selectedContentCategory);        

        const [reorderedItem] = contentCategory.tags.splice(results.source.index, 1);
        
        contentCategory.tags.splice(results.destination.index, 0, reorderedItem);
        await contentCategoryController.UpdateContentCategory(contentCategory);
        props.updateSelectedCategory(contentCategory)
    }

    return (
        <>
            {
                isLoading ?
                (
                    <Spinner ></Spinner>
                ) 
                : 
                (
                <DragDropContext onDragEnd={afterDragUpdateSubCategoryDatabase}>                    
                    <div className="category-title-container main-category-container">
                        <h2 className="category-title">{props.selectedContentCategory.name}
                            {isAdmin && ( 
                                
                                    <button                                                                         
                                        className="btn-tag-add"
                                        onClick={toggleTagEditor}
                                    >
                                        
                                        <img src={PlusBlueIconSrc} alt="Blue Plus Icon" />
                                        
                                        <span className='tag-add-text'>
                                            Add Sub Category
                                        </span>
                                    </button>
                                
                            )}
                        </h2>
                        <div className="standard-content-btn-container refresh-container">
                            <button 
                                className='btn-therapist-refresh btn-cbit-minor'
                                onClick={refreshVideoContent}
                            >
                                Refresh Video Content
                            </button>
                        </div>
                    </div>
                    <Droppable droppableId='sub-category-drop-zone'>
                        {(Provided, snapshot) => (
                            <div className="tag-list" {...Provided.droppableProps} ref={Provided.innerRef}>            
                                {
                                    selectedContentCategory.tags.map((tag, index) => (
                                        <>
                                            <Draggable
                                                key={tag._id}
                                                draggableId={tag._id}
                                                index={index}
                                            >  
                                            {(dragProvided, snapshot) => (        
                                                <div className="tag-item" ref={dragProvided.innerRef} {...dragProvided.draggableProps}>
                                                    <div className="category-title-container">
                                                        {
                                                            state.isTagEditorOpen && tag._id === state.tagForEditing ? 
                                                            (
                                                                <BasicInlineForm 
                                                                    text={tag._id} 
                                                                    inputCSSClass={"tag-title"}
                                                                    placeHolderText={'Enter A Name For This Tag'} 
                                                                    onSubmit={handleTagUpdate} 
                                                                    onBlur={toggleTagEditor}                    
                                                                /> 
                                                            )
                                                            :
                                                            (
                                                                <h4 className="tag-title" {...dragProvided.dragHandleProps}>{tag._id}</h4>
                                                            )
                                                        }   
                                                        <>
                                                            {
                                                                isAdmin && (
                                                                    <>
                                                                        <div className="therapist-panel-btn-container">
                                                                            <button                                                                                       
                                                                                className="btn-cbit-icon"
                                                                                onClick={() => handleAddContentClick(tag._id)}
                                                                            >                                                            
                                                                                <img src={PlusBlueIconSrc} alt="Blue Plus Icon" />                                                            
                                                                            </button>
                                                                            <button 
                                                                                className="btn-cbit-icon"
                                                                                onClick={(e) => setTagForEditing(e, tag._id)}
                                                                            >
                                                                                <img src={editIconSrc} alt="Edit Icon" />
                                                                            </button>
                                                                            <button 
                                                                                className="btn-cbit-icon"
                                                                                onClick={() => handleDeleteClick(tag._id)}
                                                                            >
                                                                                <img src={trashIconSrc} alt="Trash Icon" />
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </>                         
                                                    </div>                                                    
                                                    <AdminTherapistContentItemList 
                                                        selectedContentCategory={selectedContentCategory}
                                                        subCategoryId={tag._id} 
                                                        allAdditionalContentGrouped={state.groupedContent} 
                                                        isAdmin={isAdmin} 
                                                        updateAdditionalContentItems={updateAdditionalContentItems}                                                    
                                                    />                                                   
                                                </div>
                                            )}
                                            </Draggable>
                                        </>
                                    ))
                                }
                                <>
                                    {
                                        state.isTagEditorOpen && state.tagForEditing === "" && (
                                            <div className='therapist-tag-editor-container'>                                        
                                                <BasicInlineForm 
                                                    text={''} 
                                                    inputCSSClass={"tag-title"}
                                                    placeHolderText={'Enter A Name For This Tag'} 
                                                    onSubmit={handleAddingTagToCategory} 
                                                    onBlur={toggleTagEditor}                    
                                                />                                            
                                            </div>
                                        )
                                    }                                        
                                </>
                            </div>
                        )}
                    </Droppable>
                <CustomModal isOpen={state.isAdditionalContentModalOpen} toggle={toggleAdditionalContentForm}>
                    <AdditionalContentForm 
                        defaultSelectedTags={state.tagIdForForm}
                        onFormSubmit={submitAdditionalContent}
                    />
                </CustomModal>
                <CustomAlert 
                    header={'ARE YOU SURE YOU WANT TO DELETE: ' + state.tagToDelete} 
                    text={'Once you click "Delete", the category will be deleted immediately.'} 
                    primaryBtnText={'DELETE'} 
                    secondaryBtnText={'CANCEL'} 
                    isOpen={state.isDeleteAlertOpen} 
                    primaryBtnSubmit={handleDeleteTagFromAdditionalContent} 
                    secondaryBtnSubmit={toggleDeleteAlert} 
                    toggleAlert={toggleDeleteAlert}
                />
        </DragDropContext>
        )
    }
    </>
    )
}

export default AdminTherapistSupportView;