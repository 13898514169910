import { AxiosError } from "axios";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { BaseReactService } from "./interfaces/BaseReactService";

export class ArtifactServices extends BaseReactService {
    private originalHeaders = this.baseAxiosConfiguration.headers;
    /**
     *
     */
    constructor() {
        super("Artifact/");

    }

    public async uploadPDFToMongo(file:FormData) {
        const endPoint = "Artifact/UploadPdfToDatabase/";

        try {
            this.baseAxiosConfiguration.headers = {'Content-Type': 'application/x-www-form-urlencoded'}
            return await this.Post(file, endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async GetPdfDownloadUrl(id:string) {
        const endPoint = "Artifact/DownloadPDFToClient/";
        return this.GetUrl(endPoint, id);
    }

    public async GetPDFDownloadAndLogUrl (location:string, id:string ){
        const endPoint = "Artifact/DownloadPDFAndCreateLog";
        let url =  this.GetUrl(endPoint, "");
        url += `?location=${location}&id=${id}`;
        return url;
    }

    public GetFileAndLogUrl (location:string, id:string ){
        const endPoint = "Artifact/DownloadPDFAndCreateLog";
        let url =  this.GetUrl(endPoint, "");
        url += `?location=${location}&id=${id}`;
        return url;
    }

    public async UpdateArtifact(artifactFormData:FormData) {
        const endPoint = "Artifact/UpdateArtifact/";

        try {
            this.baseAxiosConfiguration.headers = {'Content-Type': 'application/x-www-form-urlencoded'}

            //@ts-ignore
            const results = await this.Put({},artifactFormData, endPoint);

            this.baseAxiosConfiguration.headers = this.originalHeaders;

            return results;

        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async DownloadPdfFile(id:string) {
        const endPoint = "Artifact/DownloadPDFToClient/";

        try {
            return await this.Get({id}, endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    public async GetDownloadName(id:string) {
        const endPoint = "Artifact/GetDownloadName/";

        try {
            return await this.Get({id}, endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }
}
