import {BaseReactService} from "./interfaces/BaseReactService";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import { AxiosError } from "axios";

export class UserLessonContentService extends BaseReactService {

    constructor(){
        super("UserLessonContent/");
    }
    
    public async GetUserLessonContentByLessonId(id: { id:string }){
        const endpoint = 'UserLessonContent/GetUserLessonContentByLessonId/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    public async GetAndCreateAssignedUserLessonContentByLessonId(id: { id:string }){
        const endpoint = 'UserLessonContent/GetAndCreateAssignedUserLessonContentByLessonId/'
        try
        {
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }
}