import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {ErrorHandler} from "../../models/utility/ErrorHandler";

interface IBaseApiService
{
    baseAxiosConfiguration:AxiosRequestConfig;
    Post(model:any, remoteApiUrl:string):any;

}

/**
 * Base class for talking to APIs not controlled by EDUTrainer
 */
export class BaseRemoteApiService implements IBaseApiService {

    public baseAxiosConfiguration:AxiosRequestConfig = {        
        headers: {'Content-Type': 'multipart/form-data'}
    }


    /**
     * Calls a post on a remote api url
     * @param model
     * @param remoteApiUrl
     * @returns
     */
    public async Post(model:any, remoteApiUrl:string){
        try{            
            var response:AxiosResponse = await axios.post(remoteApiUrl, model, this.baseAxiosConfiguration)
            return response.data;
        }catch(error){
          return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

}