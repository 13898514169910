import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import React, { FormEvent, useEffect, useState } from "react";
import { CbitUrls } from "../../models/utility/CbitUrls";
import { StripeError, StripePaymentElementOptions } from "@stripe/stripe-js";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { CurrentUserState } from "../../store/CurrentUser";
import "./PaymentForm.css";


enum paymentStatus  {
    canceled = 'canceled',
    processing = 'processing',
    requires_action = 'requires_action',
    requires_capture = 'requires_capture',
    requires_confirmation = 'requires_confirmation',
    requires_payment_method = 'requires_payment_method',
    succeeded = 'succeeded',
}

type PaymentFormProps = {
    returnUrl:string
}

/**
 * Form for rendering stripe payments
 * @param props 
 * @returns 
 */
const PaymentForm = (props:PaymentFormProps) => {
    const {
        returnUrl
    } = props;

    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>((state )=> state.currentUser);
    
    const stripe = useStripe();
    const elements = useElements();  

    const [message, setMessage] = useState<string | null | undefined>(null);
    const [paymentError, setPaymentError] = useState<StripeError | null>(null);

    const [isLoading, setIsLoading] = useState(true);
    const [isFormReady, setIsFormReady] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }
    
        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        if(elements) {

        }
    
        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            if(!paymentIntent) {
                return;
            }
            
            switch (paymentIntent.status) {
                case paymentStatus.succeeded:                    
                    setMessage("Payment succeeded!");                    
                break;
                case paymentStatus.processing:
                setMessage("Your payment is processing.");
                break;
                case paymentStatus.requires_payment_method:
                setMessage("Your payment was not successful, please try again.");
                break;
                default:
                setMessage("Something went wrong.");
                break;
            }
        });
      }, [stripe]);

      const handleSubmit = async (e:FormEvent) => {
        if(!userStore) {
            toast.error("User is not signed in");
            return;
        }
        e.preventDefault();

        if(!userStore) {
            return;
        }
        
        if (!stripe || !elements) {
          // Stripe.js hasn't yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
        const toastLoadingId = "paymentToastId";
        setIsLoading(true);
        toast.loading("Submitting Payment", {id: toastLoadingId})
        
        const {error} = await stripe.confirmPayment({
            elements,
            confirmParams: {                                       
              return_url: returnUrl,
              receipt_email: userStore.userProfile.email,
            },
        });

        //Anything below occurs if an error occurred.
        if (error.type === "card_error" || error.type === "validation_error") {
            setPaymentError(error);
            setMessage(error.message);
            if(error.message) {
                toast.error(error.message);
            }
        } else {
            const unexpectedErrMsg = "An unexpected error occurred"
            toast.error(unexpectedErrMsg);
            setMessage(unexpectedErrMsg);
        } 
        toast.dismiss(toastLoadingId)
        setIsLoading(false);
      };

      const paymentElementOptions:StripePaymentElementOptions = {
        layout: "tabs"
      };

      const onFormReady = () => {
        setIsLoading(false);
        setIsFormReady(true);
      }

      return (
        <form id="payment-form" onSubmit={handleSubmit}>        
          <PaymentElement 
                id="payment-element" 
                options={paymentElementOptions} 
                onReady={onFormReady}
            />
            {
                message && paymentError && 
                <div id="payment-message" className="form-error-container">
                    {message}
                </div>
            }
          <div className="sign-up-btn-container">
            {isFormReady && (
                <>
                    <button className="btn-cbit-primary" disabled={isLoading || !stripe || !elements} id="submit">
                        <span id="button-text">
                            {isLoading && elements && stripe ? <div >Submitting Payment</div> : "Pay"}
                        </span>
                    </button>
                </>
            )}                  
          </div>                    
        </form>
      );

}

export default PaymentForm;