import ToolTipWrapper from "../../../Utilities/ToolTipWrapper/ToolTipWrapper";
import React from "react";

type PostFunctionToolBarItemProps = {
    toggleFlag: boolean,
    toolTipText: string,
    buttonClass?: string,
    icon1?: any,
    iconClass?: string,
}

const PostFunctionReportedToolBarItem = ({toolTipText, buttonClass, icon1, iconClass}: PostFunctionToolBarItemProps) => {
    return (
        <span>
            <ToolTipWrapper toolTipText={toolTipText}>
                <button className={`social-connection-post-toolbar-item ${buttonClass ? buttonClass : ''}`}
                >
                    <>
                        <span className={iconClass}>
                            {icon1}
                         </span>
                    </>
                </button>
            </ToolTipWrapper>
        </span>
    )
}

export default PostFunctionReportedToolBarItem