import {AxiosError} from "axios";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import {INotification} from "../models/Inbox/Notification";
import {BaseWebService} from "./interfaces/BaseWebServices";

export class NotificationService extends BaseWebService {

    constructor(){
        super("Notification");
    }

    /**
     * Get all notifications
     * @param id
     * @param PageNumber
     * @param PageSize
     * @returns {Promise<INotification[]>}
     */
    public async GetNotifications(id: string, PageNumber: number, PageSize: number){
        const endpoint = 'GetNotifications'
        try{
            let response = await this.Get(endpoint, {id: id, PageNumber: PageNumber, PageSize: PageSize})
            return response.data;
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    /**
     * Get a count of all unread notifications
     * @param id
     * @returns Count of unread notifications
     */
    public async GetUnreadNotificationsCount(id: string) {
        const endpoint = 'GetUnreadNotificationsCount'
        try {
            let response = await this.Get(endpoint, { id: id })
            return response.data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    /**
     * Mark a notification as read
     * @param id
     * @returns count of notifications marked as read
     */
    public async MarkAsRead(id: string) {
        const endpoint = 'MarkAsRead'
        try {
            let response = await this.Put(endpoint,{id:id, objIn:{hasRead:true}});
            return response.data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    /**
     * Mark all notifications as read
     * @param id
     * @returns void
     */
    public async MarkAllAsRead(id: string) {
        const endpoint = 'MarkAllAsRead'
        try {
            let response = await this.Get(endpoint,{id:id});
            return response.data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    /**
     * Delete a notification
     * @param id
     * @returns count of notifications deleted
     */
    public async DeleteNotification(id: string) {
        const endpoint = 'Delete'
        try {
            let response = await this.Delete(endpoint, {id});
            return response.data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    /**
     * Creates a notification for the consultant that someone booked their consultation
     * @param consultationId 
     * @param attendeeId 
     * @returns 
     */
    public async ConsultationBookNotification(consultationId:string, attendeeId:string) {
        const endpoint = "ConsultationBookNotification";
        try {
            let response = await this.Get(endpoint, {consultationId, attendeeId});
            return response.data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Creates a notification for the consultant that someone withdrew from their consultation
     * @param consultationId 
     * @param attendeeId 
     * @returns 
     */
    public async ConsultationWithdrawNotification(consultationId:string, attendeeId:string) {
        const endpoint = "ConsultationWithdrawNotification";
        try {
            let response = await this.Get(endpoint, {consultationId, attendeeId});
            return response.data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }
}