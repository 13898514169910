import {BaseReactService} from "./interfaces/BaseReactService";
import {AxiosError} from "axios";
import {ILessonContent} from "../models/lesson/LessonContent";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import {SiteLocation} from "../models/Enums/SiteLocation";

export class ContentViewLogService extends BaseReactService {

    constructor(){
        super("ContentViewLog/");
    }

    public async UpdateViewCount(location: SiteLocation, name:string):Promise<ILessonContent[] | never>
    {
        const endpoint = 'ContentViewLog/UpdateViewCount/';
        try
        {
            const params = {location:location, name:name};
            return await this.CustomGet(params,endpoint);
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async GetCSV():Promise<ILessonContent[] | never>
    {
        const endpoint = 'ContentViewLog/GetContentViewCSV';
        try
        {
            return await this.CustomGet({},endpoint);
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async GetCSVUrl() {
        let path = this.GetUrl("ContentViewLog/GetContentViewCSV");

        return path;
    }

    /**
     * Get content view log by date range
     * @param from 
     * @param to 
     * @returns 
     */
    public async GetCsvByDate(from:string, to:string) {
        const endpoint = 'ContentViewLog/GetContentViewCsvByDate';
        try {
            return await this.GetCustomUrl({from, to}, endpoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
}
