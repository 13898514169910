import React from 'react';
import './SuccessfulBookingAlert.css';

type SuccessfulBookingAlertProps = {
    toggleModal:() => void
}

const SuccessfulBookingAlert = (props:SuccessfulBookingAlertProps) => {
    const {toggleModal} = props;

    return (
        <div className="success-booking-container cbit-dialog">
            <div className="cbit-header success-booking-header">YOU HAVE SUCCESSFULLY BOOKED A SESSION</div>
            <p className='cbit-text success-booking-text'>
                A confirmation has been sent to your email. Please check your inbox for further notification on instructions on joining your consultation session.
            </p>
            <div className="success-booking-btn-container">
                <button onClick={toggleModal} className="btn-cbit-primary">Ok</button>
            </div>
        </div>
    )
}

export default SuccessfulBookingAlert