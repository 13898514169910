import React, {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import './FamilySupportAdminDashboard.css';
import {SupportContentController} from "../../controllers/SupportContentController";
import FamilySupportAdminDashboardPanel from "./FamilySupportAdminDashboardPanel";
import {SupportContent, supportLink} from "../../models/AdditionalContent/SupportContent";
import moment from "moment/moment";
import {ContentCategory} from "../../models/AdditionalVideoContent/ContentCategory";
import {DeepCopy} from "../../models/utility/DeepCopy";
import CustomAlert from "../Utilities/CustomAlert";
import PlusBlueIconSrc from "../../resources/icons/plus_blue_icon.png";
import CustomModal from "../Utilities/CustomModal";
import AdditionalContentForm from "../AdminDashboard/AdditionalContentForm/AdditionalContentForm";
import SupportContentForum from "./SupportContentForum";
import FamilySupportAdminView from "./FamilySupportAdminView";
import supportContentForum from "./SupportContentForum";
import {ArtifactController} from "../../controllers/ArtifactController";
import FamilySupportLayout from "../FamilySupport/layout/FamilySupportLayout";

const FamilySupportAdminDashboard = () => {


    const [state, setState] = useState<SupportContent[]>([]);
    const [selectedState, setSelectedState] = useState<SupportContent>({
        id: "", name: "", createdAt: null, updatedAt: null, order: -1, links: []
    });

    const [selectedLinkIndex, setSelectedLinkIndex] = useState(0);
    const [selectedLink, setSelectedLink] = useState<supportLink>({
        title: "", external: true, link: "", content: "", mongoFile: ""
    })

    const [itemToDelete, setItemToDelete] = useState<string>("");

    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [isDeleteContentAlertOpen, setIsDeleteContentAlertOpen] = useState(false);

    const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);

    const supportContentController = new SupportContentController();
    const artifactController = new ArtifactController();

    useEffect(() => {
        async function onComponentMount(setData: any) {
            let data = await supportContentController.GetSupportContent()
            setData(data);
        }

        onComponentMount(setState);
    }, []);

    /**
     * Open or close the delete alert
     * @returns
     */
    const toggleDeleteAlert = () => setIsDeleteAlertOpen(!isDeleteAlertOpen);
    const toggleLinkModal = () => {
        setSelectedLink({title: '', content: '', link: '', external: true, mongoFile: ""})
        setIsLinkModalOpen(!isLinkModalOpen);
    }

    const toggleDeleteLinkAlert = () => {

        setIsDeleteContentAlertOpen(!isDeleteContentAlertOpen);
    }
    const toggleEditLinkModal = (link: supportLink) => {
        setSelectedLink(link)
        setIsLinkModalOpen(!isLinkModalOpen);
    }

    const DeleteCategory = async () => {

        toggleDeleteAlert();

        await supportContentController.DeleteSupportContent(itemToDelete);
        let data = await supportContentController.GetSupportContent()

        setState(data);
        setItemToDelete("");

    }

    const DeleteSupportContent = async () => {

        toggleDeleteLinkAlert();

        let category = selectedState;
        let removedItem = category.links.splice(selectedLinkIndex, 1);

        await artifactController.DeleteArtifact(removedItem[0].mongoFile);

        let updatedContent = await supportContentController.UpdateSupportContent(category);

        let data = await supportContentController.GetSupportContent()
        setSelectedLinkIndex(0);
        setState(data);

    }

    const returnCategoryName = (id: string) => {
        for (var i = 0; i < state.length; i++) {
            if (state[i].id == id) {
                return state[i].name;
            }
        }
        return "N/A"
    }

    const returnContentName = (index: number) => {
        try {
            return selectedState.links[index].title

        } catch (exception) {
            return "N/A"

        }
    }

    const UpdateCategoryContent = async (contentCategory: SupportContent) => {
        //Update Copy in the DB
        let updatedContent = await supportContentController.UpdateSupportContent(contentCategory);

        let data = await supportContentController.GetSupportContent()
        setState(data);
    }

    const CreateNewSupportContent = async (link: supportLink) => {
        let content = selectedState;
        content.links.push(link);

        toggleLinkModal();
        let updatedContent = await supportContentController.UpdateSupportContent(content);

        let data = await supportContentController.GetSupportContent()
        setState(data);
    }

    const UpdateSupportContent = async (link: supportLink) => {
        let content = selectedState;
        content.links[selectedLinkIndex] = link;

        toggleLinkModal();
        let updatedContent = await supportContentController.UpdateSupportContent(content);

        let data = await supportContentController.GetSupportContent()
        setState(data);
    }

    const updateSupportLinkOrder = async (links: supportLink[]) => {
        let content = selectedState;
        content.links = links;

        let updatedContent = await supportContentController.UpdateSupportContent(content);

        let data = await supportContentController.GetSupportContent()
        setState(data);
    }


    const AddNewSupportContent = async (name: string) => {
        // Send Up New Data
        let request: SupportContent = {
            links: [],
            updatedAt: null,
            createdAt: moment(new Date()).toISOString(),
            order: state.length,
            name: name,
            id: ""
        };

        let newData = await supportContentController.CreateSupportContent(request)

        // Update State
        const contentCategoriesCopy: SupportContent[] = DeepCopy.copy(state);
        contentCategoriesCopy.push(newData);

        setState(contentCategoriesCopy);
    }

    return (
        <FamilySupportLayout isAdmin={true}
                             contentCategories={state}
                             updateCategory={UpdateCategoryContent}
                             addNewContentCategory={AddNewSupportContent}
                             setSelectedContentCategory={setSelectedState}
                             setItemToDelete={setItemToDelete}
                             toggleDeleteAlert={toggleDeleteAlert}
                             selectedCategory={selectedState}
        >
            <>
                {selectedState.id == "" ? (
                    <>
                        <h2 className="category-title">No Categories Has Been Selected</h2>
                    </>
                ) : (
                    <>
                        <div className="category-title-container main-category-container">
                            <h2 className="category-title">{selectedState.name}
                                <button className="btn-tag-add" onClick={toggleLinkModal}>
                                    <img src={PlusBlueIconSrc} alt="Blue Plus Icon"/>
                                    <span className='tag-add-text'>Add Support Content</span>
                                </button>
                            </h2>
                        </div>
                        <FamilySupportAdminView isAdmin={true}
                                                contentLinks={selectedState.links}
                                                setSelectSupportLink={setSelectedLinkIndex}
                                                toggleEditLinkModal={toggleEditLinkModal}
                                                toggleDeleteLinkAlert={toggleDeleteLinkAlert}
                                                updateLinkOrder={updateSupportLinkOrder}
                        />
                    </>
                )}
            </>
            <CustomModal isOpen={isLinkModalOpen}
                         toggle={toggleLinkModal}
            >
                <SupportContentForum content={selectedLink}
                                     updateOldLink={UpdateSupportContent}
                                     createNewLink={CreateNewSupportContent}
                />
            </CustomModal>
            <CustomAlert
                header={'ARE YOU SURE YOU WANT TO DELETE: ' + returnCategoryName(itemToDelete)}
                text={'Once you click "Delete", the category will be deleted immediately.'}
                primaryBtnText={'DELETE'}
                secondaryBtnText={'CANCEL'}
                isOpen={isDeleteAlertOpen}
                primaryBtnSubmit={DeleteCategory}
                secondaryBtnSubmit={toggleDeleteAlert}
                toggleAlert={toggleDeleteAlert}
            />
            <CustomAlert
                header={'ARE YOU SURE YOU WANT TO DELETE: ' + returnContentName(selectedLinkIndex)}
                text={'Once you click "Delete", the content will be deleted immediately.'}
                primaryBtnText={'DELETE'}
                secondaryBtnText={'CANCEL'}
                isOpen={isDeleteContentAlertOpen}
                primaryBtnSubmit={DeleteSupportContent}
                secondaryBtnSubmit={toggleDeleteLinkAlert}
                toggleAlert={toggleDeleteLinkAlert}
            />
        </FamilySupportLayout>)
}

export default FamilySupportAdminDashboard;
