import axios, {AxiosError, AxiosResponse} from 'axios';
import {LessonContentService} from "../services/LessonContentService";
import {ILessonContent} from "../models/lesson/LessonContent";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import moment from "moment";

export class LessonContentController 
{
    constructor()
    {
        this.lessonContentService = new LessonContentService();
    }

    private lessonContentService:LessonContentService;

    public async CreateLessonContent(lessonData: ILessonContent) 
    {
        try
        {
            lessonData.createdAt = moment(new Date()).toISOString();
            lessonData.updatedAt = moment(new Date()).toISOString();
            return this.lessonContentService.Post(lessonData);
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    public async GetLessonContent(id:string): Promise<ILessonContent>
    {
        try
        {
            return this.lessonContentService.Get({id});
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

  
    public async GetAllLessonContent()
    {
        try
        {
            return this.lessonContentService.GetAll();
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    public async GetAllLessonContentByLessonId(id:string)
    {
        try
        {
            return this.lessonContentService.GetAllByLessonId(id);
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    public async UpdateLessonContent(lessonData: ILessonContent) 
    {
        try
        {
            lessonData.updatedAt = moment(new Date()).toISOString();
            return this.lessonContentService.Put({id:lessonData.id},lessonData);
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    public async DeleteLessonContent(id:string) 
    {
        try
        {
            return this.lessonContentService.Delete({id});
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
}