
import  IconCaretLeft from '../../resources/icons/icon-caret-left.png';
import  IconCaretDown from '../../resources/icons/icon-caret-down.png';
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ReactNode } from "react";

type CbitAccordionProps = {
    accordionId:string,
    summary:string,
    children:ReactNode,
    expanded:string|false,
    onchange:(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => void
}

export default function CbitAccordion(props:CbitAccordionProps) {
    const {
        accordionId,
        summary,
        children,
        expanded,
        onchange
    } = props;

    return (
        <Accordion 
            className="cbit-accordion"
            expanded={expanded === accordionId} 
            onChange={onchange(accordionId)}
        >
            <AccordionSummary
                itemID={accordionId}
                className="cbit-accordion-summary"
                expandIcon={
                        expanded === accordionId ?  
                        <i className="bi bi-caret-up-fill"></i>                   
                        : 
                        <i className="bi bi-caret-left-fill"></i>
                    }                            
                aria-controls={`${accordionId}-content`}
                id={`${accordionId}-header`}
            >
                {summary}                
            </AccordionSummary>
            <AccordionDetails
                className="cbit-accordion-details"
            >                
                {children}                
            </AccordionDetails>
        </Accordion>                
    )
}