import {BaseReactService} from "./interfaces/BaseReactService";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import {UserLessonContentLog} from "../models/utility/UserLessonContentLog";
import { AxiosError } from "axios";

export class UserLessonContentLogService extends BaseReactService {

    constructor(){
        super("UserLessonContentLog/");
    }

    public async LogUserLessonContent(postContent: UserLessonContentLog){
        const endpoint = 'UserLessonContentLog/LogUserLessonContent'
        try{
            return await this.Post(postContent,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }
}