import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { AdditionalContentController } from '../../../controllers/AdditionalContentController';
import { ArtifactController } from '../../../controllers/ArtifactController';
import { ContentCategoryController, privateContentCategoryId } from '../../../controllers/ContentCategoryController';
import useFields from '../../../hooks/UseFields';
import { AdditionalContent, IAdditionalContent, Subcategory, _idObject } from '../../../models/AdditionalContent/AdditionalContent';
import { ContentCategory } from '../../../models/AdditionalVideoContent/ContentCategory';
import { AdditionalContentEnum } from '../../../models/Enums/AdditionalContentEnum';
import { DeepCopy } from '../../../models/utility/DeepCopy';
import { FileHelper } from '../../../models/utility/FileHelper';
import { VimeoError } from '../../../models/Vimeo/VimeoError';
import PdfUploader from '../../Utilities/FileUploaders/PdfUploader';
import './AdditionalContentForm.css';

type AdditionalContentFormProps = {
    defaultSelectedTags:string
    additionalContent?:AdditionalContent
    isEditing?:boolean
    onFormSubmit:(additionalContent:IAdditionalContent) => void
}

type AdditionalContentFormState = {
    publicCategories:ContentCategory[]
    privateCategory:ContentCategory | null | undefined
    isModalOpen:boolean
    hasUploadErrors:boolean
}

type FormState = {
    contentType:AdditionalContentEnum
    videoLink:string
    name:string
    description:string
    file:File | undefined | null
    selectedCategory:ContentCategory | null | undefined
    tagList:string[]
    lessonIdList:string
}

type ErrorState = {
    listOfTagsInUse:string[]
}

const contentCategoryController = new ContentCategoryController();

const additionalContentController = new AdditionalContentController();

const artifactController = new ArtifactController();

/**
 * form for adding additional content to the Therapist toolbox
 * @param props 
 * @returns 
 */
const AdditionalContentForm = (props:AdditionalContentFormProps) => {
    const {defaultSelectedTags, additionalContent, isEditing ,onFormSubmit} = props;

    let defaultFields:FormState = {
        contentType: AdditionalContentEnum.video,
        name: "",
        videoLink: "",
        lessonIdList: "",
        tagList: [defaultSelectedTags],
        selectedCategory: null,
        description: '',
        file: null
    }

    if(additionalContent && isEditing) {
        defaultFields = {
            contentType: additionalContent.type,
            name: additionalContent.name,
            videoLink: additionalContent.videoLink,
            selectedCategory: null,
            description: additionalContent.description,
            tagList: additionalContent.tagList.map(tag => tag._id),
            lessonIdList: additionalContent.lessonIdList.map(lessonId => lessonId._id).toString(),
            file:null
        }
    }

    const defaultState:AdditionalContentFormState = {
        publicCategories: [],
        privateCategory: null,
        isModalOpen:false,
        hasUploadErrors:false
    }

    const defaultErrorState:ErrorState = {
        listOfTagsInUse: []
    }

    const [formData, handleChange, resetFormData, setFormData] = useFields(defaultFields);

    useEffect(() => {
        async function onComponentMount() {
            if(isEditing && contentType === AdditionalContentEnum.pdf) {
                await getFileFromContent()
            }
        }
        onComponentMount();
    },[]);

    /**
     * Gets the file from the api and sets up the file to be able to be downloaded
     * @returns 
     */
    const getFileFromContent = async () => {

        if(!additionalContent || additionalContent.artifactId.length <= 0) {
            toast.error("File is Missing");
            return
        }

        const artifact = await artifactController.GetArtifact(additionalContent.artifactId);



        if(!artifact.attachment) {
            toast.error("Could Not Find File");
            return;
        }

        //We need to convert the base64 into a blob so we can add pdf to it
        const newBlob = FileHelper.convertBase64ToBlob(artifact.attachment as unknown as string, "application/pdf");

        const newFIle = new File([newBlob], artifact.name);

        setFormData((prevState:any) => ({
            ...prevState,
            file: newFIle
        }));
    }

    /**
     * Updates taglist when that select taglist field change
     * @param e 
     */
    const handleTagSelectChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
        const name = e.target.name;

        const selectedOptions = e.target.selectedOptions;

        const newTags = Array.from(selectedOptions, options => options.value);

        const tagListCopy:string[] = DeepCopy.copy(formData.tagList);

        setFormData((prevState:any) => ({
            ...prevState,
            tagList:newTags
        }));
    }

    /**
     * Wrapper for updating the file upload state   
     * @param file 
     */
    const handleFileUpload = (file:File | null | undefined) => {
        setFormData((prevState:any) => ({
            ...prevState,
            file
        }));
    }

    const [state, setState] = useState(defaultState);

    const {selectedCategory, contentType}:FormState = formData;

    const [errorState, setErrorState] = useState(defaultErrorState);

    useEffect(() => {
        async function onComponentMount() {
            await getContentCategories();
        }
        onComponentMount();
    },[]);


    useEffect(() => {
        async function onModalClose() {
            await getContentCategories();
        }
        onModalClose();
    },[state.isModalOpen])

    /**
     * Get an enum of our content type
     * @returns 
     */
    const getContentType = () => {
        //because forms converts values to string; we need to convert back
        const convertedType:AdditionalContentEnum = parseInt(`${contentType}`);

        return convertedType
    }

    /**
     * Makes a call to get the content categories and update state
     */
    const getContentCategories = async () => {
        const categories = await contentCategoryController.GetAllCategories();

        const privateIndex = categories.findIndex(category => category.id === privateContentCategoryId);

        const privateCategory = categories.splice(privateIndex, 1).pop();

        setState(prevState => ({
            ...prevState,
            privateCategory,
            publicCategories: categories
        }));
    }

    /**
     * Prepare the list of lessons Ids to be sent to the database and also checks to see if any of them are taken.
     * @param lessonIdList
     */
    const handleLessonIds = (lessonIdList:string) => {
        const listOfIds = lessonIdList.split(",");

        const formattedListOfIds = listOfIds.map(str => str.trim().toLowerCase());

        const convertedListOfIds = formattedListOfIds.map(id => {
            const idObj = new _idObject();
            idObj._id = id;

            return idObj
        });

        return convertedListOfIds;
    }


    /**
     * Handles the additional content item form submission
     * @param e
     * @returns
     */
    const handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const {tagList, lessonIdList}:FormState = formData;

        const listOfTagIds = tagList.map(tag => ({_id: tag}));

        const convertedListOfIds = handleLessonIds(lessonIdList);

        //Because HTML forms converts our content type to a string
        const convertedType = parseInt(`${contentType}`);

        if(convertedType === AdditionalContentEnum.video) {
            submitVideoContent(listOfTagIds, convertedListOfIds, convertedType);
        }

        if(convertedType === AdditionalContentEnum.pdf) {
            SubmitPdfContent(listOfTagIds, convertedListOfIds, convertedType);
        }
    }

    /**
     * Handles additional content creation for video content type
     * @param listOfTags
     * @param listOfLessonIds
     */
    const submitVideoContent = async (listOfTags:_idObject[], listOfLessonIds:_idObject[], contentType:AdditionalContentEnum) => {
        const {name, description, videoLink, tagList, lessonIdList, file}:FormState = formData;

        const formattedLink = videoLink.trim().toLowerCase();

        if(isEditing) {
            handleEditSubmit(videoLink, contentType, listOfTags, listOfLessonIds);
            return;
        }

        const subCategories:Subcategory[] = listOfTags.map(tag =>  ({_id: tag._id, order: 999}))

        let additionalContent = new AdditionalContent({
            id: "",
            name,
            type: contentType,
            videoLink: formattedLink,
            videoLength : "",
            description,
            tagList: subCategories,
            lessonIdList: listOfLessonIds,
            artifactId: "",
            thumbnailUrl: "",
            thumbnailUrlWithPlayButton: ""
        });

        additionalContent = await additionalContentController.createAdditionalVideoContent(additionalContent);

       if(additionalContent == null || additionalContent instanceof VimeoError) {
            toast.error("Vimeo Video Information Could Not Be Retrieve. Please Check Your Link.");
            return;
       }

       resetFormData();

       toast.success("Successfully added Video");

       onFormSubmit(additionalContent);
    }

    /**
     * Handles additional content creation for PDF content type
     * @param listOfTags
     * @param listOfLessonIds
     */
    const SubmitPdfContent = async (listOfTags:_idObject[], listOfLessonIds:_idObject[], contentType:AdditionalContentEnum) => {
        const {name, description, file}:FormState = formData;

        if(!file) {
            toast.error("Either The File Is Missing Or You Need To Upload A File.");
            setState(prevState => ({
                ...prevState,
                hasUploadErrors:true
            }));
            return;
        }

        if(isEditing) {
            editPdfContent(listOfTags, listOfLessonIds, file)
            return;
        }

        //Creating the artifact with the file in the database
        const artifactObj = await artifactController.UploadPdfFiles(file);

        const subCategories:Subcategory[] = listOfTags.map(tag =>  ({_id: tag._id, order: 999}))

        let newAdditionalContent = new AdditionalContent({
            id: "",
            name,
            type: contentType,
            videoLink: "",
            videoLength : "",
            description,
            tagList: subCategories,
            lessonIdList: listOfLessonIds,
            artifactId: artifactObj.id,
            thumbnailUrl: "",
            thumbnailUrlWithPlayButton: ""
        });

        newAdditionalContent = await additionalContentController.createAdditionalContent(newAdditionalContent);

        if(newAdditionalContent == null) {
            toast.error("Failed To Create PDF Content");
            return;
        }

        resetFormData();

        toast.success("Successfully added PDF");

        onFormSubmit(newAdditionalContent);

    }

    /**
     * Edits the passed additional content if it is of pdf type
     * @param listOfTags
     * @param listOfLessonIds
     * @param file
     * @returns
     */
    const editPdfContent = async (listOfTags:_idObject[], listOfLessonIds:_idObject[], file:File) => {
        const {name, description}:FormState = formData;

        if(!additionalContent) {
            toast.error("Cannot Find PDF Content");
            return;
        }

        //Updating the artifact first in the database
        const artifactObj = await artifactController.UpdateArtifact(additionalContent.artifactId,file);

        if(!artifactObj) {
            toast.error("Failed To Upload PDF File");
            return;
        }

        const subCategories:Subcategory[] = [];

        for(let tag of listOfTags) {
            const foundIndex = additionalContent.tagList.findIndex(tagListItem => tagListItem._id === tag._id);
            if(foundIndex === -1) {
                subCategories.push({_id: tag._id, order: 999})
            } else {
                subCategories.push(additionalContent.tagList[foundIndex]);
            }
        }

        let updateAdditionalContent = new AdditionalContent({
            ...additionalContent,
            name,
            description,
            tagList: subCategories,
            lessonIdList: listOfLessonIds,
            artifactId: artifactObj.id
        });

        updateAdditionalContent = await additionalContentController.updateAdditionalContentItem(updateAdditionalContent);

        if(updateAdditionalContent == null) {
            toast.error("Failed To Create PDF Content");
            return;
        }

        resetFormData();

        onFormSubmit(updateAdditionalContent);
    }


    /**
     * handles when editing an additional contentItem
     * @param videoLink
     * @param type
     * @param tagList
     * @param lessonIdList
     * @returns
     */
    const handleEditSubmit = async (videoLink:string, type:AdditionalContentEnum, tagList:_idObject[], lessonIdList:_idObject[]) => {
        if(!isEditing || !additionalContent) {
            return;
        }

        const subCategories:Subcategory[] = [];

        for(let tag of tagList) {
            const foundIndex = additionalContent.tagList.findIndex(tagListItem => tagListItem._id === tag._id);
            if(foundIndex === -1) {
                subCategories.push({_id: tag._id, order: 999})
            } else {
                subCategories.push(additionalContent.tagList[foundIndex]);
            }
        }

        const updateAdditionalContent:AdditionalContent = {
            ...additionalContent,
            videoLink,
            type,
            tagList:subCategories,
            lessonIdList
        }


        const results = await additionalContentController.updateAdditionalContentItem(updateAdditionalContent);

        if(!results) {
            toast.error("Failed To Update Additional Content: " + additionalContent.name)
            return;
        }

        resetFormData();

        onFormSubmit(results);
    }

    /**
     * Display a error that tag is already being used
     * @returns 
     */
    const displayTagsInUseError = () => {
        if(errorState.listOfTagsInUse.length <= 0) {
            return;
        }

        let error = "";

        errorState.listOfTagsInUse.forEach(tagInUse => error += tagInUse + ", ");

        error = error.substring(0, error.length - 2) + ' already Exists';

        return error;
    }

    return (
        <>
        <div className="additional-content-form">
            <form action="" className="form" onSubmit={handleSubmit}>
                {
                    !isEditing ?
                    (
                        <h2 className="form-title">Create Additional Content</h2>
                    )
                    :
                    (
                        <h2 className="form-title">Update Additional Content</h2>
                    )
                }
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="contentType" className="form-label">Content Type</Label>
                            <Input
                                disabled={isEditing}
                                id="contentType"
                                className='form-input'
                                name="contentType"
                                type='select'
                                onChange={handleChange}
                                value={contentType}
                            >
                                <option value={AdditionalContentEnum.video}>
                                    Video
                                </option>
                                <option value={AdditionalContentEnum.pdf}>
                                    PDF
                                </option>
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                {
                    getContentType() === AdditionalContentEnum.video ?
                    (
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="videoLink" className="form-label">Video Link</Label>
                                    <Input
                                        required
                                        id="videoLink"
                                        name="videoLink"
                                        className='form-input'
                                        value={formData.videoLink}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    )
                    :
                    (
                        <>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="pdfName">Name</Label>
                                        <Input
                                            required
                                            id="pdfName"
                                            name="name"
                                            className='form-input'
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="pdfDescription">Description</Label>
                                        <Input
                                            required
                                            id="pdfDescription"
                                            name="description"
                                            type='textarea'
                                            className='form-input'
                                            value={formData.description}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <PdfUploader
                                            isLoading={false}
                                            fileList={ formData.file == null ? [] : [formData.file]}
                                            hasErrors={state.hasUploadErrors}
                                            handleChange={handleFileUpload}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </>
                    )
                }
                <Row>
                    <Col>
                        <FormGroup>
                            <Label className='form-label' for="tagList">Categories</Label>
                            {
                                state.publicCategories.length <= 0 ?
                                (
                                    <Input disabled className='form-input' name='category' id='category' defaultValue={""} type='select'>
                                        <option value="">Please Create Some Categories Before Proceeding</option>
                                    </Input>
                                )
                                :
                                (
                                    <Input
                                        multiple
                                        id='tagList'
                                        name='tagList'
                                        className='form-input'
                                        type='select'
                                        //@ts-ignore
                                        onChange={handleTagSelectChange}
                                        value={formData.tagList}
                                    >
                                        {state.publicCategories.map(category => (
                                            <optgroup key={category.id} label={category.name}>
                                                {category.tags.map(tag => (
                                                    <option key={tag._id} value={tag._id}>
                                                        {tag._id}
                                                    </option>
                                                ))}
                                            </optgroup>
                                        ))}
                                    </Input>
                                )
                            }
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label className='form-label' for='lessonIdList'>Lesson Id (Optional)</Label>
                            <Input
                                invalid = {errorState.listOfTagsInUse.length > 0}
                                className='form-input'
                                name='lessonIdList'
                                id='lessonIdList'
                                type='text'
                                onChange={handleChange}
                                value={formData.lessonIdList}
                            >
                                {state.privateCategory != null ?
                                (
                                    <>
                                    {state.privateCategory.tags.map(tag => (
                                        <option key={tag._id} value={tag._id}>
                                            {tag._id}
                                        </option>
                                    ))}
                                    </>
                                )
                                :
                                (
                                    <></>
                                )
                                }
                            </Input>
                            <FormFeedback>
                                {displayTagsInUseError()}
                            </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <button type="submit" className='btn-cbit-primary'>Submit</button>
                        </FormGroup>
                    </Col>
                </Row>
            </form>
        </div>
        </>
    )
}

export default AdditionalContentForm;
