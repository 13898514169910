import { AxiosError } from "axios";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { PublishedLessonService } from "../services/PublishedLessonService";
import { ILesson } from "../models/lesson/Lesson";

/**
 * Controller for handling communication with PublishedLesson Services
 */
export class PublishedLessonController{ 
    constructor(){        
        this.LessonService = new PublishedLessonService();
    }
    private LessonService:PublishedLessonService;

    /**
     * Get the last published lesson
     * @returns 
     */
    public async GetLastLesson() : Promise<ILesson> {
        try {
            return await this.LessonService.GetLastLesson();
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    } 
}