import { useState } from "react";
import CbitAccordion from "../../Utilities/CbitAccordion";
import "./Faqs.css";
import { Link } from "react-router-dom";

export default function FAQ() {
    const [expanded, setExpanded] = useState<string |false>(false)

    const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

    return (
        
            <div className="faq-page">
                {/* <div className="faq-page-inner"> */}
                    <h1 className="cbit-header">FAQ</h1>
                    <div className="faq-items-container">
                        <CbitAccordion                             
                            accordionId="panel1"
                            expanded={expanded}
                            onchange={handleChange}
                            summary="WHAT IS CBIT?"                            
                        >
                            <p>
                                The core elements of CBIT include psychoeducation, habit reversal, function-based assessment and intervention, and a collection of supplemental techniques.
                            </p>
                            <p>
                                Psychoeducation involves providing information about the diagnosis, symptoms, and clinical course of tic disorders. It also provides an opportunity to deliver an overview of- and rationale for- treatment, and for therapists to “debunk” myths about tics and misconceptions surrounding treatment.
                            </p>
                            <p>
                                The rationale for habit reversal is based on the idea that tics are involuntary behaviors that function to reduce an aversive premonitory urge and are automatic, meaning they often occur outside the individual’s awareness. Habit reversal is a set of procedures that includes awareness training, competing response training, and social support. Awareness training teaches the patient to recognize when a specific tic is about to occur. Competing response training teaches the patient to prevent or interrupt the tic in order to disrupt the urge-tic cycle. Finally, social support involves identifying and involving someone in the patients’ life to support them with treatment compliance and success.
                            </p>
                            <p>
                                Function-based assessment and intervention involves systematically identifying external antecedents and consequences that exacerbate tics and then- based on this information- developing strategies for eliminating or reducing the impact of these factors.
                            </p>
                            <p>
                            In addition to the core elements, CBIT contains several supplemental techniques. Relaxation strategies are used to reduce stress, given that stress can exacerbate tics. Motivational strategies are used to increase treatment engagement. Relapse prevention strategies ensure that patients have mastered CBIT skills prior to terminating treatment so they can apply CBIT skills to new tics as they emerge in the future. Finally, a variety of tic-healthy lifestyle recommendations are provided.
                            </p>
                        </CbitAccordion>
                        <CbitAccordion 
                            accordionId="panel2"
                            expanded={expanded}
                            onchange={handleChange}
                            summary="HOW DOES A BEHAVIORAL INTERVENTION HELP WITH TICS?"                            
                        >
                            <p>
                                Although tics are neurologically based, they can be influenced by individual and environmental factors. At its core, CBIT is a collection of individually tailored therapeutic procedures designed to identify and modify internal and external factors that worsen tics. Research supports CBIT as the first line intervention for Tic Disorders and Tourette Disorder.
                            </p>
                        </CbitAccordion>
                        <CbitAccordion 
                            accordionId="panel4"
                            expanded={expanded}
                            onchange={handleChange}
                            summary="WHAT IS INCLUDED IN CBIT TRAINER?"                            
                        >
                            <p>
                            CBIT Trainer is a comprehensive, on-line training that includes 12 hours of didactic
                            instruction (12 hours of CEU credit is available for certain licensees), video examples of
                            skills, downloadable forms, instruments, and session syllabi. After completing the
                            training, you will have access to 3, ½ hour sessions with a CBIT expert who can help you
                            with a case that you are working with. In addition to the training, CBIT Trainer offers
                            continuing education opportunities (webinars), the opportunity to be on the CBIT
                            Trainer referral network to receive and give referrals, and a Social Connection platform
                            where you will be able to interact with other CBIT trained professionals to ask questions
                            and connect about all things CBIT. After registering for CBIT Trainer, you will have access
                            to this entire suite of interactive content for one year. After this year, if you would like
                            to continue to have access to the CBIT Trainer platform, you may do so for a nominal
                            fee.
                            </p>
                        </CbitAccordion>
                        <CbitAccordion 
                            accordionId="panel5"
                            expanded={expanded}
                            onchange={handleChange}
                            summary="WHAT WILL I BE ABLE TO DO ONCE TRAINED?"                            
                        >
                            <p>
                            Once you have completed the CBIT Trainer program and your 3 consultation sessions,
                            you will be eligible to be listed on the Referral section of the CBIT Trainer platform. Most
                            importantly, you will have the knowledge, tools, and confidence to treat people
                            successfully with Tic Disorders.
                            </p>
                        </CbitAccordion>
                        <CbitAccordion 
                            accordionId="panel6"
                            expanded={expanded}
                            onchange={handleChange}
                            summary="IS THIS A LIVE TRAINING OR PRE-RECORDED?"                            
                        >
                            <p>
                            CBIT Trainer is a didactic, pre-recorded training. Throughout the training you will see
                            hundreds of videos that explain how to correctly utilize the skills being taught. Also
                            included are examples of different types of tics, examples of different interventions for
                            the varying types of tics, and video clips from mock therapy sessions that illustrate how
                            the skills should be taught, how to troubleshoot, and how to successfully treat Tic
                            Disorders.
                            </p>
                        </CbitAccordion>
                        <CbitAccordion 
                            accordionId="panel7"
                            expanded={expanded}
                            onchange={handleChange}
                            summary="CAN I GET CEU CREDIT FOR CBIT TRAINER?"                            
                        >
                            <p>
                            12 hours of CEU credit is available through Amedco for no extra charge. If you would like
                            to receive CEU credit, please click the button on the Continuing Education page that will
                            tell you how to obtain your credit. CEU credit is only available for Licensed
                            Psychologists, Licensed Professional Counselors, and other Master's level clinicians,
                            depending on your licensure.
                            </p>
                        </CbitAccordion>
                        <span className="faq-contact-message">Don't see what you are looking for? <Link to="/contact-us">Send us a message!</Link></span>
                    </div>                    
                {/* </div> */}
            </div>
        
    )
}