import {UserLessonContentLogService} from "../services/UserLessonContentLogService";
import {UserLessonContentLog} from "../models/utility/UserLessonContentLog";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import {AxiosError} from "axios";

export class UserLessonContentLogController {
    constructor(){
        this.userLessonContentLogService = new UserLessonContentLogService();
    }

    private userLessonContentLogService:UserLessonContentLogService;
    
    public async PostLog( postContent:UserLessonContentLog)
    {
        try{
            return this.userLessonContentLogService.LogUserLessonContent(postContent);
        }catch(ex){
            return ErrorHandler.catchApiError((ex as AxiosError))
        }
    }
    
}