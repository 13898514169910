import queryString from "querystring";
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router';
import { Spinner } from 'reactstrap';
import { ConsultationReceiptController } from '../../../../controllers/ConsultationReceiptController';
import { UserController } from '../../../../controllers/UserController';
import { IUserBio } from '../../../../models/module/UserProfile';
import ConsultantBookingAvailability from './ConsultantBookingAvailability';
import ConsultantBookingProfile from './ConsultantBookingProfile';
import './BookingPage.css';
import { ConsultationController } from "../../../../controllers/ConsultationController";
import { ConsultationReceipt, IConsultationReceipt } from "../../../../models/consultations/ConsultationReceipt";
import { IConsultation } from "../../../../models/consultations/Consultation";
import { IConsultationSession } from "../../../../models/consultations/ConsultationSession";

const userController = new UserController();
const receiptController = new ConsultationReceiptController();
const consultationController = new ConsultationController();

type BookingPageState = {
    consultants:IUserBio[]
    bookedSession:IConsultationSession | null //this is only used when a successful purchase happens
    isLoading:boolean
    isConsultantAvailabilityShowing:boolean
    selectedConsultant:IUserBio|null
}

/**
 * Component for users to select a consultant and viewing their schedule
 * ConsultantBookingAvailability shows the consultant schedule
 * @returns 
 */
const BookingPage = () => {
    const defaultState:BookingPageState = {
        consultants: [],
        isLoading: false,
        bookedSession: null,
        isConsultantAvailabilityShowing: false,
        selectedConsultant:null
    }

    const [state, setState] = useState(defaultState); 
    const { search } = useLocation(); 

    useEffect(() => {
        async function onComponentMount() {
            setState(prevState => ({...prevState, isLoading:true}));
            await getConsultants();   
            await showUserHasPaidForSession();         
            setState(prevState => ({...prevState, isLoading:false}));
        }
        onComponentMount();
    },[]);

    const getConsultants = async () => {
        try {
            

            const consultants = await userController.GetConsultants();

            setState(prevState => ({
                ...prevState,
                consultants,                                
            }));

        } catch (error) {
            toast.error("Failed To Retrieve Consultants");
        }
    }

    /**
     * Select a consultant and show the selected consultants availability schedule
     * @param consultant 
     */
    const selectConsultantAndShowAvailability = (consultant:IUserBio) => {
        setState(prevState => ({
            ...prevState,
            selectedConsultant:consultant,
            isConsultantAvailabilityShowing:true
        }));
    }

    /**
     * Hide the consultants availability schedule and deselect the consultant 
     */
    const hideConsultantCalendar = () => {
        setState(prevState => ({
            ...prevState,
            selectedConsultant:null,
            isConsultantAvailabilityShowing:false
        }));
    }

    //@ts-ignore
    const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));

    /**
     * Function to show the success message of a user purchasing a session and booking it
     */
    const showUserHasPaidForSession = async () => {
        const params = new URLSearchParams(search);
        const consultationId = params.get("consultationId");

        //The length the guid should be
        if(!consultationId || consultationId.length != 24) {
            return;
        }

        //We are setting a timeout because the receipt may not have been created when the redirect occurs
        await waitFor(3000);

        

        const receipt:IConsultationReceipt = await receiptController.GetUserReceiptByOriginalConsultation(consultationId);

        //If we have a receipt we can go get the consultationSession
        if(!receipt) {
            return;
        }       

        const consultationSession = await consultationController.GetConsultationSession(receipt.originalConsultationId);

        if(!consultationSession) {
            toast.error("Could not find consultation session")
            return;
        }

        const {
                consultant, 
                consultation
            } = consultationSession;

        setState(prevState => ({
            ...prevState,
            selectedConsultant:consultant,
            bookedSession:consultationSession,
            isConsultantAvailabilityShowing:true
        }));
    }


    return (                
        <>
            {
                state.isLoading ? 
                (
                    <Spinner></Spinner>
                ) 
                : 
                (
                    <>
                    {
                        state.isConsultantAvailabilityShowing && state.selectedConsultant != null ? 
                        (
                            <ConsultantBookingAvailability 
                                consultant={state.selectedConsultant} 
                                bookedSession={state.bookedSession}
                                returnToConsultants={hideConsultantCalendar}
                            />
                        )
                        :
                        (                    
                            <div className='book-consultations-container'>
                                <h2 className="consultation-header">
                                    Book Consultations
                                </h2>
                                <h4 className="cbit-label">
                                    What are CBIT consultations?                             
                                </h4>
                                <p className="cbit-text">
                                    As a part of CBIT Trainer, you receive 3 consultations with a CBIT expert to help you hone your skills with your first patient with tics. These sessions are typically in a group format and you are able to schedule them at times that are convenient to you. We want you to have support while working with your patient. These consultations are not supervision, but support for your work with your client. After you have completed your 3 consultation sessions, you are eligible to be listed on the TAAs provider list on their website, as well as on the referral portal on the CBIT Trainer website. The purpose of these consultation calls is to ask the CBIT experts about the CBIT protocol while you are working with a patient. Once you recruit a patient, please move on to doing your consultation calls.
                                </p>
                                <h4 className="consultation-sub-header">
                                    Consultants
                                </h4>
                                <div className="available-consultants-container">
                                    {
                                        state.isLoading ? 
                                        (
                                            <Spinner ></Spinner>
                                        ) 
                                        : 
                                        (
                                            state.consultants.map(consultant => (
                                                <React.Fragment key={consultant.id}>
                                                    <ConsultantBookingProfile 
                                                        consultant={consultant} 
                                                        viewConsultantAvailability={selectConsultantAndShowAvailability}
                                                    />            
                                                </React.Fragment>
                                            ))
                                        )   
                                    }
                                </div>
                            </div>
                        )
                    }
                    </>
                )
            }
        </>        
    )
}

export default BookingPage;
