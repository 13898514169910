import React from "react"
import MyRegistrationsModal from "./MyRegistrationsModal";
import {ISeminar} from "../../../../models/consultations/Seminar";
import moment from "moment";

type MyRegistrationsItemProps = {
   seminar: ISeminar,
    updateRegistrations: any
}

const MyRegistrationsItem = ({seminar, updateRegistrations}: MyRegistrationsItemProps) => {

    return (
        <div key={seminar.consultation.id} className='my-registrations-item'>
            <h4 className='my-registrations-item-title'>
                {seminar.consultation.name}
            </h4>
            <div className='my-registrations-item-info-container'>
                <h5 className='my-registrations-item-info-title instructor'>
                    Instructor: <span>{seminar.consultant.prefix !== '' ?  seminar.consultant.prefix + ' ' + seminar.consultant.firstName + ' ' + seminar.consultant.lastName : seminar.consultant.firstName + ' ' + seminar.consultant.lastName}</span>
                </h5>
                <h5 className='my-registrations-item-info-title'>
                    Date: <span> {moment(seminar.consultation.startDate).format("ddd, MMM Do YY")}</span>
                </h5>
                <h5 className='my-registrations-item-info-title'>
                    Time: <span>{moment(seminar.consultation.startDate).format('LT')} - {moment(seminar.consultation.endDate).format('LT')} CT</span>
                </h5>
                <h5 className='my-registrations-item-info-title'>
                    Webinar Link: <span><a href={seminar.consultation?.userLinks[0].registeredLink} target='_blank' rel='noreferrer'>{seminar.consultation?.userLinks[0].registeredLink}</a></span>
                </h5>
            </div>
            <div className="my-registrations-item-description-container">
                <p className="my-registrations-item-description">
                    {seminar.consultation?.description}
                </p>
            </div>
            <div className="my-registrations-item-btn-container">
                <MyRegistrationsModal seminar={seminar} updateRegistrations={updateRegistrations} />
            </div>
        </div>
    )
}

export default MyRegistrationsItem