import { ConsultationReview } from "../models/consultations/ConsultationReview";
import {BaseReactService} from "./interfaces/BaseReactService";

/**
 * Service for talking with consultation review endpoints
 */
export class ConsultationReviewService extends BaseReactService{
    constructor()
    {
        super("ConsultationReview/");
    }
    
    /**
     * Checks to see if a user needs to do a review
     * @param userTime 
     * @returns 
     */
    public async CheckIfUserNeedsToReview(userTime:Date|string) {
        const endPoint = "ConsultationReview/CheckIfUserNeedsToReview/";

        return await this.CustomGet({userTime}, endPoint);
    }

    /**
     * Creates a new consultation review
     * @param consultationReview 
     * @returns 
     */
    public async CreateConsultationReview(consultationReview:ConsultationReview) {
        const endPoint = "ConsultationReview/CreateConsultationReview/";
        return await this.Post(consultationReview, endPoint);
    } 
    
    /**
     * Get reviews by the passed in date range
     * @param userId 
     * @param from 
     * @param to 
     * @returns 
     */
    public async GetConsultationReviewByDate(userId:string, from:Date|string, to:Date|string) {
        const endPoint = "ConsultationReview/GetConsultationReviewsByDate/";
        return await this.CustomGet({userId, from, to}, endPoint);
    }

    /**
     * Checks a consultation to see if review has been done for it by the passed in user
     * @param userId 
     * @param consultationId 
     * @returns 
     */
    public async CheckReviewForConsultation(userId:string, consultationId:string) {
        const endPoint = "ConsultationReview/CheckReviewForConsultation/";
        return await this.CustomGet({userId, consultationId}, endPoint);
    }

    /**
     * Get all reviews done by consultants
     * @returns 
     */
    public async GetConsultantsReviews() {
        const endPoint = "ConsultationReview/GetConsultantsReviews";
        return await this.CustomGet({}, endPoint);
    }

    /**
     * Get a list of reviews by a list of consultation ids
     */
    public async GetReviewsByConsultations(consultationIds:string[],  assignedUserId:string){
        const endPoint = "ConsultationReview/GetConsultationReviewsByConsultations";
        return await this.GetWithObject({consultationIds, assignedUserId}, endPoint)
    }

    /**
     * Get all reviews done by attendees
     * @returns 
     */
    public async GetAttendeesReviews() {
        const endPoint = "ConsultationReview/GetAttendeesReviews";
        return await this.CustomGet({}, endPoint);
    }

    /**
     * Get review with reviewer info and consultation info
     * @param reviewId 
     * @returns 
     */
    public async GetReviewWithReviewer(reviewId:string){
        const endPoint = "ConsultationReview/GetReviewWithReviewer";
        return await this.CustomGet({reviewId}, endPoint);
    }


}
