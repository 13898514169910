import {NotificationService} from "../services/NotificationService";
import {AxiosError} from "axios";
import {ErrorHandler} from "../models/utility/ErrorHandler";

export class NotificationController {

    constructor() {
        this.notificationService = new NotificationService();
    }

    private notificationService: NotificationService;

    /**
     * Get all notifications for a user
     * @param id
     * @param PageNumber
     * @param PageSize
     * @returns {Promise<INotification[]>}
     */
    public async GetNotifications(id: string, PageNumber: number, PageSize: number){
        try{
            return await this.notificationService.GetNotifications(id, PageNumber, PageSize);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    /**
     * Get a count of all unread notifications
     * @param id
     * @returns Count of unread notifications
     */
    public async GetUnreadNotificationsCount(id: string) {
        try {
            return await this.notificationService.GetUnreadNotificationsCount(id);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    /**
     * Mark a notification as read
     * @param id
     * @returns count of notifications marked as read
     */
    public async MarkAsRead(id: string) {

        try {
            let data = await this.notificationService.MarkAsRead(id);
            return data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    /**
     * Mark all notifications as read
     * @param id
     * @returns void
     */
    public async MarkAllAsRead(id: string) {

        try {
            let data = await this.notificationService.MarkAllAsRead(id);
            return data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    /**
     * Delete a notification
     * @param id
     * @returns count of notifications deleted
     */
    public async DeleteNotification(id: string) {

        try {
            return await this.notificationService.DeleteNotification(id);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    /**
     * Creates a notification for the consultant that someone booked their consultation
     * @param consultationId 
     * @param attendeeId 
     * @returns 
     */
    public async ConsultationBookNotification(consultationId:string, attendeeId:string) {
        try {
            return await this.notificationService.ConsultationBookNotification(consultationId, attendeeId);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Creates a notification for the consultant that someone withdrew from their consultation
     * @param consultationId 
     * @param attendeeId 
     * @returns 
     */
    public async ConsultationWithdrawNotification(consultationId:string, attendeeId:string) {
        try {
            return await this.notificationService.ConsultationWithdrawNotification(consultationId, attendeeId);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

}