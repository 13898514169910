export enum AgeGroupEnum {
    Children = 1,
    Adults = 2,
    All = 3,
}

/**
 *  labels for display the age group enum
 */
export const ageGroupLabels= {
    [AgeGroupEnum.Children]: "Children",
    [AgeGroupEnum.Adults]: "Adults",
    [AgeGroupEnum.All]: "Both",
}

/**
 * Ui friendly age group text
 */
export const ageGroupUi = {
    [AgeGroupEnum.Children]: "Children",
    [AgeGroupEnum.Adults]: "Adults",
    [AgeGroupEnum.All]: "Children & Adults",
}
