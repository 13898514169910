import React from 'react';
import ReactPlayer from 'react-player';
import { baseImageUrl, variantName } from '../../../../../../controllers/ImageManagementController';
import RenderHTML from '../../../../../Utilities/RenderHTML';

type textItemProps = { 
    bodyText:string
    imageId:string 
    imageFile?:File
}



const TextViewItem = (props:textItemProps) => {
    
    const {bodyText, imageId, imageFile} = props;

    /**
     * Creates a url from a file
     * @param image 
     * @returns 
     */
    const createUrlFromFile = (image:File)  => {
        return URL.createObjectURL(image);
    }

    return (
        <div className="admin-view-item">
            <div className="admin-view-image-container">
            {
                imageFile ? 
                (
                    <>
                        <img src={createUrlFromFile(imageFile)} alt="Text Lesson Image" />
                    </>
                ) 
                : 
                (
                    <>
                        {
                            imageId && imageId.length > 0 && (
                                <img src={baseImageUrl + imageId + variantName} alt="Text Lesson Image" />
                            )
                        }
                    </>
                )
            }
      
            </div>
        
            <RenderHTML cssClass='admin-view-item-text' HTML={bodyText} />                       
        </div>
    )
}

export default TextViewItem;