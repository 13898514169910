/**Enum for the type of events that can be performed on the calendar */
export enum calendarEventsEnum {
    selectDateTime = "selectDateTime",
    beforeCreateEvent = "beforeCreateEvent",
    beforeUpdateEvent = "beforeUpdateEvent",
    beforeDeleteEvent = "beforeDeleteEvent",
    afterRenderEvent = "afterRenderEvent",
    clickDayName = "clickDayName",
    clickEvent = "clickEvent",
    clickMoreEventsBtn = "clickMoreEventsBtn",
    clickTimezonesCollapseBtn = "clickTimezonesCollapseBtn",
}