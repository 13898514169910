import { ErrorHandler } from "../models/utility/ErrorHandler";
import { BaseReactService } from "./interfaces/BaseReactService";

export class VimeoService extends BaseReactService {

    constructor() {        
        super("Vimeo/");        
    }

    /**
     * Uploads an image to an image management service
     */
    public async GetVideoDuration(id:string) {
        const endPoint = 'Vimeo/GetVideoDuration/';

        try {
            return await this.Get({id}, endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as any);
        }
    }

    /**
     * To get a thumbnail from vimeo you have to go through their API and the thumbnails image sources only last an hour
     */
    public async GetVideoThumbnail(id:string) {
        const endPoint = 'Vimeo/GetVideoThumbnailLink';
        try {
            return await this.Get({id}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError(error as any);
        }
    }

}