import * as React from "react";
import { Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import { LessonController } from "../../../controllers/LessonController";
import { ModuleController } from "../../../controllers/ModuleController";
import { PartialModuleTreeModel } from "../../../models/partialModels/PartialModuleTreeModel";
import { DeepCopy } from "../../../models/utility/DeepCopy";
import { ErrorHandler } from "../../../models/utility/ErrorHandler";
import { sortModule } from "../../../models/utility/SortModule";
import { ApplicationState } from "../../../store";
import * as UserTreeView from "../../../store/UserModuleAccordionStore";
import { AdminBranchLevelOne } from "./AdminAccordionItems/AdminBranchLevelOne";
import { AdminBranchLevelTwo } from "./AdminAccordionItems/AdminBranchLevelTwo";
import { AdminLessonLevelOne } from "./AdminAccordionItems/AdminLessonLevelOne";

type AccordionItemProps =
    UserTreeView.currentUserAccordionProps
    & typeof UserTreeView.actionCreators
    & {module:PartialModuleTreeModel, depth:number, navigateToModule:any, discardChanges:boolean}

type AccordionItemState =
    {
        moduleItemState: PartialModuleTreeModel,
        isLoading:boolean
    } & {
        selectedModule: {
            currentModule: PartialModuleTreeModel,
            parentId:string
        }
    }

const emptyModule = {
    id: '',
    originalDocumentId: '',
    type: 0,
    order: 0,
    name: '',
    description: '',
    isViewable: true,
    isDisabled: false,
    isEditable: true,
    isOptional: true,
    isComplete: false,
    isOpen: false,
    isNext:false,
    children: [],
}

class AdminModuleAccordionItem extends React.PureComponent<AccordionItemProps,AccordionItemState>
{
    _isMounted = false;
    private ModController: ModuleController = new ModuleController();    
    private LessController : LessonController = new LessonController();
    
    constructor(props:any)
    {
        super(props);
        this.state = this.cleanState;
    }

    private cleanState: AccordionItemState = 
    {
        moduleItemState: DeepCopy.copy(emptyModule),  
        isLoading:false,
        selectedModule: {
            currentModule: DeepCopy.copy(emptyModule),
            parentId: ""
        }
    }

    async componentDidMount()
    {
        this._isMounted = true;        

        if(this._isMounted) {
            this.getTreeViewData();
        }

    }    

    async componentDidUpdate(prevProps:AccordionItemProps) {

        // if(this.props.selectedModule.id !== prevProps.selectedModule.id) {
        //     // toggleAccordion(this.props.selectedModule.id, prevProps.selectedModule.id, this.props.parentModule);         
        // }

        if(this.props.discardChanges){         
               
            // this.getTreeViewData();
        }
    }

    async componentWillUnmount() {
        this._isMounted = false;
    }

    private getTreeViewData = async () => {
        try
        {              
            this.setState(prevState => ({
                ...prevState,
                isLoading:true
            }))
            
            let core = this.props.module;
            
            if(core != null)
            {
                // core.children = [];
                // let adminModules: IAdminTreeModule[] = await this.ModController.GetAdminTreeModuleByParentId(core.id);
                // let adminLessons: IAdminTreeModule[] = await this.LessController.GetAdminTreeLessonByParentId(core.id);

                // //@ts-ignore
                // core.children = adminModules.map((mod) => {
                //     return mod
                // })
                
                // adminLessons.map((lesson) => {
                //     //@ts-ignore
                //     core.children.push(lesson);
                // })
                
                
                sortModule(core.children, false);
                
                this.setState(prevState => ({
                    ...prevState,
                    moduleItemState: core,
                    isLoading:false
                }))                
                
            }
            
        }catch(error)
        {
            ErrorHandler.generalError((error));
        }
    }

    // async componentDidUpdate(prevProps:Readonly<AccordionItemProps>, prevState:Readonly<AccordionItemState>, snapshot?:any){
        
    //     if(this.props.selectedModule.id !== '' && this.props.isUpdating)
    //     {
            
    //         if(this.props.selectedModule.id === prevState.moduleItemState.id)
    //         {
                                
    //             let core = this.state.moduleItemState;
                
    //             // core = JSON.parse(JSON.stringify(this.props.selectedModule));
                
    //             this.setState(prevState => ({
    //                 ...prevState,
    //                 moduleItemState: core,
    //             }))              
    //         }    
    //     }
        
    // }
    
    SelectLesson(module:PartialModuleTreeModel)
    {
        this.props.SetSelectedModule(module);
    }
    
    
    private getTreePath = (node:PartialModuleTreeModel, arr:any[], id:string) => {            
        if(node.id !== "")
            arr.push({...node});

        if(node.id === id) {
            return true
        }
        
            let i;
            
            for(i = 0; i < node.children.length; i++) {
                                        
                if(this.getTreePath(node.children[i], arr, id)) {
                    return true
                }
            }                    

        arr.pop();
        return false;

    }

    public render()
    {        
        return(
            <React.Fragment>
                
                {!this.state.isLoading  &&(
                    <>
                {this.props.module.type== 1 &&(
                    <>
                        {this.props.depth == 0 &&(
                            <>
                                {/* Chapter Level */}
                                <div className="admin-chapter-item" >
                                    <AdminBranchLevelOne                                         
                                        selectedModuleId={this.props.selectedModule.id}
                                        module={this.props.module}
                                        parentModule={this.props.parentModule}
                                        callback={()=>this.props.navigateToModule(this.props.module)}
                                    >
                                        {this.props.module.name}
                                    </AdminBranchLevelOne>
                                </div>
                                    <Accordion.Collapse data-key={this.props.module.id} eventKey={this.props.module.id}>
                                        <Accordion flush>
                                            {this.props.module?.children?.map((module) =>
                                                (
                                                    <AdminModuleAccordionItem key={module.id}
                                                                              selectedModule={this.props.selectedModule}
                                                                              parentModule={this.props.parentModule}
                                                                              nextLessonId={this.props.nextLessonId}
                                                                              RemoveUserAccordion={this.props.RemoveUserAccordion}
                                                                              children={this.props.children}
                                                                              selectedContent={this.props.selectedContent}
                                                                              SetSelectedContent={this.props.SetSelectedContent}
                                                                              SetSelectedModule={this.props.SetSelectedModule}
                                                                              SetUserAccordion={this.props.SetUserAccordion}
                                                                              GetUserAccordion={this.props.GetUserAccordion}
                                                                              isUpdating={this.props.isUpdating}
                                                                              staticContext={this.props.staticContext}
                                                                              history={this.props.history}
                                                                              location={this.props.location}
                                                                              module={module}
                                                                              depth={this.props.depth+1}
                                                                              match={this.props.match}
                                                                              navigateToModule={this.props.navigateToModule}
                                                                              discardChanges={this.props.discardChanges}
                                                    />
                                                ))}
                                        </Accordion>
                                    </Accordion.Collapse>
                            </>
                        )}
                        {this.props.depth == 1 &&(
                            <>
                                {/* Lesson Groups */}
                                <div className="admin-lessons" >
                                    <AdminBranchLevelTwo
                                        module={this.props.module}
                                        callback={()=>this.props.navigateToModule(this.props.module)}
                                        selectedModuleId={this.props.selectedModule.id}
                                        parentModule={this.props.parentModule}
                                    >
                                        {this.props.module.name}
                                    </AdminBranchLevelTwo>
                                    <Accordion.Collapse data-key={this.props.module.id} eventKey={this.props.module.id}>
                                        <Accordion flush>
                                            {this.props.module?.children?.map((module) =>
                                                (
                                                    <AdminModuleAccordionItem key={module.id}
                                                                              selectedModule={this.props.selectedModule}
                                                                              parentModule={this.props.parentModule}
                                                                              nextLessonId={this.props.nextLessonId}
                                                                              RemoveUserAccordion={this.props.RemoveUserAccordion}
                                                                              children={this.props.children}
                                                                              selectedContent={this.props.selectedContent}
                                                                              SetSelectedContent={this.props.SetSelectedContent}
                                                                              SetSelectedModule={this.props.SetSelectedModule}
                                                                              SetUserAccordion={this.props.SetUserAccordion}
                                                                              GetUserAccordion={this.props.GetUserAccordion}
                                                                              isUpdating={this.props.isUpdating}
                                                                              staticContext={this.props.staticContext}
                                                                              history={this.props.history}
                                                                              location={this.props.location}
                                                                              module={module}
                                                                              depth={this.props.depth+1}
                                                                              match={this.props.match}
                                                                              navigateToModule={this.props.navigateToModule}
                                                                              discardChanges={this.props.discardChanges}
                                                    />
                                                ))}
                                        </Accordion>
                                    </Accordion.Collapse>
                                </div>
                            </>
                        )}                        
                    </>
                )}
                {this.props.module.type >=2 &&(
                    <>
                        {this.props.depth == 1 &&(
                            <>                                
                                <div className="admin-lessons">
                                    <AdminLessonLevelOne eventKey={this.props.module.id}
                                                    isCurrentEventKey={this.props.module.id === this.props.selectedModule.id}
                                                    module={this.props.module}
                                                    callback={()=>this.props.SetSelectedModule(this.props.module)}>
                                        {this.props.module.name}
                                    </AdminLessonLevelOne>
                                    <>
                                    </>
                                </div>
                            </>
                        )}
                        {this.props.depth == 2 &&(
                            <>
                                <div className="admin-lesson-group-items">
                                    <AdminLessonLevelOne eventKey={this.props.module.id}
                                        isCurrentEventKey={this.props.module.id === this.props.selectedModule.id}
                                        module={this.props.module}
                                        callback={()=>this.props.SetSelectedModule(this.props.module)}
                                        isWithinSubLesson={true}
                                    >
                                        {this.props.module.name}
                                    </AdminLessonLevelOne>
                                    <>
                                    </>
                                </div>
                            </>
                        )}
                    </>
                )}
                </>
                ) }
            </React.Fragment>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.currentUserModuleAccordion,
    UserTreeView.actionCreators
)(AdminModuleAccordionItem);