import * as React from 'react';
import { ITextContent, TextContent } from '../../../../models/contentTypes/TextContent';
import {ILessonContent, LessonContent} from "../../../../models/lesson/LessonContent";
import {LessonContentType} from "../../../../models/contentTypes/LessonContentType";
import TextEditor from '../../QuillTextEditor/TextEditor';
import {
    Form, 
    Row, 
    Col, 
    FormGroup,
    Label, 
    Input,
    FormFeedback} from 'reactstrap';
import { ILesson, Lesson, LessonIconEnum } from '../../../../models/lesson/Lesson';
import { LessonTypeEnum } from '../../../../models/lesson/LessonType';
import { LessonController } from '../../../../controllers/LessonController';
import { LessonContentController } from '../../../../controllers/LessonContentController';
import InputMask from 'react-input-mask';
import { ImageManagementController } from '../../../../controllers/ImageManagementController';
import ImagePreview from './ImagePreview';
import { AdminModule, IAdminModule } from '../../../../models/module/AdminModule';
import { _idObject } from '../../../../models/AdditionalVideoContent/AdditionalVideoContent';
import { ContentCategoryController, privateContentCategoryId } from '../../../../controllers/ContentCategoryController';
import { ContentCategory } from '../../../../models/AdditionalVideoContent/ContentCategory';
import './TextForm.css';
import toast from 'react-hot-toast';

export const durationRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

export type TextFormProps = {
    lesson?:ILesson
    textContent: ITextContent | null
    content: ILessonContent | null
    isEditing:boolean
    branchID:string;   
    order:number
    isInsideLesson?:boolean
    tagId?:_idObject
    toggleModal(isOpen?:boolean):void;   
    onSubmit(lesson:ILesson | ILessonContent | IAdminModule, duration?:string, imageFile?:File, tagId?:_idObject[]) : void
}

export type TextFormState = {
    textContent: ITextContent
    duration:string
    isLoading: boolean
    imagePreview:string
    imageUploadUrl:string
    imageFile:any    
    hasTagError:boolean
    tagId:string
}

class TextForm extends React.Component<TextFormProps, TextFormState>{
    private LessController = new LessonController();
    private LessContentController = new LessonContentController();
    private contentCategoryController = new ContentCategoryController();
    private ImageManagement = new ImageManagementController();
    private defaultDuration = "00:00";
    private tagErrorMsg = "Lesson ID Is Already In Use";
    
    constructor(props:any) {
        super(props);
        this.state = this.unloadState;
    }
    
    // The Default state
    private unloadState : TextFormState = {
        textContent: this.props.textContent ? this.props.textContent : new TextContent(),
        isLoading: false,
        imagePreview: '',
        duration:this.props.lesson?.duration? this.props.lesson.duration : "",
        imageUploadUrl:'',
        imageFile: null,
        hasTagError:false,
        tagId:this.props.tagId ? this.props.tagId._id : "",
    }

    /**
     * Handle input change and updates textContent in state
     * @param e 
     */
    private onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const inputName = e.currentTarget.name;
        const inputValue = e.currentTarget.value;                

        //TODO validate duration
        if(inputName === "duration") {
            const duration = inputValue.replace(/_/g, '0');
            this.setState(prevState => ({
                ...prevState,
                duration: duration
            }));

            return;
        }

        this.setState(prevState => ({
            ...prevState,
            textContent: {
                ...prevState.textContent,
                [inputName]: inputValue
            }
        }));
    }

    /**
     * Handles the change that occurs in the TextEditor
     */
     private textEditorOnchange = (editorHTML:any) => {
        this.setState(prevState => ({
            ...prevState,
            textContent: {
                ...prevState.textContent,
                text:editorHTML
            }
        }));
    }

    private onFileInputChange = (imageFile:File, imageId?:null) => {
        
        this.setState(prevState => ({
            ...prevState,                      
            imageFile,
            textContent: {
                ...prevState.textContent,
                imageId: imageId === null ? imageId : prevState.textContent.imageId
            }
        }));   
    }

    private onTagIdChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const inputName = e.currentTarget.name;
        const inputValue = e.currentTarget.value;

        this.setState(prevState => ({
            ...prevState,
            [inputName]: inputValue
            
        }));
    }
    
    private updateHasTagError = (hasTagError:boolean) => {
        this.setState(prevState => ({
            ...prevState,
            hasTagError
        }));
    }

    /**
     * Check if a tag exists or not
     * @param tagId 
     * @returns 
     */
    private checkIfTagExistsAndUpdate = async (tagId:string) => {        
        const contentCategory = await this.contentCategoryController.CheckIfTagExistsInCategoryAndUpdate(privateContentCategoryId, tagId);
        
        if(contentCategory) {
            return false;
        }

        return true;
    }

    private checkIfLessonIdExists = async (lessonId:string) => {
        try {
            
            return await this.contentCategoryController.CheckIfLessonIdExistsInPrivateCategory(lessonId);
            
        } catch (error) {
            console.error(error);
            toast.error("Failed To Validate Lesson Id");
            throw error;
        }
    }


    /**
     * Creates and returns a text lesson or a text lesson content
     * @param isLessonContent 
     * @returns 
     */
     private createTextLesson = (isLessonContent:boolean) => {
        const {title} = this.state.textContent;    
        const {duration} = this.state;
        const {order} = this.props;

        let textLesson = null;

        if(isLessonContent){

            textLesson = new LessonContent();            
            textLesson.name = title;       
            textLesson.type = LessonContentType.text;
            textLesson.textProperties = this.state.textContent;

        } else {            
            textLesson = new Lesson();
            textLesson.name = title;       
            textLesson.type = LessonTypeEnum.text;
            textLesson.lessonIcon = LessonIconEnum.TextIcon;
            textLesson.branchId = this.props.branchID;            
            textLesson.duration = duration.length > 0 ? duration : this.defaultDuration;     
        }

        textLesson.order = order;

        return textLesson;
    }

    private GetDirectImageUploadUrl = async () => {
        const res: {id:string, uploadUrl:string} | undefined = await this.ImageManagement.GetDirectUploadUrl();        

        this.setState(prevState => ({
            ...prevState,
            textContent: {
                ...prevState.textContent,
                imageId: res?.id
            }
        }));

        return res?.uploadUrl
    }

    private UploadImage = async (uploadUrl:string, file:File) => {        
        const uploadResults = await this.ImageManagement.UploadImage(uploadUrl, file);        
    }
    
    /**
     * Handles the form submission
     */
     private handleSubmit = async (e:React.FormEvent<HTMLFormElement>) => {
         e.preventDefault();                
                 
        const {isEditing, toggleModal, content} = this.props;

        const {textContent, duration} = this.state;

        const formattedTagId  = this.state.tagId.trim().toLowerCase();
        
        this.setState(prevState => ({
            ...prevState,
            isLoading:true
        }));
                  
        if(isEditing && content) {
            // if(!content)
            //     return;

            this.handleEditSubmit(content, textContent, duration, formattedTagId);
            return;
        } 
        
        /**
         * If we are normal lesson and have a tag Id continue.
         * If we are the first item in a combo lesson with a tag id continue
         * **/
        if(formattedTagId.length > 0 && !this.props.isInsideLesson 
            || this.props.isInsideLesson && this.props.order < 1 && formattedTagId.length > 0)
        {      
            const alreadyExist = await this.checkIfTagExistsAndUpdate(formattedTagId)
            
            if(alreadyExist) {
                this.setState(prevState => ({
                    ...prevState,
                    hasTagError:true,
                    isLoading:false
                }));
                toast.error(this.tagErrorMsg);
                return; 
            }        
        }

        this.handleAddNewSubmit(formattedTagId);           
    }

    /**
     * Creates a text lesson and its lesson content
     */
    private handleAddNewSubmit = async (tagId:string) => {
        
        const {isInsideLesson, branchID, toggleModal, onSubmit} = this.props;    
        
        const [imageFile, imageUrl] = await this.handleImageUploadUrl();

        let textLesson = null;

        // If we are not inside a lesson then we need to create a lesson
        if(!isInsideLesson) {            
            textLesson = this.createTextLesson(false) as ILesson;

            if(tagId.length > 0) {
                const tagIdObject = new _idObject();
    
                tagIdObject._id = tagId;
    
                (textLesson as ILesson).tagList = [tagIdObject];
            }
            
            textLesson = await this.LessController.CreateLesson(textLesson);                     
        } 
        
        let textLessonContent : ILessonContent = this.createTextLesson(true) as ILessonContent;

        // If we did not create a lesson then we are inside of one. So branchID is actually the lesson ID of the lesson we are inside of
        if(textLesson) {
            textLessonContent.lessonId = textLesson.id;
            //Not my choice but we need this for a fix

        } else {
            //setting the combo lesson/quiz/etc id to the lesson id in lesson content
            textLessonContent.lessonId = branchID;
            if(this.state.tagId.length > 0 && this.props.order < 1) {
                const comboLesson = await this.LessController.GetLesson(branchID);

                comboLesson.tagList = [{_id: this.state.tagId}];

                await this.LessController.UpdateLesson(comboLesson);
            }
        }    

        textLessonContent = await this.LessContentController.CreateLessonContent(textLessonContent);
        
        if(textLesson) {
            (textLesson as ILesson).lessonContent = [textLessonContent];
        }

        // If textLesson is null then the only content is the text content 
        const content = textLesson ? textLesson : textLessonContent;

        const adminModule = new AdminModule(content);

        adminModule.file = this.state.imageFile;
        
        onSubmit(adminModule);            

        this.setState(prevState => ({
            ...prevState,
            isLoading:false
        }));

        toggleModal(false);
        
        if(imageUrl && imageFile) {
            await this.UploadImage(imageUrl, imageFile);
        }
    }

    /**
     * Handles submitting an edited text lesson that has already been created
     * @param content 
     * @param textContent 
     * @param duration 
     * @param tagId
     * @returns 
     */
    private handleEditSubmit = async (content:ILessonContent,textContent:ITextContent, duration:string, tagId:string) => {     
        const {onSubmit, toggleModal} = this.props;        

        let updatedContent : ILessonContent = {...content} ; 

        updatedContent.name = textContent.title;

        updatedContent.textProperties = textContent;

        const [imageFile, imageUrl] = await this.handleImageUploadUrl();
        
        if(tagId !== this.props.tagId?._id && tagId.length > 0) {
            const hasAlreadyBeenCreated = await this.checkIfLessonIdExists(tagId);

            if(hasAlreadyBeenCreated) {
                this.updateHasTagError(true);
                this.setState(prevState => ({...prevState, isLoading:false}));
                toast.error(this.tagErrorMsg);
                return;
            }
        }

        let tagList:_idObject[] = [] 

        if(tagId.length > 0)        
            tagList = ContentCategory.createTagList([tagId]);

        // if(this.props.isInsideLesson && this.props.content && this.props.content.order > 0) {
        //     //@ts-ignore
        //     tagList = undefined;
        // }

        onSubmit(updatedContent, duration, imageFile, tagList);

        this.setState(prevState => ({
            ...prevState,
            isLoading:false
        }));

        toggleModal(false);
        
        if(imageUrl && imageFile) {
            await this.UploadImage(imageUrl, imageFile);
        }
    }

    /**
     * Handles getting the image upload url
     * @returns 
     */
    private handleImageUploadUrl = async () => {
        const {imageFile} = this.state;
        let imageUrl = null;
        
        if(imageFile)
            imageUrl = await this.GetDirectImageUploadUrl();  
        
        return [imageFile, imageUrl];
    }
    
    public render() {
        const {isEditing, toggleModal} = this.props;
        const {textContent, isLoading} = this.state;

        return (
            <>                
                <Form className="text-editor-form" onSubmit={this.handleSubmit}>          
                    {
                        isEditing ? 
                        (
                            <h2 className="form-title">EDIT TEXT LESSON</h2>                            
                        )
                        :
                        (                                
                            <h2 className="form-title">CREATE TEXT LESSON</h2>                            
                        )
                    }          
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label className='form-label' for="textTitle">Lesson Title</Label>
                                <Input 
                                    disabled={isLoading}  
                                    autoFocus={true} 
                                    className='form-input' 
                                    onChange={this.onChange} 
                                    value={this.state.textContent.title} 
                                    type="text" 
                                    name="title" 
                                    id="textTitle" 
                                    placeholder="Enter a title to go above your text section"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <ImagePreview isLoading={isLoading} onFileInputChange={this.onFileInputChange} imageId={textContent.imageId}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup className='dual-form-input-container tag-form-group'>
                                <div className='text-form-tags-container'>
                                    <Label className='form-label' for="tagId">Lesson ID</Label>
                                    {this.props.isInsideLesson && this.props.content && this.props.content.order > 0 ? 
                                    (
                                        <Input
                                            disabled
                                            invalid={this.state.hasTagError}                                        
                                            className='form-input' 
                                            onChange={this.onTagIdChange} 
                                            value={this.state.tagId}                                         
                                            type="text" 
                                            name="tagId" 
                                            id="tagId"                                                                             
                                            placeholder="i.e: M06_03, M07_02, etc."
                                        />
                                    )
                                    :
                                    (
                                        <Input                                    
                                            invalid={this.state.hasTagError}                                        
                                            className='form-input' 
                                            onChange={this.onTagIdChange} 
                                            value={this.state.tagId}                                         
                                            type="text" 
                                            name="tagId" 
                                            id="tagId"                                                                             
                                            placeholder="i.e: M06_03, M07_02, etc."
                                        />
                                    )
                                    } 

                                    <FormFeedback>
                                        {this.tagErrorMsg}
                                    </FormFeedback>
                                    </div>
                                    <div className='form-est-reading-container'> 
                                        <Label className='form-label' for="estReadTime">EST. READING TIME</Label>
                                        <InputMask                                        
                                            role='textbox' 
                                            disabled={isLoading}
                                            placeholder="Ex. 00:00"  
                                            className='form-input form-control' 
                                            id='estReadTime' 
                                            name='duration' 
                                            value={this.state.duration}
                                            onChange={this.onChange} 
                                            mask="99:99"
                                            
                                        />
                                    </div>
  
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <TextEditor placeHolder='Enter your text here.' editorValue={textContent.text} onChange={this.textEditorOnchange} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                    <div className="form-btn-container">                                
                                {
                                    this.state.isLoading ? 
                                    (
                                        <>
                                            <button disabled type='button' onClick={() => toggleModal()} className='btn-cbit-minor'>Cancel</button>
                                            <button disabled type='submit' className='btn-cbit-primary'>
                                            {
                                                isEditing ? "Updating" : "Submitting"
                                            } 
                                            </button>
                                        </>
                                    ) 
                                    : 
                                    (
                                        <>
                                            <button type='button' onClick={() => toggleModal(false)} className='btn-cbit-minor'>Cancel</button>
                                            <button type='submit' className='btn-cbit-primary'>
                                            {
                                                isEditing ? "Update" : "Submit"
                                            } 
                                            </button>
                                        </>
                                    )
                                }
                    </div>
                        </Col>
                    </Row>
                </Form>                
            </>
        )
    }
}

export default TextForm;