import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { CurrentUserState } from "../../../store/CurrentUser";
import { ReferralController } from "../../../controllers/ReferralController";
import { EmptyReferral, Referral } from "../../../models/Referral/Referral";
import CloudflareImageHelper from "../../../models/utility/CloudflareImageHelper";
import toast from "react-hot-toast";
import { StringHelper } from "../../../models/utility/StringHelper";
import {v4 as uuidV4} from "uuid";
import "./ReferralMyProfileInfo.css";
import { AgeGroupEnum, ageGroupLabels, ageGroupUi } from "../../../models/Enums/AgeGroupEnum";
import ReferralProfileImage from "../ReferralProfileImage/ReferralProfileImage";

type ReferralMyProfileInfoProps = {
    referral:Referral
    toggleEditing:() => void
}

const ReferralMyProfileInfo = (props:ReferralMyProfileInfoProps) => {
    const {referral, toggleEditing} = props;
    
    const imageVariant = CloudflareImageHelper.variants.defaultProfileImage;

    const displayAgeGroup = () => {
        if(referral.ageGroup === AgeGroupEnum.All) {
            return "Children & Adults"
        }

        return ageGroupLabels[referral.ageGroup];
    }

    /**
     * Converts list of licensed states to a comma string
     * @returns 
     */
        const addCommaToLicensedStates = () => {        
            if(referral.licensedStates.length <= 0) {
                return "";
            }
            return referral.licensedStates.join(", ").toString();
        }

    return (
        <>
        {              
                <div className="referral-profile-info">
                    <h2 className="cbit-dashboard-header">My Profile</h2>
                    <div className="referral-profile-body referral-profile-info">
                        <ReferralProfileImage referral={referral} imageVariant={imageVariant} />
                        <div className="referral-info-container">
                            <div className="referral-name-container">
                                <h4 className="cbit-small-header">{referral.name}</h4>
                                <span className="italicize-text">{referral.occupation}</span>
                            </div>
                            <div className="referral-bio-container">
                                <div className="cbit-label">bio</div>
                                <p>
                                    {referral.description}
                                </p>
                            </div>
                            <div className="referral-contact-container">
                                <div className="cbit-label">contact information</div>
                                <ul className="referral-contact-list">
                                    <li>{referral.email}</li>
                                    <li>{StringHelper.formatPhoneNumber(referral.phone)}</li>
                                    <li className="referral-contact-state">{`${referral.streetName}, ${referral.city}, ${referral.state} ${referral.zip}`}</li>
                                </ul>
                            </div>
                            <div className="referral-licenses-container">
                                <div className="cbit-label">Licensed States</div>
                                <div className="referral-licensed-states-list">
                                    {
                                        addCommaToLicensedStates()
                                    }
                                </div>
                            </div>
                            <div className="referral-website-container">
                                <div className="cbit-label">Website</div>
                                <div><a href="https://bouncingpixel.com">https://bouncingpixel.com</a></div>
                            </div>

                            <div className="referral-age-group-container">
                                <div className="cbit-label">Age Group</div>
                                <div>{ageGroupUi[referral.ageGroup]}</div>
                            </div>

                            <div className="referral-profile-btn-container">
                                <button 
                                    onClick={toggleEditing}
                                    className="btn-cbit-minor">
                                        Edit Profile
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            
        }    
        </>
    )
}

export default ReferralMyProfileInfo;