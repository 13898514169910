import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import IconCaretDown from '../../../../resources/icons/icon-caret-down.png';
import IconCaretLeft from '../../../../resources/icons/icon-caret-left.png';
import QuizIconSrc from '../../../../resources/icons/icon-quiz.png';
import TextIconSrc from '../../../../resources/icons/icon-reading.png';
import VideoIconSrc from '../../../../resources/icons/icon-video.png';
import DragGripSrc from '../../../../resources/icons/icon-drag-drop.png';
import EditIconSrc from '../../../../resources/icons/icon-edit.png';
import TrashIconSrc from '../../../../resources/icons/icon-trash.png';
import EyeClosedIconSrc from '../../../../resources/icons/icon-eye-closed.png';
import EyeOpenIconSrc from '../../../../resources/icons/icon-eye-open.png';
import { _idObject } from '../../../../models/AdditionalVideoContent/AdditionalVideoContent';
import { LessonContentType } from '../../../../models/contentTypes/LessonContentType';
import { ILesson } from '../../../../models/lesson/Lesson';
import { ILessonContent } from '../../../../models/lesson/LessonContent';
import { IAdminModule } from '../../../../models/module/AdminModule';
import { DeepCopy } from '../../../../models/utility/DeepCopy';
import { EditorContentEnum } from '../../../../models/utility/EditorContentEnum';
import CustomModal from '../../../Utilities/CustomModal';
import MultipleChoiceForm from '../../AdminForms/QuizForms/MultipleChoiceForm';
import TrueOrFalseForm from '../../AdminForms/QuizForms/TrueOrFalseForm';
import TextForm from '../../AdminForms/TextForms/TextForm';
import VideoForm from '../../AdminForms/VideoForm';
import AdminContentItemViewer from './AdminContentItemViewer/AdminContentItemView';
import './LessonContentItem.css';
import LessonIconRender from './LessonIconRender';

type LessonContentItemProps = {
    index:number
    lesson?:ILesson
    content:ILessonContent    
    deleteItem: () => void       
    onSubmit: (content:any, duration?:string, tagList?:_idObject[]) => void
    setIsVisible?: () => void
    isVisible?:boolean
    parentsVisibility:boolean
    isExpanded:boolean
    causeRerender:boolean
    draggableId:string
    onDeleteConfirmSubmit:any    
    imageFile?:File
    contentType?:EditorContentEnum
    tagId?:_idObject
}

type LessonContentItemState = {    
    lessonContent: ILessonContent
    isExpanded: boolean
    isModalOpen: boolean   
    isViewable?:boolean 
    contentDuration:string
}

class LessonContentItem extends React.Component<LessonContentItemProps, LessonContentItemState>{

    constructor(props:any) {
        super(props);
        this.state = this.defaultState;
    }

    componentDidUpdate(prevProps:LessonContentItemProps) {
        if(prevProps.isExpanded !== this.props.isExpanded || prevProps.causeRerender !== this.props.causeRerender && prevProps.isExpanded !== this.state.isExpanded) {
            this.setState(prevState => ({
                ...prevState,
                isExpanded: this.props.isExpanded
            }));
        }
        
        if(prevProps.parentsVisibility !== this.props.parentsVisibility || prevProps.parentsVisibility !== this.props.parentsVisibility && prevProps.causeRerender !== this.props.causeRerender) {

            this.setState(prevState => ({
                ...prevState,
                isViewable:this.props.parentsVisibility
            }))
        }

        if(prevProps.isVisible !== this.props.isVisible) {
            this.setState(prevState => ({
                ...prevState,
                isViewable:this.props.isVisible
            }));
        }
        
    }

    private defaultState : LessonContentItemState = {
        lessonContent: this.props.content,
        isExpanded: false,
        isModalOpen: false,
        contentDuration: '00:00',
        isViewable: this.props.isVisible
    }

    private getLessonContentDuration = () => {
        if(this.props.contentType) {
            this.props.contentType === EditorContentEnum.lessonQuiz || this.props.contentType === EditorContentEnum.lesson;
            return ""
        }

        if(this.props.lesson) {
            return this.props.lesson.duration
        }

        if(this.props.content.videoProperties) {
            return this.props.content.videoProperties.videoLength
        }

        return this.state.contentDuration
    }

    /**
     * toggles the accordion 
     */
    private setIsExpanding = () => {
        const {isExpanded} = this.state;
        this.setState(prevState => ({
            ...prevState,
            isExpanded: !isExpanded
        }));
    }

    private setIsViewable = async () => {
        
        const isViewable = !this.state.isViewable;

        this.setState(prevState => ({
            ...prevState,
            isViewable
        }));

        const contentCopy = DeepCopy.copy(this.props.content);

        this.onEditSubmit(contentCopy);
    }

    /**
     * Toggles the modal to be open or closed
     */
    private toggleModal = () => {
        const {isModalOpen} = this.state;
        this.setState(prevState => ({
            ...prevState,
            isModalOpen: !isModalOpen
        }));
    }
    
    /**
     * Updates the quiz questions types (multi choice and true or false)
     * @param lessonContent 
     */
    private onEditSubmit = async (lessonContent: ILessonContent, duration?:string, imageFile?:File, tagList?:_idObject[]) => {
        
       const newAdminCourseItem : IAdminModule = {
           key: this.props.draggableId,
           content: lessonContent,
           file:imageFile,
           tagList
       }                               

       this.props.onSubmit(newAdminCourseItem, duration, tagList);             
    }

    /**
     * Renders the correct icon for the content type
     */
    private renderContentTypeIcon = () => {
        const {content} = this.props;

        switch (content.type) {
            case LessonContentType.multipleChoice:
                return <img src={QuizIconSrc} alt="Quiz Icon" />

            case LessonContentType.trueOrFalse:
                return <img src={QuizIconSrc} alt="Quiz Icon" />

            case LessonContentType.text:
                return <img src={TextIconSrc} alt="Text Icon" />

            default:
                return <img src={VideoIconSrc} alt="Video Icon" />
        }
    }

    /**
     * Returns the proper form based on the content type
     * @returns 
     */
    private renderForm = () => { 

        const {content} = this.props;      
        
        switch(content.type){
            case LessonContentType.multipleChoice:
                return React.createElement(
                    MultipleChoiceForm,{
                        onSubmit: this.onEditSubmit, 
                        content: content, 
                        question: content.multipleChoiceProperties,
                        isEditing: true,                                  
                        toggleModal: this.toggleModal,
                    });
            case LessonContentType.trueOrFalse:
                return React.createElement(
                    TrueOrFalseForm, {
                        onSubmit: this.onEditSubmit, 
                        content: content, 
                        question: content.multipleChoiceProperties,
                        isEditing: true,                                  
                        toggleModal: this.toggleModal,                                            
                    });
            case LessonContentType.video:
                return React.createElement(
                    VideoForm, {
                        content: content, 
                        video: content.videoProperties,
                        isEditing: true,
                        onSubmit: this.onEditSubmit,
                        toggleModal: this.toggleModal,
                        tagId: this.props.tagId,
                        isInsideLessonCombo:this.props.contentType ? this.props.contentType === EditorContentEnum.lesson : undefined
                    });
            case LessonContentType.text:
                return React.createElement(
                    TextForm, {
                        lesson:this.props.lesson,
                        content: content, 
                        textContent: content.textProperties,
                        isEditing: true,
                        onSubmit: this.onEditSubmit,
                        toggleModal: this.toggleModal,
                        tagId: this.props.tagId,
                        isInsideLesson:this.props.contentType ? this.props.contentType === EditorContentEnum.lesson : undefined
                        
                    });                   
        }
    }

    /**
     * Checks to see if the content type is a type of question
     */
    public IsQuestionContent = () => {
        const {content} = this.props;

        const contentType = content.type;
        
        if(contentType === LessonContentType.multipleChoice || contentType === LessonContentType.trueOrFalse){
            return true;
        } else {
            return false;
        }
    }

    /**
     * Get the lesson content question number for displaying
     */
    public GetQuestionNumber = () => {  
        //If not a question type content return undefined
        if(!this.IsQuestionContent()){
            return;
        }

        const {content} = this.props;

        return content.multipleChoiceProperties?.questionNumber;
    }

    public handleVisibilityClick = () => {        
        if(this.props.setIsVisible)
            return this.props.setIsVisible;
        
        return this.setIsViewable;
    }

    public handleIsVisible = () => {
        if(this.props.isVisible != undefined)
            return this.props.isVisible
        
        return this.state.isViewable
    }

    /**
     * Because lesson content and quiz items should not have visibility toggle
     */
    public showVisibility = () => {
        return this.props.setIsVisible != null
    }

    public render() {
        const {index, content, deleteItem, isVisible, setIsVisible, draggableId, imageFile} = this.props;
        const {isExpanded, isModalOpen} = this.state;

        const expandedClass = isExpanded ? "content-item-expanded" : "";

        return (
            <>
                <Draggable
                    key={draggableId}
                    draggableId={draggableId}
                    index={index}
                >
                    {(provided, snapshot) => (
                        <div className={`content-item lesson-content-item ${expandedClass} ${snapshot.isDragging ? 'content-item-dragging' : ''}`} 
                            ref={provided.innerRef} 
                            {...provided.draggableProps} 
                        >                        
                                <div 
                                    className="drag-handle"  
                                    {...provided.dragHandleProps}
                                >
                                    <img src={DragGripSrc} alt="Drag Grip" />              
                                </div>
                                {
                                    this.props.lesson ? 
                                    (
                                        <div className="content-item-type">
                                            <LessonIconRender 
                                                lessonIcon={this.props.lesson.lessonIcon}
                                            />
                                        </div>
                                    ) 
                                    : 
                                    (
                                        <div className='content-item-type'>
                                            {this.renderContentTypeIcon()}
                                        </div> 
                                    )
                                }

                                <div className="content-item-title lesson-course-item-title">
                                    {

                                        this.IsQuestionContent() && (
                                            <div className="admin-question-number">
                                                {this.GetQuestionNumber() + "."}
                                            </div>      
                                            )
                                    }
                                    <div>
                                        {
                                            content.name
                                        }
                                    </div>
                                </div>
                                                                
                                <ul className="content-item-options"> 
                                    <li className={`lesson-content-caret-${isExpanded ? "down" : "left"}`} >
                                        <div onClick={this.setIsExpanding}> 
                                        {
                                            isExpanded ? 
                                            (
                                                <img src={IconCaretDown} alt="caret down"  />
                                                
                                            )
                                            :
                                            (                                                    
                                                <img src={IconCaretLeft} alt="caret left"  />                       
                                            )
                                        }
                                        </div>
                                    </li>
                                    <li onClick={() => this.props.onDeleteConfirmSubmit(content.name,deleteItem)}>
                                        <div className="content-action-icon">
                                            <img src={TrashIconSrc} alt="Trash Button" />
                                        </div>
                                    </li>
                                
                                    <li onClick={() => this.toggleModal()}>
                                        <div className="content-action-icon">
                                            <img src={EditIconSrc} alt="Edit Button" />
                                        </div>
                                    </li>                                    
                                    {
                                        this.showVisibility() && (

                                            <li onClick={this.handleVisibilityClick()}>
                                            <div className="content-action-icon-visibility">
                                            {
                                                this.state.isViewable ? 
                                                (
                                                    <img src={EyeOpenIconSrc} alt="Visibility Button" />                                                    
                                                ) 
                                                : 
                                                (                                                                                                                
                                                    <img src={EyeClosedIconSrc} alt="Visibility Button" />
                                                )
                                            }
                                            </div>
                                        </li>
                                        )
                                    }
                                    {!this.IsQuestionContent() && (

                                        <li>
                                            <span className='lesson-content-duration'>
                                                {this.getLessonContentDuration()}
                                            </span> 
                                        </li>                                  
                                    )}
                                </ul>      
                            {
                                isExpanded && (
                                    <>
                                        <div className="flex-break"></div>                                                                                          
                                            {
                                                <AdminContentItemViewer 
                                                imageFile={imageFile}
                                                lessonContent={content}                                            
                                                />
                                            }                                        
                                    </>
                                )
                            }
                        </div>
                    )}
                </Draggable>
                <CustomModal isOpen={isModalOpen} toggle={this.toggleModal}>
                        {this.renderForm()}
                </CustomModal>
            </>
        )
    }

}

export default LessonContentItem;