import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Spinner } from "reactstrap";
import { LessonController } from "../../../controllers/LessonController";
import { UserLessonController } from "../../../controllers/UserLessonController";
import { Lesson, getLessonIconSrc } from "../../../models/lesson/Lesson";
import { UserLesson, UserLessonWithModules } from "../../../models/lesson/UserLesson";
import { UserBio } from "../../../models/module/UserProfile";
import { StringHelper } from "../../../models/utility/StringHelper";
import {v4 as uuidV4} from "uuid";
import "./UserProgress.css";

export type UserProgressProps = {
    currentUserLesson:UserLesson;
    userBio:UserBio;
}

const userLessonController = new UserLessonController();
const lessonController = new LessonController();

/**
 * Component for showing where the user is currently at in their training
 * @returns 
 */
const UserProgress = (props:UserProgressProps) => {
    const {
        currentUserLesson,
        userBio
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [userLessonWithMods, setUserLessonWithMods] = useState<UserLessonWithModules|null>(null);
    const [lessons, setLessons] = useState<Lesson[]>([]);

    useEffect(() => {
        async function onComponentMount() {
            setIsLoading(true)
            try {
                const userLessonWithMods:UserLessonWithModules = 
                    await userLessonController.GetUserNextLesson(userBio.id, userBio.assignedSectionId);
                
                setUserLessonWithMods(userLessonWithMods);
                
                if(userLessonWithMods.userSubModule) {
                    var lessons = await lessonController.GetLessonsWithParentId(userLessonWithMods.userSubModule.originalDocumentId);
                    setLessons(lessons);
                } else {
                    var lessons = await lessonController.GetLessonsWithParentId(userLessonWithMods.userModule.originalDocumentId);
                    setLessons(lessons);
                }
                
            } catch (error) {
                console.error(error);
                toast.error("Failed to get user progress");
            }
            setIsLoading(false);
        }
        onComponentMount();
    },[]);

    /**
     * Check if we have a submodule or not
     */
    const checkIfSubmodule = () => {
        if(!userLessonWithMods) {
            return false;
        }

        return userLessonWithMods.userSubModule != null;
    }

    /**
     * renders the correct icon for a lesson
     * @param lesson 
     */
    const renderLessonIcon = (lesson:Lesson) => {
        const lessonIconSrc = getLessonIconSrc(lesson.lessonIcon);
        return lessonIconSrc;
    }

    /**
     * Returns the user first and last name capitalize
     */
    const capitalizeName = () => {
        return StringHelper.capitalizeWord(`${userBio.firstName} ${userBio.lastName}`, true)
    }

    return (
        <div className="user-progress-view">            
            <h2 className="cbit-header">
                {capitalizeName()}'s PROGRESS
            </h2>
                <div className="user-progress-module">
                {
                    isLoading ?
                    (
                        <Spinner/>
                    )
                    :
                    (
                        <>
                            {
                                checkIfSubmodule() && (
                                    <>
                                        <div className="user-progress-module-text">
                                            Module:
                                        </div>
                                        <h3 className="cbit-dashboard-header">
                                            {userLessonWithMods?.userModule.module.name}
                                        </h3>
                                    </>
                                )
                            }

                            <div className="user-progress-module-text">
                            {
                                checkIfSubmodule() ? 
                                (
                                    "Sub Lesson:"
                                )
                                :
                                (
                                    "Module:"
                                )
                            }                        
                            </div>
                            <h3 className="cbit-dashboard-header">
                                {
                                    checkIfSubmodule() ? 
                                    (
                                        userLessonWithMods?.userSubModule?.module.name
                                    ) 
                                    : 
                                    (
                                        userLessonWithMods?.userModule.module.name
                                    )
                                }
                            </h3>
                            <ul className="user-progress-lessons">
                                {
                                    lessons.map(lesson => (
                                        <li key={uuidV4()}>
                                            <div className='lesson-type-icon'>
                                                <img src={renderLessonIcon(lesson)} alt="lesson group icon"/>
                                            </div>
                                                {lesson.name}
                                                {
                                                    lesson.id === userLessonWithMods?.originalDocumentId && 
                                                    (
                                                        <div className="user-progress-location">Current Lesson</div>
                                                    )
                                                }
                                        </li>
                                    ))
                                }
                            </ul>
                        </>
                    )
                }            

                </div>                            
        </div>
    )
}

export default UserProgress;
