import React, {useEffect, useState} from "react"
import AdminSearch from "../../components/admin/AdminSearch";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {CurrentUserState} from "../../../../store/CurrentUser";
import {SocialConnectionController} from "../../../../controllers/SocialConnectionController";
import {Spinner} from "reactstrap";
import {IPost} from "../../../../models/SocialConnection/Post";
import {DeepCopy} from "../../../../models/utility/DeepCopy";
import SocialConnectionPostItem from "../../components/SocialConnectionPostItem";
import SocialConnectionLayout from "../../components/layout/SocialConnectionLayout";

const Admin = ({history}: any) => {
    const [posts, setPosts] = useState<any>([]);
    const [isVisible, setIsVisible] = useState<boolean | null>(true);
    const [isReported, setIsReported] = useState<boolean | null>(true);
    const [keyword, setKeyword] = useState<any>('');
    const [loading, setLoading] = useState(true);

    // Get current user
    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state) => state.currentUser);

    const getAllPosts = async () => {
        const sConnectionController = new SocialConnectionController();
        const allPosts = await sConnectionController.AdminGetAllPosts();
        setPosts(allPosts);
        setLoading(false);
    }

    useEffect(() => {
        if (!UserStore?.isAdmin || !UserStore?.isSuperAdmin) {
            history.push('/social-connection');
        }

    }, [UserStore?.isAdmin, UserStore?.isSuperAdmin]);

    useEffect(() => {
        const userIsBanned = UserStore?.userProfile?.isBanned;

        getAllPosts();

        if (userIsBanned) {
            history.push('/home');
        }
    }, [history, UserStore?.userProfile?.isBanned]);

    const handlePostUpdate = async (updatedPost: IPost) => {
        let oldData = DeepCopy.copy(posts);

        oldData.map(()=> {
            if(oldData.id === updatedPost.id){
                oldData = updatedPost;
            }
        })

        setPosts(oldData);
    }

    return (
        <SocialConnectionLayout>
            {loading ? (
                <div className="spinner-container">
                    <Spinner color='primary'/>
                </div>
            ) : (
                <>
                    <div className="social-connection-header">
                        {/* Header */}
                        <h2 className='social-connection-heading'>
                            Social Connection
                        </h2>
                    </div>

                    {/* Search container */}
                    <AdminSearch setPosts={setPosts} setKeyword={setKeyword} keyword={keyword} isVisible={isVisible} setIsVisible={setIsVisible} isReported={isReported} setIsReported={setIsReported} />

                    {/* Posts */}
                    <div className="social-connection-posts-container">
                        {posts.length > 0 ? posts.map((post: IPost) => (
                            <SocialConnectionPostItem key={post.id}
                                                      post={post}
                                                      updatePost={handlePostUpdate}
                            />
                        )) : (
                            <div className="social-connection-no-post-container">
                                <h4 className="social-connection-no-posts">
                                    No posts found
                                </h4>
                            </div>
                        )}
                    </div>
                </>
            )}
        </SocialConnectionLayout>
    )
}

export default Admin