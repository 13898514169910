import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { CurrentUserState } from "../../../store/CurrentUser";
import { EmptyReferral, IReferral, ReferralStatusEnum } from "../../../models/Referral/Referral";
import { ReferralController } from "../../../controllers/ReferralController";
import ReferralMyProfileInfo from "./ReferralMyProfileInfo";
import ReferralMyProfileForm from "./ReferralMyProfileForm";
import { DeepCopy } from "../../../models/utility/DeepCopy";
import { Spinner } from "reactstrap";
import CustomAlert from "../../Utilities/CustomAlert";
import CustomModal from "../../Utilities/CustomModal";
import ReferralAlert from "../ReferralAlert/ReferralAlert";
import { useHistory } from "react-router";

const referralController = new ReferralController();

const ReferralMyProfile = () => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);
    
    const [isLoading, setIsLoading] = useState(false);

    const [referralState, setReferralState] = useState(EmptyReferral);

    const [isEditing, setIsEditing] = useState(false);

    const [isReferralAlertOpen, setIsReferralAlertOpen] = useState(false);

    const [displayReferralPending, setReferralPending] = useState(false);

    const history = useHistory();

    //Lets us know if the image was removed when editing
    const [removedImageId, setRemovedImageId] = useState("");

    useEffect(() => {
        async function onGetEditingChange() {            
            displayReferralAlert();
            await getReferralInfo();
            setRemovedImageId("");
        }
        if(isEditing === false) {
            onGetEditingChange();        
        }
    },[isEditing]);

    const getReferralInfo = async () => {
        if(!userStore) {
            return;
        }

        if(!userStore.userProfile.haveCompletedReferralProfile) {
            return;
        }
        
        try {
            const userId = userStore.userProfile.id;            
            setIsLoading(true);

            const referral = await referralController.GetReferralByUserId(userId);  
            
            redirectToPendingPage(referral);
                        
            setReferralState(referral);

            setIsLoading(false);
        } catch (error) {
            toast.error("Failed to retrieve user referral information");            
            console.error(error);
            setIsLoading(false);
            
        }
    }

    const redirectToPendingPage = (referral:IReferral) => {
        const params = new URLSearchParams(window.location.search) // id=123
        let display = params.get('display')

        if(display === "true") {
            return;
        }

        if(referral.status === ReferralStatusEnum.InReview) {
            history.replace("/referral/pending-profile");
        } 
    }

    const removeImageFromReferral = () => {
        const referralCopy:IReferral = DeepCopy.copy(referralState);

        referralCopy.imageLink = "";

        setReferralState(referralCopy);

        setRemovedImageId(referralState.imageLink);
    }

    const toggleEditing = () => {
        setIsEditing(!isEditing);
    }

    const updateIsEditing = (isEdit:boolean) => {
        setIsEditing(isEdit)
    }

    const toggleReferralAlert = () => {
        setIsReferralAlertOpen(!isReferralAlertOpen);
    }

    const displayReferralAlert = () => {
        if(!userStore) {
            return;
        }

        setIsReferralAlertOpen(!userStore.userProfile.haveCompletedReferralProfile);
    }

    return (
        <>
            {
                isLoading ? 
                (
                    <div className="spinner-container">
                        <Spinner color="black"></Spinner>
                    </div>
                ) 
                : 
                (
                    <>
                        {
                        userStore && 
                        (
                        <>
                            {
                                userStore.userProfile.haveCompletedReferralProfile ? 
                                (
                                    <>
                                        {
                                            isEditing ? 
                                            (
                                                <ReferralMyProfileForm 
                                                    isEditing={true} 
                                                    referral={referralState}
                                                    updateIsEditing={updateIsEditing}
                                                    removedImageId={removedImageId}
                                                    removeImageFromReferral={removeImageFromReferral}
                                                />
                                            )       
                                            :
                                            (
                                                <>
                                                <ReferralMyProfileInfo 
                                                    toggleEditing={toggleEditing}
                                                    referral={referralState} 
                                                />
                                                </>
                                            )
                                        }
                                    </>
                                )
                                :
                                (
                                    <ReferralMyProfileForm 
                                        isEditing={false}
                                        updateIsEditing={updateIsEditing}
                                        removeImageFromReferral={removeImageFromReferral}
                                    />
                                )
                            }
                        </>                  
                        )
                        }
                        <CustomModal
                            id='referral-alert'
                            className="cbit-dialog"
                            isOpen={isReferralAlertOpen}
                            toggle={toggleReferralAlert}>
                            <ReferralAlert onBtnClick={toggleReferralAlert}/>
                        </CustomModal>                    
                    </>
                )
            }            
        </>
    );
}

export default ReferralMyProfile;