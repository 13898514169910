import {INotification} from "../../../../models/Inbox/Notification";
import React, {useState} from "react";
import moment from "moment";
import NotificationModal from "./NotificationModal";
import UseModal from "../../../../hooks/UseModal";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {MdDeleteOutline} from "react-icons/md";
import {NotificationController} from "../../../../controllers/NotificationController";
import {actionCreators, CurrentUserState} from "../../../../store/CurrentUser";
import { useSelector, useDispatch } from 'react-redux'
import {ApplicationState} from "../../../../store";
import Roles from "../../../../models/utility/Roles";
import {NotificationType} from "../../../../models/Inbox/NotificationType";
import DOMPurify from 'dompurify';

type NotificationItemProps = {
    notification: INotification,
    updateNotification: any,
    updateNotifications: any
}

const NotificationItem = ({ notification, updateNotification, updateNotifications }: NotificationItemProps) => {

    const {updateNotificationCount} = actionCreators;

    const dispatch = useDispatch()

    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>((state )=> state.currentUser);

    const { id, name, content, userId, hasRead, createdAt, updatedAt} = notification;
    const [modalState, setModalState, toggleModal] = UseModal(false);
    const [isModalOpen, setIsModalOpen] = useState(modalState);
    const [isModalViewing, setIsModalViewing] = useState(false);

    const markAsRead = async () => {
        const nController = new NotificationController();
        const data = await nController.MarkAsRead(notification.id);
        return data;
    }

    const handleModalOpen = () => {
        setIsModalOpen(true)
        setModalState(true)
        setIsModalViewing(true)

        // @ts-ignore
        dispatch(updateNotificationCount(userStore!.notificationCount -1))
        if (!hasRead) {
            markAsRead().then((data) => {
                updateNotification({
                    ...notification,
                    id: notification.id,
                    hasRead: true,
                })
            })
        }
    }

    const handleModalClose = (setModalState: any) => {
        setIsModalOpen(false)
        setModalState(false)
    }

    const renderEmailContent = (content:string) => {
        return DOMPurify.sanitize(content, { ADD_ATTR: ['target'] })

    }

    return (
        <>
            <div key={id} className={`notification-item ${hasRead ? 'read' : ''}`} onClick={handleModalOpen}>
                <div className="notification-item-content-container">
                    <h5 className="notification-item-title date">
                        {moment(createdAt).format('MMMM Do YYYY, h:mm a')}
                    </h5>
                    {notification.type === NotificationType.None && (
                        <h5 className="notification-item-title">
                            {name}
                        </h5>
                    )}
                    {notification.type === NotificationType.SocialConnection && (
                        <h5 className="notification-item-title">
                            {name}
                        </h5>
                    )}
                    {notification.type === NotificationType.Consultation && (
                        <h5 className="notification-item-title">
                            {name}
                        </h5>
                    )}
                    {notification.type === NotificationType.Email && (
                        <h5 className="notification-item-title">
                            {name}
                        </h5>
                    )}
                </div>
                <div className="notification-item-action-container">
                    <NotificationModal notification={notification} handleModalOpen={handleModalOpen} modalState={modalState} updateNotifications={updateNotifications}/>
                </div>
            </div>
            {isModalOpen && isModalViewing && (
                <Modal isOpen={isModalOpen} size={'lg'} centered={true}>
                    <ModalHeader className="notification-item-title" toggle={() => handleModalClose(setIsModalViewing)}>
                        {notification.name}
                    </ModalHeader>
                    <ModalBody className=''>
                        <div className="inbox-modal-body-container">
                            {notification.type === NotificationType.None && (
                                <p className="notification-item-description">
                                    {content}
                                </p>
                            )}
                            {notification.type === NotificationType.SocialConnection && (
                                <p className="notification-item-description">
                                    {notification.content} <a href={`${notification.link}`}>post</a>
                                </p>
                            )}
                            {notification.type === NotificationType.Consultation && (
                                <p className="notification-item-description">
                                    {notification.content.split(/:(.+)/)[0]}:<br />
                                    <span>
                                        {notification.content.split(/:(.+)/)[1]}
                                    </span>
                                </p>
                            )}
                            {notification.type === NotificationType.Email && (
                                <div 
                                    dangerouslySetInnerHTML={{__html: renderEmailContent(notification.content)}}
                                    className="notification-item-description">
                                    
                                </div>
                            )}
                        </div>
                        <div className="inbox-modal-btn-container">
                            <button className='notification-item-btn primary' onClick={() => handleModalClose(setIsModalViewing)}>
                                Close
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};

export default NotificationItem;