import React, {useEffect, useState} from "react";
import "./ReferralUserItem.css";
import { IReferral } from "../../../models/Referral/Referral";
import CloudflareImageHelper from "../../../models/utility/CloudflareImageHelper";
import ReferralProfileImage from "../ReferralProfileImage/ReferralProfileImage";
import CustomModal from "../../Utilities/CustomModal";
import ReferralUserDetails from "./ReferralUserDetails";
import IconMarkerSrc from "../../../resources/icons/icon-marker.png";
import ToolTipWrapper from "../../Utilities/ToolTipWrapper/ToolTipWrapper";

type ReferralUserItemProps = {
    referral:IReferral;
    updateSelectedReferralId:(referralId:string) => void
}

const ReferralUserItem = (props:ReferralUserItemProps) => {
    const {
            referral,
            updateSelectedReferralId
    } = props;    

    const imageVariant = CloudflareImageHelper.variants.defaultProfileImage;

    const [isUserDetailsOpen, setUserDetails] = useState(true);

    const toggleUserDetails = () => {
        setUserDetails(!isUserDetailsOpen);
    }

    return (
        <>
        
        <div id={`${referral.name.split(" ").join("")}${referral.id.slice(-2)}`} className="referral-user-item">
            <div className="referral-user-item-profile">
                <ReferralProfileImage referral={referral} imageVariant={imageVariant}/>
            </div>
            <div className="referral-user-item-info">
                <div className="referral-user-name-container">
                    {
                        referral.name.length > 28 ? 
                        (
                            <ToolTipWrapper toolTipText={referral.name}>
                                <button
                                    type="button" 
                                    onClick={() => updateSelectedReferralId(referral.id)}
                                    className="btn-cbit-text referral-user-item-name text-ellipse"
                                >
                                    {referral.name}
                                </button>
                            </ToolTipWrapper>   
                        )
                        :
                        (
                            <button
                                type="button" 
                                onClick={() => updateSelectedReferralId(referral.id)}
                                className="btn-cbit-text referral-user-item-name text-ellipse"
                            >
                                {referral.name}
                            </button>
                        )
                    }
                 
                    <div className="referral-user-marker-container">
                        <img 
                            className={referral.isContactPrivate ? "" : "referral-map-icon"}
                            src={IconMarkerSrc} 
                            alt={referral.isContactPrivate ? "Private Icon" : "Public Icon"} 
                        />
                    </div>
                </div>
                <div className="referral-user-item-occupation">
                    {referral.occupation}
                </div>
                <div className="referral-user-item-bio">
                    {referral.description}
                </div>
                <div className="referral-user-item-btn-container">
                    <button onClick={toggleUserDetails} className="btn-cbit-minor">View Details</button>
                </div>
            </div>
        </div>

        <CustomModal
            className="cbit-dialog"
            isOpen={isUserDetailsOpen}
            toggle={toggleUserDetails}>
                <ReferralUserDetails referral={referral}/>
        </CustomModal>
        </>
    )
}

export default ReferralUserItem;