import * as React from "react";
import { useState } from "react";
import { AttendanceEnum } from "../../../models/consultations/ConsultationReview";
import { IUserBio, UserBio } from "../../../models/module/UserProfile";
import { StringHelper } from "../../../models/utility/StringHelper";
import AttendanceLegend from "../../ConsultationDashboard/Attendance/AttendanceLegend";
import LateLabel from "../../ConsultationDashboard/Attendance/LateLabel";
import PresentLabel from "../../ConsultationDashboard/Attendance/PresentLabel";
import CustomModal from "../../Utilities/CustomModal";
import UserProfile from "../../UserDashboard/UserProfile/UserProfile";
import "./AttendanceViewer.css";
import AbsentLabel from "../../ConsultationDashboard/Attendance/AbsentLabel";
import ReportLabel from "../../ConsultationDashboard/Attendance/ReportLabel";

export type AttendanceWithUser = {
    attendance:AttendanceEnum;
    isReported:boolean;
    user:UserBio;
}

type AttendanceViewerProps = {
    attendanceList:AttendanceWithUser[]
}

/**
 * Displays the attendance that was filled out by a consultant for a consultation review
 * @param props 
 * @returns 
 */
const AttendanceViewer = (props:AttendanceViewerProps) => {
    const {
        attendanceList
    } = props;

    const [selectedUser, setSelectedUser] = useState<IUserBio>();
    const [isUserOpen, setIsUserOpen] = useState(false);

    /**
     * Formatting for displaying a attendee name
     * @param attendee 
     * @returns 
     */
    const displayAttendeeName = (attendee:IUserBio) => (
        `${StringHelper.capitalizeWord(attendee.firstName)} ${StringHelper.capitalizeWord(attendee.lastName)}`
    )

    /**
     * Renders the correct attendance label for an attendee
     * @param attendanceWithUser 
     * @returns 
     */
    const renderAttendanceLabel = (attendanceWithUser:AttendanceWithUser) => {
        const attendance = attendanceWithUser.attendance;

        switch (attendance) {
            case AttendanceEnum.present:
                return <PresentLabel idx={0}/>
            case AttendanceEnum.late:
                return <LateLabel idx={0}/>
            case AttendanceEnum.absent:
                return <AbsentLabel idx={0}/>
            default:
                return "Unknown"
        }
    }

    /**
     * Opens and closes the user profile model
     */
    const toggleUserProfile = () => {   
        setIsUserOpen(!isUserOpen);     
    }

    /**
     * Updates the selected user state and toggles the userProfile model
     * @param user 
     */
    const selectUser = (user:IUserBio) => {
        setSelectedUser(user);
        toggleUserProfile();
    }

    return (
        <div className="cbit-dialog attendance-view">
            <h2 className="cbit-header">Attendees Attendance</h2>
            <AttendanceLegend />
            <div>
                {
                    attendanceList.map(attendance => (                        
                        <div className="attendance-view-item" key={attendance.user.id}>
                            <button onClick={() => selectUser(attendance.user)} className="btn-cbit-link">
                                {displayAttendeeName(attendance.user)}                                
                            </button>
                            {renderAttendanceLabel(attendance)}
                                {
                                    attendance.isReported &&
                                    (
                                        
                                        <span className="review-view-report">
                                            <ReportLabel htmlFor="report" />
                                        </span>
                                    )  
                                }
                        </div>                                
                    ))
                }
            </div>                    
            <CustomModal 
                    isOpen={isUserOpen} 
                    toggle={toggleUserProfile}
                >
                    <div className="cbit-dialog review-user-profile">
                        <UserProfile userBio={selectedUser ??  new UserBio()}/>
                    </div>
            </CustomModal>                            
        </div>
    )
}

export default AttendanceViewer;