import * as React from 'react'
import { PartialModuleTreeModel } from '../../../models/partialModels/PartialModuleTreeModel';
import { EditorContentEnum } from '../../../models/utility/EditorContentEnum';
import { ReactComponent as BreadCrumbArrow  } from '../../../resources/images/admin-breadcrumb-arrow.svg';
import { Link } from 'react-router-dom';
import { getModuleTreePath } from '../../../models/partialModels/PartialModuleTreeModel';
import './AdminBreadCrumbs.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { CurrentUserAccordionState } from '../../../store/UserModuleAccordionStore';
import { ILesson } from '../../../models/lesson/Lesson';
import { IModule } from '../../../models/module/Module';
import { AdminTreeModule, IAdminTreeModule } from '../../../models/partialModels/AdminTreeModule';

/**
 * Props for the Admin Bread Crumbs
 */
type AdminBreadCrumbsProps = {
    contentType: EditorContentEnum 
    setNavigateTo:(module:IAdminTreeModule | IModule | ILesson) => void
}

type AdminBreadCrumbsState = {
    crumbs:any[]
}

/**
 * Bread crumbs for the content builder 
 */
const AdminBreadCrumbs = (props:AdminBreadCrumbsProps) => {            
    const {contentType, setNavigateTo} = props;

    const defaultState :AdminBreadCrumbsState = {
        crumbs: []
    }

    const [state, setState] = useState(defaultState);

    const accordionStore = useSelector<ApplicationState, CurrentUserAccordionState | undefined>(state => state.currentUserModuleAccordion);

    useEffect(() => {
      updateCrumbs();
    
    }, []);
    
    useEffect(() => {
       function onSelectedModuleChange() {
            updateCrumbs();
       }
       onSelectedModuleChange(); 
    },[accordionStore?.selectedModule, accordionStore?.parentModule]);

    /**
     * Updates the Admin BreadCrumbs crumbs state
     */
    const updateCrumbs = () => {
        if(!accordionStore)
            return;

        const {parentModule, selectedModule} = accordionStore;
        
        const updatedCrumbs : any[] = [];
        
        getModuleTreePath(parentModule, updatedCrumbs, selectedModule.id);        

        setState(prevState => ({
            ...prevState,
            crumbs:updatedCrumbs
        }));
    }

    const getActiveBreadCrumb = (index:number, maxCrumbArrLength:number) => {
        return index === maxCrumbArrLength - 1 ? ('admin-active-breadcrumb') : ('admin-breadcrumb')
    }
    
    const {crumbs} = state;

    return (
        <>
            {
                crumbs.length <= 0 ? 
                (
                    <Link className='admin-breadcrumb' to="/admin-tools">
                        Back To Admin Tools
                    </Link>
                ) 
                : 
                (
                    <>
                        <ul className="admin-bread-crumbs-container">
                            <li onClick={() => setNavigateTo(new AdminTreeModule())} 
                                className='admin-breadcrumb'>
                                {accordionStore?.parentModule.name}
                            </li>
                            {
                                crumbs.map((crumb:IAdminTreeModule, index) => (
                                    <li key={crumb.id}>
                                        
                                        <BreadCrumbArrow className='admin-breadcrumb-arrow'/>
                                        
                                        <span 
                                            onClick={() => setNavigateTo(crumb)}
                                            className={getActiveBreadCrumb(index, crumbs.length)}>
                                            {crumb.name}
                                        </span>
                                    </li>
                                ))
                            }
                        </ul>
                    </>
                )
            }
        </>
    )    
}

export default AdminBreadCrumbs;