import ToolTipWrapper from "../../../Utilities/ToolTipWrapper/ToolTipWrapper";
import React from "react";

type PostFunctionToolBarItemProps = {
    toggleFlag: boolean,
    toolTipText: string,
    setModalState: (isOpen: boolean) => void,
    buttonClass?: string,
    icon1?: any,
    iconClass?: string,
    icon2?: any,
}

const PostFunctionToolBarItem = ({toggleFlag, toolTipText, setModalState, buttonClass, icon1, icon2, iconClass}: PostFunctionToolBarItemProps) => {
    return (
        <span>
            <ToolTipWrapper toolTipText={toolTipText}>
                <button className={`social-connection-post-toolbar-item ${buttonClass ? buttonClass : ''}`}
                        onClick={() => setModalState(true)}
                >
                    <>

                        {toggleFlag ? (
                             <span className={iconClass}>
                                {icon1}
                             </span>
                        ) : (
                             <span>
                                {icon2}
                             </span>
                        )}

                    </>
                </button>
            </ToolTipWrapper>
        </span>
    )
}

export default PostFunctionToolBarItem