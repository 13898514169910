export class FileHelper {
        /**
     * Converts a base64String To a blob so we can download or view files.
     * TODO: Move to a helper class
     * @param base64 
     * @param contentType 
     * @returns 
     */
    public static convertBase64ToBlob = (base64:string, contentType:string) => {
       const decodedStr = window.atob(base64);        
    
       const bytes = new Uint8Array(decodedStr.length);
    
       for (let i = 0; i < decodedStr.length; i++) {
          var ascii = decodedStr.charCodeAt(i);
          bytes[i] = ascii;
       }
    
       return new Blob([bytes], {type: contentType});
    }
}