import {Action, Reducer} from "redux";
import {RouteComponentProps} from "react-router";
import {PartialModuleTreeModel} from "../models/partialModels/PartialModuleTreeModel";
import {UserLessonContent} from "../models/lesson/UserLessonContent";
import { LessonIconEnum } from "../models/lesson/Lesson";

export interface CurrentUserAccordionState
{
    parentModule:PartialModuleTreeModel,
    selectedModule: PartialModuleTreeModel,
    selectedContent: UserLessonContent[],
    nextLessonId: string,
    isUpdating: boolean,
}

export type currentUserAccordionProps = CurrentUserAccordionState
    & typeof actionCreators
    & RouteComponentProps<{}>

export interface GetUserAccordion { type: 'GET_USERACCORDION' }
export interface SetUserAccordion { type: 'SET_USERACCORDION' , currentState: CurrentUserAccordionState }
export interface SetSelectedModule { type: 'SET_MODULE', currentState: PartialModuleTreeModel}
export interface SetSelectedContent { type: 'SET_CONTENT', currentState: UserLessonContent[]}
export interface RemoveUserAccordion { type: 'REMOVE_USERACCORDION' }

export type KnownAction = GetUserAccordion| SetUserAccordion | SetSelectedModule | SetSelectedContent |RemoveUserAccordion;

export const actionCreators = {
    GetUserAccordion: () => ({type: 'GET_USERACCORDION'} as GetUserAccordion),
    SetUserAccordion: (state:CurrentUserAccordionState) => (
        { type: 'SET_USERACCORDION', currentState:state } as SetUserAccordion
    ),
    SetSelectedModule: ( module:PartialModuleTreeModel) => (
        {type: 'SET_MODULE', currentState:module} as SetSelectedModule
    ),
    SetSelectedContent: (content:UserLessonContent[]) => (
        {type: 'SET_CONTENT', currentState:content} as SetSelectedContent
    ),
    RemoveUserAccordion: () => ({ type: 'REMOVE_USERACCORDION' } as RemoveUserAccordion)
};


export const unloadedState: CurrentUserAccordionState =
    {
        parentModule: {
            id: '',
            type: 0,
            originalDocumentId: '',
            order: 0,
            name: '',
            description: '',
            isViewable: true,
            isDisabled: false,
            isEditable: true,
            isOptional: true,
            isComplete: false,
            isOpen: false,
            isNext: false,
            isLocked: false,
            time: '',
            children: [],
            parentDocumentId: "",
            lessonIcon: LessonIconEnum.Unknown,
            totalQuestions: 0
        },
        selectedModule: {
            id: '',
            type: 0,
            originalDocumentId: '',
            order: 0,
            name: '',
            description: '',
            isViewable: true,
            isDisabled: false,
            isEditable: true,
            isOptional: true,
            isComplete: false,
            isLocked: false,
            isOpen: false,
            isNext: false,
            time: '',
            children: [],
            parentDocumentId: "",
            lessonIcon: LessonIconEnum.Unknown,
            totalQuestions: 0
        },
        nextLessonId:"",
        selectedContent:[],
        isUpdating:false,
    }

export const reducer: Reducer<CurrentUserAccordionState> = (state: CurrentUserAccordionState | undefined, incomingAction: Action): CurrentUserAccordionState => {
    if (state === undefined) {
        return  unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'GET_USERACCORDION':
            return {
                parentModule: state.parentModule,
                selectedModule: state.selectedModule,
                selectedContent: state.selectedContent,
                nextLessonId:state.nextLessonId,
                isUpdating: state.isUpdating
            };
        case 'SET_USERACCORDION':
            return {
                parentModule: action.currentState.parentModule,
                selectedModule: action.currentState.selectedModule,
                isUpdating: action.currentState.isUpdating,
                nextLessonId:action.currentState.nextLessonId,
                selectedContent: action.currentState.selectedContent,
            };
        case 'SET_MODULE':
            return {
                parentModule: state.parentModule,
                selectedModule: action.currentState,
                selectedContent: state.selectedContent,
                nextLessonId: state.nextLessonId,
                isUpdating: false,
            };
        case 'SET_CONTENT':
            return{
                parentModule: state.parentModule,
                selectedModule: state.selectedModule,
                nextLessonId:state.nextLessonId,
                selectedContent: action.currentState,
                isUpdating: false,
            };
        case 'REMOVE_USERACCORDION':
            return unloadedState;
        default:
            return state;
    }
};