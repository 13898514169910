import React, {useEffect} from "react";
import {Container, Form, FormGroup, Input, Label} from "reactstrap";
import {useHistory} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from "yup";
import {UserService} from "../../services/UserService";
import toast from "react-hot-toast";
import {PasswordTokenUpdateRequest} from "../../models/requests/PasswordTokenUpdateRequest";

const PasswordRecovery = ({match}: any) => {
    const history = useHistory();

    // Object from password token
    const passwordToken = history.location.pathname.split("/")[3];
    const requestId = history.location.pathname.split("/")[2];

    const minErrorMsg = (fieldName: string, minLen: number) => (`${fieldName} must be ${minLen} or more characters`);

    const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirmation: '',
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required('Required')
                .min(6, minErrorMsg('Password', 6)),
            passwordConfirmation: Yup.string()
                .oneOf([Yup.ref('passwordConfirmation'), null], 'Passwords must match')
                .required("Please confirm your password"),
        }),
        onSubmit: values => {

            if (formik.values.password !== formik.values.passwordConfirmation) {
                toast.error("Passwords do not match");
                return;
            }

            let passwordTokenObject: PasswordTokenUpdateRequest = {
                password: formik.values.password,
                token: passwordToken,
                id: requestId
            }

            try {
                const UService = new UserService();
                const userProfile = UService.UpdatePasswordWithToken(passwordTokenObject);
                toast.success("Password updated");
                history.push('/login');
            } catch (e) {
                toast.error("Error updating password");
                console.error(e);
            }
        },
    });

    return (
        <div>
            <Container>
                <Form className="forgot-form" onSubmit={formik.handleSubmit}>
                    <h2>Password Recovery</h2>
                    <FormGroup>
                        <Label for="password">
                            New Password
                        </Label>
                        <Input
                            id="password"
                            name="password"
                            placeholder="Password"
                            type="password"
                            invalid={!!(formik.touched.password && formik.errors.password)}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className={'error-message-container'}>{formik.errors.password}</div>
                        ) : null}
                    </FormGroup>
                    <FormGroup>
                        <Label for="passwordConfirmation">
                            Password Confirmation
                        </Label>
                        <Input
                            id="passwordConfirmation"
                            name="passwordConfirmation"
                            placeholder="Password"
                            type="password"
                            invalid={!!(formik.touched.passwordConfirmation && formik.errors.passwordConfirmation)}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.passwordConfirmation}
                        />
                        {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation ? (
                            <div className={'error-message-container'}>{formik.errors.password}</div>
                        ) : null}
                    </FormGroup>
                    <button type="submit" color="primary" className='btn-cbit-primary'>
                        Submit
                    </button>
                </Form>
            </Container>
        </div>
    )
}

export default PasswordRecovery