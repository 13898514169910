import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { UserController } from '../../../../controllers/UserController';
import { IUserBio, MaxCharactersForDescription } from '../../../../models/module/UserProfile';
import { DeepCopy } from '../../../../models/utility/DeepCopy';
import { ErrorHandler } from '../../../../models/utility/ErrorHandler';
import * as DOMPurify from 'dompurify';

type FormValues = {
    firstName:string
    lastName:string
    prefix:string
    title:string
    email:string
    zoomEmail?:string
    description:string    
    imageLink:string
}

type ConsultMyProfileFormState = {
    initialValues:FormValues
}

type ConsultMyProfileFormProps = {
    userBio:IUserBio
    isLoading:boolean
    setIsLoading:(isLoading:boolean) => void
    setIsEditing:(isEditing:boolean) => void
    onFormSubmit:(userBio:IUserBio) => void
}

const userController = new UserController();

/**
 * Form for updating consultants bio info
 * @param props 
 * @returns 
 */
const ConsultMyProfileForm = (props:ConsultMyProfileFormProps) => {
    const {userBio, setIsLoading, setIsEditing, onFormSubmit} = props;
    
    const defaultState:ConsultMyProfileFormState = {
        initialValues: {
            firstName: userBio.firstName,
            lastName: userBio.lastName,
            prefix: userBio.prefix,
            title: userBio.title,
            email: userBio.email,
            zoomEmail:userBio.zoomEmail,
            description: userBio.description,
            imageLink: userBio.imageLink
        }
    }

    const [state, setState] = useState(defaultState);    

    /**
     * Schema for validating consultant's bio info
     */
    const validationSchema = Yup.object({
        firstName: Yup.string()
        .max(32, "First name cannot be greater than 32 characters")
        .min(2, "First name cannot be less than 32 characters")
        .required("Please provide a first name"),
        lastName: Yup.string()
        .max(32, "Last name cannot be greater than 32 characters")
        .min(2, "Last name cannot be less than 32 characters")
        .required("Please provide a last name"),
        description: Yup.string()
        .max(MaxCharactersForDescription + 1, `Description cannot be greater than ${MaxCharactersForDescription} characters`),
        email: Yup.string()
        .email("Please provide a valid email")        
        .required("Please provide an email"),
        zoomEmail: Yup.string()
        .email("Please provide a valid zoom email")        
        .required("Please provide an zoom email"),
    });

    /**
     * Add error class for either the description textfield of the character limit indicator
     * @param isForTextField 
     * @returns 
     */
    const addErrorClassForCharacterLimit = (isForTextField:boolean, description:string) => {        
        const isDescriptionAboveLimit = description.length > MaxCharactersForDescription;
        
        if(isForTextField) {
            return isDescriptionAboveLimit ? 'text-field-error' : '';
        }

        return isDescriptionAboveLimit ? 'text-character-limit-error' : '';
    }

    /**
     * Submits profile data to be updated
     * @param values 
     * @param setSubmitting 
     * @returns 
     */
    const handleSubmit = async (values:FormValues, setSubmitting:(isSubmitting: boolean) => void) => {
        const userBioToUpdate:IUserBio = DeepCopy.copy(userBio);                

        if(values.description.length > MaxCharactersForDescription) {
            toast.error("Description is larger than the max character limit")
            return;
        }
        
        const cleanedInput = cleanInputs(values);

        userBioToUpdate.firstName = cleanedInput.firstName;
        userBioToUpdate.lastName = cleanedInput.lastName;
        userBioToUpdate.description = cleanedInput.description;
        userBioToUpdate.email = cleanedInput.email;
        userBioToUpdate.zoomEmail = cleanedInput.zoomEmail;
        userBioToUpdate.title = cleanedInput.title;
        userBioToUpdate.prefix = cleanedInput.prefix;
        
        setIsLoading(true);

        try {
            
            toast.loading("Updating profile...", {id:"profile-submit"});    
                    
            const results = await userController.UpdateUserBio(userBioToUpdate);

            if(results) {
                toast.dismiss("profile-submit");
                toast.success("Successfully updated your profile");                
                onFormSubmit(results)
            }

        } catch (error) {
            setIsLoading(false);
            toast.dismiss("profile-submit");
            console.error(error)
            ErrorHandler.displayErrorsInToast(error as string[]);            
        }
    }

    /**
     * Trim, and sanitizes user input
     * @param values 
     * @returns 
     */
    const cleanInputs = (values:FormValues) => {
        const purifyOpts = {USE_PROFILES: {html: false},  ALLOWED_TAGS: [] };

        values.firstName = DOMPurify.sanitize(values.firstName.trim(), purifyOpts);
        values.lastName = DOMPurify.sanitize(values.lastName.trim(), purifyOpts);
        values.description = DOMPurify.sanitize(values.description.trim(), purifyOpts);
        values.email = DOMPurify.sanitize(values.email.trim().toLowerCase(), purifyOpts);
        values.title = DOMPurify.sanitize(values.title.trim(), purifyOpts);
        values.prefix = DOMPurify.sanitize(values.prefix.trim(), purifyOpts);        
        
        if(values.zoomEmail) {
            values.zoomEmail = DOMPurify.sanitize(values.zoomEmail?.trim().toLowerCase(), purifyOpts);
        }

        return values;
    }

    return (
        <Formik
            enableReinitialize={true} 
            initialValues={state.initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, {setSubmitting}) => {handleSubmit(values, setSubmitting)}}
            >
            {({values}) => (
            <Form 
                translate={"yes"}                
            >                
                <>
                    <div className="cbit-row consult-profile-name-container profile-desktop">
                        <div className="cbit-column">
                            <label htmlFor="prefix" className="cbit-label">Prefix</label>
                            <Field 
                                id="prefix"
                                type="text" 
                                className="form-input"
                                name="prefix"                            
                                placeholder="No prefix Has Been Set"                                                
                            />
                        </div>
                        <div className="cbit-column">
                            <label htmlFor="firstName" className="form-label">First Name</label> {" "}
                            <span className='required-field'>*</span>    
                            <Field 
                                id="firstName"
                                type="text" 
                                className="form-input"
                                name="firstName"                                                        
                                placeholder="Enter Your First Name"     
                                required                                   
                            />
                            <div className="error-message-container">
                                <ErrorMessage name="firstName"/>
                            </div>
                        </div>
                        <div className="cbit-column">
                            <label htmlFor="lastName" className="form-label">Last Name</label>{" "}
                            <span className='required-field'>*</span>    
                            <Field 
                                id="lastName"
                                type="text" 
                                className="form-input"
                                name="lastName"                                                        
                                placeholder="Enter Your Last Name"     
                                required                                   
                            />
                            <div className="error-message-container">
                                <ErrorMessage name="lastName"/>
                            </div>
                        </div>
                    </div>

                    {/* Mobile Name Form Fields */}
                    <div className="cbit-row consult-profile-name-container mobile-profile-name">
                        <div className="cbit-column mobile-form-container">
                            <label htmlFor="prefix" className="cbit-label">Prefix</label>
                            <Field
                                id="prefix"
                                type="text"
                                className="form-input"
                                name="prefix"
                                placeholder="No prefix Has Been Set"
                            />
                        </div>
                    </div>
                    <div className="cbit-row consult-profile-name-container mobile-profile-name">
                        <div className="cbit-column mobile-form-container">
                            <div>
                                <label htmlFor="firstName" className="form-label">First Name</label> {" "}
                                <span className='required-field'>*</span>
                            </div>
                            <Field
                                id="firstName"
                                type="text"
                                className="form-input"
                                name="firstName"
                                placeholder="Enter Your First Name"
                                required
                            />
                            <div className="error-message-container">
                                <ErrorMessage name="firstName"/>
                            </div>
                        </div>
                    </div>
                    <div className="cbit-row consult-profile-name-container mobile-profile-name">
                        <div className="cbit-column mobile-form-container">
                            <div>
                                <label htmlFor="lastName" className="form-label">Last Name</label>{" "}
                                <span className='required-field'>*</span>
                            </div>
                            <Field
                                id="lastName"
                                type="text"
                                className="form-input"
                                name="lastName"
                                placeholder="Enter Your Last Name"
                                required
                            />
                            <div className="error-message-container">
                                <ErrorMessage name="lastName"/>
                            </div>
                        </div>
                    </div>

                    <div className="cbit-row">                                                                
                        <label htmlFor="occupation" className="cbit-label">Occupation</label>                                                         
                    </div>
                    <div className="cbit-row">
                        <div className="cbit-column">
                            <Field   
                                type="text" 
                                className="form-input occupation-text" 
                                name="title"
                                id="occupation"                                                
                                placeholder="No Occupation Has Been Set"                                
                            />
                            <div className="error-message-container">
                                <ErrorMessage name="title"/>
                            </div>
                        </div>
                    </div>
                    <div className="cbit-row">
                        <label htmlFor="email" className="cbit-label">Email</label> {" "}
                        <span className='required-field'>*</span>                                     
                    </div>
                    <div className="cbit-row">
                        <div className="cbit-column">
                            <Field 
                                id="email"
                                type="email"
                                className="form-input"
                                name="email"                                                
                                required
                                />      
                            <div className="error-message-container">
                                <ErrorMessage name="email"/>
                            </div>                                              
                        </div>
                    </div>
                    <div className="cbit-row">
                        <label htmlFor="email" className="cbit-label">Zoom Email</label> {" "}
                        <span className='required-field'>*</span>                                     
                    </div>
                    <div className="cbit-row">
                        <div className="cbit-column">
                            <Field 
                                id="zoomEmail"
                                type="email"
                                className="form-input"
                                placeholder="Zoom Email Has Not Been Set"
                                name="zoomEmail"                                                
                                required
                                />      
                            <div className="error-message-container">
                                <ErrorMessage name="zoomEmail"/>
                            </div>                                              
                        </div>
                    </div>
                    <div className="cbit-row">
                        <label htmlFor="bio" className="cbit-label">Bio</label> {" "}
                        <span className='required-field'>*</span>                                            
                    </div>
                    <div className="cbit-row">                        
                            <Field 
                                name="description" 
                                className={`consultant-bio-description form-input ${addErrorClassForCharacterLimit(true, values.description)}`}
                                id="bio" 
                                cols={30}
                                rows={10}     
                                component="textarea"                                           
                                placeholder="Bio Description"
                                required
                            />
                        
                    </div>
                    <div 
                        className={`cbit-row text-character-limit-container ${addErrorClassForCharacterLimit(false, values.description)}`}
                    >
                        <div className="cbit-column">
                        {`Characters Left: ${(MaxCharactersForDescription - values.description.length).toLocaleString()}`}
                        <div className="error-message-container">
                                <ErrorMessage name="description"/>
                            </div>
                        </div>
                    </div>
                    <div className="cbit-row cbit-btn-container">
                        <button 
                            onClick={() => setIsEditing(false)} 
                            className="btn-cbit-minor"
                            type="button"
                        >
                            Discard
                        </button>
                        <button type='submit' className="btn-cbit-primary">Save</button>
                    </div>  
                </>
            </Form>
            )}
        </Formik>
    )
}

export default ConsultMyProfileForm;
