import * as React from 'react';
import ReactQuill from 'react-quill';
import SanitizeHTML from '../../models/utility/SanitizeHTML';
import './RenderHTML.css';

type RenderHTMLProps = {
    HTML:string;
    cssClass:string;
}

/**
 * Component for rendering HTML
 */
class RenderHTML extends React.Component<RenderHTMLProps> {
    private HTML:string = '';

    /**
     * Sanitizes the passed in HTML prop
     */
    constructor(props:any) {
        super(props);
        this.HTML = SanitizeHTML.sanitize(this.props.HTML);        
    }

    componentDidUpdate(prevProps:RenderHTMLProps) {
        if(prevProps !== this.props) {
            this.HTML = this.props.HTML;
        }
    }

    public render() {
        const {cssClass} = this.props;
        return (
            <div className={cssClass} dangerouslySetInnerHTML={{__html: this.HTML}}>                                
            </div>
        )
    }

}

export default RenderHTML;