import {BaseReactService} from "./interfaces/BaseReactService";
import {AxiosError} from "axios";
import {ILessonContent} from "../models/lesson/LessonContent";
import {ErrorHandler} from "../models/utility/ErrorHandler";

export class LessonContentService extends BaseReactService {

    constructor(){
        super("LessonContent/");
    }
    
    public async GetAllByLessonId(id:string):Promise<ILessonContent[] | never>
    {
        const endpoint = 'LessonContent/GetAllByLessonId/';
        try
        {
            const params = {id};
            return await this.Get(params,endpoint);
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
}