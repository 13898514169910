import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import { ConsultationController } from '../../../../controllers/ConsultationController';
import { Consultation, IConsultation } from '../../../../models/consultations/Consultation';
import { IConsultationSession } from '../../../../models/consultations/ConsultationSession';
import { IUserBio } from '../../../../models/module/UserProfile';
import CloudflareImageHelper from '../../../../models/utility/CloudflareImageHelper';
import TimeHelper from '../../../../models/utility/TimeHelper';
import iconCalendarSrc from '../../../../resources/icons/icon-calendar.png';
import IconLeftChevronWhiteSrc from '../../../../resources/icons/icon-chevron-left-white.png';
import IconRightChevronWhiteSrc from '../../../../resources/icons/icon-chevron-right-white.png';
import { ApplicationState } from '../../../../store';
import { CurrentUserState } from '../../../../store/CurrentUser';
import CustomModal from '../../../Utilities/CustomModal';
import AvailableSessionItem from './AvailableSessionItem';
import './ConsultantBookingAvailability.css';
import SuccessfulBookingAlert from './SuccessfulBookingAlert';
import MyProfileDescription from '../../../UserDashboard/MyProfile/MyProfileDescription';
import BookAndWithdrawView from './BookAndWithdrawView';

type ConsultantBookingAvailabilityProps = {
    consultant:IUserBio
    bookedSession?:IConsultationSession | null
    returnToConsultants:() => void
}

type ConsultantBookingAvailabilityState = {
    currentWeek:Date
    isLoading:boolean
    groupOfDates:any[]
    paymentBookedModal:boolean
    availableSessions:IConsultationSession[]
}

const consultationController = new ConsultationController();

/**
 * Booking calendar of a consultants available sessions.
 * A user can select a session to join a session.
 */
const ConsultantBookingAvailability = (props:ConsultantBookingAvailabilityProps) => {
    const {
            consultant, 
            bookedSession,
            returnToConsultants
        } = props;

    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    const defaultState:ConsultantBookingAvailabilityState = {
        currentWeek: new Date(),
        isLoading:false,
        groupOfDates:[],
        paymentBookedModal:false,
        availableSessions:[]
    }

    const [state, setState] = useState(defaultState);    

    const [isConsultantBioOpen, setIsConsultantBioOpen] = useState(false);

    const [isSuccessAlertOpen, setSuccessAlert] = useState(false);

    const prevDate =  useMemo(() => (moment(state.currentWeek).subtract(1, 'days')).toDate(),[state.currentWeek]);

    const imageHostUrl = CloudflareImageHelper.imageUrl;

    useEffect(() => {
        async function onCurrentWeekChange() {                    
            await getAvailableConsultation();
        }
        onCurrentWeekChange();
    },[state.currentWeek]);

    useEffect(() => {
        async function onConsultationsChange() {
            getGroupDates(state.availableSessions);
        }
        onConsultationsChange();
    }, [state.availableSessions]);

    useEffect(() => {
        if(bookedSession) {
            bookingPaymentSuccess();
        }
    },[bookedSession])

    /**
     * Get consultation that are available
     * @returns 
     */
    const getAvailableConsultation = async () => {
        if(!userStore) {
            return;
        }
        const isToday = moment(state.currentWeek).isSame(new Date(), "day");
        
        //If the start is today we want to get today's date at the current time
        const startOfWeek = isToday ? 
        TimeHelper.createMomentDate(new Date()) : TimeHelper.createMomentDate(state.currentWeek).startOf("day");
        
        const endOfWeek = TimeHelper.createMomentDate(state.currentWeek).add(4, "day").startOf("day");
        

        try {
            let sessions:IConsultationSession[] = await consultationController.GetConsultantsAvailableConsultationsByDate(
                                                                                consultant.id, startOfWeek.format(), endOfWeek.format());
                                                                                
            
            if(sessions !== null) {            
                
                sortSessionByTime(sessions);
                
                getGroupDates(sessions);

                setState(prevState => ({
                    ...prevState,
                    availableSessions:sessions
                }));

            }

        } catch (error) {
            toast.error("Failed to get consultations")
            return;
        }
    }

    /**
     * Sort session time in ascending order earlier dates show first
     */
    const sortSessionByTime = (sessions:IConsultationSession[]) => {
        sessions.sort((a, b) => moment(a.consultation.startDate as string) < (moment(b.consultation.startDate as string)) ? -1 : 1)
    }

    /**
     * groups the available consultations by date for display 
     * @param availableConsultations 
     */
    const getGroupDates = (availableSessions:IConsultationSession[]) => {                
       
            let startOfWeek = moment(state.currentWeek).startOf('week');            
            
            const isSameDay = moment(startOfWeek.date()).isSame(state.currentWeek, 'D');                 
    
            /** We want to filter out any dates before the current date/time */
            if(!isSameDay) {
                
                startOfWeek = moment(state.currentWeek);
            }
            
            const endOfWeek = moment(startOfWeek).clone().add(5, 'days');    

            const groupOfDates:any = {};
            
            for(let currentDate = startOfWeek; currentDate.isBefore(endOfWeek); currentDate.add(1, 'day')) {
                const dayString = currentDate.format("YYYY-MM-DD");
                
                groupOfDates[dayString] = [];
            }
    
            for(let session of availableSessions) {
                const {consultation} = session
                const startDate = (moment(new Date(consultation.startDate as string))).format("YYYY-MM-DD");
    
                if(!groupOfDates[startDate]) {
                    groupOfDates[startDate] = [];
                }
                groupOfDates[startDate].push(session);
            }
    
            setState(prevState => ({
                ...prevState,
                groupOfDates
            }));            
    }

    /**
     * Checks if the passed in date is before the today
     * @param dateToCheck 
     * @returns 
     */
    const checkIfDateHasPastToday = (dateToCheck:Date) => {
        
        const today = new Date();

        return dateToCheck < today;
    }

    /**
     * Navigates between weeks
     */
    const navigateThroughWeeks = (forward:boolean) => {
        const navigateBy = forward ? 5 : -5;

        const currentDate =  moment(state.currentWeek).add(navigateBy,'day');

        let startOfNewDate = moment(currentDate).startOf('day').toDate();

        if(checkIfDateHasPastToday(startOfNewDate)) {
            startOfNewDate = new Date();
        }

        setState(prevState => ({
            ...prevState,
            currentWeek:startOfNewDate
        })); 
    }

    /**
     * Get the text version of the current week
     * @returns 
     */
    const currentWeekText = () => {
        const {currentWeek} = state;

        return `${moment(currentWeek).format('MM/DD/YY')} - ${moment(currentWeek).add(4,'days').format('MM/DD/YY')}`;
    }

    /**
     * handle date change click popup menu change
     */
    const onInputCalendarChange = (e:React.ChangeEvent<HTMLInputElement>) => {        
            const inputValue = e.currentTarget.value;
            
             /**
              * HTML 5 date input gives us back a utc string (which will show the previous date) 
              * Moment converts it to the proper date
              */
            let convertedDate = moment(inputValue);               
    
            let updatedDate = convertedDate.toDate();
    
            setState(prevState => ({
                ...prevState,
                currentWeek: updatedDate
            }));
    }

    /**
    * Update our consultations in state for after an item has been booked
    */
    const updateConsultations = async (bookedConsultation:IConsultation, isCanceling?:boolean) => {

        await getAvailableConsultation();

        if(isCanceling) {            
            return;
        }

        toggleSuccessBooking();
        
    }

    /**
     * Toggles a alert that says a consultation have been successfully booked
     */
    const toggleSuccessBooking = () => {         
        setSuccessAlert(!isSuccessAlertOpen);
    }

    /**
     * Toggle if the consultant's full bio should be shown or not
     */
    const toggleConsultantBio = () => {        
        setIsConsultantBioOpen(!isConsultantBioOpen);
    }

    const togglePaymentBookModal = () => {
        setState(prevState => ({
            ...prevState,
            paymentBookedModal: !state.paymentBookedModal
        }));
    }

    /**
     * Update state to show that a purchased consultation was booked
     */
    const bookingPaymentSuccess = () => {
        if(!bookedSession) {
            return;
        }

        const consultation = bookedSession.consultation;

        const consultationDate = moment(consultation.startDate).startOf('day').toDate();
        setState(prevState => ({
            ...prevState,
            currentWeek: consultationDate,
            paymentBookedModal: true
        }));
        setSuccessAlert(true);

    }

    return (
        <>
            <div className="book-consultations-container consultant-book-availability">
            {
                <>
                    <div className="return-consultant-container">
                        <button 
                            onClick={returnToConsultants}
                            className="btn-cbit-link btn-return-consultant">
                            { "< Return to Consultants"}
                        </button>
                    </div>
                    <div className="consultant-availability-info">
                        <div className="consultant-info-container-left">
                        {
                            consultant.imageLink && consultant.imageLink.length > 0 ? 
                            (
                                <div className="consultant-profile-img-container">
                                    <img src={`${imageHostUrl}${consultant.imageLink}/consultantProfileImage`} />
                                </div>
                            )
                            :
                            (   
                                <div className="empty-profile-image"><div>No Image</div></div>
                            )
                        }
                        </div>
                        <div className="consultant-info-container-right">
                            <div className="consultant-name">
                                {consultant.firstName} {consultant.lastName}
                            </div>
                            <div className="consultant-booking-title">
                                {consultant.title}
                            </div>
                            <div className='consultant-booking-bio-container'>
                                <p className={`availability-booking-bio consultant-booking-bio`}>
                                    {consultant.description}
                                </p>
                             </div>
                                {
                                    consultant.description.length > 270 && 
                                    (
                                        <button 
                                            onClick={toggleConsultantBio}
                                            className="btn-cbit-link"
                                        >
                                            View More
                                        </button>
                                    )
                                }
                        </div>
                    </div>
                    <div className="booking-availability-container">
                        <div className="booking-availability-controls">
                            <button
                                className='btn-availability-control btn-cbit-icon' 
                                disabled={checkIfDateHasPastToday(prevDate)} 
                                onClick={() => navigateThroughWeeks(false)}
                            >
                                <img src={IconLeftChevronWhiteSrc} alt="Left Chevron Icon" />
                            </button>     
                            <span className='availability-date-range'>{currentWeekText()}</span>               
                            <button  
                                className='btn-cbit-icon btn-availability-control'          
                                onClick={() => navigateThroughWeeks(true)}              
                            >
                                <img src={IconRightChevronWhiteSrc} alt="Right Chevron Icon"/>
                            </button>
                            <div className="date-picker-btn-container">
                                <input                                 
                                    id='booking-date-picker' 
                                    name="startOfWeek" 
                                    type='date'                            
                                    min={moment(new Date).format(moment.HTML5_FMT.DATE)}
                                    value={moment(new Date(state.currentWeek)).format(moment.HTML5_FMT.DATE)}
                                    onChange={onInputCalendarChange} 
                                />
                                <button className='btn-cbit-link btn-consultation-calendar'>
                                    <img src={iconCalendarSrc} alt="Calendar Button Icon" />
                                </button>
                            </div>
                        </div>
                        <div className="booking-availability-info">
                            <div className="availability-dates">
                                {Object.keys(state.groupOfDates).map((bookingDay) => (                                
                                    <div className="availability-date-item" key={uuidV4()}>
                                    <div className="availability-date-header">
                                        <div className="day-of-the-week">                                           
                                            {moment(bookingDay).format("dddd")}
                                        </div>
                                        <div className="availability-date">
                                            {moment(bookingDay).format("MM/DD/YYYY")}
                                        </div>
                                    </div>
                                    <div className="available-sessions-list">
                                            {
                                                //@ts-ignore
                                                state.groupOfDates[bookingDay].length > 0 ?
                                                (
                                                    //@ts-ignore
                                                    Consultation.SortConsultationsByTime(state.groupOfDates[bookingDay]).map((session:IConsultationSession) => (
                                                        <React.Fragment key={uuidV4()}>
                                                            <AvailableSessionItem consultationSession={session} updateConsultations={updateConsultations} />
                                                        </React.Fragment>
                                                    ))
                                                )
                                                :
                                                (
                                                    <div className="non-available-session-item">No sessions available</div>    
                                                )
                                            }
                                    </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            }
            </div>
            <CustomModal isOpen={isConsultantBioOpen} toggle={toggleConsultantBio} className='booking-availability-bio'>
                <MyProfileDescription 
                    name={`${consultant.firstName} ${consultant.lastName}`} 
                    description={consultant.description}            
                />
            </CustomModal>
            {
                bookedSession != null && (
                    <CustomModal 
                        isOpen={state.paymentBookedModal} 
                        toggle={togglePaymentBookModal}
                    >
                    <BookAndWithdrawView 
                        consultationSession={bookedSession} 
                        toggleModal={togglePaymentBookModal}
                        togglePayment={() => {}} 
                        toggleSuccessBooking={toggleSuccessBooking}
                        updateConsultations={updateConsultations}
                    />
                </CustomModal>         
            )}  
            <CustomModal isOpen={isSuccessAlertOpen} toggle={toggleSuccessBooking}>
                    <SuccessfulBookingAlert toggleModal={toggleSuccessBooking}/>                
            </CustomModal> 
        </>
    )
}

export default ConsultantBookingAvailability;