
import { AxiosError } from "axios";
import { BaseWebService } from "./interfaces/BaseWebServices";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { ConsultationType } from "../models/consultations/ConsultationType";
import { ConsultationPaymentIntentRequest } from "../models/Stripe/ConsultationPaymentIntentRequest";

/**
 * Service for communicating with the EduTrainer API
 */
export class StripeService extends BaseWebService {
    constructor() {        
        super("Stripe");        
    }

    /**
     * Get product information from stripe
     * @param productId 
     * @returns 
     */
    public async GetStripeProduct(productId:string) {
        try {            
            const endPoint = "GetStripeProduct";
            const resp = await this.Get(endPoint, {productId});
            return resp.data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Get stripe product by price id
     * @param priceId 
     * @returns 
     */
    public async GetStripeProductByPriceId(priceId:string) {
        try {
            const endPoint = "GetStripeProductByPriceId";
            const resp = await this.Get(endPoint, {priceId});
            return resp.data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
    /**
     * Calls the api to create a new stripe customer and subscription.
     * @param user 
     * @returns 
     */
    public async CreateCbitSubscription(userId:string) {
        try {            
            const endPoint = 'CreateCbitSubscription';
            const resp = await this.Post(endPoint, {userId});
            return resp.data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Calls the api to get the current logged in user current subscription payment intent.
     * @param user 
     * @returns 
     */
    public async GetCurrentSubscriptionIntent() {
        try {            
            const endPoint = 'GetCurrentSubscriptionIntent';
            const resp = await this.Get(endPoint, {});
            return resp.data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Calls the api to get a consultation payment intent.
     * @param user 
     * @returns 
     */
    public async CreateConsultationPaymentIntent(paymentIntentReq:ConsultationPaymentIntentRequest) {
        try {            
            const endPoint = 'CreateConsultationPaymentIntent';
            const resp = await this.Post(endPoint, paymentIntentReq);
            return resp.data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Creates a subscription schedule for a test user. 
     * Note: a subscription schedule is needed in order to pay for a cbit subscription 
     */
    public async CreateSubscriptionForTestUser(userId:string) {
        try {
            const endPoint = 'CreateSubscriptionForTestUser';
            const resp = await this.Post(endPoint, {userId});
            return resp.data;
        } catch (error) {
            throw error;
        }
    }
}