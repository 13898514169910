import React from "react";
import CloudflareImageHelper from "../../../models/utility/CloudflareImageHelper";
import userPlaceholderIcon from "../../../resources/images/user-placeholder.png";
import "./ProfileImage.css";

type profileImageProps = {
    imageVariant:string
    imageLink:string
    isLocal?:boolean
}

/**
 * Component for displaying a profile image
 */
const ProfileImage  = (props:profileImageProps) => {
    const {imageVariant, imageLink, isLocal} = props;
    const imageHostUrl = CloudflareImageHelper.imageUrl;
    return (
        <div className="profile-image-container-temp">
        {

            isLocal ? 
            (
                <img className='profile-image-icon local-image' src={imageLink} alt="Profile Image" />
            ) 
            : 
            (
                <>
                {
                    imageLink && imageLink.length > 0 ? 
                    (
                        <img className='profile-image-icon' src={`${imageHostUrl}${imageLink}/${imageVariant}`} alt="Profile Image" />
                    )
                    :
                    (                                                                        
                        <img className='profile-image-icon' src={userPlaceholderIcon} alt="No Profile Image" />
                    )
                }
                </>
            )

        }
        </div>    
    )
}

export default ProfileImage;