import React, {useEffect, useMemo, useState} from "react"
import {IPost} from "../../../../models/SocialConnection/Post";
import {SocialConnectionController} from "../../../../controllers/SocialConnectionController";
import toast from "react-hot-toast";
import {FaCheck, FaCheckDouble, FaEdit, FaEye, FaEyeSlash} from "react-icons/fa";
import {GiHammerDrop} from "react-icons/gi";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {CurrentUserState} from "../../../../store/CurrentUser";
import FunctionModal from "./FunctionModal";
import PostFunctionToolBarItem from "./PostFunctionToolBarItem";
import {MdReportProblem} from "react-icons/md";
import PostFunctionReportedToolBarItem from "./PostFunctionReportedToolBarItem";

type PostFunctionToolBarProps = {
    post: IPost,
    updatePost: (posts: IPost) => void,
    replyIndex: number,
    isEditing: boolean,
    isPostPage: boolean | undefined,
    setIsEditing?: (isEditing: boolean) => void,
}

const PostFunctionToolBar = ({post, updatePost, isEditing, setIsEditing, isPostPage, replyIndex}: PostFunctionToolBarProps) => {

    const isReply = useMemo<boolean>(()=> replyIndex == -1 ? false : true, [replyIndex]);

    const sConnectionController = new SocialConnectionController();
    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state) => state.currentUser);

    const [isReportModalOpen, setIsReportModalOpen] = useState(false);

    const [isPromotedPostModalOpen, setIsPromotedPostModalOpen] = useState(false);

    const [isDeletingModalOpen, setIsDeletingModalOpen] = useState(false);

    const [isBanningModalOpen, setIsBanningModalOpen] = useState(false);

    const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);

    useEffect(() => {
    }, [post]);

    /**
     * Update a post attribute by reflection
     * @param attribute
     * @param value
     * @returns {Promise<void>}
     */
    const updateAttributeByReflection = async (attribute:string, value:any) => {
        let newpost = post;

        if (!isReply) {
            // @ts-ignore
            newpost[attribute] = value;
        }
        else {
            // @ts-ignore
            newpost.replies[replyIndex][attribute] = value;
        }

        let response = await new SocialConnectionController().UpdatePost(post.id, newpost);

        updatePost(newpost);

        switch(attribute){
            case 'isVisible':
                toast.success(`${isReply ? 'Reply' : 'Post'} Visibility Has Been Updated`);
                setIsDeletingModalOpen(false);
                break;
            case 'isPromoted':
                toast.success(`${isReply ? 'Reply' : 'Post'} Promoted Status Has Been Updated`);
                setIsPromotedPostModalOpen(false);
                break;
            case 'isEditing':
                toast.success(`${isReply ? 'Reply' : 'Post'} Content Has Been Updated`);
                setIsEditingModalOpen(false);
                break;
            case 'isReported':
                toast.success(`${isReply ? 'Reply' : 'Post'} Report Status Has Been Updated`);
                setIsReportModalOpen(false);
                break;
        }
    }

    /**
     * Get a post attribute by reflection
     * @param attribute
     * @constructor
     * @returns {any}
     */
    const GetAttributeValue = (attribute: string): any => {

        if (!isReply) {
            // @ts-ignore
            let value = post[attribute];
            return value;
        }
        else {
            if(replyIndex >= post.replies.length)
                return false;

            // @ts-ignore
            let value = post.replies[replyIndex][attribute];
            return value;
        }

    }

    /**
     * Bans a user
     * @returns {Promise<void>}
     */
    const banUser = async () => {
        if (!UserStore?.isAdmin && !UserStore?.isSuperAdmin) return;
        //Ban this user
        let id = post.userId

        const sConnectionController = new SocialConnectionController();

        let updatedUser = {
            isBanned: true
        }

        let newpost = post;
        newpost.isBanned = true;

        updatePost(newpost);

        toast.success('User has been banned');
        setIsBanningModalOpen(false);

        await sConnectionController.AdminBanUser(id, updatedUser);
        return;
    }

    /**
     * Unbans a user
     * @returns {Promise<void>}
     */
    const unbanUser = async () => {
        if (!UserStore?.isAdmin && !UserStore?.isSuperAdmin) return;

        let id = post.userId

        const sConnectionController = new SocialConnectionController();

        let updatedUser = {
            isBanned: false
        }

        let newpost = post;
        newpost.isBanned = false;
        updatePost(newpost);
        toast.success('User has been unbanned');
        setIsBanningModalOpen(false);

        await sConnectionController.AdminBanUser(id, updatedUser);
        return;
    }

    /**
     * Toggles the editing of a post
     * @returns {Promise<void>}
     */
    const handleEditPost = async () => {
        setIsEditing!(true);
        setIsEditingModalOpen(false);
    }

    /**
     * Conditional Logic Functions for Icons
     */
    const UserExists = (): boolean => {
        if (!UserStore)
            return false;
        return true;
    }


    //#region Button Visibility Conditions

    //#region Visibility Checks
    const IsPostOrReplyVisible = (): boolean => {
        let isVisible;

        if (replyIndex == -1) {
            // @ts-ignore
            isVisible = post.isVisible;
        }

        else {
            // @ts-ignore
            isVisible = post.replies[replyIndex].isVisible;
        }

        return isVisible;
    }

    const IsUserAdminOrSuperAdmin = (): boolean => {

        if (UserStore!.isSuperAdmin || UserStore!.isAdmin)
            return true;

        return false;
    }

    const PostOrReplyBelongToUser = (): boolean => {

        if (replyIndex == -1) {
            if (UserStore!.userProfile.id === post.userId)
                return true;
        }

        else {
            if (UserStore!.userProfile.id === post.replies[replyIndex].userId)
                return true;
        }

        return false;
    }
    //#endregion
    const IsDeleteButtonVisible = (): boolean => {

        if (!UserExists())
            return false;

        if (IsUserAdminOrSuperAdmin())
            return true;

        if (PostOrReplyBelongToUser())
            return true;

        if (replyIndex == -1)
            return false;

        if (PostOrReplyBelongToUser())
            return true;

        return false;
    }

    const IsReportButtonVisible = (): boolean => {

        if (!UserExists())
            return false;

        if (!IsPostOrReplyVisible())
            return false;

        return true;

    }

    const IsEditButtonVisible = (): boolean => {

            if (!UserExists())
                return false;

            if (!isPostPage)
                return false;

            if (!IsPostOrReplyVisible())
                return false;

            if (PostOrReplyBelongToUser())
                return true;

            if (IsUserAdminOrSuperAdmin())
                return false;

            if (replyIndex == -1)
                return false;

            if (PostOrReplyBelongToUser())
                return true;

            return false;

    }

    const IsPromoteButtonVisible = (): boolean => {

            if (!UserExists())
                return false;

            if (!IsPostOrReplyVisible())
                return false;

            if (replyIndex != -1)
                return false;

            if (IsUserAdminOrSuperAdmin())
                return true;

            return false;

    }

    const IsBanButtonVisible = (): boolean => {

            if (!UserExists())
                return false;

            if (!IsPostOrReplyVisible())
                return false;

            if (IsUserAdminOrSuperAdmin())
                return true;

            return false;

    }

    //#endregion

    return (
        <>
            {UserStore && (
                <React.Fragment key={UserStore.userProfile.id}>
                    <div className="social-connection-post-toolbar" onClick={(e) => e.stopPropagation()}>
                        {/* User/Admin Post Tools - Icons */}
                        {/* Promote */}
                        {IsPromoteButtonVisible() && (
                            <PostFunctionToolBarItem
                                toolTipText={`${post.isPromoted ? 'Demote' : 'Promote'}`}
                                buttonClass={'promote'}
                                icon1={<FaCheckDouble/>}
                                icon2={<FaCheck/>}
                                iconClass={'promoted'}
                                setModalState={setIsPromotedPostModalOpen}
                                toggleFlag={GetAttributeValue('isPromoted')}
                            />
                        )}
                        {/* Ban */}
                        {IsBanButtonVisible() && (
                            <PostFunctionToolBarItem
                                toolTipText={`${post.isBanned ? 'Unban' : 'Ban'}`}
                                buttonClass={'ban'}
                                icon1={<GiHammerDrop/>}
                                icon2={<GiHammerDrop/>}
                                iconClass={'banned'}
                                setModalState={setIsBanningModalOpen}
                                toggleFlag={GetAttributeValue('isBanned')}
                            />
                        )}
                        {/* Delete */}
                        {IsDeleteButtonVisible() && (
                            <PostFunctionToolBarItem
                                toolTipText={`${GetAttributeValue('isVisible') ? 'Delete' : 'Restore'}`}
                                buttonClass={'delete'}
                                icon1={<FaEye/>}
                                icon2={<FaEyeSlash />}
                                iconClass={'deleted-icon'}
                                setModalState={setIsDeletingModalOpen}
                                toggleFlag={!GetAttributeValue('isVisible')}
                            />
                        )}
                        {/* Report */}
                        {IsReportButtonVisible() && (
                            post?.isReported && !isReply ? (
                                <PostFunctionReportedToolBarItem
                                    toggleFlag={GetAttributeValue('isReported')}
                                    icon1={<MdReportProblem/>}
                                    iconClass={'reported'}
                                    toolTipText={'Reported'}
                                />
                            ) : post?.replies[replyIndex]?.isReported ? (
                                <PostFunctionReportedToolBarItem
                                    toggleFlag={GetAttributeValue('isReported')}
                                    icon1={<MdReportProblem/>}
                                    iconClass={'reported'}
                                    toolTipText={'Reported'}
                                />
                            ) : (
                                <PostFunctionToolBarItem
                                    toolTipText={`${!isReply ? post.isReported ? 'Resolve' : 'Report' : post.replies[replyIndex].isReported ? 'Resolve' : 'Report'}`}
                                    buttonClass={'report'}
                                    icon1={<MdReportProblem/>}
                                    icon2={<MdReportProblem/>}
                                    iconClass={'reported'}
                                    setModalState={setIsReportModalOpen}
                                    toggleFlag={GetAttributeValue('isReported')}
                                />
                            )
                        )}
                        {/* Edit */}
                        {IsEditButtonVisible() && (
                            <PostFunctionToolBarItem
                                toolTipText={'Edit'}
                                buttonClass={'edit'}
                                icon1={<FaEdit/>}
                                icon2={<FaEdit/>}
                                iconClass={''}
                                setModalState={setIsEditingModalOpen}
                                toggleFlag={isEditing}
                            />
                        )}
                    </div>
                </React.Fragment>
            )}

            {/* User/Admin Post Tools - Modals */}
            {/* Promote */}
            {IsPromoteButtonVisible() && (
                <FunctionModal
                    isOpen={isPromotedPostModalOpen}
                    setIsOpen={setIsPromotedPostModalOpen}
                    action={() => updateAttributeByReflection('isPromoted', !GetAttributeValue('isPromoted'))}
                    text={`Would you like to ${GetAttributeValue('isPromoted') ? 'Demote' : 'Promote'} this ${isReply ? 'reply' : 'post'}?`}
                    title={`${GetAttributeValue('isPromoted') ? 'Demote' : 'Promote'} ${isReply ? 'reply' : 'post'}`}
                />
            )}
            {/* Ban */}
            {IsBanButtonVisible() && (
                <FunctionModal
                    isOpen={isBanningModalOpen}
                    setIsOpen={setIsBanningModalOpen}
                    action={GetAttributeValue('isBanned') ? () => unbanUser() : () => banUser()}
                    text={`Are you sure you want to ${GetAttributeValue('isBanned') ? 'unban' : 'ban'} this user?`}
                    title={`${GetAttributeValue('isBanned') ? 'Unban' : 'Ban'} user`}
                />
            )}
            {/* Delete */}
            {IsDeleteButtonVisible() && (
                <FunctionModal isOpen={isDeletingModalOpen}
                               setIsOpen={setIsDeletingModalOpen}
                               action={() => updateAttributeByReflection('isVisible',!GetAttributeValue('isVisible'))}
                               text={`Are you sure you want to ${isReply ? (
                                   GetAttributeValue('isVisible') ? 'delete' : 'restore'
                               ) : (
                                   GetAttributeValue('isVisible') ? 'delete' : 'restore'
                               )} this ${isReply ? 'reply' : 'post'}?`}
                               title={`${isReply ? (
                                   GetAttributeValue('isVisible') ? 'Delete' : 'Restore'
                               ) : (
                                   GetAttributeValue('isVisible') ? 'Delete' : 'Restore'
                               )} ${isReply ? 'reply' : 'post'}`}
                />
            )}
            {/* Report */}
            {IsReportButtonVisible() && (
                <FunctionModal
                    isOpen={isReportModalOpen}
                    setIsOpen={setIsReportModalOpen}
                    action={() => updateAttributeByReflection('isReported', !GetAttributeValue('isReported'))}
                    text={`Are you sure you want to ${GetAttributeValue('isReported') ? 'resolve' : 'report'} this ${isReply ? 'reply' : 'post'}?`}
                    title={`${GetAttributeValue('isReported') ? 'Resolve' : 'Report'} ${isReply ? 'reply' : 'post'}`}
                />
            )}
            {/* Edit */}
            {IsEditButtonVisible() && isPostPage && (
                <FunctionModal
                    isOpen={isEditingModalOpen}
                    setIsOpen={setIsEditingModalOpen}
                    action={handleEditPost}
                    text={`Are you sure you want to edit this ${isReply ? 'reply' : 'post'} ?`}
                    title={`Edit ${isReply ? 'reply' : 'post'}`}
                />
            )}
        </>
    )
}

export default PostFunctionToolBar