import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { ConsultationController } from '../../../../controllers/ConsultationController';
import { Consultation, IConsultation } from '../../../../models/consultations/Consultation';
import { IConsultationSession } from '../../../../models/consultations/ConsultationSession';
import { ConsultationUIType } from '../../../../models/consultations/ConsultationType';
import { RolesHelper } from '../../../../models/utility/Roles';
import { StringHelper } from '../../../../models/utility/StringHelper';
import { ApplicationState } from '../../../../store';
import { CurrentUserState } from '../../../../store/CurrentUser';
import CustomAlert from '../../../Utilities/CustomAlert';
import CustomModal from '../../../Utilities/CustomModal';
import RecurringAlert from '../../ConsultationForms/RecurringAlert';
import ConsultationAttendeesDetails from '../../ConsultationItems/ConsultationAttendeesDetails';
import './ConsultantsConsultationSessionInfo.css';

type ConsultantsConsultationSessionProps = {
    consultationId:string
    deleteConsultation:(id:string) => void
    deleteRecurringConsultation:(meetingId:number) => void
    toggleSessionInfo:() => void
    toggleEditPlanForm:(consultation:IConsultationSession) => void
}

type ConsultantsConsultationSessionState = {
    isLoading:boolean
    consultationSession:IConsultationSession | null

}

const consultationController = new ConsultationController();

/**
 * Consultation info item for consultants to view and edit
 * Only for consultants
 * @returns 
 */
const ConsultantsConsultationSessionInfo = (props:ConsultantsConsultationSessionProps) => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);
    const {
            consultationId,
            toggleSessionInfo,
            toggleEditPlanForm,
            deleteConsultation,
            deleteRecurringConsultation
    } = props;

    const defaultState:ConsultantsConsultationSessionState = {
        isLoading:false,
        consultationSession:null,
    }
    
    const [state, setState] = useState(defaultState);

    const [isRecurringAlertOpen, setRecurringAlert] = useState(false);
    
    const [isDeleteNormalAlertOpen, setDeleteNormalAlert] = useState(false);
        
    const [isDeleteRecurringAlertOpen, setDeleteRecurringAlert] = useState(false);

    const [isDeleteOccurrenceAlertOpen, setDeleteOccurrenceAlert] = useState(false);    

    const {consultant, consultation, attendeesBioList} = state.consultationSession || {};

    useEffect(() => {
        async function onComponentMount() {
            await getConsultationSession();
        }
        onComponentMount();
    }, []);

    /**
     * Get session info for a consultation
     */
    const getConsultationSession = async () => {
        try {

            const consultationSession = await consultationController.GetConsultationSession(consultationId);               
            
            if(consultationSession == null) {
                toast.error("Could not retrieve session information");
                return;
            }             

            setState(prevState => ({
                ...prevState,
                consultationSession
            }));
            
        } catch (error) {
            console.error(error);
            toast.error("Failed to retrieve session information");
        }        
    }

    const checkIfAllowedToSeeAttendees = () => {
        if(!userStore) return false;

        if(!consultant) return false;

        return userStore.userProfile.id === consultant.id || RolesHelper.CheckIfSuperAdmin(userStore.userProfile.roles)
    }

    /**
     * Get the number of attendees and format them for display
     */
    const displayNumOfAttendees = (consultation:IConsultation) => {
        const maxSpace = consultation.maxNumberOfAttendees;

        const currentNumberOfAttendees = consultation.attendeeList.length;

        return `${currentNumberOfAttendees}/${maxSpace}`;
    }

    /**
     * Toggles away the session info modal and opens the edit plan form
     * @param consultationSession
     */
    const handleEditClick = (consultationSession:IConsultationSession | null) => {      
          
        if(!consultationSession) return;

        toggleSessionInfo();
        
        toggleEditPlanForm(consultationSession);
    }

    const toggleRecurringAlert = () => {
        const isRecurringAlert = !isRecurringAlertOpen;

        setRecurringAlert(isRecurringAlert);
    }

    const toggleDeleteNormalAlert = () => {
        const isDeleteOpen = !isDeleteNormalAlertOpen;

        setDeleteNormalAlert(isDeleteOpen);
    }

    const toggleDeleteRecurringAlert = () => {
        const isDeleteOpen = !isDeleteRecurringAlertOpen;

        setDeleteRecurringAlert(isDeleteOpen);
    }

    const toggleDeleteOccurrenceAlert = () => {
        const isDeleteOpen = !isDeleteOccurrenceAlertOpen;

        setDeleteOccurrenceAlert(isDeleteOpen);
    }
    
    const onDeleteClick = async () => {
        if(!consultation) {
            return;
        }
        
        //If we have an occurrence id it means we are part of a recurring consultation
        if(!StringHelper.IsNullOrWhiteSpace(consultation.occurrenceId)) {
            toggleRecurringAlert();
            return;
        }

        await toggleDeleteNormalAlert();
    }

    const deleteNormalConsultation = async () => {
        if(!consultation) {
            return;
        }
        const toastDeleteLoadId = "deleteConsultationLoad";
        toast.loading("Deleting consultation", {id: toastDeleteLoadId});

        try {
            
            const isDeleted = await consultationController.CancelConsultation(consultation.id);

            if(isDeleted) {
                deleteConsultation(consultation.id);
            }
            
            toggleDeleteNormalAlert();
        } catch (error) {
            console.error(error);
            toast.error("Failed to delete consultation");
        }
        toast.dismiss(toastDeleteLoadId);
    }

    const deleteRecurring = async () => {
        if(!consultation) {
            return;
        }
        const toastDeleteLoadId = "deleteRecurringConsultationLoad";
        toast.loading("Deleting recurring consultations", {id: toastDeleteLoadId});

        try {
            
            await consultationController.DeleteUpcomingRecurringConsultation(consultation.id);

            
            deleteRecurringConsultation(consultation.zoomMeetingLinkId);
            toggleDeleteRecurringAlert();
            
        } catch (error) {
            console.error(error);
            toast.error("Failed to delete recurring consultations");
        }

        toast.dismiss(toastDeleteLoadId);
    }

    const deleteOccurrence = async () => {
        if(!consultation) {
            return;
        }
        const toastDeleteLoadId = "deleteConsultationOccurrence";
        toast.loading("Deleting recurring consultations",  {id: toastDeleteLoadId});

        try {
            
            await consultationController.DeleteConsultationOccurrence(consultation.id);
            
            deleteConsultation(consultation.id);
            toggleDeleteOccurrenceAlert();
            
        } catch (error) {
            console.error(error);
            toast.error("Failed to delete consultation occurrence");
        }

        toast.dismiss(toastDeleteLoadId);
    }

    const handleDeleteAlertForRecurring = async () => {        
        toggleRecurringAlert();
        toggleDeleteRecurringAlert();
    }
    
    const handleDeleteAlertForOccurrence = async () => {        
        toggleRecurringAlert();
        toggleDeleteOccurrenceAlert();
    }    

    return (
        <>
        {
            state.isLoading ? 
            (
                <Spinner ></Spinner>
            )
            :
            (
                <>
                {
                    attendeesBioList && consultant && consultation ? 
                    (
                            <div className='consultation-plan-form form consultation-info'>                
                                <h2 className="form-title">CONSULTATION INFORMATION</h2>
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <div className="cbit-label">Session Title</div>
                                    </div>
                                </div>
                            <div className="cbit-row">
                                <div className="cbit-column view-details-text-container">
                                    {consultation.name}
                                </div>
                            </div>
                            <div className="cbit-row">
                                <div className="cbit-column">
                                    <div className="cbit-label">Session Link</div> 
                                </div>
                            </div>
                            <div className="cbit-row">
                                <div className="cbit-column view-details-text-container">
                                    {
                                        StringHelper.IsNullOrWhiteSpace(consultation.link) ? 
                                        (
                                            <>
                                                Meeting link could not be found
                                            </>
                                        )
                                        :
                                        (
                                            <a 
                                                className='session-link btn-cbit-link' 
                                                href={consultation.link}
                                                target='blank'
                                            >
                                                Meeting Link
                                            </a> 
                                        )
                                    } 
                                </div>
                            </div>
                            <div className="cbit-row">
                                <div className="cbit-column">
                                    <div className="cbit-label">Date</div>
                                </div>
                            </div>
                            <div className="cbit-row">
                                <div className="cbit-column view-details-text-container">
                                    {moment(consultation.startDate as string).format("dddd, MM/D/YY")}
                                </div>
                            </div>
                            <div className="cbit-row">
                                <div className="cbit-column">
                                    <div className="cbit-label">Time</div>
                                </div>
                            </div>
                            <div className="cbit-row">
                                <div className="cbit-column view-details-text-container">
                                    {Consultation.GetConsultationTime(consultation)}
                                </div>
                            </div>
                            <div className="cbit-row">
                                <div className="cbit-column">
                                    <div className="cbit-label">Session Type</div>
                                </div>
                            </div>
                            <div className="cbit-row">
                                <div className="cbit-column view-details-text-container">
                                    {ConsultationUIType[consultation.type]}
                                </div>
                            </div>
                            <div className="cbit-row">
                                <div className="cbit-column">
                                    <div className="cbit-label">Number Of Attendees</div>
                                </div>
                            </div>
                            <div className="cbit-row">
                                <div className="cbit-column view-details-text-container">
                                    {displayNumOfAttendees(consultation)}
                                </div>
                            </div>
                            <div className="cbit-row">
                                <div className="cbit-column">
                                    <div className="cbit-label">Attendee Names</div>
                                </div>
                            </div>
                            <>
                                {
                                    checkIfAllowedToSeeAttendees() && (
                                        <ConsultationAttendeesDetails 
                                            attendees={attendeesBioList}
                                        />
                                    )
                                }
                            </>

                            <div className="cbit-row">
                                <div className="cbit-column view-details-text-container view-details-btn-container">
                                    <button
                                        onClick={() => handleEditClick(state.consultationSession)} 
                                        className="consultants-session-info btn-cbit-primary"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={onDeleteClick} 
                                        className="consultants-session-info btn-cbit-minor"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                    :
                    (
                        <div>Could not find session info</div>
                    )
                }
                </>
            )
        }
        <CustomModal
            isOpen={isRecurringAlertOpen}
            toggle={toggleRecurringAlert}
        >
            <RecurringAlert 
                isDelete={true}
                headerText='Delete Recurring Consultations'
                toggleAlert={toggleRecurringAlert} 
                onOccurrenceUpdateSubmit={handleDeleteAlertForOccurrence} 
                onRecurringUpdateSubmit={handleDeleteAlertForRecurring}        
            />
        </CustomModal>
        <CustomAlert 
            header={'ARE YOU SURE YOU WANT TO DELETE?'} 
            text={`Once you click "Delete", the consultation will be deleted immediately.`} 
            primaryBtnText={'DELETE'} 
            secondaryBtnText={'Cancel'} 
            isOpen={isDeleteNormalAlertOpen} 
            primaryBtnSubmit={() => deleteNormalConsultation()} 
            secondaryBtnSubmit={toggleDeleteNormalAlert}             
            toggleAlert={toggleDeleteNormalAlert}
        />   
        <CustomAlert 
            header={'ARE YOU SURE YOU WANT TO DELETE?'} 
            text={`Once you click "Delete", the consultation and all following occurrences will be deleted immediately.`} 
            primaryBtnText={'DELETE'} 
            secondaryBtnText={'Cancel'} 
            isOpen={isDeleteRecurringAlertOpen} 
            primaryBtnSubmit={() => deleteRecurring()} 
            secondaryBtnSubmit={toggleDeleteRecurringAlert}             
            toggleAlert={toggleDeleteRecurringAlert}
        />   
        <CustomAlert 
            header={'ARE YOU SURE YOU WANT TO DELETE?'} 
            text={`Once you click "Delete", this consultation occurrence will be deleted immediately.`} 
            primaryBtnText={'DELETE'} 
            secondaryBtnText={'Cancel'} 
            isOpen={isDeleteOccurrenceAlertOpen} 
            primaryBtnSubmit={() => deleteOccurrence()} 
            secondaryBtnSubmit={toggleDeleteOccurrenceAlert}             
            toggleAlert={toggleDeleteOccurrenceAlert }
        />   
        </>
    )
}

export default ConsultantsConsultationSessionInfo;