import { AxiosError } from "axios";
import FileArtifact from "../models/FileArtifact/FileArtifact";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { ArtifactServices } from "../services/ArtifactServices";
import {SiteLocation} from "../models/Enums/SiteLocation";

export class ArtifactController {
    /**
     *
     */
    constructor() {
        this.artifactServices = new ArtifactServices();
    }

    private artifactServices: ArtifactServices;

    public async GetArtifact(id:string):Promise<FileArtifact> {
        try {
            return await this.artifactServices.Get({id});
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Upload a pdf file to an artifact that is then stored in the mongo database
     * @param pdfFile
     * @returns
     */
    public async UploadPdfFiles(pdfFile:File):Promise<FileArtifact> {
        //TODO add file validation check
          const data = new FormData();

          data.append("pdfFile", pdfFile);

        try {
            return  await this.artifactServices.uploadPDFToMongo(data);

        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Download the contents of an pdf artifact that is in the database
     */
    public async DownloadPdfFile(artifactId:string){
        try {
            return await this.artifactServices.DownloadPdfFile(artifactId);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }


    public async GetPdfDownloadUrl(artifactId:string) {
        return this.artifactServices.GetPdfDownloadUrl(artifactId);
    }

    public GetPdfDownloadUrlWithLog(location:string , artifactId:string) {
        return this.artifactServices.GetFileAndLogUrl(location, artifactId);
    }
    public async GetDownloadName(artifactId:string) {
        return this.artifactServices.GetDownloadName(artifactId);
    }

    public async GetPdfDownloadWithLog(artifactId:string, location:SiteLocation){
        let url = await this.artifactServices.GetPDFDownloadAndLogUrl(location.toString(), artifactId);
        return url;
    }

    public async UpdateArtifact(artifactId:string, file:File){
        try {

            const formData = new FormData();
            formData.append("id", artifactId);
            const newCreatedAt = new Date().toUTCString();
            formData.append("createdAt",newCreatedAt);
            formData.append("file",file);
            return await this.artifactServices.UpdateArtifact(formData);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Delete an artifact and the whatever file is stored in it
     * @param id
     */
    public async DeleteArtifact(id:string) {
        try {
            return await this.artifactServices.Delete({id});
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
}
