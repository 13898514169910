import {BaseReactService} from "./interfaces/BaseReactService";
import {IPost} from "../models/SocialConnection/Post";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import {AxiosError} from "axios";

export class SocialConnectionService extends BaseReactService {
    constructor() {
        super("Post/");
    }

    /**
     * Gets all posts
     * @returns An array of posts
     */
    public async GetAllPosts(): Promise<IPost[]> {
        const endPoint = 'Post/GetAllPosts';

        try {
            return await this.CustomGet({}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Gets all posts by a user
     * @param id The id of the user
     * @returns An array of posts
     */
    public async GetAllPostsByUser(id: string): Promise<IPost[]> {
        const endPoint = 'Post/GetAllPostsByUser/';

        try {
            return await this.Get({id}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Gets a post by id
     * @param id The id of the post
     * @returns A post
     */
    public async GetPostById(id: string): Promise<IPost> {
        const endPoint = 'Post/GetPostById/';

        try {
            return await this.Get({id}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Creates a post
     * @param post The post to create
     * @returns void
     */
    public async CreatePost(post: any): Promise<void> {
        const endPoint = 'Post/Post';

        try {
            return await this.Post(post, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Updates a post
     * @param post The post to update
     * @param id The id of the post to update
     */
    public async UpdatePost(id: string, post: any): Promise<void> {
        const endPoint = 'Post/Put/';

        try {
            return await this.Put({id}, post, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Edits a reply as a user
     * @param id
     * @param updatedPost
     * @constructor
     */
    public async EditReply(id: string, updatedPost: any): Promise<void> {
        const endPoint = 'Post/EditReply/';

        try {
            return await this.Put({id}, updatedPost, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Adds a reply to a post
     * @param id
     * @param params
     * @returns void
     */
    public async AddReply(id: any, params: any): Promise<void> {
        const endPoint = 'Post/UpdateReplies/';

        try {
            return await this.Put({id}, params, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Searches for posts by keyword
     * @param keyword The keyword to search for
     * @returns An array of posts that match the keyword
     */
    public async SearchPosts(keyword: string): Promise<IPost[]> {
        const endPoint = 'Post/SearchPosts';

        try {
            return await this.CustomGet({keyword}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Admin Functions
     */

    /**
     * Gets all posts for an admin
     * @returns An array of posts
     */
    public async AdminGetAllPosts(): Promise<IPost[]> {
        const endPoint = 'Post/AdminGetAllPosts';

        try {
            return await this.CustomGet({}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Bans a user as an Admin
     * @param id The id of the user to ban
     * @param params
     * @returns void
     */
    public async AdminBanUser(id: string, params: any): Promise<void> {
        const endPoint = 'Post/AdminBanUser/';

        try {
            return await this.Put({id}, params, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Admin search for posts by keyword, isVisible, and isReported
     * @param keyword The keyword to search for
     * @param isVisible
     * @param isReported
     * @returns An array of posts that match the keyword, isVisible, and isReported
     */
    public async AdminSearchPosts(keyword: string, isVisible: boolean | null, isReported: boolean | null): Promise<IPost[]> {
        const endPoint = 'Post/AdminSearchPosts';

        try {
            return await this.CustomGet({keyword, isVisible, isReported}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
}