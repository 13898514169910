import { AxiosError } from "axios";
import { GroupedAdditionalContent, IAdditionalContent } from "../models/AdditionalContent/AdditionalContent";
import { AdditionalVideoContent, IAdditionalVideoContent } from "../models/AdditionalVideoContent/AdditionalVideoContent";
import { ILesson } from "../models/lesson/Lesson";
import { ILessonContent } from "../models/lesson/LessonContent";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { AdditionalContentService } from "../services/AdditionalContentService";

export class AdditionalContentController {
    /**
     *
     */
    constructor() {                
        this.additionalContentServices = new AdditionalContentService();
    }

    private additionalContentServices;

    public async GetAdditionalContentAndGroupByTag(tagIds:string[]):Promise<GroupedAdditionalContent[] | undefined> {
        try {
            return await this.additionalContentServices.GetContentGroupByTagIds(tagIds);
        } catch (error) {
            console.error(error);        
        }
    }

    public async RefreshVideoAdditionalContent() {
        try {
            return await this.additionalContentServices.RefreshVideoAdditionalContent();
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async createAdditionalVideoContent(additionalContent:IAdditionalContent) {
        try {            
            return await this.additionalContentServices.CreateAdditionalVideoContent(additionalContent);
        } catch (error) {
            console.error(error);
        }        
    }

    public async createAdditionalContent(additionalContent:IAdditionalContent) {
        try {
            
            return await this.additionalContentServices.Post(additionalContent);

        } catch (error) {
            return ErrorHandler.generalError(error);
        }
    }

    public async updateAdditionalContentItem(additionalContent:IAdditionalContent) {
        try {
            return await this.additionalContentServices.Put({id: additionalContent.id}, additionalContent);
        } catch (error) {
            console.error(error);
        }
    }

    public async updateTagInAdditionalContentItem(oldTagId:string, newTagId:string):Promise<GroupedAdditionalContent[] | undefined> {
        try {
            return await this.additionalContentServices.UpdateTagInAdditionalContent(oldTagId, newTagId);
        } catch (error) {
            console.error(error);
        }
    }    

    public async DeleteAdditionalContent(additionalContentId:string): Promise<void> {
        try {
            await this.additionalContentServices.Delete({id: additionalContentId});
        } catch (error) {
            console.error(error);
        }
    }

    public async DeleteTagInAdditionalContentItems(tagToDelete:string):Promise<boolean | undefined> {
        try {
            return await this.DeleteTagInAdditionalContentItems(tagToDelete);
        } catch (error) {
            console.error(error);
        }
    }

}