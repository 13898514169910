import moment from 'moment';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import useModal from '../../../hooks/UseModal';
import { Consultation, IConsultation } from '../../../models/consultations/Consultation';
import { IConsultationSession } from '../../../models/consultations/ConsultationSession';
import { ConsultationUIType } from '../../../models/consultations/ConsultationType';
import CloudflareImageHelper from '../../../models/utility/CloudflareImageHelper';
import { RolesHelper } from '../../../models/utility/Roles';
import { ApplicationState } from '../../../store';
import { CurrentUserState } from '../../../store/CurrentUser';
import CustomModal from '../../Utilities/CustomModal';
import ConsultationAttendeesDetails from './ConsultationAttendeesDetails';
import './SessionItem.css';

export enum SessionItemType {
    upcoming = 0,
    previous = 1
}

type SessionItemProps = {
    children:React.ReactNode
    sessionType:SessionItemType
    consultationSession:IConsultationSession
}

/**
 * Consultation session item used for displaying session info
 * for either the upcoming or previous session pages
 * @param props 
 * @returns 
 */
const SessionItem = (props:SessionItemProps) => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);
    
    const imageHostUrl = useRef(CloudflareImageHelper.imageUrl);
    
    const {
        children,
        sessionType,
        consultationSession: {
            consultant, 
            consultation, 
            attendeesBioList
        },
    } = props;

    const [isModalOpen, , toggleModal] = useModal(false);

    /**
     * Formats the date to display in the below format:
     * Wednesday, 01/11/23
     * @param consultation 
     * @returns 
     */
    const formatConsultationDate = (consultation:IConsultation) => {
        return moment(consultation.startDate).format("dddd, MM/DD/YY");
    }

    /**
     * Get the logged in user appropriate zoom link
     * @returns 
     */
    const getZoomLink = () => {
        if(!userStore) return "";

        if(RolesHelper.CheckIfSuperAdmin(userStore.userProfile.roles)) {
            return consultation.link;
        }

        return Consultation.GetSessionsZoomLink(userStore.userProfile.id, consultation)
    }

    /**
     * Check if allowed to see attendees. Only the consultant owner and admins should be able to see the list
     * @returns 
     */
    const checkIfAllowedToSeeAttendees = () => {
        if(!userStore) return false;
        return userStore.userProfile.id === consultant.id || RolesHelper.CheckIfSuperAdmin(userStore.userProfile.roles)
    }

    return (
        <>
        {
            consultant != null && consultation != null && 
            (
                <div className="session-item">
                    <div className="consultant-info-container-left session-profile-icon">
                        {consultant.imageLink && consultant.imageLink.length > 0 ?
                            (
                                <div className="consultant-profile-img-container">
                                <img src={`${imageHostUrl.current}${consultant.imageLink}/consultantProfileImage`} />
                            </div>
                            ) 
                            : 
                            (
                                <div className="empty-profile-image"><div>No Image</div></div>
                            ) 
                        }
                    </div>
                    <div className="consultant-info-container-right session-profile-info">
                        <h4 className="consultation-sub-header">
                            {consultation.name}
                        </h4>
                        <div className="consultation-info cbit-text">
                            <div>
                                <span>
                                    consultant:{" "}
                                </span>
                                {`${consultant.firstName} ${consultant.lastName}`}
                            </div>
                            <div>
                                <span>
                                    date:{" "}
                                </span>
                                {formatConsultationDate(consultation)}
                            </div>
                            <div>
                                <span>
                                    time:{" "}
                                </span>
                                {Consultation.GetConsultationTime(consultation)}
                            </div>
                            <div>
                                <span>
                                    session type:{" "}
                                </span>
                                {ConsultationUIType[consultation.type]}
                            </div>
                            <div>
                                <span className='text-transform-normal'>
                                    Number of attendees:{" "}
                                </span>
                                {`${attendeesBioList.length}/${consultation.maxNumberOfAttendees}`}
                            </div>
                                                            
                                    <div>
                                        <span>
                                            Session Link:{" "}
                                        </span>
                                        {
                                            getZoomLink().length > 0 ? 
                                            (                             
                                                <a 
                                                    className='session-link btn-cbit-link' 
                                                    href={getZoomLink()} 
                                                    target='blank'
                                                >
                                                    Join Meeting
                                                </a>  
        
                                            )
                                            :
                                            (
                                                <>
                                                    Meeting link could not be found
                                                </>
                                            )
                                        }
                                    </div>
                                                            
                            {checkIfAllowedToSeeAttendees() && (
                                <div>
                                    <span>
                                        Attendees:{" "}
                                    </span>
                                    <ConsultationAttendeesDetails 
                                        attendees={attendeesBioList}                                        
                                    />
                                </div>
                            )}
                        </div>
                        <div className="consultation-action-container">
                            {children}
                        </div>
                    </div>
            </div>
            )
        }
        </>
    )
}

export default SessionItem;