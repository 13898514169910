import * as React from 'react';
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { LessonController } from "../../../controllers/LessonController";
import { UserLessonController } from "../../../controllers/UserLessonController";
import { AdditionalContent } from '../../../models/AdditionalContent/AdditionalContent';
import { AdditionalContentEnum } from '../../../models/Enums/AdditionalContentEnum';
import pdfIconSrc from '../../../resources/icons/icon-pdf.png';
import { ApplicationState } from "../../../store";
import * as UserTreeView from "../../../store/UserModuleAccordionStore";
import CustomModal from '../../Utilities/CustomModal';
import noContentSrc from '../../../resources/images/img-no-content.png';
import "./AdditionalVideosViewer.css";
import {SiteLocation} from "../../../models/Enums/SiteLocation";
import {ContentViewLogController} from "../../../controllers/ContentViewLogController";
import {ArtifactController} from "../../../controllers/ArtifactController";
import {useHistory} from "react-router";


type UserAccordionItemProps =
    UserTreeView.currentUserAccordionProps
    & typeof UserTreeView.actionCreators

type AdditionalVideoState =
    {
        additionalContentList: AdditionalContent[],
        selectedUserLessonId: string,
        hasLoaded:boolean,
        isDisabled:boolean,
        isModalOpen:boolean,
        selectedVideoContent:AdditionalContent | null
    }

class AdditionalVideosViewer extends React.PureComponent< UserAccordionItemProps, AdditionalVideoState>
{
    private lessonController = new LessonController();
    private userLessonController =new UserLessonController();
    private contentViewLogController = new ContentViewLogController();
    private artifactController = new ArtifactController();

    constructor(props:any)
    {
        super(props);
        this.state = {
            selectedUserLessonId: "",
            hasLoaded: false,
            additionalContentList: [],
            isDisabled:false,
            isModalOpen:false,
            selectedVideoContent:null
        };
    }


    //When a lesson is selected
    async componentDidUpdate(prevProps:Readonly<UserAccordionItemProps>, prevState:Readonly<AdditionalVideoState>, snapshot?:any)
    {
        try
        {
            if(!this.state.hasLoaded)
            {
                if(this.props.selectedModule.id != this.state.selectedUserLessonId && this.props.selectedModule.id != '')
                {
                    let additionalContent = await this.lessonController.GetAdditionalVideosForLessonId(this.props.selectedModule.originalDocumentId);

                    let hasVideos = additionalContent.length > 0;

                    this.setState(prevState => ({
                        ...prevState,
                        additionalContentList: additionalContent,
                        selectedUserLessonId: this.props.selectedModule.id,
                        hasLoaded:true,
                        isDisabled:hasVideos,
                    }))

                }
            }else
            {
                if(this.props.selectedModule.id != this.state.selectedUserLessonId)
                {
                    if(this.props.selectedModule.id != '')
                    {
                        this.setState(prevState=>({
                            ...prevState,
                            additionalContentList:[],
                            hasLoaded:false,
                        }))
                    }
                }

            }
        }catch(error)
        {

        }
    }

    private toggleModal = () => {
        this.setState(prevState => ({
            ...prevState,
            isModalOpen: !this.state.isModalOpen
        }));
    }

    public GetDownloadUrl = async (item:AdditionalContent) =>{
        let data:string  =  await this.artifactController.GetPdfDownloadWithLog(item.artifactId, SiteLocation.AdditionalContent)
        window.open(data);
    }

    public handleVideoClick = (e:any, videoContent:AdditionalContent) => {
        e.preventDefault;
        this.setState(prevState => ({
            ...prevState,
            selectedVideoContent:videoContent,
            isModalOpen:true
        }));

        //TODO: Send Post Log
        var response = this.contentViewLogController.UpdateViewCount(SiteLocation.AdditionalContent, videoContent.name);

    }

    public render()
    {
        return (
            <>
                <div className='addVideoName'>
                    {/* RECOMMENDED VIDEOS */}
                    Toolbox
                </div>
                <div className='add-video-separator'/>
                <div>
                    {
                        this.state.additionalContentList.length <= 0 ?
                        (
                            <div className='no-content-additional-video-container'>
                                <div className="no-content-image-container">
                                    <div className="no-content-image">
                                        <img src={noContentSrc} alt="" />
                                    </div>
                                </div>
                                <div className="no-content-text">
                                    No Toolbox content found
                                </div>
                            </div>
                        )
                        :
                        (
                            <>
                                {this.state.additionalContentList.map((vContent)=>(
                                    <React.Fragment key={vContent.id}>
                                        {
                                            vContent.type === AdditionalContentEnum.video ?
                                            (
                                                <>
                                                    <div className='add-video-container' onClick={(e) => this.handleVideoClick(e, vContent)}>
                                                        <div className='addVideo-box'>
                                                            {
                                                                vContent.thumbnailUrlWithPlayButton.length > 0 ?
                                                                (
                                                                    <img className='vimeo-thumbnail' src={vContent.thumbnailUrlWithPlayButton} alt="Video Thumbnail" />
                                                                )
                                                                :
                                                                (
                                                                    <></>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='addVideo-text'>
                                                            <div className='addVideo-titleText'>{vContent.name}</div>
                                                            <div className='addVideo-lengthText'>{vContent.videoLength}</div>
                                                        </div>
                                                    </div>
                                                </>

                                            )
                                            :
                                            (
                                                <>
                                                    <div onClick={()=>this.GetDownloadUrl(vContent)} className="pdf-toolbox-box">
                                                        <div className='addVideo-box'>
                                                            <div className="pdf-img-container">
                                                                <img src={pdfIconSrc} alt="PDF Image"/>
                                                            </div>
                                                        </div>
                                                        <div className='addVideo-text'>
                                                            <div className='addVideo-titleText'>{vContent.name}</div>
                                                            <div className='addVideo-lengthText'>{vContent.videoLength}</div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </React.Fragment>
                                ))}
                            </>
                        )
                    }

                </div>
                <CustomModal
                    toggle={this.toggleModal}
                    isOpen={this.state.isModalOpen}
                    className="therapist-support-modal"
                >
                    <div className="react-player-wrapper">
                        {this.state.selectedVideoContent ?
                            (
                                <>
                                    <ReactPlayer
                                        url={this.state.selectedVideoContent.videoLink}
                                        controls={true}
                                        className="react-player"

                                />
                                <div className="react-player-title-container">
                                    <p className="react-player-title">
                                        {this.state.selectedVideoContent.name}
                                    </p>
                                </div>
                                </>
                            )
                            :
                            (
                                <></>
                            )
                        }


                    </div>
                </CustomModal>
            </>
        )
    }

}

export default connect(
    (state: ApplicationState) => state.currentUserModuleAccordion,
    UserTreeView.actionCreators
)(AdditionalVideosViewer);
