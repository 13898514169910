import React from 'react';
import './CbitTextArea.css';

type CbitTextAreaProps = {
    name:string
    id:string
    value:string
    cols:number
    rows:number
    maxCharacterLimit:number
    onChange:any
    required:boolean
    additionalClasses:string[]
}

type CbitTextAreaState = {

}

const CbitTextArea = (props:CbitTextAreaProps) => {
    const {name, id, value, cols, rows, maxCharacterLimit, onChange, required, additionalClasses} = props;

    /**
     * Add error class for either the description textfield of the character limit indicator
     * @param isForTextField 
     * @returns 
     */
    const addErrorClassForCharacterLimit = (isForTextField:boolean) => {
        const isDescriptionAboveLimit = value.length > maxCharacterLimit;
        
        if(isForTextField) {
            return isDescriptionAboveLimit ? 'text-field-error' : '';
        }

        return isDescriptionAboveLimit ? 'text-character-limit-error' : '';
    }

    const getAdditionalClasses = () => {
        if(!Array.isArray(additionalClasses)) return "";

        return additionalClasses.join(" ");
    }

    return (
        <>            
            <textarea 
                name={name} 
                className={`form-input ${getAdditionalClasses()} ${addErrorClassForCharacterLimit(true)}`}
                id={name} 
                cols={cols}
                rows={rows}
                onChange={onChange}
                value={value}
                required={required}
                ></textarea>
            <span 
                className={`text-character-limit-container ${addErrorClassForCharacterLimit(false)}`}
                >
            {`Characters Left: ${(maxCharacterLimit - value.length).toLocaleString()}`}
            </span>
        </>
    )
}

export default CbitTextArea;
