import { ILesson } from "../models/lesson/Lesson";
import { ILessonContent } from "../models/lesson/LessonContent";
import { IAdminModule, AdminModule } from "../models/module/AdminModule";
import { IModule } from "../models/module/Module";
import { AdminTreeModule } from "../models/partialModels/AdminTreeModule";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { sortModule } from "../models/utility/SortModule";
import { AdminModuleService } from "../services/AdminModuleService"
import { LessonContentController } from "./LessonContentController";
import { LessonController } from "./LessonController";
import { ModuleController } from "./ModuleController";

export class AdminModuleController {
    constructor() {
        this.adminModuleService = new AdminModuleService();        
        this.moduleController = new ModuleController();
        this.lessonController = new LessonController();
        this.lessonContentController = new LessonContentController();
    }

    private adminModuleService:AdminModuleService;    
    private moduleController:ModuleController;
    private lessonController:LessonController;
    private lessonContentController:LessonContentController;

    /**
     * Get the accordion tree for the content builder
     * @param id 
     * @returns 
     */
    public getAdminAccordionTree = async (id:string): Promise<AdminTreeModule> => {
        try {
            
            return await this.adminModuleService.GetAminAccordionTreeModal(id);
        } catch (error) {
            return ErrorHandler.generalError(error);
        }
    }

    /**
     * Get a list of lessons and chapters for a passed in module id formatted for the contentBuilder 
     * i.e when inside of a chapter
     * @param selectedModuleId 
     * @returns 
     */
    public GetModulesAndLessons = async (selectedModuleId:string):Promise<IAdminModule[]> => {
        try{
            const modules:IModule[] = await this.moduleController.GetModulesWithParentId(selectedModuleId);
                
            const lessons:ILesson[] = await this.lessonController.GetLessonsWithParentId(selectedModuleId);

            const combinedList = [...modules, ...lessons];            

            sortModule(combinedList, false);

            const adminModules:IAdminModule[] = combinedList.map((content) => (new AdminModule(content)));
            
            return adminModules;

        } catch (error) {
            console.error(error);
            const errMsg = "Failed to retrieve chapters and lessons";
        }

        return [];
    }

    /**
     * Get a list of chapter formatted for the contentBuilder
     * i.e: top most/parent module 
     * @param selectedModuleId 
     */
    public getChapters = async (selectedModuleId:string):Promise<IAdminModule[]> => {
        
        try {            
            const modules:IModule[] = await this.moduleController.GetModulesWithParentId(selectedModuleId);            
            
            sortModule(modules, false)

            const adminModules:IAdminModule[] = modules.map(content => (new AdminModule(content)));
        
            return adminModules;

        } catch (error) {
            console.error(error);
            const errMsg ="Failed to retrieve chapters";
        }

        return [];        
    }

    /**
     * Get a list of lesson content for a quiz or combo lesson in the contentBuilder
     * @param selectedModuleId 
     * @param isQuiz 
     * @returns 
     */
    public getLessonContent = async (selectedModuleId:string, isQuiz:boolean):Promise<IAdminModule[]> => {
        const failedFetchErrMsg = isQuiz ? "Failed to retrieve Quiz Items" : "Failed to retrieve Lesson Items";
        
        try{            
            
            const lessonContentList:ILessonContent[] = await this.lessonContentController.GetAllLessonContentByLessonId(selectedModuleId);

            sortModule(lessonContentList, false);

            const adminModules:IAdminModule[] = lessonContentList.map((content) => (new AdminModule(content)));

            return adminModules;

        } catch(error) {
            console.error(error);
            const errMsg =failedFetchErrMsg;
        }

        return [];
    }
 } 