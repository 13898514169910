import {UserLessonContentService} from "../services/UserLessonContentService";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import {AxiosError} from "axios";
import {LessonContent} from "../models/lesson/LessonContent";
import {UserLessonContent} from "../models/lesson/UserLessonContent";

export class UserLessonContentController {
    constructor(){
        this.userLessonContentService = new UserLessonContentService();
    }

    private userLessonContentService:UserLessonContentService;
    
    public async GetUserLessonContentByLessonId(id:string)
    {
        try
        {
            return await this.userLessonContentService.GetUserLessonContentByLessonId({id});
        }catch(ex)
        {
            return ErrorHandler.catchApiError((ex as AxiosError))  
        }
    }
    
    public async UpdateUserLessonContent(userLessonContent : UserLessonContent){
        try
        {
              return await this.userLessonContentService.Put({id:userLessonContent.id}, userLessonContent);  
        }catch(ex)
        {
            return ErrorHandler.catchApiError((ex as AxiosError))
        }
    }
    
    public async GetAndCreateAssignedUserLessonContentByLessonId(id:string){
        try
        {
            return await this.userLessonContentService.GetAndCreateAssignedUserLessonContentByLessonId({id});
        }catch(ex)
        {
            return ErrorHandler.catchApiError((ex as AxiosError))
        }
    }
    
    public async CreateNewUserContentByLessonContent( lessonContent: LessonContent, parentUserDocumentId:string, userId:string, completion:string, isCompleted:boolean){
        try{
            const userLessonContent = 
            {
                originalDocumentId: lessonContent.id,
                parentUserDocumentId: parentUserDocumentId,
                parentDocumentId:lessonContent.lessonId,
                order: lessonContent.order,
                completion: completion,
                isViewable: false,
                isDisabled: false,
                isEditable:false,
                isOptional: false,
                isComplete: isCompleted,
            };
            
            return await this.userLessonContentService.Post(userLessonContent);
            
        }catch(ex){
            return ErrorHandler.catchApiError((ex as AxiosError))
        }
    }
}