import moment from 'moment';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {toast} from 'react-hot-toast';
import {v4 as uuidV4} from 'uuid';
import {ConsultationController} from '../../../controllers/ConsultationController';
import {Consultation, IConsultation, Recurrence} from '../../../models/consultations/Consultation';
import {IConsultationSession} from '../../../models/consultations/ConsultationSession';
import {ConsultationStatus} from '../../../models/consultations/ConsultationStatus';
import {ConsultationType} from '../../../models/consultations/ConsultationType';
import {DaysOfWeek} from '../../../models/Enums/DaysOfWeek';
import {ICurrentUser, UserBio} from '../../../models/module/UserProfile';
import {RecurringConsultationRequest} from '../../../models/requests/RecurringConsultationRequest';
import {DeepCopy} from '../../../models/utility/DeepCopy';
import {StringHelper} from '../../../models/utility/StringHelper';
import TimeHelper from '../../../models/utility/TimeHelper';
import {UiFriendlyTimeZones, ZoomTimeZoneEnum} from '../../../models/Zoom/ZoomTimeZoneEnum';
import CustomModal from '../../Utilities/CustomModal';
import CbitTextArea from '../../Utilities/FormFields/CbitTextArea';
import ConsultationAttendeesDetails from '../ConsultationItems/ConsultationAttendeesDetails';
import './ConsultantPlanForm.css';
import DaysOfWeekSelect from './DaysOfWeekSelect';
import RecurringAlert from './RecurringAlert';

/**
 * acceptable props for the consultation plan form
 */
type ConsultantPlanFormProps = {
    currentUser: ICurrentUser
    startDate?: Date
    isEditing: boolean
    isLoading: boolean
    consultationSession: IConsultationSession | null
    onSubmit: (consultation: IConsultation) => void
    recurringConsultationSubmit: (recurringRequest: RecurringConsultationRequest) => void
    updateRecurringConsultations: (recurringRequest: RecurringConsultationRequest) => void
    updateConsultationOccurrence: (consultationOccurrence: IConsultation) => void
    cancelRecurring: (updatedConsultation: IConsultation) => void
    togglePlanForm: () => void
}

/**
 * State type for the consultant plan form
 */
type ConsultantPlanFormState = {
    title: string
    description: string
    type: ConsultationType
    maxAttendees: number
    startDate: string
    consultationDuration: number
    attendees: UserBio[]
    attendeesToRemove: string[]
    timeZone: string
    isModalOpen: boolean
    isRecurring: boolean
    listOfDays: DaysOfWeek[]
    isRecurringAlertOpen: boolean
}

/**
 * and object that hold the amount of time a consultant can add to their consultation
 */
const TimeInMinutes = {
    minutes30: 30,
    oneHour: 60,
    oneHourAndHalf: 90,
    twoHour: 120,
    twoHourAndHalf: 150,
    threeHour: 180,
    threeHourAndHalf: 210,
    fourHour: 240,
}

/**
 * Creates a default title for a consultation
 */
const createDefaultTitle = (currentUser: ICurrentUser) => {
    if (!currentUser) return "";
    const {firstName, lastName} = currentUser;
    return `${StringHelper.capitalizeWord(firstName)} ${StringHelper.capitalizeWord(lastName)}'s Consultation Session`;
}

const consultationController = new ConsultationController();

/**
 * Form for consultants to plan their consultations
 * Note: Depending on if a consultation is normal or recurring the way we submit changes
 * Updating a recurring consultation causes an alert like in google calendar and that popup actually submits the form
 * Note: May not need isLoading
 */
const ConsultantPlanForm = (props: ConsultantPlanFormProps) => {
    const {
        isEditing,
        consultationSession,
        isLoading,
        currentUser,
        startDate,
        onSubmit,
        recurringConsultationSubmit,
        updateRecurringConsultations,
        updateConsultationOccurrence,
        cancelRecurring,
        togglePlanForm,
    } = props;

    const currentDate = startDate ? startDate : new Date();

    const maxNotesCharLimit = 1500;

    /**The max amount of time consultations can go on for */
    const consultationLengthInputSelect = {
        "(30 mins)": TimeInMinutes.minutes30,
        "(1 hr)": TimeInMinutes.oneHour,
        "(1.5 hr)": TimeInMinutes.oneHourAndHalf,
        "(2 hrs)": TimeInMinutes.twoHour,
        "(2.5 hrs)": TimeInMinutes.twoHourAndHalf,
        "(3 hrs)": TimeInMinutes.threeHour,
        "(3.5 hrs)": TimeInMinutes.threeHourAndHalf,
        "(4 hrs)": TimeInMinutes.fourHour,
    }

    const getListOfDays = () => {
        if (!consultationSession) {
            return [];
        }

        const {consultation} = consultationSession;

        if (StringHelper.IsNullOrWhiteSpace(consultation.occurrenceId)) {
            return [];
        }

        const weeklyDays = Recurrence.convertWeeklyDaysToDaysOfWeeList(consultation.recurrence);

        return weeklyDays;
    }

    const getIsRecurring = () => {
        if (!consultationSession) {
            return false;
        }

        const {consultation} = consultationSession;

        return !StringHelper.IsNullOrWhiteSpace(consultation.occurrenceId);
    }

    const defaultState: ConsultantPlanFormState = {
        title: createDefaultTitle(currentUser),
        type: ConsultationType.OneOnOne,
        maxAttendees: 1,
        startDate: TimeHelper.getNextHalfHour(currentDate).toString(),
        consultationDuration: 30,
        attendees: consultationSession?.attendeesBioList ? consultationSession.attendeesBioList : [],
        attendeesToRemove: [],
        timeZone: Consultation.GetDefaultTimeZone(),
        description: '',
        isModalOpen: false,
        isRecurring: getIsRecurring(),
        listOfDays: getListOfDays(),
        isRecurringAlertOpen: false
    }

    const [state, setState] = useState(defaultState);

    useEffect(() => {
        function onComponentMount() {
            //If we are editing a consultation we need to set the consultation duration for the form
            if (isEditing && consultationSession) {

                const {consultation} = consultationSession;

                const {startDate, endDate} = consultation;

                if (startDate == null || endDate == null) return;

                const duration = moment.duration(TimeHelper.createMomentDate(endDate).diff(TimeHelper.createMomentDate(startDate)));

                const localStart = TimeHelper.createMomentDate(startDate as string).toString();

                const durationInMinutes = duration.asMinutes();

                setState(prevState => ({
                    ...prevState,
                    title: consultation.name,
                    type: consultation.type,
                    description: consultation.description,
                    maxAttendees: consultation.maxNumberOfAttendees,
                    startDate: localStart,
                    consultationDuration: durationInMinutes
                }))
            }
        }

        onComponentMount();
    }, []);

    /**
     * Creates a new consultation with default end time for 30 minutes
     */
    const buildConsultation = () => {

        let {startDate, title, type, consultationDuration, timeZone} = state;

        // Because the input from the form transforms our startDate to a string we need to transform it back to a date 
        const startDateConverted = new Date(startDate);

        // Adding the consultation duration to the start date to get an end time
        const endTime = (new Date(startDateConverted.getTime() + consultationDuration * 60000))

        const endTimeLocal = new Date(endTime).toLocaleString();

        const startDateStr = TimeHelper.createMomentDate(startDateConverted).local().toString();

        let newConsultation: Consultation = {
            id: "",
            name: title,
            description: state.description.trim(),
            ownerUserProfileId: currentUser.id,
            maxNumberOfAttendees: state.maxAttendees,
            attendeeList: [],
            type: type,
            status: ConsultationStatus.Active,
            startDate: moment(startDateStr).format(),
            endDate: moment(endTimeLocal).format(),
            deletedAt: null,
            link: "",
            timeZone: timeZone,
            guestLink: '',
            zoomMeetingLinkId: 0,
            userLinks: [],
            occurrenceId: '',
            recurrence: new Recurrence(),
            recordingLink: ''
        }
        return newConsultation;
    }

    const editConsultation = async (updatedConsultation: IConsultation) => {
        if (!consultationSession) {
            toast.error("Failed to edit consultation")
            return;
        }

        const {consultation} = consultationSession;
        /**
         * We need to refresh the consultation because the attendee list could have change while editing
         */
        const refreshedConsultation: IConsultation = await consultationController.GetConsultationById(consultation.id);

        if (updatedConsultation.maxNumberOfAttendees < refreshedConsultation.attendeeList.length) {
            toast.error("Consultation size is too small for the current amount of attendees");
            return;
        }

        if (updatedConsultation.maxNumberOfAttendees > refreshedConsultation.attendeeList.length && refreshedConsultation.status === ConsultationStatus.ActiveFull) {
            refreshedConsultation.status = ConsultationStatus.Active;
        }

        refreshedConsultation.name = updatedConsultation.name
        refreshedConsultation.description = updatedConsultation.description
        refreshedConsultation.maxNumberOfAttendees = updatedConsultation.maxNumberOfAttendees
        refreshedConsultation.type = updatedConsultation.type
        refreshedConsultation.startDate = moment(updatedConsultation.startDate).format();
        refreshedConsultation.endDate = moment(updatedConsultation.endDate).format();
        refreshedConsultation.timeZone = updatedConsultation.timeZone
        refreshedConsultation.attendeeList = refreshedConsultation.attendeeList.filter(attendee => !state.attendeesToRemove.includes(attendee._id));

        return refreshedConsultation;
    }

    /**
     * method that runs on form submission
     * @param e
     */
    const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (maxNotesCharLimit < state.description.length) {
            toast.error(`Description must be ${maxNotesCharLimit.toLocaleString()} characters or less`);
            return;
        }

        if (consultationSession) {
            if (isEditing && !state.isRecurring && !StringHelper.IsNullOrWhiteSpace(consultationSession.consultation.occurrenceId)) {
                await handleStopRecurrence();
                return;
            }
        }

        if (state.isRecurring) {
            await handleRecurringSubmit();
            return;
        }

        await normalConsultationSubmit();
    }

    const normalConsultationSubmit = async () => {
        let consultation = buildConsultation();

        if (isEditing) {
            const updatedConsultation = await editConsultation(consultation);

            if (!updatedConsultation) return;

            onSubmit(updatedConsultation);

            return;
        }

        onSubmit(consultation);
    }

    const handleRecurringSubmit = async () => {
        const isRecurring = state.isRecurring;

        let consultation = buildConsultation();

        const listOfDays = state.listOfDays.map(day => day);

        const recurringRequest: RecurringConsultationRequest = {
            listOfDays, recurringConsultation: consultation
        }

        // If we are canceling the recurring on a recurring consultation
        if (isEditing && !isRecurring && !StringHelper.IsNullOrWhiteSpace(consultation.occurrenceId)) {
            handleStopRecurrence();
            return;
        }

        if (isEditing && isRecurring) {
            toggleRecurringAlert();
            return;
        }

        recurringConsultationSubmit(recurringRequest);
    }

    const handleRecurringUpdate = async () => {
        const isRecurring = state.isRecurring;

        if (!isRecurring) {
            return;
        }

        const consultation = buildConsultation();

        try {
            const updatedConsultation = await editConsultation(consultation);

            if (!updatedConsultation) {
                return;
            }

            const listOfDays = state.listOfDays.map(day => day);

            const recurringRequest: RecurringConsultationRequest = {
                listOfDays, recurringConsultation: updatedConsultation
            }

            updateRecurringConsultations(recurringRequest);

        } catch (error) {
            console.error(error);
        }
    }

    // Update a single occurrence of a recurring consultation
    const handleOccurrenceUpdateSubmit = async () => {
        const isRecurring = state.isRecurring;

        if (!isRecurring) {
            return;
        }

        const consultation = buildConsultation();

        try {
            const updatedConsultation = await editConsultation(consultation);

            if (!updatedConsultation) {
                return;
            }

            updateConsultationOccurrence(updatedConsultation);
        } catch (error) {

        }
    }

    const handleStopRecurrence = async () => {
        const isRecurring = state.isRecurring;
        if (!consultationSession) {
            return;
        }

        const consultation = consultationSession.consultation;

        //If we canceling the recurring on a recurring consultation
        if (!isRecurring && isEditing && !StringHelper.IsNullOrWhiteSpace(consultation.occurrenceId)) {
            const consultation = buildConsultation();
            const updatedConsultation = await editConsultation(consultation);

            if (!updatedConsultation) {
                toast.error("Failed to cancel recurring on this consultation");
                return;
            }

            await cancelRecurring(updatedConsultation);
        }
    }

    /**
     * Limits the max number of attendees based on the consultation type
     * TODO add error handling
     */
    const limitMaxAttendees = () => {
        let attendeeLimit = 100;

        const consultationType = state.type;

        if (consultationType === ConsultationType.OneOnOne) {
            attendeeLimit = 1;
        } else if (consultationType === ConsultationType.SmallGroup) {
            attendeeLimit = 10;
        }

        return attendeeLimit;
    }

    /**
     * checks the state to see if the consultation type is one on one
     * and disables the maxAttendeesInput field
     */
    const isMaxAttendeesInputDisabled = () => {
        const consultationType = state.type;

        return consultationType === ConsultationType.OneOnOne;
    }

    /**
     * Handle the dropdown select for time.
     * TODO move to other select
     */
    const onTimeSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const inputValue = e.currentTarget.value;
        const temp = TimeHelper.createMomentDate(inputValue).toISOString();
        setState(prevState => ({
            ...prevState, startDate: TimeHelper.createMomentDate(inputValue).toISOString()
        }));
    }

    /**
     * Updates state based on the change events in the form
     * @param e
     */
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const inputName = e.currentTarget.name;

        let inputValue: string | number = e.currentTarget.value;

        //Because change event converts enum into strings we need to convert them back to numbers
        if (inputName === "type") {
            inputValue = Number.parseInt(inputValue)

            //We need to let Typescript know that input value is an enum value in consultation type
            const consultationType = inputValue as ConsultationType;

            if (consultationType === ConsultationType.OneOnOne) {
                const maxAttendees = 1;

                setState(prevState => ({
                    ...prevState, type: consultationType, maxAttendees
                }));

                return;
            }
        }
        setState(prevState => ({
            ...prevState, [inputName]: inputValue
        }));
    }


    /**
     * Handles what should happen to the start date input
     * @param value
     * @returns
     */
    const handleStartDateInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        const value = e.currentTarget.value;

        const startDate = TimeHelper.getNextHalfHour(value).toString();

        setState(prevState => ({...prevState, startDate}))
    }

    const handleCheckboxInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.currentTarget.checked;
        setState(prevState => ({
            ...prevState, isRecurring: checked
        }));
    }

    const updateListOfDaysState = (listOfDays: DaysOfWeek[]) => {
        setState(prevState => ({
            ...prevState, listOfDays
        }));
    }

    /**
     * Removes the attendee from the current consultation
     * Note: Only updates the Consultation in state and not in the DB
     */
    const handleAttendeeRemoval = (id: string) => {
        if (!isEditing && !consultationSession) return;

        const userBiosCopy: UserBio[] = DeepCopy.copy(state.attendees);

        const filteredBios = userBiosCopy.filter(bio => bio.id !== id);

        const attendeesToRemove = userBiosCopy.filter(bio => bio.id === id).map(bio => bio.id)

        setState(prevState => ({
            ...prevState, attendees: filteredBios, attendeesToRemove
        }));
    }

    const toggleModal = () => {
        const isModalOpen = !state.isModalOpen;
        setState(prevState => ({
            ...prevState, isModalOpen
        }));
    }

    const toggleRecurringAlert = () => {

        const isRecurringAlertOpen = !state.isRecurringAlertOpen;
        setState((prevState) => ({
            ...prevState, isRecurringAlertOpen
        }))
    }

    return (
        <>
            <form className='consultation-plan-form form' onSubmit={submitForm}>
                {isEditing ? (<h2 className="form-title">EDIT CONSULTATION SESSION</h2>) : (
                    <h2 className="form-title">CREATE A NEW CONSULTATION SESSION</h2>)}
                <div className="cbit-row">
                    <div className="cbit-column">
                        <label className='cbit-label' htmlFor="consultationTitleInput">SESSION TITLE</label> {" "}
                        <span className="required-field">*</span>
                    </div>
                </div>
                <div className="cbit-row">
                    <div className="cbit-column">
                        <input
                            placeholder='Enter a title for your consultation session'
                            id='consultationTitleInput'
                            className='form-input'
                            name='title'
                            type="text"
                            value={state.title}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
                <div className="cbit-row">
                    <div className="cbit-column">
                        <label className='cbit-label' htmlFor="description">Notes</label> {" "}
                    </div>
                </div>
                <div className="cbit-row consultation-plan-textarea-container">
                    <div className="cbit-column">
                        <CbitTextArea
                            name="description"
                            id="description"
                            cols={30}
                            rows={10}
                            value={state.description}
                            onChange={handleInputChange}
                            additionalClasses={["consultation-plan-textarea"]}
                            required={false}
                            maxCharacterLimit={1500}
                        />
                    </div>
                </div>


                <div className="cbit-row session-info">
                    <div className="cbit-column">
                        <label htmlFor="sessionDate" className="cbit-label">SESSION DATE</label>{" "}
                        <span className="required-field">*</span>
                    </div>
                    <div className="cbit-column">
                        <label htmlFor="sessionTime" className="cbit-label">SESSION TIME</label> {" "}
                        <span className="required-field">*</span>
                    </div>
                    <div className="cbit-column">

                    </div>
                    <div className="cbit-column"></div>
                </div>

                <div className="cbit-row session-info">
                    <div className="cbit-column">
                        <input
                            id='consultationStartInput'
                            className='form-input'
                            name='startDate'
                            type="date"
                            onChange={handleStartDateInputChange}
                            min={TimeHelper.FormatDateForInput(new Date(), false)}
                            value={moment(new Date(state.startDate), TimeHelper.momentFormats).format("YYYY-MM-DD")}
                        />
                    </div>
                    <div className="cbit-column consultation-date-column">
                        <select
                            value={moment(TimeHelper.createMomentDate(state.startDate)).toISOString()}
                            name="consultationStartTime"
                            className='form-input consult-start-time-select'
                            onChange={onTimeSelectChange}
                        >
                            {TimeHelper.CreateTimeList(state.startDate).map(time => (
                                <option key={uuidV4()} value={time.date}>{time.time}</option>))}
                        </select>
                    </div>
                    <div className="cbit-column session-time-to-column">
                        <div className='session-time-to-text'>To</div>
                    </div>
                    <div className="cbit-column consultation-date-column">
                        <select
                            id="consultationEndInput"
                            className='form-input'
                            name="consultationDuration"
                            value={state.consultationDuration}
                            onChange={handleInputChange}
                        >
                            {Object.entries(consultationLengthInputSelect).map(([key, val]) => (
                                <option key={uuidV4()} value={val}>
                                    {`${TimeHelper.GetTimeFromMinutes(val, state.startDate)}`}
                                </option>))}
                        </select>
                    </div>
                    <div className="cbit-column consultation-timezone-container">
                        <select
                            name="timeZone"
                            className='form-input'
                            onChange={handleInputChange}
                            value={state.timeZone}
                        >
                            <option
                                value=''
                                disabled
                            >
                                Select a timezone
                            </option>
                            {Object.entries(ZoomTimeZoneEnum).map(([key, val]) => (
                                <option key={uuidV4()} value={val}>{`${UiFriendlyTimeZones[val]}`}</option>))}
                        </select>
                    </div>
                </div>

                <div className="mobile-session-info">
                    <div className="mobile-session-info-container">
                        <div className="mobile-session-info-label">
                            <label htmlFor="sessionDate" className="cbit-label">SESSION DATE</label>{" "}
                            <span className="required-field">*</span>
                        </div>
                        <input
                            id='consultationStartInput'
                            className='form-input'
                            name='startDate'
                            type="date"
                            onChange={handleStartDateInputChange}
                            min={TimeHelper.FormatDateForInput(new Date(), false)}
                            value={moment(new Date(state.startDate), TimeHelper.momentFormats).format("YYYY-MM-DD")}
                        />
                    </div>
                    <div className="mobile-session-info-container">
                        <div className="mobile-session-info-label">
                            <label htmlFor="sessionTime" className="cbit-label">SESSION TIME</label> {" "}
                            <span className="required-field">*</span>
                        </div>
                        <select
                            value={moment(TimeHelper.createMomentDate(state.startDate)).toISOString()}
                            name="consultationStartTime"
                            className='form-input consult-start-time-select'
                            onChange={onTimeSelectChange}
                        >
                            {TimeHelper.CreateTimeList(state.startDate).map(time => (
                                <option key={uuidV4()} value={time.date}>{time.time}</option>))}
                        </select>
                    </div>
                    <div className="mobile-session-info-container">
                        <div className='session-time-to-text'>To</div>
                        <select
                            id="consultationEndInput"
                            className='form-input'
                            name="consultationDuration"
                            value={state.consultationDuration}
                            onChange={handleInputChange}
                        >
                            {Object.entries(consultationLengthInputSelect).map(([key, val]) => (
                                <option key={uuidV4()} value={val}>
                                    {`${TimeHelper.GetTimeFromMinutes(val, state.startDate)}`}
                                </option>))}
                        </select>
                    </div>
                    <div className="mobile-session-info-container">
                        <select
                            name="timeZone"
                            className='form-input'
                            onChange={handleInputChange}
                            value={state.timeZone}
                        >
                            <option
                                value=''
                                disabled
                            >
                                Select a timezone
                            </option>
                            {Object.entries(ZoomTimeZoneEnum).map(([key, val]) => (
                                <option key={uuidV4()} value={val}>{`${UiFriendlyTimeZones[val]}`}</option>))}
                        </select>
                    </div>
                </div>

                <div className="cbit-row">
                    <div className="cbit-column recurring-plan-container">
                        <div className="recurring-repeat-container">
                            <label
                                className='cbit-label'
                                htmlFor="recurringCheck"
                            >
                                Does Repeat
                            </label>
                            <input
                                id="recurringCheck"
                                name="isRecurring"
                                className="does-repeat-checkbox"
                                type="checkbox"
                                onChange={handleCheckboxInput}
                                checked={state.isRecurring}
                            />
                            <div className='recurring-note upload-text'>Up to 50 occurrences per
                                meeting. <br/> Recurring meetings occur up to a max of 3 months.
                            </div>
                        </div>
                        {state.isRecurring && (<div>
                                <label className="cbit-label">Recurring Days</label>
                                <DaysOfWeekSelect
                                    listOfDays={state.listOfDays}
                                    updateDaysOfWeekList={updateListOfDaysState}
                                />
                            </div>)}

                    </div>
                </div>
                <div className="cbit-row consultation-type">
                    <div className="cbit-column">
                        <label className='cbit-label' htmlFor="consultationTypeInput">Consultation Type</label> {" "}
                        <span className="required-field">*</span>
                        <select
                            id="consultationTypeInput"
                            className='form-input'
                            name="type"
                            value={state.type}
                            onChange={handleInputChange}
                        >
                            <option value={ConsultationType.OneOnOne}>One On One</option>
                            <option value={ConsultationType.SmallGroup}>Small Group</option>
                            <option value={ConsultationType.Seminar}>Seminar</option>
                        </select>
                    </div>
                    <div className="">
                        <label className='cbit-label' htmlFor="consultationMaxAttendeesInput">Attendees</label>{" "}
                        <span className="required-field">*</span>
                        <input
                            id='consultationMaxAttendeesInput'
                            className='form-input max-attendees-input'
                            name='maxAttendees'
                            type="number"
                            min={1}
                            max={limitMaxAttendees()}
                            onChange={handleInputChange}
                            value={state.maxAttendees}
                            disabled={isMaxAttendeesInputDisabled()}
                        />
                    </div>
                </div>

                <div className="mobile-consultation-type">
                    <div className="mobile-session-info-container">
                        <div className="mobile-session-info-label">
                            <label htmlFor="consultationTypeInput" className="cbit-label">Consultation Type</label>{" "}
                            <span className="required-field">*</span>
                        </div>
                        <select
                            id="consultationTypeInput"
                            className='form-input'
                            name="type"
                            value={state.type}
                            onChange={handleInputChange}
                        >
                            <option value={ConsultationType.OneOnOne}>One On One</option>
                            <option value={ConsultationType.SmallGroup}>Small Group</option>
                            <option value={ConsultationType.Seminar}>Seminar</option>
                        </select>
                    </div>
                    <div className="mobile-session-info-container">
                        <div className="mobile-session-info-label">
                            <label htmlFor="consultationMaxAttendeesInput" className="cbit-label">Attendees</label> {" "}
                            <span className="required-field">*</span>
                        </div>
                        <input
                            id='consultationMaxAttendeesInput'
                            className='form-input max-attendees-input'
                            name='maxAttendees'
                            type="number"
                            min={1}
                            max={limitMaxAttendees()}
                            onChange={handleInputChange}
                            value={state.maxAttendees}
                            disabled={isMaxAttendeesInputDisabled()}
                        />
                    </div>
                </div>

                <div className="cbit-row">
                    {state.attendees.length > 0 && (<div className="cbit-column">
                            <label className='cbit-label'>Current Attendees</label>
                            <ConsultationAttendeesDetails
                                attendees={state.attendees}
                                removeAttendee={handleAttendeeRemoval}
                            />
                        </div>)}
                </div>
                <div className='plan-button-group'>
                    <button onClick={togglePlanForm} type="button" className="btn-cbit-minor">Cancel</button>
                    <button
                        disabled={isLoading}
                        type='submit'
                        className='btn-cbit-primary'
                    >
                        {isEditing ? ("Update") : ("Create")}
                    </button>
                </div>
            </form>
            <CustomModal
                isOpen={state.isModalOpen}
                toggle={toggleModal}
            >
            </CustomModal>
            <CustomModal
                isOpen={state.isRecurringAlertOpen}
                toggle={toggleRecurringAlert}
            >
                <RecurringAlert
                    headerText='Update Recurring Consultations'
                    toggleAlert={toggleRecurringAlert}
                    onOccurrenceUpdateSubmit={handleOccurrenceUpdateSubmit}
                    onRecurringUpdateSubmit={handleRecurringUpdate}
                />
            </CustomModal>
        </>
    )
}

export default ConsultantPlanForm;