import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { StripeController } from "../../../controllers/StripeController";
import { UserController } from "../../../controllers/UserController";
import { UserLesson } from "../../../models/lesson/UserLesson";
import { UserBio, UserProgressAndReferral, UserProgressEnum } from "../../../models/module/UserProfile";
import { IReferral, ReferralStatusEnum, ReferralStatusUi } from "../../../models/Referral/Referral";
import { RolesHelper } from "../../../models/utility/Roles";
import TimeHelper from "../../../models/utility/TimeHelper";
import IconEdit from '../../../resources/icons/icon-edit.png';
import IconDelete from '../../../resources/icons/icon-trash.png';
import CbitDataGrid from "../../Utilities/CbitTable/CbitDataGrid";
import CustomAlert from "../../Utilities/CustomAlert";
import "./UserManagementTable.css";

const userController = new UserController();
const stripeController = new StripeController();

type UserManagementTableProps = {
    users:UserProgressAndReferral[];
    isLoading:boolean;
    updateIsLoading:(isLoading:boolean) => void;
    onReferralClick:(referral:IReferral) => void;
    onUserProgressClick:(currentUserLesson:UserLesson, userBio:UserBio) => void;
    onDelete:(user:UserProgressAndReferral) => void;
    getUsers:() => void;

}

type testUserData = {
    id:string;
    firstName:string;
    lastName:string
}

/**
 * Get user full name to display in the table
 * @param params 
 * @returns 
 */
function fullNameGetter(params:GridValueGetterParams){    
    return `${params.row.firstName} ${params.row.lastName}`;
}

/**
 * Get a users highest role to display in the table
 */
function roleGetter(params:GridValueGetterParams){
    const roles = params.row.roles;

    return RolesHelper.GetHighestRole(roles);
}

/**
 * Get a users sign up date and format it into a readable text
 * Note: the time will display in the admin that is viewing it timezone
 * @param params 
 */
function signUpDateGetter(params:GridValueGetterParams){
    const date = params.row.createdAt;

    if(date) {
        return TimeHelper.formatFullDateAndTime(date, "YYYY-MM-DD h:mm A");
    } else {
        return "-";
    }    
}

/**
 * Get user progress and either displays the progress 
 * or a button to open the progress dialogue
 * @param params 
 * @returns 
 */
function renderUserProgress(row:GridValueGetterParams, 
    handleUserProgressClick:(currentUserLesson:UserLesson, userBio:UserBio) => void) {    
    const progress = UserProgressAndReferral.getUserProgress(row as any);

    const currentUserLesson = (row as unknown as UserProgressAndReferral).currentUserLesson;

    if(progress == UserProgressEnum.inProgress) {
       return (
                <button 
                    className="btn-cbit-link btn-user-table"
                    onClick={                        
                        () => handleUserProgressClick(currentUserLesson, row as unknown as UserBio)
                    } 
                >
                    View Progress
                </button>
        )
    }
    return (
        <span>{progress}</span>
    )
}

/**
 * Render tools to update referral status in the table
 * @param params 
 */
function renderReferralTools(row:GridValueGetterParams, handleReferralClick:(referral:IReferral) => void) {
    //This is only possible because row is actually userProgressAndReferral obj 
    const user = (row as unknown as UserProgressAndReferral);

    const referral = user.referral;

    const statusText = getReferralStatus(referral);    

    if(statusText === ReferralStatusUi[ReferralStatusEnum.Unknown]) {
        return statusText;
    }

    return (
        <button 
            className="btn-cbit-link btn-user-table"
            onClick={() => handleReferralClick(referral)}            
        >
            {statusText}
        </button>
    )
}


/**
 * Getting the referral status text from a referral
 */
const getReferralStatus = (referral:IReferral|null) => {
    if(!referral) {
        return ReferralStatusUi[ReferralStatusEnum.Unknown];
    }

    const status = referral.status;

    return ReferralStatusUi[status];
}

const noUsersFoundTxt = "No Users Found";

/**
 * Component for admins to view and manage users
 */
const UserManagementTable = (props:UserManagementTableProps) => {
    const {
        users,
        isLoading,
        updateIsLoading,
        onUserProgressClick,
        onReferralClick,
        onDelete,
        getUsers,
    } = props;        

    // TODO remove this when testing is over
    const [selectedTestUser, setTestUser] = useState<testUserData | null>(null);
    const [isEndTestAlertOpen, setEndTestAlert] = useState(false);

    const usersRows = users.map(u => ({referralStatus:ReferralStatusUi[u.referral?.status ?? ReferralStatusEnum.Unknown],  ...u}));

    /**
     * Returns how progress should look like when export to csv file
     * @param gridObj 
     * @returns 
     */
    const progressValueFormat = (gridObj:any) => {
        const userId = gridObj.id;
        const user = users.find(u => u.id === userId);
        if(!user) {
            return "";
        }

        const progress = UserProgressAndReferral.getUserProgress(user as any);

        if(progress === UserProgressEnum.inProgress) {
            
            return user?.currentLesson.name;
        }

        return progress;
    }

    const userColumns:GridColDef[] = [
        {
            field: 'role',
            headerName: 'Role',
            headerClassName: 'user-table-header',
            flex:1,
            valueGetter:roleGetter,
        },
        {
            field: 'fullName', 
            headerName: 'Full Name',
            headerClassName: 'user-table-header', 
            flex:1,
            valueGetter:fullNameGetter,
        },
        {
            field: 'name',
            headerName: 'Username',
            headerClassName: 'user-table-header',
            flex:1,
        },
        {
            field: 'email',
            headerName: 'Email',
            headerClassName: 'user-table-header',
            flex:2,
        },
        {
            field: 'numFreeConsultations',
            headerName: 'Consultations Completed',
            align: "center",
            headerClassName: 'user-table-header',
            flex:2,
        },
        {
            field: 'referralStatus',
            headerName: 'Referral',             
            headerClassName: 'user-table-header',
            flex:1,            
        },
        {
            field: 'referral',
            headerName: 'Referral',                          
            filterable:false,
            headerClassName: 'user-table-header',
            flex:1,
            valueFormatter: ({value}) => (getReferralStatus(value)),
            renderCell: (cell) => renderReferralTools(cell.row, onReferralClick),
        },
        {
            field: 'createdAt',
            headerName: 'Sign Up Date',
            headerClassName: 'user-table-header',
            flex:1,
            valueGetter:signUpDateGetter
        },
        {
            field: 'progress',
            headerName: 'Progress',
            headerClassName: 'user-table-header',
            flex:1,
            sortable: false,
            filterable:false,
            valueFormatter:(gridObj) => (progressValueFormat(gridObj)),
            renderCell:(cell) => renderUserProgress(cell.row, onUserProgressClick),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            headerClassName: 'user-table-header',            
            sortable:false,
            flex:0,
            disableExport:true,
            filterable:false,
            renderCell: (cell:any) => {                
                const row = cell.row;
                return (
                    <div className='user-management-actions'>
                        {/* {
                            TODO: uncomment this when test phase if finished
                            row.roles.includes(Roles.TestUser) && (
                                <button
                                    onClick={() => onEndTestIconClick({
                                        id:row.id, 
                                        firstName:row.firstName, 
                                        lastName:row.lastName
                                    })} 
                                    className="expire-test-user btn-cbit-link"
                                >
                                    <i className="late-icon bi bi-alarm"></i>
                                </button>
                            )
                        } */}
                        <Link to={`/users/${row.name}/edit`} className='table-action-edit btn-cbit-link'>
                            <img src={IconEdit} alt="Edit" />
                        </Link>
                        <button 
                            onClick={() => onDelete(row)}
                            className='table-action-delete btn-cbit-link'
                        >
                            <img src={IconDelete} alt="Delete" />
                        </button>
                    </div>
                )
            }
        }
    ];

    /**
     * Opens and closes the the end test phase alert
     */
    const toggleEndTestAlert = () => {
        setEndTestAlert(!isEndTestAlertOpen);
    }

    /**
     * Set the test user and toggle the end test alert
     * @param testUser 
     */
    const onEndTestIconClick = (testUser:testUserData) => {
        setTestUser(testUser);
        toggleEndTestAlert();
    }

    /**
     * Makes a call to the api to end the user test phase 
     * and create a subscription schedule
     */
    const endTestPhaseAndCreateSubscription = async () => {
        if(!selectedTestUser) {
            toast.error("No user was selected to end test phase");
            return;
        }

        const userId = selectedTestUser.id;

        const loadId = "endTestLoad";

        try {
            const endTestPromise = userController.EndUserTestingPhase(userId);
            const createSubscriptionPromise = stripeController.CreateSubscriptionForTestUser(userId);

            updateIsLoading(true);

            toast.loading("Ending testing phase for user", {id: loadId});
                        
            toggleEndTestAlert();

            //running both task in parallel because the order they run in do not matter
            await Promise.all([endTestPromise, createSubscriptionPromise]);
            
            await getUsers();     
            toast.success(`Successfully ended ${selectedTestUser.firstName, selectedTestUser.lastName} testing phase`);                               
        } catch (error) {
            console.error(error);
            toast.error("Failed to end the test phase and create a subscription");
        }
        
        updateIsLoading(false);
        toast.dismiss(loadId);
    }
    
    return (
        <div className="user-mana-table">
            <CbitDataGrid 
                isLoading={isLoading}
                columns={userColumns}
                rowData={usersRows}
                columnVisibilityModel={{referralStatus:false}}
                noRowsText={noUsersFoundTxt}
            />                        
            <CustomAlert 
                header={`ARE YOU SURE YOU WANT TO END ${selectedTestUser?.firstName} ${selectedTestUser?.lastName}'s TESTING PHASE?`} 
                text={`Once you click "Submit", the user testing phase will end immediately and will be required to buy a subscription to continue using the platform.`} 
                primaryBtnText={'Submit'} 
                secondaryBtnText={'Cancel'} 
                isOpen={isEndTestAlertOpen} 
                primaryBtnSubmit={endTestPhaseAndCreateSubscription} 
                secondaryBtnSubmit={toggleEndTestAlert} 
                toggleAlert={toggleEndTestAlert}
            />            
        </div>
    )
}

export default UserManagementTable;