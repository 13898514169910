import React, {useEffect, useMemo, useState} from "react"
import {Modal, ModalBody} from "reactstrap";
import UseModal from "../../../../hooks/UseModal";
import {GrFormClose} from "react-icons/gr";
import {ISeminar} from "../../../../models/consultations/Seminar";
import moment from "moment/moment";
import {ConsultationController} from "../../../../controllers/ConsultationController";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {CurrentUserState} from "../../../../store/CurrentUser";
import toast from "react-hot-toast";

type UpcomingWebinarsModalProps = {
    seminar: ISeminar,
    updateWebinars: any,
}

const UpcomingWebinarsModal = ({seminar, updateWebinars}: UpcomingWebinarsModalProps) => {
    const [modalState, setModalState, toggleModal] = UseModal(false);
    const [isModalOpen, setIsModalOpen] = useState(modalState);

    const cController = new ConsultationController();

    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state) => state.currentUser);

    /**
     * Handles opening the modal
     * @returns void
     */
    const handleModalOpen = () => {
        setIsModalOpen(true)
        setModalState(true)
    }

    /**
     * Handles closing the modal
     * @returns void
     */
    const handleModalClose = () => {
        setIsModalOpen(false)
        setModalState(false)
    }

    /**
     * Handles booking a consultation
     * @returns Promise<ISeminar>
     */
    const bookConsultation = async () => {
       try {
           let data = await cController.BookConsultation(seminar.consultation.id, UserStore!.userProfile!.id)
           updateWebinars(data);

           toast.success('Successfully booked consultation');
           setIsModalOpen(false);
           setModalState(false);

           return data;
       }
         catch (e) {           
           toast.error('Failed to book consultation')
           console.error(e);
         }
    }

    /**
     * Handles cancelling a consultation
     * @returns Promise<ISeminar>
     */
    const cancelConsultation = async () => {
        try {
            let data = await cController.WithdrawFromConsultation(seminar.consultation.id, UserStore!.userProfile!.id)
            updateWebinars(data);

            toast.success('Successfully cancelled consultation');
            setIsModalOpen(false);
            setModalState(false);

            return data;
        }
        catch (e) {
            toast.error('Failed to cancel consultation');
            console.error(e);
        }
    }

    /**
     * Handles checking if the user is signed up for a consultation
     * @returns boolean
     */
    const isUserSignedUp = ():boolean => {
        let userId = UserStore!.userProfile!.id;
        let signedUp = false;

        seminar.consultation.attendeeList.forEach((attendee) => {
            if (attendee._id === userId) {
                signedUp = true;
            }
        })

        return signedUp;
    }

    const isConsultationOwner = () => {
        if(!UserStore) {
            return false;
        }

        return seminar.consultant.id === UserStore.userProfile.id;
    }


    return (
        <>
            <button className='upcoming-webinar-item-btn' onClick={handleModalOpen}>
                Learn More
            </button>
            <Modal isOpen={isModalOpen} size={'lg'} centered={true} className='upcoming-webinars-modal' key={seminar.consultation.id}>
                <ModalBody className='upcoming-webinars-modal-container'>
                    <div className="upcoming-webinars-modal-title-container">
                        <h4 className='upcoming-webinars-modal-title'>
                            Webinar Information
                        </h4>
                        <div className='upcoming-webinars-modal-btn-close' onClick={handleModalClose}>
                            <GrFormClose />
                        </div>
                    </div>
                    <div className="upcoming-webinars-modal-body-container">
                        <h2 className='upcoming-webinars-modal-body-title'>
                            {seminar.consultation.name}
                        </h2>
                        <div className="upcoming-webinars-modal-body-info-container">
                            <div className="upcoming-webinars-modal-body-info-item">
                                <h5 className='upcoming-webinars-modal-body-title'>
                                    Date
                                </h5>
                                <p className='upcoming-webinars-modal-body-text'>
                                    {moment(seminar.consultation.startDate).format("dddd, MMM Do YY")}
                                </p>
                            </div>
                            <div className="upcoming-webinars-modal-body-info-item">
                                <h5 className='upcoming-webinars-modal-body-title'>
                                    Webinar Price
                                </h5>
                                <p className='upcoming-webinars-modal-body-text'>
                                    Free
                                </p>
                            </div>
                            <div className="upcoming-webinars-modal-body-info-item">
                                <h5 className='upcoming-webinars-modal-body-title'>
                                    Time
                                </h5>
                                <p className='upcoming-webinars-modal-body-text'>
                                    {moment(seminar.consultation.startDate).format('LT')} - {moment(seminar.consultation.endDate).format('LT')} CT
                                </p>
                            </div>
                            <div className="upcoming-webinars-modal-body-info-item">
                                <h5 className='upcoming-webinars-modal-body-title'>
                                    Space Remaining
                                </h5>
                                <p className='upcoming-webinars-modal-body-text'>
                                    {seminar.consultation.maxNumberOfAttendees - seminar.consultation.attendeeList.length}
                                </p>
                            </div>
                        </div>
                        <div className="upcoming-webinars-modal-instructor-container">
                            <h5 className='upcoming-webinars-modal-body-title'>
                                Instructor
                            </h5>
                            <p className='upcoming-webinars-modal-body-text instructor'>
                                {seminar.consultant.firstName + ' ' + seminar.consultant.lastName}
                            </p>
                        </div>
                        <div className="upcoming-webinars-modal-description-container">
                            <h5 className='upcoming-webinars-modal-body-title'>
                                About This Webinar
                            </h5>
                            <p className='upcoming-webinars-modal-body-text'>
                                {seminar.consultation.description}
                            </p>
                        </div>
                    </div>
                    <div className='upcoming-webinars-modal-btn-container'>
                        {
                            //Users should not be able to book their own consultation session
                            isConsultationOwner() ? 
                            (
                                <></>
                            ) 
                            : 
                            (
                                <>
                                {!isUserSignedUp()? (
                                    <button className='upcoming-webinars-modal-btn' onClick={bookConsultation}>
                                        Sign Up
                                    </button>
                                ) : (
                                    <button className='upcoming-webinars-modal-btn cancel' onClick={cancelConsultation}>
                                        Cancel Registration
                                    </button>
                                )}
                                </>
                            )
                        }
                    </div>
                </ModalBody>
            </Modal>
        </>

    )
}

export default UpcomingWebinarsModal