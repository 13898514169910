import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import toast from "react-hot-toast";
import { ContentCategoryController } from "../../../../controllers/ContentCategoryController";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { ContentCategory, IContentCategory } from "../../../../models/AdditionalVideoContent/ContentCategory";
import { DeepCopy } from "../../../../models/utility/DeepCopy";
import editIconSrc from "../../../../resources/icons/icon-edit.png";
import trashIconSrc from "../../../../resources/icons/icon-trash.png";
import { ReactComponent as DragGrip } from '../../../../resources/images/icon-drag-drop.svg';
import NavSidebar from "../../../SocialConnection/components/layout/NavSidebar/NavSidebar";
import BasicInlineForm from "../../AdminForms/InlineForms/BasicInlineForm";
import {
    StandardContentButton
} from "../../ContentBuilder/ContentBuilderMenu/ContentBuilderMenuButtons/ContentBuilderMenuButton";

type AdminTherapistSupportLayoutProps = {
    isAdmin:boolean;
    contentCategories:ContentCategory[]
    selectedContentCategory:ContentCategory | null | undefined
    selectContentCategory:(id:string) => void
    addNewContentCategory:(name:string) => void
    editContentCategory:(contentCategory:ContentCategory) => void
    toggleDeleteAlert:() => void
    setCategoryForDelete:(contentCategory:ContentCategory) => void
    updateContentCategoryState:(contentCategories:ContentCategory[]) => void
    children:React.ReactNode
}

type AdminTherapistSupportPanelState = {
    newCategoryName:string
    isShowingInlineForm:boolean
    categoryEditingId:string
}

const contentCategoryController = new ContentCategoryController();

const AdminTherapistSupportLayout = (props: AdminTherapistSupportLayoutProps) => {
    // Navigation Panel
    const [isNavOpen, setIsNavOpen] = useState(true);
    const {height, width} = useWindowDimensions();

    const toggle = () => {
        setIsNavOpen(!isNavOpen);
    }

    useEffect(() => {
        // If the width of the device is greater than 1023px, open the navigation panel
        if (width > 1023) {
            setIsNavOpen(true);
        }

        // If the width of the device is less than 1023px, close the navigation panel
        if (width <= 1023) {
            setIsNavOpen(false);
        }

    }, [width]);

    const {
        isAdmin,
        contentCategories,
        selectedContentCategory,
        selectContentCategory,
        addNewContentCategory,
        editContentCategory,
        setCategoryForDelete,
        toggleDeleteAlert,
        children
    } = props;

    const defaultState:AdminTherapistSupportPanelState = {
        newCategoryName: '',
        isShowingInlineForm:false,
        categoryEditingId:""
    }

    const [isShowingEditInlineForm, setIsShowingEditInlineForm] = useState(false);

    const [state, setState] = useState(defaultState);
    const [showInline, setShowInline] = useState(false);
    /**
     * Get styles for the current selected category
     * @param id
     * @returns
     */
    const getSelectedStyles = (id:string) => {
        if(!selectedContentCategory)
            return "";

        return selectedContentCategory.id === id ? "active-nav-item" : "";
    }

    /**
     * Toggles wether or not to show the inline form
     */
    const toggleInlineForm = () => {        

        // setShowInline(!showInline)
        let isShowingInlineForm = !state.isShowingInlineForm;        

        setState(prevState => ({
            ...prevState,
            isShowingInlineForm
        }));
    }

    const handleEditingCategory = (categoryName:string) => {
        const contentCategory = props.contentCategories.find(category => category.id === state.categoryEditingId);

        if(!contentCategory)
        {
            toast.error("Failed To Update Category");
            return;
        }

        const contentCategoryCopy:ContentCategory = DeepCopy.copy(contentCategory);

        contentCategoryCopy.name = categoryName;

        editContentCategory(contentCategoryCopy);
    }

    const toggleEditInlineForm = () => setIsShowingEditInlineForm(!isShowingEditInlineForm);

    const handleToggleEditForm = (e:React.MouseEvent<HTMLButtonElement>, categoryId:string) => {
        e.stopPropagation();
        setState(prevState => ({...prevState, categoryEditingId: categoryId}))
        toggleEditInlineForm();
    }

    const handleDeleteClick = (e:React.MouseEvent<HTMLButtonElement>, contentCategory:ContentCategory) => {
        e.stopPropagation();
        setCategoryForDelete(contentCategory);
        toggleDeleteAlert();
    }

    /**
     * When a content category finish dragging update the order of that category
     * and update the database
     * @param results
     * @returns
     */
    const afterDragUpdateCategoryDatabase = (results:any) => {
        if (!results.destination) return;

        const contentCategories:IContentCategory[] = DeepCopy.copy(props.contentCategories);

        const [reorderedItem] = contentCategories.splice(results.source.index, 1);

        contentCategories.splice(results.destination.index, 0, reorderedItem);

        const updateTasks = contentCategories.map((content, index) => {
            try {
                content.order = index;
                contentCategoryController.UpdateContentCategory(content);

            } catch (error) {
                console.error(error);
                toast.error("Failed To Reorder Category")
            }
        });

        props.updateContentCategoryState(contentCategories);

        Promise.all(updateTasks);
    }

    return (
        <div className={`therapist-support-layout ${isNavOpen ? 'isOpen' : ''}`}>
            {/* Navigation Panel */}
            <div className="AccordionContent">
                <NavSidebar toggle={toggle}
                            isNavOpen={isNavOpen}
                            setIsNavOpen={setIsNavOpen}
                            title={'Therapist Toolbox'}
                >
                    <DragDropContext onDragEnd={afterDragUpdateCategoryDatabase}>
                        <Droppable droppableId='content-category-drop-zone'>
                            {(provided, snapshot) => (
                                <div className="" {...provided.droppableProps} ref={provided.innerRef}>
                                    <>
                                        {
                                            contentCategories.map((category, index) => (
                                                <Draggable
                                                    key={category.id}
                                                    draggableId={category.id}
                                                    index={index}
                                                >
                                                    {(dragProvided, snapshot) => (
                                                        <div
                                                            key={category.id}
                                                            onClick={() => selectContentCategory(category.id)}
                                                            className={`chapter-content-container ${getSelectedStyles(category.id)}`}
                                                            ref={dragProvided.innerRef}
                                                            {...dragProvided.draggableProps}
                                                        >

                                                            <div className="chapter-icon-container">
                                                                <div
                                                                    className="drag-handle"
                                                                    {...dragProvided.dragHandleProps}
                                                                >
                                                                    <DragGrip />
                                                                </div>
                                                                <div className="sidebar-link-text">
                                                                    {
                                                                        isShowingEditInlineForm && state.categoryEditingId === category.id ?
                                                                            (
                                                                                <BasicInlineForm
                                                                                    text={category.name}
                                                                                    placeHolderText={'Enter A Name For This Category'}
                                                                                    onSubmit={handleEditingCategory}
                                                                                    onBlur={toggleEditInlineForm}
                                                                                />
                                                                            ) : (
                                                                                <>
                                                                                    {category.name}
                                                                                </>
                                                                            )
                                                                    }
                                                                </div>
                                                            </div>

                                                            {
                                                                isAdmin && (
                                                                    <div className="therapist-panel-btn-container">
                                                                        <button
                                                                            className="btn-cbit-icon"
                                                                            onClick={(e) => handleToggleEditForm(e, category.id)}
                                                                        >
                                                                            <img src={editIconSrc} alt="Edit Icon" />
                                                                        </button>
                                                                        <button
                                                                            className="btn-cbit-icon"
                                                                            onClick={(e) => handleDeleteClick(e, category)}
                                                                        >
                                                                            <img src={trashIconSrc} alt="Trash Icon" />
                                                                        </button>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))
                                        }
                                        <>
                                            {
                                                <>
                                                    {!showInline && (
                                                    <div className="chapter-content-container">
                                                        <BasicInlineForm
                                                            text={''}
                                                            placeHolderText={'Enter A Name For This Category'}
                                                            onSubmit={addNewContentCategory}
                                                            onBlur={()=>{}}
                                                        />
                                                    </div>
                                                    )}
                                                </>
                                            }
                                        </>
                                    </>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <>
                        {
                            isAdmin && (
                                <div className="standard-content-btn-container">
                                    <StandardContentButton
                                        isLoading={false}
                                        onClick={toggleInlineForm}
                                        btnLabel={'Add Category'}
                                    />
                                </div>
                            )
                        }
                    </>
                </NavSidebar>
            </div>
            {/* Main content container */}
            <div className="therapist-support-main-content">
                {children}
            </div>
        </div>
    )
}

export default AdminTherapistSupportLayout