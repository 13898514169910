import * as React from 'react';
import UrlWhitelist from "./components/Utilities/RouteGuard/UrlWhitelist";
import { Toaster } from 'react-hot-toast';
import Layout from "./components/Layout";
import { toastOptions } from './models/toasts/ToastOptions';
import './custom.css';
import './styles/index.css';
import './styles/responsive.css';

const App = () => {
    let windowWidth = window.innerWidth;

    return (    
        <Layout>
            <UrlWhitelist />
            <Toaster toastOptions={toastOptions}
                     containerStyle={{
                        top: windowWidth > 1023 ? '6rem' : '5rem',
                    }}
            />
        </Layout>    
    );
} 

export default App