import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { ApplicationState } from '../../../store';
import { CurrentUserState, userStoreAvailableActions } from '../../../store/CurrentUser';
import AdminNavLinks from '../../AdminDashboard/AdminNavigation/AdminNavLinks';
import cbitLogo from '../../../resources/images/temp_logo.png';
import adminCbitLogo from '../../../resources/images/cbit-admin-logo.png';
import UserProfileMenu from './UserProfileMenu';
import './NavMenu.css';
import { adminRoutes } from '../../../Routes/PrivateRoutes';
import { CbitUrls } from '../../../models/utility/CbitUrls';

/**
 * Get the current environment url
 * @returns 
 */
const getCurrentUrl = () => {
    const env = process.env.REACT_APP_DEV_ENV;        

    const currentUrl = CbitUrls.externalUrls[env as keyof typeof CbitUrls.externalUrls];

    //If somehow no value exists return the local url
    if(!currentUrl) {
        return CbitUrls.localExternalUrl;
    }

    return currentUrl;
}  

/**
 * Main Navigation menu header 
 */
const NavMenu = () => {
    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state )=> state.currentUser);
    
    const dispatch = useDispatch();  

    const location = useLocation();

    const logOutAction = useCallback(
        () => dispatch({type: userStoreAvailableActions.removeCurrentUser}), 
        [dispatch]
    )

    const getHomeRoute = () => {
        return UserStore?.isLoggedIn ? "/home" : "/login";
    }

    /**
     * Show admin logo or not. Routes page should be protecting the admin routes
     * There are better ways to do this but time is not on our side =/
     * @returns 
     */
    const showAdminDashboardLogo = () => {
        const currentPath = location.pathname;
        if(!UserStore) 
            return false;

        return adminRoutes.includes(currentPath)              
    }
    

    return (
        <header className={`header-navbar ${showAdminDashboardLogo() && 'admin-header-nav'}`}>  
            <div className="navbar-inner">
                <div className="logo-container">
                    {
                        showAdminDashboardLogo() ? 
                        (
                            <NavLink className='logo-link' to='/home'>
                                <img className='logo' src={adminCbitLogo} alt="CBIT Logo" />
                            </NavLink>
                        ) 
                        : 
                        (
                            <NavLink className='logo-link' to={getHomeRoute()}>
                                <img className='logo' src={cbitLogo} alt="CBIT Logo" />
                            </NavLink>
                        )
                    }
                </div>
                <div className="nav-items-container">                
                    <div className={`nav-items ${location.pathname === '/login' ? 'home' : ''}`}>
                    {
                        UserStore?.isLoggedIn && ( 
                            <>
                            <NavLink activeClassName='is-active' className="nav-item-text" to="/home">Home</NavLink>
                            <NavLink activeClassName='is-active' className="nav-item-text" to="/contact-us">Contact&nbsp;Us</NavLink>
                            <NavLink activeClassName='is-active' className="nav-item-text" to="/faq">FAQ</NavLink>
                            <a  className="nav-item-text" target='blank' href='https://csbsutah.co1.qualtrics.com/jfe/form/SV_0MuxBPUoZZcMz7E'>Request&nbsp;A&nbsp;Manual</a>
                            {/* <a  className="nav-item-text" href={`${getCurrentUrl()}/contact-us`}>Contact&nbsp;Us</a> */}
                            </>

                        )

                    }
                        <AdminNavLinks />
                        {
                            !UserStore?.isLoggedIn && (
                                <>                                
                                    <NavLink exact activeClassName='is-active' className="nav-item-text" to="/login">Login</NavLink>        
                                    <NavLink exact activeClassName='is-active' className="nav-item-text" to="/sign-up">Sign Up</NavLink>        
                                </>
                            )
                        }                        
                    </div>
                    <div className={`user-navigation-items ${location.pathname === '/login' ? 'home' : ''}`}>
                        {
                            UserStore?.isLoggedIn &&
                            (
                                <UserProfileMenu 
                                    userId={UserStore.userProfile.id} 
                                    firstName={UserStore.userProfile.firstName} 
                                    lastName={UserStore.userProfile.lastName} 
                                    isAdmin={UserStore.isAdmin} 
                                    logOutFunc={logOutAction } 
                                />
                            )                             
                        }
                    </div> 
                </div>
            </div>           
        </header>        
    )
}

export default NavMenu; 