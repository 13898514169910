import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import AdminUserForm from '../components/AdminDashboard/AdminForms/UserForms/AdminUserForm';
import AdminTherapistSupport from '../components/AdminDashboard/AdminTherapistSupport/AdminTherapistSupport';
import ContentManagement from '../components/AdminDashboard/AdminTools/ContentManagement';
import ContentBuilder from '../components/AdminDashboard/ContentBuilder/ContentBuilder';
import ReviewSection from '../components/AdminDashboard/ReviewsSection/ReviewSection';
import UserManagement from '../components/AdminDashboard/UserManagement/UserManagement';
import ConsultationLayout from '../components/ConsultationDashboard/ConsultationLayout';
import AvailabilityManagement from '../components/ConsultationDashboard/ConsultationPages/AvailabilityManagement/AvailabilityManagement';
import BookingPage from '../components/ConsultationDashboard/ConsultationPages/Booking/BookingPage';
import ConsultantMyProfile from '../components/ConsultationDashboard/ConsultationPages/ConsultationMyProfile/ConsultMyProfile';
import PreviousSessionsPage from '../components/ConsultationDashboard/ConsultationPages/PreviousSessions/PreviousSessionsPage';
import UpcomingSessionsPage from '../components/ConsultationDashboard/ConsultationPages/UpcomingSessions/UpcomingSessionsPage';
import UploadWebinars from '../components/ContinuingEducation/ContinuingEducationPages/AdminPages/UploadWebinars';
import MyRegistrations from "../components/ContinuingEducation/ContinuingEducationPages/MyRegistrations/MyRegistrations";
import PastWebinars from "../components/ContinuingEducation/ContinuingEducationPages/PastWebinars/PastWebinars";
import UpcomingWebinars from "../components/ContinuingEducation/ContinuingEducationPages/UpcomingWebinars/UpcomingWebinars";
import FamilySupportIndex from "../components/FamilySupport/FamilySupportIndex";
import FamilySupportAdminDashboard from "../components/FamilySupportAdminDashboard/FamilySupportAdminDashboardIndex";
import Inbox from "../components/Inbox/InboxPages/Inbox/Inbox";
import DownloadPdfPage from '../components/PublicPages/DownloadPdfPage';
import Logout from '../components/PublicPages/Logout';
import ReferralLayout from '../components/Referral/ReferralLayout';
import ReferralListPage from '../components/Referral/ReferralList/ReferralListPage';
import ReferralMyProfile from '../components/Referral/ReferralMyProfile/ReferralMyProfile';
import ReferralPendingPage from '../components/Referral/ReferralMyProfile/ReferralPendingPage';
import AddPost from "../components/SocialConnection/SocialConnectionPages/AddPost/AddPost";
import Admin from "../components/SocialConnection/SocialConnectionPages/Admin/Admin";
import Faqs from "../components/SocialConnection/SocialConnectionPages/Faqs/Faqs";
import MyPosts from "../components/SocialConnection/SocialConnectionPages/MyPosts/MyPosts";
import Post from "../components/SocialConnection/SocialConnectionPages/Post/Post";
import SocialConnection from "../components/SocialConnection/SocialConnectionPages/SocialConnection/SocialConnection";
import TherapistSupport from '../components/TherapistSupport/TherapistSupport';
import LearningDashboard from '../components/UserDashboard/LearningContentViewer/LearningDashboard';
import MyProfileTemp from '../components/UserDashboard/MyProfile/MyProfile';
import PasswordReset from '../components/UserDashboard/PasswordReset/PasswordReset';
import UserDashboard from '../components/UserDashboard/UserHome/UserDashboard';
import AuthGuard from '../components/Utilities/RouteGuard/AuthGuard';
import { CheckIfConsultant } from '../models/module/UserProfile';
import { RolesHelper } from '../models/utility/Roles';
import { ApplicationState } from '../store';
import { CurrentUserState } from '../store/CurrentUser';
import ContactUs from '../components/PublicPages/ContactUs/ContactUs';
import FAQ from '../components/PublicPages/Faqs/Faqs';

//To get the Admin logo to show you need to add the route to here also
export const adminRoutes = [    
    '/users',
    '/therapist-toolbox-admin',
    '/family-support-dashboard',
    '/users/new',
    '/therapist-support-Admin',
    '/admin-tools',
    '/content-builder',
    '/users/:username/edit',
    '/consultation-reviews',
    'continuing-education/upload-webinars',
    '/test-page'
]

const PrivateRoutes = () => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    /**
     * Checks if the user have completed their three free consultation sessions
     * @returns 
     */
    const checkUserThreeSessions = () => {
        if (!userStore) {
            return false;
        }
        const {userProfile} = userStore;
        
        let canVisit = CheckIfConsultant(userProfile.roles);

        if(canVisit) {
            return canVisit;
        }

        canVisit = userProfile.completedFreeConsultation;

        return canVisit;
    }

    /**
     * Check if the user have completed their training
     * @returns 
     */
    const checkUserTraining = () => {
        if (!userStore) {
            return false;
        }

        const {userProfile} = userStore;
                
        let canVisit = CheckIfConsultant(userProfile.roles);

        if(canVisit) {
            return canVisit;
        }

        canVisit = userProfile.completedTraining === userProfile.assignedSectionId;

        return canVisit;
    }

    return (
        <AuthGuard>
            <Switch>
                <Route exact path='/' component={UserDashboard}/>
                <Route exact path='/home' component={UserDashboard}/>
                <Route path='/contact-us' component={ContactUs} />
                <Route path='/faq' component={FAQ} />
                <Route path='/logout' component={Logout}/>
                <Route path='/my-profile' component={MyProfileTemp}/>
                <Route path='/inbox' component={Inbox}/>
                <Route path='/password-reset' component={PasswordReset}/>
                <Route path='/learningDashboard/:id'
                       render={props => <LearningDashboard id={props.match.params.id}
                                                           {...props}/>}/>
                <Route path='/therapist-toolbox' component={TherapistSupport}/>
                <Route path='/family-support' component={FamilySupportIndex}/>
                <Route path='/family-support-dashboard' component={FamilySupportAdminDashboard}/>
                <Route path='/social-connection' exact component={SocialConnection}/>
                <Route path='/social-connection/posts/:id' component={Post} />
                <Route path='/social-connection/add-post' component={AddPost} />
                <Route path='/social-connection/my-posts' component={MyPosts}/>
                <Route path='/social-connection/faqs' component={Faqs}/>
                <Route path='/social-connection/admin' component={Admin}/>
                <Route path={'/continuing-education/upcoming-webinars'} component={UpcomingWebinars}/>
                <Route path={'/continuing-education/past-webinars'} component={PastWebinars}/>
                <Route path={'/continuing-education/my-registrations'} component={MyRegistrations}/>
                <Route path='/download-pdf/:id' component={DownloadPdfPage}/>
                {/** When adding a new route to the consultation layout make sure to add it here too */}
                <Route exact path={
                    [
                        "/consultation",
                        "/consultation/booking",
                        "/consultation/upcoming-sessions",
                        "/consultation/previous-sessions",
                        "/consultation/availability-management",
                        "/consultation/my-profile"
                    ]}
                >
                    {
                        checkUserTraining() ? 
                        (
                            <ConsultationLayout>
                                <Route path='/consultation/booking' component={BookingPage}/>
                                <Route path='/consultation/upcoming-sessions' component={UpcomingSessionsPage}/>
                                <Route path='/consultation/previous-sessions' component={PreviousSessionsPage}/>
                                {
                                    userStore && RolesHelper.CheckIfConsultant(userStore.userProfile.roles) ?
                                    (
                                        <>
                                            <Route path='/consultation/availability-management' component={AvailabilityManagement}/>
                                            <Route path='/consultation/my-profile' component={ConsultantMyProfile}/>
                                        </>
                                    )
                                    :
                                    (
                                        <Redirect to='/consultation/booking' />
                                    )
                                }
                            </ConsultationLayout>
                        ) 
                        : 
                        (
                            <Redirect to='/home' />
                        )

                    }
                </Route>
                <Route exact path={
                    [
                        '/referral',
                        '/referral/my-profile',
                        '/referral/list',
                        '/referral/pending-profile',
                    ]}
                >
                    {
                        checkUserThreeSessions() ? 
                        (
                            <ReferralLayout>
                                <Route path='/referral/my-profile' component={ReferralMyProfile} />
                                <Route path='/referral/list' component={ReferralListPage} />
                                <Route path='/referral/pending-profile' component={ReferralPendingPage} />
                            </ReferralLayout>
                        ) 
                        : 
                        (
                            <Redirect to='/home' />
                        )
                    }
                </Route>
                {
                    userStore && userStore.isAdmin || userStore && userStore.isSuperAdmin ?
                        (
                            <>
                                {/**Add any new Admin routes to the Admin routes object to show the Admin logo */}                                
                                <Route path='/users' component={UserManagement} exact/>
                                <Route path='/users/new' render={props => <AdminUserForm isEditing={false}/>}/>
                                <Route path='/users/:username/edit' render={props => <AdminUserForm isEditing={true}/>}/>
                                <Route path='/therapist-toolbox-admin' component={AdminTherapistSupport}/>
                                <Route path='/admin-tools' component={ContentManagement}/>
                                <Route path='/content-builder' component={ContentBuilder}/>
                                <Route path='/consultation-reviews' component={ReviewSection}/>
                                <Route path='/continuing-education/upload-webinars' component={UploadWebinars} />
                                {/* <Route path='/test-page' component={MyProfileTemp}/> */}
                            </>
                        )
                        :
                        (
                            <Redirect to='/home'/>
                        )
                }                              
            </Switch>
        </AuthGuard>
    )
}

export default PrivateRoutes;
