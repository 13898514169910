import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { Spinner } from 'reactstrap';
import { LessonContentController } from '../../../../../controllers/LessonContentController';
import { _idObject } from '../../../../../models/AdditionalVideoContent/AdditionalVideoContent';
import { ILessonContent } from '../../../../../models/lesson/LessonContent';
import { IAdminModule } from '../../../../../models/module/AdminModule';
import { AdminTreeModule } from '../../../../../models/partialModels/AdminTreeModule';
import { DeepCopy } from '../../../../../models/utility/DeepCopy';
import { EditorContentEnum } from '../../../../../models/utility/EditorContentEnum';
import { ModuleAction } from '../../../../../models/utility/UnSavedCourse/ModuleAction';
import LessonContentItem from '../../ContentItems/LessonContentItem';
import { AdminViewProps } from '../TopModuleView/TopModuleView';

type LessonContentViewProps = {
    failedFetchErrMsg:string
    failedUpdateErrMsg:string,
    tagId?:_idObject
} & AdminViewProps;

const lessonContentController = new LessonContentController

/**
 * View for "viewing" lesson content items.
 * AKA looking inside Combo Lessons and Quizzes 
 */
const LessonContentView = (props:LessonContentViewProps) => {
    const {
        contentList, 
        updatedContentList, 
        updateIsLoading, 
        selectedModuleId, 
        contentType,
        isLoading,
        onDeleteConfirm,
        updateUnSavedContent,
        failedFetchErrMsg,
        failedUpdateErrMsg,
        selectedModule,
        parentModule,
        forceRerender,
        isVisible,
        isExpanded,
    } = props;

    useEffect(() => {
        async function onSelectedModuleChange() {
            const type = AdminTreeModule.getAdminTreeItemContentType(selectedModule, parentModule)
            if(type === EditorContentEnum.lesson || type === EditorContentEnum.lessonQuiz) {
                // await getContent()
            }
        }
        onSelectedModuleChange();
    }, [selectedModule.id]);


    /**
     * replaces an adminModule item in the content list with an updated one
     * Note: because it is lesson content we don't need to update the accordion
     * @param adminModule 
     * @returns 
     */
       const updateContent = (adminModule:IAdminModule) => {
        const contentListCopy:IAdminModule[] = DeepCopy.copy(contentList);
        
        const foundIndex = contentListCopy.findIndex(adminMod => adminMod.content.id === adminModule.content.id);        
        if(foundIndex !== -1) {
            contentListCopy[foundIndex] = adminModule;            
            
            updatedContentList(contentListCopy);            
    
            updateUnSavedContent(adminModule, ModuleAction.UPDATE);            

            return;
        }
    
        toast.error(failedUpdateErrMsg)
    }

    /**
     * Removes a content item from the content list and the accordion
     * @param adminModule 
     */
      const removeContent = (adminModule:IAdminModule) => {
        let contentListCopy:IAdminModule[] = DeepCopy.copy(contentList);
     
        contentListCopy = contentListCopy.filter(adminMod => adminMod.content.id !== adminModule.content.id);  
     
        updatedContentList(contentListCopy);          
        
        updateUnSavedContent(adminModule, ModuleAction.DELETE);
    }

    

    return (
        <>
        {
            isLoading ? 
            (
                <Spinner className='custom-spinner admin-spinner' color="secondary">loading</Spinner>
            ) 
            : 
            (
                <>
                {
                    contentList.length > 0 ? 
                    (
                        <>
                            {contentList.map((contentItem: IAdminModule, index:number) => (
                                    //@ts-ignore
                                    <React.Fragment key={contentItem.key}>
                                        {
                                            <LessonContentItem 
                                                index={index} 
                                                content={contentItem.content as ILessonContent} 
                                                deleteItem={() => removeContent(contentItem) } 
                                                onSubmit={updateContent} 
                                                isExpanded={isExpanded} 
                                                causeRerender={forceRerender} 
                                                draggableId={contentItem.key} 
                                                onDeleteConfirmSubmit={onDeleteConfirm} 
                                                contentType={contentType}
                                                parentsVisibility={isVisible}    
                                                tagId={props.tagId}                                                                                                                        
                                            />                                                 
                                        }
                                    </React.Fragment>
                                )
                            )}                                                                                          
                        </>
                    ) 
                    :
                    (                                                                           
                        <div className="empty-content-list">
                            <p>There is nothing here yet</p>
                        </div>
                    )
                }
                </>
            )
        }
        
    </>
    )
}

export default LessonContentView;