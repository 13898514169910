import React, { FunctionComponent, useEffect, useState } from "react";
import { LessonController } from "../../../controllers/LessonController";
import { UserLessonController } from "../../../controllers/UserLessonController";
import { Lesson } from "../../../models/lesson/Lesson";
import { LessonTypeEnum } from "../../../models/lesson/LessonType";
import { UserLesson } from "../../../models/lesson/UserLesson";
import { PartialModuleTreeModel } from "../../../models/partialModels/PartialModuleTreeModel";
import CustomModal from "../../Utilities/CustomModal";
import ComboLessonType from "./LessonContentTypes/ComboLessonType";
import QuizLesson from "./LessonContentTypes/QuizLesson";
import TextLesson from "./LessonContentTypes/TextLesson";
import VideoLesson from "./LessonContentTypes/VideoLesson";
import CourseCompletion from "./CourseCompletion";

type viewerProps = {
    ParentModule: PartialModuleTreeModel,
    SelectedUserModule: PartialModuleTreeModel,
    SetUserAccordion: any,
}

const UserLessonViewerComponent: FunctionComponent<viewerProps> = (props) => {

    const [selectedId, setSelectedId] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [showCourseComplete, setShowCourseComplete] = useState(false);
    const [selectedUserLesson, setSelectedUserLesson] = useState(
        {
            id: "",
            userProfileId: "",
            originalDocumentId: "",
            parentUserDocumentId: "",
            parentDocumentId: "",
            order: -1,
            completion: "",
            isViewable: false,
            isDisabled: false,
            isEditable: false,
            isOptional: false,
            isComplete: false,
            isNext: false,
            VersionAt: '',
            lesson: new Lesson(),
            ChildUserModules: [],
        }
    );

    const userLessonController = new UserLessonController();
    const lessonController = new LessonController();

    useEffect(() => {

        if(props.SelectedUserModule != undefined){
            if (props.SelectedUserModule.id != selectedId) {
                const GetData = async (SetUserLesson: any, SetLoading: any) => {
                    let searchId = props.SelectedUserModule.id;
                    let userLessonCore: UserLesson = await userLessonController.GetCompleteUserLesson(searchId);
                
                    if(userLessonCore != undefined){
                        SetUserLesson(userLessonCore);
                        SetLoading(false);
                    }

                }

                setSelectedId(props.SelectedUserModule.id);
                GetData(setSelectedUserLesson, setIsLoading);
            }
        }


    }, [props.SelectedUserModule])

    /**
    * Toggles the course completed module
    */
    const toggleCourseCompleteModal = () => {
        setShowCourseComplete(!showCourseComplete);
    }

    return (
        <>
            {isLoading ? (
                <div className="learning-content-loading">
                    loading
                </div>
            ) : (
                <>
                    <div className="learning-content-view-panel">

                        {selectedUserLesson?.lesson?.type == LessonTypeEnum.video && (
                            <VideoLesson selectedUserLesson={selectedUserLesson}
                                updateAccordionFunction={props.SetUserAccordion}
                                parentModule={props.ParentModule}
                                toggleCourseCompletion={toggleCourseCompleteModal}
                            />
                        )}

                        {selectedUserLesson.lesson.type == LessonTypeEnum.quiz && (
                            <QuizLesson parentModule={props.ParentModule}
                                updateAccordionFunction={props.SetUserAccordion}
                                selectedUserLesson={selectedUserLesson}
                                toggleCourseCompletion={toggleCourseCompleteModal}                                                        
                            />
                        )}

                        {selectedUserLesson.lesson.type == LessonTypeEnum.text && (
                            <TextLesson parentModule={props.ParentModule}
                                updateAccordionFunction={props.SetUserAccordion}
                                selectedUserLesson={selectedUserLesson}
                                toggleCourseCompletion={toggleCourseCompleteModal}
                            />
                        )}

                        {selectedUserLesson.lesson.type == LessonTypeEnum.custom && (
                            <ComboLessonType parentModule={props.ParentModule}
                                updateAccordionFunction={props.SetUserAccordion}
                                selectedUserLesson={selectedUserLesson}
                                toggleCourseCompletion={toggleCourseCompleteModal}
                            />
                        )}

                    </div>
                </>
            )}
            <CustomModal
                isOpen={showCourseComplete}
                toggle={toggleCourseCompleteModal}                
            >
                <CourseCompletion onReturnToLessonClick={toggleCourseCompleteModal}/>
            </CustomModal>
        </>
    )

}

export default UserLessonViewerComponent;