import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { CurrentUserState } from '../store/CurrentUser';


/**
 * Auth hook for getting the current user store 
 * @returns 
 */
const useAuth = () => {
  const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

  if (!userStore) throw new Error('No User Store Available');

  return userStore;
};

export default useAuth;
