import * as React from 'react';
import {IMultipleChoiceContent, MultipleChoiceContent} from '../../../../models/contentTypes/MultipleChoiceContent'; 
import { ISelectableChoice, SelectableChoice } from '../../../../models/contentTypes/SelectableChoice';
import "bootstrap-icons/font/bootstrap-icons.css";
import QuestionForm from './QuestionForm';
import {ILessonContent} from "../../../../models/lesson/LessonContent";
import {QuestionType} from "../../../../models/contentTypes/QuestionType";
import { IAdminModule } from '../../../../models/module/AdminModule';


// State type for the multi choice form
export type QuestionFormState = {
    question: IMultipleChoiceContent,
    isLoading: boolean
}

// Prop type for the multipleChoiceForm component
export type MultipleChoiceProps = {
    content: ILessonContent
    isEditing: boolean
    branchID: string
    question:MultipleChoiceContent
    order:number
    questionNumber:number
    onSubmit(content:ILessonContent | IAdminModule) : void
    toggleModal():void
}

const defaultMultiChoiceItem = {
    questionText:'',
    questionNumber: '',
    choices: [
        new SelectableChoice(),
        new SelectableChoice(),
        new SelectableChoice(),
        new SelectableChoice()
    ],
    hasMultipleAnswers: true,
    completed:false,
}

// Form for multiple choice questions
class MultipleChoiceForm extends React.Component<MultipleChoiceProps, QuestionFormState> {
    
    /**
     * Passing props to super and setting the default state
     */
    constructor(props:any) {
        super(props);        
        this.state = this.defaultState;        
    }

    private defaultState : QuestionFormState = {
        question: this.props.question ? 
            this.props.question : 
            //If a question props have not been passed in create a new multipleChoiceContent
            new MultipleChoiceContent(JSON.parse(JSON.stringify(defaultMultiChoiceItem))), 
        isLoading: false
    }


    /**
     * Adds a choice to the question state
     */
    private AddChoice = (childState : IMultipleChoiceContent) => {

        let choices : ISelectableChoice[] = [];

        if( Array.isArray(this.state.question.choices))
            choices = this.state.question.choices;

        this.setState(prevState => ({
            ...prevState,
            question: {
                ...childState,
                choices: [...choices, new SelectableChoice()]
            }
        }));
    }

    /**
     * Removes a choice from the question state
     * @param index 
     */
    private RemoveChoice = (index:number, questionText:string) => {
        // Creating a new array so we dont mutate the state    
        const choices = this.state.question.choices;
        
        // We are removing one item at the index passed in
        choices.splice(index, 1);

        this.setState(prevState => ({
            ...prevState,
            question: {
                ...prevState.question,
                questionText:questionText,
                choices: [...choices]
            }
        }));
    }

    private handleSubmit = (content:ILessonContent | IAdminModule) => {
        this.props.onSubmit(content);

        this.setState(this.defaultState);
    }

    public render() {
        const {isEditing, branchID, toggleModal, questionNumber, order} = this.props;
        return (
            <>
                <QuestionForm 
                    order={order}
                    questionNumber={questionNumber}
                    isTrueOrFalse={false}
                    onSubmit={this.handleSubmit}
                    content={this.props.content}
                    addChoice={this.AddChoice}
                    removeChoice={this.RemoveChoice}
                    questionType={QuestionType.multipleChoice}
                    defaultQuestion={this.state.question} 
                    isEditing={isEditing} 
                    branchID={branchID}                
                    toggleModal={toggleModal}
                />
            </>
        )
    }
}

export default MultipleChoiceForm;