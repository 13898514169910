import {AxiosError} from 'axios';
import {
    convertToPartialModuleEnum,
    PartialModuleTreeModel,
    TreeViewType
} from "../models/partialModels/PartialModuleTreeModel";
import {UserLessonService} from "../services/UserLessonService";
import {UserLesson} from "../models/lesson/UserLesson";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import {LessonService} from "../services/LessonService";
import {PublishedLessonService} from "../services/PublishedLessonService";
import {UserLessonContentService} from "../services/UserLessonContentService";
import {LessonContentService} from "../services/LessonContentService";
import {LessonTypeEnum} from "../models/lesson/LessonType";
import { PublishedLessonContentService } from '../services/PublishedLessonContentService';


export class UserLessonController {
    constructor(){
        this.userLessonService = new UserLessonService();
        this.lessonService = new LessonService();
        this.lessonContentService = new LessonContentService();
        this.publishedLessonService = new PublishedLessonService();
        this.publishedLessonContentService = new PublishedLessonContentService();
        this.userLessonContentService = new UserLessonContentService();
    }

    private userLessonService:UserLessonService;
    private lessonService:LessonService;
    private lessonContentService: LessonContentService;
    private publishedLessonService:PublishedLessonService;
    private publishedLessonContentService:PublishedLessonContentService;
    private userLessonContentService:UserLessonContentService;
    
    public async CreateLesson(lessonData: UserLesson) {
        try{
            return this.userLessonService.Post(lessonData);
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    public async GetLesson(id:string): Promise<UserLesson> {
        try{
            return this.userLessonService.Get({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async GetLessons() {
        try{
            return this.userLessonService.GetAll();
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    public async UpdateLesson(lessonData: UserLesson) {
        try{
            return await this.userLessonService.Put({id:lessonData.id},lessonData);
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    public async DeleteLesson(id:string) {
        try{
            return this.userLessonService.Delete({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    public async GetUserLessonById(id:string){
        try
        {
            return this.userLessonService.Get({id});
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    public async GetUpdatedFlags(uLesson:PartialModuleTreeModel, id:string){
        try
        {
            let lesson = await this.userLessonService.Get({id});
            uLesson.isDisabled = lesson.isDisabled;
            uLesson.isNext = lesson.isNext;
            uLesson.isViewable = lesson.isViewable;
            uLesson.isEditable = lesson.isEditable;
            uLesson.isOptional = lesson.isOptional;
            uLesson.isComplete = lesson.isComplete;
            uLesson.isOpen = lesson.isOpen;
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    public async GetCompleteUserLesson(id:string){
        try{
            let userLesson = await this.userLessonService.Get({id});
            // let lesson = await this.lessonService.Get({id:userLesson.originalDocumentId});
            let lesson = await this.publishedLessonService.Get({id:userLesson.originalDocumentId});
            let lessonContent = await this.publishedLessonContentService.GetAllByLessonId(lesson.id)
            let userLessonContent = await this.userLessonContentService.GetAndCreateAssignedUserLessonContentByLessonId({id:lesson.id});

            lesson.lessonContent = lessonContent;
            lesson.userLessonContent = userLessonContent;
            userLesson.lesson = lesson;

            return userLesson;
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    public async UnlockUserLesson(id:string){
        try{
            return await this.userLessonService.UnlockUserLesson({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }


    public async GetAssignedUserLessonsByParentId(id:string): Promise<UserLesson[]>{
        try{
            return await this.userLessonService.GetAssignedLessonForUsersByParentModuleId({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }
    
    /**
     * Get the users current/next lesson. The next lesson is the current lesson the user is on
     * @param userId 
     * @param sectionId 
     * @returns 
     */
    public async GetUserNextLesson(userId:string, sectionId:string) {
        try {
            return await this.userLessonService.GetUserNextLesson(userId, sectionId);
        } catch (error) {            
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }

}