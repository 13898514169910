import React, {useEffect, useState} from "react"
import {SocialConnectionController} from "../../../../controllers/SocialConnectionController";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import Search from "../../components/Search";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {CurrentUserState} from "../../../../store/CurrentUser";
import UseModal from "../../../../hooks/UseModal";
import {UserController} from "../../../../controllers/UserController";
import SocialConnectionPostItem from "../../components/SocialConnectionPostItem";
import {IPost} from "../../../../models/SocialConnection/Post";
import {DeepCopy} from "../../../../models/utility/DeepCopy";
import "./SocialConnection.css"
import SocialConnectionLayout from "../../components/layout/SocialConnectionLayout";

const SocialConnection = ({history}: any) => {
    const [posts, setPosts] = useState<any>([]);
    const [keyword, setKeyword] = useState<any>('');
    const [loading, setLoading] = useState(true);
    const [hasUserVisited, setHasUserVisited] = useState(false);
    const [modalState, setModalState, toggleModal] = UseModal(true);
    const [isModalOpen, setIsModalOpen] = useState(modalState);
    const [hasAgreed, setHasAgreed] = useState(false);

    const uController = new UserController();

    // Get current user
    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state) => state.currentUser);

    const goToAddPost = () => {
        history.push('/social-connection/add-post');
    }

    const getAllPosts = async () => {
        const sConnectionController = new SocialConnectionController();
        const allPosts = await sConnectionController.GetAllPosts();
        setPosts(allPosts);
        setLoading(false);
    }

    useEffect(() => {
        const userIsBanned = UserStore?.userProfile?.isBanned;

        if (userIsBanned) {
            history.push('/home');
        }

        const hasVisitedSocialConnection = UserStore?.userProfile?.visitedSocialConnection;
        if (!hasVisitedSocialConnection) {
            setModalState(true);
        } else {
            setHasUserVisited(true);
            setModalState(false);
        }

        return () => {
            setHasUserVisited(false);
            setModalState(false);
            setHasAgreed(false);
        }

    }, [UserStore?.userProfile]);

    useEffect(() => {
        getAllPosts();
    }, [history]);

    const handlePostUpdate = async (updatedPost: IPost) => {
        let oldData = DeepCopy.copy(posts);

        oldData.map(() => {
            if (oldData.id === updatedPost.id) {
                oldData = updatedPost;
            }
        })

        setPosts(oldData);
    }

    const handleModalAgree = async () => {
        let data = await uController.UpdateVisitedSocialConnection(UserStore!.userProfile!.id)
        setHasUserVisited(true);
        setHasAgreed(true);
        setModalState(false);
        return data;
    }

    const handleModal = () => {
        setModalState(false);
        history.push('/home');
    }

    return (
        <SocialConnectionLayout>
            {loading ? (
                <div className="spinner-container">
                    <Spinner color='primary'/>
                </div>
            ) : (
                <>
                    {/* Header */}
                    <div className="social-connection-header">
                        <h2 className='social-connection-heading'>
                            Social Connection
                        </h2>
                        <button className='btn-cbit-primary btn-social-connection' onClick={goToAddPost}>
                            <span>New Post</span>
                            <i className="bi bi-plus-lg new-post-icon"/>
                        </button>
                    </div>

                    {/* Search */}
                    <Search setPosts={setPosts} setKeyword={setKeyword} keyword={keyword}/>

                    {/* Subheader */}
                    <div className="social-connection-subheader">
                        <h4 className='social-connection-subheading'>
                            All posts
                        </h4>
                    </div>

                    {/* Posts */}
                    <div className="social-connection-posts-container">
                        {posts?.length > 0 ? posts.map((post: any) => (
                            <SocialConnectionPostItem key={post.id}
                                                      post={post}
                                                      updatePost={handlePostUpdate}
                            />
                        )) : (
                            <div className='social-connection-no-posts-container'>
                                <h4 className={'social-connection-no-posts-text'}>No posts found</h4>
                            </div>
                        )}
                    </div>

                    {/*  Modal  */}
                    {!hasUserVisited &&
                        <Modal isOpen={isModalOpen} size={'lg'} centered={true}>
                            <ModalHeader toggle={handleModal}>Welcome To Social Connection</ModalHeader>
                            <ModalBody>
                                <p>
                                    I agree that I will protect the privacy of the clients that I am asking questions
                                    about.
                                    I
                                    will not reveal any identifying information that could compromise the privacy of a
                                    patient
                                    in any way.
                                </p>

                                <p>
                                    I agree to be respectful and use language that is appropriate when interacting with
                                    other
                                    CBIT Trainer users on the Social Connect feature.
                                </p>

                                <p>
                                    I will be supportive of other users of this feature of CBIT Trainer.
                                </p>

                                <p>
                                    I understand that if I do not comply with these rules, I may be asked to leave the
                                    Social
                                    Connect function of CBIT Trainer.
                                </p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={handleModalAgree}>
                                    Agree
                                </Button>{' '}
                                <Button color="secondary" onClick={handleModal}>
                                    Cancel
                                </Button>
                            </ModalFooter>
                        </Modal>
                    }
                </>
            )}
        </SocialConnectionLayout>
    )
}

export default SocialConnection