import React from 'react';
import CustomModal from './CustomModal';
import './CustomAlert.css';
type CustomAlertProps = {
    header:string
    text:string
    primaryBtnText:string
    secondaryBtnText:string
    tertiaryBtnText?:string
    isOpen:boolean    
    primaryBtnSubmit:() => void
    secondaryBtnSubmit:() => void
    tertiaryBtnSubmit?:()=>void
    toggleAlert:() => void
}

/**
 * A custom Alert component that pops with as a modal
 */
const CustomAlert = (props:CustomAlertProps) => {
    
    const {
            header, 
            text, 
            primaryBtnText, 
            secondaryBtnText, 
            tertiaryBtnText, 
            primaryBtnSubmit, 
            secondaryBtnSubmit, 
            tertiaryBtnSubmit, 
            toggleAlert, 
            isOpen,            
    } = props;
    
    return (
        <>
            <CustomModal isOpen={isOpen} toggle={toggleAlert}>        
                <div className="custom-alert">
                    <div className="custom-alert-header">
                        {header}
                    </div>
                    <div className="custom-alert-text">
                        {text}
                    </div>
                        <div className="btns-custom-alert-container">
                        {
                                tertiaryBtnText && tertiaryBtnSubmit && (
                                    <button className='btn-cbit-minor' onClick={() => tertiaryBtnSubmit()}>{tertiaryBtnText}</button> 
                                )
                            }
                            <button className='btn-cbit-minor' onClick={() => secondaryBtnSubmit()}>{secondaryBtnText}</button>
                            <button className='btn-cbit-primary' onClick={() => primaryBtnSubmit()}>{primaryBtnText}</button>
                        </div>
                </div>
            </CustomModal>
        </>
    )
}

export default CustomAlert;