import React from 'react';
import {Form, FormGroup, Label, Input, Container} from "reactstrap";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {UserService} from "../../services/UserService";
import toast from "react-hot-toast";
import {useHistory} from "react-router-dom";

const ForgotPassword = () => {
    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid email address').required('Required'),
        }),
        onSubmit: values => {
            const UService = new UserService();
            const userEmail = values.email;

            try {
                const userProfile = UService.GetUsernameByEmailForPassword(userEmail);
                toast.success("Password reset link sent to email");
                history.push('/login');
            } catch (e) {
                toast.error("Error sending password reset link to email");
                console.error(e);
            }
        },
    });

    return (
        <Container>
            <Form className="forgot-form" onSubmit={formik.handleSubmit}>
                <h2>Forgot Username or Password?</h2>
                <FormGroup>
                    <Label for="email">
                        Email
                    </Label>
                    <Input
                        id="email"
                        name="email"
                        placeholder="Email"
                        type="email"
                        invalid={!!(formik.touched.email && formik.errors.email)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className={'error-message-container'}>{formik.errors.email}</div>
                        ) : null}
                </FormGroup>
                <button type="submit" color="primary" className='btn-cbit-primary'>
                    Submit
                </button>
            </Form>
        </Container>
    )
}

export default ForgotPassword