import React, {useEffect, useState} from "react"
import UseModal from "../../../../hooks/UseModal";
import {Modal, ModalBody} from "reactstrap";
import {ConsultationController} from "../../../../controllers/ConsultationController";
import toast from "react-hot-toast";
import {ISeminar} from "../../../../models/consultations/Seminar";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {CurrentUserState} from "../../../../store/CurrentUser";

type MyRegistrationsModalProps = {
    seminar: ISeminar,
    updateRegistrations: any
}

const MyRegistrationsModal = ({seminar, updateRegistrations}: MyRegistrationsModalProps) => {
    const [modalState, setModalState, toggleModal] = UseModal(false);
    const [isModalOpen, setIsModalOpen] = useState(modalState);
    const [currentUserId, setCurrentUserId] = useState<string>('');

    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state) => state.currentUser);

    /**
     * Handles the opening of the modal
     * @returns {void}
     */
    const handleModalOpen = () => {
        setIsModalOpen(true)
        setModalState(true)
    }

    /**
     * Handles the cosing of the modal
     * @returns {void}
     */
    const handleModalClose = () => {
        setIsModalOpen(false)
        setModalState(false)
    }

    /**
     * Cancels the consultation
     * @returns {Promise<ISeminar[]>}
     */
    const cancelConsultation = async () => {
        const cController = new ConsultationController();

        try {
            let data = cController.WithdrawFromConsultation(seminar.consultation.id, UserStore!.userProfile.id);
            updateRegistrations(data);

            toast.success('Successfully cancelled consultation');
            setIsModalOpen(false);
            setModalState(false);

            return data;
        }
        catch (e) {
            toast.error('Failed to cancel consultation');
            console.error(e);
        }
    }

    return (
    <>
        <button className="my-registrations-item-btn" onClick={handleModalOpen}>
            Cancel Registration
        </button>
        <Modal isOpen={isModalOpen} size={'lg'} centered={true} className='my-registrations-modal'>
            <ModalBody className='my-registrations-modal-container'>
                <div>
                    <h1 className='my-registrations-modal-title'>
                        Are you sure you want to cancel session?
                    </h1>
                    <p className='my-registrations-modal-text'>
                        Once you click "Cancel Session", you will no longer have a reservation for this webinar and must repeat the reservation.
                    </p>
                    <div className='my-registrations-modal-btn-container'>
                        <button className='my-registrations-modal-btn cancel' onClick={handleModalClose}>
                            Do Not Cancel
                        </button>
                        <button className='my-registrations-modal-btn' onClick={cancelConsultation}>
                            Cancel Session
                        </button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    </>
  )
}

export default MyRegistrationsModal