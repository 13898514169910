import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { ImageManagementController } from '../../../../controllers/ImageManagementController';
import { UserController } from '../../../../controllers/UserController';
import { IUserBio } from '../../../../models/module/UserProfile';
import CloudflareImageHelper from '../../../../models/utility/CloudflareImageHelper';
import { DeepCopy } from '../../../../models/utility/DeepCopy';
import { ApplicationState } from '../../../../store';
import { CurrentUserState } from '../../../../store/CurrentUser';
import UserProfile from '../../../UserDashboard/UserProfile/UserProfile';
import ImageUploader from '../../../Utilities/ImageUploader';
import "./ConsultMyProfile.css";
import ConsultMyProfileForm from './ConsultMyProfileForm';

type ConsultationMyProfileState = {
    userBio:IUserBio | null
    isLoading:boolean
    isEditing:boolean
    imageFile:File | null
}

const userController = new UserController();

const imageManagementController = new ImageManagementController();

type ConsultantProfileEditState = {
    firstName:string
    lastName:string
    prefix:string
    title:string
    email:string
    description:string    
    imageLink:string
}

/**
 * Profile for consultants where they can update their  occupation (title), email, bio, and profile picture 
 * @returns 
 */
const ConsultationMyProfile = () => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    const imageHostUrl = CloudflareImageHelper.imageUrl;

    const defaultState:ConsultationMyProfileState = {
        userBio: null,
        isLoading:false,
        isEditing:false,
        imageFile:null
    }

    const defaultProfileFieldsState:ConsultantProfileEditState = {
        firstName: '',
        lastName: '',
        prefix: '',
        title: '',
        email: '',
        description: '',
        imageLink: '',
    }

    const [state, setState] = useState(defaultState);

    useEffect(() => {
        function onComponentMount() {
            getUserBio();
        }
        onComponentMount();
    }, []);

    /**
     * Get the current user bio info
     * @returns 
     */
    const getUserBio = async () => {
        if(!userStore) return;

        const userBio = await userController.GetUserBio(userStore.userProfile.id);        

        setState(prevState => ({
            ...prevState,
            userBio
        }));
    }

    /**
     * Toggle the flag that allow a consultant to edit their profile
     */
    const toggleIsEditing = () => {
        setState(prevState => ({...prevState, isEditing:!state.isEditing}));
    }

    const setIsLoading = (isLoading:boolean) => {
        setState(prevState => ({...prevState, isLoading}));
    }

    const onImageUpload = async (imageId:string) => {
        if(!state.userBio) return;

        setState(prevState => ({...prevState, isLoading:true}));

        try {
            
            let updateUserBio:IUserBio = DeepCopy.copy(state.userBio);

            updateUserBio.imageLink = imageId;

            updateUserBio = await userController.UpdateUserBio(updateUserBio);

            if(updateUserBio) {
                toast.dismiss("upload-image-toast");
                toast.success("Successfully updated image");
                setState(prevState => ({
                    ...prevState,
                    userBio:updateUserBio,
                    isLoading:false,
                    isEditing:false
                }));
            }
        } catch (error) {
            console.error(error);
            toast.dismiss("upload-image-toast");
            toast.error("Failed to upload image");
        }
    }

    const removeImage = async () => {
        if(!state.userBio) return;

        try {
            setState(prevState => ({...prevState, isLoading:true}));
            
            toast.loading("Removing Image", {id: "toast-remove-image"});

            await imageManagementController.DeleteImage(state.userBio.imageLink);

            const updateUser:IUserBio = DeepCopy.copy(state.userBio);

            updateUser.imageLink = "";

            const userBio = await userController.UpdateUserBio(updateUser);

            toast.dismiss("toast-remove-image");

            setState(prevState => ({
                ...prevState,
                isLoading:false,
                isEditing:false,
                userBio
            }));
            
        } catch (error) {
            setState(prevState => ({...prevState, isLoading:false}));
            console.error(error);
            toast.dismiss("toast-remove-image");
            toast.error("Failed to remove image");
        }
    }

    const setIsEditing = (isEditing:boolean) => {
        setState(prevState => ({...prevState, isEditing}))
    }

    const onFormSubmit = (userBio:IUserBio) => {
        setState(prevState => ({
            ...prevState, 
            isLoading:false,
            userBio,
            isEditing:false
        }));
    }

    return (
        <div id='consult-my-profile' className="consultations-container">
            <h2 className="consultation-header">My Profile</h2>
            {
                state.isLoading ?
                (
                    <Spinner></Spinner>
                ) 
                : 
                (
                    <>
                    {
                        state.userBio ? 
                        (
                            <div>
                                <div className="consultant-image-container">
                                    <div className="consultant-info-container-left">                                        
                                        {
                                            state.userBio && state.userBio.imageLink && state.userBio.imageLink.length > 0 ? 
                                            (
                                                <div className="consultant-my-profile-image-container">
                                                    <img className='profile-image-icon' src={`${imageHostUrl}${state.userBio.imageLink}/consultantProfileImage`} alt="Profile Image" />
                                                </div>
                                            ) 
                                            : 
                                            (
                                                <div className="empty-profile-image"><div>No Image</div></div>
                                            )
                                        }
                                    </div>
                                    <div className="consultant-info-container-right">
                                        {
                                            state.isEditing ? 
                                            (
                                                <div className='consultant-upload-container'>
                                                    <label htmlFor='inputImageUpload' className="upload-btn-container btn-cbit-minor">Change Image</label>
                                                    <ImageUploader 
                                                        setIsLoading={setIsLoading}
                                                        isLoading={state.isLoading}
                                                        updateParentImageUrl={() => {}}
                                                        performAfterUpload={onImageUpload}
                                                        removeImage={removeImage}
                                                    />
                                                </div>
                                                
                                            )
                                            :
                                            (
                                                <button onClick={toggleIsEditing} className="btn-cbit-minor">Edit Profile</button>                                                    
                                            )
                                        }                                        
                                    </div>
                                </div>
                                <div className="consultant-profile-container">
                                    {
                                        state.isEditing ? 
                                        (
                                            <ConsultMyProfileForm 
                                                isLoading={state.isLoading}
                                                userBio={state.userBio}
                                                setIsLoading={setIsLoading}
                                                setIsEditing={setIsEditing}
                                                onFormSubmit={onFormSubmit}
                                            />
                                        ) 
                                        : 
                                        (
                                            <UserProfile userBio={state.userBio}/>
                                        ) 
                                    }             
                                </div>
                            </div>
                        ) 
                        : 
                        (
                            <div>                     
                            </div>
                        )
                    }
                    </>
                )
            }
        </div>
    )
}

export default ConsultationMyProfile;