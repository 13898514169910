import React, {useState, useRef, useCallback, useEffect} from 'react';
import { Input } from 'reactstrap';
import { ILesson } from '../../../models/lesson/Lesson';
import { IModule, Module } from '../../../models/module/Module';
import './ModuleInlineForm.css';

type ModuleItemFormProps = {     
    module: IModule | ILesson;
    placeHolderText:string;    
    onSubmit(module:IModule) : void;
    onBlur(isEditing:boolean) :void;
}

// The module form state type 
type ModuleFormState = {
    module: IModule | ILesson,    
    isLoading: boolean,
    errors: string[] // Make as an object
    textHeight:string
}

/**
 * This component is for inline editing Module and Lesson names in the Admin dashboard
 */
const ModuleInlineForm = (props:ModuleItemFormProps) =>  {
    const placeholderText = props.placeHolderText;

    /**Reference to the input textarea */
   

    /**
     * 
     */
    const defaultState: ModuleFormState = {
        module: props.module,
        isLoading:false,
        errors: [],
        textHeight: ""
    }

    const [state, setState] = useState(defaultState);

    useEffect(() => {
        function onComponentMount() {
            
        }
    }, []);

    const textAreaRef = useCallback(node => {
        if(node !== null) {
            if(node.value)
                node.selectionStart = node.value.length

            setState(prevState => ({
                ...prevState,
                textHeight: node.scrollHeight + 'px'
            }))
        }
    }, []);

    /**
     * When input changes update the state
     * @param e 
     */
    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) : void => {
        
        const inputName : string = e.currentTarget.name;

        const inputValue : string = e.currentTarget.value;

        setState(prevState => ({
            ...prevState,
            module: {
                ...prevState.module,
                [inputName]: inputValue}
        }));
    }

    const onSubmit = async (e: React.KeyboardEvent<HTMLTextAreaElement>) : Promise<void> => {
        
        const moduleName = state.module.name;

        if(moduleName.trim().length <= 0)
            return;

        if(e.which === 13 && !e.shiftKey) {            
            e.preventDefault();
            // despite what your IDE may say the await is needed to stop the flashing of the form or empty content when creating new content
            await props.onSubmit({...state.module as Module});
            
            props.onBlur(false);                            
        }
    }
    
    /**
     * Handles when a user clicks of the inline form
     */
    const handleOnBlur = async () => {                
        const moduleCopy = state.module;
        
        moduleCopy.name = moduleCopy.name.trim();
        // If a user had not typed anything then we still want to submit but with the placeholder text
        if(state.module.name.length <= 0) {
            moduleCopy.name = placeholderText;
        }
        await props.onSubmit({...moduleCopy as Module});

        props.onBlur(false);
    }

    
    return (
        <>        

            <textarea
                rows={1}              
                ref={textAreaRef}     
                onBlur={handleOnBlur}
                className='inline-module-input content-item-title'
                autoFocus
                style={
                    {
                        width: (state.module.name.length + 'ch'),
                        minWidth: (placeholderText.length + 'ch'),  
                        height: state.textHeight                                                        
                    }
                }
                name="name" id="name" placeholder={placeholderText} 
                value={state.module.name} onKeyPress={onSubmit} onChange={onChange}                      
            />            
        </>
    )    
}

export default ModuleInlineForm;