import React, {Children, useEffect, useState} from 'react';
import ReferralNavigationPanel from './ReferralNavigationPanel/ReferralNavigationPanel';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import NavSidebar from "../SocialConnection/components/layout/NavSidebar/NavSidebar";

type ReferralLayoutProps = {
    children: React.ReactNode
}

const ReferralLayout = ({children}: ReferralLayoutProps) => {
// Navigation Panel
    const [isNavOpen, setIsNavOpen] = useState(true);
    const {height, width} = useWindowDimensions();

    const toggle = () => {
        setIsNavOpen(!isNavOpen);
    }

    useEffect(() => {
        // If the width of the device is greater than 1023px, open the navigation panel
        if (width > 1023) {
            setIsNavOpen(true);
        }

        // If the width of the device is less than 1023px, close the navigation panel
        if (width <= 1023) {
            setIsNavOpen(false);
        }

    }, [width]);

    return (
        <div className={`referral-layout ${isNavOpen ? 'isOpen' : ''}`}>
            {/* Navigation Panel */}
            <div className="AccordionContent">
                <NavSidebar toggle={toggle}
                            isNavOpen={isNavOpen}
                            setIsNavOpen={setIsNavOpen}
                            title={'Referral'}
                            links={[
                                {
                                    name: 'My Profile',
                                    path: '/referral/my-profile'
                                },
                                {
                                    name: 'View Referral List',
                                    path: '/referral/list'
                                },
                            ]}
                />
            </div>
            {/* Main content container */}
            <div className="referral-main-content">
                {children}
            </div>
        </div>
    )
}

export default ReferralLayout;