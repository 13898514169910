/**
 * Label that displays aa report icon to be used with a report field
 * @param htmlFor 
 * @returns 
 */
const ReportLabel = ({htmlFor}:{htmlFor:string}) => (
    <label className="report-label" htmlFor={`${htmlFor}`}>
        <i className="report-icon bi bi-flag-fill"></i> 
    </label>
)


export default ReportLabel;
