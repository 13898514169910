import { ILesson, IsInstanceOfLesson } from "../lesson/Lesson";
import { ILessonContent, IsInstanceOfLessonContent } from "../lesson/LessonContent";
import { IModule } from "../module/Module";
import { EditorContentEnum } from "./EditorContentEnum";

/**
 * Returns an enum that determines if the passed in course item is a
 * module, lesson, or is lesson content
 * @param content 
 */
export function GetCourseItemType(courseItem: IModule | ILesson | ILessonContent){
    if(IsInstanceOfLesson(courseItem as ILesson)) {
        return EditorContentEnum.lesson;
    } else if(IsInstanceOfLessonContent(courseItem as ILessonContent)) {
        return EditorContentEnum.lessonContent;
    } else {
        return EditorContentEnum.module;
    }
}