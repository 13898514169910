export default class CloudflareImageHelper {
    public static imageUrl = "https://imagedelivery.net/vvP7bkGThCibwLU377SBtQ/"
    public static variants = {
        public: "public",
        imageThumb: "imagethumb",
        consultantProfileImage: "consultantProfileImage",
        defaultProfileImage: "defaultProfileImage",
        navbarUserImage: "navbarUserImage",
        userProfileImage:"userProfileImage",        
    }
}