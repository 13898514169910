import * as React from "react";

type CourseCompletionProps = {
    onReturnToLessonClick:() => void
}

/**
 * Component for displaying the user have completed the course message
 */
const CourseCompletion = (props:CourseCompletionProps) => {
    const {onReturnToLessonClick} = props;

    return (
        <div className="cbit-dialog">
            <h2 className="cbit-header">
                Congratulations! 
            </h2>
            <div className="cbit-form-group">
                You have completed CBIT Training, you now have unlocked the ability to do your 3 consultation sessions.
            </div>
            <div>
            <div className="cbit-dialog-btn-container">
                <button 
                    onClick={onReturnToLessonClick} 
                    className="btn-cbit-minor"
                >
                    Return To Lesson
                </button>
                <a className="btn-cbit-primary" href="/home">
                    Navigate To Dashboard
                </a>                
            </div>
            </div>
        </div>
    )

}

export default CourseCompletion;