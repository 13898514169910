import { ToastOptions } from "react-hot-toast"

export const toastOptions = {    
    success: {
            style: {
                color: "#155724",
                backgroundColor: "#d4edda",
                borderColor: "#c3e6cb",
            }
    },
    error: {
        style: {
            color: "#721c24",
            backgroundColor: "#f8d7da",
            borderColor: "#f5c6cb",
            // maxWidth:"500px",
        }
    }
    
}