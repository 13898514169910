import { TZDate } from '@toast-ui/calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import { default as Calendar, default as ToastUIReactCalendar } from '@toast-ui/react-calendar';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import { CalendarEventType } from '../../../models/calendar/CalendarEvent';
import { calendarEventsEnum } from '../../../models/calendar/CalendarEventsEnums';
import { calendarViewEnum } from '../../../models/calendar/CalendarViewEnum';
import { Consultation, IConsultation } from '../../../models/consultations/Consultation';
import { ConsultationType } from '../../../models/consultations/ConsultationType';
import TimeHelper from '../../../models/utility/TimeHelper';
import IconLeftChevronWhiteSrc from '../../../resources/icons/icon-chevron-left-white.png';
import IconRightChevronWhiteSrc from '../../../resources/icons/icon-chevron-right-white.png';
import { ApplicationState } from '../../../store';
import { CurrentUserState } from '../../../store/CurrentUser';
import ToolTipWrapper from '../../Utilities/ToolTipWrapper/ToolTipWrapper';
import './ConsultationCalendar.css';
import useWindowDimensions from "../../../hooks/useWindowDimensions";

type consultationCalendarProps = {
    consultations:IConsultation[]
    dateFormat:string
    /**Disables the previous button to not go past the current date */
    prevDisabled:boolean    
    isReadonly:boolean
    updateStartDate:(startDate:Date) => void;
    calendarHeight?:string
    onConsultationStateUpdate?:(consultation:IConsultation) => void    
    renderForm?:(startDate:Date, isEditing:false, isViewDetails:boolean, consultation?:IConsultation) => void
    renderViewItem?:(consultationId:string) => void
    calendarRef?:React.RefObject<ToastUIReactCalendar>
}

type consultationCalendarState = {
    events:CalendarEventType[]
    date:string    
    renderEventForm:any    
}

enum calendarNavigationOpt {
    prev = 0,
    today= 1,
    next = 2,
}

const eventColorByConsultationType = {
    [ConsultationType.OneOnOne]: 'var(--cbit-dark-blue)',
    [ConsultationType.SmallGroup]: 'var(--cbit-orange)',
    [ConsultationType.Seminar]: 'var(--cbit-yellow-green)',
    [ConsultationType.Unknown]: 'var(--cbit-light-gray)',
}

const defaultState:consultationCalendarState = {
    events: [],
    date: moment(new Date(), TimeHelper.momentFormats).toString(),    
    renderEventForm:null,    
}

/**
 * Toast UI calendar for the consultation page
 * @param props 
 * @returns 
 */
const ConsultationCalendar = (props:consultationCalendarProps) => {
    //This is the default calendar id we set on events
    const calendarId = '0';

    const {
            consultations,             
            prevDisabled, 
            isReadonly,             
            calendarHeight, 
            calendarRef, 
            updateStartDate
    } = props;
    
    const [state, setState] = useState(defaultState);

    /**Allows us access to the current logged in user */
    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state )=> state.currentUser);  

    /**
     * Themes allow us to control the look of the calendar
     */
    const theme = {        
        theme: {
            common: {
                pastDay: {
                    color: 'red'
                },
                holiday: {
                    color: 'black'
                },  
                //Because we cant disable gridSelection we are making it transparent
                gridSelection: {
                    backgroundColor: 'transparent',
                    border: 'none',
                },              
            },
            month: {
                holidayExceptThisMonth: {
                    color: 'black'
                },                           
            },
        }
    }

    /**
     * Templates allow control over how you want data to appear in the calendar
     */
    const template = {
        time: function(schedule:any) {            
            return  new Date(schedule.start.d).toLocaleTimeString('en-US', {hour12: true, hour: '2-digit', minute: '2-digit'})  + ' ' + schedule.title ;
        }
    }

    const setStylesForReadOnlyMode = () =>  {
        if(!isReadonly)
            return;
        
        const calendar = document.querySelector(".toastui-calendar-layout");

        calendar?.classList.add("calendar-read-only");
    }    

    /**
     * The only reason this is a separate function is so we cant remove the 
     * mouseleave event when the component unmounts
     * @param toolTip 
     */
    const onCalendarEventMouseLeave = (toolTip:any) => {        
        toolTip.firstChild.innerText = ""; 
        
        toolTip.firstChild.style.visibility = "hidden";
    }

    // Nav Sidebar sizes
    const [navSidebarWidth, setNavSidebarWidth] = useState(0);

    // @ts-ignore
    const {width: windowWidth, height} = useWindowDimensions();

    const GetNavSidebarCompensation = ()=>{
        let width = window.innerWidth;

        if (width > 1280) {
            return 400;
        }
        if(width > 1023){
            return 320;
        }
        return 0
    }

    const GetNavSidebarCompensationNearEdge = (defaultAdjustment:number)=>{
        let width = window.innerWidth;

        if (width > 1280) {
            return defaultAdjustment
        }
        if(width > 1023){
           return defaultAdjustment - 80;
        }
        if(width > 767){
            return defaultAdjustment - 50
        }
        if(width < 767){
            return defaultAdjustment - 10
        }
        return 0
    }

    useEffect(() => {

        if (windowWidth >= 1280) {
            setNavSidebarWidth(400);
        }

        if (windowWidth < 1280) {
            setNavSidebarWidth(320);
        }

        if (windowWidth < 1024) {
            setNavSidebarWidth(300);
        }

        if (windowWidth < 768) {
             setNavSidebarWidth(60);
        }

         return () => {
                setNavSidebarWidth(0);
        }

     },[windowWidth, navSidebarWidth]);


    /**
     * Sets the tooltip when a calendar event is hovered over.
     * Note: The ToastUi calendar does not support hovering, so we have to do it be using js.
     * Also, we need to remove this event listener when to component unmounts
     * @param e 
     */
    const onCalendarEventMouseOver = (e:any) => {
        const toolTip:any = document.querySelector(".cbit-tooltip");

        const el = e.target;
        
        const eventItem = el.closest(".toastui-calendar-weekday-event");
        
        if(eventItem) {
            eventItem.addEventListener('mouseleave', () => onCalendarEventMouseLeave(toolTip), {once:true});

            const {x, width, bottom:eventBottom} = eventItem.getBoundingClientRect();
            const calendar = document.querySelector(".toastui-calendar-month-daygrid");

            const calendarCell = document.querySelector(".toastui-calendar-daygrid-cell");

            if(!calendar || !calendarCell) {
                return;
            }

            const calendarWidth = calendar.clientWidth;

            const calendarCellWidth = calendarCell.clientWidth;
            
            toolTip.hidden = false;
                        
            toolTip.firstChild.innerText = eventItem.innerText;
            
            toolTip.firstChild.style.visibility = "visible";
            let newLeft = 0;
            if(width + x >= calendarWidth) {

                newLeft = (x-GetNavSidebarCompensation())+Math.round(width/2) - (GetNavSidebarCompensationNearEdge(calendarCellWidth)/2);

                Object.assign(toolTip.style, {
                    top: `${eventBottom - 75}px`,
                    left: `${newLeft}px`,
                  });
                  
                  toolTip.firstChild.style.width = `${calendarCellWidth}px`

                  return;
            }
            //Set the toolTip to be under the event item. Make sure the tooltip is set to absolute

            newLeft = (x-GetNavSidebarCompensation())+Math.round(width/2) ;

            Object.assign(toolTip.style, {
                top: `${eventBottom - 75}px`,
                 left: `${newLeft}px`,
              });
        }
    }

    /**
     * On component mount useEffect
     */
    useEffect(() => {        
        function onComponentMount(){
            setCalendarPopUpForm();
            convertConsultationsToEvents();
            setStylesForReadOnlyMode();                        
            document.addEventListener('mouseover', onCalendarEventMouseOver);
        }

        onComponentMount();

        //When the component unmounts we want the remove the event handlers we added
        return () => {
            if(calendarRef)
                calendarRef.current?.getInstance()?.clear();        
            
            document.removeEventListener("mouseover", onCalendarEventMouseOver);

            const eventItem = document.querySelector(".toastui-calendar-weekday-event");

            if(eventItem) {
                eventItem.removeEventListener("mouseleave", onCalendarEventMouseLeave);
            }
        }  
    },[]);

    useEffect(() => {
      function updateEvents() {
        convertConsultationsToEvents();     
      }
      updateEvents();
    }, [consultations]);  

    /**
     * Convert consultations prop into event objects
     * because the calendar can only read event objects
     */
    const convertConsultationsToEvents = () => {
        const events:CalendarEventType[] = [];        

        for(let consultation of consultations) {
            const backgroundColor = eventColorByConsultationType[consultation.type]; 
                
            const [startDate, endDate] = Consultation.HandleDstWithConsultationDate(consultation);

            const event:CalendarEventType = {                
                id: consultation.id,
                calendarId: calendarId,
                title: consultation.name,
                category: 'time',
                start: new TZDate(startDate),
                end: new TZDate(endDate),
                backgroundColor,                
                borderColor:"red"
            }

            events.push(event);
        }

        setState(prevState => ({
            ...prevState,
            events
        }));
        
    }

    /**
     * Sets a popup form for when clicking on a calendar date
     * @returns 
     */
    const setCalendarPopUpForm = () => {
        if(!calendarRef)return;
        const calendar = calendarRef.current?.getInstance();

        if(!calendar)
            return;

        if(!UserStore?.userProfile) 
            return;

        const {renderForm, renderViewItem} = props;

        calendar.on(calendarEventsEnum.selectDateTime, (e) => {      
            calendar.clearGridSelections();   

            const date = e.start;            

            const updatedDate = TimeHelper.getNextHalfHour(date);

            const isBefore = TimeHelper.checkDateHasPast(updatedDate);

            if(isBefore)
                return;
                                                
            if(!renderForm) 
                return;            

            renderForm(e.start, false, false);       
        });

        calendar.on(calendarEventsEnum.clickEvent, (e) => {         
            if(!renderViewItem)
                return;

            const id = e.event.id;       
            
            renderViewItem(id);
        });
    }
    
    /**
     * Handles calendar navigation
     * @param navForward 
     * @returns 
     */
    const handleCalendarNavigation = (navigation:calendarNavigationOpt) => {
        if(!calendarRef) return;
        if(!calendarRef.current)
            return;

        const calendar = calendarRef.current.getInstance();

        if(!calendar)
            return;        
        
        switch (navigation) {
            case calendarNavigationOpt.prev:
                //We do not want to allow users to go back 
                const isSame = moment(new Date(state.date), TimeHelper.momentFormats).isSame(new Date(), 'month');
                
                if(isSame && prevDisabled)
                    return;
                calendar.prev();                
                break;
        
            case calendarNavigationOpt.next:
                calendar.next();
                break;
                
            default:
                calendar.today();                    
                break;
        }   
        
        const calendarStartDate = calendar.getDate().toDate();        

        updateStartDate(calendarStartDate);
        const formattedDateStr = moment(new Date(calendarStartDate), TimeHelper.momentFormats).toString();
        
        setState(prevState => ({
            ...prevState,
            date:formattedDateStr
        }));
    }

    return (
        <div className="calendar-container">
            <div className="availability-date-container">
                <button
                    disabled={moment(new Date(state.date), TimeHelper.momentFormats).isSame(new Date(), 'month')}
                    className='btn-cbit-icon btn-availability-control'
                    onClick={() => handleCalendarNavigation(calendarNavigationOpt.prev)}                                                 
                >
                    <img src={IconLeftChevronWhiteSrc} alt="Left" />
                    
                </button>     
                <div className='calendar-date-range availability-date-range'>{moment(new Date(state.date), TimeHelper.momentFormats).format("MMMM YYYY")}</div>               
                <button  
                    className='btn-cbit-icon btn-availability-control'
                    onClick={() => handleCalendarNavigation(calendarNavigationOpt.next)}
                >
                    <img src={IconRightChevronWhiteSrc} alt="Right" />                        
                </button>
            </div>  
            <div className="consultation-calendar-legend">                
                <span className="one-on-one-legend consultation-legend-item">
                    <span className="consultation-legend-icon"></span>
                    One on One
                </span>
                <span className="small-group-legend consultation-legend-item">
                    <span className="consultation-legend-icon"></span>
                    Small Group
                </span>
                <span className="seminar-legend consultation-legend-item">
                    <span className="consultation-legend-icon"></span>
                    Seminar
                </span>                
            </div>          
            <Calendar                                               
                ref={calendarRef}                       
                height={calendarHeight ? calendarHeight : "calc(100vh - var(--cbit-nav-bar-height) - 286.3px)"}            
                usageStatistics={false}
                useDetailPopup={false}  
                useFormPopup={false}              
                view={calendarViewEnum.month}                                   
                theme={ theme.theme}
                events={state.events}
                month={{isAlways6Weeks: false}}
                template={template}      
                isReadOnly={isReadonly}                  
                gridSelection={{enableDblClick: false, enableClick: true}}                
            />    
            <ToolTipWrapper 
                toolTipText={'default tool tip text'}
            >
            </ToolTipWrapper>     
        </div>
    )
}

export default ConsultationCalendar;