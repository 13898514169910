import { Link } from "react-router-dom";
import { cbitTrainingID } from "../../../models/module/Module";
import userManaSrc from "../../../resources/icons/icon-user-mana.png";
import AdminActionFullItem from "./AdminActionFullItem";
import ContentLog from "./ContentLog";
import "./ContentManagement.css";

const ContentManagement = () => {
    
    return (
        <div className="content-management">
            <div className="content-mana-left cbit-dialog">
                <h1 className="content-mana-header cbit-dashboard-header">Content Management</h1>
                <p className="content-mana-desc">Welcome to the the CBIT Trainer Administrative website! Visit the pages below to manage all CBIT Trainer course materials and resources.</p>
                <div className="content-mana-actions">
                    <div className="admin-action-item">
                        <Link 
                            className='admin-action-link'
                            to={{
                                pathname: "/content-builder",
                                state: {
                                 id:cbitTrainingID
                                }
                            }} 
                        >
                            <div className="admin-action-inner">
                                <div className="admin-action-header cbit-dashboard-header">
                                    Training
                                </div>
                                <div className="admin-action-content">
                                    Build and modify the main CBIT Trainer course content, including lessons, videos, quizzes, titles etc.
                                </div>
                            </div>
                            <div className="btm-banner"></div>
                        </Link>
                    </div>
                    <div className="admin-action-item">
                        <Link 
                            className='admin-action-link'
                            to="/therapist-toolbox-admin"
                        >
                            <div className="admin-action-inner">
                                <div className="admin-action-header cbit-dashboard-header">
                                    Therapist Toolbox
                                </div>
                                <div className="admin-action-content">
                                    Upload and organize supplemental resources such as videos and forms to the CBIT Trainer course.
                                </div>
                            </div>
                            <div className="btm-banner"></div>
                        </Link>
                    </div>
                    {/* <div className="admin-action-item">
                        <Link 
                            className='admin-action-link'
                            to="/consultation/booking"
                        >
                            <div className="admin-action-inner">
                                <div className="admin-action-header cbit-dashboard-header">
                                    CONSULTATION 
                                </div>
                                <div className="admin-action-content">
                                    View and manage the arrangement of consultation sessions between Consultants and Professionals.
                                </div>
                            </div>
                            <div className="btm-banner"></div>
                        </Link>
                    </div> */}
                    <div className="admin-action-item">
                        <Link 
                            className='admin-action-link'
                            to="/consultation-reviews"
                        >
                            <div className="admin-action-inner">
                                <div className="admin-action-header cbit-dashboard-header">
                                    CONSULTATION REVIEW
                                </div>
                                <div className="admin-action-content">
                                    View consultation reviews
                                </div>
                            </div>
                            <div className="btm-banner"></div>
                        </Link>
                    </div>
                    <div className="admin-action-item">
                        <Link 
                            className='admin-action-link'
                            to="/continuing-education/upload-webinars"                        
                        >
                            <div className="admin-action-inner">
                                <div className="admin-action-header cbit-dashboard-header">
                                    CONTINUING EDUCATION
                                </div>
                                <div className="admin-action-content">
                                    View and upload webinar recordings hosted by consultants.
                                </div>
                            </div>
                            <div className="btm-banner"></div>
                        </Link>
                    </div>
                    {/* <div className="admin-action-item">
                        <Link 
                            className='admin-action-link'
                            to="/referral/my-profile"
                        >
                            <div className="admin-action-inner">
                                <div className="admin-action-header cbit-dashboard-header">
                                    REFERRALS
                                </div>
                                <div className="admin-action-content">
                                    Manage and approve of Professionals who completed the CBIT Trainer course and consultation sessions as referrals.
                                </div>
                            </div>
                            <div className="btm-banner"></div>
                        </Link>
                    </div> */}
                    <div className="admin-action-item">
                        <Link 
                            className='admin-action-link'
                            to="/family-support-dashboard"
                        >
                            <div className="admin-action-inner">
                                <div className="admin-action-header cbit-dashboard-header">
                                    FAMILY SUPPORT
                                </div>
                                <div className="admin-action-content">
                                    Populate resources and helpful information for families who are struggling with tics.
                                </div>
                            </div>
                            <div className="btm-banner"></div>
                        </Link>
                    </div>
                    <div className="admin-action-item">
                        <Link 
                            className='admin-action-link'
                            to="/social-connection/admin"
                        >
                            <div className="admin-action-inner">
                                <div className="admin-action-header cbit-dashboard-header">
                                    SOCIAL CONNECT
                                </div>
                                <div className="admin-action-content">
                                    Manage online forum activities and promote popular posts to FAQ section.
                                </div>
                            </div>
                            <div className="btm-banner"></div>
                        </Link>
                    </div>                                                        
                </div>
            </div>
            <div className="content-mana-right">
                <ContentLog/>
                <Link
                    to="/users"
                >
                <AdminActionFullItem 
                    mainContainerClass={"user-management"} 
                    iconSrc={userManaSrc} 
                    title={"USER MANAGEMENT"} 
                    description={"Create, update, and manage CBIT Trainer users and admins."}
                    />
                </Link>
            </div>
        </div>
    )
}

export default ContentManagement;
