import { AxiosError } from "axios";
import { TagTransferObject, _idObject } from "../models/AdditionalVideoContent/AdditionalVideoContent";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { BaseReactService } from "./interfaces/BaseReactService";

export class ContentCategoryService extends BaseReactService {

    constructor()
    {
        super("ContentCategory/");
    }


    public async GetAdditionalVideosForTagList(tagList: TagTransferObject)
    {
        const endpoint = 'ContentCategory/GetAdditionalVideosForTagList/'
        try{
            return await this.Post(tagList, endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async CheckIfTagExistsInCategoryAndUpdate(categoryId:string, tagIdObj:_idObject) {
        const endpoint = 'ContentCategory/CheckIfTagExistsInCategoryAndUpdate/'
        
        try {            
            return await this.Put({id: categoryId}, tagIdObj, endpoint);
        } catch(error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async CheckIfLessonIdExistsInPrivateCategory(lessonTagId:string) : Promise<boolean> {
        const endPoint = 'ContentCategory/CheckIfTagExistsInPrivateCategory';

        try {
            const params = new URLSearchParams();

            params.append("lessonTagId",  lessonTagId);        

            return await this.CustomGet(params,endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
}