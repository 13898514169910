import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import useModal from '../../../../hooks/UseModal';
import { LessonContentType } from "../../../../models/contentTypes/LessonContentType";
import { LessonTypeEnum } from '../../../../models/lesson/LessonType';
import { AdminModule, IAdminModule } from '../../../../models/module/AdminModule';
import { ModuleContentType } from "../../../../models/module/ModuleContent";
import { AdminTreeModule, IAdminTreeModule } from '../../../../models/partialModels/AdminTreeModule';
import { DeepCopy } from '../../../../models/utility/DeepCopy';
import { EditorContentEnum } from '../../../../models/utility/EditorContentEnum';
import { ModuleAction } from '../../../../models/utility/UnSavedCourse/ModuleAction';
import { ReactComponent as ModuleBtnIcon } from '../../../../resources/images/module-button.svg';
import { ApplicationState } from '../../../../store';
import { CurrentUserAccordionState } from '../../../../store/UserModuleAccordionStore';
import CustomModal from '../../../Utilities/CustomModal';
import MultipleChoiceForm from "../../AdminForms/QuizForms/MultipleChoiceForm";
import TrueOrFalseForm from "../../AdminForms/QuizForms/TrueOrFalseForm";
import TextForm from "../../AdminForms/TextForms/TextForm";
import VideoForm from "../../AdminForms/VideoForm";
import ComboLessonMenuOption from './ComboLessonOptions';
import ModuleMenuOptions from './ModuleMenuOptions';
import QuizMenuOptions from './QuizMenuOptions';
import './ContentMenu.css';

/**
 * Props required for the AddContentMenu
 */
type ContentMenuProps = {            
    contentType:EditorContentEnum
    contentList: IAdminModule[]
    isLoading:boolean; //we need to know when to disable buttons from our parent
    currentModuleId: string
    addEmptyCourseItem: (isLesson?:boolean, lessonType?:LessonTypeEnum) => void                
    addToAccordion?:(accordionModule:IAdminTreeModule) => void
    updateUnSavedContent?:(adminModule:IAdminModule, action:ModuleAction) => void
    updateContentList?:(contentList:IAdminModule[])=> void
}

type formState = {
    form:any
}

/**
 * This component renders buttons that toggles modals with forms that allow users
 * to add content (modules, lessons, lesson groups, etc) to the content list
 */
const ContentMenu = (props:ContentMenuProps) => {
    const {isLoading, updateUnSavedContent, addToAccordion, contentType, updateContentList} = props;
    
    const accordionStore = useSelector<ApplicationState, CurrentUserAccordionState | undefined>(state => state.currentUserModuleAccordion);

    const defaultFormState:formState = {
        form:null
    }

    const [formState, setFormState] = useState(defaultFormState);

    const [modalState, setModalState, toggleModal] = useModal(false);

    /**
     * Handles adding content items to the unsaved content while updating the accordion
     * @param adminModule 
     * @returns 
     */
    const handleAddContentItem = (adminModule:IAdminModule) => {
        
        if(!updateUnSavedContent)
            return;

        if(!updateContentList)
            return;

        if(!addToAccordion)
            return;        
        
        const contentListCopy:IAdminModule[] = DeepCopy.copy(props.contentList);

        contentListCopy.push(adminModule);

        updateContentList(contentListCopy);

        updateUnSavedContent(adminModule, ModuleAction.ADD);

        const adminTreeModule = AdminTreeModule.convertAdminModuleToAdminTreeModule(adminModule);

        addToAccordion(adminTreeModule)     
                
    }

    /**
     * Renders all the different types of the content builder menu items
     */
    const renderMenuButtons = () => {
        const {contentType, isLoading, addEmptyCourseItem} = props;        

        switch (contentType) {
            
            case EditorContentEnum.module:

                return (
                    <ModuleMenuOptions 
                        isLoading={isLoading} 
                        isLessonGroup={false}
                        onVideoClick={ () => onClick(LessonContentType.video, "Create Video Lesson")    } 
                        onTextClick={ () => onClick(LessonContentType.text, "Create Text Lesson")} 
                        onQuizClick={ () => addEmptyCourseItem(true, LessonTypeEnum.quiz)} 
                        onComboLessonClick={ () => addEmptyCourseItem(true, LessonTypeEnum.custom)} 
                        onLessonGroupClick={ () => addEmptyCourseItem(false) } 
                    />
                )

            case EditorContentEnum.lessonGroup:
                return (

                    <ModuleMenuOptions 
                        isLoading={isLoading} 
                        isLessonGroup={true}
                        onVideoClick={ () => onClick(LessonContentType.video, "Create Video Lesson")    } 
                        onTextClick={ () => onClick(LessonContentType.text, "Create Text Lesson")} 
                        onQuizClick={ () => addEmptyCourseItem(true, LessonTypeEnum.quiz)} 
                        onComboLessonClick={ () => addEmptyCourseItem(true, LessonTypeEnum.custom)} 
                        onLessonGroupClick={ () => addEmptyCourseItem(false) } 
                    />
                )
                
            
            case EditorContentEnum.lesson:
                //TODO CHECK LESSON TYPE
                return (
                    <ComboLessonMenuOption 
                        isLoading={isLoading} 
                        onVideoClick={ () => onClick(LessonContentType.video, "Create Video Lesson") } 
                        onTextClick={() => onClick(LessonContentType.text, "Create Text Lesson")} 
                        onMultiChoiceClick={ () => onClick(LessonContentType.multipleChoice, "CREATE MULTIPLE CHOICE QUESTION")} 
                        onTrueFalseClick={ () => onClick(LessonContentType.trueOrFalse, "CREATE TRUE OR FALSE QUESTION")}
                    />
                )

            case EditorContentEnum.lessonQuiz:
                
                return (
                    <QuizMenuOptions 
                        isLoading={isLoading} 
                        onMultiChoiceClick={ () => onClick(LessonContentType.multipleChoice, "CREATE MULTIPLE CHOICE QUESTION")} 
                        onTrueFalseClick={ () => onClick(LessonContentType.trueOrFalse, "CREATE TRUE OR FALSE QUESTION")} 
                        onTextClick={() => onClick(LessonContentType.text, "CREATE TEXT CONTENT")  } 
                    />
                )
                
            case EditorContentEnum.parentModule:
                
                return (
                    <>
                        <button 
                            disabled={isLoading}                        
                            className="admin-content-menu-item"
                            onClick={
                                () => addEmptyCourseItem()                            
                            }
                        >
                            <span className='plus-icon'>
                                <ModuleBtnIcon/>
                            </span>
                            <span className='admin-content-menu-item-subheading'>
                                New Chapter
                            </span>
                        </button>
                    </>
                );                           
        }

    }


    /**
     * Creates the form that will be rendered in the modal
     * @param typeID 
     * @returns 
     */
    const renderForm = (typeID: LessonContentType | ModuleContentType) => {
         if(!accordionStore)
            return;

        const currentSelectContentID = accordionStore.selectedModule.id;
        
        const {contentType, contentList} = props;            
            
            const order = contentList.length;

            let questionNumber = 0;
            
            if(contentType === EditorContentEnum.lessonQuiz || contentType === EditorContentEnum.lesson) {
                const lessonContent = contentList.map(contentListItem => (contentListItem.content)) 

                questionNumber = AdminModule.GetModuleQuestionTotal(lessonContent as any[]) + 1;                
            }
            //@ts-ignore    

            const isInsideLesson = contentType === EditorContentEnum.lesson || contentType === EditorContentEnum.lessonQuiz;

           // checking the type and setting the current form based on the type
           switch(typeID){
            case LessonContentType.multipleChoice:
                return React.createElement(
                    MultipleChoiceForm,
                    {
                        order:order,
                        questionNumber,
                        isEditing: false,          
                        branchID: currentSelectContentID,               
                        toggleModal: toggleModal,                    
                        onSubmit: handleAddContentItem
                    });
                
            case LessonContentType.trueOrFalse:
                  return React.createElement(
                    TrueOrFalseForm, 
                    {
                        order:order,
                        questionNumber,
                        isEditing: false,          
                        branchID: currentSelectContentID,               
                        toggleModal: toggleModal,                    
                        onSubmit: handleAddContentItem
                    });
                
            case LessonContentType.video:
                 return React.createElement(VideoForm, 
                    {  
                        order:order,                  
                        isInsideLessonCombo: isInsideLesson,    
                        isEditing: false,          
                        branchID: currentSelectContentID,               
                        toggleModal: toggleModal,                    
                        onSubmit: handleAddContentItem
                    });
                
            case LessonContentType.text:
                return React.createElement(TextForm, 
                    {
                        order:order,
                        isInsideLesson: isInsideLesson,
                        isEditing: false,          
                        branchID: currentSelectContentID,               
                        toggleModal: toggleModal,                    
                        onSubmit: handleAddContentItem
                    });             
        }
    }

    /**
     * Toggles a modal with a form
     * @param typeID 
     * @param modalHeader 
     */
    const onClick = (typeID: LessonContentType | ModuleContentType, modalHeader:string ) => {
        
        const form = renderForm(typeID);

        setFormState(prevState => ({
          ...prevState,
          form  
        }));

        toggleModal(true);
    }

    const menuText = () => {
        const {contentType} = props

        switch (contentType) {            
            
            case EditorContentEnum.module:
                return "Click on the modules below to add lessons to this chapter.";

            case EditorContentEnum.lessonGroup:
                return "Click on the modules below to add lessons to this lesson group.";

            case EditorContentEnum.lesson:
                return "Click on the modules below to add content to this combo lesson.";

            case EditorContentEnum.lessonQuiz:
                return "Click on the modules below to add questions to this quiz.";              
                
            default:
                return "Click on the module below to add a chapter to this course.";            
        }
    }

    return (         
        <>   
            <div className="component-list">
                <h4 className='admin-content-menu-title'>Content Builder</h4>
                <p className='admin-content-menu-text'>
                    {menuText()}
                </p>
                {
                    isLoading ? 
                    (
                        <div className="admin-content-menu-item-loading">
                            <Spinner className='custom-spinner admin-spinner' color="secondary">loading</Spinner>
                        </div>                        
                    ) 
                    : 
                    (

                        renderMenuButtons()
                    )          
                    
                }
            </div>  
            <CustomModal isOpen={modalState} toggle={toggleModal}>
                {formState.form}
            </CustomModal>
        </>
    )
}

export default ContentMenu;