import { AxiosError } from "axios";
import { AdminTreeModule } from "../models/partialModels/AdminTreeModule";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { BaseReactService } from "./interfaces/BaseReactService";

export class AdminModuleService extends BaseReactService {

    constructor() {
        super("AdminModule/");
    }

    public async GetAminAccordionTreeModal(id:string):Promise<AdminTreeModule> {
        const endPoint = "AdminModule/GetAdminAccordionTreeModal/";
        try {            
           return await this.Get({id}, endPoint);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
}