import React, {useEffect, useState} from "react"
import moment from "moment";
import CloudflareImageHelper from "../../../models/utility/CloudflareImageHelper";
import userProfileImage from "../../../resources/images/user-placeholder.png"
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {CurrentUserState} from "../../../store/CurrentUser";
import {IPost} from "../../../models/SocialConnection/Post";
import {IReply} from "../../../models/SocialConnection/Reply";
import {useHistory} from "react-router-dom";
import PostFunctionToolBar from "./toolbar/PostFunctionToolBar";

type postProps = {
    post: IPost,
    updatePost: any,
}

const SocialConnectionPost = ({post, updatePost}: postProps) => {
    const [myPost, setMyPost] = useState(post)

    const imageHostUrl = CloudflareImageHelper.imageUrl;
    const history = useHistory();

    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state) => state.currentUser);

    useEffect(() => {
        setMyPost(post)
    }, [post])

    const getVisibleReplyCount = (replies: any) => {
        replies = myPost?.replies;

        if (replies) {
            return replies.filter((reply: IReply) => reply.isVisible).length;
        }
    }

    const goToPost = (id: string) => {
        history.push('/social-connection/posts/' + id)
    }

    return (
        <React.Fragment key={myPost.id}>
            <div onClick={() => goToPost(myPost?.id)} className={`social-connection-post ${!myPost.isVisible ? 'deleted' : ''}`}>
                {/* Post header */}
                <div className={'social-connection-post-header'} />
                {/* Post body */}
                <div className="social-connection-post-container">
                    {/* Post left side */}
                    <div className="social-connection-post-header-left">
                        <img
                            src={myPost?.userImage ? `${imageHostUrl}${myPost?.userImage}/userProfileImage` : userProfileImage}
                            alt={`${post?.userName ? 's profile image' : 'User profile image'}'`} className={`social-connection-profile-image ${!myPost.isVisible ? 'deleted' : ''}`}/>
                        <h5 className={`social-connection-username ${!myPost.isVisible ? 'deleted' : ''}`}>
                            {myPost?.userName ? myPost?.userName : 'User Name'}
                        </h5>
                    </div>
                    {/* Post middle - content */}
                    <div className="social-connection-post-content">
                        <h4 className={`social-connection-post-content-heading ${!myPost.isVisible ? 'deleted' : ''}`}>
                            {myPost?.name} {!myPost.isVisible ? <span>[Deleted]</span> : ''}
                        </h4>
                        <p className={`social-connection-post-content-text ${!myPost.isVisible ? 'deleted' : ''}`}>                            
                            {myPost?.content}                            
                        </p>
                    </div>
                    {/* Post right side */}
                    <div className="social-connection-post-header-right">
                        <h5 className={`social-connection-date ${!myPost.isVisible ? 'deleted' : ''}`}>
                            Last Post: {moment(myPost?.updatedAt).format('MM/DD/YYYY')}
                        </h5>
                        {UserStore?.isAdmin || UserStore?.isSuperAdmin ? (
                            <h5 className={`social-connection-reply-count ${!myPost.isVisible ? 'deleted' : ''}`}>
                                {myPost?.replies.length > 0 ? myPost?.replies?.length + ' replies' : '0 replies'}
                            </h5>
                        ) : (
                            <h5 className={`social-connection-reply-count ${!myPost.isVisible ? 'deleted' : ''}`}>
                                {myPost?.replies.length > 0 ? getVisibleReplyCount(myPost?.replies?.length) + ' replies' : '0 replies'}
                            </h5>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SocialConnectionPost