import React from "react";
import { CustomContentButton, StandardContentButton } from "./ContentBuilderMenuButtons/ContentBuilderMenuButton";

export type QuizMenuOptionsProps = {
    isLoading:boolean;    
    onMultiChoiceClick:any;
    onTrueFalseClick:any;
    onTextClick:any;        
}

/**
 * Menu options for when inside of a quiz lesson
 * @param props 
 * @returns 
 */
const QuizMenuOptions = (props:QuizMenuOptionsProps) => {
    const {isLoading, onMultiChoiceClick, onTrueFalseClick, onTextClick} = props;
    return (
        
            <>
                <p className="content-label">Question by Format</p>
                
                <StandardContentButton 
                    isLoading={isLoading} 
                    onClick={() => onMultiChoiceClick()} 
                    btnLabel={"Multiple Choice Question"} 
                />

                <StandardContentButton 
                    isLoading={isLoading} 
                    onClick={() => onTrueFalseClick()} 
                    btnLabel={"True or False Question"} 
                />

                <p className="content-label">Miscellaneous Feature</p>
                
                <StandardContentButton 
                    isLoading={isLoading} 
                    onClick={() => onTextClick()} 
                    btnLabel={"Text Instruction"} 
                />
            </>
        
    )
}

export default QuizMenuOptions;

