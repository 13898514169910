import React, {useEffect, useState} from "react";
import ContinuingEducationLayout from "../../components/layout/ContinuingEducationLayout";
import MyRegistrationsItem from "../../components/myRegistrations/MyRegistrationsItem";
import "./MyRegistrations.css";
import {ISeminar} from "../../../../models/consultations/Seminar";
import {ConsultationController} from "../../../../controllers/ConsultationController";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {CurrentUserState} from "../../../../store/CurrentUser";
import {Spinner} from "reactstrap";
import {DeepCopy} from "../../../../models/utility/DeepCopy";

const MyRegistrations = () => {
    // @ts-ignore
    const [myRegistrations, setMyRegistrations] = useState<ISeminar>([]);
    const [loading, setLoading] = useState(true);
    const [currentUserId, setCurrentUserId] = useState("");
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(12);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);

    const cController = new ConsultationController();

    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state) => state.currentUser);

    /**
     * Gets the current user's registrations
     * @returns {Promise<ISeminar[]>}
     */
    const getRegistrations = async () => {
        let data = await cController.GetWebinarsByUser(pageNumber, pageSize, UserStore!.userProfile!.id);

        setMyRegistrations(data);
        setLoading(false);
        return data;
    }

    /**
     * Runs on component mount
     * Gets all users registrations
     */
    useEffect(() => {
        getRegistrations();
    }, []);

    /**
     * Handles the data update
     * @param updatedRegistrations
     * @returns {Promise<void>}
     */
    const handleDataUpdate = async (updatedRegistrations: any) => {
        let oldData = DeepCopy.copy(myRegistrations);

        oldData.map(()=> {
            if(oldData.id === updatedRegistrations.id){
                oldData = updatedRegistrations;
            }
        })

        setMyRegistrations(oldData);
    }

    /**
     * Loads more data
     * @param pageNumber
     * @param pageSize
     * @returns {Promise<ISeminar>}
     */
    const loadMore = async (pageNumber: number, pageSize: number) => {
        let newPage = pageNumber + 1;
        cController.GetWebinarsByUser(newPage, pageSize, currentUserId).then((data) => {
            if (data.length < pageSize) {
                setIsCompleted(true);
            }
            // @ts-ignore
            setMyRegistrations([...myRegistrations, ...data]);
        });
        setPageNumber(newPage);
    }

    return (
        <ContinuingEducationLayout>
            <div>
                <h2 className="my-registrations-header">
                    My Registrations
                </h2>
            </div>
            <div className="my-registrations-container">
                {/* @ts-ignore */}
                {myRegistrations.length > 0 ? myRegistrations.map((seminar: ISeminar) => (
                    <MyRegistrationsItem key={seminar.consultation.id} seminar={seminar} updateRegistrations={handleDataUpdate} />
                )) : (
                    <div className="my-registrations-no-webinars-container">
                        <h3 className="my-registrations-no-webinars-header">
                            No Registrations
                        </h3>
                    </div>
                )}
            </div>

            {myRegistrations.length > 11 && !isCompleted ? (
                <div className="my-registrations-load-more-container">
                    <button className="my-registrations-load-more-button" onClick={() => loadMore(pageNumber, pageSize)}>
                        {loading ? (<Spinner color='primary'/>) : ("Load More")}
                    </button>
                </div>
            ) : (
                <></>
            )}
        </ContinuingEducationLayout>
    )
}

export default MyRegistrations;