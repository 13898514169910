import * as React from "react";
import "./AttendanceLegend.css";

const AttendanceLegend = () => {
    return (
        <div className="attendance-legend consultation-calendar-legend">
            <span><i className="present-icon bi bi-person-check"></i> Present</span>
            <span><i className="absent-icon bi bi-x-lg"></i> Absent</span>
            <span><i className="late-icon bi bi-alarm"></i> Late</span>
            <span><i className="absent-icon bi bi-flag"></i> Reported</span>
        </div>
    )
}

export default AttendanceLegend;