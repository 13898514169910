import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { RecurrenceTypeEnum } from '../../../models/consultations/Consultation';
import './RecurringAlert.css';
import CustomAlert from '../../Utilities/CustomAlert';

type RecurringAlertProps = {     
    headerText:string
    isDelete?:boolean
    toggleAlert:() => void
    onOccurrenceUpdateSubmit:() => Promise<void>
    onRecurringUpdateSubmit:() => Promise<void>
}

enum RecurringUpdateEnum {
    none = 0,
    thisConsultation = 1,
    allConsultations = 2,
}

type RecurringAlertState = {
    updateType:RecurringUpdateEnum
}

const RecurringAlert = (props:RecurringAlertProps) => {  
    const {
        headerText,
        toggleAlert,
        onOccurrenceUpdateSubmit,
        onRecurringUpdateSubmit
    } = props;

    const defaultState:RecurringAlertState = {
        updateType:RecurringUpdateEnum.none
    }

    const [state, setState] = useState(defaultState);
    const [isAlertOpen, SetIsAlertOpen] = useState(false);

    const toggleRecurringAlert = () => {
        SetIsAlertOpen(!isAlertOpen);
    }

    const handleInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        const updateType = parseInt(value);

        if(isNaN(updateType)) {
            return;
        }

        setState(prevState => ({
            ...prevState,
            updateType:updateType
        }));
    }

    const handleSubmit = (e:React.FormEvent) => {
        e.preventDefault();

        if(state.updateType === RecurringUpdateEnum.thisConsultation) {
            onOccurrenceUpdateSubmit();
            return;
        }

        if(state.updateType === RecurringUpdateEnum.allConsultations) {
            if(props.isDelete) {
                onRecurringUpdateSubmit();
                return;
            }
            
            toggleRecurringAlert()
            return;
        }

        toast.error("Please select an option");
    }

    const updateAllConsultations = () => {
        if(state.updateType === RecurringUpdateEnum.allConsultations) {
            onRecurringUpdateSubmit();
            return;
        }
    }

    return (
        <>  
        <form className="recurring-alert-container form" onSubmit={handleSubmit}>
            <h2 className="form-title">{headerText}</h2>
            <div className="recurring-alert-item-container">
                <div className="recurring-alert-item">
                    <input 
                        id="updateThisEvent" 
                        name="updateType"
                        className='recurring-alert-radio' 
                        type="radio"
                        onChange={handleInputChange} 
                        value={RecurringUpdateEnum.thisConsultation}
                    />
                    <label htmlFor="updateThisEvent">This consultation</label>
                </div>
                <div className="recurring-alert-item">
                    <input 
                        id="updateFollowingEvents"
                        className='recurring-alert-radio' 
                        name="updateType"
                        type="radio" 
                        onChange={handleInputChange}
                        value={RecurringUpdateEnum.allConsultations}

                    />
                    <label htmlFor="updateFollowingEvents">All consultation occurrences</label>                    
                </div>   
            </div>        
            <div className='form-btn-container'>
                <button onClick={() => toggleAlert()} type='button' className="btn-cbit-minor">Cancel</button>
                <button type='submit' className="btn-cbit-primary">Submit</button>
            </div>
        </form>
        <CustomAlert 
            header={'Are you sure you want to update all occurrences'} 
            text={'Updating all occurrences requires all registered attendees to re-register, as Zoom resets attendee registrations when changes are made to all occurrences.'} 
            primaryBtnText={'Yes'} 
            secondaryBtnText={'No'} 
            isOpen={isAlertOpen} 
            primaryBtnSubmit={updateAllConsultations} 
            secondaryBtnSubmit={toggleRecurringAlert} 
            toggleAlert={toggleRecurringAlert } 
        />
        </>
    )
}

export default RecurringAlert;