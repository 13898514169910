import React from 'react';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';
import closeIconSrc from "../../resources/icons/icon-close.png";
import './CustomModal.css';

//The state type for the modal
export type ModalState = {
    isOpen:boolean,        
}

//Props to be passed in when using the custom modal
export type ModalProps = {
    isOpen:boolean;
    toggle():void;    
    size:modalSizes;
    className?:string
    preventClosing?:boolean;
    id?:string;
}

export enum modalSizes {
    default = '',
    small = 'sm',
    large = 'lg',
    extraLarge = 'xl'
}

// A custom modal component for rendering components inside a modal because Reactstap sucks
class CustomModal extends React.Component< ModalProps, ModalState> {
    static defaultProps = {size: modalSizes.default}
    constructor(props:any) {
        super(props);
        this.state = this.unloadState;        
    }

    // The default state
    private unloadState : ModalState = {
        isOpen: false,
    }

    /**
     * When the component is updated this function is called
     * @param prevProps the previous props 
     * @param prevState  the previous state
     */
    componentDidUpdate(prevProps:ModalProps, prevState:ModalState) {
        // Checking to see if the props have been updated
        if(prevProps.isOpen !== this.props.isOpen) {
            // If the props have been updated we set the state to the current props
            const isOpen = this.props.isOpen;
            this.setState({isOpen});
        }
    }

    getCustomClass = () => {
        return this.props.className ? this.props.className : "";
    }

    /**
     * Prevents an event from being passed into our modal
     */
    handleModalToggleClick = () => {
        if(this.props.preventClosing) {
            return;
        }

        this.props.toggle();
    }

    public render() {
        return (
            <Modal 
                centered={true} 
                contentClassName={`large-modal ${this.getCustomClass()}`}
                id={this.props.id}
                size={this.props.size} 
                className={`custom-modal`}
                isOpen={this.state.isOpen} 
                toggle={this.handleModalToggleClick}>
                    <button 
                        onClick={this.handleModalToggleClick} 
                        className="btn-cbit-icon"
                    >
                        <img src={closeIconSrc} alt="Close Dialog" />
                    </button>                    
                    {this.props.children}                
            </Modal>
        )
    }

}

export default CustomModal;