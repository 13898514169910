import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { LessonContentController } from '../../../../controllers/LessonContentController';
import { LessonController } from '../../../../controllers/LessonController';
import { ModuleController } from '../../../../controllers/ModuleController';
import { ILesson } from '../../../../models/lesson/Lesson';
import { ILessonContent } from '../../../../models/lesson/LessonContent';
import { LessonTypeEnum } from '../../../../models/lesson/LessonType';
import { AdminModule } from '../../../../models/module/AdminModule';
import { IModule } from '../../../../models/module/Module';
import { IAdminTreeModule } from '../../../../models/partialModels/AdminTreeModule';
import { PartialModuleTreeModel, getModuleTreePath } from '../../../../models/partialModels/PartialModuleTreeModel';
import { EditorContentEnum } from '../../../../models/utility/EditorContentEnum';
import DragGripSrc from '../../../../resources/icons/icon-drag-drop.png';
import eyeClosedIconSrc from '../../../../resources/icons/icon-eye-closed.png';
import eyeOpenIconSrc from '../../../../resources/icons/icon-eye-open.png';
import LessonGroupIconSrc from '../../../../resources/icons/icon-lesson-group.png';
import QuizIconSrc from '../../../../resources/icons/icon-quiz.png';
import TrashIconSrc from '../../../../resources/icons/icon-trash.png';
import { ApplicationState } from '../../../../store';
import { CurrentUserAccordionState } from '../../../../store/UserModuleAccordionStore';
import ModuleInlineForm from '../../AdminForms/ModuleInlineForm';
import './ContentItem.css';
import ModuleItemNavigationBtn from './ContentItemNavigationBtn';
import LessonIconRender from './LessonIconRender';

/**
 * Props type for the content item
 */
 export type ContentItemProps = {    
    index:number;
    content:ILesson | IModule | ILessonContent
    contentType:EditorContentEnum;
    deleteItem: () => void;       
    onSubmit: (content:any) => void,
}

/**
 * Content item state type
 */
export type ContentItemState = {
    isEditing:boolean;
    isVisible:boolean; 
}
 
type ModuleItemState = {
    totalQuestions: number
} & ContentItemState;

//TODO see if need
type ModuleItemProps = ContentItemProps & 
{   
    parentID:string
    onDeleteConfirmSubmit:any
    isLesson:boolean;
    content: IModule | ILesson,
    draggableID:string,
    isEditing: boolean,
    addingNewContent:boolean,
    parentsVisibility:boolean,
    causeRerender:boolean,    
    isNewModule?:boolean
    navigateTo:(content:IAdminTreeModule | IModule | ILesson) => void
    };
 
/**
 * because Module and Lesson visibility properties are different
 * we check to see if the courseItem is a Module or lesson and return its 
 * visibility property
 * TODO make Module and Lesson visibility property the same
 * @param courseItem 
 * @returns 
 */
const getVisibility = (courseItem: IModule | ILesson) : boolean=> {

        return (courseItem as IModule | ILesson).isViewable as boolean;    
}

const moduleController = new ModuleController();    
const lessonController = new LessonController();
const lessonContentController = new LessonContentController();

/**
 * A component for rendering Modules, custom lessons, and lesson groups as an item in the content builder list
 */
// class ModuleItem extends React.Component<ModuleItemProps, ModuleItemState> {
const ModuleItem = (props:ModuleItemProps) => {
    // Default state of the ContentModuleItem
    const defaultState : ModuleItemState = {
        isEditing: props.isEditing,
        isVisible: getVisibility(props.content),
        totalQuestions: 0
    }

    /**
     * Place holder text for the module inline form
     */
    const inlineFormPlaceHolderTxt = React.useMemo(() => {
        const placeHolderText = "Enter a Title for this"
        switch(props.contentType) {
            case EditorContentEnum.lessonQuiz:
                return placeHolderText + " Quiz";

            case EditorContentEnum.lesson:
                return placeHolderText + " Combo Lesson";
            
            default: {
                if((props.content as IModule).parentModule === props.parentID)
                    return placeHolderText + " Module";
                    
                return placeHolderText + " Lesson Group";
            }

        }
    },[props.contentType])

    const [state, setState] = useState(defaultState);

    const accordionStore = useSelector<ApplicationState, CurrentUserAccordionState  | undefined>(state => state.currentUserModuleAccordion);

    const prevParentsVisibility = useRef(props.parentsVisibility);

    const prevCauseRerender = useRef(props.causeRerender);

    useEffect(() => {
        async function componentOnMount() {            
            await getQuestionTotal();
        }        
        componentOnMount();
    }, []);

    useEffect(() => {        
        
        if(prevParentsVisibility.current !== props.parentsVisibility 
            || prevParentsVisibility.current !== props.parentsVisibility 
            && prevCauseRerender.current !== props.causeRerender
        ) {

            setState(prevState => ({
                ...prevState,
                isVisible:props.parentsVisibility
            }));

            prevParentsVisibility.current = props.parentsVisibility;
            prevCauseRerender.current = props.causeRerender
        }
    }, [props.parentsVisibility]);


    /**
     * Adds a new course item to the db
     * @param content 
     * @returns 
     */
    const addContent = async (content:any) => {
        
        const {isLesson} = props;
        
        let newContent = null;

        if(isLesson){
            newContent = await lessonController.CreateLesson(content);
        } else {
            newContent = await moduleController.CreateModule(content);
        }
        
        return newContent;                
    }

    const onSubmit = async (content:any) => {        
        let adminContentItem;

        const {draggableID, onSubmit} = props;
            
        if(contentType === EditorContentEnum.lessonQuiz) {
            (content as ILesson).duration = "0"
        }

        //If we don't have an id then we are creating a new module/combolesson/etc
        if(content.id === "") {
            content = await addContent(content);
        } 


        adminContentItem = {
            key: draggableID,
            content: content,
            isNewModule:props.isNewModule
        }


        onSubmit(adminContentItem);
    }

    /**
     * Updates the component visibility state property 
     * and updates the module in the db
     */
     const setIsVisible = () => {
        //If editing they should not be able to set visibility  
        if(state.isEditing)
            return;

        const visibility = !state.isVisible;

        setState(prevState => ({
            ...prevState,
            isVisible: visibility
        }));
        
        const newMod = {...props.content};

        (newMod as IModule | ILesson).isViewable = visibility;
        

        onSubmit(newMod);
    }

    /**
     * Set whether or not to show the inline edit form
     * @param isEditing 
     */
        const setIsEditing = (isEditing:boolean, event?:React.MouseEvent<HTMLDivElement, MouseEvent>) => {    
            if(event) {
                event.stopPropagation();
            }
                
            setState(prevState => ({
                ...prevState,
                isEditing
            }))
        }

            /**
     * Renders the correct icon for the content type
     */
    const renderContentTypeIcon = () => {
        const {contentType, content, parentID} = props;                            
        
            switch (contentType) {
                case EditorContentEnum.lessonQuiz:
                    return (
                        <div className='content-item-type'>
                            <img src={QuizIconSrc} alt="Quiz Icon" />
                        </div>
                    )
    
                case EditorContentEnum.lesson:
                    const lesson = content as ILesson;
                    return (  
                            <div className='content-item-type'>
                                <LessonIconRender lessonIcon={(lesson as ILesson).lessonIcon} />
                            </div>                                             
                    )

                default:
                    if((content as IModule).parentModule === parentID)
                        return ""                
                    else 
                        return (
                            <div className='content-item-type'>
                                <img src={LessonGroupIconSrc} alt="Lesson Group Icon" />
                            </div>
                        )
            }
    }

    /**
     * Checks the type of module and renders the appropriate item for 
     * the content duration section
     */
    const renderContentDuration = () => {
        const {contentType, content, parentID} = props;
        const {totalQuestions} = state;

        if((content as ILesson).lessonIcon) {
            const lesson = (content as ILesson);
            return (
                <li className='lesson-content-duration'>
                    {`${totalQuestions}/${totalQuestions}`}
                </li>
            )
        }

        switch (contentType) {
            case EditorContentEnum.lessonQuiz:
                return (
                    <li className='lesson-content-duration'>
                        0/{totalQuestions}
                    </li>
                )

            case EditorContentEnum.lesson:
                return (
                        <li className="lesson-content-duration">
                        {
                            (content as ILesson).duration ? (content as ILesson).duration : "00:00"
                        }
                        </li>
                )

            default:
                if((content as IModule).parentModule === parentID)
                    return ""                
                else 
                    return (
                            <li className="lesson-content-duration lesson-group-amount">
                                ({getLessonGroupAmount()})
                            </li>
                    )
        }
    }

    /**
     * Get the amount of children/lessons inside of a lesson group
     * @returns 
     */
    const getLessonGroupAmount = () => {
        const defaultAmount = 0;

        if(!accordionStore)
            return defaultAmount

        const {content} = props;
        
        const treePath:PartialModuleTreeModel[] = [];        
        
        const {parentModule} = accordionStore;            
        
        getModuleTreePath(parentModule, treePath, content.id)
        
        const currentPartialModule = treePath.pop();
        
        if(currentPartialModule?.id !== content.id)
            return defaultAmount
        return currentPartialModule?.children.length ?? 0                    
    }

    /**
     * Add css class to highlight a new empty module
     */
    const highlightNewEmptyModule = () => {
        if(props.isNewModule) {
            return "empty-module-item";
        }

        return "";
    }

    /**
     * Get the total amount of question numbers for a quiz lesson
     */
    const getQuestionTotal = async () => {        
        const {contentType, content} = props;

        //If the id is empty than the module item have not been created.
        if(content.id === "") {
            return;
        }

        // if(contentType !== EditorContentEnum.lessonQuiz) {
        //     return    
        // }

        if((content as ILesson).type === LessonTypeEnum.quiz || (content as ILesson).type === LessonTypeEnum.custom){
            let totalQuestions = 0;    
            const lessonContentArr = await lessonContentController.GetAllLessonContentByLessonId(content.id);
            
            if( Array.isArray(lessonContentArr)) {
                totalQuestions = AdminModule.GetModuleQuestionTotal(lessonContentArr);                
                
                setState(prevState => ({
                    ...prevState,
                    totalQuestions: totalQuestions
                }));
            }
        }
    }

    const {draggableID, index, content, deleteItem, addingNewContent, navigateTo, onDeleteConfirmSubmit, contentType} = props;
    const {isEditing, totalQuestions, isVisible} = state;
    
    return (
            <Draggable
                key={draggableID}
                draggableId={draggableID}
                index={index}
            >
                {(provided, snapshot) => (
                    <div 
                        onClick={() => navigateTo(content)} 
                        className = {
                            `module-content-item content-item
                            ${highlightNewEmptyModule()} 
                            ${snapshot.isDragging ? 'content-item-dragging' : ''}`
                        
                        }
                        ref={provided.innerRef} 
                        {...provided.draggableProps} 
                    >
                        
                        <div 
                            className="drag-handle"  
                            {...provided.dragHandleProps}
                        >
                            {/* <DragGrip />          */}
                            <img src={DragGripSrc} alt="Drag grip" />
                        </div>

                      
                            {renderContentTypeIcon()}
                        

                        <div className="content-item-title" onClick={(e) => setIsEditing(true, e)}>
                        {
                                isEditing ? 
                                (
                                    <ModuleInlineForm   
                                        placeHolderText={inlineFormPlaceHolderTxt}                                         
                                        module={content as IModule | ILesson}
                                        onSubmit={onSubmit} 
                                        onBlur={() => setIsEditing(false) }/>
                                ) 
                                : 
                                (
                                    <>
                                        {content.name }                                                                        
                                    </>                                    
                                )
                            }
                        </div>                                                
                            <ul className='content-item-options'>
                                <li>
                                    <span className="empty-option">
                                        
                                    </span>
                                </li>
                                <li onClick={(e) => {e.stopPropagation(); onDeleteConfirmSubmit(content.name, deleteItem)}}>
                                    <div className="content-action-icon">
                                        <img src={TrashIconSrc} alt="trash button" />
                                    </div>
                                </li>
                                <li >
                                    <div className={`content-action-icon ${isEditing ? "no-click-allowed" : ""}`}>
                                        <ModuleItemNavigationBtn eventKey={content.id} callback={() =>navigateTo(content)} /> 
                                    </div>
                                </li>
                                <li onClick={(e) => {e.stopPropagation(); setIsVisible()}}>
                                    <div className={`content-action-icon-visibility ${isEditing ? "no-click-allowed" : ""}`}>
                                    {
                                        isVisible ? 
                                        (
                                            <img src={eyeOpenIconSrc} alt="visibility button" />
                                            
                                        ) 
                                        : 
                                        (                                                
                                            <img src={eyeClosedIconSrc} alt="visibility button" />
                                        )
                                    }
                                    </div>
                                </li>
                                <>
                                    {
                                        renderContentDuration()
                                    }
                                </>
                            </ul>
                        </div>                    
                )}
            </Draggable>
        )
}

export default ModuleItem;