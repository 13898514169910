import React, { useContext, useEffect, useRef, useState } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";
import { LessonContentController } from "../../../../controllers/LessonContentController";
import { LessonController } from "../../../../controllers/LessonController";
import { ILesson, LessonIconEnum } from "../../../../models/lesson/Lesson";
import { LessonTypeEnum } from "../../../../models/lesson/LessonType";
import { AdminModule } from "../../../../models/module/AdminModule";
import { PartialModuleTreeModel, TreeViewType } from "../../../../models/partialModels/PartialModuleTreeModel";
import IconComboLesson from "../../../../resources/icons/icon-combo.png";
import IconQuiz from "../../../../resources/icons/icon-quiz.png";
import IconText from "../../../../resources/icons/icon-reading.png";
import IconVideo from "../../../../resources/icons/icon-video.png";
import LessonIconRender from "../../ContentBuilder/ContentItems/LessonIconRender";

interface contextAwareProps {
    module: any,
    children: any,
    eventKey: any,
    callback: any,
    isCurrentEventKey:boolean
    isWithinSubLesson?:boolean
}

type lessLevelOneState = {
    lessonType: LessonTypeEnum    
    duration:string
    totalQuestions:number
}

const defaultState:lessLevelOneState = {
    lessonType: LessonTypeEnum.video,
    duration: "00:00",
    totalQuestions:0
}

const lessonController = new LessonController();

const lessonContentController = new LessonContentController();

/**
 * Admin Lesson Accordion Level One for displaying different lesson types
 * Only Quizzes and Combo Lesson can be navigated into 
 * @param param0 
 * @returns 
 */
export const AdminLessonLevelOne: React.FC<contextAwareProps> = ({module,children,eventKey,callback, isCurrentEventKey, isWithinSubLesson}) =>
{
    const [lessonModule, setLessonModule] = useState(defaultState)
    const [isMobile, setIsMobile] = useState<boolean>(false);


    const firstRender = useRef(true);

    const isMounted = useRef(false);

    useEffect(() => {     
        isMounted.current = true;

        // We need to get the lesson type to show icons and do various things         
        async function onComponentMount () {
            if(isMounted.current){}
                // await updateLessonModule();
        }

        onComponentMount();

        return () => {isMounted.current = false};

    }, []);

    useEffect(() => {
        async function onModuleChildrenUpdate() {       
              
            if(firstRender.current) {
                firstRender.current = false;
                return;
            }
            //TODO create new partial view object to hold duration and question total 
            const updatedModule = module.children.pop();            

            if(!updatedModule)
                return;

            if(updatedModule && updatedModule.type !== LessonTypeEnum.quiz) {                                
                setLessonModule(prevState => ({
                    ...prevState,
                    duration:updatedModule.duration,                    
                }));
                return;
            }                        

            if(updatedModule.type !== LessonTypeEnum.quiz) {
                // updateLessonModule();
            }
        }
        
        onModuleChildrenUpdate();        
    },[children]);

    useEffect(() => {

        function onModuleDurationUpdate() {
            const totalQuestions = parseInt(module.duration);

            if(isNaN(totalQuestions))
                return;

            setLessonModule(prevState => ({
                ...prevState,
                totalQuestions
            }));
        }
        
        onModuleDurationUpdate();
    },[module.duration]);

    /**
     * Make a call to the API and update the state, lesson type and duration and total questions
     */
    const updateLessonModule = async () => {
        const lesson : ILesson = await lessonController.GetLesson(module.id);

        let totalQuestions = 0;

        if(lesson.type === LessonTypeEnum.quiz)
            totalQuestions = await getQuizQuestionTotal(lesson.id);
                       
            setLessonModule((prevState:any) => ({
                ...prevState,
                lessonType: lesson.type,                
                totalQuestions
            }));
    }

    /**
     * Get a quiz total amount of questions (skips non questions)
     * @param lessonId 
     * @returns 
     */
    const getQuizQuestionTotal = async (lessonId:string) => {
        
        const lessonContent = await lessonContentController.GetAllLessonContentByLessonId(lessonId);

        const questionTotal = AdminModule.GetModuleQuestionTotal(lessonContent);

        let duration = Number.parseInt(module.duration);

        if(!Number.isInteger(duration))
            duration = 0;

        //update the lesson if duration if somehow the quiz duration was set differently
        if(duration !== questionTotal) {
            
            duration = questionTotal;
            
            const lesson = await lessonController.GetLesson(lessonId);

            if(lesson.type === LessonTypeEnum.custom)
                return questionTotal;

            lesson.duration = duration.toString();
            
            await lessonController.UpdateLesson(lesson);        
        }

        return questionTotal;
    }

    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),

    );
    const decoratedOnClick_empty = () => {return};
    
    // const isCurrentEventKey = activeEventKey === eventKey;

    const mod:PartialModuleTreeModel = module;
    
    /**
     * Get the lesson icon based on the content type
     * @returns 
     */
    const getLessonIcon = () => {
        const moduleType:TreeViewType = module.type;

        switch (moduleType) {
            case TreeViewType.VideoLesson:
                return IconVideo

            case TreeViewType.TextLesson:
                return IconText

            case TreeViewType.QuizLesson:            
                return IconQuiz
                                        
            default:
                return IconComboLesson
        }
    }

    /**
     * Based on the content type the onClick method needs to behave differently
     * This method decides which on click function to use
     */
    const setOnClickFunc = () => {          

        if(module.type === TreeViewType.TextLesson || module.type === TreeViewType.VideoLesson) {
            return decoratedOnClick_empty;
        } else {
            return decoratedOnClick
        }
    }

    /**
     * Set the style of an element to be pointer or 
     * default cursor based on the content type
     */
    const setCursorStyle = () => {        

        if(module.type === TreeViewType.TextLesson || module.type === TreeViewType.VideoLesson) {
            return "default";
        } else {
            return "pointer"
        }
    }   

    /**
     * Removes the background hover effect on non-clickable items
     * @returns 
     */
    const setRemoveBackgroundHover = () => {        

        if(module.type === TreeViewType.TextLesson || module.type === TreeViewType.VideoLesson) {
            return "remove-background-hover";
        }
        return ""
    }

    /**
     * A lesson inside a lesson group
     */
    const lessonInsideGroup = () => {
        return (
            <>
                {isCurrentEventKey? (
                    <div className='sub-lesson-item admin-selected' onClick={setOnClickFunc()}>
                        <div className='admin-lesson-content'>
                            <div className='lesson-type-icon'>
                                <LessonIconRender lessonIcon={module.lessonIcon}/>
                            </div>
                            <div className='admin-lesson-text'>
                                <b>{children}</b>
                            </div>
                            <div className='admin-lesson-duration'>
                                {
                                    module.type === TreeViewType.QuizLesson || module.lessonIcon === LessonIconEnum.QuizIcon ?
                                    (
                                        <span>{module.totalQuestions}/{module.totalQuestions}</span>                                    
                                    ) 
                                    : 
                                    (
                                        <span>{module.duration}</span>                                    
                                    )
                                }
                            </div>
                        </div>
                    </div>
                ):(
                    <div style={{cursor: setCursorStyle()}} className={`sub-lesson-item ${setRemoveBackgroundHover()}`} onClick={setOnClickFunc()}>                    
                        <div className='admin-lesson-content'>
                            <div className='lesson-type-icon'>
                                <LessonIconRender lessonIcon={module.lessonIcon}/>
                            </div>
                            <div className='admin-lesson-text'>
                                {children}                                                            
                            </div>                    
                            <div className='admin-lesson-duration'>
                                {
                                    module.type === TreeViewType.QuizLesson || module.lessonIcon === LessonIconEnum.QuizIcon ?
                                    (
                                        <span>{module.totalQuestions}/{module.totalQuestions}</span>                                                                     
                                    ) 
                                    : 
                                    (
                                        <span>{module.duration}</span>                                    
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }

    /**
     * Updates the isMobile state based on the window size
     */
    const updateMobileStatus = () => {
        if (window.innerWidth <= 1023) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    /**
     * Updates the isMobile state on component mount
     * and adds a listener to update the isMobile state
     * on window resize
     */
    useEffect(() => {

        if (window !== undefined) {
            updateMobileStatus();

            window.addEventListener("resize", updateMobileStatus);
        }

        return () => window.removeEventListener("resize", updateMobileStatus);

    }, [isMobile, window.innerWidth]);

    return(
        <>
            {
                isWithinSubLesson ? 
                (
                    <>
                        {lessonInsideGroup()}
                    </>
                )
                :
                (
                    <>
                        {
                            isCurrentEventKey ? 
                            (
                                <div className='admin-lesson-item admin-selected' onClick={setOnClickFunc()}>

                                    {isMobile ? (
                                        <>
                                            <div className="admin-lesson-content mobile">
                                                <div className="admin-lesson-text-container mobile">
                                                    <div className='lesson-type-icon'>
                                                        <LessonIconRender lessonIcon={module.lessonIcon}/>
                                                    </div>
                                                    <div className='admin-lesson-text'>
                                                        <b>{children}</b>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="admin-icon-container mobile">
                                                <div className='admin-lesson-duration'>
                                                    {
                                                        module.type === TreeViewType.QuizLesson || module.lessonIcon === LessonIconEnum.QuizIcon ?
                                                            (
                                                                <span>{module.totalQuestions}/{module.totalQuestions}</span>
                                                            )
                                                            :
                                                            (
                                                                <span>{module.duration}</span>
                                                            )
                                                    }
                                                </div>
                                            </div>

                                        </>
                                    ) : (
                                        <>
                                            <div className='admin-lesson-content'>
                                                <div className='lesson-type-icon'>
                                                    <LessonIconRender lessonIcon={module.lessonIcon}/>
                                                </div>
                                                <div className='admin-lesson-text'>
                                                    <b>{children}</b>
                                                </div>
                                                <div className='admin-lesson-duration'>
                                                    {
                                                        module.type === TreeViewType.QuizLesson || module.lessonIcon === LessonIconEnum.QuizIcon ?
                                                            (
                                                                <span>{module.totalQuestions}/{module.totalQuestions}</span>
                                                            )
                                                            :
                                                            (
                                                                <span>{module.duration}</span>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )
                            :
                            (
                                <div style={{cursor: setCursorStyle()}} className={`admin-lesson-item ${setRemoveBackgroundHover()}`} onClick={setOnClickFunc()}>
                                    {isMobile ? (
                                        <>
                                            <div className="admin-lesson-content mobile">
                                                <div className='lesson-type-icon'>
                                                    <LessonIconRender lessonIcon={module.lessonIcon}/>
                                                </div>
                                                <div className='admin-lesson-text'>
                                                    {children}
                                                </div>
                                            </div>

                                            <div className="admin-icon-container mobile">
                                                <div className='admin-lesson-duration'>
                                                    {
                                                        module.type === TreeViewType.QuizLesson || module.lessonIcon === LessonIconEnum.QuizIcon ?
                                                            (
                                                                <span>{module.totalQuestions}/{module.totalQuestions}</span>
                                                            )
                                                            :
                                                            (
                                                                <span>{module.duration}</span>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='admin-lesson-content'>
                                                <div className='lesson-type-icon'>
                                                    <LessonIconRender lessonIcon={module.lessonIcon}/>
                                                </div>
                                                <div className='admin-lesson-text'>
                                                    {children}
                                                </div>
                                                <div className='admin-lesson-duration'>
                                                    {
                                                        module.type === TreeViewType.QuizLesson || module.lessonIcon === LessonIconEnum.QuizIcon ?
                                                            (
                                                                <span>{module.totalQuestions}/{module.totalQuestions}</span>
                                                            )
                                                            :
                                                            (
                                                                <span>{module.duration}</span>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )
                        }
                    </>
                )
            }
            
        </>
    );
}
