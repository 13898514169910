import * as React from 'react';
import { ContentCategoryController, privateContentCategoryId } from '../../../../controllers/ContentCategoryController';
import { IContentCategory } from '../../../../models/AdditionalVideoContent/ContentCategory';
import ContentCategoryForm from './ContentCategoryForm';
import './ContentCategoryBuilder.css';
import { DeepCopy } from '../../../../models/utility/DeepCopy';

type ContentCategoryBuilderProps = {

}

type ContentCategoryBuilderState = {
    contentCategories:IContentCategory[]
}


class ContentCategoryBuilder extends React.Component<ContentCategoryBuilderProps, ContentCategoryBuilderState> {    
    private ContentCategoryAPI = new ContentCategoryController();

    private defaultContentCategories:ContentCategoryBuilderState = {
        contentCategories: []
    }

    /**
     *
     */
    constructor(props:any) {
        super(props);   
        this.state = this.defaultContentCategories;   
    }


    async componentDidMount() {
        this.getCategories();
    }

    /**
     * Get all the categories inside the db and set the state
     */
    private getCategories = async () => {
        let contentCategories = await this.ContentCategoryAPI.GetAllCategories();        
        
        let newContentCategories : IContentCategory[] = [];
        if(contentCategories) {
            if(contentCategories.length > 0) {
                newContentCategories = contentCategories
            }
        }

        newContentCategories = newContentCategories.filter(category => category.id !== privateContentCategoryId);

        this.setState(prevState => ({
            ...prevState,
            contentCategories: newContentCategories
        }));
    }

    /**
     * 
     * @param category 
     */
    private updateContentCategories = (newCategory:IContentCategory) => {

        const contentCategories = DeepCopy.copy(this.state.contentCategories);

        contentCategories.unshift(newCategory);
        

        this.setState(prevState => ({
            ...prevState,
            contentCategories
        }));
    }

    /**
     * 
     * @param newCategory 
     * @param index 
     */
    private editContentCategory = (newCategory:IContentCategory, index:number) => {
        const contentCategories = DeepCopy.copy(this.state.contentCategories);

        contentCategories[index] = newCategory;

        this.setState(prevState => ({
            ...prevState,
            contentCategories
        }));

        this.ContentCategoryAPI.UpdateContentCategory(newCategory);
    }

    /**
     * 
     * @param categoryToBeRemoved 
     * @param index 
     */
    private deleteContentCategory = (categoryToBeRemoved:IContentCategory, index:number) => {
        const contentCategories : IContentCategory[] = DeepCopy.copy(this.state.contentCategories);

        let filteredCategories = contentCategories.filter((category) => category.id !== categoryToBeRemoved.id);

        this.setState(prevState => ({
            ...prevState,
            contentCategories:filteredCategories
        }));

        this.ContentCategoryAPI.DeleteContentCategory(categoryToBeRemoved.id);
    }

    public render() {
        
       return (
           <div className="category-builder">
               <h2 className="form-title">Create Categories & Tags</h2>
               <ContentCategoryForm 
                    updateContentCategoryBuilder={this.updateContentCategories}
                    editContentCategoryBuilder={this.editContentCategory}
                    deleteContentCategory={this.deleteContentCategory}
                />
               {this.state.contentCategories.length > 0 && (
                   <ul className='category-container'>                                                                   
                        {
                            this.state.contentCategories.map((category, index) => (
                                <>
                                    <li>
                                        <ContentCategoryForm 
                                            index={index}
                                            editContentCategoryBuilder={this.editContentCategory}
                                            updateContentCategoryBuilder={this.updateContentCategories}
                                            contentCategory={category}
                                            deleteContentCategory={this.deleteContentCategory}
                                        />
                                    </li>
                                </>
                            )
                        )}
                    </ul>
                )}
           </div>           
       ) 
    }
}

export default ContentCategoryBuilder;