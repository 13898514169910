import toast from "react-hot-toast";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { VimeoService } from "../services/VimeoServices";

export const baseImageUrl = "https://imagedelivery.net/vvP7bkGThCibwLU377SBtQ/";

export class VimeoController {
    
    private Vimeo = new VimeoService();
    
    public async GetVimeoDuration(videoUrl:string) {
        try {
            
            //Getting the index of the last / in the url
            const lastIndex = videoUrl.lastIndexOf('/');

            let videoId = videoUrl.substring(lastIndex + 1);
            
            videoId = videoId.trim();

            
            return await this.Vimeo.GetVideoDuration(videoId);

        } catch (error) {
            toast.error("Failed To Get Video Duration");
            ErrorHandler.catchApiError(error as any);
        }
    }
}