import React, {useState} from "react";
import UpcomingWebinarsModal from "./UpcomingWebinarsModal";
import {ISeminar} from "../../../../models/consultations/Seminar";
import moment from "moment";
import {DeepCopy} from "../../../../models/utility/DeepCopy";

type UpcomingWebinarItemProps = {
    seminar: ISeminar,
    updateWebinars: any
}

const UpcomingWebinarItem = ({seminar, updateWebinars}: UpcomingWebinarItemProps) => {

    return (
        <div key={seminar.consultation.id} className="upcoming-webinar-item">
            <div className="upcoming-webinar-item-title-container">
                <h4 className="upcoming-webinar-item-title">
                    {seminar.consultation.name}
                </h4>
            </div>
            <div className='upcoming-webinar-item-info-container'>
                <h5 className="upcoming-webinar-item-info-title">
                    {moment(seminar.consultation.startDate).format("ddd, MMM Do YY")}
                </h5>
                <h5 className="upcoming-webinar-item-info-title">
                    {moment(seminar.consultation.startDate).format('LT')} - {moment(seminar.consultation.endDate).format('LT')} CT
                </h5>
                <h5 className="upcoming-webinar-item-info-title">
                    {seminar.consultant.prefix !== '' ?  seminar.consultant.prefix + ' ' + seminar.consultant.firstName + ' ' + seminar.consultant.lastName : seminar.consultant.firstName + ' ' + seminar.consultant.lastName}
                </h5>
                <h5 className="upcoming-webinar-item-info-title">
                    Free
                </h5>
            </div>
            <div className="upcoming-webinar-item-description-container">
                <p className="upcoming-webinar-item-description">
                    {seminar.consultation.description}
                </p>
            </div>
            <div className="upcoming-webinar-item-btn-container">
                <UpcomingWebinarsModal seminar={seminar} updateWebinars={updateWebinars} />
            </div>
        </div>
    )
}

export default UpcomingWebinarItem;