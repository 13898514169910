import ToastUIReactCalendar from '@toast-ui/react-calendar/*';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { ConsultationController } from '../../../../controllers/ConsultationController';
import { Consultation, emptyConsultation, IConsultation } from '../../../../models/consultations/Consultation';
import { IConsultationSession } from '../../../../models/consultations/ConsultationSession';
import { RecurringConsultationRequest } from '../../../../models/requests/RecurringConsultationRequest';
import { DeepCopy } from '../../../../models/utility/DeepCopy';
import TimeHelper from '../../../../models/utility/TimeHelper';
import { ApplicationState } from '../../../../store';
import { CurrentUserState } from '../../../../store/CurrentUser';
import CustomModal from '../../../Utilities/CustomModal';
import ConsultationCalendar from '../../ConsultationCalendar/ConsultationCalendar';
import ConsultantPlanForm from '../../ConsultationForms/ConsultantPlanForm';
import './AvailabilityManagement.css';
import ConsultantsConsultationSessionInfo from './ConsultantsConsultationSessionInfo';

type AvailabilityManagementState = {
    consultations:IConsultation[]
    isLoading:boolean
    idToDelete:string
    startDate:Date
    calendarEndDate:Date
}

/**
 * State that will be passed in the plan form as props
 */
 type PlanFormPropsState = {
    consultationSession:IConsultationSession | null
    isEditing:boolean
    isReadOnly:boolean
    startDate?:Date
    onSubmit:((consultation:IConsultation) => void) | null
}

type ConsultationSessionInfoModalState = {
    isConsultationSessionOpen:boolean,
    consultationId:string
}

const consultationController = new ConsultationController();

const AvailabilityManagement = () => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    const calendarRef = useRef<ToastUIReactCalendar>(null);

    const defaultState:AvailabilityManagementState = {
        consultations: [],
        isLoading: false,
        idToDelete: "",
        startDate:new Date(),
        calendarEndDate: new Date()
    }

    const defaultPlanFormState:PlanFormPropsState = {
        consultationSession: null,
        isEditing: false,
        isReadOnly: false,    
        onSubmit: null
    }

    const defaultSessionInfoModalState:ConsultationSessionInfoModalState = {
        isConsultationSessionOpen: false,
        consultationId: ''
    }
    
    const [state, setState] = useState(defaultState);
    
    const {consultations, isLoading} = state || {};
    
    const [isModalOpen, setModalState] = useState(false);

    const [planFormProps, setPlanForm] = useState(defaultPlanFormState);
    
    const [sessionInfoModalState, setSessionInfoModalState] = useState(defaultSessionInfoModalState);

    const toggleModal = () => { setModalState(!isModalOpen)};

    const toggleSessionInfoModal = () => {
        setSessionInfoModalState(prevState => ({
            ...prevState, 
            isConsultationSessionOpen:!sessionInfoModalState.isConsultationSessionOpen
        }));
    }

    /**
     * Filters out consultations that have already passed
     * @param consultations 
     * @returns IConsultation[]
     */
    const filterOutPastConsultations = (consultations:IConsultation[]) : IConsultation[] => {        
        const consultationsCopy:IConsultation[] = DeepCopy.copy(consultations);
        
        const filteredConsultations = consultationsCopy.filter(consultation => {     
            const startDate = new Date(consultation.startDate as string);       
            
            if(!TimeHelper.checkDateHasPast(startDate)) {
                return consultation;
            }
        })

        return filteredConsultations;        
    }

    useEffect(() => {
        async function onComponentMount() {
            await getConsultations();            
        }

        onComponentMount();
    }, []);

    useEffect(() => {
        async function onDateNavigation() {
            await getConsultations();            
        }
        onDateNavigation();
    },[state.startDate]);

    /**
     * Get consultations that have already been created by the user
     */
    const getConsultations = async () => {        
        if(!userStore) return;
        if(!calendarRef.current) return;
        
        setState(prevState => ({
            ...prevState,
            isLoading:true
        }));        

        
        const calendarInstance = calendarRef.current.getInstance();

        if(!calendarInstance) return;

        const startDate = calendarInstance.getDateRangeStart().toDate();

        const endDate = calendarInstance.getDateRangeEnd().toDate();   
        
        const consultationSession:IConsultationSession[] = await consultationController.GetConsultantsAvailableConsultationsByDate(
                                                                                        userStore.userProfile.id, startDate, endDate)
        
        let  consultations = consultationSession.map(session => session.consultation);
        
        consultations = filterOutPastConsultations(consultations);

        setState(prevState => ({
            ...prevState,
            consultations:consultations,
            isLoading:false
        }));
    }

    const updateStartDate = (newDate:Date) => {
        setState(prevState => ({
            ...prevState,
            startDate:newDate
        }));
    }

    /**
     * Either add or Update consultations in the state
     * @param consultation 
     */
     const createNewConsultation = async (newConsultation:IConsultation) => {        
        setState(prevState => ({...prevState, isLoading:true}))

        const toastCreateLoadingId = "createConsultationLoading";

        toast.loading("Creating consultation", {id:toastCreateLoadingId});

        const consultationsCopy:IConsultation[] = DeepCopy.copy(consultations);            

        try {        
                const consultation = await consultationController.CreateConsultation(newConsultation);                            

                consultationsCopy.push(consultation);                
        
                setState(prevState => ({
                    ...prevState,
                    consultations: consultationsCopy
                }));
            
                toast.dismiss(toastCreateLoadingId);

                toast.success("Successfully Created Consultation");
            
        } catch (error) {
            
            console.error(error);

            toast.dismiss(toastCreateLoadingId);

            toast.error("Failed To Create Consultation");
        }    
        
        setState(prevState => ({...prevState, isLoading:false}));

        toggleModal();
    }

    const createRecurringConsultations = async (recurrenceRequest:RecurringConsultationRequest) => {
        const toastRecurringLoadId = "toastRecurringLoad";

        setState(prevState => ({...prevState, isLoading:true}));

        toast.loading("Creating Consultations", {id:toastRecurringLoadId});

        try {
            let consultations:IConsultation[] = await consultationController.CreateRecurringConsultations(recurrenceRequest);

            const consultationsCopy:IConsultation[] = DeepCopy.copy(state.consultations);

            consultations = consultationsCopy.concat(consultations);

            setState(prevState => (
                {
                    ...prevState,
                    consultations,
                    isLoading:false
                }));
            
            toast.dismiss(toastRecurringLoadId);

        } catch(error) {
            console.error(error);

            toast.dismiss(toastRecurringLoadId);

            toast.error("Failed To Create Recurring Consultations");
        }

        setState(prevState => ({...prevState, isLoading:false}));
        toggleModal();
    }

    /**
     * Updates a consultation
     * @param updatedConsultation 
     * @returns 
     */
    const updateConsultation = async (updatedConsultation:IConsultation) => {
        const toastUpdateLoadId = "toastUpdateLoad";
        
        setState(prevState => ({...prevState, isLoading:true}));

        toast.loading("Updating consultation", {id: toastUpdateLoadId});        

        const foundIndex = state.consultations.findIndex((consultation) => consultation.id === updatedConsultation.id);
        
        if(foundIndex === -1) {
            toast.dismiss(toastUpdateLoadId)
            toast.error("Consultation Was Not Found");
            return;
        }

        try {
            
            const consultation = await consultationController.UpdateConsultation(updatedConsultation);

            const consultations:IConsultation[] = DeepCopy.copy(state.consultations);

            consultations[foundIndex] = consultation;

            setState(prevState => ({
                ...prevState,
                consultations:consultations
            }));

            toast.dismiss(toastUpdateLoadId);
            toast.success("Successfully Update Consultation")

        } catch (error) {
            //Log this to a db/etc
            console.error(error);
            toast.dismiss(toastUpdateLoadId);
            toast.error("Failed To Update Consultation")
        }        
        setState(prevState => ({...prevState, isLoading:false}));
        toggleModal();
    }

    const updateRecurringConsultation = async (recurringRequest:RecurringConsultationRequest) => {
        const toastUpdateRecurringId = "updateRecurringLoad";

        setState(prevState => ({...prevState, isLoading:true}));

        toast.loading("Updating Consultations", {id:toastUpdateRecurringId});

        try {

            let consultations:Consultation[] = await consultationController.UpdateRecurringConsultation(recurringRequest);

            const meetingId = recurringRequest.recurringConsultation.zoomMeetingLinkId;

            const consultationsCopy:Consultation[] = DeepCopy.copy(state.consultations);

            const filteredConsultations = consultationsCopy.filter(consult => consult.zoomMeetingLinkId !== meetingId);
            
            consultations = filteredConsultations.concat(consultations);

            setState(prevState => ({
                ...prevState,
                consultations,
                isLoading:false
            }));
            toast.success("Successfully updated recurring consultations");
        } catch (error) {
            console.error(error);            
            toast.error("Failed to update recurring consultations");
        }
        toast.dismiss(toastUpdateRecurringId);
        setState(prevState => ({...prevState, isLoading:false}));
        toggleModal();
    }

    // Update a single occurrence of a recurring consultation
    const updateConsultationOccurrence = async (consultationOccurrence:IConsultation) => {
        const toastOccurrenceId = "updateOccurrenceLoad";

        setState(prevState => ({...prevState, isLoading:true}));

        toast.loading("Updating Consultation", {id:toastOccurrenceId});

        try {
            let updatedConsultation:IConsultation = await consultationController.UpdateConsultationOccurrence(consultationOccurrence);
            
            const foundIndex = state.consultations.findIndex((consultation) => consultation.id === updatedConsultation.id);

            const consultations:IConsultation[] = DeepCopy.copy(state.consultations);

            consultations[foundIndex] = updatedConsultation;

            setState(prevState => ({
                ...prevState,
                consultations,
                isLoading:false
            }));

            toast.success("Successfully updated consultation occurrence");

        } catch(error) {
            console.error(error);
            toast.error("Failed to update consultation occurrence")
        }
        toast.dismiss(toastOccurrenceId);
        setState(prevState => ({...prevState, isLoading:false}));
        toggleModal();
    }

    /**
     * Cancel the recurring on a recurring consultation and creates a new normal consultation
     * @param updatedConsultation 
     */
    const cancelRecurringAndCreateConsultation =  async (updatedConsultation:IConsultation) => {
        const toastCancelRecurringLoadId = "cancelRecurringLoad";

        setState(prevState => ({...prevState, isLoading:true}));

        toast.loading("Updating Consultation", {id:toastCancelRecurringLoadId});

        try {
            let newConsultation:IConsultation = await consultationController.CancelRecurringAndCreateConsultation(updatedConsultation);            

            const consultations:IConsultation[] = DeepCopy.copy(state.consultations);
            
            //We need to filter out the recurring occurrences
            const filteredConsultations = consultations.filter(c => c.zoomMeetingLinkId !== updatedConsultation.zoomMeetingLinkId);

            filteredConsultations.push(newConsultation);

            setState(prevState => ({
                ...prevState,
                consultations:filteredConsultations
            }));

        } catch (error) {
            console.error(error);
            console.error("Failed to cancel recurring for this consultation");
        }

        toast.dismiss(toastCancelRecurringLoadId);
        setState(prevState => ({...prevState, isLoading:false}));
        toggleModal();
    }


     /**
      * handles a deletion of a single occurrence out of a recurring consultation
      * or deleting a normal consultation
      */
     const onDeleteConsultation = (id:string) => {
        const consultationsCopy:IConsultation[] = DeepCopy.copy(state.consultations);

        const foundIndex = consultationsCopy.findIndex(c => c.id === id);

        if(foundIndex === -1) {
            toast.error("Can't find consultation occurrence");
            return;
        }

        consultationsCopy.splice(foundIndex, 1);

        setState(prevState => ({
            ...prevState,
            consultations:consultationsCopy
        }));

        toggleSessionInfoModal();
     }

     /**
      * remove all recurring consultations
      * @param meetingId 
      */
     const onDeleteRecurringConsultation = (meetingId:number) => {
        const consultationsCopy:IConsultation[] = DeepCopy.copy(state.consultations);

        const filteredConsultations = consultationsCopy.filter(c => c.zoomMeetingLinkId !== meetingId);

        setState(prevState => ({
            ...prevState,
            consultations:filteredConsultations
        }));

        toggleSessionInfoModal();
     }

     /**
      * Opens the availability form so consultants can create a consultation
      */
    const renderCreateAvailabilityForm = (startDate:Date, isEditing:boolean, isViewDetails:boolean, updateConsultation?:IConsultation) => {
        const consultation  = updateConsultation ? updateConsultation : emptyConsultation;

        setPlanForm(prevState => ({
            ...prevState,            
            ...defaultPlanFormState,            
            startDate,
        }));

        toggleModal();
    }

    /**
     * 
     * @param id 
     */
    const renderUpdateAvailabilityForm = async (consultationSession:IConsultationSession) => {
        
        setPlanForm(prevState => ({
            ...prevState,
            isEditing:true,
            isReadOnly:false,
            consultationSession,            
            startDate: defaultPlanFormState.startDate,
        }));

        toggleModal();
    }

    /**
     * renders the a modal with consultation session info; This is done like this because we need to pass it into the calendar
     * @param id 
     */
    const renderConsultationSessionInfoModal = async (id:string) => {
        setSessionInfoModalState(prevState => ({
            ...prevState,
            isConsultationSessionOpen:true,
            consultationId:id
        }))
    }

    return (
        <>
        <div className="consultations-container">
            <h2 className="consultation-header">
                Manage My Availability
            </h2>
            <div className="availability-management">
                <div className="availability-calendar">
                    <ConsultationCalendar 
                        updateStartDate={updateStartDate}
                        calendarRef={calendarRef}
                        isReadonly={false}
                        prevDisabled={true}
                        dateFormat={'MMMM YYYY'}
                        consultations={consultations}                          
                        renderForm={renderCreateAvailabilityForm}                                                         
                        renderViewItem={renderConsultationSessionInfoModal}
                    />
                </div>
            </div>
        </div>
        {/**Consultation plan form */}
        <CustomModal isOpen={isModalOpen} toggle={toggleModal}>
            {
                userStore && (
                    <ConsultantPlanForm 
                        consultationSession={planFormProps.consultationSession}
                        currentUser={userStore.userProfile}
                        isEditing={planFormProps.isEditing}
                        // isLoading={isLoading}
                        isLoading={false}
                        onSubmit={planFormProps.isEditing ? updateConsultation : createNewConsultation}
                        startDate={planFormProps.startDate}
                        togglePlanForm={toggleModal} 
                        recurringConsultationSubmit={planFormProps.isEditing ? updateRecurringConsultation : createRecurringConsultations}
                        updateRecurringConsultations={updateRecurringConsultation}
                        updateConsultationOccurrence={updateConsultationOccurrence}
                        cancelRecurring={cancelRecurringAndCreateConsultation}
                    />
                )
            }
        </CustomModal>
        <CustomModal isOpen={sessionInfoModalState.isConsultationSessionOpen} toggle={toggleSessionInfoModal}>
            <ConsultantsConsultationSessionInfo 
                consultationId={sessionInfoModalState.consultationId}
                deleteConsultation={onDeleteConsultation}
                deleteRecurringConsultation={onDeleteRecurringConsultation}
                toggleSessionInfo={toggleSessionInfoModal} 
                toggleEditPlanForm={renderUpdateAvailabilityForm}
            />
        </CustomModal>
        </>
    )
}

export default AvailabilityManagement;