import { ILesson } from "../lesson/Lesson";
import { ILessonContent } from "../lesson/LessonContent";
import { ISelectableChoice, SelectableChoice } from "./SelectableChoice";

/**
 * Fields required for a multipleChoiceContent object
 */
export interface IMultipleChoiceContent {
     questionText : string;
     questionNumber : string;
     choices : ISelectableChoice[] ;
     hasMultipleAnswers : boolean;
     completed:boolean;
}

/**
 * Multiple Choice Content class that is used for both True/False and normal multiple choice questions
 */
export class MultipleChoiceContent implements IMultipleChoiceContent {
     questionText  = '';
     questionNumber = '';
     choices : ISelectableChoice[] = [];
     hasMultipleAnswers:boolean = false; // Because we are only doing multiple choice questions for now\
     completed:boolean;


     constructor(multipleChoiceContent : IMultipleChoiceContent = {
          questionText:'',
          questionNumber: '',
          choices: [new SelectableChoice()],
          hasMultipleAnswers: true,
          completed:false,
         
     }) {
          this.questionText = multipleChoiceContent.questionText;
          this.questionNumber = multipleChoiceContent.questionNumber;
          this.choices = multipleChoiceContent.choices;
          this.hasMultipleAnswers = multipleChoiceContent.hasMultipleAnswers;         
          this.completed = multipleChoiceContent.completed;
     }

     /**
      * Removes white space from the beginning and end of the choices
      * @param choices 
      */
     public static TrimChoicesWhiteSpace = (choices:ISelectableChoice[]) => {
          return choices.map(choice => ({...choice, text: choice.text.trim()}))
     }

     /**
      * Gets the total amount of questions in a Lesson
      */
     public static GetTotalQuestion = (contentItemList : ILessonContent[]) => {
          
          let totalQuestions = 0;
          
          // Checking if the lesson have lesson Content
          if(contentItemList) {
          
               // Get an array of question items by filtering the contentItemList
               for(let item of contentItemList){
                    
                    if(MultipleChoiceContent.checkIfQuestion(item)){
                         totalQuestions += 1
                    }
               };     
          }

          return totalQuestions;

     }

     /**
      * Checks if an item is a multiple/TF question
      * @param contentItem 
      */
     public static checkIfQuestion = (contentItem : ILessonContent) => {
          // If the multiple choice property is null then we know its not a question item otherwise it is a question
          return contentItem.multipleChoiceProperties != null
           
     }
     
     /**
      * Swap the question number between two questions
      * @param questionA source 
      * @param questionB destination
      */
     public static swapQuestionNumbers = (questionA: ILessonContent, questionB:ILessonContent) => {
          //Checking if both questions are questions
          if(MultipleChoiceContent.checkIfQuestion(questionA) && MultipleChoiceContent.checkIfQuestion(questionB)) {
               // Because we are already checking if these are questions we can use the ! non-null assertion operator
               const aQuestNumber : string = questionA.multipleChoiceProperties!.questionNumber
               
               const bQuestNumber = questionB.multipleChoiceProperties!.questionNumber

               //Performing the swap
               questionA.multipleChoiceProperties!.questionNumber = bQuestNumber;

               questionB.multipleChoiceProperties!.questionNumber = aQuestNumber;

          }

     }
}
