import userProfileImage from "../../../resources/images/user-placeholder.png";
import moment from "moment/moment";
import React, {useState} from "react";
import PostFunctionToolBar from "./toolbar/PostFunctionToolBar";
import {IPost} from "../../../models/SocialConnection/Post";
import {IReply} from "../../../models/SocialConnection/Reply";
import CloudflareImageHelper from "../../../models/utility/CloudflareImageHelper";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {CurrentUserState} from "../../../store/CurrentUser";
import {SocialConnectionController} from "../../../controllers/SocialConnectionController";

type SocialConnectionReplyItemProps = {
    post: IPost,
    reply: IReply,
    replyIndex: number,
    updatePost: any,
}

const SocialConnectionReplyItem = ({post, reply, replyIndex, updatePost}: SocialConnectionReplyItemProps) => {
    const [isEditing, setIsEditing] = useState(false);
    const [content, setContent] = useState(reply.content);

    const imageHostUrl = CloudflareImageHelper.imageUrl;

    const handleChange = (e: any) => {
        const {name, value} = e.target;

        if (name === 'content') {
            setContent(value);
        }
    }

    const handleEditReply = async (postId: string) => {
        const sConnectionController = new SocialConnectionController();

        let reply = {
            ...post.replies[replyIndex],
            content: content,
        }

        return await sConnectionController.EditReply(postId, reply).then((data) => {
            updatePost({
                ...post,
                replies: post.replies.map((reply, index) => {
                    if (index === replyIndex) {
                        return {
                            ...reply,
                            content: content,
                        }
                    } else {
                        return reply;
                    }
                })
            })
            setIsEditing(false);
        });
    }

    return (
        <div key={replyIndex} className="social-connection-single-post-reply-container">
            <div className={`social-connection-single-post-reply ${!post.replies[replyIndex].isVisible ? 'deleted' : ''}`}>
                {/* User Image */}
                <div className="social-connection-single-post-reply-user-image">
                    <img src={post.replies[replyIndex].userImage ? `${imageHostUrl}${reply.userImage}/userProfileImage` : userProfileImage} alt="User profile image"
                         className={`social-connection-single-post-user-image-reply ${!post.replies[replyIndex].isVisible ? 'deleted' : ''}`}/>
                </div>
                {/* Reply content */}
                <div className="social-connection-single-post-reply-body">
                    <div className="social-connection-single-post-reply-header">
                        <h5 className={`social-connection-single-post-reply-username ${!post.replies[replyIndex].isVisible ? 'deleted' : ''}`}>
                            {reply.userName ? reply.userName : 'User Name Not Found'}
                        </h5>
                        <h5 className={`social-connection-single-post-date ${!post.replies[replyIndex].isVisible ? 'deleted' : ''}`}>
                            {moment(reply.createdAt).format('MM/DD/YYYY')} {!post.replies[replyIndex].isVisible ? <span>[Deleted]</span> : ''}
                        </h5>
                    </div>
                    {isEditing ? (
                        <div className="social-connection-editing-textarea-container">
                            <textarea className='social-connection-editing-textarea' name='content' value={content} onChange={handleChange} />
                        </div>
                    ) : (
                        <p className={`social-connection-single-post-reply-text ${!post.replies[replyIndex].isVisible ? 'deleted' : ''}`}>{reply.content}</p>
                    )}

                    {isEditing && (
                        <div className="social-connection-edit-post-container">
                            <button className='btn-cbit-primary btn-social-connection-edit-post'
                                    onClick={() => handleEditReply(post.id)}>
                                Save
                            </button>
                            <button className='btn-cbit-primary btn-social-connection-cancel-edit-post'
                                    onClick={() => setIsEditing}>
                                Cancel
                            </button>
                        </div>
                    )}

                </div>

                {/* User Toolbar */}
                <PostFunctionToolBar post={post}
                                     updatePost={updatePost}
                                     replyIndex={replyIndex}
                                     isPostPage={true}
                                     isEditing={isEditing}
                                     setIsEditing={setIsEditing}
                />
            </div>
        </div>
    )
}

export default SocialConnectionReplyItem;