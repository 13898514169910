import { AxiosError } from 'axios';
import { IModule, PartialModuleType } from "../models/module/Module";
import { AdminTreeModule, IAdminTreeModule } from '../models/partialModels/AdminTreeModule';
import { PartialModuleTreeModel, TreeViewType } from "../models/partialModels/PartialModuleTreeModel";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { LessonService } from "../services/LessonService";
import { ModuleService } from "../services/ModuleService";
import moment from "moment/moment";
import { StringHelper } from '../models/utility/StringHelper';
import { LessonIconEnum } from '../models/lesson/Lesson';

// Class for interacting with the EduTrainer API Module Endpoints
export class ModuleController{

    constructor(){
        this.ModService = new ModuleService()
        this.LessonService = new LessonService();
    }

    private ModService:ModuleService;
    private LessonService:LessonService;
    
    // Sends a POST request to create a module
    public async CreateModule(moduleData : IModule) 
    {
       try{
           moduleData.createdAt =moment(new Date()).toISOString();
           moduleData.updatedAt =moment(new Date()).toISOString();
           return this.ModService.Post(moduleData);
       }catch(error){
           return ErrorHandler.catchApiError((error as AxiosError))
       }
    }

    // sends a GET request to get an array of modules
    public async GetModule(id: string)
    {
        try{
            return await this.ModService.Get({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }
    
    public async GetModuleWithChildren(id:string){
        try{
            let module:IModule = await this.ModService.Get({id});
            if(module != null){
                
                let childModules:PartialModuleType[] = await this.ModService.GetModulesWithParentId({id});
                let lessonModules:PartialModuleType[] = await this.LessonService.GetLessonsWithParentId({id});
                module.combinedList = childModules;
                if(module.combinedList)
                    module.combinedList = module.combinedList.concat(lessonModules);
                
            }
            return module;
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }
    
    public async GetModuleDepthById(id:string){
        try{
            let value = await this.ModService.GetModuleDepthById({id});
            return value;
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }
    
    public async GetModules()
    {
        try{
            return this.ModService.GetAll();
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }
    
    public async GetAvailableModulesByParentModule(id:string){
        try{
            return this.ModService.GetAvailableModulesByParentModule({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    };

    public async GetDeletedModules()
    {
        try {
            return this.ModService.GetDeletedModules();
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    public async GetPartialModuleByParentId(id:string): Promise<PartialModuleType[]>{
        try{
            const modules: IModule[] = await this.ModService.GetModulesWithParentId({id});
            const partialModules = modules.map((module)=>
                ({
                    id: module.id,
                    name: module.name,
                    description: module.description,
                    isLesson:false,
                    order: module.order
                }))

            return partialModules;
            
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }
    //
    // public async GetPartialTreeViewForModuleId(id:string): Promise<PartialModuleTreeModel>{
    //     try{
    //         const mod:IModule =await this.ModService.Get({id});
    //         const partial =
    //             ({
    //                 id: mod.id,
    //                 originalDocumentId:mod.id,
    //                 name : mod.name,
    //                 isOpen: false,
    //                 children:[],
    //                 description: mod.description,
    //                 order: mod.order,
    //                 type: TreeViewType.Branch,
    //                 isComplete: false,
    //                 isViewable:false,
    //                 isOptional:false,
    //                 isEditable:false,
    //                 isDisabled:false,
    //                 isNext:false,
    //                 time:""
    //             })
    //
    //         return partial;
    //     }catch(error){
    //         return ErrorHandler.catchApiError((error as AxiosError))
    //     }
    // }
    //
    // public async GetPartialTreeViewModulesByParentId(id:string): Promise<PartialModuleTreeModel[]>{
    //     try{
    //         const modules:IModule[] =await this.ModService.GetModulesWithParentId({id});
    //         const partials = modules.map((mod)=>
    //             ({
    //                 id: mod.id,
    //                 originalDocumentId:mod.id,
    //                 name : mod.name,
    //                 isOpen: false,
    //                 children:[],
    //                 description: mod.description,
    //                 order: mod.order,
    //                 type: TreeViewType.Branch,
    //                 isComplete: false,
    //                 isViewable: mod.isViewable ? mod.isViewable : false,
    //                 isOptional:mod.isOptional ? mod.isOptional : false,
    //                 isEditable:mod.isEditable ? mod.isEditable : false,
    //                 isDisabled:mod.isDisabled ? mod.isDisabled : false,
    //                 isNext:false,
    //                 time:""
    //             }))
    //
    //         return partials;
    //     }catch(error){
    //         return ErrorHandler.catchApiError((error as AxiosError))
    //     }
    // }
    
    /**
     * Get a parent module's children and convert them to an array of AdminTreeModules
     * @param id 
     * @returns 
     */
    public async GetAdminTreeModuleByParentId(id:string): Promise<IAdminTreeModule[]> {
        try {
            const modules:IModule[] = await this.ModService.GetModulesWithParentId({id});

            return modules.map((mod) => new AdminTreeModule({
                ...mod,
                type: TreeViewType.Branch,
                parentId: mod.parentModule ?? "",
                children: [],                
                duration: "",                
                isViewable:mod.isViewable ? mod.isViewable : false,
                totalQuestions: 0,
                lessonIcon: LessonIconEnum.Unknown
            }))
            
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }
    
    public async GetModulesWithParentId(id:string) {
        try{
            return this.ModService.GetModulesWithParentId({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }

    /**
     * Get the topmost modules
     */
    public async GetParentModules() {
        try {
            const response:IModule[] = await this.ModService.GetAll();            
            const parentModules = response.filter((module) => module.parentModule == null);
            return parentModules;            
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }

    public async UnlockUserLessons(id:string)
    {
        const endpoint = 'Module/UnlockNextLesson/'
        try
        {
            return this.ModService.Get({id},endpoint);
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    // sends a GET request to get an individual module when given an ID
    // TODO: Combine this with GetModules
    // static async GetModuleWithID(moduleID : string) : Promise<IModule | never> {
    //     const endPoint = `/Module/Get/${moduleID}`;

    //     try {
            
    //         const response : AxiosResponse<IModule> = await axios.get(`${this.baseUrl}${endPoint}`);
            
    //         return response.data;

    //     } catch (error) {
    //         return this.handleError((error as AxiosError));
    //     }
    // }

    //
    public async UpdateModule(obj : IModule) 
    {
        try{
            let id = obj.id;
            const params = {id}
            obj.updatedAt = moment(new Date()).toISOString();
            await this.UpdateLastModified(id);
            return await this.ModService.Put(params,obj);
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async UpdateLastModified(id:string) {
        try {
            if(id == null || StringHelper.IsNullOrWhiteSpace(id)) {
                return;
            }

            return await this.ModService.UpdateLastModified({id});
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async DeleteModule(id : string) 
    {
        try{
            return await this.ModService.Delete({id});
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    public async DeleteModuleSoftly(id:string)
    {
        try {
            return await this.ModService.DeleteModuleSoftly({id});
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async RestoreDeletedModule(id:string)
    {
        try {
            return await this.ModService.RestoreDeletedModule({id});
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
    // Get an array of modules and extract only partial amount of information from modules
    public async GetPartialModules() : Promise<PartialModuleType[] | never> 
    {
        // Get an array of modules
        try{
            const modules: IModule[] = await this.ModService.GetAll();
            const partialModules = modules.map((module)=>
                ({
                    id: module.id,
                    name: module.name,
                    description: module.description,
                    isLesson:false,
                    order: module.order,
                }))

            return partialModules;
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Checks if a module differs from its published version
     * @param id 
     */
    public async CheckModuleAndPublishedModuleChanges(id:string):Promise<boolean>{
        try {
            const res = this.ModService.CheckModuleAndPublishedModuleChanges(id);
            return res;
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
}


