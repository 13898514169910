import React, {useEffect, useState} from 'react';
import toast from 'react-hot-toast';
import {ContentCategoryController} from '../../controllers/ContentCategoryController';
import {ContentCategory} from '../../models/AdditionalVideoContent/ContentCategory';
import {AdditionalContentService} from '../../services/AdditionalContentService';
import TherapistSupportPanel from './TherapistSupportPanel';
import TherapistSupportView from './TherapistSupportView/TherapistSupportView';

import {sortModule} from '../../models/utility/SortModule';
import './TherapistSupport.css';
import TherapistSupportLayout from "./layout/TherapistSupportLayout";

const contentCategoryController = new ContentCategoryController();

type therapistSupportState = {
    selectedContentCategory: ContentCategory | null | undefined,
    selectedTag: string;
    contentCategories: ContentCategory[]
    categoryForDelete: ContentCategory | null
}

const TherapistSupport = () => {
    const defaultState: therapistSupportState = {
        selectedContentCategory: null, selectedTag: "", contentCategories: [], categoryForDelete: null,
    }

    const [state, setState] = useState(defaultState);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function onComponentMount() {

            await getContentCategories();

            new AdditionalContentService().GetContentGroupByTagIds(["Tick"])
        }

        onComponentMount();
    }, []);

    /**
     * Get public content categories from the API and update the state
     */
    const getContentCategories = async () => {
        const categories = await contentCategoryController.GetAllPublicCategories();

        sortModule(categories, false)

        setState(prevState => ({
            ...prevState, contentCategories: categories, selectedContentCategory: categories[0]
        }));
    }

    /**
     * Sets a main or selected content category, so we can know which category tags to show
     * @param id
     * @returns
     */
    const setSelectedContentCategory = (id: string) => {
        const selectedCategory = state.contentCategories.find(category => category.id === id);

        if (!selectedCategory) {
            toast.error("Could Not Retrieve Category");
            console.error("Content Category: " + id + " Could Not Be Found");
            return;
        }

        setState(prevState => ({
            ...prevState, selectedContentCategory: selectedCategory
        }));
    }

    return (
        <TherapistSupportLayout contentCategories={state.contentCategories}
                                selectedContentCategory={state.selectedContentCategory}
                                selectContentCategory={setSelectedContentCategory}
        >
            {state.selectedContentCategory == null ? (
                <h2 className="category-title">No Categories Have Been Created</h2>
            ) : (
                <>
                    <TherapistSupportView
                        selectedContentCategory={state.selectedContentCategory}
                    />
                </>
            )}
        </TherapistSupportLayout>
    )
}

export default TherapistSupport;