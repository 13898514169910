import React, {useEffect, useState} from "react";
import {SocialConnectionController} from "../../../../controllers/SocialConnectionController";
import DOMPurify from "dompurify";

type adminSearchProps = {
    setPosts: any,
    isVisible: boolean | null,
    setIsVisible: any,
    isReported: boolean | null,
    setIsReported: any,
    setKeyword: any,
    keyword: string,
}

const AdminSearch = ({setPosts, isVisible, setIsVisible, isReported, setIsReported, setKeyword, keyword}: adminSearchProps) => {
    const [status, setStatus] = useState('Reported (Visible)');

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const sController = new SocialConnectionController();

        let cleanedInput = DOMPurify.sanitize(keyword);

        try {
            await sController.AdminSearchPosts(cleanedInput, isVisible, isReported).then((res: any) => {
                setPosts(res);
            })
        } catch (error) {
            console.error(error);
        }
    }

    const handleFilterChange = async (e: any) => {
       setStatus(e.target.value);
        if (e.target.value === 'Reported (Visible)') {
            setIsVisible(true);
            setIsReported(true);
        }
        if (e.target.value === 'Reported (Not Visible)') {
            setIsVisible(false);
            setIsReported(true);
        }
        if (e.target.value === 'Visible') {
            setIsVisible(true);
            setIsReported(null);
        }
        if (e.target.value === 'Not Visible') {
            setIsVisible(false);
            setIsReported(null);
        }

    }

    return (
        <div className="social-connection-search-container admin-search">
            <form onSubmit={handleSubmit}>
                <div className="social-connection-search-left">
                    <select name="status" id="status" onChange={handleFilterChange} value={status}>
                        <option value={'Reported (Visible)'}>Reported (Visible)</option>
                        <option value={'Reported (Not Visible)'}>Reported (Not Visible)</option>
                        <option value={'Visible'}>Visible</option>
                        <option value={'Not Visible'}>Not Visible</option>
                    </select>

                </div>
                <div className="social-connection-search-center">
                    <input type="search" name="keyword" id="keyword" placeholder="Search" onChange={handleSearchChange}
                           value={keyword}/>
                </div>
                <div className="social-connection-search-right">
                    <button type="submit" className='btn-cbit-primary btn-social-connection-search'>
                        <span>
                            Search
                        </span>
                        <i className="bi bi-search search-icon"/>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default AdminSearch