export default class ArrayHelper {

    /**
     * Group an array of values by a passed in key and
     * return an array grouped by the key
     * @param arr 
     * @param key 
     * @returns 
     */
    static groupBy(arr:any[], key:string) {
        return arr.reduce((acc, currVal) => {
            (acc[currVal[key]] = acc[currVal[key]] || []).push(currVal);
            return acc;
        }, {})
    }
}