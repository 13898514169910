import React, {useEffect, useState} from "react"
import NavSidebar from "../../../SocialConnection/components/layout/NavSidebar/NavSidebar";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

type InboxLayoutProps = {
    children: React.ReactNode
}

const InboxLayout = ({children}: InboxLayoutProps) => {
    const [isNavOpen, setIsNavOpen] = useState(true);
    const {height, width} = useWindowDimensions();

    const toggle = () => {
        setIsNavOpen(!isNavOpen);
    }

    useEffect(() => {
        // If the width of the device is greater than 1023px, open the navigation panel
        if (width > 1023) {
            setIsNavOpen(true);
        }

        // If the width of the device is less than 1023px, close the navigation panel
        if (width <= 1023) {
            setIsNavOpen(false);
        }

    }, [width]);

    return (
        <div className={`inbox-layout ${isNavOpen ? 'isOpen' : ''}`}>
            {/* Navigation Panel */}
            <div className="AccordionContent">
                <NavSidebar toggle={toggle}
                            isNavOpen={isNavOpen}
                            setIsNavOpen={setIsNavOpen}
                            title={'Inbox'}
                            links={[
                                {
                                    name: 'Notifications',
                                    path: '/inbox'
                                },
                            ]}
                />
            </div>
            {/* Main content container */}
            <div className="inbox-main-content">
                {children}
            </div>
        </div>
    )
}

export default InboxLayout