import React, {useEffect, useState} from "react"
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {INotification} from "../../../../models/Inbox/Notification";
import UseModal from "../../../../hooks/UseModal";
import {MdOutlineMarkunread, MdOutlineMarkEmailRead, MdDeleteOutline, MdOutlineOpenInNew} from "react-icons/md";
import {NotificationController} from "../../../../controllers/NotificationController";

type NotificationModalProps = {
    notification: INotification,
    handleModalOpen: () => void,
    modalState: boolean,
    updateNotifications: any
}

const NotificationModal = ({notification, handleModalOpen, updateNotifications}: NotificationModalProps) => {
    const [modalState, setModalState, toggleModal] = UseModal(false);
    const [isModalOpen, setIsModalOpen] = useState(modalState);
    const [isModalDeleting, setIsModalDeleting] = useState(false);

    const handleModalDeleteOpen = () => {
        setIsModalOpen(true)
        setModalState(true)
        setIsModalDeleting(true)
    }

    const handleModalDeleteClose = () => {
        setIsModalOpen(false)
        setModalState(false)
        setIsModalDeleting(false)
    }

    useEffect(() => {
        return () => {
            document.removeEventListener('click', handleModalDeleteClose)
        }
    }, [])

    const stopPropagation = (e: any) => {
        e.stopPropagation()
    }

    const handleDeleteNotification = async () => {
        const nController = new NotificationController();
        try {
            const data = await nController.DeleteNotification(notification.id);
            updateNotifications(notification)
            setIsModalOpen(false)
            setModalState(false)
            setIsModalDeleting(false)

            return data;
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <>
            <div className='notification-item-toolbar' onClick={stopPropagation}>
                <button className="notification-item-toolbar-btn" onClick={handleModalDeleteOpen}>
                    <MdDeleteOutline />
                </button>
            </div>
            {isModalOpen && isModalDeleting && (
                <Modal isOpen={isModalOpen} size={'lg'} centered={true} key={notification.id}>
                    <ModalHeader toggle={handleModalDeleteClose} className='notification-item-title'>
                           Delete this notification?
                    </ModalHeader>
                    <ModalBody className={''}>
                        <div className="inbox-modal-body-container">
                            <h5 className="notification-item-title">
                                {notification.name}
                            </h5>
                            <p className="notification-item-description">
                                {notification.content}
                            </p>
                        </div>
                        <div className="inbox-modal-btn-container">
                            <button className='notification-item-btn' onClick={handleModalDeleteClose}>
                                Cancel
                            </button>
                            <button className='notification-item-btn primary' onClick={handleDeleteNotification}>
                                Delete
                            </button>
                        </div>
                    </ModalBody>
                </Modal>
            )}
        </>
    )
}

export default NotificationModal