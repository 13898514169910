import React, {FunctionComponent, useEffect, useState} from "react";
import ReactPlayer from "react-player";
import {UserLessonContentLogController} from "../../../../../controllers/UserLessonContentLogController";
import {UserLessonContentController} from "../../../../../controllers/UserLessonContentController";
import {SelectableChoice, UserLessonContent} from "../../../../../models/lesson/UserLessonContent";
import {IMultipleChoiceContent} from "../../../../../models/contentTypes/MultipleChoiceContent";
import {ISelectableChoice} from "../../../../../models/contentTypes/SelectableChoice";

type contentProps={
    completed: boolean
    userLessonContent:UserLessonContent|null
    completeCallback:any,
}

const ContentViewerTrueFalseContent:FunctionComponent<contentProps>=({userLessonContent, completed, completeCallback})=> {

    const [state, setState] = useState(new UserLessonContent())

    const userLessonContentLogController: UserLessonContentLogController = new UserLessonContentLogController();
    const userLessonContentController :UserLessonContentController = new UserLessonContentController()

    const AnswerChoiceSelected=(index:number ,choice:string, isCorrect:boolean)=>{

        //LogCompletetion
        userLessonContentLogController.PostLog(
            {
                Choice:"Selected True False Answer ( "+choice+" )",
                IsCompleted:"true",
                LessonContentId:state.lessonContent.id,
                CorrectAnswer:'Completed True False Question',
                IsCorrect:isCorrect.toString(),
                LessonId:state.lessonContent.lessonId,
                Timestamp:""
            })


        let updatedState = state;

        updatedState.isComplete = true;
        updatedState.isCorrect = isCorrect;
        let alreadySelected = false;

        if(updatedState.savedAnswer != null){
            if(updatedState.savedAnswer.length !=0){
                alreadySelected = true;
                updatedState.savedAnswer[0].text = choice;
                updatedState.savedAnswer[0].isCorrect = isCorrect;
                updatedState.savedAnswer[0].order =index;
            }
        }else{
            updatedState.savedAnswer = [];
        }

        if(!alreadySelected){
            updatedState.savedAnswer.push({ order:index, text: choice, isCorrect:isCorrect});
        }


        setState(updatedState);

        userLessonContentController.UpdateUserLessonContent(updatedState)
        completeCallback();
    }

    const HasChoiceBeenSelected = (content:SelectableChoice[], answerIndex:number) =>{
        if(content == null)
            return false;

        for(let i=0;i< content.length;i++){
            if(content[i].order == answerIndex){
                return true;
            }
        }

        return false;
    }

    useEffect(()=>{

        const initalSet = (setData:any) =>{
            setData(userLessonContent)
        }
        initalSet(setState)
    }, [userLessonContent, completed])


    return(
        <>
            {userLessonContent == null || state == null ?(
                <>
                </>
            ):(
                <>
                    {completed?(
                        <>
                            <div className="quiz-question-text">
                                <div className="quiz-question-title-seperation">
                                    <div className="">
                                        <span>
                                            {state.lessonContent.multipleChoiceProperties?.questionNumber}.
                                        </span>
                                        <span className="quiz-question-left-margin">
                                            {state.lessonContent.multipleChoiceProperties?.questionText}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    {state.lessonContent.multipleChoiceProperties!= null &&(
                                        <>
                                            {state.lessonContent.multipleChoiceProperties?.choices.map((awnser, awnserIndex)=>(

                                                <div key={awnserIndex} className="quiz-question-seperation">
                                                    {HasChoiceBeenSelected(userLessonContent?.savedAnswer, awnserIndex)?(
                                                        <>
                                                            {awnser.isCorrect?(
                                                                //MAKE GREEN
                                                                <div className='CorrectRadioButtonText radioButtonAnswerAdjustment'>
                                                                    <div className={'quiz-question-container'}>
                                                                        <i className="bi bi-check-lg radioDesignationAddjustment" />
                                                                        <input defaultChecked={true} disabled name={state.lessonContent.order+'_choice'} type='radio'/>
                                                                        <span className='radioTextAdjustment'>{awnser.text}</span>
                                                                    </div>
                                                                </div>
                                                            ):(
                                                                //MAKE RED
                                                                <div className='inCorrectRadioButtonText radioButtonAnswerAdjustment'>
                                                                    <div className={'quiz-question-container'}>
                                                                        <i className="bi bi-x-lg radioDesignationAddjustment" />
                                                                        <input defaultChecked={true} disabled name={state.lessonContent.order+'_choice'} type='radio'/>
                                                                        <span className='radioTextAdjustment'>{awnser.text}</span>
                                                                    </div>
                                                                </div>
                                                            )}

                                                        </>
                                                    ):(
                                                        <>
                                                            {awnser.isCorrect?(
                                                                //Missed Correct Answer
                                                                <div className='CorrectRadioButtonText radioButtonAnswerAdjustment'>
                                                                    <div className={'quiz-question-container'}>
                                                                        <i className="bi bi-check-lg radioDesignationAddjustment" />
                                                                        <input disabled  name={state.lessonContent.order+'_choice'} type='radio'/>
                                                                        <span className='radioTextAdjustment'>{awnser.text}</span>
                                                                    </div>
                                                                </div>
                                                            ):(
                                                                <div className={'quiz-question-container'}>
                                                                    <input disabled  name={state.lessonContent.order+'_choice'} type='radio'/>
                                                                    <span className='radioTextAdjustment'>{awnser.text}</span>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>

                                            ))}
                                        </>
                                    )}

                                </div>
                            </div>
                        </>
                    ):(
                        <>
                            <div className="quiz-question-text">
                                <div className="">
                                    <div className="quiz-question-title-seperation">
                                        <span>
                                            {state.lessonContent.multipleChoiceProperties?.questionNumber}.
                                        </span>
                                        <span className="quiz-question-left-margin">
                                            {state.lessonContent.multipleChoiceProperties?.questionText}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    {state.lessonContent.multipleChoiceProperties!= null &&(
                                        <>
                                            {state.lessonContent.multipleChoiceProperties?.choices.map((awnser, awnserIndex)=>(

                                                <div key={awnserIndex} className="quiz-question-seperation">
                                                    {HasChoiceBeenSelected(userLessonContent?.savedAnswer, awnserIndex)?(
                                                        <>
                                                            <input onClick={()=>AnswerChoiceSelected(awnserIndex,awnser.text, awnser.isCorrect)} defaultChecked={true} name={state.lessonContent.order+'_choice'} type='radio' />
                                                            <span className='radioTextAdjustment'>{awnser.text}</span>
                                                        </>
                                                    ):(
                                                        <>
                                                            <input onClick={()=>AnswerChoiceSelected(awnserIndex,awnser.text, awnser.isCorrect)} name={state.lessonContent.order+'_choice'} type='radio' />
                                                            <span className='radioTextAdjustment'>{awnser.text}</span>
                                                        </>
                                                    )}
                                                </div>

                                            ))}
                                        </>
                                    )}

                                </div>
                            </div>
                        </>
                    )}
                </>
            )}

        </>
    )
}

export default ContentViewerTrueFalseContent