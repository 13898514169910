
export enum ModuleType {
    branch = 0,
    training = 1, 
    practice = 2,
    subLesson = 3,
    undefined = 4
}

/**
 * Get the user view for the name of the module type
 * Internally Chapter and sub lessons are branches
 * To the user they are different things
 */
export const ModuleTypeDisplay  = {
    [ModuleType.branch]: "Chapter",
    [ModuleType.training]: "Training",
    [ModuleType.practice]: "Practice",
    [ModuleType.subLesson]: "Lesson Group",
    [ModuleType.undefined]: "Unknown Type",
}


