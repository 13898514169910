import React from "react";
import { FieldProps, useField } from "formik";
import Select, { StylesConfig } from "react-select";
import {ActionMeta} from "react-select";

export type MultiSelectOption = {label:string, value:string}

type IsMulti = false;

export const CbitMultiSelectStyles:StylesConfig<MultiSelectOption, IsMulti> = {
    control:(base, state) => ({
        ...base,
        border: state.isFocused ? "2px solid #80bdff" : "2px solid var( --cbit-cadet-blue)",
        borderColor: state.isFocused ? "#80bdff" : "#9ab5bf",
        boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)" : "0 0 0 0 rgba(0,0, 0, 0)",
        borderRadius: "7px"        
    })
}
const CbitMultiSelect = (props:FieldProps) => {
    const [field, state, {setValue, setTouched}] = useField(props.field.name);

    const onChange = (value:any) => {
        
        setValue(value)
    }

    // const customStyles: StylesConfig<MultiSelectOption, IsMulti> = {
    //     control:(base, state) => ({
    //         ...base,
    //         border: state.isFocused ? "2px solid #80bdff" : "2px solid var( --cbit-cadet-blue)",
    //         borderColor: state.isFocused ? "#80bdff" : "#9ab5bf",
    //         boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)" : "0 0 0 0 rgba(0,0, 0, 0)",
    //         borderRadius: "7px"

    //     })
    // }

    return (
        <Select 
        {...props} 
        styles={CbitMultiSelectStyles}
        value={state?.value} 
        onChange={onChange} 
        //@ts-ignore
        isMulti
        />
    )
}

export default CbitMultiSelect;