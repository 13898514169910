import React, { useEffect, useRef } from "react"
import {SocialConnectionController} from "../../../controllers/SocialConnectionController";
import DOMPurify from "dompurify";
import { StringHelper } from "../../../models/utility/StringHelper";

type searchProps = {
    setPosts: any,
    setKeyword: any,
    keyword: string,
}

/**
 * Search component for searching posts in the social connect
 * @param param0 
 * @returns 
 */
const Search = ({setPosts, setKeyword, keyword}: searchProps) => {
    const isFirstRender = useRef(true);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value);
    }

    useEffect(() => {
        if(keyword.length<= 0 && isFirstRender.current ===false) {                      
            getPosts();
        }

        if(isFirstRender) {
            isFirstRender.current = false;
        }

    }, [keyword]);

    /**
     * Get all post based on the search keyword
     * @returns 
     */
    const getPosts = () => {
        const sController = new SocialConnectionController();

        let cleanedInput = DOMPurify.sanitize(keyword);

        try {
            //Fixes post not appearing when search field is empty
            if(StringHelper.IsNullOrWhiteSpace(cleanedInput)) {
                sController.GetAllPosts().then((res:any) => {
                    setPosts(res)
                });

                return;
            }

            sController.SearchPosts(cleanedInput).then((res: any) => {
                setPosts(res);
            })
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Prevents the form from refreshing the page and get posts
     * @param e 
     */
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        getPosts();
    }

    return (
        <div className="social-connection-search-container">
            <form onSubmit={handleSubmit}>
                <div className="social-connection-search-center">
                    <input type="search" name="keyword" id="keyword" placeholder="Search" onChange={handleSearchChange}
                           value={keyword}/>
                </div>
                <div className="social-connection-search-right">
                    <button type="submit" className='btn-cbit-primary btn-social-connection-search'>
                        <span>
                            Search
                        </span>
                        <i className="bi bi-search search-icon"/>
                    </button>

                </div>
            </form>
        </div>
    )
}

export default Search