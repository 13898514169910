import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {CurrentUserState} from "../../../../store/CurrentUser";
import {SocialConnectionController} from "../../../../controllers/SocialConnectionController";
import SocialConnectionPostItem from "../../components/SocialConnectionPostItem";
import {Spinner} from "reactstrap";
import "./MyPosts.css"
import SocialConnectionLayout from "../../components/layout/SocialConnectionLayout";

const MyPosts = ({history}: any) => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);

    const currentUser = useSelector((state: any) => state.currentUser.userProfile);

    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state) => state.currentUser);

    const getMyPosts = async () => {
        const sConnectionController = new SocialConnectionController();
        // @ts-ignore
        const posts = await sConnectionController.GetAllPostsByUser(currentUser.id);
        // @ts-ignore
        setPosts(posts.result);
        setLoading(false);
    }

    const updatePosts = (id: string, updatedPost: any) => {
        const updatedPosts = posts.map((post: any) => {
            if (post.id === id) {
                return updatedPost;
            }
            return post;
        });

        // @ts-ignore
        setPosts(updatedPosts);
    }

    useEffect(() => {
        getMyPosts();
    }, []);

    useEffect(() => {
        const userIsBanned = UserStore?.userProfile?.isBanned;

        if (userIsBanned) {
            history.push('/home');
        }

    }, [UserStore?.userProfile?.isBanned]);

    return (
        <SocialConnectionLayout>
            {loading ? (
                <div className="spinner-container">
                    <Spinner color='primary'/>
                </div>
            ) : (
                <>
                    <div className='social-connection-my-posts-container'>
                        <div className="social-connection-my-posts-page-header">
                           <span className='social-connection-go-back'>
                                <a href='/social-connection'>&#60; Return to Main Discussion Board</a>
                            </span>
                        </div>
                        <div className="social-connection-my-posts-subheader">
                            <h4 className='social-connection-subheading'>
                                My Posts
                            </h4>
                        </div>
                        <>
                            {posts.length > 0 ? posts.map((post: any) => {
                                return (
                                    <SocialConnectionPostItem key={post.id} post={post} updatePost={updatePosts} />
                                )
                            }) :  (
                                <div className='social-connection-no-posts-container'>
                                    <h4 className={'social-connection-no-posts-text'}>No posts found</h4>
                                </div>
                            )}
                        </>
                    </div>
                </>
            )}
        </SocialConnectionLayout>
    )
}

export default MyPosts