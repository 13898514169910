import * as Dompurify from "dompurify";
import { ErrorMessage, Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { EmailerController } from "../../../controllers/EmailerController";
import { useGoogleReCAPTCHA } from "../../../hooks/UseGoogleReCaptcha";
import { ContactUsEmailRequest } from "../../../models/requests/ContactUsEmailRequest";
import FormHelper from "../../../models/utility/FormHelper";
import { StringHelper } from "../../../models/utility/StringHelper";
import CustomModal from "../../Utilities/CustomModal";


/**
 * Controller for communicating with the email service
 */
const emailerController = new EmailerController();

/**
 * The type of object the form handles 
 */
type ContactUsFormType = {
    date:string,
    name:string
    email:string,
    subject:string,
    message:string
}

//Our recaptcha public key from https://www.google.com/recaptcha
const googleRecaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;

/**
 * Component for the contact form that will send an email when submitted
 */
export default function ContactUsForm() {
    const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false)
    
    useGoogleReCAPTCHA();

    /**
     * The initial values of the form
     */
    const initialValues:ContactUsFormType = {
        date: new Date().toISOString(),
        name: "",
        email: "",
        subject: "",
        message: ""
    }

    /**
     * Toggles the modal by set the modal state to its opposite current value
     */
    const toggleModal = () => {    
        setIsModalOpen(!isModalOpen);
    }

    const onModalClose = () => {
        window?.location.reload();
    }

    /**
     * If this callback occurs that means the box was checked 
     * and we need to enable the submit button
     */
    const onRecaptchaCallback = () => {
        setIsCaptchaChecked(true);
    }

    /**
     * If this callback occurs that means the captcha has expired 
     * and we need to disable the submit button
     */
    const onRecaptchaExpiredCallback = () => {
        setIsCaptchaChecked(false);
    }

    /**To handle disabling and enabling the submit button on recaptcha click the callbacks 
     * need to be on the window object next will throw an invalid data-callback/etc error
     * **/
    useEffect(() => {
        function onComponentMount() {            
            (window as any).onRecaptchaCallback = onRecaptchaCallback;
            
            (window as any).onRecaptchaExpiredCallback = onRecaptchaExpiredCallback;

            //When component unmounts remove the captcha callbacks from the window object
            return () => {
                (window as any).onRecaptchaCallback = null;
                (window as any).onRecaptchaExpiredCallback = null;
            }
        }
        onComponentMount();
    }, [])


    /**
     * Schema validation for our form
     */
    const yupValidationObj = {
        name: Yup.string()
                .required(FormHelper.requiredErrorMsg("name"))
                .min(2, FormHelper.minErrorMsg("Name", 2))
                .max(32, FormHelper.maxErrorMsg("Name", 32)),
        email: Yup.string()
                .required(FormHelper.requiredErrorMsg("valid email address"))
                .matches(/(.+)@(.+){2,}\.(.+){2,}/, "Please provide a valid email address"), 
        subject: Yup.string()
                    .required(FormHelper.requiredErrorMsg("subject"))
                    .min(5, FormHelper.minErrorMsg("Subject", 5))
                    .max(130, FormHelper.maxErrorMsg("Subject", 130)),
        message:Yup.string()
                    .required(FormHelper.requiredErrorMsg("message"))
                    .max(4000, FormHelper.maxErrorMsg("Message", 4000))
    }

    const validationSchema = Yup.object(yupValidationObj);

    /**
     * Get a captcha token from the grecaptcha that will be validated on the backend
     * @returns 
     */
    const getRecaptchaToken = () => {
        if(window == null) {
            return null;
        }
        const gRecaptcha = window["grecaptcha" as keyof typeof window];

        const captchaToken = gRecaptcha.getResponse();

        return captchaToken;
    }

    /**
     * handles when the form is submitted
     * @param formData 
     * @returns 
     */
    const onFormSubmit = (formData:ContactUsFormType, formik:any) => {        
        const captchaToken = getRecaptchaToken();     

        if(!captchaToken || StringHelper.IsNullOrWhiteSpace(captchaToken)) {
            toast.error("Please verify you are not a robot.");
            return;
        }

        //cleaning the user input
        let purifiedValues:ContactUsEmailRequest = {
            date: Dompurify.sanitize(formData.date),
            name: Dompurify.sanitize(formData.name),
            email: Dompurify.sanitize(formData.email),
            subject: Dompurify.sanitize(formData.subject),
            message: Dompurify.sanitize(formData.message),
            captchaToken:Dompurify.sanitize(captchaToken),
        };

        //The token is validated on the backend and will return false if invalid
        const successfullySubmitted = emailerController.SendContactUsEmail(purifiedValues);

        if(!successfullySubmitted) {
            toast.error("Failed to send message")
            return;            
        }
        
        // toast.success("Your message have successfully been sent");
        toggleModal();              
        formik.resetForm();        
    }    

    return (
        <>
            <Formik 
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onFormSubmit}
            >
                {({errors, handleChange, handleSubmit, handleBlur, resetForm, touched, values}) => (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <div className="form-label-input-container">
                                <label htmlFor="name" className="cbit-label">Name</label>
                                <input 
                                    id="name"
                                    name="name"
                                    className="form-input"
                                    type="text" 
                                    value={values.name}
                                    placeholder="Name"
                                    onChange={handleChange} 
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className="error-message-container">
                                <ErrorMessage name={"name"} />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-label-input-container">
                                <label htmlFor="subject" className="cbit-label">Subject</label>
                                <input 
                                    id="subject"
                                    name="subject"
                                    className="form-input"
                                    type="text" 
                                    value={values.subject}
                                    placeholder="Subject"
                                    onChange={handleChange} 
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className="error-message-container">
                                <ErrorMessage name={"subject"} />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-label-input-container">
                                <label htmlFor="email" className="cbit-label">Email</label>
                                <input 
                                    id="email"
                                    name="email"
                                    className="form-input"
                                    type="text" 
                                    value={values.email}
                                    placeholder="Email"
                                    onChange={handleChange} 
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div className="error-message-container">
                                <ErrorMessage name={"email"} />
                            </div>
                        </div>
                        <div className="form-group last">
                            <div className="form-label-input-container">
                                <label htmlFor="message" className="cbit-label">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    placeholder="Message"
                                    className="form-input vertical-resize-only"                                
                                    rows={8}
                                    value={values.message}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{height:128}}
                                >                                
                                </textarea>
                            </div>
                            <div className="error-message-container">
                                <ErrorMessage name={"message"} />
                            </div>
                        </div>
                        <div className="recaptcha-container">
                            {googleRecaptchaKey && !StringHelper.IsNullOrWhiteSpace(googleRecaptchaKey) && (
                                <div 
                                    id="Grecaptcha" 
                                    className="g-recaptcha" 
                                    data-sitekey={googleRecaptchaKey} 
                                    data-callback={"onRecaptchaCallback"} 
                                    data-expired-callback="onRecaptchaExpiredCallback"
                                ></div>
                            )}
                        </div>
                        <div className="btn-form-container">
                            <button
                                id={"btn-contact-us"}
                                disabled={!isCaptchaChecked}
                                className="btn-cbit-primary"
                                type="submit"
                            >
                                SUBMIT
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
            <CustomModal                 
                isOpen={isModalOpen} 
                toggle={onModalClose}
            >
                <div className="cbit-dialog">
                    <h1 className="cbit-section-label">Thank you for reaching out!</h1>
                    <p>Your message has been submitted. A team member will get in touch in the near future.</p>
                </div>
            </CustomModal>
        </>
    )
}