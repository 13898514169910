import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { LessonController } from "../../../controllers/LessonController";
import { ModuleController } from "../../../controllers/ModuleController";
import { IModule, PartialModuleType } from "../../../models/module/Module";
import { ICurrentUser } from "../../../models/module/UserProfile";
import { ApplicationState } from "../../../store";
import * as CurrentUserStore from "../../../store/CurrentUser";
import AdditionalVideosViewer from "./AdditionalVideosViewer";
import './LearningDashboard.css';
import LearningModuleAccordionComp from "./LearningModuleAccordionComp";
import UserLessonViewerWrapper from "./UserLessonViewerWrapper";

type currentUserProps =
    CurrentUserStore.CurrentUserState
    & typeof CurrentUserStore.actionCreators
    & RouteComponentProps & {id:string};

type LearningDashboardState = {
    user: ICurrentUser,
    CoreModule: IModule,
    isLoading:boolean,
    CurrentModule:PartialModuleType
    isOpen:boolean,
    isNavOpen:boolean
}

class LearningDashboard extends React.PureComponent<currentUserProps, LearningDashboardState> {
        
    private isLoggedIn = this.props.isLoggedIn;
    private modController:ModuleController = new ModuleController();
    private lessonController:LessonController = new LessonController();
    private firstLoad = true;
    
    constructor(props:any){
        super(props);
        this.state = this.initalState;
    }
    
    private initalState: LearningDashboardState = {
        user: this.props.userProfile,
        isLoading: true,
        isOpen: true,
        isNavOpen: true,
        CoreModule: {
            id: '',
            name: '',
            parentModule: '',
            description: '',
            order: 0,
            type: 0,
            modules: [],
            lessons: [],
            combinedList: [],
            createdAt: '',
            updatedAt:''
        },
        CurrentModule: {
            id: '',
            name: '',
            description: '',
            order: 0,
            isLesson: false
        },        
    }
    
    private setCurrentModule = (currentModule:PartialModuleType) => {
        this.setState(prevState => ({
            ...prevState,
            CurrentModule: {...currentModule}
        }))
    }

    Toggle=()=>
    {
        // $('.AdditionalVideoContainer').toggleClass('isOpen');

        this.setState(prevState => ({
            ...prevState,
            isOpen: !prevState.isOpen
        }))
    }

    handleSidebar = ()=> {

        this.setState(prevState => ({
            ...prevState,
            isNavOpen: !prevState.isNavOpen
        }))

    }

    accordionRef = React.createRef<HTMLDivElement>();
    rightMenuButtonRef = React.createRef<HTMLButtonElement>();
    rightMenuRef = React.createRef<HTMLDivElement>();

    // Get window size and remove class at 1023 pixels
    handleResponsiveNav = () => {                        
        const mainContent =   document.querySelector(".MainContent");
        const additionalVideoContainer = document.querySelector(".AdditionalVideoContainer");
        const viewerContent = document.querySelector(".Viewer-Content");

        if (window.innerWidth <= 1023) {
            mainContent!.classList.remove('isNavOpen');
            additionalVideoContainer!.classList.remove('isNavOpen');
            additionalVideoContainer!.classList.remove('isOpen')
            viewerContent!.classList.remove('isNavOpen');
        }

        if (window.innerWidth > 1023) {
            mainContent!.classList.add('isNavOpen');
            additionalVideoContainer!.classList.add('isNavOpen');
            additionalVideoContainer!.classList.add('isOpen')
            viewerContent!.classList.add('isNavOpen');
        }
    }

    handleClickOutside = (event:any) => {
        // if (this.accordionRef.current && !this.accordionRef.current.contains(event.target)) {
        //     this.setState(prevState => ({
        //         ...prevState,
        //         isNavOpen: false
        //     }))
        // }
    }

    handleRightMenuClickOutside = (event:any) => {

        // if (this.rightMenuRef.current && this.rightMenuRef.current.contains(event.target)) {
        //     return;
        // }

        // if (this.rightMenuButtonRef.current && !this.rightMenuButtonRef.current.contains(event.target) && this.state.isOpen) {
        //     this.setState(prevState => ({
        //         ...prevState,
        //         isOpen: !prevState.isOpen
        //     }))
        // }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResponsiveNav);
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('mousedown', this.handleRightMenuClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResponsiveNav);
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('mousedown', this.handleRightMenuClickOutside);
    }

    public render() 
    {
        return (
            <React.Fragment>
                <div className={`container-fluid AdditionalVideoContainer ${this.state.isOpen ? 'isOpen' : ''} ${this.state.isNavOpen ? 'isNavOpen' : ''}`}>
                    <button ref={this.rightMenuButtonRef} className='btn-cbit-primary menuTab' onClick={this.Toggle}>
                        <div className='buttonIcon'>
                            <i className="bi bi-list"></i>
                        </div>
                    </button>

                    <button id={'accordion-tab'} className='nav-sidebar-menu-tab' onClick={this.handleSidebar}>
                        <div className='buttonIcon'>
                            <i className="bi bi-list" />
                        </div>
                    </button>

                    <div className={`MainContent ${this.state.isNavOpen ? 'isNavOpen' : ''}`}>
                        <div ref={this.accordionRef} className='AccordionContent'>
                            {/*@ts-ignore*/}
                            <LearningModuleAccordionComp
                                    location={this.props.location}
                                    history={this.props.history}
                                    match={this.props.match}
                                    isAdmin={this.props.isAdmin}
                                    isSuperAdmin={this.props.isSuperAdmin}
                                    userProfile={this.props.userProfile}
                                    isLoggedIn={this.props.isLoggedIn}
                                    id={this.props.id}
                                    notificationCount={this.props.notificationCount}
                                    handleSidebar={this.handleSidebar}
                            />

                        </div>
                        <div className={`Viewer-Content ${this.state.isNavOpen ? 'isNavOpen' : ''}`}>
                            <UserLessonViewerWrapper location={this.props.location}
                                                     history={this.props.history}
                                                     match={this.props.match}
                            />
                        </div>
                    </div>
                    <div ref={this.rightMenuRef} className='Menu-Right-Bar'>
                        <div className='AdditionalContentContainer'>
                            <AdditionalVideosViewer location={this.props.location}
                                                    history={this.props.history}
                                                    match={this.props.match}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.currentUser,
    CurrentUserStore.actionCreators
)(LearningDashboard);
