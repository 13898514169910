import React, {FunctionComponent, useEffect, useState} from 'react';
import {PartialModuleTreeModel} from "../../../../models/partialModels/PartialModuleTreeModel";
import {UserLesson} from "../../../../models/lesson/UserLesson";
import {ListGroup} from "react-bootstrap";
import {UserLessonController} from "../../../../controllers/UserLessonController";
import {UserModuleController} from "../../../../controllers/UserModuleController";
import {UserLessonContentLogController} from "../../../../controllers/UserLessonContentLogController";
import {UserLessonContent} from "../../../../models/lesson/UserLessonContent";
import {LessonContentType} from "../../../../models/contentTypes/LessonContentType";
import ContentViewerVideoContent from "./LessonContentComponents/ContentViewerVideoContent";
import ContentViewerMultipleChoiceContent from "./LessonContentComponents/ContentViewerMultipleChoiceContent";
import ContentViewerTrueFalseContent from "./LessonContentComponents/ContentViewerTrueFalseContent";
import ContentViewerQuizTextContent from "./LessonContentComponents/ContentViewerQuizTextContent";
import ContentViewerMultipleAnswerContent from "./LessonContentComponents/ContentViewerMultipleAnswerContent";
import {ILessonContent} from "../../../../models/lesson/LessonContent";
import {DefaultLesson, ILesson} from "../../../../models/lesson/Lesson";
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import { CurrentLastLessonState } from '../../../../store/LastLessonStore';

type ComboLessonQuizProps = {
    parentModule: PartialModuleTreeModel,
    selectedUserLesson: UserLesson,
    updateAccordionFunction: any,
    toggleCourseCompletion:() => void,
}

const ComboLessonType: FunctionComponent<ComboLessonQuizProps> = (props) => {

    //State Data
    const [userContent, setUserContent] = useState([])
    const [completedCount, setCompletedCount] = useState(0);
    const [Lesson, setLesson] =useState(DefaultLesson);

    const [totalCount, setTotalCount] = useState(0);
    const [complete, setComplete] = useState(false);

    const [dataLoaded, setDataLoaded] = useState(false);
    const lastLessonStore = useSelector<ApplicationState, CurrentLastLessonState | undefined>(state => state.currentLastLesson);
    const lastLesson:ILesson | null = lastLessonStore ? lastLessonStore.lastLesson : null;

    const userLessonController: UserLessonController = new UserLessonController();
    const userModuleController: UserModuleController = new UserModuleController();
    const userLessonContentLog: UserLessonContentLogController = new UserLessonContentLogController();

    //Inital Configuration
    useEffect(() => {
        const setInitalData = async (setUserContent: any, setCompletedC: any, setTotal: any, dataIsSet:any, setLesson:any, setDefaultComplete:any) => {

            let completed = 0;

            setUserContent(props.selectedUserLesson.lesson.userLessonContent);
            setLesson(props.selectedUserLesson.lesson);

            if (props.selectedUserLesson.lesson.lessonContent != null) {
                const totalQuestions = props.selectedUserLesson.lesson.totalQuestions;
                setTotal(totalQuestions);
            }


            props.selectedUserLesson.lesson.userLessonContent.map((ulesson) => {
                if (ulesson.isComplete) {
                    if(ulesson?.lessonContent.type == LessonContentType.multipleChoice ||
                        ulesson?.lessonContent.type == LessonContentType.trueOrFalse ||
                        ulesson?.lessonContent.type == LessonContentType.video)
                        completed++;
                };
            })

            setCompletedC(completed);
            setDefaultComplete(props.selectedUserLesson.isComplete);
            dataIsSet(true);
        }

        setInitalData(setUserContent, setCompletedCount, setTotalCount, setDataLoaded, setLesson, setComplete);

    }, [props.selectedUserLesson])


    const navigateToNextLesson = async () => {
        let parent = props.parentModule;
        let nextUserLessonId = await userModuleController.GetNextUserLessonId(props.selectedUserLesson.id);

        props.updateAccordionFunction({
            parentModule: parent,
            selectedModule: nextUserLessonId,
            nextLessonId: nextUserLessonId.id,
            selectedContent: userContent,
            isUpdating: true,
        });
    }

    const SubmitQuiz = async () => {
        
        if (completedCount == totalCount) {

            await userLessonContentLog.PostLog({
                Choice: "User Completed Quiz",
                IsCompleted: "true",
                LessonContentId: "",
                CorrectAnswer: 'Completed Quiz',
                IsCorrect: "true",
                LessonId: Lesson.id,
                Timestamp: ""
            })

            //Obtain the next user Lesson in the list
            let nextUserPartial = await userModuleController.GetNextUserLessonId(props.selectedUserLesson.id);

            if(nextUserPartial !== null)
            {
                //UserLessonContent - Get First In the Array ( for Video )
                let isUserLessonComplete = await userModuleController.UpdateUserLessonCompleteness(props.selectedUserLesson.id, nextUserPartial.id);

                if(isUserLessonComplete)
                {
                    setComplete(true);
                    let parent = props.parentModule;
                    //Update the Parent in the accordion
                    parent?.children?.forEach(firstChild => {
                        //look for next

                        if (firstChild.id == nextUserPartial.id) {
                            //Set Next
                            firstChild.isNext = true;
                            firstChild.isDisabled = false;
                            firstChild.isLocked = false;
                        }
                        if (firstChild.id == props.selectedUserLesson.id) {
                            //Unset Next
                            firstChild.isNext = false;
                            firstChild.isComplete = true;
                            firstChild.isLocked = false;
                            firstChild.isDisabled = false;
                        }
                        if (firstChild.children.length > 0) {
                            firstChild.children.forEach(secondChild => {
                                if (secondChild.id == nextUserPartial.id) {
                                    secondChild.isNext = true;
                                    secondChild.isDisabled = false;
                                    secondChild.isLocked = false;
                                }
                                if (secondChild.id == props.selectedUserLesson.id) {
                                    secondChild.isNext = false;
                                    secondChild.isComplete = true;
                                    secondChild.isDisabled = false;
                                    secondChild.isLocked = false;
                                }
                                if(secondChild.children.length >0){
                                    secondChild.children.forEach(thirdChild =>{
                                        if (thirdChild.id == nextUserPartial.id) {
                                            thirdChild.isNext = true;
                                            thirdChild.isDisabled = false;
                                            thirdChild.isLocked = false;
                                        }
                                        if (thirdChild.id == props.selectedUserLesson.id) {
                                            thirdChild.isNext = false;
                                            thirdChild.isComplete = true;
                                            thirdChild.isDisabled = false;
                                            thirdChild.isLocked = false;
                                        }
                                    })
                                }
                            })
                        }
                    })

                    props.updateAccordionFunction({
                        parentModule:parent,
                        selectedModule: props.selectedUserLesson,
                        nextLessonId: nextUserPartial.id,
                        selectedContent: UserLessonContent,
                        isUpdating:true,

                    })

                    if(lastLesson && lastLesson.id === props.selectedUserLesson.lesson.id) {
                        props.toggleCourseCompletion();
                    }
                }
            }
        }
    }

    const GetUserLessonContent = (userLessonContent: UserLessonContent[], index: number) => {
        try {
            let returnData = null;
            for(let i=0; i<userLessonContent.length; i++){
                if(userLessonContent[i].order == index){
                    returnData = userLessonContent[i];
                    break;
                }
            }

            return returnData
        } catch (error) {
            return null
        }
    }

    const completionCallBack = (order: string) => {

        let completeCount = 0
        let contentList: UserLessonContent[] = userContent;

        contentList.map((content: UserLessonContent) => {
            if (content.isComplete) {
                completeCount++;
            }
        })

        setCompletedCount(completeCount);
    }

    const doesContentHaveMultipleAnswers = (content:ILessonContent) =>{
        var count =0;
        content.multipleChoiceProperties?.choices.map((choices)=>{
            if(choices.isCorrect){
                count++;
            }
        });

        if(count>1){
            return true;
        }

        return false;
    }


    return (
        <>
            <div className="combo-lesson-quiz-content-panel">
                {/* Combo Lesson Quiz header */}
                <div className="combo-lesson-quiz-header">
                    <div className="combo-lesson-quiz-title">
                        <h1>{props.selectedUserLesson.lesson.name}</h1>
                    </div>
                </div>
                {/* Combo Lesson body section */}
                <div>
                    <>
                        {props.selectedUserLesson.lesson.lessonContent != null && (
                            <ListGroup>
                                {props.selectedUserLesson.lesson.lessonContent.map((lessonContent, contentIndex) => (
                                    <div key={contentIndex}>
                                        {userContent != null && (

                                            <div key={lessonContent.id}>
                                                {lessonContent.type == LessonContentType.video && (
                                                    <ContentViewerVideoContent
                                                        userLessonContent={GetUserLessonContent(userContent, contentIndex)}
                                                        completeCallback={completionCallBack}/>
                                                )}

                                                {lessonContent.type == LessonContentType.multipleChoice && (
                                                    <>
                                                        {doesContentHaveMultipleAnswers(lessonContent) ?(
                                                            <ContentViewerMultipleAnswerContent
                                                                userLessonContent={GetUserLessonContent(userContent, contentIndex)}
                                                                completed={complete}
                                                                completeCallback={completionCallBack}/>
                                                        ):(
                                                            <ContentViewerMultipleChoiceContent
                                                                userLessonContent={GetUserLessonContent(userContent, contentIndex)}
                                                                completed={complete}
                                                                completeCallback={completionCallBack}/>
                                                        )}

                                                    </>
                                                )}

                                                {lessonContent.type == LessonContentType.trueOrFalse && (
                                                    <ContentViewerTrueFalseContent
                                                        userLessonContent={GetUserLessonContent(userContent, contentIndex)}
                                                        completed={complete}
                                                        completeCallback={completionCallBack}/>
                                                )}


                                                {lessonContent.type == LessonContentType.text && (
                                                    <ContentViewerQuizTextContent
                                                        userLessonContent={GetUserLessonContent(userContent, contentIndex)}
                                                        completeCallback={completionCallBack}/>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </ListGroup>
                        )}
                    </>
                </div>

                {/* Combo Lesson Footer */}
                <div className="combo-lesson-footer">
                    <div>
                        {complete ? (
                            <button onClick={() => navigateToNextLesson()} className={'btn-cbit btn-primary combo-lesson-footer-right-content'}>
                                NEXT LESSON
                            </button>
                        ) : (
                            <>
                                {completedCount >= totalCount && (
                                    <button onClick={() => SubmitQuiz()} className='btn-cbit btn-primary combo-lesson-footer-content'>
                                        Submit Quiz
                                    </button>
                                )}
                                {completedCount < totalCount && (
                                    <button className='btn-cbit btn-disabled combo-lesson-footer-content'>
                                        Submit Quiz
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ComboLessonType;
