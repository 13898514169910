import React, { useState } from "react";
import { IReferral } from "../../../models/Referral/Referral";
import "./ReferralUserDetails.css";
import ReferralProfileImage from "../ReferralProfileImage/ReferralProfileImage";
import CloudflareImageHelper from "../../../models/utility/CloudflareImageHelper";
import {v4 as uuidV4} from "uuid";
import { StringHelper } from "../../../models/utility/StringHelper";
import ReferralUserMap from "../ReferralMap/ReferralUserMap";
import { AgeGroupEnum, ageGroupUi } from "../../../models/Enums/AgeGroupEnum";
import CustomModal from "../../Utilities/CustomModal";
import MyProfileDescription from "../../UserDashboard/MyProfile/MyProfileDescription";
import { useSelector, useStore } from "react-redux";
import { ApplicationState } from "../../../store";
import { CurrentUserState } from "../../../store/CurrentUser";
import { Link } from "react-router-dom";

type ReferralUserDetailsProps = {
    referral:IReferral
}

const ReferralUserDetails = (props:ReferralUserDetailsProps) => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    const {referral} = props;
    const imageVariant = CloudflareImageHelper.variants.defaultProfileImage;

    const characterLimit = 704;

    const [isFullDescriptionOpen, setIsFullDescriptionOpen] = useState(false);

    console.error(isFullDescriptionOpen);

    const toggleFullDescriptionModal = () => {
        setIsFullDescriptionOpen(!isFullDescriptionOpen);
    }

    /**
     * Converts list of licensed states to a comma string
     * @returns
     */
    const addCommaToLicensedStates = () => {
        if(referral.licensedStates.length <= 0) {
            return "";
        }
        return referral.licensedStates.join(", ").toString();
    }

    const checkIfLoggedInUserReferral = () => {
        if(!userStore) {
            return false;
        }

        const {userProfile: {id}} = userStore;

        return id === referral.userProfileId;
    }

    return (
        <>
            <div className="referral-details">
                <h2 className="cbit-header">REFERRAL INFORMATION</h2>
                <div className="referral-profile-body referral-profile-info">
                    <ReferralProfileImage referral={referral} imageVariant={imageVariant}/>
                    <div className="referral-info-container">
                        <div className="referral-name-container">
                            <h4 className="cbit-small-header">
                                {referral.name} { checkIfLoggedInUserReferral() && ("(You)")} {" "}
                            </h4>
                            <span className="italicize-text">{referral.occupation}</span>
                            {
                                checkIfLoggedInUserReferral() &&
                                (
                                    <div>
                                        <Link to={"/referral/my-profile"} className="btn-cbit-link">Edit</Link>
                                    </div>
                                )
                            }
                        </div>
                        <div className="referral-bio-container text-ellipse">
                            <div className="cbit-label">bio</div>
                            <p>
                                {referral.description}
                            </p>
                        </div>
                        <div>
                            {referral.description.length > characterLimit &&
                            (
                                <button
                                    onClick={toggleFullDescriptionModal}
                                    className="btn-cbit-link"
                                >
                                    View More
                                </button>
                            )}
                        </div>
                        <div className="referral-contact-container">
                            <div className="cbit-label">contact information</div>
                            {
                                referral.isContactPrivate ?
                                (
                                    <>Private</>
                                )
                                :
                                (
                                    <ul className="referral-contact-list">
                                        <li>{referral.email}</li>
                                        <li>{StringHelper.formatPhoneNumber(referral.phone)}</li>
                                        <li className="referral-contact-state">{`${referral.streetName}, ${referral.city}, ${referral.state} ${referral.zip}`}</li>
                                    </ul>
                                )
                            }
                        </div>
                        <div className="referral-licenses-container">
                            <div className="cbit-label">Licensed States</div>
                            <div className="referral-licensed-states-list">
                                {
                                    addCommaToLicensedStates()
                                }
                            </div>
                        </div>
                        <div className="referral-website-container">
                            <div className="cbit-label">Website</div>
                            {referral.websiteLink.length > 0  && (
                                <div><a href={`${referral.websiteLink}`}>{referral.websiteLink}</a></div>
                            )}
                        </div>
                        <div className="referral-age-group-container">
                            <div className="cbit-label">Age Group</div>
                            <div>{ageGroupUi[referral.ageGroup]}</div>
                        </div>
                        <ReferralUserMap referral={referral} />
                        </div>
                </div>
            </div>
            <CustomModal
                isOpen={isFullDescriptionOpen}
                toggle={toggleFullDescriptionModal}
            >
                <MyProfileDescription
                    name={referral.name}
                    description={referral.description}
                />
            </CustomModal>
        </>
    )
}

export default ReferralUserDetails