export enum LessonContentType {
    text = 0,
    multipleChoice = 1,
    video = 2,
    image = 3,
    trueOrFalse = 4,
    undefined = 5
}

// User Friendly display for enums 
export const LessonContentTypeDisplay = {
    [LessonContentType.text]: "Text",
    [LessonContentType.multipleChoice]: "Multiple Choice Question",
    [LessonContentType.video]: "Video",
    [LessonContentType.image]: "Image",
    [LessonContentType.trueOrFalse]: "True Or False",
    [LessonContentType.undefined]: "Undefined"
}