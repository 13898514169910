import React, {Children, useEffect, useState} from 'react';
import ConsultationNavigationPanel from './ConsultationNavigationPanel/ConsultationNavigationPanel';
import './ConsultationLayout.css';
import useWindowDimensions from "../../hooks/useWindowDimensions";
import NavSidebar from "../SocialConnection/components/layout/NavSidebar/NavSidebar";
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { CurrentUserState } from '../../store/CurrentUser';
import { CheckIfConsultant } from '../../models/module/UserProfile';

type ConsultationLayoutProps = {
    children: React.ReactNode
}

//Manage availability and my profile should not be accessible by professionals
const links = [
    {
        name: 'Manage My Availability',
        path: '/consultation/availability-management'
    },
    {
        name: 'Book a Consult',
        path: '/consultation/booking'
    },
    {
        name: 'My Upcoming Sessions',
        path: '/consultation/upcoming-sessions'
    },
    {
        name: 'My Previous Sessions',
        path: '/consultation/previous-sessions'
    },
    {
        name: 'My Profile',
        path: '/consultation/my-profile'
    },
];

const ConsultationLayout = ({children}: ConsultationLayoutProps) => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    
    const [isNavOpen, setIsNavOpen] = useState(true);
    const {height, width} = useWindowDimensions();
    
    const toggle = () => {
        setIsNavOpen(!isNavOpen);
    }
    
    useEffect(() => {
        // If the width of the device is greater than 1023px, open the navigation panel
        if (width > 1023) {
            setIsNavOpen(true);
        }
        
        // If the width of the device is less than 1023px, close the navigation panel
        if (width <= 1023) {
            setIsNavOpen(false);
        }
        
    }, [width]);
    
    /**
     * Display links based on the user role
     */
    const getLinksByRole = () => {
        const professionalLinks = links.filter(l => l.path !== "/consultation/availability-management" && l.path !== "/consultation/my-profile");
        
        if(!userStore) {
            return professionalLinks;
        }

        const user = userStore.userProfile;

        //Consultants and admins should be able to see all links for consultations
        if(CheckIfConsultant(user.roles)) {
            return links;
        }

        return professionalLinks;
    }

    return (
        <div className={`consultation-layout ${isNavOpen ? 'isOpen' : ''}`}>
            {/* Navigation Panel */}
            <div className="AccordionContent">
                <NavSidebar toggle={toggle}
                            isNavOpen={isNavOpen}
                            setIsNavOpen={setIsNavOpen}
                            title={'Consultation'}
                            links={getLinksByRole()}
                />
            </div>
            {/* Main content container */}
            <div className="consultation-main-content">
                {children}
            </div>
        </div>
    )
}

export default ConsultationLayout;