import {SocialConnectionService} from "../services/SocialConnectionService";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import {AxiosError} from "axios";
import {IPost} from "../models/SocialConnection/Post";
import {UserService} from "../services/UserService";
import toast from "react-hot-toast";

export class SocialConnectionController {

    constructor() {
        this.sSocialConnectionService = new SocialConnectionService();
        this.sUserService = new UserService();
    }

    private sSocialConnectionService: SocialConnectionService;
    private sUserService: UserService;

    /**
     * Gets all posts
     * @returns An array of posts
     */
    public async GetAllPosts(): Promise<IPost[]> {
        try {
             return await this.sSocialConnectionService.GetAllPosts();
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Get all posts by a user
     * @param userId The id of the user
     * @returns An array of posts
     */
    public async GetAllPostsByUser(userId: string): Promise<IPost[]> {
        try {
            return await this.sSocialConnectionService.GetAllPostsByUser(userId);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Gets a post by id
     * @param id The id of the post
     * @returns A post
     */
    public async GetPostById(id: string): Promise<IPost> {
        try {
            return await this.sSocialConnectionService.GetPostById(id);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Creates a post
     * @param post The post to create
     * @returns void
     */
    public async CreatePost(post: any): Promise<void> {
        try {

            let postData = {
                id: "",
                name: post.name,
                content: post.content,
                isPromoted: post.isPromoted,
                replies: post.replies,
                userId: "",
                createdAt: post.createdAt,
                updatedAt: post.updatedAt
            }

            return await this.sSocialConnectionService.CreatePost(postData);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Updates a post
     * @param post The post to update
     * @param id The id of the post to update
     * @returns void
     */
    public async UpdatePost(id: string, post: IPost,): Promise<void> {
        try {
            return await this.sSocialConnectionService.UpdatePost(id, post);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Edits a reply as a user
     * @param id
     * @param updatedPost
     * @constructor
     */
    public async EditReply(id: string, updatedPost: any): Promise<void> {
        try {
            toast.success("Reply edited");
            return await this.sSocialConnectionService.EditReply(id, updatedPost);
        } catch (error) {
            toast.error("Something went wrong");
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Adds a reply to a post
     * @param id The id of the post to add a reply to
     * @param reply The reply to add
     * @returns void
     */
    public async AddReply(id: string, reply: any): Promise<void> {
        try {

            let postData =  {
                userId: '',
                content: reply,
                createdAt: new Date(),
                updatedAt: new Date()
            }

            return await this.sSocialConnectionService.AddReply(id, postData);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Searches for a post by keyword
     * @param keyword The keyword to search for
     * @returns An array of posts that match the keyword
     */
    public async SearchPosts(keyword: string): Promise<IPost[]> {
        try {
            return await this.sSocialConnectionService.SearchPosts(keyword);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Admin Functions
     */

    /**
     * Gets all posts for an admin
     * @returns An array of posts
     */
    public async AdminGetAllPosts(): Promise<IPost[]> {
        try {
            return await this.sSocialConnectionService.AdminGetAllPosts();
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Bans a user as an admin
     * @param userId The id of the user to ban
     * @param updatedUser
     * @returns void
     */
    public async AdminBanUser(userId: string, updatedUser: any): Promise<void> {
        try {
            return await this.sSocialConnectionService.AdminBanUser(userId, updatedUser);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Admin search for a post by name, isVisible, and isReported
     * @param keyword The keyword to search for
     * @param isVisible
     * @param isReported
     * @returns An array of posts that match the keyword, isVisible, and isReported
     */
    public async AdminSearchPosts(keyword: string, isVisible: boolean | null, isReported: boolean | null): Promise<IPost[]> {
        try {
            return await this.sSocialConnectionService.AdminSearchPosts(keyword, isVisible, isReported);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

}