/**
 * The type of items the Editor edits
 * TODO change the name
 */
export enum EditorContentEnum {
    lesson = "LESSON",
    lessonQuiz = "LESSON_QUIZ",
    lessonGroup = "LESSON_GROUP",
    module = "MODULE",
    parentModule = "PARENT_MODULE",
    lessonContent = "LESSON_CONTENT"
}