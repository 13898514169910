import React from 'react';
import './ToolTipWrapper.css';

type ToolTipWrapperProps = {
    toolTipText:string,
    children:React.ReactNode
}

const ToolTipWrapper = (props:ToolTipWrapperProps) => {
    const {toolTipText, children} = props;

    return (
        <>
        {
            toolTipText && toolTipText.length > 0 ? 
            (
                <div className="cbit-tooltip">
                    <span className="cbit-tooltip-text">{toolTipText}</span>
                    {children}
                </div>
            ) 
            : 
            (
                <>
                    {children}
                </>
            )
        }

        </>
    )
}

export default ToolTipWrapper;