import { Box, Tab, Tabs } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import AttendeeReviewTable from "./ConsultationReviewTable/AttendeeReviewTable";
import ConsultantReviewTable from "./ConsultationReviewTable/ConsultantReviewTable";
import "./ReviewSection.css";

type TabPanelProps = {
    index:number;
    value:number;
    children?:React.ReactNode;
}

/**
 * Section for admins to view consultation views
 * @returns 
 */
const ReviewSection = () => {
    const [tab, setTab] = useState<number>(0);    

    /**
     * Function that renders the tab panel
     * @param props 
     * @returns 
     */
    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <span>{children}</span>
              </Box>
            )}
          </div>
        );
      }
      
      /**
       * Props need for aria-controls for tabs
       * @param index 
       * @returns 
       */
      function a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
      
      /**
       * Updates tab state
       * @param event 
       * @param newValue 
       */
      const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
      };

    return (
        <div className="cbit-container review-section">              
            <h2 className="cbit-header">Consultation Reviews</h2>
            <Box>
                <Tabs 
                    value={tab} 
                    onChange={handleChange} 
                    aria-label="Review Tabs"                    
                    TabIndicatorProps={
                        {
                        style: {
                            backgroundColor: "var(--cbit-orange)",                            
                        },                        
                    }}        
                >
                    <Tab className="review-tabs" label="Consultants" {...a11yProps(0)}/>
                    <Tab className="review-tabs" label="Attendees" {...a11yProps(1)}/>
                </Tabs>
            </Box> 
            <CustomTabPanel  value={tab} index={0}>
                <ConsultantReviewTable/>
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={1}>
                <AttendeeReviewTable/>
            </CustomTabPanel>
        </div>
    )
}

export default ReviewSection;