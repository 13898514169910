import React, {useEffect, useState} from "react";
import "./PastWebinars.css";
import PastWebinarItem from "../../components/pastWebinars/PastWebinarItem";
import {ISeminar} from "../../../../models/consultations/Seminar";
import {ConsultationController} from "../../../../controllers/ConsultationController";
import {Spinner} from "reactstrap";
import ContinuingEducationLayout from "../../components/layout/ContinuingEducationLayout";

const PastWebinars = () => {
    // @ts-ignore
    const [pastWebinars, setPastWebinars] = useState<ISeminar[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(12);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);

    const cController = new ConsultationController();

    /**
     * Gets all webinars
     * @returns {Promise<ISeminar[]>}
     */
    const getAllWebinars = async () => {
        const data = await cController.GetWebinarsWithRecordings(pageNumber, pageSize);

        setPastWebinars(data);
        setLoading(false)
        return data;
    }

    /**
     * Runs on component mount
     * Gets all webinars
     */
    useEffect(() => {
        getAllWebinars();
    }, []);

    /**
     * Loads more webinars
     * @param pageNumber
     * @param pageSize
     * @returns {Promise<ISeminar>}
     */
    const loadMore = async (pageNumber: number, pageSize: number) => {
        let newPage = pageNumber + 1;
        cController.GetWebinarsWithRecordings(newPage, pageSize).then((data:ISeminar[]) => {
            if (data.length < pageSize) {
                setIsCompleted(true);
            }            
            
            setPastWebinars([...data]);
        });
        setPageNumber(newPage);
    }

    return (
        <ContinuingEducationLayout>
            {loading ? (
                <div className="spinner-container">
                    <Spinner/>
                </div>
            ) : (
                <>
                    <div>
                        <h2 className="past-webinars-header">
                            Past Webinars
                        </h2>
                    </div>
                    <div className="past-webinars-container">
                        {/* @ts-ignore */}
                        {pastWebinars.length > 0 ? pastWebinars.map((seminar: ISeminar) => (
                            <PastWebinarItem key={seminar.consultation.id} seminar={seminar}/>
                        )) : (
                            <div className="past-webinars-no-webinars-container">
                                <h3 className="past-webinars-no-webinars-header">
                                    No Past Webinars
                                </h3>
                            </div>
                        )}

                    </div>
                    {pastWebinars.length > 11 && !isCompleted ? (
                        <div className="past-webinars-load-more-container">
                            <button className="past-webinars-load-more-button" onClick={() => loadMore(pageNumber, pageSize)}>
                                {loading ? (<Spinner color='primary'/>) : ("Load More")}
                            </button>
                        </div>
                    ) : (
                        <></>
                    )}
                </>
            )}
        </ContinuingEducationLayout>
    )
}

export default PastWebinars;