import React, {useState} from "react";
import {SocialConnectionController} from "../../../controllers/SocialConnectionController";
import DOMPurify from "dompurify";
import toast from "react-hot-toast";
import {IPost} from "../../../models/SocialConnection/Post";
import {IReply} from "../../../models/SocialConnection/Reply";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {CurrentUserState} from "../../../store/CurrentUser";

type addReplyProps = {
    post: IPost,
    updatePostReply?: any,
}

const AddReply = ({post, updatePostReply}: addReplyProps) => {
    const [reply, setReply] = useState('');

    // Get current user
    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state) => state.currentUser);

    const addReply = async (postId: string, reply: string) => {
        const sConnectionController = new SocialConnectionController();
        return await sConnectionController.AddReply(postId, reply);
    }

    const handleReply = (postId: string, reply: string) => {

        if (reply === '') {
            toast.error('Please enter a reply before submitting.');
            return;
        }

        let cleanedReply = DOMPurify.sanitize(reply);

        cleanedReply = cleanedReply.trim();


        addReply(post.id, cleanedReply).then((data) => {

            //@ts-ignore
            let newReply:IReply = data.replies[ data.replies.length - 1];

            newReply.userName = UserStore!.userProfile.username
            newReply.userImage = UserStore!.userProfile.imageLink

            post.replies.push(newReply);
            updatePostReply(post);
            setReply('')
        });
    }

    return (
        <div key={reply.indexOf(reply)} className='social-connection-post-add-reply-container'>
            <div className="social-connection-post-add-reply-center">
                <div className="social-connection-post-add-reply-warning-text-container">
                    <p className='social-connection-post-add-reply-warning-text'>
                        Note: By clicking "Submit", you agree to not reveal any identifying information that could compromise the privacy of a patient, to be respectful and use language that is appropriate when interacting with others, and to be supportive of other users of this feature of CBIT Trainer.
                    </p>
                </div>
                    <textarea
                        className="social-connection-post-add-reply-textarea"
                        placeholder="Reply to this post..."
                        rows={4}
                        cols={50}
                        value={reply}
                        onChange={(e) => setReply(e.target.value)}
                    />
                <div className="social-connection-post-add-reply-btn-container">
                    <button className="btn-cbit-primary btn-social-connection-add-reply-submit text-uppercase"
                            onClick={() => handleReply(post.id, reply)}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AddReply;