import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { ConsultationController } from '../../../../controllers/ConsultationController';
import { UserController } from '../../../../controllers/UserController';
import { Consultation, IConsultation } from '../../../../models/consultations/Consultation';
import { IConsultationSession } from '../../../../models/consultations/ConsultationSession';
import { ConsultationType, ConsultationUIType } from '../../../../models/consultations/ConsultationType';
import { IUserBio, UserBio } from '../../../../models/module/UserProfile';
import { ErrorHandler } from '../../../../models/utility/ErrorHandler';
import { RolesHelper } from '../../../../models/utility/Roles';
import { ApplicationState } from '../../../../store';
import { CurrentUserState } from '../../../../store/CurrentUser';
import ConsultationAttendeesDetails from '../../ConsultationItems/ConsultationAttendeesDetails';
import './BookAndWithdrawView.css';
import { NotificationController } from '../../../../controllers/NotificationController';
import { ConsultationReceiptController } from '../../../../controllers/ConsultationReceiptController';
import { StringHelper } from '../../../../models/utility/StringHelper';
import TimeHelper from '../../../../models/utility/TimeHelper';

type ConsultationViewInfoProps = {
    consultationSession:IConsultationSession
    toggleModal:() => void    
    togglePayment:() => void    
    toggleSuccessBooking:() => void
    updateConsultations:(bookedConsultation:IConsultation, isCanceling?:boolean) => void
}

type ConsultationViewInfoState = {
    consultant:IUserBio
    isLoading:boolean
    isBooking:boolean
    isCanceling:boolean

}

const userController = new UserController();
const consultationController = new ConsultationController();
const notificationController = new NotificationController();
const receiptController = new ConsultationReceiptController();


/**
 * Component that display info about a consultation and allows users to book or cancel the consultation
 * @param props 
 * @returns 
 */
const BookAndWithdrawView = (props:ConsultationViewInfoProps) => {
    const {
            consultationSession:{consultation, consultant}, 
            toggleModal, 
            togglePayment,
            toggleSuccessBooking, 
            updateConsultations,
        } = props;

    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    const defaultState:ConsultationViewInfoState = {
        consultant: new UserBio(),
        isLoading:false,
        isBooking:false, 
        isCanceling:false,      
    }

    const [state, setState] = useState(defaultState);    

    useEffect(() => {
        async function onComponentMount() {
            await getConsultant();
        }


        // window.addEventListener("beforeunload", (event) => {
            
        // })
        onComponentMount();

        if(window!== undefined){

            window.addEventListener('beforeunload', (e) => {
                e.preventDefault();
                removeUser();
            });  
        }  
        
        return()=>{
            window.removeEventListener('beforeunload', removeUser);  
        }
    }, []);

    const removeUser = async () => {
        await consultationController.RemoveUserFromReserveList(consultation.id);
    }

    /**
     * Get the consultant that created the consultation information
     */
    const getConsultant = async () => {
        setState(prevState => ({...prevState, isLoading:true}));
        
        const userBio = await userController.GetUserBio(consultation.ownerUserProfileId);

        setState(prevState => ({
            ...prevState,
            isLoading:false,                        
            consultant:userBio
        }));
    }

    /**
     * Books a consultation for a user
     * @param e 
     * @returns 
     */
    const bookConsultation = async (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if(!userStore) {
            return;
        } 

        const userId = userStore.userProfile.id;

        if(consultation.ownerUserProfileId === userId){
            toast.error("Consultants cannot book their own session");
            return;
        } 

        const alreadyBooked = consultation.attendeeList.findIndex
                                (attendee => attendee._id == userId) !== -1

        if(alreadyBooked) {
            toast.error("You have already booked this session");
            return;
        }

        setState(prevState => ({...prevState, isBooking:true}));

        const toastId = "bookingToast";
        
        toast.loading("Booking Session", {id: toastId})

        if(Consultation.GetSpaceRemaining(consultation) <= 0) {
            toast.error("Consultation is full");
            return;
        }

        try {

            /// WHEN TEST PHASE IS OVER UNCOMMENT
            // const needToPay = await checkIfUserNeedsToPay(userId, consultation);
            // let hasReceipt = false;

            // if(needToPay) {
            //     hasReceipt = await checkIfHaveReceipt(userId, consultation.id);
            // }            


            //If a user needs to pay and don't have a consultation receipt they can use.
            // if(needToPay &&!hasReceipt) {                                                    
            //     setState((prevState) => ({
            //         ...prevState,
            //         isLoading:false
            //     }));
            //     toast.dismiss(toastId)
            //     togglePayment();
            //     return;                
            // }
            /// WHEN TEST PHASE IS OVER UNCOMMENT

            const bookedConsultation = await consultationController.BookConsultation(consultation.id, userId);            

            toast.dismiss(toastId);

            setState(prevState => ({
                ...prevState,
                isBooking:false
            }));
            
            if(bookedConsultation) {
                toast.success("Successfully booked consultation");                            
                updateConsultations(bookedConsultation);
                await notificationController.ConsultationBookNotification(consultation.id, userId);
            }
        } catch (error) {
            console.error(error);
            setState(prevState => ({...prevState,isBooking:false}));
            toast.dismiss(toastId);
            ErrorHandler.displayErrorsInToast(error as string[]);
        }
    } 
    
    /**
     * Withdraws a user from a consultation
     * @param e 
     * @returns 
     */
    const withdrawUserFromConsultation = async (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if(!userStore) return;

        setState(prevState => ({...prevState, isCanceling:true}));

        const toastId = "cancelConsultationToast";

        toast.loading("Withdrawing from session...", {id:toastId});

        try {
            
            var canceledConsultation = await consultationController.WithdrawFromConsultation(consultation.id, userStore.userProfile.id);

            toast.dismiss(toastId);

            if(canceledConsultation != null) {
                toast.success("Successfully withdrew from the consultation session");
                updateConsultations(canceledConsultation, true)
                toggleModal();
                await notificationController.ConsultationWithdrawNotification(consultation.id, userStore.userProfile.id);
                
            }

        } catch (error) {            
            console.error(error);
            toast.dismiss(toastId);
            toast.error("Failed to withdraw from the consultation");
        }
    }

    /**
     * Checks if a user have already booked the consultation
     * @returns 
     */
    const checkIfUserAlreadyBookedConsultation = () => {
        if(!userStore)
            return false;

        const foundIndex = consultation.attendeeList.findIndex(attendee => attendee._id === userStore.userProfile.id);

        return foundIndex !== -1;
    }

    /**
     * Checks to see if a the current looked in user is allowed to see the attendees list.
     *  Either the consultant or super admins should be able to see the list
     * @returns 
     */
    const checkIfAllowedToSeeAttendees = () => {
        if(!userStore) return false;

        return userStore.userProfile.id === consultant.id || RolesHelper.CheckIfSuperAdmin(userStore.userProfile.roles)
    }

    /**
     * Checks if a user needs to pay for the consultation session they are trying to book
     */
    const checkIfUserNeedsToPay = async (userId:string, consultation:IConsultation) => {
        try {

            if(consultation.type === ConsultationType.Seminar || consultation.type === ConsultationType.Unknown) {
                return false;
            }
            
            const tokenAmount  = await userController.GetUserTokens(userId);            
            
            if(tokenAmount <= 0 ) {
                return true; //user needs to pay
            }

            //All one on one (Private sessions) are paid
            //TODO when test phase is over uncomment this
            // if(consultation.type === ConsultationType.OneOnOne) {
            //     return true;
            // }

            return false;

        } catch (error) {
            throw error;
        }
    }

    /**
     * Check if a user have a receipt they can use to book a consultation
     * @param userId 
     * @param consultationId 
     */
    const checkIfHaveReceipt = async (userId:string, consultationId:string) => {
        try {
            let receipt = await receiptController.GetUserCompatibleReceipt(userId, consultationId);       

            if(receipt === "") {
                receipt = null
            }

            return receipt != null;
        } catch (error) {
            throw error;
        }
    }

    return (
        <>
        <div className='consultation-plan-form form consultation-info'>                
            <h2 className="form-title">CONSULTATION INFORMATION</h2>
            <div className="cbit-row">
                <div className="cbit-column">
                    <span className='cbit-label'>
                        Consultant
                    </span> 
                </div>
            </div>
            <div className="cbit-row">
                <div className="cbit-column view-details-text-container">
                    {state.isLoading ? 
                        ("") 
                        : 
                        (`${state.consultant.firstName} ${state.consultant.lastName}`)
                    }                    
                </div>
            </div>
            <div className="cbit-row">
                <div className="cbit-column">
                    <div className="cbit-label">Session Title</div>
                </div>
            </div>
            <div className="cbit-row">
                <div className="cbit-column view-details-text-container">
                    {consultation.name}
                </div>
            </div>
            <div className="cbit-row">
                <div className="cbit-column">
                    <div className="cbit-label">Date</div>
                </div>
            </div>
            <div className="cbit-row">
                <div className="cbit-column view-details-text-container">
                    {moment(consultation.startDate as string).format("dddd, MM/D/YY")}
                </div>
            </div>
            <div className="cbit-row">
                <div className="cbit-column">
                    <div className="cbit-label">Time</div>
                </div>
            </div>
            <div className="cbit-row">
                <div className="cbit-column view-details-text-container">
                    {Consultation.GetConsultationTime(consultation)}
                </div>
            </div>
            <div className="cbit-row">
                <div className="cbit-column">
                    <div className="cbit-label">Session Type</div>
                </div>
            </div>
            <div className="cbit-row">
                <div className="cbit-column view-details-text-container">
                    {ConsultationUIType[consultation.type]}
                </div>
            </div>
            <div className="cbit-row">
                <div className="cbit-column">
                    <div className="cbit-label">Space Remaining</div>
                </div>
            </div>
            <div className="cbit-row">
                <div className="cbit-column view-details-text-container">
                    {Consultation.GetSpaceRemaining(consultation)}
                </div>
            </div>
            {checkIfAllowedToSeeAttendees() && (
                <>
                    <div className="cbit-row">
                        <div className="cbit-column">
                            <div className="cbit-label">Attendees</div>
                        </div>
                    </div>
                    <div className="cbit-row">
                        <div className="cbit-column view-details-text-container">
                            <ConsultationAttendeesDetails 
                                attendees={props.consultationSession.attendeesBioList}                        
                            />
                        </div>
                    </div>
                </>
            )}
            <div className="cbit-row">
                <div className="cbit-column view-details-text-container view-details-btn-container">
                    <button onClick={toggleModal} className='btn-consultation-close-modal btn-cbit-link' />
                    {
                        checkIfUserAlreadyBookedConsultation() ? 
                        (
                            <>
                                <button
                                    onClick={withdrawUserFromConsultation}
                                    className='btn-cbit-minor btn-consultation-minor'
                                >
                                    Cancel Session
                                </button>
                            </>
                        ) 
                        : 
                        (
                            <>  
                                <button 
                                    disabled={Consultation.GetSpaceRemaining(consultation) <= 0}
                                    onClick={bookConsultation} 
                                    className='btn-cbit-primary btn-book-session'
                                >
                                    Book Session
                                </button>                              
                            </>
                        )
                    }
                </div>
            </div>
        </div>
        </>
    )
}

export default BookAndWithdrawView;