import React from "react";
import { CustomContentButton, StandardContentButton } from "./ContentBuilderMenuButtons/ContentBuilderMenuButton";
import {ReactComponent as ModuleBtnIconGreen} from "../../../../resources/images/module-button-green.svg";
import {ReactComponent as ModuleBtnIconPurple} from "../../../../resources/images/module-button-purple.svg";

export type ModuleMenuOptionsProps = {
    isLoading:boolean;
    isLessonGroup:boolean;
    onVideoClick:any;
    onTextClick:any;
    onQuizClick:any;
    onComboLessonClick:any;
    onLessonGroupClick:any;
}

/**
 * Menu options for when inside of a module or lesson group 
 * @param props 
 * @returns 
 */
const ModuleMenuOptions = (props:ModuleMenuOptionsProps) => {
    
    const {isLoading, onVideoClick, onTextClick, onQuizClick, onLessonGroupClick, onComboLessonClick, isLessonGroup} = props;

    return (
        <>
            <p className="content-label">Standard Content</p>
            
            <StandardContentButton
                isLoading={isLoading} 
                onClick={ () => onVideoClick() } 
                btnLabel={"Video Lesson"} 
            />

            <StandardContentButton
                isLoading={isLoading} 
                onClick={ () => onTextClick() } 
                btnLabel={"Text Lesson"} 
            />

            <StandardContentButton
                isLoading={isLoading} 
                onClick={ () => onQuizClick() } 
                btnLabel={"Quiz"} 
            />

            <p className="content-label">Customizations</p>

            <CustomContentButton 
                isLoading={isLoading} 
                onClick={() => onComboLessonClick()} 
                btnLabel={"Combo Lesson"} 
                textDesc="Combine videos, texts, and/or quiz elements in one lesson."
                btnIcon={<ModuleBtnIconGreen/>} 
            />
            {
                !isLessonGroup && 
                (
                    <CustomContentButton 
                        isLoading={isLoading} 
                        onClick={() => onLessonGroupClick()} 
                        btnLabel={"Lesson Group"} 
                        textDesc="Organize a set of lessons under a singular topic." 
                        btnIcon={<ModuleBtnIconPurple/>} 
                    />
                )
            }

        </>
    )

}

export default ModuleMenuOptions;