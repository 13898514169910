import React, {useEffect, useState} from "react"
import "./Post.css"
import {Link, useParams} from "react-router-dom";
import {SocialConnectionController} from "../../../../controllers/SocialConnectionController";
import {Spinner} from "reactstrap";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {CurrentUserState} from "../../../../store/CurrentUser";
import SocialConnectionPostItemWithReply from "../../components/SocialConnectionPostItemWithReply";
import {IPost} from "../../../../models/SocialConnection/Post";
import {DeepCopy} from "../../../../models/utility/DeepCopy";
import SocialConnectionLayout from "../../components/layout/SocialConnectionLayout";

const Post = ({history}: any) => {
    const [post, setPost] = useState<IPost>({
        id: "",
        name: "",
        content: "",
        isPromoted: false,
        replies: [],
        userId: "",
        createdAt: new Date(),
        updatedAt: new Date(),
        userName: '',
        userImage: '',
        isVisible: true,
        isReported: false,
        isBanned: false
    });
    const [isPostPage, setIsPostPage] = useState(true);
    const [loading, setLoading] = useState(true);

    const params = useParams();

    // Get current user
    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state) => state.currentUser);

    const getSingePost = async () => {
        const sConnectionController = new SocialConnectionController();
        // @ts-ignore
        const post = await sConnectionController.GetPostById(params.id);

        // @ts-ignore
        setPost(post);
        setLoading(false)
    }

    const handlePostUpdate = async (updatedPost: IPost) => {

        let newData = DeepCopy.copy(updatedPost);

        setPost(newData);

    }

    const handlePostUpdateReply = (data: any) => {

        //NEW POST + REPLY
        let incData = data;

        let newReplies = incData.replies[incData.replies.length - 1];
        let replyData = post.replies;

        //@ts-ignore
        replyData.push(newReplies);

        //@ts-ignore
        setPost((prevState) => (
            {
                ...prevState,
                replies: replyData
            }
        ))
    }

    useEffect(() => {
        getSingePost();
    }, []);

    useEffect(() => {
    }, [post]);

    useEffect(() => {
        const userIsBanned = UserStore?.userProfile?.isBanned;

        if (userIsBanned) {
            history.push('/home');
        }

    }, [UserStore?.userProfile]);

    return (
        <SocialConnectionLayout>
            {loading ? (
                <div className="spinner-container">
                    <Spinner color='primary'/>
                </div>
            ) : (
                <>
                    <div className='social-connection-single-post-main-container'>
                        <div className="social-connection-single-post-page-header">
                            <span className='social-connection-go-back'>
                                <Link to='/social-connection'>&#60; Return to Main Discussion Board</Link>
                            </span>
                        </div>
                        <>
                            <SocialConnectionPostItemWithReply post={post}
                                                               key={post.id}
                                                               isPostPage={isPostPage}
                                                               updatePost={handlePostUpdate}
                                                               updatePostReply={handlePostUpdateReply}
                            />
                        </>
                    </div>
                </>
            )}
        </SocialConnectionLayout>
    )
}

export default Post;