import * as CurrentUser from './CurrentUser';
import * as CurrentTreeView from './CurrentTreeView';
import * as UserTreeView from './UserModuleAccordionStore';
import * as CurrentLastLessonStore from './LastLessonStore';
import { CurrentLastLessonState } from './LastLessonStore';

// The top-level state object
export interface ApplicationState {
    currentUser:  CurrentUser.CurrentUserState | undefined;
    currentUserModuleAccordion: UserTreeView.CurrentUserAccordionState | undefined ;
    currentPartialModuleTreeView: CurrentTreeView.CurrentPartialTreeViewState | undefined ;
    currentLastLesson: CurrentLastLessonState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    currentUser: CurrentUser.reducer,
    currentUserModuleAccordion: UserTreeView.reducer,
    currentPartialModuleTreeView: CurrentTreeView.reducer,
    currentLastLesson:CurrentLastLessonStore.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
