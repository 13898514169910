import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Spinner } from "reactstrap";
import { ConsultationController } from "../../../controllers/ConsultationController";
import { ConsultationReviewController } from "../../../controllers/ConsultationReviewController";
import { AttendanceEnum, ConsultationReview, ConsultationReviewWithReviewer, ReviewTypeEnum } from "../../../models/consultations/ConsultationReview";
import { ConsultationType } from "../../../models/consultations/ConsultationType";
import { StringHelper } from "../../../models/utility/StringHelper";
import TimeHelper from "../../../models/utility/TimeHelper";
import UserProfile from "../../UserDashboard/UserProfile/UserProfile";
import CustomModal from "../../Utilities/CustomModal";
import AttendanceViewer, { AttendanceWithUser } from "./AttendanceViewer";
import "./ReviewViewer.css";

export type ReviewViewerProps = {
    reviewId:string;
    reviewerName:string;
}

const consultationController = new ConsultationController();
const reviewController = new ConsultationReviewController();

/**
 * Component for admins to view a single review
 * @returns 
 */
const ReviewViewer = (props:ReviewViewerProps) => {
    const {
            reviewId,   
            reviewerName         
        } = props;

    const [reviewWithReviewer, setReviewWithReviewer] = useState<ConsultationReviewWithReviewer>();
    const [attendanceList, setAttendanceList] = useState<AttendanceWithUser[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isConsultantOpen, setIsConsultantOpen] = useState(false);
    const [isAttendanceOpen, setIsAttendanceOpen] = useState(false);

    useEffect(() => {
        async function onComponentMount() {
            await getReview();
        }
        onComponentMount();
    }, []);

    /**
     * Get the attendance from a review
     * @param review 
     * @param userId 
     * @returns 
     */
    const getAttendance = (review:ConsultationReview, userId:string) : [AttendanceEnum, boolean] => {
        const attendeeAttendance = review.attendeeAttendanceList.find(a => a.attendeeId === userId);
        if(!attendeeAttendance) {
            return [AttendanceEnum.unknown, false];
        }

        return [attendeeAttendance.attendance, attendeeAttendance.isReported];
    }

    /**
     * Get the review from the api and creates the attendanceList
     */

    const getReview = async () => {
        try {
            const reviewWithReviewer:ConsultationReviewWithReviewer = await reviewController.GetReviewWithReviewer(reviewId);            
            setReviewWithReviewer(reviewWithReviewer);
            const consultationSession = await consultationController.GetConsultationSession(reviewWithReviewer.consultation.id)
            let attendanceList = [];

            for(let user of consultationSession.attendeesBioList) {
                const [attendance, isReported] = getAttendance(reviewWithReviewer.review, user.id)

                const attendanceWithUser:AttendanceWithUser = {
                    user,
                    isReported,
                    attendance,
                }
                attendanceList.push(attendanceWithUser);
            }

            setAttendanceList(attendanceList);
            setIsLoading(false);

        } catch (error) {            
            setIsLoading(false);
            console.error(error);
            toast.error("Failed to get review");
        }
    }


    /**
     * Check whether or not we should show the attendee list
     * @returns 
     */
    const showAttendanceList = () => {
        if(!reviewWithReviewer) {
            return false;
        }

        const consultation = reviewWithReviewer.consultation;
        const attendeesCount = consultation.attendeeList.length;

        if(reviewWithReviewer.review.reviewType === ReviewTypeEnum.reviewByAttendee) {
            return false;
        }
        
        return consultation.type !== ConsultationType.Seminar &&  attendeesCount > 0 
    }

    /**
     * Checks if the review is done by an attendee to display the consultant
     */
    const showConsultant = () => {
        if(!reviewWithReviewer) {
            return false;
        }

        const review = reviewWithReviewer.review;
        return review.reviewType == ReviewTypeEnum.reviewByAttendee;
    }

    /**
     * Display a consultants full name if a consultant exists
     * @returns 
     */
    const displayConsultantName = () => {
        let displayName = "Name not found";
        if(!reviewWithReviewer) {
            return displayName;
        }
        
        const consultant = reviewWithReviewer.consultant;

        if(!consultant) {
            return displayName;
        }

        displayName = `${consultant.firstName} ${consultant.lastName}`;

        return displayName;
    }

    /**
     * Display the review comments
     * @returns 
     */
    const displayReviewComments = () => {
        let commentsDisplay = "No comments";
        if(!reviewWithReviewer) {
            return commentsDisplay;
        }

        const comments = reviewWithReviewer.review.comments;

        if(!StringHelper.IsNullOrWhiteSpace(comments)) {
            commentsDisplay = comments;
        }

        return commentsDisplay;
    }

    /**
     * Opens and close the consultant modal
     */
    const toggleConsultantModal = () => {
        setIsConsultantOpen(!isConsultantOpen);
    }

    /**
     * Opens and close the attendance modal
     */
    const toggleAttendanceModal = () => {
        setIsAttendanceOpen(!isAttendanceOpen);
    }

    /**
     * displays and format the time of the consultation
     */
    const displayConsultationTime = () => {
        if(!reviewWithReviewer) {
            return "";
        }

        const consultation = reviewWithReviewer.consultation;

        const startDate = consultation.startDate;
        const endDate = consultation.endDate;

        return `${TimeHelper.formatFullDateAndTime(startDate as string)} - ${TimeHelper.formatFullDateAndTime(endDate as string, "h:mm A")}`
    }

    /**
     * displays and format the time the review was posted at
     * @returns 
     */
    const displayReviewTime = () => {
        if(!reviewWithReviewer) {
            return "";
        }

        const review = reviewWithReviewer.review;

        const data = review.PostedAt;        

        return `${TimeHelper.formatFullDateAndTime(data as string)}`
    }

    return (        
        <div className="cbit-dialog review-viewer">
            <h2 className="form-title">{reviewerName} Review</h2>
            <div className="review-viewer-inner">
            {
                isLoading ? 
                (
                    <Spinner color="black"></Spinner>
                ) 
                : 
                (
                    <>
                    {
                        !reviewWithReviewer ?
                        (
                            <p>Could not find review</p>
                        ) 
                        : 
                        (
                            <>                                                                
                                <div className="review-btn-container">
                                    {
                                        showAttendanceList() && 
                                        (
                                                <div className="cbit-row">
                                                    <button 
                                                    type="button" 
                                                        className="btn-cbit-link cbit-label"
                                                        onClick={toggleAttendanceModal}
                                                    >
                                                        Attendance List
                                                    </button>
                                                </div>      
                                        )
                                    }
                                    <>
                                        {
                                            showConsultant() && 
                                            (
                                                <>
                                                    <div className="cbit-label">
                                                        Consultant:
                                                    </div>
                                                    <button 
                                                        type="button" 
                                                        className="btn-cbit-link cbit-label" 
                                                        onClick={toggleConsultantModal}
                                                    >
                                                        {displayConsultantName()}
                                                    </button>
                                                </>
                                            )
                                        }
                                    </>                        
                                </div>                                                                    
                                <div>
                                    <div className="cbit-label">
                                        Consultation Time
                                    </div>
                                    <div>
                                        {displayConsultationTime()}
                                    </div>
                                </div>
                                <div>
                                    <label className="cbit-label">
                                        Reviewed At
                                    </label>
                                    <div>
                                        {displayReviewTime()}
                                    </div>
                                </div>
                                <div>                                    
                                    <label 
                                        className="cbit-label"
                                        htmlFor="consultationReviewComments"
                                    >
                                        Rating
                                    </label>                                    
                                    <div className="rating-container">
                                    {
                                        Array.from({length: reviewWithReviewer.review.qualityRating}).map(idx => (
                                            <label 
                                            className="rating-label"                                                
                                            ></label>
                                            )) 
                                        }
                                    </div>                                
                                </div>                                
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <label 
                                            className="cbit-label"
                                            htmlFor="consultationReviewComments"
                                        >
                                            Additional Comments
                                        </label>
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div id='cbit-review-textarea' className="cbit-column">
                                    <textarea                                          
                                        disabled
                                        className="form-input consultation-review-comments"
                                        cols={50}
                                        rows={10}
                                        readOnly={true}               
                                        onChange={() => {}}
                                        value={displayReviewComments()}                                        
                                        ></textarea>
                                    </div>
                                </div>
                                {
                                    reviewWithReviewer.consultant != null && (                                        
                                        <CustomModal isOpen={isConsultantOpen} toggle={toggleConsultantModal}>
                                            {
                                                <div className="cbit-dialog review-user-profile">
                                                    <UserProfile 
                                                        userBio={reviewWithReviewer.consultant}
                                                        />
                                                </div>                
                                            }
                                        </CustomModal>
                                    )
                                }
                                {
                                    showAttendanceList() && (
                                        <CustomModal isOpen={isAttendanceOpen} toggle={toggleAttendanceModal}>  
                                           <AttendanceViewer 
                                                attendanceList={attendanceList} 
                                            />
                                        </CustomModal>
                                    )
                                }
                            </>
                        )
                    }
                    </>
                )
            }
            </div>
        </div>        
    )
}

export default ReviewViewer;