import React, { useState } from "react";
import "./AttendanceForm.css";
import { IUserBio, UserBio } from "../../../models/module/UserProfile";
import {v4 as uuidV4} from "uuid";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { AttendanceEnum, AttendeeAttendance } from "../../../models/consultations/ConsultationReview";
import * as Yup from "yup";
import { StringHelper } from "../../../models/utility/StringHelper";
import PresentLabel from "../Attendance/PresentLabel";
import AbsentLabel from "../Attendance/AbsentLabel";
import LateLabel from "../Attendance/LateLabel";
import CustomModal from "../../Utilities/CustomModal";
import UserProfile from "../../UserDashboard/UserProfile/UserProfile";
import ReportLabel from "../Attendance/ReportLabel";

/**
 * type of props the AttendanceForm takes
 */
type AttendanceProps = {
    attendees:UserBio[];
    attendanceList:AttendeeAttendance[];
    attendanceFormSubmit:(attendanceList:AttendeeAttendance[]) => void
}

/**
 * Form for consultants to fill out on the consultation review what attendees where present or not
 */
const AttendanceForm = (props:AttendanceProps) => {
    const {
        attendees,
        attendanceList,
        attendanceFormSubmit,
    } = props;

    const [isUserOpen, setIsUserOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<IUserBio>(new UserBio());

    /**
     * Set the selected user and open the user profile modal
     * @param user 
     */
    const setUserAndOpenModal = (user:IUserBio) => {
        setSelectedUser(user);
        setIsUserOpen(true);
    }

    const formSubmit = (values:any) =>{
        attendanceFormSubmit(values.attendance)
    }

    const initialValues = {attendance: attendanceList}

    const isAttendanceFieldChecked = (attendance:AttendeeAttendance[], attendeeId:string, attendanceVal:AttendanceEnum) => {
        const foundIndex = attendance.findIndex(a => a.attendeeId === attendeeId);

        if(foundIndex === -1) {
            return false;
        }

        return attendance[foundIndex].attendance === attendanceVal;
    }

    const isAttendeeReported = (attendance:AttendeeAttendance[], attendeeId:string) => {
        const foundIndex = attendance.findIndex(a => a.attendeeId === attendeeId);

        if(foundIndex === -1) {
            return false;
        }

        return attendance[foundIndex].isReported;
    }

    const schema = Yup.object().shape({
        attendance: Yup.array()
            .of(
                Yup.object().shape({
                    attendeeId: Yup.string(),
                    isReported: Yup.boolean(),
                    attendance: Yup.mixed().oneOf([AttendanceEnum.present, AttendanceEnum.absent, AttendanceEnum.late], 
                        "Please select one of the attendance types")
                })
            )
    })

    return (
        <>
        <Formik
            enableReinitialize={true}             
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={formSubmit}
        >
            { ({values}) => (
                <Form className="attendee-list cbit-dialog attendance-form">                                    
                    <h2 className="cbit-header">Attendees Attendance</h2>
                    <div className="attendance-legend consultation-calendar-legend">
                        <span><i className="present-icon bi bi-person-check"></i> Present</span>
                        <span><i className="absent-icon bi bi-x-lg"></i> Absent</span>
                        <span><i className="late-icon bi bi-alarm"></i> Late</span>
                        <span><i className="report-icon bi bi-flag-fill"></i> Report</span>
                    </div>
                    <div>
                        <div className="attendance-list">
                            <FieldArray 
                                name="attendance"
                                render={arrayHelpers => (
                                    <div>
                                    {
                                        attendees.map((attendee, idx) => (
                                            <React.Fragment key={uuidV4()}>
                                                <div key={uuidV4()} className="attendance-list-item">
                                                    <div>
                                                        <button type="button" onClick={() => setUserAndOpenModal(attendee)} className="btn-cbit-link">
                                                            {`${StringHelper.capitalizeWord(attendee.firstName)} ${StringHelper.capitalizeWord(attendee.lastName)}`}
                                                        </button>
                                                    </div>
                                                    <div className="attendance-actions">                        
                                                        <Field 
                                                            className="attendance-input" 
                                                            type="radio" 
                                                            name={`attendance[${idx}]`} 
                                                            id={`present-${idx}`}   
                                                            value={AttendanceEnum.present}
                                                            checked={isAttendanceFieldChecked(values.attendance, attendee.id, AttendanceEnum.present)}  
                                                            onChange={() => 
                                                                arrayHelpers.replace(idx,
                                                                    {
                                                                        attendeeId: attendee.id,
                                                                        isReported: values.attendance[idx].isReported, 
                                                                        attendance:AttendanceEnum.present
                                                                    })}
                                                        />
                                                        <PresentLabel idx={idx} />
                                                        <Field 
                                                            className="attendance-input" 
                                                            type="radio" 
                                                            name={`attendance[${idx}]`} 
                                                            id={`absent-${idx}`}    
                                                            value={AttendanceEnum.absent} 
                                                            checked={isAttendanceFieldChecked(values.attendance, attendee.id, AttendanceEnum.absent)}  
                                                            onChange={() => 
                                                                arrayHelpers.replace(idx,
                                                                    {
                                                                        attendeeId: attendee.id,
                                                                        isReported: values.attendance[idx].isReported,
                                                                        attendance:AttendanceEnum.absent
                                                                    })}
                                                        />
                                                        <AbsentLabel idx={idx}/>
                                                        <Field 
                                                            className="attendance-input" 
                                                            type="radio" 
                                                            name={`attendance[${idx}]`} 
                                                            id={`late-${idx}`}    
                                                            value={AttendanceEnum.late}
                                                            checked={isAttendanceFieldChecked(values.attendance, attendee.id, AttendanceEnum.late)}  
                                                            onChange={() => 
                                                                arrayHelpers.replace(idx,
                                                                    {
                                                                        attendeeId: attendee.id, 
                                                                        isReported: values.attendance[idx].isReported,
                                                                        attendance:AttendanceEnum.late
                                                                    })}                                                     
                                                        />
                                                        <LateLabel idx={idx}/>
                                                    </div>
                                                    <div>
                                                    <Field 
                                                        className="attendance-input attendance-report"
                                                        type="checkbox"
                                                        name={`attendance[${idx}]`}
                                                        id={`isReported-${idx}`}
                                                        value={`${values.attendance[idx].isReported}`}
                                                        checked={isAttendeeReported(values.attendance, attendee.id)}
                                                        onChange={() => 
                                                            arrayHelpers.replace(idx, 
                                                                {
                                                                    attendeeId: attendee.id,
                                                                    attendance:values.attendance[idx].attendance, 
                                                                    isReported: !values.attendance[idx].isReported
                                                                })
                                                        }                                                            
                                                    />
                                                        <ReportLabel htmlFor={`isReported-${idx}`}/>
                                                    </div>
                                                </div>
                                                <div className="error-message-container">
                                                    <ErrorMessage name={`attendance[${idx}].attendance`} />
                                                </div>
                                            </React.Fragment>
                                        ))
                                    }
                                    </div>
                                )}
                            />                                                            
                            <div className="form-btn-container">
                                <button type="submit" className="btn-cbit-primary">Submit</button>
                            </div>
                        </div>
                    </div>        
                </Form>
        )}
        </Formik>
        <CustomModal 
            isOpen={isUserOpen} 
            toggle={() => setIsUserOpen(!isUserOpen)}
        >
            <div className="cbit-dialog review-user-profile">
                <UserProfile 
                    userBio={selectedUser} 
                    />
            </div>
        </CustomModal>
        </>
    )
}

export default AttendanceForm;