import React from 'react';
import { IUserBio } from '../../../models/module/UserProfile';
import './UserProfile.css';

type userProfileProps = {
    userBio:IUserBio
}

const UserProfile = (props:userProfileProps) => {
    const {userBio} = props;
    return (
        <>
        <h4 className="cbit-header">
                {`${userBio.prefix} ${userBio.firstName} ${userBio.lastName}`}
            </h4>
            <div className="cbit-row">
                <div className="cbit-label">
                    Occupation
                </div>                                      
            </div>
            <div className="cbit-row">
                <p className='capitalize-text'>
                    {
                        userBio.title && userBio.title.length > 0 ? 
                        (
                            <>
                                {userBio.title}
                            </>
                        )
                        :
                        (
                            <>
                            No occupation has been set
                            </>
                        )
                    }
                    
                </p>
            </div>
            <div className="cbit-row">
                <div className="cbit-label">
                    Email
                </div>   
            </div>
            <div className="cbit-row">
                <p>
                    {userBio.email}
                </p>
            </div>
            <div className="cbit-row">
                <div className="cbit-label">
                    Consultations Completed
                </div>   
            </div>
            <div className="cbit-row">
                <p>
                    {userBio.numFreeConsultations ?? 0}
                </p>

            </div>
            <div className="cbit-row">
                <div className="cbit-label">
                    Bio
                </div>         
            </div>
            <div className="cbit-row">
                <p className='user-profile-description'>
                    {userBio.description}
                </p>

            </div>
        </>
    )
}

export default UserProfile;