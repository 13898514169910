import { GridColDef } from "@mui/x-data-grid";
import * as React from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { v4 as uuidV4 } from "uuid";
import { ConsultationReviewController } from "../../../../controllers/ConsultationReviewController";
import { ConsultationUIType } from "../../../../models/consultations/ConsultationType";
import { AttendeeReview } from "../../../../models/consultations/reviews/AttendeeReview";
import TimeHelper from "../../../../models/utility/TimeHelper";
import CbitDataGrid from "../../../Utilities/CbitTable/CbitDataGrid";
import CustomModal from "../../../Utilities/CustomModal";
import ReviewViewer from "../ReviewViewer";
import { SelectedReviewState } from "./ConsultantReviewTable";

export type attendeeReviewType = {
    id:string;
    createdAt:string;
    consultant:string;
    consultationType:string,
    rating:number;    
    reviewedBy:string;
    summary:string;
}

const reviewController = new ConsultationReviewController(); 

const noReviewsFoundText = "No Attendee Reviews Found";

/**
 * Table for attendee type reviews
 * @returns 
 */
const AttendeeReviewTable = () => {
    //TODO refactor so we don't repeat this
    const [reviews, setReviews] = useState<(AttendeeReview & {id:string})[]>([]);
    const [selectedReviewInfo, setSelectedReviewInfo] = useState<SelectedReviewState>({reviewId: "" ,reviewerName: ""})
    const [isReviewOpen, setIsReviewOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    React.useEffect(() => {
      async function onComponentMount() {          
          try {
              const results:AttendeeReview[] = await reviewController.GetAttendeesReviews();
              const consultantReviews = results.map(cr => ({...cr, id:uuidV4()}))
              setReviews(consultantReviews);
          } catch (error) {
              console.error(error);
              toast.error("Failed to get attendees reviews");
          }
          setIsLoading(false);
      }
      onComponentMount()
  }, []);
    
    const attendeeColumns:GridColDef[] =[
        {
            field: 'reviewedAt', 
            headerName: 'Date', 
            flex:1,       
            valueGetter: (params) => {
                const date = params.value;            
                return TimeHelper.formatFullDateAndTime(date, "YYYY-MM-DD h:mm A")
            }                                                            
        },
        {
            field: 'consultant', 
            headerName: 'Consultant', 
            flex:1,                                                             
        },
        {
            field: 'type',
            headerName: 'Consultation Type',
            flex: 1,
            valueGetter: (params) => {
                const type = params.value;            
                return ConsultationUIType[type as keyof typeof ConsultationUIType];
            }
        },
        {
            field: 'rating', 
            headerName: 'Rating', 
            flex:1,                                                                  
        },
        {
            field: 'reviewerName', 
            headerName: 'Reviewed By', 
            flex:1,                                                                  
        },
        {
            field: 'comments', 
            headerName: 'Summary', 
            flex:1,                                                                  
        },
        {
            field: 'view', 
            headerName: 'View', 
            filterable:false,
            sortable: false,
            renderCell: (cell:any) => {                
                return (

                    <button 
                        onClick={() => setAndOpenReview(cell.row.reviewId, cell.row.reviewerName)}
                        className="btn-cbit-link btn-user-table"
                    >
                        View
                    </button>
                )
            }                                                                
        },
    ];

    /**
     * Sets a selected review and open it in the modal
     * @param reviewId 
     * @param reviewerName 
     */
    const setAndOpenReview = (reviewId:string, reviewerName:string) => {
        setSelectedReviewInfo({reviewId, reviewerName});
        toggleIsReviewOpen();
    }

    /**
     * Open and close the review modal
     */
    const toggleIsReviewOpen = () => {
        setIsReviewOpen(!isReviewOpen);
    }

        
    return (
        <>        
            <CbitDataGrid 
                columns={attendeeColumns}
                rowData={reviews}
                isLoading={isLoading} 
                noRowsText={noReviewsFoundText}
            />                                         
            <CustomModal 
                isOpen={isReviewOpen} 
                toggle={toggleIsReviewOpen}
            >
                <ReviewViewer
                    reviewId={selectedReviewInfo.reviewId}                         
                    reviewerName={selectedReviewInfo.reviewerName}                         
                />
            </CustomModal>            
    </>
    )
}

export default AttendeeReviewTable;