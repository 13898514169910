import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { v4 as uuidV4 } from 'uuid';
import { ConsultationController } from '../../../../controllers/ConsultationController';
import { ConsultationReviewController } from '../../../../controllers/ConsultationReviewController';
import useSortBy from '../../../../hooks/UseSortBy';
import { IConsultation } from '../../../../models/consultations/Consultation';
import { ConsultationReview, IConsultationReview } from '../../../../models/consultations/ConsultationReview';
import { ConsultationSession, IConsultationSession } from '../../../../models/consultations/ConsultationSession';
import { ConsultationType } from '../../../../models/consultations/ConsultationType';
import { SortByEnum } from '../../../../models/Enums/SortByEnum';
import { CheckIfSuperAdmin } from '../../../../models/module/UserProfile';
import { DeepCopy } from '../../../../models/utility/DeepCopy';
import TimeHelper from '../../../../models/utility/TimeHelper';
import { ApplicationState } from '../../../../store';
import { CurrentUserState } from '../../../../store/CurrentUser';
import CustomModal from '../../../Utilities/CustomModal';
import ToggleButton from '../../../Utilities/ToggleButton/ToggleButton';
import ConsultationReviewForm from '../../ConsultationForms/ConsultationReviewForm';
import SessionItem, { SessionItemType } from '../../ConsultationItems/SessionItem';

enum consultationFilterDates {
    past7Days = 0,
    past30Days = 1,
    past60Days = 2,
    past90Days = 3,
    AllTime = 4
}

type PreviousSessionsPageState = {
    currentFilterType:ConsultationType
    currentFilterDate:consultationFilterDates
    previousConsultationSessions:IConsultationSession[],
    originalPreviousSessions:IConsultationSession[],
    reviews:IConsultationReview[]    
    isLoading:boolean
    isCancelAlertOpen:boolean
    isReviewOpen:boolean
}

const consultationController = new ConsultationController();
const consultationReviewController = new ConsultationReviewController();

/**
 * A copy of the upcoming sessions page but for previous session. It would be better to make them into one component
 * @returns 
 */
const PreviousSessionsPage = () => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    const isFirstRender = useRef(true);
    
    const defaultState:PreviousSessionsPageState = {
        currentFilterType: ConsultationType.Unknown,
        currentFilterDate: consultationFilterDates.past7Days,
        previousConsultationSessions:[],
        originalPreviousSessions:[],
        reviews:[],        
        isLoading:false,
        isCancelAlertOpen:false,
        isReviewOpen:false
    }

    const [state, setState] = useState(defaultState); 

    const [selectedSession, setSelectedSession] = useState<IConsultationSession | null>(null);

    const [isReviewOpen, setIsReviewOpen] = useState(false);

    const [showFeedBackSessions, setShowFeedBackSessions] = useState(false);

    const [showAllSessions, setShowAllSessions] = useState(false);

    const [sortBy, setSortBy, handleOnSortChange] = useSortBy(SortByEnum.desc);

    useEffect(() => {
        function onComponentMount() {
            isFirstRender.current = false
        }
        onComponentMount();
    }, []);

    useEffect(() => {
        async function onFilterChangeAndMount() {
            await getPastSessions();
        }
        onFilterChangeAndMount();
    },[state.currentFilterDate]);

    useEffect(() => {
        function clearSelectedUserOnModalClose() {
            if(!isReviewOpen) {
                setSelectedSession(null);
            }
        }
        clearSelectedUserOnModalClose();
    }, [isReviewOpen]);

    useEffect(() => {
        async function onDisplayEmptyUpdate() {            
            getPastSessions();
        }
        if(!isFirstRender.current) {
            onDisplayEmptyUpdate();
        }
    },[showAllSessions]);

    useEffect(() => {
        async function sortConsultationOnSortByChange() {    
            const prevSessions = DeepCopy.copy(state.originalPreviousSessions);

             const updatedSessions = sortAndFilterConsultations(prevSessions);
            
             setState(prevState => ({
                ...prevState,
                previousConsultationSessions:updatedSessions
             }));
        }
        sortConsultationOnSortByChange();
    }, [sortBy, showFeedBackSessions, state.currentFilterType]);

    useEffect(() => {

    },[state.reviews]);
    
    const filterConsultationsByType = (sessions:IConsultationSession[]) => {
        const {currentFilterType} = state; 

        let prevSessionsCopy:IConsultationSession[] = DeepCopy.copy(sessions);

        if(currentFilterType !== ConsultationType.Unknown) {
            prevSessionsCopy = prevSessionsCopy.filter(session => session.consultation.type === currentFilterType);
        }

        return prevSessionsCopy;
    }

    const sortAndFilterConsultations = (sessions:IConsultationSession[]) => {            
        const isDesc = sortBy === SortByEnum.desc;

        const sessionCopy = DeepCopy.copy(sessions);
        
        const sessionListCopy:IConsultationSession[] = filterConsultationsByType(sessionCopy);
        
        let sortedSessions = ConsultationSession.SortConsultationSessionsByTime(sessionListCopy, isDesc);

        if(showFeedBackSessions) {
            const listOfReviewIds = state.reviews.map(r => r.consultationId);                      

            sortedSessions = sortedSessions.filter(ss => {
                return !listOfReviewIds.includes(ss.consultation.id) && ss.consultation.attendeeList.length > 0;
            });            
        }

        return sortedSessions;
    }

    /**
     * Get past consultation sessions from on the current date
     */
    const getPastSessions = async () => {
        if(!userStore) return;        

        setState(prevState => ({...prevState, isLoading:true}));

        let from = TimeHelper.createMomentDate(new Date()).toDate();
        let to = TimeHelper.createMomentDate(new Date());

        switch (state.currentFilterDate) {
            case consultationFilterDates.past7Days:
                from = moment(from).subtract(7, "day").startOf("day").toDate();
                break;
            case consultationFilterDates.past30Days:
                from = moment(from).subtract(30, "day").startOf("day").toDate();
                break;
            case consultationFilterDates.past60Days:
                from = moment(from).subtract(60, "day").startOf("day").toDate();
                break;
            case consultationFilterDates.past90Days:
                from = moment(from).subtract(90, "days").startOf("day").toDate();
                break;
            default:
                break;
        }
        try {
            let pastSessions:ConsultationSession[] = [];
            let reviews:ConsultationReview[] = [];
            const userId = userStore.userProfile.id;
            if(showAllSessions) {
                pastSessions = await consultationController.GetAllPreviousSessions(from);
                reviews = [];
            } else {            
                pastSessions = await consultationController.GetPreviousConsultations(userId,from,from);
                reviews = await consultationReviewController.GetReviewsByConsultations(pastSessions.map(s => s.consultation.id), userId);
            }                    
            
            ConsultationSession.SortConsultationSessionsByTime(pastSessions, sortBy === SortByEnum.desc);
            
            const sortedAndFilteredSessions = sortAndFilterConsultations(pastSessions);

            setState(prevState => ({
                ...prevState, 
                isLoading:false,
                originalPreviousSessions:pastSessions,
                previousConsultationSessions:sortedAndFilteredSessions,
                reviews
            }));

        } catch (error) {
            console.error(error);
            toast.error("Failed to retrieve consultation sessions");
            setState(prevState => ({...prevState, isLoading:false}));
        }
    }

    /**
    * Handles input select change and updates state
    * @param e 
    */
    const handleInputChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
        const name = e.target.name;

        //Because the values are converted to strings by html we need to convert them back 
        const value = parseInt(e.target.value);

        setState((prevState:any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleProvideFeedbackClick = (session:IConsultationSession) => {
        setSelectedSession(session);
        toggleReviewModal();
        
    }

    const toggleReviewModal = () => { 
        setIsReviewOpen(!isReviewOpen);      
    }

    const toggleShowAllSessions = () => {
        setShowAllSessions(!showAllSessions);
    }

    /**
     * Toggle to show only consultations that need feedback/review
     */
    const toggleConsultationsNeedFeedback = () => {
        setShowFeedBackSessions(!showFeedBackSessions);
    }

    const onReviewSubmit = (consultationReview?:IConsultationReview) => {
        if(consultationReview) {
            const reviewCopy:ConsultationReview[] = state.reviews;
            
            reviewCopy.push(consultationReview);    
            
            setState(prevState => ({
                ...prevState,
                reviews:reviewCopy
            }));            
            
        }
        toggleReviewModal();
    }

    /**
     * checks the list of reviews to see if we already have a review.
     * If we don't have a review than the user can provide feedback
     * @param consultationId 
     * @returns 
     */
    const canProvideFeedback = (consultationId:string, consultantId:string) => {   
        const foundIndex = state.reviews.findIndex(cr => cr.consultationId === consultationId 
                                                        && cr.assignedUserId === consultantId);
        return foundIndex === -1
    }

    /**
     * Check to see if the consultation had attendees attend
     */
    const checkIfHadAttendees = (sessionItem:IConsultationSession) => {
        const {consultation} = sessionItem;

        return consultation.attendeeList.length > 0;
    }

    /**
     * Check to see if the current user is part of the consultation or not
     */
    const checkIfPartOfConsultation = (consultationSession:IConsultationSession) => {
        if(!userStore) {
            return false;
        }
        
        const {consultant, consultation, attendeesBioList} = consultationSession;

        const id = userStore.userProfile.id;

        if(consultant.id === id) {
            return true;
        }

        if(attendeesBioList.find(a => a.id === id)) {
            return true;
        }

        return false;
    }   

    return (
        <>
        <div className="consultations-container upcoming-consultations-container">
            <h2 className="consultation-header">
                Previous Sessions
            </h2>
            <div id='previous-sessions' className="consultation-filters">
                <div className="consultation-filters-container">
                    <select
                        id="filterConsultationType"
                        name="currentFilterType"
                        className="form-input consultation-filter-select"
                        value={state.currentFilterType}
                        onChange={handleInputChange}
                    >
                        <option value={ConsultationType.Unknown}>All Sessions</option>
                        <option value={ConsultationType.OneOnOne}>Individual Sessions</option>
                        <option value={ConsultationType.SmallGroup}>Group Sessions</option>
                        <option value={ConsultationType.Seminar}>Seminars</option>
                    </select>
                    <select
                        id="filterConsultationDate"
                        name="currentFilterDate"
                        className="form-input consultation-filter-select"
                        value={state.currentFilterDate}
                        onChange={handleInputChange}
                    >
                        <option value={consultationFilterDates.past7Days}>Past 7 Days</option>
                        <option value={consultationFilterDates.past30Days}>Past 30 days</option>
                        <option value={consultationFilterDates.past60Days}>Past 60 days</option>
                        <option value={consultationFilterDates.past90Days}>Past 90 days</option>
                    </select>
                    <select
                        className="form-input consultation-filter-select"
                        name="sortBy"
                        value={sortBy}
                        onChange={handleOnSortChange}
                    >
                        <option value="" disabled> Sort Date By:</option>
                        <option value={SortByEnum.asc}>Ascending</option>
                        <option value={SortByEnum.desc}>Descending</option>
                    </select>
                </div>
                <div className="empty-session-toggle">
                    <span>Display sessions that need feedback</span>
                    <ToggleButton
                        isToggled={showFeedBackSessions}
                        onToggleChange={toggleConsultationsNeedFeedback}
                    />
                </div>
                {
                    userStore && CheckIfSuperAdmin(userStore.userProfile.roles) && 
                    (
                        <div className="empty-session-toggle">
                            <span>Display All Consultations</span>
                            <ToggleButton
                                isToggled={showAllSessions}
                                onToggleChange={toggleShowAllSessions}
                            />
                        </div>
                    )
                }
            </div>

            <div className="upcoming-consultations">
                {
                    state.isLoading ? 
                    (
                        <Spinner ></Spinner>
                    ) 
                    : 
                    (
                        <>
                        {
                            state.previousConsultationSessions.length > 0 ? 
                            (
                                <>
                                    {state.previousConsultationSessions.map(session => (
                                        <React.Fragment
                                            key={uuidV4()}
                                        >
                                            <SessionItem
                                                sessionType={SessionItemType.previous}
                                                consultationSession={session}
                                            >
                                                {
                                                    checkIfPartOfConsultation(session) && 
                                                    (
                                                        <>
                                                            {
                                                                canProvideFeedback(session.consultation.id, session.consultant.id) ? 
                                                                (
                                                                    <button
                                                                        onClick={() => handleProvideFeedbackClick(session)} 
                                                                        disabled={!checkIfHadAttendees(session)}
                                                                        className="btn-cbit-minor btn-consultation-minor"
                                                                    >
                                                                        Provide Feedback
                                                                    </button>
                                                                ) 
                                                                : 
                                                                (
                                                                    <button   
                                                                        disabled                                                                                                                  
                                                                        className="btn-cbit-minor btn-consultation-minor"
                                                                    >
                                                                        Feedback Sent
                                                                    </button>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                }
                
                                            </SessionItem>
                                        </React.Fragment>
                                    ))}
                                </>
                            )
                            :
                            (
                                <div>No Previous Sessions Found</div>
                            )
                        }
                        </>
                    )
                }
                
            </div>
        </div>
        
            
                <CustomModal isOpen={isReviewOpen} toggle={toggleReviewModal}>
                    {
                        selectedSession && (
                            <ConsultationReviewForm 
                                consultationSession={selectedSession as IConsultationSession} 
                                onSubmit={onReviewSubmit} 
                            />
                        )
                    }
                </CustomModal>
        
        </>
    )
}

export default PreviousSessionsPage;